import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { SvgGradient, ThemeProvider, Toaster, Tooltip } from '@knack/asterisk-react';
import { QueryClientProvider, type QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { ErrorFallback } from '@/components/errors/ErrorFallback';
import { LanguageSwitcher } from '@/components/layout/LanguageSwitcher';

if (import.meta.env.MODE !== 'testing') {
  // eslint-disable-next-line no-console
  console.log(
    `%cKnack Builder (${APP_VERSION})`,
    'background: #982a86; color: #ffffff; font-size: 20px; padding:5px'
  );
  // eslint-disable-next-line no-console
  console.log(`%cLast modification: ${APP_LAST_MOD}`, 'font-size: 14px;');
}

if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.PUBLIC_LOGROCKET_APP_ID, {
    rootHostname: import.meta.env.PUBLIC_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com',
    dom: {
      baseHref: `${window.location.protocol}//${window.location.hostname}/`
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body?.includes('"password":')) {
          const params = JSON.parse(request.body);
          params.password = '********';
          request.body = JSON.stringify(params);
        }

        // otherwise log the request normally
        return request;
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

interface AppProvidersProps {
  children: React.ReactNode;
  queryClient: QueryClient;
}

export function AppProviders({ children, queryClient }: AppProvidersProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <FlagsmithProvider
        options={{
          environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENV_ID
        }}
        flagsmith={flagsmith}
      >
        <HelmetProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
            <ThemeProvider forceMode="light">
              {import.meta.env.DEV && <LanguageSwitcher />}
              <Toaster />
              <SvgGradient gradientId="gradient-1" />
              <SvgGradient gradientId="gradient-2" />
              <Tooltip.Provider>{children}</Tooltip.Provider>
            </ThemeProvider>
          </ErrorBoundary>
        </HelmetProvider>

        <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
      </FlagsmithProvider>
    </QueryClientProvider>
  );
}
