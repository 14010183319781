import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface CrispVerifyPayload {
  email: string;
}

interface VerifyEmailResponse {
  email: string;
  signature: string;
}

const verifyEmail = async (email: string) => {
  const { data } = await axios.post<VerifyEmailResponse>(
    `${import.meta.env.PUBLIC_API_URL}/v1/crisp-auth/identify`,
    { email },
    {
      withCredentials: true
    }
  );
  return data.signature;
};

export function useCrispMutation() {
  return useMutation({
    mutationFn: ({ email }: CrispVerifyPayload) => verifyEmail(email)
  });
}
