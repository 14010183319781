import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type KnackField } from '@/types/schema/KnackField';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type CreateFieldParams = {
  tableKey: string;
  fieldData: KnackField;
  fieldIndex: number;
};

async function createField({ tableKey, fieldData, fieldIndex }: CreateFieldParams) {
  const endpoint = `/v1/objects/${tableKey}/fields?fieldIndex=${fieldIndex}`;

  const { data } = await axios.post(endpoint, fieldData, { withCredentials: true });

  return data;
}

export function useCreateFieldMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createField,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
