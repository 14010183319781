import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { buildGradientString } from '@/pages/themes/theme-editor/utils';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

const calculatePosition = (angle: number, radius: number) => {
  const radian = (angle * Math.PI) / 180; // Convert degrees to radians
  const x = radius * Math.cos(radian);
  const y = radius * Math.sin(radian);
  return { x, y };
};

export function GradientWheel({
  mode,
  firstColor,
  secondColor,
  angle
}: {
  mode: string;
  firstColor: string;
  secondColor: string;
  angle: number;
}) {
  const [t] = useTranslation();
  const wheelRef = useRef<HTMLButtonElement>(null);
  const radius = 10; // Radius for the dot placement
  const { setThemeField } = useThemesPageContext();
  const [currentAngle, setCurrentAngle] = useState<number>(angle);
  const { x: initialX, y: initialY } = calculatePosition(angle, radius);
  const [dotPosition, setDotPosition] = useState<{ x: number; y: number }>({
    x: initialX,
    y: initialY
  });

  const handleMouseMove = (e: React.MouseEvent) => {
    if (wheelRef.current) {
      const rect = wheelRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const deltaX = e.clientX - centerX;
      const deltaY = e.clientY - centerY;
      const newAngle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
      const roundedNewAngle = Math.round((newAngle + 360) % 360);

      setCurrentAngle(roundedNewAngle);
      setThemeField((draft) => {
        draft.background[mode] = buildGradientString(firstColor, secondColor, roundedNewAngle);
      });

      const { x, y } = calculatePosition(roundedNewAngle, radius);
      setDotPosition({ x, y });
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove as any);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove as any);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setCurrentAngle(Math.round(currentAngle));
      setThemeField((draft) => {
        draft.background[mode] = buildGradientString(
          firstColor,
          secondColor,
          Math.round(currentAngle)
        );
      });
      const { x, y } = calculatePosition(currentAngle, radius);
      setDotPosition({ x, y });
    }
  };

  return (
    <div className="flex items-center gap-2">
      <button
        type="button"
        aria-label={t('themes.angle_picker')}
        className="relative flex size-8 shrink-0 cursor-pointer items-center justify-center rounded-full"
        ref={wheelRef}
        onMouseDown={handleMouseDown}
        style={{
          background: `linear-gradient(${angle}deg, ${firstColor}, ${secondColor})`
        }}
      >
        <div
          className={cn('absolute h-1 w-1 rounded-full bg-black', {
            'bg-white': mode === 'dark'
          })}
          style={{
            transform: `translate(${dotPosition.x}px, ${dotPosition.y}px)`
          }}
        />
      </button>
      <Input
        value={currentAngle}
        size="sm"
        type="number"
        min={0}
        max={360}
        className="max-w-17"
        onChange={(e) => setCurrentAngle(Number(e.target.value))}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
