import {
  type BuilderPageColumn,
  type BuilderPageSection,
  type SectionLayoutThreeColumnStructure,
  type SectionLayoutTwoColumnStructure
} from '@/types/schema/BuilderPage';

type GetUpdatedSectionFromLayoutStructureChangeParams = {
  section: BuilderPageSection;
  selectedLayoutStructure: SectionLayoutTwoColumnStructure | SectionLayoutThreeColumnStructure;
};

export function getUpdatedSectionFromLayoutStructureChange({
  section,
  selectedLayoutStructure
}: GetUpdatedSectionFromLayoutStructureChangeParams) {
  if (section.layout.columns === '1' || section.layout.structure === selectedLayoutStructure) {
    return section;
  }

  let newSectionLayout = section.layout;
  let newSectionColumns: BuilderPageColumn[] = [];

  // If the section has two columns, explicitly cast the selected layout structure to a two-column structure
  if (newSectionLayout.columns === '2') {
    newSectionLayout = {
      ...newSectionLayout,
      structure: selectedLayoutStructure as SectionLayoutTwoColumnStructure
    };
  }

  // If the section has two columns, explicitly cast the selected layout structure to a three-column structure
  if (newSectionLayout.columns === '3') {
    newSectionLayout = {
      ...newSectionLayout,
      structure: selectedLayoutStructure as SectionLayoutThreeColumnStructure
    };
  }

  const columnWidths = selectedLayoutStructure
    .split('/')
    .map((width) => `${width}%` as BuilderPageColumn['width']);

  newSectionColumns = section.columns.map((column, index) => ({
    ...column,
    width: columnWidths[index]
  }));

  const updatedSection: BuilderPageSection = {
    ...section,
    layout: newSectionLayout,
    columns: newSectionColumns
  };

  return updatedSection;
}
