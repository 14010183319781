import { useTranslation } from 'react-i18next';

import { type TableView } from '@/types/schema/views/TableView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewPaginationSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewPaginationSettings';
import { ViewSettingsSwitchGroup } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewSettingsSwitchGroup';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function TableGeneralSettingsCategory() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<TableView>();

  const tableUserSettings = [
    {
      key: 'cell_editor',
      label: t('pages.element_settings.table.categories.general_settings.edit_record_inline'),
      checked: view.options?.cell_editor || false
    },
    {
      key: 'keyword_search',
      label: t('pages.element_settings.common.categories.general_settings.keyword_search'),
      checked: view.keyword_search
    },
    {
      key: 'allow_exporting',
      label: t('pages.element_settings.common.categories.general_settings.record_exporting'),
      checked: view.allow_exporting
    },
    {
      key: 'add_record_form',
      label: t('pages.element_settings.table.categories.general_settings.add_record_form'),
      checked: false,
      disabled: true,
      isComingSoon: true
    }
  ];

  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings />

      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.common.categories.general_settings.user_options')}
      >
        <ViewSettingsSwitchGroup options={tableUserSettings} />
      </BuilderAccordion.Item>

      <ViewPaginationSettings />
    </BuilderAccordion>
  );
}
