import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { useGlobalState } from '@/hooks/useGlobalStore';
import { TextTooltip } from '@/components/TextTooltip';

type FormFooterProps = {
  isTargetFieldSelected: boolean;
  handleCancel: () => void;
};

export function FormFooter(props: FormFooterProps) {
  const { isTargetFieldSelected, handleCancel } = props;
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const [t] = useTranslation();
  const {
    formState: { isValid }
  } = useFormContext();

  return (
    <div className="mt-4 flex justify-end gap-2">
      <Button intent="secondary" data-testid="validation-rule-cancel-button" onClick={handleCancel}>
        {t('actions.cancel')}
      </Button>
      {isDraftModeEnabled ? (
        <TextTooltip label={t('components.data_table.disabled_because_draftmode')}>
          <Button disabled data-testid="validation-rule-submit-button">
            {t('actions.save')}
          </Button>
        </TextTooltip>
      ) : (
        <Button
          type="submit"
          disabled={!isTargetFieldSelected || !isValid}
          data-testid="validation-rule-submit-button"
        >
          {t('actions.save')}
        </Button>
      )}
    </div>
  );
}
