import { useTranslation } from 'react-i18next';
import { Checkbox, Label, RadioGroup } from '@knack/asterisk-react';

import { type RepeatOptions } from '@/components/data-table/display/fields/Field';
import { weeklyCheckboxOptionsMap, type DayKey, type SaveDateTimeCellValue } from './types';

interface RepeatsOnOptionsProps {
  repeatOptions?: RepeatOptions;
  saveCell: (value: SaveDateTimeCellValue) => void;
}

export function RepeatsOnOptions({ repeatOptions, saveCell }: RepeatsOnOptionsProps) {
  const [t] = useTranslation();
  const isWeekly = repeatOptions?.frequency === 'weekly';

  const handleSelectWeeklyCheckbox = (day: DayKey) => {
    if (repeatOptions) {
      saveCell({
        repeat: {
          ...repeatOptions,
          [day]: !repeatOptions[day]
        }
      });
    }
  };

  const handleSelectMonthlyOptions = (option: 'dom' | 'dow') => {
    if (repeatOptions) {
      saveCell({
        repeat: {
          ...repeatOptions,
          repeatby: option
        }
      });
    }
  };

  if (isWeekly) {
    return (
      <div className="flex max-w-[400px] flex-wrap gap-2">
        {Object.keys(weeklyCheckboxOptionsMap()).map((day) => {
          const dayOption = day as DayKey;
          return (
            <div key={day} className="flex items-center gap-2">
              <Checkbox
                checked={repeatOptions?.[dayOption]}
                onClick={() => handleSelectWeeklyCheckbox(dayOption)}
              />
              <Label>{weeklyCheckboxOptionsMap()[dayOption]}</Label>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <RadioGroup
        defaultValue="dom"
        onValueChange={handleSelectMonthlyOptions}
        className="flex flex-col"
      >
        <RadioGroup.Container>
          <RadioGroup.Item value="dom" />
          <Label>
            {t('components.data_table.attributes.field_labels.date_time.day_of_the_month')}
          </Label>
        </RadioGroup.Container>
        <RadioGroup.Container>
          <RadioGroup.Item value="dow" />
          <Label>
            {t('components.data_table.attributes.field_labels.date_time.day_of_the_week')}
          </Label>
        </RadioGroup.Container>
      </RadioGroup>
    </div>
  );
}
