import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon } from 'react-icons/hi';
import { HiOutlineArrowUpTray as ImportIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import {
  Button,
  defaultAppTheme,
  DropdownMenu,
  useToast,
  type AppTheme
} from '@knack/asterisk-react';

import { useThemeMutation } from '@/hooks/api/mutations/useThemeMutation';
import { isThemeStructureValid } from '@/pages/themes/themes-list/utils';
import {
  DEFAULT_KNACK_APP_THEME,
  DEFAULT_KNACK_APP_THEME_COLOR_PRESET
} from '@/pages/themes/ThemesPageContext';

export function TopBar() {
  const [t] = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { addMutation } = useThemeMutation();
  const { presentToast } = useToast();

  const handleNewTheme = (theme?: AppTheme) => {
    addMutation.mutate(
      {
        appTheme: theme
          ? {
              ...theme,
              updatedOn: new Date().toISOString()
            }
          : {
              ...defaultAppTheme,
              name: t('themes.my_new_theme'),
              updatedOn: new Date().toISOString(),
              colors: {
                ...defaultAppTheme.colors,
                preset: DEFAULT_KNACK_APP_THEME_COLOR_PRESET
              }
            }
      },
      {
        onError: () => {
          presentToast({
            title: t('themes.error_new_theme')
          });
        }
      }
    );
  };

  const handleThemeUploadError = () => {
    presentToast({
      title: t('themes.error_new_theme')
    });
  };

  const handleThemeUpload = (event) => {
    const file = event.target.files[0];

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const newTheme = JSON.parse(e.target?.result as string);
          if (!isThemeStructureValid(newTheme, DEFAULT_KNACK_APP_THEME)) {
            presentToast({
              title: t('themes.error_incompatible_theme')
            });
            return;
          }
          newTheme.id = 'temp-id'; // The server always generates a new ID
          newTheme.updatedOn = new Date().toISOString();
          handleNewTheme(newTheme);
        } catch (error) {
          handleThemeUploadError();
        }
      };
      reader.onerror = () => handleThemeUploadError();
      reader.readAsText(file);
    } else {
      presentToast({
        title: t('themes.valid_theme_file')
      });
    }

    // Reset the input value to allow re-uploading the same file
    event.target.value = null;
  };

  return (
    <div className="flex items-center justify-between px-6 py-4">
      <h2 className="text-xl font-medium text-emphasis">
        {t('components.layout.left_sidebar.themes')}
      </h2>
      <div className="flex items-center">
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button isLoading={addMutation.isPending}>
              <PlusIcon size={20} className="mr-2" />
              {t('themes.new')}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item onClick={() => handleNewTheme()}>
              <EditIcon size={20} />
              <span>{t('themes.from_scratch')}</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => fileInputRef.current?.click()}>
              <ImportIcon size={20} />
              <span>{t('actions.import')}</span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
        <input
          ref={fileInputRef}
          type="file"
          accept=".json"
          className="hidden"
          id="themeFileInput"
          onChange={handleThemeUpload}
        />
      </div>
    </div>
  );
}
