import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/tailwind';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { SidePanel } from '@/components/SidePanel';
import { HELP_MENU_PANEL, type HelpMenuOption } from './types';

interface HeaderProps {
  helpMenuOptions: Record<string, HelpMenuOption>;
}

export function Header({ helpMenuOptions }: HeaderProps) {
  const { activePanel, activeSupportFormId } = useHelpPanelStore((state) => ({
    activePanel: state.activePanel,
    activeSupportFormId: state.activeSupportFormId
  }));
  const [t] = useTranslation();
  const isMainPanel = activePanel === HELP_MENU_PANEL.main;
  const panelClasses = 'absolute space-y-2 py-4 opacity-0 transition-all duration-500';
  const panelAnimationClasses = 'translate-x-0 opacity-100';

  const activeSupportForm = activeSupportFormId
    ? helpMenuOptions[HELP_MENU_PANEL.support].children?.find(
        (children) => children.id === activeSupportFormId
      )
    : null;

  return (
    <div data-testid="help-menu-side-panel-header">
      <div
        className={cn(panelClasses, '-translate-x-[1000px]', {
          [panelAnimationClasses]: isMainPanel
        })}
      >
        <SidePanel.Title>{t('components.help_menu.learn_and_build')}</SidePanel.Title>
        <SidePanel.Description>
          {t('components.help_menu.everything_you_need')}
        </SidePanel.Description>
      </div>
      <div
        className={cn(panelClasses, 'translate-x-[1000px]', {
          [panelAnimationClasses]: !isMainPanel && !activeSupportFormId,
          '-translate-x-[1000px]': activeSupportFormId
        })}
      >
        <SidePanel.Title>
          {helpMenuOptions[activePanel]?.title || t('components.help_menu.learn_and_build')}
        </SidePanel.Title>
        <SidePanel.Description>
          {helpMenuOptions[activePanel]?.description ||
            t('components.help_menu.everything_you_need')}
        </SidePanel.Description>
      </div>
      <div
        className={cn(panelClasses, 'translate-x-[1000px]', {
          [panelAnimationClasses]: activeSupportFormId
        })}
      >
        <SidePanel.Title>
          {activeSupportForm ? activeSupportForm.title : helpMenuOptions[activePanel]?.title}
        </SidePanel.Title>
        <SidePanel.Description className="max-w-[40ch]">
          {(activeSupportForm && activeSupportForm.description) ||
            helpMenuOptions[activePanel]?.description}
        </SidePanel.Description>
      </div>
    </div>
  );
}
