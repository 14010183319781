import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type ResetPasswordParams = {
  tableKey: string;
  ids: string[];
};

async function resetPassword({ ids, tableKey }: ResetPasswordParams) {
  const endpoint = `/v1/objects/${tableKey}/records/users/passwords`;

  const { data } = await axios.post(endpoint, { ids }, { withCredentials: true });

  return data;
}

export function useResetPasswordMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
