import { useState } from 'react';
import { HiEllipsisHorizontal as EllipsisHorizontalIcon } from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { type PageUrlParams } from '@/Router';

type TableTreeItemProps = {
  table: KnackObject;
  route: string;
  Icon: React.ComponentType<{ size?: number; className?: string }>;
  isSortingDisabled?: boolean;
  dropdownMenuComponent?: React.ComponentType<{
    table: KnackObject;
    onDropdownMenuOpenChange: (isOpen: boolean, tableKey: string) => void;
    triggerElement: React.ReactNode;
  }>;
};

export function TableTreeItem({
  table,
  route,
  Icon,
  isSortingDisabled,
  dropdownMenuComponent: DropdownMenuComponent
}: TableTreeItemProps) {
  const urlParams = useParams<PageUrlParams>();
  const { id: selectedTableKey } = urlParams;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: table.key,
    disabled: isSortingDisabled
  });

  const [isTableDropdownOpen, setIsTableDropdownOpen] = useState(false);
  const isActive = selectedTableKey === table.key;
  const sortableItemStyles = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleDropdownMenuTrigger = (isOpen: boolean) => {
    setIsTableDropdownOpen(isOpen);
  };

  return (
    <div className="group relative" ref={setNodeRef} style={sortableItemStyles}>
      {!isSortingDisabled && (
        <div
          data-testid="tables-rearrange-icon"
          className={cn(
            'invisible absolute left-1 top-2.5 cursor-grab pr-1 align-middle active:cursor-grabbing',
            {
              'group-hover:visible': !transform,
              visible: isDragging
            }
          )}
          {...listeners}
          {...attributes}
        >
          <DragIcon className="size-4" />
        </div>
      )}
      <Link
        to={generatePath(route, { id: table.key })}
        className={cn([
          'flex h-9 w-full items-center justify-between gap-2 rounded-lg p-2 pl-[1.35rem] text-sm',
          {
            'bg-brand-100 text-emphasis': isActive,
            'group-hover:bg-subtle': !isActive && !transform,
            'bg-subtle':
              (transform && isDragging && selectedTableKey !== table.key) ||
              (isTableDropdownOpen && !isActive)
          }
        ])}
      >
        <div
          className={cn('flex max-w-[calc(100%-30px)] items-center gap-2', {
            'max-w-[calc(100%-30px)]': isActive
          })}
          data-testid={`data-item-name-${table.key}`}
        >
          <Icon
            size={20}
            className={cn('text-subtle', {
              'fill-[url(#svg-gradient-1)]': isActive
            })}
          />
          <span className="flex-1 truncate">{table.name}</span>
        </div>
      </Link>
      {DropdownMenuComponent && (
        <DropdownMenuComponent
          table={table}
          onDropdownMenuOpenChange={(isOpen) => handleDropdownMenuTrigger(isOpen)}
          triggerElement={
            <div className="absolute right-1 top-0.5 flex size-8 items-center justify-center gap-2">
              <Button
                intent="minimalStandalone"
                className={cn('invisible size-7', {
                  'group-hover:visible': !transform,
                  'visible focus:bg-brand-100': isActive || isTableDropdownOpen,
                  'hover:bg-emphasis': !isActive,
                  'hover:bg-brand-200': isActive,
                  visible: isDragging
                })}
              >
                <EllipsisHorizontalIcon size={20} />
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}
