import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Label, RadioGroup, Spinner, Textarea, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { useGetHelpMutation } from '@/hooks/api/mutations/useGetHelpMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';

export function GetHelpForm() {
  const { resetPanel } = useHelpPanelStore((state) => ({
    resetPanel: state.resetPanel
  }));
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const presentErrorToast = useErrorToast();
  const { mutate, isPending } = useGetHelpMutation();

  const getHelpFormSchema = z.object({
    helpSubject: z.enum(['my-account', 'my-app', 'how-to-knack']),
    helpQuestion: z.string().min(1)
  });

  type GetHelpFormSchema = z.infer<typeof getHelpFormSchema>;

  const {
    register,
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors }
  } = useForm<GetHelpFormSchema>({
    resolver: zodResolver(getHelpFormSchema),
    defaultValues: {
      helpQuestion: ''
    }
  });

  const onSubmitHandler: SubmitHandler<GetHelpFormSchema> = (data) => {
    mutate(data, {
      onSuccess: () => {
        resetPanel();
        presentToast({
          title: t('components.help_menu.forms.get_help.message_success')
        });
      },
      onError: (error) => {
        presentErrorToast({
          error,
          translationPrefix: 'components.help_menu.forms.get_help',
          fallbackKey: 'components.help_menu.forms.get_help.error_sending_message'
        });
      }
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        id="get-help-form"
        data-testid="get-help-form"
        className="space-y-4 *:space-y-2"
      >
        <div>
          <Label
            className="text-sm font-medium text-default"
            isRequired
            data-testid="get-help-what-is-your-question-label"
          >
            {t('components.help_menu.forms.get_help.what_is_your_question')}
          </Label>
          <Controller
            control={control}
            name="helpSubject"
            render={({ field }) => (
              <RadioGroup onValueChange={field.onChange} className="mt-2" value={field.value}>
                <RadioGroup.Container>
                  <RadioGroup.Item
                    value="my-account"
                    id="account-and-billing"
                    data-testid="get-help-my-account-button"
                  />
                  <Label htmlFor="account-and-billing">
                    {t('components.help_menu.forms.get_help.my_account')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="my-app" id="use-case" />
                  <Label htmlFor="use-case">
                    {t('components.help_menu.forms.get_help.my_app')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="how-to-knack" id="knack-features" />
                  <Label htmlFor="knack-features">
                    {t('components.help_menu.forms.get_help.knack_features')}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            )}
          />
          {errors.helpSubject && (
            <p className="text-sm text-destructive" data-testid="get-help-form-error-message">
              {t('components.help_menu.forms.get_help.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label
            isRequired
            className="text-sm font-medium text-default"
            data-testid="get-help-form-how-we-can-help"
          >
            {t('components.help_menu.forms.get_help.how_we_can_help')}
          </Label>
          <p className="text-sm text-muted" data-testid="get-help-form-helper-text">
            {t('components.help_menu.forms.get_help.helper_text')}
          </p>
          <Textarea
            spellCheck={false}
            {...register('helpQuestion')}
            data-testid="get-help-question-textarea"
          />
          {errors.helpQuestion && (
            <p className="text-sm text-destructive">
              {t('components.help_menu.forms.get_help.this_field_is_required')}
            </p>
          )}
        </div>
      </form>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          intent="minimal"
          disabled={isPending}
          onClick={() => resetForm()}
          data-testid="get-help-form-clear-button"
        >
          {t('actions.clear')}
        </Button>
        <Button
          type="submit"
          form="get-help-form"
          disabled={isPending}
          data-testid="get-help-form-submit-button"
        >
          {isPending ? <Spinner /> : t('actions.submit')}
        </Button>
      </div>
    </div>
  );
}
