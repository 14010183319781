import { isAxiosError, type AxiosError } from 'axios';

import i18n from '@/i18n';

type ServerError = {
  errorCode?: string;
  message: string;
};

type ServerErrorResponse = {
  errors: ServerError[];
  status: number;
};

export type ErrorResponseProps = {
  error: Error | AxiosError | unknown;
  fallbackKey: string;
  translationPrefix?: string;
  i18nOptions?: { [key: string]: string };
};

export function getErrorMessages({
  error,
  fallbackKey,
  translationPrefix,
  i18nOptions
}: ErrorResponseProps) {
  const translatedFallbackErrorMessage = [i18n.t(fallbackKey, i18nOptions)];

  let errorMessages = isAxiosError<ServerErrorResponse>(error)
    ? error.response?.data.errors
    : undefined;

  if (!Array.isArray(errorMessages)) {
    if (
      isAxiosError(error) &&
      typeof error.response?.data === 'object' &&
      error.response?.data.error
    ) {
      errorMessages = [
        { message: error.response.data.error, errorCode: error.response.data.errorCode }
      ];
    } else {
      errorMessages = undefined;
    }
  }

  const errorCode: number | undefined = isAxiosError<ServerErrorResponse>(error)
    ? error.response?.status
    : undefined;

  if ((errorCode === 500 && errorMessages?.length === 0) || !errorMessages) {
    return translatedFallbackErrorMessage;
  }

  const translatedErrorMessages = errorMessages?.map((err) =>
    translationPrefix ? i18n.t(`${translationPrefix}.${err.errorCode}`, i18nOptions) : err.message
  );

  return translatedErrorMessages || translatedFallbackErrorMessage;
}
