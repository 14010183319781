import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Form, Input, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { FieldSelect } from '@/components/FieldSelect';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';
import { DateTimeCriteriaValue } from '@/pages/tables/toolkit-sidebar/rules/DateTimeCriteriaValue';
import { type ConditionalRuleFormSchema } from './conditional-rules/ConditionalRulesForm';
import { conditionalRuleMap, shouldHideOperator } from './conditional-rules/utils';
import { ConnectionInput } from './ConnectionInput';
import {
  ASSET_BYTE_UNITS,
  shouldShowUnitSelector,
  validationRuleMap
} from './validation-rules/utils';
import { type ValidationRuleFormSchema } from './validation-rules/ValidationRuleForm';

interface CriteriaFormProps {
  table: KnackObject;
  defaultValues: ConditionalRuleFormSchema | ValidationRuleFormSchema;
  defaultCriteria:
    | ConditionalRuleFormSchema['criteria'][0]
    | ValidationRuleFormSchema['criteria'][0];
  setShouldRenderForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function isConditionalRuleFormSchema(
  criteria: ConditionalRuleFormSchema['criteria'][0] | ValidationRuleFormSchema['criteria'][0]
): criteria is ConditionalRuleFormSchema['criteria'][0] {
  return (criteria as ConditionalRuleFormSchema['criteria'][0]).value_type !== undefined;
}

export function CustomCriteriaField({ index, table }: { index: number; table: KnackObject }) {
  return (
    <Controller
      name={`criteria.${index}.value_field`}
      render={({ field }) => (
        <FieldSelect
          id="criteria-value-field-select"
          data-testid="criteria-value-field-select"
          fields={table.fields}
          onFieldChange={field.onChange}
          defaultValue={field.value ?? table.fields[0].key}
        />
      )}
    />
  );
}

export function CustomCriteriaValue({
  index,
  field,
  table
}: {
  index: number;
  field: KnackField;
  table: KnackObject;
}) {
  const [t] = useTranslation();
  const { register, getValues } = useFormContext();
  const inputHtmlId = `${field.key}-${field.type}-input`;

  if (field.type === 'date_time') {
    return (
      <div>
        <DateTimeCriteriaValue index={index} targetField={field} />
      </div>
    );
  }

  if (field.type === 'boolean') {
    return (
      <div data-testid="criteria-value-boolean">
        <BooleanInput id={inputHtmlId} targetField={field} name={`criteria.${index}.value`} />
      </div>
    );
  }
  if (field.type === 'multiple_choice') {
    return (
      <div data-testid="criteria-value-multiple-choice">
        <MultipleChoiceInput
          id={inputHtmlId}
          targetField={field}
          name={`criteria.${index}.value`}
        />
      </div>
    );
  }
  if (field.type === 'connection') {
    return (
      <div data-testid="criteria-value-connection">
        <ConnectionInput field={field} name={`criteria.${index}.value`} />
      </div>
    );
  }
  if (field.type === 'user_roles') {
    return (
      <div data-testid="criteria-value-user-roles">
        <UserRolesInput
          name={`criteria.${index}.value`}
          id={inputHtmlId}
          isCriteria
          selectedRole={table}
        />
      </div>
    );
  }
  if (field.type === 'file' || field.type === 'image') {
    return (
      <div className="flex gap-2">
        <Input
          placeholder={t('components.data_table.right_sidebar.conditional_rules.enter_value')}
          {...register(`criteria.${index}.value`)}
        />
        {shouldShowUnitSelector(getValues(`criteria.${index}.operator`)) && (
          <Controller
            name={`criteria.${index}.unit`}
            defaultValue={ASSET_BYTE_UNITS[0]}
            render={({ field: formField }) => (
              <Select
                onValueChange={(unit: string) => {
                  formField.onChange(unit);
                }}
                value={formField.value}
              >
                <Select.Trigger
                  placeholder={t('actions.select')}
                  className="w-full truncate"
                  {...formField}
                />
                <Select.Content>
                  {ASSET_BYTE_UNITS.map((option: string) => (
                    <Select.Item key={option} value={option}>
                      {option}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        )}
      </div>
    );
  }
  return (
    <Input
      placeholder={t('components.data_table.right_sidebar.conditional_rules.enter_value')}
      {...register(`criteria.${index}.value`)}
    />
  );
}

interface CriteriaFormItemProps {
  formField: {
    id: string;
  };
  index: number;
  table: KnackObject;
  defaultValues: ConditionalRuleFormSchema | ValidationRuleFormSchema;
  defaultCriteria:
    | ConditionalRuleFormSchema['criteria'][0]
    | ValidationRuleFormSchema['criteria'][0];
  remove: (index: number) => void;
  fields: Record<'id', string>[];
  setShouldRenderForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function CriteriaFormItem({
  formField,
  index,
  table,
  defaultValues,
  defaultCriteria,
  remove,
  fields,
  setShouldRenderForm
}: CriteriaFormItemProps) {
  const [t] = useTranslation();

  const CONDITIONAL_CRITERIA_VALUE_TYPES = [
    {
      key: 'custom',
      name: t('components.data_table.right_sidebar.conditional_rules.custom_value')
    },
    {
      key: 'field',
      name: t('components.data_table.right_sidebar.conditional_rules.field_value')
    }
  ];

  const getRuleMap = () =>
    isConditionalRuleFormSchema(defaultCriteria) ? conditionalRuleMap : validationRuleMap;

  const { getValues, trigger, watch } = useFormContext();
  const operator = watch(`criteria.${index}.operator`);

  const criteriaFieldType = table.fields.find((f) => f.key === getValues(`criteria.${index}.field`))
    ?.type as KnackField['type'];
  if (!criteriaFieldType) {
    setShouldRenderForm(false);
    return null;
  }

  const shouldRenderCriteriaValue = () => {
    if (shouldHideOperator(operator)) {
      return false;
    }

    return true;
  };

  return (
    <div key={formField.id} className="flex gap-2" data-testid="rule-criteria">
      <div className="min-w-0 flex-1 space-y-2">
        <div className="flex gap-2 whitespace-nowrap">
          <Controller
            name={`criteria.${index}.field`}
            render={({ field }) => (
              <FieldSelect
                id={`criteria-field-select-${index}`}
                data-testid={`criteria-field-select-${index}`}
                fields={table.fields}
                onFieldChange={(e) => {
                  field.onChange(e);
                  void trigger(`criteria.${index}.operator`);
                }}
                defaultValue={field.value}
                {...field}
              />
            )}
          />
          <Controller
            name={`criteria.${index}.operator`}
            render={({ field }) => {
              const ruleMap = getRuleMap();
              if (!ruleMap[criteriaFieldType].operators.includes(field.value)) {
                field.onChange(ruleMap[criteriaFieldType].operators[0]);
              }

              return (
                <Select
                  onValueChange={(e) => {
                    field.onChange(e);
                    void trigger(`criteria.${index}.value`);
                  }}
                  defaultValue={
                    defaultValues.criteria[index]?.operator ??
                    ruleMap[criteriaFieldType].operators[0] ??
                    ''
                  }
                  value={operator}
                >
                  <Select.Trigger
                    id={`operator-select-${index}`}
                    data-testid={`operator-select-${index}`}
                    className="w-full truncate"
                    aria-label={t(
                      isConditionalRuleFormSchema(defaultCriteria)
                        ? 'components.data_table.right_sidebar.conditional_rules.select_condition'
                        : 'components.data_table.right_sidebar.validation_rules.select_condition'
                    )}
                  />
                  <Select.Content>
                    {ruleMap[criteriaFieldType]?.operators?.map((condition: string) => (
                      <Select.Item key={`${condition}-${formField.id}`} value={condition}>
                        {t(`operators.${snakeCase(condition)}`)}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              );
            }}
          />
        </div>
        {shouldRenderCriteriaValue() && (
          <>
            {isConditionalRuleFormSchema(defaultCriteria) && (
              <Controller
                name={`criteria.${index}.value_type`}
                render={({ field }) => (
                  <Select
                    onValueChange={(e) => {
                      field.onChange(e);
                      void trigger(`criteria.${index}.value`);
                    }}
                    defaultValue={defaultCriteria.value_type}
                  >
                    <Select.Trigger
                      id="condition-criteria-value-type-select"
                      placeholder={t('actions.select')}
                      className="w-full truncate"
                      data-testid="criteria-value-type-select"
                      aria-label={t(
                        'components.data_table.right_sidebar.conditional_rules.select_criteria_value_type'
                      )}
                    />
                    <Select.Content>
                      {CONDITIONAL_CRITERIA_VALUE_TYPES.map((value_type) => (
                        <Select.Item
                          key={`${value_type.key}-${formField.id}`}
                          value={value_type.key}
                        >
                          {value_type.name}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            )}
            {(isConditionalRuleFormSchema(defaultCriteria) &&
              getValues(`criteria.${index}.value_type`) === 'custom') ||
            !isConditionalRuleFormSchema(defaultCriteria) ? (
              <div data-testid="custom-criteria-value">
                <CustomCriteriaValue
                  index={index}
                  field={
                    table.fields.find((f) => f.key === getValues(`criteria.${index}.field`)) ??
                    table.fields[0]
                  }
                  table={table}
                />
              </div>
            ) : (
              <CustomCriteriaField index={index} table={table} />
            )}
          </>
        )}
      </div>
      {fields.length > 1 && (
        <Button
          intent="minimalStandalone"
          className="my-2 size-6 shrink-0"
          data-testid="criteria-remove-button"
          onClick={() => remove(index)}
        >
          <RemoveIcon size={16} />
        </Button>
      )}
    </div>
  );
}

export function CriteriaForm({
  table,
  defaultValues,
  defaultCriteria,
  setShouldRenderForm
}: CriteriaFormProps) {
  const [t] = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  });

  return (
    <Form.Section data-testid="criteria-section">
      <Form.Label htmlFor="condition-field-select">{t('components.rules.when')}</Form.Label>
      <div className="space-y-2">
        {fields.map((formField, index) => (
          <CriteriaFormItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            formField={formField}
            index={index}
            table={table}
            defaultValues={defaultValues}
            defaultCriteria={defaultCriteria}
            remove={remove}
            fields={fields}
            setShouldRenderForm={setShouldRenderForm}
          />
        ))}
      </div>
      <Button
        intent="secondary"
        className="mt-4"
        onClick={() => append(defaultCriteria)}
        data-testid="criteria-add-button"
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('components.data_table.right_sidebar.conditional_rules.criteria')}
      </Button>
    </Form.Section>
  );
}
