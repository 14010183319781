import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Input, RichTextEditor } from '@knack/asterisk-react';
import i18n from 'i18next';
import { nanoid } from 'nanoid';

import {
  FORM_VIEW_SECTION_BREAK_INPUT_ID_PREFIX,
  type FormView,
  type FormViewSectionBreakInput
} from '@/types/schema/views/FormView';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

function getDefaultSectionBreakSchema() {
  const sectionBreak: FormViewSectionBreakInput = {
    id: `${FORM_VIEW_SECTION_BREAK_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'section_break',
    label: i18n.t(
      'pages.element_settings.form.categories.form_fields.field_management.rich_text_title'
    ),
    copy: '',
    instructions: ''
  };

  return sectionBreak;
}

interface FormSectionBreakInputEditModalProps {
  onOpenChange: (isOpen: boolean) => void;
  existingInput?: FormViewSectionBreakInput;
}

export function FormSectionBreakInputEditModal({
  onOpenChange,
  existingInput
}: FormSectionBreakInputEditModalProps) {
  const [t] = useTranslation();
  const { updatePage } = usePageEditorContext();
  const { view } = useActiveViewContext<FormView>();

  const [inputSchema, setInputSchema] = useState<FormViewSectionBreakInput>(
    () => existingInput || getDefaultSectionBreakSchema()
  );

  const dialogTitle = existingInput
    ? t('pages.element_settings.form.categories.form_fields.field_management.edit_rich_text')
    : t('pages.element_settings.form.categories.form_fields.field_management.add_rich_text');

  const onSave = () => {
    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: {
        groups: view.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column, columnIndex) => {
            // If an existing input is provided, we need to update it
            if (existingInput) {
              return {
                ...column,
                inputs: column.inputs.map((input) => {
                  if (input.id === existingInput.id) {
                    return inputSchema;
                  }

                  return input;
                })
              };
            }

            // Otherwise, we add a new section break input to the last column
            if (columnIndex === group.columns.length - 1) {
              return { ...column, inputs: [...column.inputs, inputSchema] };
            }

            return column;
          })
        }))
      }
    });
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{dialogTitle}</Dialog.Title>
            <Dialog.Description className="sr-only">{dialogTitle}</Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <div className="mb-4">
              <span className="mb-2 block text-sm font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_title'
                )}
              </span>
              <Input
                value={inputSchema.label}
                onChange={(e) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    label: e.target.value
                  }));
                }}
              />
            </div>
            <div>
              <span className="mb-2 block text-sm font-medium">
                {t(
                  'pages.element_settings.form.categories.form_fields.field_management.rich_text_content'
                )}
              </span>
              <RichTextEditor
                content={inputSchema.copy}
                onUpdate={({ editor }) => {
                  setInputSchema((prev) => ({
                    ...prev,
                    copy: editor.getHTML()
                  }));
                }}
              />
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button onClick={onSave}>{t('actions.add')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
