import { useTranslation } from 'react-i18next';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { EMERGENCY_ALERT_FEATURE_LEVEL } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { Header as HelpMenuHeader } from '@/components/help-menu/Header';
import { MenuItem as HelpMenuItem } from '@/components/help-menu/MenuItem';
import { SubPanel as HelpMenuSubPanel } from '@/components/help-menu/SubPanel';
import { SupportFormsPanel } from '@/components/help-menu/SupportFormsPanel';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { SidePanel } from '@/components/SidePanel';
import ApiCustomizationIcon from '@/assets/svg/api-customization.svg';
import BuilderGuideIcon from '@/assets/svg/builder-guide.svg';
import HireAnExpertIcon from '@/assets/svg/builder-network.svg';
import CommunityIcon from '@/assets/svg/community.svg';
import ConnectRelatedDataIcon from '@/assets/svg/connect-related-data.svg';
import DemosIcon from '@/assets/svg/demos.svg';
import DevDocsIcon from '@/assets/svg/devdocs.svg';
import EmbedAppIcon from '@/assets/svg/embed-app.svg';
import EmergencySupportIcon from '@/assets/svg/emergency-support.svg';
import ExampleAppsIcon from '@/assets/svg/example-apps.svg';
import ExtendIcon from '@/assets/svg/extend.svg';
import FeatureRequestIcon from '@/assets/svg/feature-request.svg';
import FeedbackIcon from '@/assets/svg/feedback.svg';
import FormStackIcon from '@/assets/svg/formstack.svg';
import GetHelpIcon from '@/assets/svg/get-help.svg';
import GetStartedIcon from '@/assets/svg/get-started-support-menu.svg';
import HowDoIIcon from '@/assets/svg/how-do-i.svg';
import HowToGuidesIcon from '@/assets/svg/how-to-guides.svg';
import IntegrationIcon from '@/assets/svg/integrations.svg';
import IntegromatIcon from '@/assets/svg/integromat.svg';
import FAQIcon from '@/assets/svg/kb-faq.svg';
import KnowledgeIcon from '@/assets/svg/kb.svg';
import LearnMoreIcon from '@/assets/svg/learn-more.svg';
import ManageAccountIcon from '@/assets/svg/manage-account.svg';
import ReportBugIcon from '@/assets/svg/report-bug.svg';
import ShowTellIcon from '@/assets/svg/show-tell.svg';
import SupportIcon from '@/assets/svg/support.svg';
import TheBasicIcon from '@/assets/svg/the-basics.svg';
import UserAccessIcon from '@/assets/svg/users-access.svg';
import VideosIcon from '@/assets/svg/webinars.svg';
import WorkFlowIcon from '@/assets/svg/workflow.svg';
import ZapierIcon from '@/assets/svg/zapier.svg';
import {
  HELP_MENU_PANEL,
  SUPPORT_FORM_PANEL,
  type HelpMenuOption,
  type HelpMenuPanel
} from './types';

export function SidePanelContent({ containerRef }) {
  const { data: application } = useApplicationQuery();
  const { activePanel, setActivePanel, activeSupportFormId, setActiveSupportFormId } =
    useHelpPanelStore((state) => ({
      activePanel: state.activePanel,
      activeSupportFormId: state.activeSupportFormId,
      setActiveSupportFormId: state.setActiveSupportFormId,
      setActivePanel: state.setActivePanel
    }));
  const [t] = useTranslation();
  const isEmergencyReportFormEnabled = application
    ? application.account.productPlan.level >= EMERGENCY_ALERT_FEATURE_LEVEL
    : false;

  const panelClasses =
    'absolute w-full -translate-x-[1000px] space-y-4 py-4 opacity-0 transition-all duration-500';

  const helpMenuOptions: Record<Exclude<HelpMenuPanel, 'main'>, HelpMenuOption> = {
    [HELP_MENU_PANEL.getStarted]: {
      icon: GetStartedIcon,
      title: t('components.help_menu.get_started'),
      description: t('components.help_menu.get_started_description'),
      children: [
        {
          icon: TheBasicIcon,
          title: t('components.help_menu.the_basics'),
          description: t('components.help_menu.the_basics_description'),
          link: 'https://learn.knack.com/category/qmonldr5wz-get-started'
        },
        {
          title: t('components.help_menu.connect_related_data'),
          icon: ConnectRelatedDataIcon,
          description: t('components.help_menu.connect_related_data_description'),
          link: 'https://learn.knack.com/article/esitwor45f-connect-related-data'
        },
        {
          title: t('components.help_menu.workflow'),
          icon: WorkFlowIcon,
          description: t('components.help_menu.workflow_description'),
          link: 'https://learn.knack.com/article/pgvj64jx4q-workflow'
        },
        {
          title: t('components.help_menu.user_and_access'),
          icon: UserAccessIcon,
          description: t('components.help_menu.user_and_access_description'),
          link: 'https://learn.knack.com/article/h10rky0k5n-users-access'
        },
        {
          title: t('components.help_menu.how_to_guides'),
          icon: HowToGuidesIcon,
          description: t('components.help_menu.how_to_guides_description'),
          link: 'https://learn.knack.com/category/kts3n67bat-how-to-guides'
        }
      ]
    },
    [HELP_MENU_PANEL.demos]: {
      icon: DemosIcon,
      title: t('components.help_menu.demos'),
      description: t('components.help_menu.demos_description'),
      children: [
        {
          title: t('components.help_menu.template_apps'),
          icon: ExampleAppsIcon,
          description: t('components.help_menu.template_apps_description'),
          link: 'https://www.knack.com/templates'
        },
        {
          title: t('components.help_menu.videos'),
          icon: VideosIcon,
          description: t('components.help_menu.videos_description'),
          link: 'https://www.knack.com/videos/'
        }
      ]
    },
    [HELP_MENU_PANEL.knowledgeBase]: {
      icon: KnowledgeIcon,
      title: t('components.help_menu.knowledge_base'),
      description: t('components.help_menu.knowledge_base_description'),
      children: [
        {
          title: t('components.help_menu.learn_more'),
          icon: LearnMoreIcon,
          description: t('components.help_menu.learn_more_description'),
          link: 'https://learn.knack.com/category/i1wwd7tf9s-learn-more'
        },
        {
          title: t('components.help_menu.builder_guide'),
          icon: BuilderGuideIcon,
          description: t('components.help_menu.builder_guide_description'),
          link: 'https://learn.knack.com/category/0n8x08uh5v-builder-guide'
        },
        {
          title: t('components.help_menu.manage_account'),
          icon: ManageAccountIcon,
          description: t('components.help_menu.manage_account_description'),
          link: 'https://learn.knack.com/category/kgpgiqvqef-manage-your-account'
        },
        {
          title: t('components.help_menu.faq'),
          icon: FAQIcon,
          description: t('components.help_menu.faq_description'),
          link: 'https://learn.knack.com/category/dfstiod2zm-faq'
        }
      ]
    },
    [HELP_MENU_PANEL.extendApp]: {
      icon: ExtendIcon,
      title: t('components.help_menu.extend_your_app'),
      description: t('components.help_menu.extend_your_app_description'),
      children: [
        {
          title: t('components.help_menu.integrations'),
          icon: IntegrationIcon,
          description: t('components.help_menu.integrations_description'),
          collapsibleItems: [
            {
              title: t('components.help_menu.zapier'),
              icon: ZapierIcon,
              description: t('components.help_menu.zapier_description'),
              link: 'https://learn.knack.com/article/t6k2l9zu5m-integrate-with-zapier'
            },
            {
              title: t('components.help_menu.make'),
              icon: IntegromatIcon,
              description: t('components.help_menu.make_description'),
              link: 'https://learn.knack.com/article/kvuz42h257-integrate-with-integromat'
            },
            {
              title: t('components.help_menu.formstack_documents'),
              icon: FormStackIcon,
              description: t('components.help_menu.formstack_documents_description'),
              link: 'https://learn.knack.com/article/6u99kxnk9z-integrate-with-formstack-documents'
            }
          ]
        },
        {
          title: t('components.help_menu.embed'),
          icon: EmbedAppIcon,
          description: t('components.help_menu.embed_description'),
          link: 'https://learn.knack.com/article/jyqtajcj52-embedding-your-app'
        },
        {
          title: t('components.help_menu.dev_docs'),
          icon: DevDocsIcon,
          description: t('components.help_menu.dev_docs_description'),
          link: 'https://www.knack.com/developer-documentation/'
        }
      ]
    },
    [HELP_MENU_PANEL.hireAnExpert]: {
      icon: HireAnExpertIcon,
      title: t('components.help_menu.hire_an_expert'),
      description: t('components.help_menu.hire_an_expert_description'),
      link: 'https://knack.com/experts'
    },
    [HELP_MENU_PANEL.support]: {
      icon: SupportIcon,
      title: t('components.help_menu.support'),
      description: t('components.help_menu.support_description'),
      children: [
        {
          id: SUPPORT_FORM_PANEL.getHelp,
          icon: GetHelpIcon,
          title: t('components.help_menu.get_help'),
          description: t('components.help_menu.get_help_description'),
          onClick: () => setActiveSupportFormId(SUPPORT_FORM_PANEL.getHelp)
        },
        {
          id: SUPPORT_FORM_PANEL.feedback,
          title: t('components.help_menu.give_feedback'),
          icon: FeedbackIcon,
          description: t('components.help_menu.give_feedback_description'),
          onClick: () => setActiveSupportFormId(SUPPORT_FORM_PANEL.feedback)
        },
        {
          id: SUPPORT_FORM_PANEL.reportBug,
          title: t('components.help_menu.report_bug'),
          icon: ReportBugIcon,
          description: t('components.help_menu.report_bug_description'),
          onClick: () => setActiveSupportFormId(SUPPORT_FORM_PANEL.reportBug)
        },
        {
          id: SUPPORT_FORM_PANEL.emergency,
          title: t('components.help_menu.submit_an_emergency_request'),
          icon: EmergencySupportIcon,
          description: t('components.help_menu.submit_an_emergency_request_description'),
          onClick: () => setActiveSupportFormId(SUPPORT_FORM_PANEL.emergency),
          enabled: isEmergencyReportFormEnabled
        }
      ]
    },
    [HELP_MENU_PANEL.community]: {
      icon: CommunityIcon,
      title: t('components.help_menu.community'),
      description: t('components.help_menu.community_description'),
      children: [
        {
          title: t('components.help_menu.how_do_i'),
          icon: HowDoIIcon,
          description: t('components.help_menu.how_do_i_description'),
          link: 'https://forums.knack.com/c/help-center/answers/5'
        },
        {
          title: t('components.help_menu.show_and_tell'),
          icon: ShowTellIcon,
          description: t('components.help_menu.show_and_tell_description'),
          link: 'https://forums.knack.com/c/help-center/no-code-solutions/24'
        },
        {
          title: t('components.help_menu.feature_requests'),
          icon: FeatureRequestIcon,
          description: t('components.help_menu.feature_requests_description'),
          link: 'https://product.knack.com'
        },
        {
          title: t('components.help_menu.api_customization'),
          icon: ApiCustomizationIcon,
          description: t('components.help_menu.api_customization_description'),
          link: 'https://forums.knack.com/c/api-customization/23'
        }
      ]
    }
  };

  return (
    <SidePanel.Content
      position="absolute"
      className="flex w-full max-w-[448px] flex-col overflow-x-hidden bg-menu focus:outline-none md:w-3/4"
      data-testid="help-menu-side-panel-content"
      portalContainer={containerRef.current}
      {...(activePanel !== HELP_MENU_PANEL.main && {
        onBack: () => {
          if (activeSupportFormId) {
            setActiveSupportFormId(null);
            setActivePanel(HELP_MENU_PANEL.support);
          } else {
            setActivePanel(HELP_MENU_PANEL.main);
          }
        }
      })}
    >
      <HelpMenuHeader helpMenuOptions={helpMenuOptions} />
      <div className="relative mt-12">
        <div
          className={cn(panelClasses, {
            'translate-x-0 opacity-100': activePanel === HELP_MENU_PANEL.main
          })}
        >
          {Object.keys(helpMenuOptions).map((key) => {
            const option = helpMenuOptions[key];
            return (
              <HelpMenuItem
                key={option.title}
                onClick={() => setActivePanel(key as HelpMenuPanel)}
                {...option}
              />
            );
          })}
        </div>
        <HelpMenuSubPanel helpMenuOptions={helpMenuOptions} />
        <SupportFormsPanel />
      </div>
    </SidePanel.Content>
  );
}
