import { type WorkBook } from 'xlsx';

export enum DataSource {
  blankTable = 'blank',
  preMade = 'pre-made',
  import = 'import',
  integration = 'integration'
}

export type SelectedDataSource =
  | { type: DataSource.import; file: File; workbook: WorkBook }
  | { type: DataSource.import; file: google.picker.DocumentObject; workbook: WorkBook }
  | { type: DataSource.blankTable }
  | { type: DataSource.preMade }
  | { type: DataSource.integration };

export type GoogleSheetPreview = {
  name: string;
  rowCount: number;
  preview: string[][];
};
