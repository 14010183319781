import { Skeleton } from '@knack/asterisk-react';

import { ConnectionRender } from '@/components/data-table/display/fields/connection/ConnectionRender';
import { CurrencyRender } from '@/components/data-table/display/fields/currency/CurrencyRender';
import { DateTimeRender } from '@/components/data-table/display/fields/date-time/DateTimeRender';
import { DurationRender } from '@/components/data-table/display/fields/duration/DurationRender';
import { FileRender } from '@/components/data-table/display/fields/file/FileRender';
import { NonEditableFieldRender } from '@/components/data-table/display/fields/non-editable-field/NonEditableFieldRender';
import { PasswordRender } from '@/components/data-table/display/fields/password/PasswordRender';
import { PhoneRender } from '@/components/data-table/display/fields/phone/PhoneRender';
import { RatingRender } from '@/components/data-table/display/fields/rating/RatingRender';
import { RichTextRender } from '@/components/data-table/display/fields/rich-text/RichTextRender';
import { SignatureRender } from '@/components/data-table/display/fields/signature/SignatureRender';
import { UserRolesRender } from '@/components/data-table/display/fields/user-roles/UserRolesRender';
import { AddressRender } from './address/AddressRender';
import { BooleanRender } from './boolean/BooleanRender';
import { EmailRender } from './email/EmailRender';
import { type Field, type PartialField } from './Field';
import { ImageRender } from './image/ImageRender';
import { LinkRender } from './link/LinkRender';
import { MultipleChoiceRender } from './multiple-choice/MultipleChoiceRender';
import { NameRender } from './name/NameRender';
import { NumberRender } from './number/NumberRender';
import { ParagraphTextRender } from './paragraph-text/ParagraphTextRender';
import { ShortTextRender } from './short-text/ShortTextRender';

export type FieldRenderProps<T> = {
  isFloating: boolean;
  isEditable: boolean;
} & T;

// Guard to check if field is filled or not
function isFieldFilled(field: Field | PartialField): field is Field {
  return (field as Field).value !== undefined && (field as Field).rawValue !== undefined;
}

const initialSkeletonSizes = ['w-24', 'w-20', 'w-12', 'w-8', 'w-4'];
const shuffledSizesCache: Record<string, string[]> = {};

const shuffleArray = (array: string[]) => array.sort(() => Math.random() - 0.5);

// Returns a random non-consecutive skeleton size
const getSkeletonSize = (fieldId: string) => {
  // Initialize the array with the suffled sizes if it is not already initialised
  if (!shuffledSizesCache[fieldId]) {
    shuffledSizesCache[fieldId] = shuffleArray([...initialSkeletonSizes]);
  }

  // Remove the last one and return it
  const randomSize = shuffledSizesCache[fieldId].pop();

  // If the array is empty, fill it again filtering out the last used size to avoid consecutive sizes
  if (shuffledSizesCache[fieldId].length === 0) {
    shuffledSizesCache[fieldId] = shuffleArray([...initialSkeletonSizes]).filter(
      (size) => size !== randomSize
    );
  }
  return randomSize;
};

export function FieldRender(field: FieldRenderProps<PartialField>) {
  const { type, fieldId, rowId, isFloating } = field;
  // If the field is an empty object, it means its not filled so its loading
  if (!isFieldFilled(field)) {
    // Random size to make the skeleton look more random
    const randomSize = getSkeletonSize(fieldId as string);
    if (type === 'image') {
      return <Skeleton.Image />;
    }
    return (
      <Skeleton
        data-testid={`skeleton-${rowId}-${fieldId}`}
        className={`${randomSize} mr-2 h-3 rounded-full`}
      />
    );
  }

  switch (type) {
    case 'timer':
      return <DurationRender {...field} isFloating={isFloating} />;
    case 'signature':
      return <SignatureRender {...field} isFloating={isFloating} />;
    case 'file':
      return <FileRender {...field} isFloating={isFloating} />;
    case 'date_time':
      return <DateTimeRender {...field} isFloating={isFloating} />;
    case 'currency':
      return <CurrencyRender {...field} isFloating={isFloating} />;
    case 'boolean':
      return <BooleanRender {...field} isFloating={isFloating} />;
    case 'email':
      return <EmailRender {...field} isFloating={isFloating} />;
    case 'address':
      return <AddressRender {...field} isFloating={isFloating} />;
    case 'multiple_choice':
      return <MultipleChoiceRender {...field} isFloating={isFloating} />;
    case 'image':
      return <ImageRender {...field} isFloating={isFloating} />;
    case 'name':
      return <NameRender {...field} isFloating={isFloating} />;
    case 'number':
      return <NumberRender {...field} isFloating={isFloating} />;
    case 'rich_text':
      return <RichTextRender {...field} isFloating={isFloating} />;
    case 'short_text':
      return <ShortTextRender {...field} isFloating={isFloating} />;
    case 'link':
      return <LinkRender {...field} isFloating={isFloating} />;
    case 'paragraph_text':
      return <ParagraphTextRender {...field} isFloating={isFloating} />;
    case 'rating':
      return <RatingRender {...field} isFloating={isFloating} />;
    case 'phone':
      return <PhoneRender {...field} isFloating={isFloating} />;
    case 'connection':
      return <ConnectionRender {...field} isFloating={isFloating} />;
    case 'password':
      return <PasswordRender {...field} isFloating={isFloating} />;

    case 'user_roles':
      return <UserRolesRender {...field} isFloating={isFloating} />;

    case 'concatenation':
    case 'equation':
    case 'auto_increment':
    case 'sum':
    case 'min':
    case 'max':
    case 'average':
    case 'count':
      return <NonEditableFieldRender {...field} isFloating={isFloating} />;

    default:
      return null;
  }
}
