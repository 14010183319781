import { type Rect, type XYPosition } from 'reactflow';
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export type SavedDataModelDiagram = {
  nodes: {
    id: string;
    position: XYPosition;
  }[];
  nodesBounds: Rect;
};

async function getDataModelDiagram(accountSlug: string, appSlug: string) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/data-model/diagram`;
  const { data } = await axios.get<SavedDataModelDiagram>(endpoint, {
    withCredentials: true
  });
  return data;
}

export function useDataModelDiagramQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.dataModelDiagram, accountSlug, appSlug],
    queryFn: () => getDataModelDiagram(accountSlug, appSlug),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  });
}
