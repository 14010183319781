import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { LeftPanelContextProvider } from '@/pages/pages/LeftPanelContext';
import { PageEditorMessagingContextProvider } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { PagesPageContextProvider } from '@/pages/pages/PagesPageContext';
import { LeftPanelContent } from './LeftPanelContent';

export function PagesPage() {
  const [t] = useTranslation();

  const { data: pages, isPending: isPagesQueryPending } = usePagesQuery();

  if (isPagesQueryPending) {
    return null;
  }

  if (!pages) {
    throw new Error('Error fetching pages');
  }

  return (
    <MainLayout>
      <PageEditorMessagingContextProvider>
        <PagesPageContextProvider pages={pages}>
          <ResizableCollapsiblePanel
            title={t('components.layout.left_sidebar.pages')}
            panelContent={
              <LeftPanelContextProvider>
                <LeftPanelContent />
              </LeftPanelContextProvider>
            }
            mainContent={<Outlet />}
            autoSaveId="global-panel"
          />
        </PagesPageContextProvider>
      </PageEditorMessagingContextProvider>
    </MainLayout>
  );
}
