import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields';
import { type KnackCriteria, type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';

export function getInitialIsAnyDate(initialValue: KnackCriteria['value']) {
  if (initialValue && typeof initialValue === 'object' && 'date' in initialValue) {
    return initialValue.date === '';
  }

  return false;
}

export function getInitialIsAnyTime(initialValue: KnackCriteria['value']) {
  if (initialValue && typeof initialValue === 'object' && 'time' in initialValue) {
    return (
      initialValue.time === '' &&
      initialValue.minutes === '' &&
      initialValue.hours === '' &&
      initialValue.am_pm === ''
    );
  }

  return false;
}

export function getNormalizedDateTimeCriteriaValue(
  criteriaDateTimeValue: KnackCriteriaDateTimeValue
) {
  const { hours, minutes } = criteriaDateTimeValue;

  // Ensure that the hours and minutes are numbers
  const normalizedSelectedDateTimeValue: KnackCriteriaDateTimeValue = {
    ...criteriaDateTimeValue,
    hours: typeof hours === 'string' && hours !== '' ? Number(hours) : hours,
    minutes: typeof minutes === 'string' && minutes !== '' ? Number(minutes) : minutes
  };

  return normalizedSelectedDateTimeValue;
}

export function getCriteriaTimeString(
  criteriaDateTimeValue: KnackCriteriaDateTimeValue,
  dateTimeFieldFormat: DateTimeFieldFormat
) {
  if (
    criteriaDateTimeValue.hours === undefined ||
    criteriaDateTimeValue.minutes === undefined ||
    criteriaDateTimeValue.hours === '' ||
    criteriaDateTimeValue.minutes === ''
  ) {
    return '';
  }

  if (dateTimeFieldFormat.time_format === 'HH MM (military)') {
    return DateTime.fromObject({
      hour:
        typeof criteriaDateTimeValue.hours === 'number' ? criteriaDateTimeValue.hours : undefined,
      minute:
        typeof criteriaDateTimeValue.minutes === 'number'
          ? criteriaDateTimeValue.minutes
          : undefined
    })
      .toFormat('HH:mm')
      .toUpperCase();
  }

  return DateTime.fromObject({
    hour: typeof criteriaDateTimeValue.hours === 'number' ? criteriaDateTimeValue.hours : undefined,
    minute:
      typeof criteriaDateTimeValue.minutes === 'number' ? criteriaDateTimeValue.minutes : undefined
  })
    .toFormat('hh:mma')
    .toUpperCase();
}

export function getUpdatedCriteriaDateTimeValueOnTimeChange(
  dateTimeFormat: DateTimeFieldFormat,
  dateTimeValue: KnackCriteriaDateTimeValue,
  newTimeString: string
) {
  // If the time format is military, the time string is already in the correct format
  if (dateTimeFormat.time_format === 'HH MM (military)') {
    const [hours, minutes] = newTimeString.split(':');

    return {
      ...dateTimeValue,
      hours: Number(hours),
      minutes: Number(minutes),
      time: newTimeString
    };
  }

  // The new time string needs to be formatted from `hh:mm` to `h:mma`. Example: 04:00 -> 4:00am
  const newTimeFormattedString = DateTime.fromFormat(
    `${newTimeString} ${dateTimeValue.am_pm || 'AM'}`,
    'hh:mm a'
  )
    .toFormat('h:mma')
    .toLowerCase();

  // Parse the time string and convert it to 24-hour format
  const timeInMilitary = DateTime.fromFormat(
    `${newTimeString} ${dateTimeValue.am_pm || 'AM'}`,
    'hh:mm a'
  ).toFormat('H:mm');

  const [hours, minutes] = timeInMilitary.split(':');

  return {
    ...dateTimeValue,
    hours: Number(hours),
    minutes: Number(minutes),
    time: newTimeFormattedString,
    am_pm: dateTimeValue.am_pm || 'AM'
  };
}

export function getUpdatedCriteriaDateTimeValueOnTimePeriodChange(
  dateTimeValue: KnackCriteriaDateTimeValue,
  newPeriodValue: KnackCriteriaDateTimeValue['am_pm']
) {
  if (
    !newPeriodValue ||
    typeof dateTimeValue.hours !== 'number' ||
    typeof dateTimeValue.minutes !== 'number'
  ) {
    return dateTimeValue;
  }

  const hoursInMilitary =
    newPeriodValue === 'AM' ? dateTimeValue.hours % 12 : (dateTimeValue.hours % 12) + 12;

  // The new time string needs to be formatted from `hh:mm` to `h:mma`. Example: 04:00 -> 4:00am
  const newTimeFormattedString = DateTime.fromObject({
    hour: hoursInMilitary,
    minute: dateTimeValue.minutes
  })
    .toFormat('h:mma')
    .toLowerCase();

  return {
    ...dateTimeValue,
    hours: hoursInMilitary,
    time: newTimeFormattedString,
    am_pm: newPeriodValue
  };
}

export function isManualDateInputValid(inputValue: string) {
  // This regex does the following:
  // - Allows only numbers and slashes
  // - Disallows a double slash
  const regex = /^(?!.*\/\/)[0-9/]*\/?$/;
  return regex.test(inputValue);
}
