import { Controller } from 'react-hook-form';
import { Select } from '@knack/asterisk-react';

import { type DateTimeRangeType } from '@/types/schema/fields/DateTimeField';

export function FilterDateTimeRangeInput({
  formFieldName,
  operator
}: {
  formFieldName: string;
  operator: string;
}) {
  const days = Array.from(Array(31), (_, i) => (i + 1).toString());
  const dateTimeFieldRangeTypes: DateTimeRangeType[] = [
    'days',
    'weeks',
    'months',
    'years',
    'rolling weeks',
    'rolling months',
    'rolling years'
  ];
  const specificDateTimeFieldRanges: DateTimeRangeType[] = ['week', 'month', 'quarter', 'year'];

  if (operator === 'is during the current') {
    return (
      <Controller
        name={`${formFieldName}.type`}
        defaultValue={specificDateTimeFieldRanges[0]}
        render={({ field }) => (
          <Select value={field.value} onValueChange={(val) => field.onChange(val)}>
            <Select.Trigger className="w-full max-w-[120px]" />
            <Select.Content>
              {specificDateTimeFieldRanges.map((type) => (
                <Select.Item key={type} value={type}>
                  {type}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    );
  }

  return (
    <div className="flex w-full gap-2">
      <Controller
        name={`${formFieldName}.range`}
        defaultValue={days[0]}
        render={({ field }) => (
          <Select value={field.value} onValueChange={(val) => field.onChange(val)}>
            <Select.Trigger className="w-24" />
            <Select.Content>
              {days.map((day) => (
                <Select.Item key={day} value={day}>
                  {day}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      <Controller
        name={`${formFieldName}.type`}
        defaultValue={dateTimeFieldRangeTypes[0]}
        render={({ field }) => (
          <Select value={field.value} onValueChange={(val) => field.onChange(val)}>
            <Select.Trigger defaultValue="1" className="w-full" />
            <Select.Content className="min-w-[140px]">
              {dateTimeFieldRangeTypes.map((type) => (
                <Select.Item key={type} value={type}>
                  {type}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
