import { useEffect } from 'react';

import { getApplicationBasePathSegments } from '@/utils/application';
import { cn } from '@/utils/tailwind';
import { type ScreenSize } from '@/components/LiveAppPreview';

interface LiveAppFrameProps {
  pagePath: string;
  registerIFrame: (iFrameElement: HTMLIFrameElement) => void;
  screenSize?: ScreenSize;
  isPreviewMode?: boolean;
}

// This hook is used to observe the DOM for Radix Popper wrappers (used for dropdowns, tooltips, popovers, etc.) being added or removed, and disables the pointer events on the Live App iframe when a Radix Popper is present.
// This is necessary because clicks inside the iframe are not propagated to the parent document, which means that the parent page can't detect if a click occurred "outside" of dropdowns or tooltips in order to close them
function useRadixPopperObserver() {
  const RADIX_POPPER_CONTENT_WRAPPER_SELECTOR = '[data-radix-popper-content-wrapper]';
  const LIVE_APP_FRAME_SELECTOR = '[data-live-app-frame]';

  useEffect(() => {
    const targetNode: HTMLElement = document.body;

    const callback: MutationCallback = (mutationsList) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Check when a new Radix Popper content wrapper is added to the DOM (as a direct child of `body`)
          if (mutation.addedNodes.length) {
            mutation.addedNodes.forEach((node) => {
              if (
                node instanceof HTMLElement &&
                node.matches(RADIX_POPPER_CONTENT_WRAPPER_SELECTOR)
              ) {
                const liveAppFrameElement = document.querySelector(LIVE_APP_FRAME_SELECTOR);
                if (liveAppFrameElement) {
                  (liveAppFrameElement as HTMLElement).style.pointerEvents = 'none';
                }
              }
            });
          }

          // Check when a Radix Popper content wrapper is removed from the DOM
          if (mutation.removedNodes.length) {
            mutation.removedNodes.forEach((node) => {
              if (
                node instanceof HTMLElement &&
                node.matches(RADIX_POPPER_CONTENT_WRAPPER_SELECTOR)
              ) {
                const liveAppFrameElement = document.querySelector(LIVE_APP_FRAME_SELECTOR);
                if (liveAppFrameElement) {
                  (liveAppFrameElement as HTMLElement).style.pointerEvents = 'auto';
                }
              }
            });
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, { childList: true });

    // Disconnect the observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);
}

export function LiveAppFrame({
  pagePath,
  registerIFrame,
  screenSize,
  isPreviewMode = false
}: LiveAppFrameProps) {
  useRadixPopperObserver();

  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return (
    <div
      data-live-app-frame
      className={cn('h-full w-full bg-subtle', {
        'py-8': screenSize && screenSize.type !== 'desktop',
        'h-[calc(100%-theme(spacing.16))]': isPreviewMode
      })}
    >
      <iframe
        ref={(iFrameElement) => {
          if (!iFrameElement) return;
          registerIFrame(iFrameElement);
        }}
        src={`${import.meta.env.PUBLIC_LIVE_APP_URL}/${accountSlug}/${appSlug}/${pagePath}${isPreviewMode ? '?preview=true' : ''}`}
        title="Live App"
        className={cn('mx-auto size-full', {
          'rounded-md': screenSize && screenSize.type !== 'desktop'
        })}
        style={{ maxWidth: screenSize?.maxWidth ?? 'none' }}
      />
    </div>
  );
}
