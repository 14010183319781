import { nanoid } from 'nanoid';

import { type BuilderPageColumn, type BuilderPageSection } from '@/types/schema/BuilderPage';
import { NEW_SECTION_ID_PREFIX } from './constants';
import { generateNewColumn } from './generate-new-column';

type GenerateNewSectionOptions = {
  columns?: BuilderPageColumn[];
};

export function generateNewSection({ columns }: GenerateNewSectionOptions = {}) {
  const newSection: BuilderPageSection = {
    id: `${NEW_SECTION_ID_PREFIX}${nanoid(10)}`,
    columns: columns ?? [generateNewColumn()],
    layout: {
      columns: '1',
      structure: '100'
    }
  };

  return newSection;
}
