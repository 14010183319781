import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, RadioGroup, Switch } from '@knack/asterisk-react';

import { IMAGE_RESIZE_TYPE_OPTIONS, type ImageField } from '@/types/schema/fields/ImageField';

type ResizeImageProps = {
  field: ImageField;
};

const DEFAULT_MAX_DIMENSIONS = 1500;

export function ResizeImage({ field }: ResizeImageProps) {
  const [t] = useTranslation();
  const [enableResizeImage, setEnableResizeImage] = useState(
    !!field?.format?.resize?.type || false
  );

  const { setValue, getValues } = useFormContext();

  const resize = getValues('format.resize');
  const hasCustomResizeType = enableResizeImage && resize?.type === 'custom';
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <Switch
          id="resize-image-switch"
          className="mr-2"
          data-testid="resize-image-value-switch"
          checked={enableResizeImage}
          onCheckedChange={(checked) => {
            setEnableResizeImage(checked);
            if (!checked) {
              setValue('format.resize', undefined);
            } else {
              setValue('format.resize', {
                type: 'web-friendly'
              });
            }
          }}
        />
        <Label htmlFor="resize-image-switch">
          {t('components.data_table.attributes.field_settings.image.enable_resize_image_label')}
        </Label>
      </div>

      {enableResizeImage && (
        <div className="flex flex-col gap-2">
          <Label>
            {t('components.data_table.attributes.field_settings.image.resize_dimensions_label')}
          </Label>
          <RadioGroup
            onValueChange={(value) => {
              if (value === 'custom') {
                setValue('format.resize', {
                  type: value,
                  maxWidth: DEFAULT_MAX_DIMENSIONS,
                  maxHeight: DEFAULT_MAX_DIMENSIONS
                });
              } else {
                setValue('format.resize', {
                  type: value
                });
              }
            }}
            defaultValue={resize?.type}
            className="space-y-1"
          >
            {IMAGE_RESIZE_TYPE_OPTIONS.map((option) => (
              <RadioGroup.Container key={option} className="flex gap-3">
                <RadioGroup.Item
                  id={option}
                  value={option}
                  data-testid={`image-field-settings-resize-value-${option}`}
                />
                <Label htmlFor={option} className="font-normal">
                  {t(
                    `components.data_table.attributes.field_settings.image.resize_options.${option}`
                  )}
                </Label>
              </RadioGroup.Container>
            ))}
          </RadioGroup>
        </div>
      )}

      {hasCustomResizeType && (
        <div className="flex gap-4">
          <div className="flex flex-col gap-2">
            <Label htmlFor="max-width">
              {t(
                'components.data_table.attributes.field_settings.image.resize_custom_max_width_label'
              )}
            </Label>
            <Input
              id="max-width"
              type="number"
              className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              data-testid="resize-image-max-width"
              onChange={(e) => {
                setValue('format.resize', {
                  ...resize,
                  maxWidth: Number(e.target.value)
                });
              }}
              value={resize?.maxWidth}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="max-height">
              {t(
                'components.data_table.attributes.field_settings.image.resize_custom_max_height_label'
              )}
            </Label>
            <Input
              id="max-height"
              type="number"
              className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              data-testid="resize-image-max-height"
              onChange={(e) => {
                setValue('format.resize', {
                  ...resize,
                  maxHeight: Number(e.target.value)
                });
              }}
              value={resize?.maxHeight}
            />
          </div>
        </div>
      )}
    </div>
  );
}
