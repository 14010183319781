import { useEffect } from 'react';
import { IMask, useIMask } from 'react-imask';
import { Input, Select } from '@knack/asterisk-react';

import { type DateTimeField } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';

interface TimeInputProps {
  value: string;
  className?: string;
  children?: React.ReactNode;
  format: DateTimeField['format']['time_format'];
  disabled?: boolean;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
  onValueChange: (value: string) => void;
}

interface TimeModeSelectProps {
  value: 'AM' | 'PM';
  className?: string;
  disabled?: boolean;
  onValueChange: (value: 'AM' | 'PM') => void;
}

function TimeInput({
  value,
  className,
  inputProps,
  format,
  children,
  disabled,
  onValueChange
}: TimeInputProps) {
  const is12HourFormat = format === 'HH:MM am';
  const {
    ref: inputRef,
    value: iMaskValue,
    setValue
  } = useIMask<HTMLInputElement>(
    {
      mask: 'HH:MM',
      lazy: false,

      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: is12HourFormat ? 12 : 23
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        }
      }
    },
    {
      defaultValue: value,
      onComplete: (val) => {
        onValueChange(val);
      }
    }
  );

  const handleOnBlurPlaceholderReplace = () => {
    if (iMaskValue.includes('_')) {
      const newValue = iMaskValue.replace(/_/g, '0');
      setValue(newValue);
      onValueChange(newValue);
    }
  };

  // Update the iMask value whenever `value` prop changes
  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  return (
    <Input.Container className="flex">
      <Input
        disabled={disabled}
        ref={inputRef}
        defaultValue={value}
        className={cn('w-20 rounded-lg px-3 py-2 text-sm hover:z-10 focus:z-10', className, {
          'rounded-r-none': children
        })}
        onBlur={handleOnBlurPlaceholderReplace}
        {...inputProps}
      />
      {children}
    </Input.Container>
  );
}

function TimeModeSelect({ value, className, disabled, onValueChange }: TimeModeSelectProps) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <Select.Trigger
        disabled={disabled}
        shouldHideChevron
        className={cn(
          '-ml-0.5 min-w-10 justify-center rounded-lg rounded-l-none p-1 text-sm [&>*:last-child]:ml-0',
          className
        )}
      />
      <Select.Content className="min-w-20">
        <Select.Item value="AM">AM</Select.Item>
        <Select.Item value="PM">PM</Select.Item>
      </Select.Content>
    </Select>
  );
}

const CompoundTimeInput = Object.assign(TimeInput, {
  TimeModeSelect
});

TimeModeSelect.displayName = 'TimeInput.TimeModeSelect';

export { CompoundTimeInput as TimeInput };
