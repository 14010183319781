import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, Spinner } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation, type NewPagePayload } from '@/hooks/api/mutations/usePageMutation';
import { cn } from '@/utils/tailwind';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { getNewPageDefaultName } from './getNewPageDefaultName';

export function AddNewPageButton({
  parentPage,
  isUserPage = false
}: {
  parentPage?: BuilderPage;
  isUserPage?: boolean;
}) {
  const [t] = useTranslation();
  const { createMutation, updateDropdownMutation } = usePageMutation();
  const { allPages, hasAppSingleLoginPage, appSingleLoginPage } = useLeftPanelContext();

  const createNewPage = () => {
    const newPagePayload: NewPagePayload = {
      type: isUserPage ? 'user' : 'page',
      name: getNewPageDefaultName(isUserPage ? 'user' : 'page')
    };

    if (hasAppSingleLoginPage && appSingleLoginPage) {
      newPagePayload.parent = appSingleLoginPage.slug;
    }

    createMutation.mutate(newPagePayload, {
      onSuccess: (response) => {
        if (parentPage?.type === 'menu') {
          const foundPages = allPages?.filter((item) => item.menuPageKey === parentPage.key);
          const newMenuPages = foundPages?.map((item) => item.key) || [];
          newMenuPages.push(response.scene.key);
          updateDropdownMutation.mutate({
            pageKey: parentPage.key,
            name: parentPage.name,
            menuPages: newMenuPages
          });
        }
      }
    });
  };

  return (
    <Button
      intent="minimalStandalone"
      className={cn('mb-1', {
        'ml-11': parentPage,
        'ml-5': !parentPage
      })}
      onClick={() => createNewPage()}
    >
      {createMutation.isPending ? (
        <Spinner />
      ) : (
        <>
          <Button.Icon position="left" icon={PlusIcon} />
          {t('keywords.new')}
        </>
      )}
    </Button>
  );
}
