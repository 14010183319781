import { AnalyticsBrowser } from '@segment/analytics-next';

import { useSession } from './useSession';

type EventPayloadsMapping = {
  object_add: {
    object_name: string;
    object_id: string;
    object_origin: 'from_scratch' | 'template' | 'import';
    object_template?: string;
  };
};

type IdentifyPayload = {
  email: string;
};

type TrackLinkEventPayload = {
  'Clicked Action': {
    account_id: string;
    application_id: string;
    location: string;
    text: string;
  };
};

// Load analytics only if we have the API key defined
export const analytics = import.meta.env.PUBLIC_SEGMENT_API_KEY
  ? AnalyticsBrowser.load({ writeKey: import.meta.env.PUBLIC_SEGMENT_API_KEY })
  : undefined;

export const isTestingEmail = (email: string) => {
  if (typeof email !== 'string') {
    return false;
  }

  // By default we don't want to track knack emails
  if (import.meta.env.PUBLIC_SEGMENT_TRACK_KNACK_EMAILS !== 'true') {
    const isKnackEmail = email.includes('@knack.com') || email.includes('@knackhq.com');
    if (isKnackEmail) {
      return true;
    }
  }

  const testEmailPrefixes = [
    'dev+appbuilder',
    'dev+cypress-',
    'dev+apiwrappertests-',
    'dev+benchmarktests-',
    'dev+playwright-',
    'dev.playwright.'
  ];

  return testEmailPrefixes.some((testEmailPrefix) => email.startsWith(testEmailPrefix));
};

export const useSegment = () => {
  const session = useSession();

  if (!session.user.email || isTestingEmail(session.user.email) || !analytics) {
    return undefined;
  }

  return {
    track: async <T extends keyof EventPayloadsMapping>(
      eventKey: T,
      eventPayload: EventPayloadsMapping[T]
    ) => {
      await analytics.track(eventKey, eventPayload);
    },
    trackLink: async <T extends keyof TrackLinkEventPayload>(
      link: Element | Element[],
      event: T,
      properties: TrackLinkEventPayload[T]
    ) => {
      await analytics.trackLink(link, event, properties);
    },
    identify: async (userId: string, payload: IdentifyPayload) => {
      await analytics.identify(userId, payload);
    },
    reset: async () => {
      await analytics.reset();
    }
  };
};
