import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { type TaskHistoryData } from '@/types/schema/tasks/KnackTask';
import { type TASK_HISTORY_ROWS_PER_PAGE_OPTIONS } from '@/pages/tables/toolkit-sidebar/tasks/constants';

type TaskHistoryContextState = {
  rowsPerPage: RowsPerPage;
  currentHistoryPage: number;
  currentHistoryData: TaskHistoryData[];
  totalHistoryPageNumber: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: RowsPerPage) => void;
} | null;

type TaskHistoryContextProviderProps = {
  children: React.ReactNode;
  taskHistoryData: TaskHistoryData[];
};

export type RowsPerPage = (typeof TASK_HISTORY_ROWS_PER_PAGE_OPTIONS)[number];

const TaskHistoryContext = createContext<TaskHistoryContextState>(null);

const INITIAL_PAGE = 1;
const INITIAL_ROWS_PER_PAGE = 10;

export function TaskHistoryContextProvider({
  children,
  taskHistoryData
}: TaskHistoryContextProviderProps) {
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPage>(INITIAL_ROWS_PER_PAGE);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(INITIAL_PAGE);
  const [currentHistoryData, setCurrentHistoryData] = useState(
    taskHistoryData.slice(0, rowsPerPage)
  );

  useEffect(() => {
    setCurrentHistoryData(taskHistoryData.slice(0, rowsPerPage));
  }, [taskHistoryData, rowsPerPage]);

  const totalHistoryPageNumber = Math.ceil(taskHistoryData.length / rowsPerPage);

  const onPageChange = (page: number) => {
    setCurrentHistoryPage(page);
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setCurrentHistoryData(taskHistoryData.slice(start, end));
  };

  const onRowsPerPageChange = (rowsPerPageValue: RowsPerPage) => {
    setRowsPerPage(rowsPerPageValue);
    setCurrentHistoryPage(INITIAL_PAGE);
    setCurrentHistoryData(taskHistoryData.slice(0, rowsPerPage));
  };

  const contextValue = useMemo(
    () => ({
      rowsPerPage,
      currentHistoryPage,
      currentHistoryData,
      totalHistoryPageNumber,
      onPageChange,
      onRowsPerPageChange
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, currentHistoryPage, currentHistoryData, totalHistoryPageNumber]
  );

  return <TaskHistoryContext.Provider value={contextValue}>{children}</TaskHistoryContext.Provider>;
}

export const useTaskHistoryContext = () => {
  const context = useContext(TaskHistoryContext);
  if (!context) {
    throw new Error('useTaskHistoryContext must be used within a TaskHistoryContext');
  }
  return context;
};
