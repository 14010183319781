import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { ColorOptions, ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function Contrast() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  const contrastItems: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.monochromatic')}</span>,
      value: 'monochromatic',
      isDisabled: !hasAccessToNonPublicFeatures
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.high_contrast')}</span>,
      value: 'high-contrast',
      isDisabled: !hasAccessToNonPublicFeatures
    }
  ];

  return (
    <div className="my-4">
      <div className="mb-2 text-sm">
        {`${t('pages.element_settings.report.categories.general.contrast')} (Coming Soon)`}
      </div>
      <Tabs
        defaultValue="monochromatic"
        onValueChange={(value) => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].layout.colors = value as ColorOptions;
          updateViewSchema(newSchema);
        }}
      >
        <Tabs.List items={contrastItems} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
