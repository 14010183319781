import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface TaskRunModalProps {
  onOpenChange: (isOpen: boolean) => void;
  handleRunTask: () => void;
}

export function TaskRunModal({ onOpenChange, handleRunTask }: TaskRunModalProps) {
  const [t] = useTranslation();

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="run-task-modal">
        <Dialog.MainContent className="pb-0">
          <Dialog.Header>
            <Dialog.Title>
              {t('components.data_table.right_sidebar.tasks.run_task_manually.title')}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>{t('components.data_table.right_sidebar.tasks.run_task_manually.description')}</p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={handleRunTask} data-testid="confirm-run-task-button">
            {t('components.data_table.right_sidebar.tasks.run_task_manually.run_task')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
