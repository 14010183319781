import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type KnackField } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { FieldRow } from './FieldRow';
import { useFieldsStore } from './useFieldsStore';

export type FieldProps = {
  field: KnackField;
  index: number;
};

export function Field({ field, index }: FieldProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: field.key
  });

  const sortableItemStyles = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const visibleFields = useFieldsStore().use.visibleFields();

  // If it's the only field in the table, we don't need dnd behavior
  if (visibleFields.length === 1) {
    return <FieldRow field={field} index={index} />;
  }

  return (
    <div className="group relative" ref={setNodeRef} style={sortableItemStyles}>
      <div
        data-testid={`field-rearrange-icon-${field.key}`}
        className="group-hover:visible"
        {...listeners}
        {...attributes}
      >
        <DragIcon
          size={20}
          className={cn(
            'invisible absolute left-0.5 top-2.5 cursor-grab active:cursor-grabbing group-hover:bg-brand-muted',
            {
              'group-hover:visible': !transform,
              visible: isDragging
            }
          )}
        />
      </div>
      <FieldRow field={field} index={index} isDraggingRow={isDragging} />
    </div>
  );
}
