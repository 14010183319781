import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Calendar } from '@knack/asterisk-react';
import { DateTime } from 'luxon';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CellError } from '@/components/data-table/display/fields/date-time/components/CellError';
import { Time } from '@/components/data-table/display/fields/date-time/components/Time';
import {
  formatDate,
  getDateFormat,
  getFormattedDateForInput,
  getUTCFromRawValue
} from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import {
  getAmPmFormatFrom24HourTime,
  getFormattedTimeForInput,
  getHoursFromTime,
  getMinutesFromTime
} from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import { getDurationSchema } from '@/components/data-table/display/fields/duration/DurationSchema';
import {
  type DurationField as DurationFieldDisplay,
  type DurationRawValue,
  type DurationTimes
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import {
  DAY_PICKER_FROM_YEAR,
  DAY_PICKER_TO_YEAR
} from '@/components/data-table/helpers/constants';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type SaveDurationCellValue = {
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
};

export function DurationEdit({
  fieldId,
  rawValue,
  rowId,
  type
}: FieldRenderProps<DurationFieldDisplay>) {
  const [t] = useTranslation();

  const useDataTableState = useDataTableStore();
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const durationRange = rawValue?.times?.[0];

  const [startDateAmPmTimeFormat, setStartDateAmPmTimeFormat] = useState(
    durationRange?.from?.am_pm || 'AM'
  );
  const [endDateAmPmTimeFormat, setEndDateAmPmTimeFormat] = useState(
    durationRange?.to?.am_pm || 'AM'
  );

  const [isEditingEndDate, setIsEditingEndDate] = useState(false);

  const { moveSelectionVertical } = useSelectionStrategy();
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { date_format: dateFormat, time_format: timeFormat } = currentField.format;
  const hasDate = dateFormat !== 'Ignore Date';
  const hasTime = timeFormat !== 'Ignore Time';
  const hasAmPmTimeFormat = timeFormat === 'HH:MM am';

  const durationFormSchema = getDurationSchema(currentField);

  type DurationFormSchema = z.infer<typeof durationFormSchema>;

  const formMethods = useForm<DurationFormSchema>({
    resolver: zodResolver(durationFormSchema),
    defaultValues: {
      startDate: durationRange?.from.iso_timestamp
        ? getFormattedDateForInput(durationRange?.from.iso_timestamp, dateFormat) || ''
        : '',
      startTime: durationRange?.from?.iso_timestamp
        ? getFormattedTimeForInput(durationRange?.from.iso_timestamp, timeFormat) || ''
        : '',

      endDate: durationRange?.to?.iso_timestamp
        ? getFormattedDateForInput(durationRange?.to?.iso_timestamp, dateFormat) || ''
        : '',
      endTime: durationRange?.to?.iso_timestamp
        ? getFormattedTimeForInput(durationRange?.to.iso_timestamp, timeFormat) || ''
        : ''
    },
    mode: 'onChange',
    shouldFocusError: false
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = formMethods;

  const hasErrors = Object.keys(errors).length > 0 || !!cellErrors?.length;

  const { refs: startDateRef, floatingStyles: startDateFloatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.maxHeight = '100%';
            elements.floating.style.maxWidth = '100%';
            return;
          }

          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const { refs: endDateRef, floatingStyles: endDateFloatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.maxHeight = '100%';
            elements.floating.style.maxWidth = '100%';
            return;
          }

          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const saveValue = useCallback(
    async (dateTime: SaveDurationCellValue) => {
      const updates = {
        times: [
          {
            from: {
              date: dateTime.startDate,
              time: dateTime.startTime,
              am_pm: startDateAmPmTimeFormat
            },
            to: {
              date: dateTime.endDate,
              time: dateTime.endTime,
              am_pm: endDateAmPmTimeFormat
            }
          } as DurationTimes
        ]
      };
      const updatedData = { ...rawValue, ...updates } as DurationRawValue;

      const { startDate, startTime, endDate, endTime } = dateTime;

      const timesRange = updates?.times?.[0];

      if (!timesRange.from) {
        timesRange.from = {}; // Initialize `from` as an empty object if it's not already
      }

      if (!timesRange.to) {
        timesRange.to = {}; // Initialize `to` as an empty object if it's not already
      }

      if (!startDate) {
        timesRange.from.date = getValues('startDate');
      }

      if (!endDate) {
        timesRange.to.date = getValues('endDate');
      }

      if (!startTime) {
        timesRange.from.hours = getHoursFromTime(getValues('startTime') || '');
        timesRange.from.minutes = getMinutesFromTime(getValues('startTime') || '');
      }

      if (!endTime) {
        timesRange.to.hours = getHoursFromTime(getValues('endTime') || '');
        timesRange.to.minutes = getMinutesFromTime(getValues('endTime') || '');
      }

      if (startTime) {
        timesRange.from.hours = getHoursFromTime(startTime);
        timesRange.from.minutes = getMinutesFromTime(startTime);
      }

      if (endTime) {
        timesRange.to.hours = getHoursFromTime(endTime);
        timesRange.to.minutes = getMinutesFromTime(endTime);
      }

      // Do not include the offset. That way we ensure that the date is in the 0 timezone
      timesRange.from.iso_timestamp =
        getUTCFromRawValue(timesRange.from, currentField.format).toISO({ includeOffset: false }) ||
        '';

      timesRange.to.iso_timestamp =
        getUTCFromRawValue(timesRange.to, currentField.format).toISO({ includeOffset: false }) ||
        '';

      updatedData.times = [timesRange];

      const formattedStartDate = formatDate(timesRange.from.iso_timestamp, dateFormat);
      const formattedEndDate = formatDate(timesRange.to.iso_timestamp, dateFormat);

      const startDateValue = getValues('startDate');
      const endDateValue = getValues('endDate');

      const startTimeValue = getValues('startTime');
      const endTimeValue = getValues('endTime');

      const hasDateValues = !!startDateValue && !!endDateValue;
      const hasTimeValues = !!startTimeValue && !!endTimeValue;

      const durationValue = `${hasDateValues && !!formattedStartDate ? formattedStartDate : ''} ${
        startTimeValue ? `${startTimeValue}${startDateAmPmTimeFormat?.toLowerCase()}` : ''
      } ${
        hasDateValues || hasTimeValues
          ? t('components.data_table.attributes.field_labels.date_time.to')
          : ''
      } ${
        formattedEndDate ? `${formattedEndDate}` : ''
      } ${endTimeValue ? `${endTimeValue}${endDateAmPmTimeFormat?.toLowerCase()}` : ''}`;

      if (timeFormat === 'HH MM (military)') {
        setStartDateAmPmTimeFormat(getAmPmFormatFrom24HourTime(startTimeValue));
        setEndDateAmPmTimeFormat(getAmPmFormatFrom24HourTime(endTimeValue));
      }

      await saveCell(rowId, fieldId, updatedData, {
        value: durationValue,
        rawValue: {
          ...rawValue,
          times: [
            {
              from: {
                date: dateTime.startDate,
                time: dateTime.startTime,
                am_pm: startDateAmPmTimeFormat,
                iso_timestamp: timesRange.from.iso_timestamp
              },
              to: {
                date: dateTime.endDate,
                time: dateTime.endTime,
                am_pm: endDateAmPmTimeFormat,
                iso_timestamp: timesRange.to.iso_timestamp
              }
            } as DurationTimes
          ]
        }
      });
    },
    [
      startDateAmPmTimeFormat,
      endDateAmPmTimeFormat,
      rawValue,
      getValues,
      dateFormat,
      timeFormat,
      t,
      currentField.format,
      saveCell,
      rowId,
      fieldId
    ]
  );

  const onDateSelection = async (selectedDate?: Date, isEndDate = false) => {
    if (!selectedDate) return;
    clearCellErrors(rowId, fieldId);

    const dateSelectedWithoutTimezone = DateTime.fromJSDate(selectedDate).toISO({
      includeOffset: false
    });
    if (!dateSelectedWithoutTimezone) return;

    const formattedDate = getFormattedDateForInput(dateSelectedWithoutTimezone, dateFormat);

    setValue(isEndDate ? 'endDate' : 'startDate', formattedDate);

    await saveValue(isEndDate ? { endDate: formattedDate } : { startDate: formattedDate });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);

    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (selectedCell.isEditing) {
        moveSelectionVertical('down');
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onValueChange = async () => {
    if (!selectedCell?.isEditing) {
      setIsEditing(true);
    }

    await handleSubmit(saveValue)();
  };

  useEffect(() => {
    if (
      startDateAmPmTimeFormat !== durationRange?.from?.am_pm ||
      endDateAmPmTimeFormat !== durationRange?.to?.am_pm
    ) {
      void saveValue({});
    }
  }, [startDateAmPmTimeFormat, endDateAmPmTimeFormat]);

  if (!hasDate && !hasTime) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <div
        className={cn('flex size-full flex-row items-center truncate px-2', {
          'bg-base': selectedCell.isEditing,
          'ring-2 ring-destructive focus:ring-2 focus:ring-destructive':
            cellErrors ||
            errors?.startDate ||
            errors?.endDate ||
            errors?.startTime ||
            errors?.endTime
        })}
        ref={startDateRef.setReference}
        data-testid={`duration-edit-${rowId}-${fieldId}`}
      >
        {hasDate && (
          <>
            <input
              placeholder={getDateFormat(dateFormat)}
              className={cn(
                'max-w-[120px] flex-grow border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
                {
                  'size-full max-w-full opacity-0': !selectedCell.isEditing,
                  'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors,
                  'size-full max-w-full': !hasTime,
                  'max-h-7': selectedCell.isEditing
                }
              )}
              onClick={(e) => {
                if (!selectedCell.isEditing) {
                  setIsEditing(true);
                  setCursorPositionAtTheEnd(e);
                }

                setIsEditingEndDate(false);
              }}
              defaultValue={durationRange?.from?.date_formatted}
              onKeyDown={handleKeyDown}
              data-testid={`edit-start-date-input-${rowId}-${fieldId}`}
              onFocus={setCursorPositionAtTheEnd}
              {...register('startDate', {
                onChange: () => onValueChange()
              })}
            />

            {selectedCell.isEditing && !isEditingEndDate && (
              <div
                ref={startDateRef.setFloating}
                style={startDateFloatingStyles}
                className={cn('mt-1 flex rounded-lg border-2 border-default bg-base', {
                  hidden: !selectedCell.isEditing
                })}
                data-testid={`start-date-date-picker-${rowId}-${fieldId}`}
              >
                <div className="overflow-auto">
                  <Calendar
                    mode="single"
                    fromYear={DAY_PICKER_FROM_YEAR}
                    toYear={DAY_PICKER_TO_YEAR}
                    selected={
                      // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                      !errors?.startDate?.message && durationRange?.from.iso_timestamp
                        ? DateTime.fromISO(durationRange?.from.iso_timestamp).toJSDate()
                        : undefined
                    }
                    onSelect={(date) => onDateSelection(date)}
                    month={
                      !errors?.startDate?.message && durationRange?.from.iso_timestamp
                        ? DateTime.fromISO(durationRange?.from.iso_timestamp).toJSDate()
                        : undefined
                    }
                    onMonthChange={(month) => onDateSelection(month)}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {hasTime && (
          <Time
            dateAmPmTimeFormat={startDateAmPmTimeFormat || 'AM'}
            setDateAmPmTimeFormat={setStartDateAmPmTimeFormat}
            handleKeyDown={handleKeyDown}
            hasDate={hasDate}
            hasAmPmTimeFormat={hasAmPmTimeFormat}
            rowId={rowId}
            fieldId={fieldId}
            timeFormat={timeFormat || 'HH:MM am'}
            onTimeChange={() => onValueChange()}
            formPropName="startTime"
          />
        )}

        <div className="flex size-full flex-row items-center" ref={endDateRef.setReference}>
          <span className={cn('ml-1 mr-2 bg-base', { hidden: !selectedCell.isEditing })}>
            {t('components.data_table.attributes.field_labels.date_time.to')}
          </span>

          {hasDate && (
            <>
              <input
                placeholder={getDateFormat(dateFormat)}
                className={cn(
                  'max-w-[120px] flex-grow border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
                  {
                    'size-full max-w-full opacity-0': !selectedCell.isEditing,
                    'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors,
                    'size-full max-w-full': !hasTime,
                    'max-h-7': selectedCell.isEditing
                  }
                )}
                onClick={(e) => {
                  if (!selectedCell.isEditing) {
                    setIsEditing(true);
                    setCursorPositionAtTheEnd(e);
                  }

                  setIsEditingEndDate(true);
                }}
                defaultValue={durationRange?.to?.date_formatted}
                onKeyDown={handleKeyDown}
                data-testid={`edit-end-date-input-${rowId}-${fieldId}`}
                onFocus={setCursorPositionAtTheEnd}
                {...register('endDate', {
                  onChange: () => onValueChange()
                })}
              />

              {selectedCell.isEditing && isEditingEndDate && (
                <div
                  ref={endDateRef.setFloating}
                  style={endDateFloatingStyles}
                  className={cn(
                    'mt-1 flex overflow-auto rounded-lg border-2 border-default bg-default',
                    {
                      hidden: !selectedCell.isEditing
                    }
                  )}
                  data-testid={`end-date-date-picker-${rowId}-${fieldId}`}
                >
                  <div className="overflow-auto">
                    <Calendar
                      mode="single"
                      fromYear={DAY_PICKER_FROM_YEAR}
                      toYear={DAY_PICKER_TO_YEAR}
                      selected={
                        // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                        !errors?.endDate?.message && durationRange?.to.iso_timestamp
                          ? DateTime.fromISO(durationRange?.to.iso_timestamp).toJSDate()
                          : undefined
                      }
                      onSelect={(endDate) => onDateSelection(endDate, true)}
                      month={
                        // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                        !errors?.endDate?.message && durationRange?.to.iso_timestamp
                          ? DateTime.fromISO(durationRange?.to.iso_timestamp).toJSDate()
                          : undefined
                      }
                      onMonthChange={(month) => onDateSelection(month, true)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {hasTime && (
            <Time
              dateAmPmTimeFormat={endDateAmPmTimeFormat || 'AM'}
              setDateAmPmTimeFormat={setEndDateAmPmTimeFormat}
              handleKeyDown={handleKeyDown}
              hasDate={hasDate}
              hasAmPmTimeFormat={hasAmPmTimeFormat}
              rowId={rowId}
              fieldId={fieldId}
              timeFormat={timeFormat || 'HH:MM am'}
              onTimeChange={() => onValueChange()}
              formPropName="endTime"
            />
          )}
        </div>

        {hasErrors && (
          <div className="absolute left-0 top-full w-full bg-base">
            {selectedCell.isEditing && hasDate && errors?.startDate?.message && (
              <CellError
                message={errors.startDate.message}
                testId={`edit-start-date-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasTime && errors?.startTime?.message && (
              <CellError
                message={errors.startTime.message}
                testId={`edit-start-time-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasDate && errors?.endDate?.message && (
              <CellError
                message={errors.endDate.message}
                testId={`edit-end-date-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasTime && errors?.endTime?.message && (
              <CellError
                message={errors.endTime.message}
                testId={`edit-end-time-error-${rowId}-${fieldId}`}
              />
            )}

            <CellErrors rowId={rowId} fieldId={fieldId} />
          </div>
        )}
      </div>
    </FormProvider>
  );
}
