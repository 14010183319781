import { useTranslation } from 'react-i18next';

import { type BuilderView } from '@/types/schema/BuilderView';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ActiveViewContextProvider } from './ActiveViewContextProvider';
import { DetailsSettings } from './details/DetailsSettings';
import { FormSettings } from './form/FormSettings';
import { ListViewSettings } from './list/ListViewSettings';
import { LoginSettings } from './login/LoginSettings';
import { MenuSettings } from './menu/MenuSettings';
import { ReportSettings } from './report/ReportSettings';
import { RichTextSettings } from './rich-text/RichTextSettings';
import { TableViewSettings } from './table/TableViewSettings';
import { ViewInputEditModeContextProvider } from './ViewInputEditModeContext';

interface ViewSettingsProps {
  view: BuilderView;
}

export function ViewSettings({ view }: ViewSettingsProps) {
  const [t] = useTranslation();

  const getViewSettingsContent = () => {
    switch (view.type) {
      case 'form':
      case 'registration':
        return (
          <ViewInputEditModeContextProvider>
            <FormSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'table':
        return (
          <ViewInputEditModeContextProvider>
            <TableViewSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'list':
        return (
          <ViewInputEditModeContextProvider>
            <ListViewSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'rich_text':
        return <RichTextSettings />;
      case 'menu':
        return <MenuSettings />;
      case 'report':
        return <ReportSettings />;
      case 'details':
        return (
          <ViewInputEditModeContextProvider>
            <DetailsSettings />
          </ViewInputEditModeContextProvider>
        );
      case 'login':
        return <LoginSettings />;

      // More views will be added here as they get implemented
      default:
        return (
          <CollapsiblePanel.Content title={t('pages.element_settings.heading')}>
            Coming Soon
          </CollapsiblePanel.Content>
        );
    }
  };

  return (
    <ActiveViewContextProvider viewKey={view.key}>
      {getViewSettingsContent()}
    </ActiveViewContextProvider>
  );
}
