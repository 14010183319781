import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Input } from '@knack/asterisk-react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { cn } from '@/utils/tailwind';

export function InlineSingleSelect(props: {
  defaultValue: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
}) {
  const [searchValue, setSearchValue] = useState('');
  const { defaultValue, options, onChange } = props;
  const [t] = useTranslation();
  const virtualizerContainerRef = useRef<HTMLDivElement>(null);

  const filteredOptions =
    options?.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())) ||
    [];

  const rowVirtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => virtualizerContainerRef.current,
    estimateSize: () => 40,
    gap: 4
  });

  return (
    <div className="relative z-50 flex max-w-[200px] animate-slideDownAndFade flex-col gap-1 overflow-hidden rounded-xl border border-subtle bg-menu p-2 text-base text-default shadow-md">
      <Input
        placeholder={t('components.data_table.select.search')}
        onChange={(e) => setSearchValue(e.target.value)}
        className="focus:ring-none ml-1 border-none p-0 focus:border-none focus:outline-0 focus:outline-offset-0 focus:ring-offset-0"
      />
      <Divider className="mb-2" />
      <div
        ref={virtualizerContainerRef}
        className="relative max-h-[200px] w-full overflow-auto"
        data-virtualizer-container
      >
        <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const option = filteredOptions[virtualRow.index];
            return (
              <Button
                intent="minimal"
                className={cn('absolute left-1 right-1 justify-start', {
                  'bg-brand-100 hover:bg-brand-100 focus:bg-brand-100':
                    defaultValue === option.value
                })}
                key={virtualRow.key}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                  height: `${virtualRow.size}px`
                }}
                onClick={() => {
                  onChange(option.value);
                }}
              >
                <p className="truncate">{option.label}</p>
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
