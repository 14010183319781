import { type NameFieldFormat } from '@/types/schema/fields';
import { type NameField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { NameEdit } from '@/components/data-table/display/fields/name/NameEdit';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

// This format function comes from
// https://github.com/knackhq/Server/blob/2b83776119bcf01f20a6a3651ce046be9128a4f5/app/lib/converter.js#L1133
const formatNameToString = (
  value: NameField['rawValue'],
  format: NameFieldFormat = 'First Last'
): string => {
  const output: string[] = [];
  let result = '';
  if (!value) {
    return result;
  }

  if (!value.first && !value.last) {
    return result;
  }

  switch (format) {
    case 'Title First Last':
      if (value.title) {
        output.push(value.title);
      }

      if (value.first) {
        output.push(value.first);
      }

      if (value.last) {
        output.push(value.last);
      }

      break;
    case 'First Middle Last':
      if (value.first) {
        output.push(value.first);
      }

      if (value.middle) {
        output.push(value.middle);
      }

      if (value.last) {
        output.push(value.last);
      }

      break;
    case 'Last, First':
    case 'Last, First Middle':
      if (value.last) {
        if (value.first || (format === 'Last, First Middle' && value.middle)) {
          output.push(`${value.last},`);
        } else {
          output.push(value.last);
        }
      }

      if (value.first) {
        output.push(value.first);
      }
      if (format === 'Last, First Middle' && value.middle) {
        output.push(value.middle);
      }
      break;
    case 'First Last':
    default:
      if (value.first) {
        output.push(value.first);
      }
      if (value.last) {
        output.push(value.last);
      }

      break;
  }
  result = output.join(' ');

  return result;
};

export function NameRender(props: FieldRenderProps<NameField>) {
  const { rawValue, isFloating, fieldId } = props;
  const currentField = useDataTableStore().use.getField<'name'>(fieldId);

  // TODO: FE-2183 - Render value in the frontend without relying on the server
  if (isFloating) {
    return <NameEdit {...props} />;
  }

  return (
    <p className="flex size-full truncate p-2">
      {formatNameToString(rawValue, currentField.format?.format)}
    </p>
  );
}
