import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Calendar } from '@knack/asterisk-react';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CalendarFooter } from '@/components/data-table/display/fields/date-time/components/CalendarFooter';
import { CellError } from '@/components/data-table/display/fields/date-time/components/CellError';
import { Time } from '@/components/data-table/display/fields/date-time/components/Time';
import { getDateTimeSchema } from '@/components/data-table/display/fields/date-time/DateTimeSchema';
import {
  formatDate,
  formatDateTimeRawValue,
  getFormattedDateForInput,
  getUTCFromRawValue
} from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import {
  formatTime,
  getFormattedTimeForInput,
  getHoursFromTime,
  getMinutesFromTime
} from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import {
  type DateTimeField as DateTimeFieldDisplay,
  type DateTimeRawValue
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import {
  DAY_PICKER_FROM_YEAR,
  DAY_PICKER_TO_YEAR
} from '@/components/data-table/helpers/constants';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type SaveDateTimeCellValue = {
  date?: string;
  time?: string;
};

export function DateTimeEdit({
  fieldId,
  rawValue,
  rowId,
  type
}: FieldRenderProps<DateTimeFieldDisplay>) {
  const useDataTableState = useDataTableStore();
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const [amPmTimeFormat, setAmPmTimeFormat] = useState(rawValue.am_pm);
  const { moveSelectionVertical } = useSelectionStrategy();
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { date_format: dateFormat, time_format: timeFormat } = currentField.format;
  const hasDate = dateFormat !== 'Ignore Date';
  const hasTime = timeFormat !== 'Ignore Time';
  const hasAmPmTimeFormat = timeFormat === 'HH:MM am';

  const selectedDate = rawValue.iso_timestamp
    ? DateTime.fromISO(rawValue.iso_timestamp)
    : DateTime.now();

  const dateTimeFormSchema = getDateTimeSchema(currentField);

  type DateTimeFormSchema = z.infer<typeof dateTimeFormSchema>;

  const formMethods = useForm<DateTimeFormSchema>({
    resolver: zodResolver(dateTimeFormSchema),
    defaultValues: {
      date: rawValue.iso_timestamp
        ? getFormattedDateForInput(rawValue.iso_timestamp, dateFormat) || ''
        : '',
      time: rawValue?.iso_timestamp
        ? getFormattedTimeForInput(rawValue.iso_timestamp, timeFormat) || ''
        : ''
    },
    mode: 'onChange',
    shouldFocusError: false
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = formMethods;

  const hasErrors = Object.keys(errors).length > 0 || !!cellErrors?.length;

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.maxHeight = '100%';
            elements.floating.style.maxWidth = '100%';
            return;
          }

          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const saveCellValue = useCallback(
    async (dateTime: SaveDateTimeCellValue) => {
      const { date, time } = dateTime;
      let updatedData = {
        ...dateTime,
        am_pm: amPmTimeFormat,
        to: undefined
      } as DateTimeRawValue;

      if (!date && !time && amPmTimeFormat === rawValue.am_pm) return;

      if (time) {
        updatedData.hours = getHoursFromTime(time);
        updatedData.minutes = getMinutesFromTime(time);
      }

      if (!date) {
        if (hasDate && currentField.required) {
          updatedData.date = formatDate(DateTime.now().toISO(), dateFormat) || '';
        } else {
          updatedData = omit(updatedData, 'date');
        }
      }

      if (!time && hasTime && currentField.required) {
        updatedData.hours = '12';
        updatedData.minutes = '00';
      }

      updatedData.iso_timestamp =
        getUTCFromRawValue(updatedData, currentField.format).toISO({ includeOffset: false }) || '';

      await saveCell(rowId, fieldId, updatedData, {
        value: formatDateTimeRawValue(updatedData, currentField.format),
        rawValue: updatedData
      });
    },
    [
      rawValue,
      amPmTimeFormat,
      hasTime,
      currentField.required,
      currentField.format,
      saveCell,
      rowId,
      fieldId,
      hasDate,
      dateFormat
    ]
  );

  const onDateSelection = async (date?: Date) => {
    if (!date) return;
    clearCellErrors(rowId, fieldId);

    const dateSelectedWithoutTimezone = DateTime.fromJSDate(date).toISO({ includeOffset: false });
    if (!dateSelectedWithoutTimezone) return;

    const formattedDate = getFormattedDateForInput(dateSelectedWithoutTimezone, dateFormat);

    setValue('date', formattedDate);

    await saveCellValue({ date: formattedDate });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);

    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (selectedCell.isEditing) {
        moveSelectionVertical('down');
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onValueChange = async () => {
    if (!selectedCell?.isEditing) {
      setIsEditing(true);
    }

    await handleSubmit(saveCellValue)();
  };

  useEffect(() => {
    if (amPmTimeFormat !== rawValue.am_pm) {
      // We want to send the form once you change the AM/PM format
      void handleSubmit(saveCellValue)();
    }
  }, [amPmTimeFormat, handleSubmit, rawValue, saveCellValue]);

  if (!hasDate && !hasTime) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <div
        className={cn('flex size-full flex-row items-center truncate px-2', {
          'bg-base': selectedCell.isEditing
        })}
        ref={refs.setReference}
        data-testid={`date-time-edit-${rowId}-${fieldId}`}
      >
        {hasDate && (
          <>
            <input
              placeholder={`${dateFormat}`}
              className={cn('max-w-[120px] flex-grow border-0 bg-base p-2', {
                'ring-2 ring-black focus:ring-2 focus:ring-black':
                  !cellErrors && selectedCell.isEditing,
                'size-full max-w-full opacity-0': !selectedCell.isEditing,
                'ring-2 ring-destructive focus:ring-2 focus:ring-destructive':
                  cellErrors || errors?.date,
                'size-full max-w-full': !hasTime,
                'max-h-7': selectedCell.isEditing
              })}
              onClick={(e) => {
                if (!selectedCell.isEditing) {
                  setIsEditing(true);
                  setCursorPositionAtTheEnd(e);
                }
              }}
              defaultValue={rawValue.date_formatted}
              onKeyDown={handleKeyDown}
              data-testid={`edit-date-input-${rowId}-${fieldId}`}
              onFocus={setCursorPositionAtTheEnd}
              {...register('date', {
                onChange: async () => {
                  if (!selectedCell?.isEditing) {
                    setIsEditing(true);
                  }

                  await handleSubmit(saveCellValue)();
                }
              })}
            />

            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className={cn('mt-1 flex rounded-lg border-default bg-base', {
                hidden: !selectedCell.isEditing
              })}
              data-testid={`date-time-date-picker-${rowId}-${fieldId}`}
            >
              <div className="overflow-auto">
                <Calendar
                  mode="single"
                  fromYear={DAY_PICKER_FROM_YEAR}
                  toYear={DAY_PICKER_TO_YEAR}
                  selected={
                    // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                    !errors?.date?.message && rawValue.iso_timestamp
                      ? selectedDate.toJSDate()
                      : undefined
                  }
                  month={
                    !errors?.date?.message && rawValue.iso_timestamp
                      ? selectedDate.toJSDate()
                      : undefined
                  }
                  onSelect={(date) => onDateSelection(date)}
                  onMonthChange={(month) =>
                    onDateSelection(
                      new Date(month.getFullYear(), month.getMonth(), selectedDate.day)
                    )
                  }
                  footer={
                    hasTime ? (
                      <CalendarFooter
                        fieldId={fieldId}
                        rowId={rowId}
                        formPropName="date"
                        currentDateTimestamp={formatTime(rawValue?.iso_timestamp, timeFormat) || ''}
                        hasAmPmTimeFormat={hasAmPmTimeFormat}
                        dateAmPmTimeFormat={amPmTimeFormat || 'AM'}
                        hasEndTime={false}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
          </>
        )}
        {hasTime && (
          <Time
            dateAmPmTimeFormat={amPmTimeFormat || 'AM'}
            setDateAmPmTimeFormat={setAmPmTimeFormat}
            handleKeyDown={handleKeyDown}
            hasDate={hasDate}
            hasAmPmTimeFormat={hasAmPmTimeFormat}
            rowId={rowId}
            fieldId={fieldId}
            timeFormat={timeFormat || 'HH:MM am'}
            onTimeChange={() => onValueChange()}
            formPropName="time"
          />
        )}

        {hasErrors && (
          <div className="absolute left-0 top-full w-full bg-base">
            {selectedCell.isEditing && hasDate && errors?.date?.message && (
              <CellError
                message={errors.date.message}
                testId={`edit-date-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasTime && errors?.time?.message && (
              <CellError
                message={errors.time.message}
                testId={`edit-time-error-${rowId}-${fieldId}`}
              />
            )}
            <CellErrors rowId={rowId} fieldId={fieldId} />
          </div>
        )}
      </div>
    </FormProvider>
  );
}
