import { useTranslation } from 'react-i18next';

interface PageErrorProps {
  title: string;
  message: string;
  children: React.ReactNode;
}

export function PageError({ title, message, children }: PageErrorProps) {
  const [t] = useTranslation();
  return (
    <div className="full-page-wrapper">
      <div className="flex h-screen max-w-lg flex-col items-center justify-center text-center">
        <span aria-label={t('emojis.downcast_face_with_sweat')} className="mb-6 text-5xl">
          😓
        </span>
        <h2 className="mb-2 text-2xl font-medium text-emphasis" data-testid="page-error-title">
          {title}
        </h2>
        <p className="mb-6">{message}</p>
        {children}
      </div>
    </div>
  );
}
