import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function useSelectionStrategy() {
  const dataTableStore = useDataTableStore();

  const moveSelectionVertical = (direction: 'up' | 'down') => {
    const { dataOrder, selectedCell, actions } = dataTableStore.getState();
    const { setSelectedCell, setIsEditing } = actions;

    const directionMultiplier = direction === 'up' ? -1 : 1;
    if (!selectedCell) return;
    const currentRowIndex = dataOrder.findIndex((element) => element === selectedCell?.rowId);

    const newRowIndex = currentRowIndex + directionMultiplier;
    if (newRowIndex < 0 || newRowIndex >= dataOrder.length) {
      setIsEditing(false);
      return;
    }

    const previousRow = dataOrder[newRowIndex];
    setSelectedCell(previousRow, selectedCell.fieldId);
  };

  const moveSelectionHorizontal = (direction: 'left' | 'right') => {
    const { selectedCell, actions, fields } = dataTableStore.getState();
    const { setSelectedCell, setIsEditing } = actions;

    if (!selectedCell) return;

    const directionMultiplier = direction === 'left' ? -1 : 1;
    const currentColumnIndex = fields.findIndex((column) => column.key === selectedCell.fieldId);

    // This code was used to move the selection to the next valid column
    // I'm keeping it here until we are sure we don't need it anymore

    // let newColumnIndex = currentColumnIndex + directionMultiplier;
    // while (newColumnIndex >= 0 && newColumnIndex < fields.length) {
    //   const potentialNewField = fields[newColumnIndex];
    //     break;
    //   }
    //   newColumnIndex += directionMultiplier;
    // }

    const newColumnIndex = currentColumnIndex + directionMultiplier;

    if (newColumnIndex < 0 || newColumnIndex >= fields.length) {
      setIsEditing(false);
      return;
    }

    const nextValidColumn = fields[newColumnIndex];
    setSelectedCell(selectedCell.rowId, nextValidColumn.key);
  };
  return {
    moveSelectionVertical,
    moveSelectionHorizontal
  };
}
