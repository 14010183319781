import { t } from 'i18next';
import { z } from 'zod';

import { type CurrencyField } from '@/types/schema/fields';
import { numericStringRegex } from '@/components/data-table/helpers/zod';

export function getCurrencySchema(field: CurrencyField) {
  const { required } = field;
  return z.object({
    currency: z
      .string()
      .regex(numericStringRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'currency' })
      })
      .optional()
      .or(z.literal(''))
      .refine((newValue) => !required || newValue, t('components.data_table.errors.required_field'))
  });
}
