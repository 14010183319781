import { useCallback } from 'react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackViewType } from '@/types/schema/BuilderView';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { usePageDataSources, type PageDataSource } from './usePageDataSources';

export function useAllowedViewTypes() {
  const { data: application } = useApplicationQuery();

  const {
    hasNumericField,
    hasGeocodedAddressField,
    hasDateTimeField,
    getConnectionsToManyFromObjectKey,
    getUserConnectionsToManyFromPage
  } = useObjectHelpers();
  const { getPageDataSources } = usePageDataSources();

  // Check if page allows adding views that can display more than one record
  const canAddViewsThatDisplayMultipleRecords = useCallback(
    (page: BuilderPage) => {
      // If the page has no source object, it means we can show all records for all objects
      if (!page.sourceObjectKey) {
        return true;
      }

      // Check if the page's source object has any connections to `many`
      if (getConnectionsToManyFromObjectKey(page.sourceObjectKey, true).length > 0) {
        return true;
      }

      // Check if the page is associated to user roles with connections to `many`
      if (getUserConnectionsToManyFromPage(page, false, true).length > 0) {
        return true;
      }

      return false;
    },
    [getConnectionsToManyFromObjectKey, getUserConnectionsToManyFromPage]
  );

  // Check if the page allows adding 'map' views
  const canAddMapView = useCallback(
    (viewSourceOptions: PageDataSource[]) =>
      viewSourceOptions.some((sourceOption) =>
        hasGeocodedAddressField({ fromObject: sourceOption.object })
      ),
    [hasGeocodedAddressField]
  );

  // Check if the page allows adding 'calendar' views
  const canAddCalendarView = useCallback(
    (viewSourceOptions: PageDataSource[]) =>
      viewSourceOptions.some((sourceOption) =>
        hasDateTimeField({ fromObject: sourceOption.object })
      ),
    [hasDateTimeField]
  );

  // Check if the page allows adding 'customer' views (payment methods)
  const canAddCustomerView = useCallback(
    (pageDataSources: PageDataSource[], page: BuilderPage) => {
      if (!application?.ecommerce.enabled) {
        return false;
      }

      // The view is automatically allowed if the page is behind a login
      if (application?.users.enabled && page.requiresAuthentication) {
        return true;
      }

      return pageDataSources.some((pageDataSource) => {
        // Payment methods only apply to individual records so we ignore views that display many records
        if (pageDataSource.recordDisplayQuantity === 'many') {
          return false;
        }

        // Payments can be added to any user object
        return pageDataSource.object && pageDataSource.object.type === 'UserObject';
      });
    },
    [application]
  );

  // Check if the page allows adding 'checkout' views (payment)
  const canAddCheckoutView = useCallback(
    (pageDataSources: PageDataSource[], page: BuilderPage) => {
      if (!application?.ecommerce.enabled) {
        return false;
      }

      if (
        page.type === 'authentication' ||
        (!page.sourceObjectKey && !page.requiresAuthentication)
      ) {
        return false;
      }

      return pageDataSources.some((pageDataSource) =>
        hasNumericField({ fromObject: pageDataSource.object })
      );
    },
    [application, hasNumericField]
  );

  // Get the allowed view types for a page
  const getPageAllowedViewTypes = useCallback(
    (page: BuilderPage) => {
      // Static views are allowed by default
      const allowedViewTypes: KnackViewType[] = ['rich_text', 'menu'];

      const viewSourceOptions = getPageDataSources(page);

      // Check if 'customer' views (payment methods) can be added, since it's the only other view that can be added to a login page (aside from the static views)
      if (canAddCustomerView(viewSourceOptions, page)) {
        allowedViewTypes.push('customer');
      }

      // If it's a login page, we don't allow any other views
      if (page.type === 'authentication') {
        return allowedViewTypes;
      }

      // Check if 'checkout' views can be added
      if (canAddCheckoutView(viewSourceOptions, page)) {
        allowedViewTypes.push('checkout');
      }

      // Allow the views that don't have any other special requirements
      allowedViewTypes.push('form', 'report');

      // Allow 'details' views if the page has a source object, or if it's behind a login (since it can show details about the user)
      if (page.sourceObjectKey || page.requiresAuthentication) {
        allowedViewTypes.push('details');
      }

      // Check if views that can display more than one record (table, search, list, map, calendar) can be added
      if (canAddViewsThatDisplayMultipleRecords(page)) {
        // No further check necessary for 'table' 'search' and 'list'
        allowedViewTypes.push('table', 'search', 'list');

        if (canAddMapView(viewSourceOptions)) {
          allowedViewTypes.push('map');
        }

        if (canAddCalendarView(viewSourceOptions)) {
          allowedViewTypes.push('calendar');
        }
      }

      return allowedViewTypes;
    },
    [
      canAddCalendarView,
      canAddCheckoutView,
      canAddCustomerView,
      canAddMapView,
      canAddViewsThatDisplayMultipleRecords,
      getPageDataSources
    ]
  );

  return { getPageAllowedViewTypes };
}
