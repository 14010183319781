import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { RequiredSwitch as BooleanRequiredSwitch } from '@/components/field-settings/boolean/RequiredSwitch';

type RequiredSwitchProps = {
  fieldType?: KnackFieldType;
};

export function RequiredSwitch({ fieldType }: RequiredSwitchProps) {
  const [t] = useTranslation();

  const { control } = useFormContext();

  if (fieldType === 'boolean') {
    return <BooleanRequiredSwitch />;
  }

  return (
    <div className="flex items-center">
      <Controller
        control={control}
        name="required"
        render={({ field: { onChange, value } }) => (
          <Switch
            id="field-settings-required-switch"
            className="mr-2"
            data-testid="required-value-switch"
            checked={value}
            onCheckedChange={onChange}
          />
        )}
      />
      <Label htmlFor="field-settings-required-switch">
        {t('components.data_table.attributes.field_settings.common.required')}
      </Label>
    </div>
  );
}
