import { Position } from 'reactflow';

export const calculateTargetPosition = (
  sourceNodeWidth: number,
  sourceNodeX: number,
  targetNodeWidth: number,
  targetNodeX: number
) => {
  if (
    sourceNodeX > targetNodeX + targetNodeWidth ||
    (sourceNodeX > targetNodeX && sourceNodeX < targetNodeX + targetNodeWidth)
  ) {
    return Position.Right;
  }
  return Position.Left;
};
