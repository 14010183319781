import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import {
  LAYOUT_COLUMNS,
  LAYOUT_THREE_COLUMN_STRUCTURES,
  LAYOUT_TWO_COLUMN_STRUCTURES,
  type BuilderPageSection,
  type SectionLayout,
  type SectionLayoutColumns,
  type SectionLayoutThreeColumnStructure,
  type SectionLayoutTwoColumnStructure
} from '@/types/schema/BuilderPage';
import { cn } from '@/utils/tailwind';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { InlineRadioCardGroup } from '@/components/InlineRadioCardGroup';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { getUpdatedSectionFromLayoutColumnsChange } from '@/pages/pages/page-editor/helpers/get-updated-section-from-layout-columns-change';
import { getUpdatedSectionFromLayoutStructureChange } from '@/pages/pages/page-editor/helpers/get-updated-section-from-layout-structure-change';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

interface SectionSettingsProps {
  sectionId: string;
}

interface LayoutStructureOptionProps {
  structure: SectionLayoutTwoColumnStructure | SectionLayoutThreeColumnStructure;
  className?: string;
}

function LayoutStructureOption({ structure, className }: LayoutStructureOptionProps) {
  const [firstCol, secondCol, thirdCol] = structure.split('/');

  const getStructureColumn = (width: string) => (
    <span
      style={{ width: `${width}%` }}
      className="min-w-0 rounded-md border border-subtle bg-card px-2 py-1 text-center"
    >
      {width}%
    </span>
  );

  return (
    <div className={cn('flex flex-1 gap-1', className)}>
      {getStructureColumn(firstCol)}
      {secondCol && getStructureColumn(secondCol)}
      {thirdCol && getStructureColumn(thirdCol)}
    </div>
  );
}

export function SectionSettings({ sectionId }: SectionSettingsProps) {
  const [t] = useTranslation();

  const { page, updatePage } = usePageEditorContext();

  const section = useMemo(() => page.groups.find((s) => s.id === sectionId), [page, sectionId]);

  const [selectedLayout, setSelectedLayout] = useState<SectionLayout | null>(
    section?.layout ?? null
  );

  const processLayoutChange = (updatedSection: BuilderPageSection) => {
    setSelectedLayout(updatedSection.layout);

    updatePage({
      type: 'section',
      action: 'update',
      updatedSection
    });
  };

  const handleLayoutColumnsChange = (selectedLayoutColumns: string) => {
    if (!section) {
      return;
    }

    const updatedSection = getUpdatedSectionFromLayoutColumnsChange({
      section,
      selectedLayoutColumns: selectedLayoutColumns as SectionLayoutColumns
    });

    processLayoutChange(updatedSection);
  };

  const handleLayoutStructureChange = (
    selectedLayoutStructure: SectionLayoutTwoColumnStructure | SectionLayoutThreeColumnStructure
  ) => {
    if (!section) {
      return;
    }

    const updatedSection = getUpdatedSectionFromLayoutStructureChange({
      section,
      selectedLayoutStructure
    });

    processLayoutChange(updatedSection);
  };

  if (!selectedLayout) {
    return null;
  }

  return (
    <CollapsiblePanel.Content title={t('pages.section_settings.heading')}>
      <BuilderAccordion>
        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.section_settings.layout_settings.heading')}
        >
          <div className="mb-4">
            <span className="mb-2 block text-sm font-medium">
              {t('pages.section_settings.layout_settings.columns')}
            </span>
            <InlineRadioCardGroup
              options={LAYOUT_COLUMNS}
              value={selectedLayout.columns}
              onValueChange={handleLayoutColumnsChange}
              itemClassName="size-10"
            />
          </div>

          {selectedLayout.columns !== '1' && (
            <div>
              <span className="mb-2 block text-sm font-medium">
                {t('pages.section_settings.layout_settings.structure')}
              </span>

              <Select value={selectedLayout.structure} onValueChange={handleLayoutStructureChange}>
                <Select.Trigger shouldHideValue className="w-full py-1 pl-1 text-sm">
                  <LayoutStructureOption structure={selectedLayout.structure} />
                </Select.Trigger>
                <Select.Content>
                  {selectedLayout.columns === '2' &&
                    LAYOUT_TWO_COLUMN_STRUCTURES.map((structure) => (
                      <Select.Item key={structure} value={structure} asChild>
                        <>
                          <LayoutStructureOption structure={structure} className="max-w-[90%]" />
                          <Select.ItemIndicator className="ml-auto" />
                        </>
                      </Select.Item>
                    ))}

                  {selectedLayout.columns === '3' &&
                    LAYOUT_THREE_COLUMN_STRUCTURES.map((structure) => (
                      <Select.Item key={structure} value={structure} asChild>
                        <>
                          <LayoutStructureOption structure={structure} className="max-w-[90%]" />
                          <Select.ItemIndicator className="ml-auto" />
                        </>
                      </Select.Item>
                    ))}
                </Select.Content>
              </Select>
            </div>
          )}
        </BuilderAccordion.Item>
      </BuilderAccordion>
    </CollapsiblePanel.Content>
  );
}
