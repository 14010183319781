import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HiChevronDown as ChevronDownIcon,
  HiChevronUp as ChevronUpIcon,
  HiViewColumns as FieldsIcon
} from 'react-icons/hi2';
import { Collapsible } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplication } from '@/hooks/useApplication';
import { FieldIcon } from '@/components/FieldIcon';
import { InlineKnackTable } from '@/components/InlineKnackTable';
import { SidePanel } from '@/components/SidePanel';
import { FieldSidePanelConditionalRulesList } from './FieldSidePanelConditionalRulesList';
import { FieldSidePanelValidationRulesList } from './FieldSidePanelValidationRulesList';

interface ConnectionRelationshipDetailsProps {
  field: ConnectionField;
  table: KnackObject;
}

interface FieldSidePanelContentProps {
  field: KnackField;
  table: KnackObject;
}

function ConnectionRelationshipDetails({ field, table }: ConnectionRelationshipDetailsProps) {
  const [t] = useTranslation();
  const application = useApplication();
  const knackObjects = application?.objects;
  const connectionTargetObject = useMemo(
    () => knackObjects?.find((obj) => obj.key === field.relationship.object),
    [field.relationship.object, knackObjects]
  );

  if (!connectionTargetObject) return null;

  const sourceTableRecordCount =
    field.relationship.belongs_to === 'many' ? t('keywords.records') : t('keywords.record');
  const targetTableRecordCount =
    field.relationship.has === 'many' ? t('keywords.records') : t('keywords.record');

  return (
    <div className="mt-2 rounded border border-default p-2">
      <Trans i18nKey="components.data_model.field_connection_relationship_details">
        This field can connect
        <strong>{{ belongsTo: field.relationship.belongs_to } as never}</strong>
        <InlineKnackTable
          tableName={table.inflections.singular}
          tableType={table.type}
          className="text-default"
        />
        {{ sourceTableRecordCount }} with
        <strong>{{ has: field.relationship.has } as never}</strong>
        <InlineKnackTable
          tableName={connectionTargetObject.inflections.singular}
          tableType={connectionTargetObject.type}
          className="text-default"
        />
        {{ targetTableRecordCount }}.
      </Trans>
    </div>
  );
}

export function FieldSidePanelContent({ field, table }: FieldSidePanelContentProps) {
  const [t] = useTranslation();

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title
          className="flex items-center text-xl"
          data-testid="data-model-sidepanel-field-name"
        >
          <FieldsIcon size={26} className="mr-2 text-emphasis" /> {field.name}
        </SidePanel.Title>
      </SidePanel.Header>
      <div className="mt-12">
        <div className="mb-6">
          <h3 className="mb-2 font-semibold text-subtle">
            {t('components.data_model.field_type')}
          </h3>
          <span className="flex items-center" data-testid="data-model-sidepanel-field-type-text">
            <FieldIcon name={field.type} className="mr-2" />{' '}
            {t(`attributes.field_types.${field.type}`)}
          </span>
          {field.type === 'connection' && (
            <ConnectionRelationshipDetails field={field} table={table} />
          )}
        </div>
        <div>
          <h3
            className="mb-2 font-semibold text-subtle"
            data-testid="data-model-sidepanel-field-key-heading"
          >
            {t('components.data_model.field_key')}
          </h3>
          <span className="flex" data-testid="data-model-sidepanel-field-key-text">
            {field.key}
          </span>
        </div>

        <div
          role="separator"
          aria-orientation="horizontal"
          className="my-6 h-px w-full bg-subtle"
        />

        <Collapsible defaultOpen>
          <Collapsible.Trigger className="group flex w-full items-center rounded-sm text-subtle focus:outline-none focus:ring-2">
            <span>
              <span className="font-semibold">
                {t('components.data_model.fields_validation_rules')}
              </span>{' '}
              <span className="text-sm" data-testid="field-validation-rules-count">
                ({field.validation?.length ?? 0})
              </span>
            </span>
            <ChevronDownIcon size={14} className="ml-auto group-data-[state=open]:hidden" />
            <ChevronUpIcon size={14} className="ml-auto group-data-[state=closed]:hidden" />
          </Collapsible.Trigger>
          <Collapsible.Content className="mt-2">
            <FieldSidePanelValidationRulesList
              validationRules={field.validation}
              tableFields={table.fields}
            />
          </Collapsible.Content>
        </Collapsible>

        <Collapsible className="mt-8" defaultOpen>
          <Collapsible.Trigger className="group flex w-full items-center rounded-sm text-subtle focus:outline-none focus:ring-2">
            <span>
              <span className="font-semibold">
                {t('components.data_model.fields_conditional_rules')}
              </span>{' '}
              <span className="text-sm" data-testid="field-conditional-rules-count">
                ({field.rules?.length ?? 0})
              </span>
            </span>
            <ChevronDownIcon size={14} className="ml-auto group-data-[state=open]:hidden" />
            <ChevronUpIcon size={14} className="ml-auto group-data-[state=closed]:hidden" />
          </Collapsible.Trigger>
          <Collapsible.Content className="mt-2">
            <FieldSidePanelConditionalRulesList field={field} tableFields={table.fields} />
          </Collapsible.Content>
        </Collapsible>
      </div>
    </>
  );
}
