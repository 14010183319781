import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { DataTablePagination } from '@/components/data-table/display/footer/DataTablePagination';
import { FocusChanges } from '@/components/data-table/display/footer/FocusChanges';
import { FocusErrors } from '@/components/data-table/display/footer/FocusErrors';
import { SaveWithoutValidationDialog } from '@/components/data-table/display/footer/SaveWithoutValidationModal';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';

export function TableFooter() {
  const [t] = useTranslation();

  const draftRows = useDataTableStore().use.draftRows();
  const rowErrorList = useDataTableStore().use.rowErrorList();

  const { saveDraftRow, cancelRowEdit } = useDataTableStore().use.actions();
  const dataNavigationMode = useDataTableStore().use.dataNavigationMode();
  const currentLoadingRows = useDataTableStore().use.currentLoadingRows();
  const filesBeingUploaded = useDataTableStore().use.filesBeingUploaded();
  const [isConfirmDiscardChangesModalOpen, setIsConfirmDiscardChangesModalOpen] = useState(false);
  const [isSaveWithoutValidationModalOpen, setIsSaveWithoutValidationModalOpen] = useState(false);

  const isDraftMode = Object.keys(draftRows).length > 0;

  if (!isDraftMode && dataNavigationMode === 'infinite-scroll') return null;

  return (
    <div className="z-10 -ml-8 flex items-center bg-base p-4 shadow-2xl shadow-black">
      {isDraftMode && (
        <>
          <FocusChanges />
          <FocusErrors />
        </>
      )}

      {dataNavigationMode === 'pagination' && (
        <div className="m-auto">
          <DataTablePagination />
        </div>
      )}

      {isDraftMode && (
        <div
          className={cn('flex items-center gap-4', {
            'ml-auto': dataNavigationMode === 'infinite-scroll'
          })}
        >
          <Button
            data-testid="discard-changes-button"
            intent="minimal"
            onClick={() => {
              setIsConfirmDiscardChangesModalOpen(true);
            }}
          >
            {t('components.data_table.footer.discard_changes')}
          </Button>
          <DiscardChangesModal
            isOpen={isConfirmDiscardChangesModalOpen}
            onOpenChange={setIsConfirmDiscardChangesModalOpen}
            onConfirm={() => {
              Object.keys(draftRows).forEach((rowId) => {
                void cancelRowEdit(rowId);
              });
            }}
          />
          {isSaveWithoutValidationModalOpen && (
            <SaveWithoutValidationDialog
              onClose={() => {
                setIsSaveWithoutValidationModalOpen(false);
              }}
            />
          )}
          <Button
            data-testid="save-rows-button"
            disabled={Object.keys(filesBeingUploaded).length > 0}
            onClick={() => {
              if (Object.keys(rowErrorList).length > 0) {
                setIsSaveWithoutValidationModalOpen(true);
                return;
              }

              Object.keys(draftRows).forEach((rowId) => {
                void saveDraftRow(rowId);
              });
            }}
            isLoading={Object.keys(currentLoadingRows).length > 0}
          >
            {t('components.data_table.footer.save_rows', {
              count: Object.keys(draftRows).length
            })}
          </Button>
        </div>
      )}
    </div>
  );
}
