import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiSelect, Select } from '@knack/asterisk-react';

import { type MultipleChoiceField } from '@/types/schema/fields';

export function MultipleChoiceInput({
  targetField,
  name,
  id
}: {
  targetField: MultipleChoiceField;
  name: string;
  id: string;
}) {
  const [t] = useTranslation();
  const options = targetField.format?.options || [];
  const multiple =
    targetField.format?.type === 'multi' || targetField.format?.type === 'checkboxes';

  if (multiple) {
    return (
      <Controller
        name={name}
        render={({ field }) => {
          const fieldValues =
            (field.value &&
              field.value.map((val: string) => ({
                label: val,
                value: val,
                key: val
              }))) ||
            [];

          return (
            <MultiSelect
              id={id}
              options={
                options.map((option) => ({
                  label: option,
                  key: option
                })) || []
              }
              selectedOptions={fieldValues}
              maxVisibleChips={5}
              isSearchEnabled
              onSelectOptions={(values) => {
                field.onChange(values.map((val) => val.key));
              }}
            />
          );
        }}
      />
    );
  }
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Select onValueChange={field.onChange} defaultValue={field.value || ''}>
          <Select.Trigger
            id={id}
            placeholder={t('actions.select')}
            className="w-full truncate rounded-lg"
            {...field}
          />
          <Select.Content>
            {options.map((option: string) => (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}
