import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';
import { z } from 'zod';

import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { filterOperatorsByType } from '@/types/schema/operators/FilterOperatorsByType';
import { cn } from '@/utils/tailwind';
import { DefaultInputValue } from '@/components/data-table/display/header/filters/inputs/DefaultInputValue';
import { FilterDateTimeInput } from '@/components/data-table/display/header/filters/inputs/FilterDateTimeInput';
import { FilterDateTimeRangeInput } from '@/components/data-table/display/header/filters/inputs/FilterDateTimeRangeInput';
import { MatchSelector } from '@/components/data-table/display/header/filters/MatchSelector';
import { type FilterFormat, type RuleFormat } from '@/components/data-table/helpers/getRecords';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { FieldSelect } from '@/components/FieldSelect';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';
import { ConnectionInput } from '@/pages/tables/toolkit-sidebar/rules/ConnectionInput';

const operatorsRequiringRangeInput = [
  'is during the next',
  'is during the previous',
  'is before the previous',
  'is after the next',
  'is during the current'
];

export function isRangeRequiredForOperator(operator: string) {
  return operatorsRequiringRangeInput.includes(operator?.toLowerCase());
}

export type InputValueType<T> = {
  defaultValue: string;
  field: T;
};

const operatorsWithoutInputValue = [
  'is blank',
  'is not blank',
  'is any',
  'is before current time',
  'is after current time',
  'is today',
  'is today or before',
  'is today or after',
  'is before today',
  'is after today'
];

export function FilterInputValue(
  props: InputValueType<any> & { field: KnackField; ruleIndex: number }
) {
  const { field, ruleIndex } = props;
  const { watch } = useFormContext();
  const operator = watch(`rules.${ruleIndex}.operator`);

  if (operatorsWithoutInputValue.includes(operator)) return null;
  if (!filterOperatorsByType[field.type]) return null;

  if (field.type === 'multiple_choice') {
    return (
      <MultipleChoiceFieldInput<FilterFormat>
        formFieldName={`rules.${ruleIndex}.value`}
        field={field}
      />
    );
  }
  if (field.type === 'boolean') {
    return (
      <BooleanFieldInput<FilterFormat> formFieldName={`rules.${ruleIndex}.value`} field={field} />
    );
  }

  if (field.type === 'date_time') {
    if (isRangeRequiredForOperator(operator)) {
      return <FilterDateTimeRangeInput formFieldName={`rules.${ruleIndex}`} operator={operator} />;
    }
    return <FilterDateTimeInput formFieldName={`rules.${ruleIndex}.value`} field={field} />;
  }

  if (field.type === 'connection') {
    return (
      <div className="w-full">
        <ConnectionInput name={`rules.${ruleIndex}.value`} {...props} />
      </div>
    );
  }

  return <DefaultInputValue name={`rules.${ruleIndex}.value`} {...props} />;
}

export function getFirstOperator(fields: KnackField[], fieldKey: string): string {
  const field = fields.find((el) => el.key === fieldKey);

  if (!field) return '';

  return filterOperatorsByType[field.type]?.[0] || '';
}

export function OperatorSelect({
  field,
  ruleIndex,
  rule
}: {
  field: KnackField;
  ruleIndex: number;
  rule: RuleFormat;
}) {
  const [t] = useTranslation();
  const { control } = useFormContext();

  if (!filterOperatorsByType[field.type]) {
    return <p className="w-full pl-4">{t('keywords.coming_soon')}</p>;
  }

  return (
    <Controller
      name={`rules.${ruleIndex}.operator`}
      control={control}
      defaultValue={rule.operator || getFirstOperator([field], field.key)}
      render={({ field: { value, onChange } }) => (
        <Select
          value={value}
          onValueChange={(operatorSelected) => {
            if (operatorSelected) {
              onChange(operatorSelected);
            }
          }}
          defaultValue={rule.operator}
        >
          <Select.Trigger
            placeholder={t('components.data_table.filtering.select')}
            className="w-full rounded-lg"
            data-testid={`filter-rule-${ruleIndex}-operator-select`}
          />
          <Select.Content className="max-h-[200px]">
            {filterOperatorsByType[field.type]?.map((operator) => (
              <Select.Item key={operator} value={operator}>
                {t(`operators.${snakeCase(operator)}`)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}

export function Filters({
  onCancel,
  initialFilters,
  filterChange
}: {
  onCancel: () => void;
  filterChange: (format: FilterFormat | null) => void;
  initialFilters: FilterFormat;
}) {
  const [t] = useTranslation();
  const fields = useDataTableStore().use.fields();

  const filterSchema = z.object({
    match: z.string(),
    rules: z.array(
      z.object({
        field: z.string(),
        operator: z.string(),
        value: z.any(),
        range: z.string().optional(),
        type: z.string().optional()
      })
    )
  });

  const formMethods = useForm<FilterFormat>({
    resolver: zodResolver(filterSchema),
    defaultValues: initialFilters
  });

  const { control, getValues, setValue, handleSubmit } = formMethods;
  const {
    fields: ruleFields,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'rules'
  });

  const onFormSubmit = (data: FilterFormat) => {
    if (data.rules.length === 0) {
      filterChange(null);
      return;
    }

    data.rules.map((rule) => {
      const targetField = fields.find((el) => el.key === rule.field);

      if (targetField?.type === 'date_time') {
        if (rule.value.anyDate) {
          rule.value.date = '';
        }
        if (rule.value.anyTime) {
          rule.value.time = '';
          rule.value.hours = '';
          rule.value.minutes = '';
          rule.value.am_pm = '';
        }
        delete rule.value.anyDate;
        delete rule.value.anyTime;
      }
      return rule;
    });

    filterChange(data);
  };

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-col justify-between" onSubmit={handleSubmit(onFormSubmit)}>
        {ruleFields.map((rule, ruleIndex) => {
          const selectedField = getValues(`rules.${ruleIndex}.field`);
          const currentField = fields.find((el) => el.key === selectedField);
          if (!currentField) return null;

          return (
            <div
              // We don't have a unique key, so we have to use the index of the array
              // We also add other rule options to ensure it re-renders when those change
              // eslint-disable-next-line react/no-array-index-key
              key={`${ruleIndex}-${rule.operator}-${rule.field}`}
              className="mb-5 flex items-center gap-2 text-sm"
            >
              <MatchSelector ruleIndex={ruleIndex} name="match" />
              <Controller
                name={`rules.${ruleIndex}.field`}
                control={control}
                defaultValue={rule.field || fields[0].key}
                render={({ field: { value, onChange } }) => (
                  <FieldSelect
                    id="field-select"
                    data-testid={`filter-rule-${ruleIndex}-field-select`}
                    defaultValue={value || fields[0].key}
                    fields={fields}
                    onFieldChange={(fieldSelected) => {
                      onChange(fieldSelected as KnackFieldKey);

                      setValue(`rules.${ruleIndex}.value`, '');

                      const firstOperator = getFirstOperator(
                        fields,
                        fieldSelected as KnackFieldKey
                      );
                      setValue(`rules.${ruleIndex}.operator`, firstOperator);
                    }}
                  />
                )}
              />
              <OperatorSelect field={currentField} ruleIndex={ruleIndex} rule={rule} />
              <FilterInputValue
                {...{
                  ruleIndex,
                  field: currentField,
                  defaultValue: rule.value
                }}
              />
              <Button
                intent="minimal"
                data-testid={`filter-rule-${ruleIndex}-remove`}
                onClick={() => remove(ruleIndex)}
              >
                <CloseIcon size={17} />
              </Button>
            </div>
          );
        })}
        <div>
          <Button
            data-testid="filter-add-condition"
            intent="secondary"
            className={cn('w-auto', { 'ml-[78px]': ruleFields.length > 0 })}
            onClick={() => {
              append({
                field: fields[0].key,
                operator: getFirstOperator(fields, fields[0].key),
                value: ''
              });
            }}
          >
            <PlusIcon className="mr-2" /> {t('components.data_table.filtering.condition')}
          </Button>
        </div>
        <div className="mt-4 flex justify-between gap-2">
          <Button
            className="text-default"
            data-testid="filter-remove"
            intent="minimal"
            onClick={() => {
              setValue('rules', []);
              filterChange(null);
              onCancel();
            }}
          >
            {t('components.data_table.filtering.remove')}
          </Button>
          <div className="flex gap-2">
            <Button
              data-testid="filter-cancel"
              intent="minimal"
              onClick={() => {
                onCancel();
              }}
            >
              {t('components.data_table.filtering.cancel')}
            </Button>
            <Button data-testid="filter-apply" type="submit">
              {t('components.data_table.filtering.filter')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
