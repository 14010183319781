import { cn } from '@/utils/tailwind';

interface DragInsertIndicatorProps {
  isVisible: boolean;
  className?: string;
}

export function DragInsertIndicator({ isVisible, className }: DragInsertIndicatorProps) {
  return (
    <div
      className={cn(
        'hidden h-1 rounded-md bg-emphasis',
        {
          block: isVisible
        },
        className
      )}
    />
  );
}
