import { useEffect, useRef } from 'react';
import LogRocket from 'logrocket';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type Session } from '@/types/session';
import { analytics } from '@/hooks/useSegment';

interface ThirdPartyIntegrationsProps {
  session: Session;
  application: BuilderApplication;
  children: React.ReactNode;
}

export function ThirdPartyIntegrations({
  session,
  application,
  children
}: ThirdPartyIntegrationsProps) {
  const hasInitializedIntegrations = useRef(false);

  useEffect(() => {
    const initializeThirdPartyIntegrations = async () => {
      if (analytics) {
        await analytics.identify(session.user.id, {
          email: session.user.email
        });
      }

      if (import.meta.env.PUBLIC_LOGROCKET_APP_ID && !application.account.isHipaa) {
        LogRocket.identify(session.user.id, {
          name: `${session.user.firstName} ${session.user.lastName}`,
          email: session.user.email,
          account_id: application.account.id,
          plan_id: application.account.productPlan.id
        });
      }
    };

    if (session.user.id !== '') {
      if (!hasInitializedIntegrations.current) {
        void initializeThirdPartyIntegrations();
        hasInitializedIntegrations.current = true;
      }
    } else if (analytics) {
      void analytics.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return children;
}
