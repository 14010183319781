import { type BuilderView } from '@/types/schema/BuilderView';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

export function useUpdateView<T extends BuilderView>() {
  const { view } = useActiveViewContext<T>();
  const { updatePage } = usePageEditorContext();

  function updateViewSchema(viewSchema: Partial<T>) {
    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: view.key,
      viewSchema: viewSchema satisfies Partial<T>
    });
  }

  return updateViewSchema;
}
