import { useTranslation } from 'react-i18next';
import { Label, Switch, Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { BarType, ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { Contrast } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/Contrast';
import { LegendPlacement } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/LegendPlacement';
import { ShowLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowLabels';
import { TiltLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/TiltLabels';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function BarChartAppearanceSettings() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const barTypes: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.column')}</span>,
      value: 'column'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.row')}</span>,
      value: 'bar'
    }
  ];

  return (
    <>
      <div className="mb-4">
        <div className="mb-2 text-sm">
          {t('pages.element_settings.report.categories.general.bar_display')}
        </div>
        <Tabs
          defaultValue={view.rows[0].reports[0].layout.bar_type}
          onValueChange={(value) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].layout.bar_type = value as BarType;
            updateViewSchema(newSchema);
          }}
        >
          <Tabs.List items={barTypes} shouldDisableResponsive />
        </Tabs>
      </div>
      <TiltLabels />
      <ShowLabels />
      <div className="flex items-center gap-2">
        <Switch
          id="stack-groups"
          checked={view.rows[0].reports[0].layout.stacking === 'normal'}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].layout.stacking = state ? 'normal' : 'none';
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="stack-groups">
          {`${t('pages.element_settings.report.categories.general.stack_groups')} (Coming Soon)`}
        </Label>
      </div>
      <Contrast />
      <LegendPlacement />
    </>
  );
}
