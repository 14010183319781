import formatHelper from '@knack/format-helper';

export function capitalize(value: string) {
  return `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}`;
}

export function capitalizeAllWords(value: string) {
  return value
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

/**
 * Formats bytes (using binary base 2) into a string with the correct unit as suffix. Will use KB as the unit if value is zero.
 * @param {number} valueInBytes - The value (in bytes) to be formatted
 * @return {string} Formatted string with its corresponding unit suffix
 */
export function formatNumberForStorage(valueInBytes: number): string {
  if (valueInBytes <= 0) return '0 KB';

  const kilobytes = valueInBytes / 1024;

  if (kilobytes >= 1024) {
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1024) {
      return `${formatHelper.formatNumber(megabytes / 1024, {
        precision: 1
      })} GB`;
    }

    return `${formatHelper.formatNumber(megabytes, {
      precision: 1
    })} MB`;
  }

  return `${formatHelper.formatNumber(kilobytes, {
    precision: 1
  })} KB`;
}

/**
 * Formats a slug by removing invalid characters and replacing consecutive hyphens or underscores with a single hyphen.
 * Also removes preceding hyphens from the start of the string.
 * @param {string} slug - The original slug to be formatted.
 * @returns {string} The formatted slug.
 */
export function formatSlug(slug: string): string {
  const trimmedSlug = slug.trim().toLowerCase();

  const sanitizedSlug = trimmedSlug
    .replace(/[^a-z0-9-\s]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+/, '');

  return sanitizedSlug;
}

/**
 * Sanitize a string by removing any HTML tags.
 * @param {string} value - The string to be sanitized.
 * @returns {string} The sanitized string.
 * @example
 * sanitizeHTML('<p>hello</p>'); // 'hello'
 */

export function sanitizeHTMLTags(value: string): string {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent || div.innerText || '';
}
