export function DataModelIcon({
  size = 16,
  className = ''
}: {
  size?: string | number;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      className={className}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3164 5.50008C18.5425 5.50008 19.5365 4.47407 19.5365 3.20841C19.5365 1.94276 18.5425 0.916748 17.3164 0.916748C16.0903 0.916748 15.0964 1.94276 15.0964 3.20841C15.0964 3.6539 15.2195 4.06969 15.4326 4.42151L12.0252 8.37671C11.6178 8.14974 11.1516 8.02091 10.6562 8.02091C10.1006 8.02091 9.58158 8.18302 9.14123 8.46392L5.52087 4.72677C5.68137 4.40978 5.77214 4.04921 5.77214 3.66675C5.77214 2.4011 4.77818 1.37508 3.55208 1.37508C2.32598 1.37508 1.33203 2.4011 1.33203 3.66675C1.33203 4.9324 2.32598 5.95842 3.55208 5.95842C3.92259 5.95842 4.2719 5.86473 4.57898 5.69905L8.19934 9.43619C7.92722 9.89075 7.77018 10.4265 7.77018 11.0001C7.77018 11.2067 7.79056 11.4084 7.82935 11.6031L5.63355 12.6609C5.22767 12.2035 4.64433 11.9167 3.99609 11.9167C2.76999 11.9167 1.77604 12.9428 1.77604 14.2084C1.77604 15.4741 2.76999 16.5001 3.99609 16.5001C5.22219 16.5001 6.21615 15.4741 6.21615 14.2084C6.21615 14.1062 6.20966 14.0055 6.19709 13.9068L8.39306 12.8489C8.85307 13.4482 9.53166 13.8604 10.3045 13.9573L10.7005 17.6365C10.1453 17.9464 9.76823 18.5528 9.76823 19.2501C9.76823 20.2626 10.5634 21.0834 11.5443 21.0834C12.5252 21.0834 13.3203 20.2626 13.3203 19.2501C13.3203 18.4093 12.772 17.7007 12.0244 17.4845L11.6284 13.806C12.412 13.5167 13.0389 12.8876 13.3428 12.0907L15.1479 12.3569C15.3389 13.1569 16.0385 13.7501 16.8724 13.7501C17.8533 13.7501 18.6484 12.9293 18.6484 11.9167C18.6484 10.9042 17.8533 10.0834 16.8724 10.0834C16.2167 10.0834 15.6441 10.4502 15.3364 10.9958L13.5306 10.7295C13.4841 10.1971 13.302 9.70513 13.0202 9.29064L16.444 5.31638C16.7118 5.43462 17.0067 5.50008 17.3164 5.50008Z"
      />
    </svg>
  );
}
