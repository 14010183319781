import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Select, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BooleanField } from '@/types/schema/fields';
import { BOOLEAN_FORMAT_OPTIONS, BOOLEAN_INPUT_OPTIONS } from '@/types/schema/fields/BooleanField';

const MAX_CHECKBOX_LABEL_LENGTH = 100;

type BooleanFormSettingsProps = {
  field: BooleanField;
};

export const booleanSchema = z.object({
  format: z.object({
    input: z.enum(BOOLEAN_INPUT_OPTIONS),
    format: z.enum(BOOLEAN_FORMAT_OPTIONS),
    required: z.boolean().default(false),
    default: z.boolean().default(false),
    text: z.string().max(MAX_CHECKBOX_LABEL_LENGTH).optional()
  }),
  required: z.boolean()
});

export function BooleanFormSettings({ field }: BooleanFormSettingsProps) {
  const [enableCheckboxTextLabel, setEnableCheckboxTextLabel] = useState(!!field?.format?.text);
  const [t] = useTranslation();

  const { getValues } = useFormContext();

  const isRadioButtonsSelected = getValues('format.input') === 'radios';
  const isCheckboxSelected = getValues('format.input') === 'checkbox';

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="display-as-select">
          {t('components.data_table.attributes.field_settings.boolean.input_label')}
        </Label>
        <Controller
          name="format.input"
          defaultValue={field?.format?.input || BOOLEAN_INPUT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || BOOLEAN_INPUT_OPTIONS[0]}
            >
              <Select.Trigger
                id="display-as-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="boolean-form-settings-display-as-select"
                {...currentField}
              />
              <Select.Content>
                {BOOLEAN_INPUT_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`boolean-field-settings-input-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.boolean.input_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
        {isRadioButtonsSelected && (
          <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
            {t('components.data_table.attributes.field_settings.boolean.radio_buttons_info')}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="labels-select">
          {t('components.data_table.attributes.field_settings.boolean.format_label')}
        </Label>
        <Controller
          name="format.format"
          defaultValue={field?.format?.format || BOOLEAN_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || BOOLEAN_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="labels-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="boolean-form-settings-labels-select"
                {...currentField}
              />
              <Select.Content>
                {BOOLEAN_FORMAT_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`boolean-field-settings-format-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.boolean.format_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      {isCheckboxSelected && (
        <>
          <div className="flex items-center">
            <Controller
              name="format.text"
              defaultValue={field?.format?.text || undefined}
              render={({ field: { onChange } }) => (
                <Switch
                  id="checkbox-text-switch"
                  className="mr-2"
                  data-testid="checkbox-text-switch"
                  checked={enableCheckboxTextLabel}
                  onCheckedChange={(checked) => {
                    onChange(checked ? '' : undefined);
                    setEnableCheckboxTextLabel(checked);
                  }}
                />
              )}
            />
            <Label htmlFor="checkbox-text-switch">
              {t(
                'components.data_table.attributes.field_settings.boolean.enable_checkbox_text_label'
              )}
            </Label>
          </div>
          {enableCheckboxTextLabel && (
            <div className="flex flex-col gap-2 pl-12">
              <Label htmlFor="checkbox-label">
                {t('components.data_table.attributes.field_settings.boolean.checkbox_text_label')}
              </Label>
              <Controller
                name="format.text"
                defaultValue={field?.format?.text || undefined}
                render={({ field: currentField }) => (
                  <Input
                    placeholder={t(
                      'components.data_table.attributes.field_settings.boolean.checkbox_text_placeholder'
                    )}
                    id="checkbox-label"
                    className="rounded-lg p-2"
                    data-testid="boolean-form-settings-checkbox-label"
                    {...currentField}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
