import { useMutation } from '@tanstack/react-query';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { useApplication } from '@/hooks/useApplication';
import { getDomainAsCrm } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

interface ReportBugData {
  bugLocation: 'Builder' | 'Interface';
  bugUrl: string;
  bugDescription: string;
}

async function reportBug(data: ReportBugData, application: BuilderApplication) {
  const url = `/v1/applications/${application.id}/help/report-bug`;
  const ticketData = {
    devLink: getDomainAsCrm(),
    ...data
  };

  const response = await axiosInstance.post(url, ticketData, {
    withCredentials: true
  });

  return response;
}

export function useReportBugMutation() {
  const application = useApplication();

  return useMutation({
    mutationFn: (data: ReportBugData) => {
      if (!application) throw new Error('Application not found');
      return reportBug(data, application);
    }
  });
}
