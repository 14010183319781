import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowDescription() {
  const [t] = useTranslation();
  const { selectedViewType, selectedViewSource, selectedFormRecordAction, currentStep } =
    useAddRecordViewFlowContext();

  const getDescription = () => {
    if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
      if (selectedViewType === 'form') {
        if (selectedFormRecordAction === 'update') {
          return t('views.add_view_dialog.form_view_select_source_table_description_update');
        }
        return t('views.add_view_dialog.form_view_select_source_table_description_add');
      }
      if (selectedViewType === 'search') {
        return t('views.add_view_dialog.search_view_select_source_table_description');
      }

      return t('views.add_view_dialog.generic_view_select_source_table_description', {
        viewName: t(`views.view_names.${selectedViewType}`)
      });
    }

    if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
      if (selectedViewSource?.recordDisplayQuantity === 'one') {
        if (selectedViewType === 'form') {
          return t(
            'views.add_view_dialog.form_view_select_record_display_option_description_update'
          );
        }
        return t(
          'views.add_view_dialog.generic_view_select_record_display_option_description_singular',
          {
            viewName: t(`views.view_names.${selectedViewType}`)
          }
        );
      }
      if (selectedViewType === 'form') {
        return t('views.add_view_dialog.form_view_select_record_display_option_description_add');
      }
      return t('views.add_view_dialog.generic_view_select_record_display_option_description', {
        viewName: t(`views.view_names.${selectedViewType}`)
      });
    }

    return t('views.add_view_dialog.confirm_add_view.description');
  };

  return <Dialog.Description>{getDescription()}</Dialog.Description>;
}
