import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@knack/asterisk-react';

import { type NameField } from '@/types/schema/fields';
import {
  DEFAULT_FORMAT_VALUE,
  TITLES
} from '@/components/data-table/display/fields/name/constants';

export function PersonInput({
  targetField,
  name,
  id
}: {
  targetField: NameField;
  name: string;
  id: string;
}) {
  const format = targetField?.format;
  const nameFormat = format?.format || DEFAULT_FORMAT_VALUE;
  const [t] = useTranslation();
  const { control, register } = useFormContext();

  return (
    <div className="flex gap-2" data-testid="person-input">
      {nameFormat === 'Title First Last' && (
        <Controller
          control={control}
          name={`${name}.value.title`}
          render={({ field }) => (
            <Select
              data-testid="default-values-title-select"
              defaultValue="none"
              value={field.value}
              onValueChange={(selectedOption) => field.onChange(selectedOption)}
            >
              <Select.Trigger />
              <Select.Content className="min-w-32">
                {TITLES.map((title) => (
                  <Select.Item key={title} value={title || 'none'}>
                    {title}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      )}
      <Input
        id={id}
        placeholder={t('components.add_table.subfield_types.first_name')}
        {...register(`${name}.first`)}
      />
      {(nameFormat === 'First Middle Last' || nameFormat === 'Last, First Middle') && (
        <Input
          id={id}
          placeholder={t('components.add_table.subfield_types.middle_name')}
          {...register(`${name}.middle`)}
        />
      )}
      <Input
        id={id}
        placeholder={t('components.add_table.subfield_types.last_name')}
        {...register(`${name}.last`)}
      />
    </div>
  );
}
