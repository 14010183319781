import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type PageEditorItemToSelect, type PageEditorSelectedItem } from './types';

export function getSelectedItem(
  itemToSelect: PageEditorItemToSelect,
  originPage: BuilderPage
): PageEditorSelectedItem {
  if (!itemToSelect) {
    return null;
  }

  if (itemToSelect.type === 'view') {
    const viewToSelect = originPage.views.find((view) => view.key === itemToSelect.viewKey) || null;

    if (!viewToSelect) {
      return null;
    }

    return {
      type: itemToSelect.type,
      view: viewToSelect
    };
  }

  if (itemToSelect.type === 'section') {
    const sectionToSelect =
      originPage.groups.find((section) => section.id === itemToSelect.sectionId) || null;

    if (!sectionToSelect) {
      return null;
    }

    return {
      type: itemToSelect.type,
      section: sectionToSelect
    };
  }

  return null;
}
