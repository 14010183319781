import { useMemo } from 'react';

import { useTemplateKeyName } from '@/components/field-settings/utils/rich-text/useTemplateKeyName';
import { EmailTemplatesAddedVariables } from '@/pages/roles/role-settings/EmailAddedVariables';

export function useEmailTemplateKeyName() {
  const { templateKeyVariables, templateNameVariables } = useTemplateKeyName();

  const emailTemplateKeyVariables = useMemo(
    () => [
      ...templateKeyVariables,
      ...EmailTemplatesAddedVariables.map((emailTemplateVariable) => emailTemplateVariable.key)
    ],
    [templateKeyVariables]
  );

  const emailTemplateNameVariables = useMemo(
    () => [
      ...templateNameVariables,
      ...EmailTemplatesAddedVariables.map((emailTemplateVariable) => emailTemplateVariable.name)
    ],
    [templateNameVariables]
  );

  return {
    emailTemplateKeyVariables,
    emailTemplateNameVariables
  };
}
