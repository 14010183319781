import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox, Label } from '@knack/asterisk-react';

import { useCreateTemplateObjectsMutation } from '@/hooks/api/mutations/useCreateTemplateObjectsMutation';
import { cn } from '@/utils/tailwind';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';
import { WizardLayout } from '@/components/layout/WizardLayout';

interface SampleTableCardProps {
  table: SampleTable;
  isSelected: boolean;
  onClick: (table: SampleTable) => void;
}

export interface SampleTable {
  name: string;
  key: string;
  fields: string[];
}

const sampleTables: SampleTable[] = [
  {
    name: 'Companies',
    key: 'company',
    fields: [
      'Company Name',
      'Logo',
      'Website',
      'Industry',
      'Description',
      'Telephone',
      'Address',
      'Notes'
    ]
  },
  {
    name: 'Employees',
    key: 'employee',
    fields: ['Name', 'Image', 'Address', 'Email', 'Phone', 'Position', 'Start Date', 'Notes']
  },
  {
    name: 'Contacts',
    key: 'contact',
    fields: ['Name', 'Image', 'Birthday', 'Address', 'Email', 'Phone', 'Occupation', 'Notes']
  },
  {
    name: 'Products',
    key: 'product',
    fields: ['Name', 'Product ID', 'Image', 'Description', 'Price', 'Available']
  },
  {
    name: 'Orders',
    key: 'order',
    fields: [
      'Order ID',
      'Order Name',
      'Status',
      'Description',
      'Total',
      'Order Date',
      'Shipping Date',
      'Customer',
      'Shipping Address',
      'Shipping Type'
    ]
  }
];

function SampleTableCard({ table, isSelected, onClick }: SampleTableCardProps) {
  const { t } = useTranslation();
  const handleCardClick = useCallback(() => onClick(table), [table, onClick]);

  return (
    <Label
      htmlFor={`sample-table-${table.key}`}
      className="cursor-pointer"
      data-testid={`sample-table-${table.key}-label`}
    >
      <Card
        className={cn(
          'relative flex min-h-[80px] space-x-3 border border-subtle text-left shadow-none sm:px-4 sm:py-5',
          isSelected && 'border-default bg-muted'
        )}
      >
        <Checkbox
          id={`sample-table-${table.key}`}
          data-testid={`sample-table-${table.key}-checkbox`}
          checked={isSelected}
          onCheckedChange={handleCardClick}
          className="self-start"
        />

        <div className="space-y-2">
          <p className="text-base font-semibold leading-none">{`${table.name} ${t(
            'components.add_table.table'
          )}`}</p>
          <p className="text-sm text-subtle">
            {t('components.add_table.fields_included')}: {table.fields.join(', ')}
          </p>
        </div>
      </Card>
    </Label>
  );
}

export function CreateTableFromSampleList({ onBack }: { onBack: () => void }) {
  const { t } = useTranslation();
  const [selectedTables, setSelectedTables] = useState<SampleTable[]>([]);
  const createTemplateObjects = useCreateTemplateObjectsMutation();

  const handleImportTables = () => {
    createTemplateObjects.mutate(selectedTables.map(({ key }) => key));
  };

  const handleCardClick = (tableSelected: SampleTable) => {
    if (selectedTables.find((table) => table.key === tableSelected.key)) {
      setSelectedTables(selectedTables.filter((table) => table.key !== tableSelected.key));
    } else {
      setSelectedTables([...selectedTables, tableSelected]);
    }
  };

  return (
    <>
      <WizardLayout
        title={t('components.add_table.sample_list_title')}
        contentWidth="full"
        onClose={onBack}
        hasFooter
      >
        <div className="mx-auto mt-6 max-w-[520px] px-7">
          <ApiErrorBanner
            translationPrefix="components.add_table.errors"
            error={createTemplateObjects?.error}
            data-testid="error-banner"
            fallbackKey="errors.generic_error"
          />
          <div className="-mx-14">
            <p className="mb-6">{t('components.add_table.select_templates_intro')}</p>
            <div className="grid grid-cols-1 gap-2">
              {sampleTables.map((table: SampleTable) => (
                <SampleTableCard
                  key={table.key}
                  table={table}
                  isSelected={selectedTables.some(
                    (selectedTable) => selectedTable.key === table.key
                  )}
                  onClick={handleCardClick}
                />
              ))}
            </div>
          </div>
        </div>
      </WizardLayout>
      <div className="fixed bottom-0 flex h-16 w-full items-center justify-center bg-base px-7 shadow-sm">
        {t('components.add_table.step', { step1: 2, step2: 2 })}
        <div className="fixed right-0">
          <Button intent="secondary" onClick={onBack} data-testid="go-back-button">
            {t('actions.back')}
          </Button>
          <Button
            className="mx-4"
            onClick={handleImportTables}
            disabled={!selectedTables.length}
            data-testid="import-sample-tables-button"
          >
            {t('components.add_table.create_x_tables', { count: selectedTables.length })}
          </Button>
        </div>
      </div>
    </>
  );
}
