import { type DateTimeFieldConditionalRuleValue } from '@/types/schema/fields';
import { getRuleTimeFormatted } from '@/utils/rules/getRuleTimeFormatted';
import i18n from '@/i18n';

export function getDateTimeConditionalRuleActionValue(value: DateTimeFieldConditionalRuleValue) {
  const { all_day: allDay, date, hours, minutes, to } = value;

  const timeFormatted = getRuleTimeFormatted(hours, minutes);
  const fullDateFormatted = [date || '', allDay ? '' : timeFormatted].join(' ').trim();

  if (to) {
    const { date: toDate, hours: toHours, minutes: toMinutes } = to;
    const toTimeFormatted = getRuleTimeFormatted(toHours, toMinutes);
    const toFullDateFormatted = [toDate || '', allDay ? '' : toTimeFormatted].join(' ').trim();

    return i18n.t('attributes.field_labels.date_time.from_to', {
      from: fullDateFormatted,
      to: toFullDateFormatted
    });
  }

  return fullDateFormatted;
}
