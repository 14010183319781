import { Fragment } from 'react';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { Label } from '@knack/asterisk-react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { useAddRecordViewFlowContext } from './AddRecordViewFlowContext';
import { ConnectionDisambiguation } from './ConnectionDisambiguation';

export function ViewSourcePathList() {
  const {
    selectedViewSource,
    selectedViewSourcePath,
    setSelectedViewSourcePath,
    setSelectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey
  } = useAddRecordViewFlowContext();

  if (!selectedViewSource) {
    return null;
  }

  const handleSelectViewSourcePath = (value: string) => {
    const newSelectedViewSourcePath = selectedViewSource.paths.find((path) => path.key === value);

    if (!newSelectedViewSourcePath) {
      return;
    }

    setSelectedViewSourcePath(newSelectedViewSourcePath);

    if (!newSelectedViewSourcePath.connections) {
      return;
    }

    // If there are multiple direct connections the user can choose from, default to the first one
    const hasMultipleDirectConnections = newSelectedViewSourcePath.connections.direct.length > 1;
    if (hasMultipleDirectConnections) {
      setSelectedViewSourcePathDirectConnectionFieldKey(
        newSelectedViewSourcePath.connections.direct[0].field.key
      );
    } else {
      setSelectedViewSourcePathDirectConnectionFieldKey(undefined);
    }

    // If there are multiple parent connections the user can choose from, default to the first one
    const hasMultipleParentConnections = newSelectedViewSourcePath.connections.parent.length > 1;
    if (hasMultipleParentConnections) {
      setSelectedViewSourcePathParentConnectionFieldKey(
        newSelectedViewSourcePath.connections.parent[0].field.key
      );
    } else {
      setSelectedViewSourcePathParentConnectionFieldKey(undefined);
    }
  };

  return (
    <RadioGroupPrimitive.Root
      value={selectedViewSourcePath?.key}
      onValueChange={(value) => handleSelectViewSourcePath(value)}
    >
      {selectedViewSource.paths.map((sourcePath) => (
        <Fragment key={sourcePath.key}>
          <RadioGroupPrimitive.Item
            id={sourcePath.key}
            value={sourcePath.key}
            className="flex w-full items-center rounded-lg border border-default bg-default p-2 text-sm font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis data-[state=checked]:border-emphasis data-[state=checked]:bg-subtle data-[state=checked]:text-emphasis [&:not(:last-child)]:mb-2"
          >
            <DatabaseIcon size={16} className="mr-2 shrink-0" />
            <Label
              htmlFor={sourcePath.key}
              className="pointer-events-none text-left font-normal text-default"
            >
              {sourcePath.label}
            </Label>
          </RadioGroupPrimitive.Item>

          <ConnectionDisambiguation sourcePathKey={sourcePath.key} />
        </Fragment>
      ))}
    </RadioGroupPrimitive.Root>
  );
}
