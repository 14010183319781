import { type KnackField } from '@/types/schema/KnackField';
import { DEFAULT_ROW_HEIGHT, ROW_HEIGHTS } from './constants';

export function getRowHeight(tableColumnSchema: KnackField[]): number {
  let maxRowHeight = DEFAULT_ROW_HEIGHT;
  tableColumnSchema.forEach((column) => {
    const currentColumnType = ROW_HEIGHTS[column.type];
    if (!!currentColumnType && currentColumnType > maxRowHeight) {
      maxRowHeight = currentColumnType;
    }
  });

  return maxRowHeight;
}
