import { useTranslation } from 'react-i18next';
import { Badge, Label, Switch } from '@knack/asterisk-react';

interface ViewSettingsSwitchProps {
  label: string;
  disabled?: boolean;
  checked: boolean;
  isComingSoon?: boolean;
  onCheckedChange?: (value: boolean) => void;
}

export function ViewSettingsSwitch({
  label,
  disabled,
  checked,
  isComingSoon,
  onCheckedChange
}: ViewSettingsSwitchProps) {
  const [t] = useTranslation();

  return (
    <Label className="flex items-center gap-2">
      <Switch checked={checked} disabled={disabled} onCheckedChange={onCheckedChange} />
      {label}
      {isComingSoon && (
        <Badge className="px-2 py-0.5 text-xs text-default">{t('keywords.coming_soon')}</Badge>
      )}
    </Label>
  );
}
