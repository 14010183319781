import { type BaseKnackField } from '@/types/schema/KnackField';

export const CURRENCY_FORMAT_OPTIONS = ['$', '£', '€_after', '€'] as const;

export interface CurrencyField extends BaseKnackField {
  type: 'currency';
  format?: {
    format: (typeof CURRENCY_FORMAT_OPTIONS)[number];
  };
}
