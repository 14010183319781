import { useTranslation } from 'react-i18next';
import { Divider } from '@knack/asterisk-react';

import { CollapsibleSection } from '@/pages/pages/page-tree/CollapsibleSection';

export function PageTree() {
  const [t] = useTranslation();

  return (
    <div className="pt-4">
      <CollapsibleSection
        sectionName={t('pages.page_tree.standard_pages')}
        testId="page-tree-collapsible-section-standard-pages"
      />
      <Divider className="my-4" />
      <CollapsibleSection
        sectionName={t('pages.page_tree.user_pages')}
        isUserPages
        testId="page-tree-collapsible-section-user-pages"
      />
    </div>
  );
}
