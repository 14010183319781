import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { nanoid } from 'nanoid';

import { AddOption } from '@/components/field-settings/multiple-choice/AddOption';
import { OptionsDraggableList } from '@/components/field-settings/multiple-choice/OptionsDraggableList';
import { SortingSwitch } from '@/components/field-settings/multiple-choice/SortingSwitch';
import { TypeSelect } from '@/components/field-settings/multiple-choice/TypeSelect';

export type MultipleChoiceDraggableOption = {
  id: string;
  option: string;
};

type FormContentProps = {
  options: MultipleChoiceDraggableOption[];
  isImmutable: boolean;
};

const DEFAULT_NEW_OPTION_VALUE = 'Another Choice';

export function FormContent({ options: optionsWithIds, isImmutable }: FormContentProps) {
  const { setValue } = useFormContext();
  const [options, setOptions] = useState(optionsWithIds);

  const onAddOption = () => {
    const newOption = {
      id: nanoid(5),
      option: `${DEFAULT_NEW_OPTION_VALUE} ${options.length + 1}`
    };
    setOptions([...options, newOption]);
    setValue('format.sorting', 'custom');
  };

  const onSortOptions = () => {
    setOptions([...options].sort((a, b) => a.option.localeCompare(b.option)));
  };

  useEffect(() => {
    setValue(
      'format.options',
      options.map(({ option }) => option)
    );
  }, [options, setValue]);

  return (
    <div className="flex flex-col gap-4" data-testid="options-list-container">
      {!isImmutable && <TypeSelect />}
      <OptionsDraggableList options={options} setOptions={setOptions} isImmutable={isImmutable} />
      {!isImmutable && <AddOption onAddOption={onAddOption} />}
      <SortingSwitch onSortOptions={onSortOptions} />
    </div>
  );
}
