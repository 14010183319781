import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  defaultAppTheme,
  type AppTheme,
  type ColorPreset,
  type Theming
} from '@knack/asterisk-react';
import { produce } from 'immer';

import { useThemingQuery } from '@/hooks/api/queries/useThemingQuery';
import { useApplication } from '@/hooks/useApplication';
import { useThemesMessagingContext } from '@/pages/themes/theme-editor/MessagingContext';
import { type PageUrlParams } from '@/Router';

type ThemesPageContextState = {
  theme: AppTheme;
  setThemeField: (updater: (draft: AppTheme) => void) => void;
  theming: Theming | undefined;
  isLoadingTheming: boolean;
} | null;

type ThemesPageContextProviderProps = {
  children: React.ReactNode;
};

export const DEFAULT_KNACK_APP_THEME_COLOR_PRESET: ColorPreset = 'blue';
export const DEFAULT_KNACK_APP_THEME: AppTheme = {
  ...defaultAppTheme,
  colors: {
    ...defaultAppTheme.colors,
    preset: DEFAULT_KNACK_APP_THEME_COLOR_PRESET
  }
};

const ThemesPageContext = createContext<ThemesPageContextState>(null);

export function ThemesPageContextProvider({ children }: ThemesPageContextProviderProps) {
  const { sendMessageToLiveApp, messageFromLiveApp } = useThemesMessagingContext();
  const application = useApplication();
  const { data: theming, isLoading: isLoadingTheming } = useThemingQuery();
  const { id: themeId } = useParams<PageUrlParams>();
  const [theme, setTheme] = useState<AppTheme>(DEFAULT_KNACK_APP_THEME);
  const [isSyncronized, setIsSyncronized] = useState<boolean>(false);

  function setThemeField(updater: (draft: AppTheme) => void) {
    setTheme((prevTheme) => produce(prevTheme, updater));
  }

  // Detect the new theme id, look for it in the theming data and set it as the current theme
  useEffect(() => {
    const selectedTheme: AppTheme | undefined = theming?.appThemes?.find(
      (appTheme) => appTheme.id === themeId
    );
    if (selectedTheme) {
      setTheme(selectedTheme);
    }
  }, [themeId, theming, application]);

  // Wait for the Live App to be ready and send the theme to it
  useEffect(() => {
    if (!messageFromLiveApp) {
      return;
    }

    if (messageFromLiveApp.action === 'request-page-sync' && !isSyncronized) {
      sendMessageToLiveApp({
        action: 'set-theme',
        theme
      });
      setIsSyncronized(true);
    }
  }, [messageFromLiveApp, sendMessageToLiveApp, theme, isSyncronized]);

  // Update the theme in the Live App when the theme settings change
  useEffect(() => {
    if (theme && isSyncronized) {
      sendMessageToLiveApp({
        action: 'set-theme',
        theme
      });
    }
  }, [theme, isSyncronized, sendMessageToLiveApp]);

  const contextValue = useMemo(
    () => ({
      theme,
      setThemeField,
      theming,
      isLoadingTheming
    }),
    [theme, theming, isLoadingTheming]
  );

  return <ThemesPageContext.Provider value={contextValue}>{children}</ThemesPageContext.Provider>;
}

export const useThemesPageContext = () => {
  const context = useContext(ThemesPageContext);
  if (!context) {
    throw new Error('useThemesPageContext must be used within a ThemesPageContextProvider');
  }
  return context;
};
