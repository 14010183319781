import { useCallback } from 'react';
import { useDropzone, type FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';
import { type AxiosError } from 'axios';
import { type TFunction } from 'i18next';

import { useApplication } from '@/hooks/useApplication';
import { axiosInstance } from '@/utils/axiosConfig';
import { type FileField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { apiHelper } from '@/components/data-table/helpers/apiHelper';
import {
  useDataTableStore,
  type ServerErrorResponseType
} from '@/components/data-table/useDataTableStore';

const MAX_FILE_SIZE_IN_BYTES = 5 * 1000 * 1000;

export const getFileFieldUploadErrorMessage = (error: FileError, t: TFunction) => {
  switch (error.code) {
    case 'file-too-large':
      return t('components.data_table.errors.file_too_large', {
        size: MAX_FILE_SIZE_IN_BYTES / 1000 / 1000
      });
    case 'file-invalid-type':
      return t('components.data_table.errors.file_invalid');
    case 'too-many-files':
      return t('components.data_table.errors.too_many_files');
    default:
      return t('components.data_table.errors.file_invalid');
  }
};

export function useFileUpload(props: FieldRenderProps<FileField>) {
  const { rowId, fieldId } = props;
  const selectedCell = useDataTableStore().use.selectedCell();
  const { saveCell, setFileIsBeingUploaded, cancelFieldEdit, setCellErrors, clearCellErrors } =
    useDataTableStore().use.actions();
  const { presentToast } = useToast();
  const [t] = useTranslation();
  const application = useApplication();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0 || !application?.id) {
        return;
      }
      clearCellErrors(rowId, fieldId);

      const previewName = acceptedFiles[0]?.name;
      // We don't have the asset id until we upload it to the server.
      let currentAssetId = '';

      setFileIsBeingUploaded(rowId, fieldId, true);

      await saveCell(rowId, fieldId, currentAssetId, {
        rawValue: { url: previewName, thumb_url: previewName },
        value: previewName
      });

      try {
        const response = await apiHelper(axiosInstance).uploadFileAsset({
          applicationId: application.id,
          fieldId,
          file: acceptedFiles[0]
        });

        setFileIsBeingUploaded(rowId, fieldId, false);

        // Now we have the asset id and can update the cell
        currentAssetId = response.data[0].id;
        await saveCell(rowId, fieldId, currentAssetId, {
          rawValue: {
            ...response.data[0],
            url: response.data[0].public_url,
            application_id: application?.id,
            s3: true,
            field_key: fieldId
          },
          value: response.data[0].filename
        });
      } catch (error) {
        const errorResponse = error as AxiosError<ServerErrorResponseType>;

        if (errorResponse.response?.data.errors) {
          setCellErrors(rowId, fieldId, errorResponse.response.data.errors);
        } else {
          setCellErrors(rowId, fieldId, [
            {
              message: t('components.data_table.errors.file_invalid')
            }
          ]);
        }

        cancelFieldEdit(rowId, fieldId);
        setFileIsBeingUploaded(rowId, fieldId, false);
      }
    },
    [
      application?.id,
      cancelFieldEdit,
      clearCellErrors,
      fieldId,
      rowId,
      saveCell,
      setCellErrors,
      setFileIsBeingUploaded,
      t
    ]
  );

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    onDropRejected: (rejections) => {
      rejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          presentToast({
            title: getFileFieldUploadErrorMessage(error, t)
          });
        });
      });
    },
    onDrop,
    noClick: selectedCell?.isEditing,
    noKeyboard: selectedCell?.isEditing
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    fileRejections
  };
}
