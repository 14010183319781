import { useMutation } from '@tanstack/react-query';

import { type KnackRecord } from '@/types/schema/KnackRecord';
import { ApiWrapper } from '@/utils/api-wrapper';

export interface UpdateRecordPayload {
  objectKey: string;
  recordId: string;
  data: unknown;
  assets?: RecordAsset[];
}

export interface RecordAsset {
  type: string;
  content: {
    size: number;
    type: string;
  };
  filename: string;
  fieldKey: string;
}

export async function updateRecord({
  objectKey,
  recordId,
  data,
  assets
}: UpdateRecordPayload): Promise<KnackRecord> {
  const updatedRecord = await ApiWrapper.updateRecord(objectKey, recordId, data, assets);
  return updatedRecord;
}

export function useUpdateRecordMutation() {
  return useMutation({
    mutationFn: updateRecord
  });
}
