import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, RichTextEditor, Switch } from '@knack/asterisk-react';
import { type Editor } from '@tiptap/core';

type DescriptionProps = {
  description?: string;
};

export function Description({ description }: DescriptionProps) {
  const editorRef = useRef<Editor>();
  const [t] = useTranslation();

  const [shouldShowDescriptionValue, setShouldShowDescriptionValue] = useState(!!description);

  const { setValue } = useFormContext();

  return (
    <div className="flex">
      <Switch
        className="mr-2"
        data-testid="description-value-switch"
        checked={shouldShowDescriptionValue}
        onCheckedChange={() => {
          if (shouldShowDescriptionValue) {
            setValue('meta.description', '');
          }
          setShouldShowDescriptionValue(!shouldShowDescriptionValue);
        }}
      />
      <div className="flex flex-grow flex-col">
        <Label htmlFor="field-settings-description-input">
          {t('components.data_table.attributes.field_settings.common.add_description')}
        </Label>
        {shouldShowDescriptionValue && (
          <RichTextEditor
            toolBarOptions={['bold', 'italic', 'link']}
            data-testid="description-editor-value"
            className="mt-2 size-full rounded-lg bg-base ring-2 ring-black"
            editorProps={{
              attributes: {
                class: `size-full`
              }
            }}
            onCreate={({ editor }) => {
              // We save the editor instance so we can use it anywhere
              editorRef.current = editor;
              editorRef.current?.commands.focus('end');
            }}
            onUpdate={({ editor }) => {
              setValue('meta.description', editor.getHTML(), {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true
              });
            }}
            content={description || ''}
          />
        )}
      </div>
    </div>
  );
}
