/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useRef } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type ConnectionField } from '@/types/schema/fields';
import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { createCustomSelectors, createSelectors } from '@/utils/zustand';

const SHOW_FIELD_KEYS_LOCAL_STORAGE_KEY = 'fields-table-show-field-keys';

const initialState = {
  objectKey: null,
  displayFieldKey: null,
  fields: [],
  visibleFields: [],
  newConnectionFieldRelationship: {
    has: 'one',
    belongs_to: 'many'
  },
  shouldShowFieldKeys: false
} satisfies Partial<FieldsStore>;

type FieldsStore = {
  objectKey: KnackObject['key'] | null;
  displayFieldKey: KnackFieldKey | null;
  fields: KnackField[];
  visibleFields: KnackField[];
  newConnectionFieldRelationship: ConnectionField['relationship'];
  shouldShowFieldKeys: boolean;
  actions: {
    // Actions
    initialize: ({
      app,
      objectKey
    }: {
      app: BuilderApplication;
      objectKey: KnackObject['key'];
    }) => Promise<void>;

    setVisibleFields: (fields: KnackField[]) => void;

    setNewConnectionFieldRelationship: (relationship: ConnectionField['relationship']) => void;
    setShouldShowFieldKeys: (showFieldKeys: boolean) => void;

    reset: () => void;
  };
};

export const createFieldsStore = () => {
  const fieldsStore = create<FieldsStore>()(
    immer((set) => ({
      ...initialState,
      actions: {
        // Actions
        initialize: async ({ app, objectKey }) => {
          const tableMetadata = app?.objects.find((obj) => obj.key === objectKey);
          // If the objectKey is incorrect we throw an error, since we don't have access in the store to react-hooks we will handle this error wherever this is called.
          if (!tableMetadata) throw new Error(`Object ${objectKey} not found in application`);

          set((draft) => {
            draft.objectKey = objectKey;
            draft.fields = tableMetadata.fields;
            draft.visibleFields = tableMetadata.fields;
            draft.displayFieldKey = tableMetadata.identifier;
            draft.shouldShowFieldKeys = JSON.parse(
              localStorage.getItem(SHOW_FIELD_KEYS_LOCAL_STORAGE_KEY) || 'false'
            );
          });
        },
        setVisibleFields: (fields: KnackField[]) => {
          set((draft) => {
            draft.visibleFields = fields;
          });
        },
        setNewConnectionFieldRelationship: (relationship: ConnectionField['relationship']) => {
          set((draft) => {
            draft.newConnectionFieldRelationship = relationship;
          });
        },
        setShouldShowFieldKeys: (showFieldKeys: boolean) => {
          set((draft) => {
            localStorage.setItem(SHOW_FIELD_KEYS_LOCAL_STORAGE_KEY, JSON.stringify(showFieldKeys));
            draft.shouldShowFieldKeys = showFieldKeys;
          });
        },
        reset: () => {
          set(initialState);
        }
      }
    }))
  );

  const computedSelectors = {
    getField: <T extends KnackFieldType>(fieldKey: string) =>
      fieldsStore(
        useShallow(
          (state) =>
            state.fields.find((field) => field.key === fieldKey) as Extract<KnackField, { type: T }>
        )
      )
  };

  // Adds custom selectors and create the automatic selectors that will live inside useFields().use
  return createCustomSelectors(createSelectors(fieldsStore), computedSelectors);
};

const StoreContext = createContext<ReturnType<typeof createFieldsStore>>(
  null as unknown as ReturnType<typeof createFieldsStore>
);

export function FieldsStoreProvider({ children }) {
  const storeRef = useRef<ReturnType<typeof createFieldsStore>>();
  if (!storeRef.current) {
    storeRef.current = createFieldsStore();
  }
  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
}

export const useFieldsStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('Missing Fields StoreProvider');
  }
  return store;
};
