import { type TemplateStringVariable } from '@/pages/roles/role-settings/EmailTemplateSelector';

export const EmailTemplatesAddedVariables = [
  {
    key: 'record_id',
    name: 'RECORD ID',
    type: 'short_text'
  },
  {
    key: 'parent_url',
    name: 'PARENT PAGE URL',
    type: 'short_text'
  },
  {
    key: 'page_url',
    name: 'PAGE URL',
    type: 'short_text'
  },
  {
    key: 'charge_summary',
    name: 'PAYMENT SUMMARY',
    type: 'short_text'
  }
] satisfies TemplateStringVariable[];
