import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type FormView } from '@/types/schema/views/FormView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function FormGeneralSettingsCategory() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();
  const updateViewSchema = useUpdateView<FormView>();

  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings />

      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.form.categories.general_settings.button_settings')}
      >
        <div className="mb-4">
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-submit-button-text`}>
            {t('pages.element_settings.form.categories.general_settings.submit_button_text')}
          </Label>
          <Input
            id={`${view.key}-submit-button-text`}
            className="text-sm"
            value={view.submitButtonText ?? t('views.new_view_defaults.form.submit_button_text')}
            onChange={(e) => {
              updateViewSchema({
                submitButtonText: e.target.value
              });
            }}
          />
        </div>
        <div>
          <Label className="mb-2 block font-medium" htmlFor={`${view.key}-reload-form-text`}>
            {t('pages.element_settings.form.categories.general_settings.reload_form_text')}
          </Label>
          <Input
            id={`${view.key}-reload-form-text`}
            className="text-sm"
            value={view.reload_form_text ?? t('views.new_view_defaults.form.reload_form_text')}
            onChange={(e) => {
              updateViewSchema({
                reload_form_text: e.target.value
              });
            }}
          />
        </div>
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
