import { type ConnectionOption } from '@/types/schema/fields/ConnectionField';
import { Cardinality } from './constants';

export function getEdgeLabel(belongsTo: ConnectionOption, has: ConnectionOption) {
  if (belongsTo === 'one') {
    if (has === 'one') {
      return Cardinality.OneToOne;
    }
    if (has === 'many') {
      return Cardinality.OneToMany;
    }
  }

  if (belongsTo === 'many') {
    if (has === 'one') {
      return Cardinality.ManyToOne;
    }
    if (has === 'many') {
      return Cardinality.ManyToMany;
    }
  }

  return '';
}
