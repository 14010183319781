import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiTrash as DeleteIcon,
  HiDocumentDuplicate as DuplicateIcon,
  HiArrowUpTray as ImportIcon,
  HiCog6Tooth as SettingsIcon
} from 'react-icons/hi2';
import { generatePath, useNavigate } from 'react-router-dom';
import { Dialog, DropdownMenu } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { useImportStore } from '@/components/import/useImportStore';
import { TextTooltip } from '@/components/TextTooltip';
import { ROUTES } from '@/Router';
import { DialogContent } from './DialogContent';

export type DialogContentType = 'settings' | 'delete' | 'duplicate';

export interface TableDropdownMenuProps {
  table: KnackObject;
  triggerElement: React.ReactNode;
  onDropdownMenuOpenChange?: (isOpen: boolean, tableKey: string) => void;
}

export function TableDropdownMenu({
  table,
  triggerElement,
  onDropdownMenuOpenChange
}: TableDropdownMenuProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContentType, setDialogContentType] = useState<DialogContentType | null>(null);
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);
  const setExistingTable = useImportStore((state) => state.setExistingTable);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DropdownMenu
        open={isDropdownOpen}
        onOpenChange={(isOpen) => {
          if (onDropdownMenuOpenChange) {
            onDropdownMenuOpenChange(isOpen, table.key);
          }
          setDropdownOpen(isOpen);
        }}
        data-testid={`dropdown-menu-${table.key}`}
        modal={false}
      >
        <DropdownMenu.Trigger data-testid={`dropdown-trigger-${table.key}`} asChild>
          {triggerElement}
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          className="flex flex-col text-sm"
          data-testid={`dropdown-content-${table.key}`}
        >
          <TextTooltip
            label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
          >
            <DropdownMenu.Item
              onClick={() => {
                // We have to explicitly set the table selected in the state,
                // I think we can improve this by getting it from the url which already happens if you refresh the page
                setExistingTable(table);

                navigate(
                  `${generatePath(ROUTES.TABLES_ID_IMPORT, { id: table.key })}?origin=builder-next`
                );
              }}
              data-testid={`dropdown-settings-item-${table.key}`}
              disabled={isDraftModeEnabled}
            >
              <ImportIcon size={18} className="mr-2" />
              {t('components.tables_list.import')}
            </DropdownMenu.Item>
          </TextTooltip>

          <DropdownMenu.Separator />

          <TextTooltip
            label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
          >
            <DropdownMenu.Item
              onClick={() => {
                setIsDialogOpen(true);
                setDialogContentType('settings');
              }}
              data-testid={`dropdown-settings-item-${table.key}`}
              disabled={isDraftModeEnabled}
            >
              <SettingsIcon size={18} className="mr-2" />
              {t('components.tables_list.table_settings')}
            </DropdownMenu.Item>
          </TextTooltip>
          <TextTooltip
            label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
          >
            <DropdownMenu.Item
              onClick={() => {
                setIsDialogOpen(true);
                setDialogContentType('duplicate');
              }}
              data-testid={`dropdown-duplicate-item-${table.key}`}
              disabled={isDraftModeEnabled}
            >
              <DuplicateIcon size={18} className="mr-2" />
              {t('components.tables_list.duplicate_table')}
            </DropdownMenu.Item>
          </TextTooltip>

          <DropdownMenu.Separator />

          <TextTooltip
            label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
          >
            <DropdownMenu.Item
              onClick={() => {
                setIsDialogOpen(true);
                setDialogContentType('delete');
              }}
              className="text-destructive"
              data-testid={`dropdown-delete-item-${table.key}`}
              disabled={isDraftModeEnabled}
            >
              <DeleteIcon className="mr-2" size={18} />
              {t('components.tables_list.delete_table')}
            </DropdownMenu.Item>
          </TextTooltip>
        </DropdownMenu.Content>
      </DropdownMenu>

      <DialogContent
        table={table}
        contentType={dialogContentType}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </Dialog>
  );
}
