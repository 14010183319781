import { useMutation } from '@tanstack/react-query';

import { type SavedDataModelDiagram } from '@/hooks/api/queries/useDataModelDiagramQuery';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

function saveDataModelDiagram(
  accountSlug: string,
  appSlug: string,
  diagram: SavedDataModelDiagram
) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/data-model/diagram`;
  return axios.post(
    endpoint,
    { diagram },
    {
      withCredentials: true
    }
  );
}

export function useDataModelDiagramMutation() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useMutation({
    mutationFn: (diagram: SavedDataModelDiagram) =>
      saveDataModelDiagram(accountSlug, appSlug, diagram)
  });
}
