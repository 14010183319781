import { AddNewRow } from '@/components/data-table/display/header/AddNewRow';
import { FilterButton } from '@/components/data-table/display/header/FilterButton';
import { SortingIndicator } from '@/components/data-table/display/header/SortingIndicator';

export function DataTableHeader() {
  return (
    <div className="flex items-center gap-2">
      <SortingIndicator />
      <FilterButton />
      <AddNewRow />
    </div>
  );
}
