import { useMemo, useState, type MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@knack/asterisk-react';
import { type Editor } from '@tiptap/core';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { assertTruthiness } from '@/utils/assert';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { FieldIcon } from '@/components/FieldIcon';
import { EmailTemplatesAddedVariables } from '@/pages/roles/role-settings/EmailAddedVariables';

// Create a new type for fields with a modified key (e.g. to allow keys like field_123.field_456) to be used in equations
type KnackFieldForEquation = Omit<KnackField, 'key'> & {
  key: `field_${string}` | `field_${string}.field_${string}`;
};

const validFieldTypesForEmail: KnackFieldType[] = [
  'number',
  'currency',
  'rating',
  'auto_increment',
  'multiple_choice',
  'boolean',
  'date_time',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'address',
  'concatenation',
  'email',
  'equation',
  'link',
  'name',
  'paragraph_text',
  'phone',
  'rich_text',
  'short_text',
  'timer',
  'user_roles'
];

const isFieldValidForEmail = (fieldType: KnackFieldType) =>
  validFieldTypesForEmail.includes(fieldType);

export type TemplateStringVariable = {
  name: string;
  key: string;
  type: KnackFieldType;
};

export function EmailTemplateSelector({
  editorRef
}: {
  editorRef: MutableRefObject<Editor | null>;
}) {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const { data: app } = useApplicationQuery();
  const table = useCurrentTable();

  assertTruthiness(table, 'Table not found');

  // We want to merge the fields of this table with the fields of the connected tables
  // We change the key and name in the way these are displayed in the equation and save in the database
  // {Connection FieldConnector > FieldName}
  // {field_1.field_20}
  const fieldList = useMemo(() => {
    const fields: TemplateStringVariable[] = table.fields.filter((currentField) =>
      isFieldValidForEmail(currentField.type)
    );

    const connections = [
      ...(table?.connections.outbound.filter((connection) => connection.has !== 'many') || []),
      ...(table?.connections.inbound || [])
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const connection of connections) {
      const connectedTable = app?.objects?.find((obj) => obj?.key === connection.object);
      if (!connectedTable) {
        break;
      }
      fields.push(
        ...connectedTable.fields
          .filter((currentField) => isFieldValidForEmail(currentField.type))
          .map((currentField) => {
            const fieldKey: KnackFieldForEquation['key'] = `${connection.key}.${currentField.key}`;

            return {
              ...currentField,
              name: `${t('components.data_table.right_sidebar.connections.connection')} ${connection.name} > ${currentField.name}`,
              key: fieldKey
            };
          })
      );
    }

    return [...fields, ...EmailTemplatesAddedVariables];
  }, [app?.objects, t, table?.connections.inbound, table?.connections.outbound, table.fields]);

  const filteredList = useMemo(
    () =>
      fieldList.filter(
        (currentField) =>
          currentField.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          currentField.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          currentField.key.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [fieldList, searchTerm]
  );

  return (
    <>
      <div className="p-2">
        <Input
          className="m-0 w-full min-w-0 rounded-none border-0 border-b-2 border-b-subtle text-sm focus:outline-0"
          placeholder={t('components.data_table.select.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="max-h-[200px] max-w-[320px] overflow-auto p-2 pr-0 pt-0">
        <div className="flex flex-col">
          {filteredList.length === 0 && (
            <p className="p-2 text-sm text-default">
              {t(
                'components.data_table.attributes.field_settings.rich_text_fields.template_not_found'
              )}
            </p>
          )}
          {filteredList.map((currentfield) => (
            <Button
              key={currentfield.key}
              intent="minimal"
              className="mr-2 flex h-auto flex-col p-2 text-left font-normal"
              onClick={(e) => {
                e.preventDefault();

                editorRef.current?.commands.insertContent(`{${currentfield.name}}`, {
                  updateSelection: false
                });
                editorRef.current?.commands.focus();
              }}
            >
              <div className="mb-0.5 flex w-full justify-between">
                <p>{currentfield.name}</p>
                <p className="flex text-sm text-subtle">
                  <FieldIcon name={currentfield.type} size={12} className="m-1" />
                  {t(`attributes.field_types.${currentfield.type}`)}
                </p>
              </div>
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}
