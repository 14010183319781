import { DateTime } from 'luxon';
import { z } from 'zod';

import { type DateTimeFieldFormat, type TimeFormatValue } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { getAddressSchema } from '@/components/data-table/display/fields/address/AddressSchema';
import { getCurrencySchema } from '@/components/data-table/display/fields/currency/CurrencySchema';
import { getEmailSchema } from '@/components/data-table/display/fields/email/EmailSchema';
import { type DateTimeAttributes } from '@/components/data-table/display/fields/Field';
import { getFileSchema } from '@/components/data-table/display/fields/file/FileSchema';
import { getImageSchema } from '@/components/data-table/display/fields/image/ImageSchema';
import { getLinkSchema } from '@/components/data-table/display/fields/link/LinkSchema';
import { getNameSchema } from '@/components/data-table/display/fields/name/NameSchema';
import { getNumberSchema } from '@/components/data-table/display/fields/number/NumberSchema';
import { getParagraphTextSchema } from '@/components/data-table/display/fields/paragraph-text/ParagraphTextSchema';
import { getPasswordSchema } from '@/components/data-table/display/fields/password/PasswordSchema';
import { getRichTextSchema } from '@/components/data-table/display/fields/rich-text/RichTextSchema';
import { getShortTextSchema } from '@/components/data-table/display/fields/short-text/ShortTextSchema';
import { type DataTableRowValue } from '@/components/data-table/display/types';
import {
  getDefaultDate,
  getDefaultTimeToString
} from '@/components/import/confirm-import/date-time/helpers';
import { getDefaultValue } from '@/components/import/ConfirmImport';

export const convertTo24HourFormat = (hours: number, am_pm: 'AM' | 'PM'): number => {
  if (am_pm === 'PM' && hours < 12) {
    return hours + 12;
  }
  if (am_pm === 'AM' && hours === 12) {
    return 0;
  }
  return hours;
};

export const convertTo12HourFormat = (hours: number): { hours: number; am_pm: 'AM' | 'PM' } => {
  if (hours === 0) {
    return { hours: 12, am_pm: 'AM' };
  }
  if (hours >= 12) {
    return { hours: hours > 12 ? hours - 12 : hours, am_pm: 'PM' };
  }
  return { hours, am_pm: 'AM' };
};

const getNormalizedTimeObject = (
  timeObject: {
    hours?: string | number;
    minutes?: string | number;
    am_pm?: 'AM' | 'PM';
  },
  timeFormat: TimeFormatValue = 'HH MM (military)'
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let { hours, minutes, am_pm } = timeObject;
  hours = Number(hours);
  minutes = Number(minutes);

  if (timeFormat === 'HH MM (military)') {
    hours = convertTo24HourFormat(hours, am_pm || 'AM');
  } else if (timeFormat === 'HH:MM am') {
    const timeConversion = convertTo12HourFormat(hours);
    hours = timeConversion.hours;
    am_pm = timeConversion.am_pm;
  }

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return {
    ...timeObject,
    hours,
    minutes,
    am_pm,
    rawTime: timeFormat !== 'Ignore Time' ? `${formattedHours}:${formattedMinutes}` : ''
  };
};

const getNormalizedDateTimeObject = (
  dateTimeValue: DateTimeAttributes,
  format: DateTimeFieldFormat
) => {
  const transformedTime = getNormalizedTimeObject(
    {
      hours: dateTimeValue.hours,
      minutes: dateTimeValue.minutes,
      am_pm: dateTimeValue.am_pm
    },
    format.time_format as TimeFormatValue
  );

  const transformedObject = {
    ...dateTimeValue,
    hours: transformedTime.hours,
    minutes: transformedTime.minutes,
    rawTime: transformedTime.rawTime,
    dateFormatted: dateTimeValue.date_formatted,
    date: dateTimeValue.date
  };
  if (format.calendar) {
    if (dateTimeValue.to) {
      const transformedToTime = getNormalizedTimeObject(
        {
          hours: dateTimeValue.to.hours,
          minutes: dateTimeValue.to.minutes,
          am_pm: dateTimeValue.to.am_pm
        },
        format.time_format as TimeFormatValue
      );
      transformedObject.to = {
        ...dateTimeValue.to,
        hours: transformedToTime.hours,
        minutes: transformedToTime.minutes,
        rawTime: transformedToTime.rawTime,
        dateFormatted: dateTimeValue.to.date_formatted,
        date: dateTimeValue.to.date
      };
    } else {
      transformedObject.to = {
        date: getDefaultDate(format),
        dateFormatted: getDefaultDate(format),
        am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM',
        hours: DateTime.now().hour,
        minutes: DateTime.now().minute,
        rawTime: getDefaultTimeToString(format, 0)
      };
    }
  }
  return transformedObject;
};

export const getDefaultValues = (
  record: { [key: string]: DataTableRowValue },
  fields: KnackField[]
) =>
  Object.fromEntries(
    Object.entries(record).map(([key, field]) => {
      const currentField = fields.find((f) => f.key === key);
      if (!currentField) return [key, field.rawValue];
      if (currentField && currentField.type === 'date_time' && field.rawValue) {
        const dateTimeValue = field.rawValue as DateTimeAttributes;
        return [key, getNormalizedDateTimeObject(dateTimeValue, currentField.format)];
      }
      if (currentField && currentField.type === 'timer' && field.rawValue) {
        const rawValueWithTimes = field.rawValue as {
          times: Array<{
            from: { hours: string; minutes: string };
            to: { hours: string; minutes: string };
          }>;
        };

        return [
          key,
          {
            ...rawValueWithTimes,
            times: rawValueWithTimes.times.map((time) => ({
              from: getNormalizedTimeObject(time.from),
              to: getNormalizedTimeObject(time.to)
            }))
          }
        ];
      }

      if (currentField && currentField.type === 'password') {
        return [key, { password: field.rawValue, password_confirmation: field.rawValue }];
      }

      return [key, field.rawValue || getDefaultValue(currentField)];
    })
  );
export const generateFormSchema = (
  record: {
    [key: string]: DataTableRowValue;
  },
  knackFields: KnackField[]
) => {
  const schemaFields = {};

  Object.entries(record).forEach(([key]) => {
    const field = knackFields.find((f) => f.key === key);
    if (!field) return;
    if (field.type === 'short_text') {
      schemaFields[key] = getShortTextSchema(field).shape.shortText;
    } else if (field.type === 'number') {
      schemaFields[key] = getNumberSchema(field).shape.number;
    } else if (field.type === 'paragraph_text') {
      schemaFields[key] = getParagraphTextSchema(field).shape.paragraph;
    } else if (field.type === 'rich_text') {
      schemaFields[key] = getRichTextSchema(field).shape.richText;
    } else if (field.type === 'name') {
      schemaFields[key] = getNameSchema(field);
    } else if (field.type === 'link') {
      schemaFields[key] = getLinkSchema(field);
    } else if (field.type === 'address') {
      schemaFields[key] = getAddressSchema(field);
    } else if (field.type === 'currency') {
      schemaFields[key] = getCurrencySchema(field).shape.currency;
    } else if (field.type === 'email') {
      schemaFields[key] = getEmailSchema(field);
    } else if (field.type === 'rating') {
      schemaFields[key] = z.union([z.number(), z.string().length(0)]);
    } else if (field.type === 'file') {
      schemaFields[key] = getFileSchema(field);
    } else if (field.type === 'image') {
      schemaFields[key] = getImageSchema(field);
    } else if (field.type === 'password') {
      schemaFields[key] = getPasswordSchema(field);
    } else {
      schemaFields[key] = z.any();
    }
  });

  return z.object(schemaFields);
};

// Formats the record received from the server so it can be handled by the Edit Record form
export function getNormalizedRecordData(data: Record<string, any>, fields: KnackField[]) {
  const updatedData: Record<string, any> = {};

  Object.entries(data).forEach(([key, value]) => {
    const field = fields.find((f) => f.key === key);
    if (field?.type === 'date_time' && value !== null) {
      const nestedObject: Record<string, any> = {};

      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        if (nestedKey === 'dateFormatted') {
          nestedObject.date = nestedValue;
        }
        if (nestedKey === 'to' && !field.format.calendar) {
          nestedObject.to = null;
        }
        if (nestedKey === 'to' && field.format.calendar) {
          (nestedValue as DateTimeAttributes).date = (
            nestedValue as DateTimeAttributes
          ).dateFormatted;
          nestedObject[nestedKey] = nestedValue;
        } else {
          nestedObject[nestedKey] = nestedValue;
        }
      });

      updatedData[key] = nestedObject;
    } else {
      updatedData[key] = value;
    }
  });

  return updatedData;
}
