import { t } from 'i18next';
import { z } from 'zod';

import { type LinkField } from '@/types/schema/fields';

const LABEL_INPUT_MAX_LENGTH = 100;

export function getLinkSchema(field: LinkField) {
  const { required } = field;
  return z.object({
    url: z
      .string()
      .optional()
      .or(z.literal(''))
      .refine((url) => !required || url, t('components.data_table.errors.required_field')),
    label: z
      .string()
      .max(
        LABEL_INPUT_MAX_LENGTH,
        t('components.data_table.errors.field_max_length', {
          field: 'label',
          max: LABEL_INPUT_MAX_LENGTH
        })
      )
      .nullish()
      .or(z.literal(''))
  });
}
