import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface ShortcutProps {
  actionKeyPrimary: 'Ctrl' | 'Shift' | 'V' | 'H';
  actionKeySecondary?: 'A' | 'C' | 'E';
  labelText: string;
}

function UnifiedShortcut({ actionKeyPrimary, actionKeySecondary, labelText }: ShortcutProps) {
  return (
    <div className="flex justify-between">
      <p>{labelText}</p>
      <div className="flex items-center gap-1.5 text-xs">
        <span className="rounded-md border p-1">{actionKeyPrimary}</span>
        {actionKeySecondary && (
          <>
            <span> + </span>
            <span className="flex h-6 w-6 items-center justify-center rounded-md border text-xs">
              {actionKeySecondary}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export function DataModelShortcutsDialogContent() {
  const [t] = useTranslation();

  return (
    <Dialog.Content data-testid="shortcuts-dialog-content">
      <Dialog.MainContent>
        <Dialog.Header>
          <Dialog.Title>{t('components.data_model.keyboard_shortcuts')}</Dialog.Title>
          <Dialog.Description className="py-6 text-base">
            {t('components.data_model.shortcuts_dialog_description')}
          </Dialog.Description>
        </Dialog.Header>
        <p className="mb-2 font-semibold">{t('components.data_model.keyboard_shortcuts')}</p>
        <div className="space-y-2">
          <UnifiedShortcut
            labelText={t('components.data_model.select_tool')}
            actionKeyPrimary="V"
          />
          <div className="flex justify-between">
            <p>{t('components.data_model.select_tool_pan')}</p>
            <p className="text-xs">
              <span className="rounded-md border p-1">{t('keyboard_shortcuts.space')}</span> +{' '}
              {t('actions.drag')}
            </p>
          </div>
          <UnifiedShortcut
            labelText={t('components.data_model.show_connection_arrows')}
            actionKeyPrimary="Shift"
            actionKeySecondary="A"
          />
          <UnifiedShortcut
            labelText={t('components.data_model.collapse_all')}
            actionKeyPrimary="Shift"
            actionKeySecondary="C"
          />
          <UnifiedShortcut
            labelText={t('components.data_model.expand_all')}
            actionKeyPrimary="Shift"
            actionKeySecondary="E"
          />
        </div>
      </Dialog.MainContent>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button intent="minimal">{t('actions.close')}</Button>
        </Dialog.Close>
      </Dialog.Footer>
    </Dialog.Content>
  );
}
