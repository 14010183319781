import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { ViewSettingsSwitch } from './ViewSettingsSwitch';

interface ViewSettingsSwitchGroupProps {
  options: {
    key: string;
    label: string;
    disabled?: boolean;
    checked: boolean;
    isComingSoon?: boolean;
  }[];
}

export function ViewSettingsSwitchGroup({ options }: ViewSettingsSwitchGroupProps) {
  const updateViewSchema = useUpdateView();

  return (
    <div className="mb-4 space-y-2">
      {options.map((option) => (
        <ViewSettingsSwitch
          key={option.key}
          label={option.label}
          disabled={option.disabled}
          checked={option.checked}
          isComingSoon={option.isComingSoon}
          onCheckedChange={(value) => {
            if (option.key === 'cell_editor') {
              updateViewSchema({
                options: {
                  cell_editor: value
                }
              });
              return;
            }
            updateViewSchema({
              [option.key]: value
            });
          }}
        />
      ))}
    </div>
  );
}
