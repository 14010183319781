import { type SVGAttributes } from 'react';
import {
  BiSolidKey as PasswordIcon,
  BiFontFamily as RichTextIcon,
  BiStopwatch as TimerIcon
} from 'react-icons/bi';
import {
  HiMapPin as AddressIcon,
  HiCurrencyDollar as CurrencyIcon,
  HiCalendar as DateTimeIcon,
  HiEnvelope as EmailIcon,
  HiPaperClip as FileIcon,
  HiPhoto as ImageIcon,
  HiLink as LinkIcon,
  HiUser as NameIcon,
  HiHashtag as NumberIcon,
  HiBars3BottomLeft as ParagraphTextIcon,
  HiPhone as PhoneIcon,
  HiOutlineStar as RatingIcon,
  HiBars2 as ShortTextIcon,
  HiPencilSquare as SignatureIcon,
  HiUsers as UserRolesIcon
} from 'react-icons/hi2';
import { LiaSortNumericDownSolid as AutoIncrementIcon } from 'react-icons/lia';
import {
  PiCheckSquareFill as BooleanIcon,
  PiPlugs as ConnectionIcon,
  PiFunction as EquationIcon,
  PiCaretCircleDownFill as MultipleChoiceIcon,
  PiSigma as SigmaIcon,
  PiBracketsCurly as TextFormulaIcon
} from 'react-icons/pi';

import { type KnackFieldType } from '@/types/schema/KnackField';

interface IconProps extends SVGAttributes<SVGElement> {
  name: KnackFieldType;
  size?: number;
}

export function FieldIcon({ name, size = 20, ...svgProps }: IconProps) {
  const commonProps = {
    size,
    ...svgProps
  };

  const iconComponents = {
    address: AddressIcon,
    auto_increment: AutoIncrementIcon,
    boolean: BooleanIcon,
    concatenation: TextFormulaIcon,
    connection: ConnectionIcon,
    currency: CurrencyIcon,
    date_time: DateTimeIcon,
    email: EmailIcon,
    equation: EquationIcon,
    file: FileIcon,
    image: ImageIcon,
    link: LinkIcon,
    multiple_choice: MultipleChoiceIcon,
    name: NameIcon,
    number: NumberIcon,
    paragraph_text: ParagraphTextIcon,
    phone: PhoneIcon,
    rating: RatingIcon,
    rich_text: RichTextIcon,
    short_text: ShortTextIcon,
    signature: SignatureIcon,
    timer: TimerIcon,
    password: PasswordIcon,
    user_roles: UserRolesIcon,
    sum: SigmaIcon,
    min: SigmaIcon,
    max: SigmaIcon,
    average: SigmaIcon,
    count: SigmaIcon
  };

  const IconComponent = iconComponents[name] || null;
  return IconComponent ? <IconComponent {...commonProps} /> : null;
}
