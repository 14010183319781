import { useEffect } from 'react';

import { type Session } from '@/types/session';
import { useCrispMutation } from '@/hooks/api/mutations/useCrispMutation';
import { initCrisp, sendEventsForMeasuring, setCrispUserData } from '@/utils/crisp';

interface CrispChatSetupProps {
  children: React.ReactNode;
  session: Session;
  accountName: string;
}

export function CrispChatSetup({ session, accountName, children }: CrispChatSetupProps) {
  const crispMutation = useCrispMutation();

  useEffect(() => {
    if (session.user && !window.$crisp) {
      initCrisp();
      void crispMutation.mutate(
        { email: session.user.email },
        {
          onSuccess: (signature: string) => {
            setCrispUserData(session, accountName, signature);
            sendEventsForMeasuring();
          }
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return children;
}
