import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import get from 'lodash.get';

import { type BOOLEAN_FORMAT_OPTIONS, type BooleanField } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import { OPTIONS_BY_FORMAT } from '@/components/data-table/display/fields/boolean/constants';

interface BooleanFieldInputProps<FormSchema extends FieldValues> {
  field: BooleanField;
  formFieldName: Path<FormSchema>;
  isDisabled?: boolean;
}

export function BooleanFieldInput<FormSchema extends FieldValues>({
  field,
  formFieldName,
  isDisabled
}: BooleanFieldInputProps<FormSchema>) {
  const [t] = useTranslation();
  const {
    formState: { errors }
  } = useFormContext();

  const booleanFormat: (typeof BOOLEAN_FORMAT_OPTIONS)[number] = field.format?.format || 'yes_no';
  const booleanOptionsMap = OPTIONS_BY_FORMAT[booleanFormat];

  return (
    <Controller
      name={formFieldName}
      render={({ field: { onChange, value } }) => (
        <Select
          value={String(value)}
          onValueChange={(selectedOption) => {
            onChange(selectedOption === 'true' ? true || selectedOption === 'false' : false);
          }}
          disabled={isDisabled}
        >
          <Select.Trigger
            placeholder={t('actions.select')}
            className={cn('w-full', {
              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                get(errors, formFieldName)
            })}
          />
          <Select.Content>
            {Object.keys(booleanOptionsMap).map((option) => (
              <Select.Item key={option} value={String(booleanOptionsMap[option])}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}
