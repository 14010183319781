import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { LineType, ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ChartLineType() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const lineTypeOptions: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.curved')}</span>,
      value: 'natural'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.straight')}</span>,
      value: 'linear'
    }
  ];

  return (
    <div className="mb-4">
      <div className="mb-2 text-sm">
        {t('pages.element_settings.report.categories.general.line_type')}
      </div>
      <Tabs
        defaultValue={view.rows[0].reports[0].layout.line_type}
        onValueChange={(value) => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].layout.line_type = value as LineType;
          updateViewSchema(newSchema);
        }}
      >
        <Tabs.List items={lineTypeOptions} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
