import { DateTimeCalendarRender } from '@/components/data-table/display/fields/date-time/calendar/DateTimeCalendarRender';
import { DateTimeEdit } from '@/components/data-table/display/fields/date-time/DateTimeEdit';
import { formatDateTimeRawValue } from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import { type DateTimeField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function DateTimeRender(props: FieldRenderProps<DateTimeField>) {
  const { rawValue, rowId, fieldId, type, isFloating } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { calendar: hasEndDateTime } = currentField.format;

  if (hasEndDateTime) {
    return <DateTimeCalendarRender {...props} />;
  }

  if (isFloating) {
    return <DateTimeEdit {...props} />;
  }
  return (
    <div
      className="flex size-full flex-col p-2"
      data-testid={`date-time-render-${rowId}-${fieldId}`}
    >
      <p>{formatDateTimeRawValue(rawValue, currentField.format)}</p>
    </div>
  );
}
