import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { PageError } from '@/components/errors/PageError';

export function NotFoundPage() {
  const [t, { language }] = useTranslation();
  const { data: application } = useApplicationQuery();

  const handleGoToDashboardClick = () => {
    safeLogRocketTrack('NavigateToDashboard', application?.account.isHipaa);

    window.location.replace(import.meta.env.PUBLIC_DASHBOARD_URL);
  };

  return (
    <>
      <PageError title={t('components.page_not_found.title')} message={t('errors.verify_url')}>
        <div className="flex gap-4">
          <Button
            onClick={handleGoToDashboardClick}
            data-testid="page-not-found-go-to-dashboard-button"
          >
            {t('navigation.go_to_dashboard')}
          </Button>
        </div>
      </PageError>
      <Helmet>
        <title lang={language}>{t('components.page_not_found.title')}</title>
      </Helmet>
    </>
  );
}
