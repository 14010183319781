import axios from 'axios';

// Define a variable to hold the Axios instance
export const axiosInstance = axios.create({
  baseURL: import.meta.env.PUBLIC_API_URL,
  headers: {
    'x-knack-rest-api-key': 'builder',
    'x-knack-new-builder': 'true',
    'x-knack-builder-version': 'next'
  }
});

export function setResponseInterceptors() {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Not all error responses have a status code (Ex: ERR_UPLOAD_FILE_CHANGED)
      if (error.response && error.response.status === 401) {
        window.location.href = import.meta.env.PUBLIC_DASHBOARD_URL;
      }
      return Promise.reject(error);
    }
  );
}

export const setAxiosHeaders = (headersToUpdate: { [key: string]: string }) => {
  Object.keys(headersToUpdate).forEach((header) => {
    if (Object.prototype.hasOwnProperty.call(axiosInstance.defaults.headers, header)) {
      axiosInstance.defaults.headers[header] = headersToUpdate[header];
    } else {
      axiosInstance.defaults.headers[header] = headersToUpdate[header];
    }
  });
};

function setRequestInterceptors(readBaseUrl: string, writeBaseUrl: string) {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.method) {
        return config;
      }

      const requestMap: { [key: string]: 'read' | 'write' } = {
        GET: 'read',
        PUT: 'write',
        POST: 'write',
        DELETE: 'write'
      };

      const method = config.method.toUpperCase();
      let newBaseUrl = config.baseURL;

      if (requestMap[method] === 'read') {
        newBaseUrl = readBaseUrl;
      } else if (requestMap[method] === 'write') {
        newBaseUrl = writeBaseUrl;
      }

      return {
        ...config,
        baseURL: newBaseUrl
      };
    },
    (error) => Promise.reject(error)
  );
}

export function setApiBaseUrls(readSubdomain: string, writeSubdomain: string) {
  const defaultApiUrl = new URL(import.meta.env.PUBLIC_API_URL);
  const { hostname } = defaultApiUrl;
  const hostnameParts = hostname.split('.');

  // Check if there are more than two parts (to exclude base domains like 'example.com')
  if (hostnameParts.length > 2) {
    const defaultApiUrlSubdomain = hostnameParts[0];

    const readBaseUrl = defaultApiUrl.toString().replace(defaultApiUrlSubdomain, readSubdomain);
    const writeBaseUrl = defaultApiUrl.toString().replace(defaultApiUrlSubdomain, writeSubdomain);

    setRequestInterceptors(readBaseUrl, writeBaseUrl);
  }
}

export const initAxios = () => {
  setResponseInterceptors();
};
