import { t } from 'i18next';
import { z } from 'zod';

import { type NameField } from '@/types/schema/fields';

export function getNameSchema(field: NameField) {
  const { required } = field;
  return z
    .object({
      first: z.string().optional().or(z.literal('')),
      last: z.string().optional().or(z.literal('')),
      middle: z.string().nullish().or(z.literal('')),
      title: z.string().nullish().or(z.literal(''))
    })
    .refine(({ first, last }) => !required || first || last, {
      message: t('components.data_table.errors.required_field'),
      path: ['first']
    });
}
