import { Trans } from 'react-i18next';
import { RiErrorWarningFill as WarningIcon } from 'react-icons/ri';

type CompatibilityWarningCardProps = {
  oldFieldName: string;
  newFieldName: string;
};

export function CompatibilityWarningCard({
  oldFieldName,
  newFieldName
}: CompatibilityWarningCardProps) {
  return (
    <span className="flex rounded-lg bg-destructive p-4 text-sm text-destructive-emphasis">
      <WarningIcon size={18} className="mr-2 shrink-0" />
      <span>
        <Trans
          i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion"
          components={[
            <span className="font-semibold" key="0" />,
            <ul key="1" className="mt-2 list-disc">
              <li>
                <Trans
                  i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion_warnings.data_will_be_deleted"
                  components={[<span className="font-semibold" key="1" />]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion_warnings.field_settings_will_be_deleted"
                  components={[<span className="font-semibold" key="1" />]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion_warnings.no_record_history_logs"
                  components={[<span className="font-semibold" key="1" />]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion_warnings.records_of_formulas_and_equations"
                  components={[<span className="font-semibold" key="1" />]}
                />
              </li>
              <li>
                <Trans
                  i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.disallowed_conversion_warnings.no_record_rules_or_tasks"
                  components={[<span className="font-semibold" key="1" />]}
                />
              </li>
            </ul>
          ]}
          values={{
            oldFieldName,
            newFieldName
          }}
        />
      </span>
    </span>
  );
}
