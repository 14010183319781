import { type ReportView } from '@/types/schema/views/ReportView';

export const getBaseReportViewObject = (view: ReportView) => ({
  rows: [
    {
      ...view.rows[0],
      reports: [
        {
          ...view.rows[0].reports[0]
        }
      ]
    }
  ]
});
