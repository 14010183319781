import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { mutationKeys } from '@/hooks/api/mutationKeys';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

// Expand this type as we need more settings properties to be updated
interface AppSettingsPayload {
  name?: string;
  slug?: string;
  description?: string;
  settings?: {
    cluster?: string;
    support_access?: boolean;
    encoded_record_ids?: boolean;
    googleMapsApiKey?: string;
    mapsAndGeocoderProvider?: 'google' | 'here' | null;
    sso_google?: {
      client_id?: string;
      client_secret?: string;
      domain_restriction?: string;
    };
    lockout_options?: {
      lockout_enforced?: boolean;
      lockout_password_reset?: boolean;
      lockout_message?: string;
      password_reset_message?: string;
    };
    password_options?: {
      password_require_expiration: boolean;
      password_require_no_reuse: boolean;
      password_require_no_reuse_message: string;
      password_minimum_character: boolean;
      password_require_no_common: boolean;
      password_require_number: boolean;
      password_special_character: boolean;
      password_require_lowercase: boolean;
      password_require_uppercase: boolean;
    };
    inactivity_timeout_enabled?: boolean;
    inactivity_timeout?: number;
    inactivity_message?: string;
  };
}

export function convertBuilderApplicationToAppSettingsPayload(application: BuilderApplication) {
  const appSettingsPayload: AppSettingsPayload = {};

  // Root level properties
  appSettingsPayload.name = application.name;
  appSettingsPayload.slug = application.slug;
  appSettingsPayload.description = application.description;

  // Settings properties
  const { settings } = application;
  appSettingsPayload.settings = {};
  appSettingsPayload.settings.cluster = settings.cluster;
  appSettingsPayload.settings.support_access = settings.hasSupportAccess;
  appSettingsPayload.settings.encoded_record_ids = settings.shouldEncodeRecordIds;
  appSettingsPayload.settings.googleMapsApiKey = settings.googleMapsApiKey;
  appSettingsPayload.settings.inactivity_timeout_enabled = settings.inactivityTimeout.isEnabled;
  appSettingsPayload.settings.inactivity_timeout = settings.inactivityTimeout.timeoutInMinutes;
  appSettingsPayload.settings.inactivity_message = settings.inactivityTimeout.inactivityMessage;
  appSettingsPayload.settings.mapsAndGeocoderProvider = settings.mapsAndGeocoderProvider;
  appSettingsPayload.settings.googleMapsApiKey = settings.googleMapsApiKey;

  if (settings.sso?.google) {
    appSettingsPayload.settings.sso_google = settings.sso.google;
  }

  if (settings.lockoutOptions) {
    appSettingsPayload.settings.lockout_options = {
      lockout_password_reset: settings.lockoutOptions.hasLockoutPasswordReset,
      lockout_message: settings.lockoutOptions.lockoutMessage,
      password_reset_message: settings.lockoutOptions.passwordResetMessage
    };
  }

  if (settings.passwordOptions) {
    appSettingsPayload.settings.password_options = {
      password_require_expiration: settings.passwordOptions.requireExpiration,
      password_require_no_reuse: settings.passwordOptions.requireNoReUse,
      password_require_no_reuse_message: settings.passwordOptions.requireNoReUseMessage,
      password_minimum_character: settings.passwordOptions.minimumCharacter,
      password_require_no_common: settings.passwordOptions.requireNoCommon,
      password_require_number: settings.passwordOptions.requireNumber,
      password_special_character: settings.passwordOptions.specialCharacter,
      password_require_lowercase: settings.passwordOptions.requireLowerCase,
      password_require_uppercase: settings.passwordOptions.requireUpperCase
    };
  }

  return appSettingsPayload;
}

async function updateApplication(appSettingsPayload: AppSettingsPayload) {
  const { data } = await axios.put(
    '/v1/settings',
    {
      ...appSettingsPayload
    },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useApplicationSettingsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (appSettingsPayload: AppSettingsPayload) => updateApplication(appSettingsPayload),
    mutationKey: [mutationKeys.applicationSettings],
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
