import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Chip, Label, useToast } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { useCreateTemplateObjectsMutation } from '@/hooks/api/mutations/useCreateTemplateObjectsMutation';
import {
  useDownloadExcelMutation,
  type DownloadExcelResponse,
  type ImportedExcelPageData
} from '@/hooks/api/mutations/useDownloadExcelMutation';
import { useImportFileMutation } from '@/hooks/api/mutations/useImportMutation';
import { cn } from '@/utils/tailwind';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { type Column, type RawData } from '@/components/import/types';
import { getSerializedNewColumns } from '@/components/import/utils';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { sampleExcels } from './sampleExcels';

export interface SampleExcelCardProps {
  excel: SampleExcel;
  isSelected?: boolean;
  onClick: (excel: SampleExcel) => void;
  selectedExcelsPages: string[];
  toggleSelectedExcelPage: (page: string) => void;
}

export interface SampleExcel {
  name: string;
  key: string;
  url: string;
  pages: string[];
}

export function SampleExcelCard({
  excel,
  isSelected,
  onClick,
  selectedExcelsPages,
  toggleSelectedExcelPage
}: SampleExcelCardProps) {
  const handleCardClick = useCallback(() => onClick(excel), [excel, onClick]);
  const getChipColor = (isExcelSelected: boolean) => {
    if (isExcelSelected || !isSelected) {
      return 'bg-brand-muted content-muted';
    }
    return 'bg-muted content-muted';
  };
  const { t } = useTranslation();

  return (
    <Label
      htmlFor={`sample-excel-${excel.key}`}
      className="cursor-pointer"
      data-testid={`sample-excel-${excel.key}-label`}
    >
      <Card
        className={cn(
          'relative flex flex-col space-y-3 border border-subtle text-left shadow-none sm:px-4 sm:py-5',
          isSelected && 'border-default bg-muted',
          !isSelected && 'h-[250px]'
        )}
        onClick={handleCardClick}
      >
        <h2 className="font-semibold">{excel.name}</h2>
        <div className="overflow-y-auto">
          {t('components.add_table.tables')}
          <div>
            {excel.pages.map((field) => (
              <Chip
                key={field}
                className={`mr-1 rounded-md ${getChipColor(selectedExcelsPages.includes(field))}`}
                onClick={(event) => {
                  if (isSelected) {
                    event.stopPropagation();
                    toggleSelectedExcelPage(field);
                  }
                }}
              >
                {field}
              </Chip>
            ))}
          </div>
        </div>
        <div className="flex-grow" />
      </Card>
    </Label>
  );
}

export function CreateTableFromExcelSampleList({ onBack }: { onBack: () => void }) {
  const { t } = useTranslation();
  const [excelSelected, setExcelSelected] = useState<SampleExcel | undefined>(undefined);
  const [selectedExcelPages, setSelectedExcelPages] = useState<string[]>([]);
  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const createTemplateObjects = useCreateTemplateObjectsMutation();
  const downloadExcelMutation = useDownloadExcelMutation();
  const importFile = useImportFileMutation();
  const { presentToast } = useToast();

  const handleCardClick = (excel: SampleExcel) => {
    setExcelSelected(excel);
    setSelectedExcelPages(excel.pages);
  };

  const getTableSorted = () => {
    sampleExcels.sort((a, b) => b.pages.length - a.pages.length);
    return sampleExcels;
  };

  const toggleSelectedExcelPage = (field: string) => {
    setSelectedExcelPages((prevTable) =>
      prevTable.includes(field) ? prevTable.filter((f) => f !== field) : [...prevTable, field]
    );
  };

  const handleBackClick = () => {
    setExcelSelected(undefined);
  };

  const prepareColumns = (data: (string | undefined)[][], table: ImportedExcelPageData) => {
    const columns: Column[] = [];

    const headers = data[0];

    headers.forEach((header: string | undefined, index: number) => {
      columns.push({
        header: header ?? `${t('components.add_table.column_default', { index: index + 1 })}`,
        accessorKey: index.toString(),
        meta: {
          newFieldType: table.predictedColumnTypes[index] as KnackFieldType,
          newFieldFormat: table.predictedColumnFormats[index],
          parts: [],
          isThisColumnMapped: true
        }
      });
    });

    return { columns };
  };

  const mapRowData = (row: any[]) =>
    row.map((cell) => {
      if (typeof cell === 'object' && cell !== null) {
        if (cell.name) {
          return cell.name;
        }
        if (typeof cell === 'object') {
          try {
            return JSON.stringify(cell);
          } catch {
            return '';
          }
        }
      }
      return cell !== null && cell !== undefined ? cell : '';
    });

  const handleDownloadError = () => {
    presentToast({
      title: t('components.add_table.errors.download_excel')
    });
  };

  const handleImportError = () => {
    setIsImportInProgress(false);
    presentToast({
      title: t('components.add_table.errors.import_excel')
    });
  };

  const checkAnyMalformedRow = (data: RawData[]) =>
    data.filter(
      (row, index) =>
        index === 0 ||
        !(
          data[0].length === row.length &&
          row.every((cell, cellIndex) => cell === data[0][cellIndex])
        )
    );

  const handleTableSuccess = async (response: DownloadExcelResponse) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, table] of response.tablesArray.entries()) {
      const rawData = checkAnyMalformedRow(table.data);

      const processedData = rawData.map(mapRowData);

      const { columns } = prepareColumns(processedData, table);
      // eslint-disable-next-line no-await-in-loop
      await importFile.mutateAsync(
        {
          action: 'create',
          hasHeaderRow: true,
          match: {
            updateRecords: false,
            insertUnmatched: false,
            rules: []
          },
          fileName: table.sheetName,
          serializedNewColumns: getSerializedNewColumns(columns),
          file: response.file,
          shouldSkipRecordsWithErrors: true,
          shouldRedirectAfterImport: index === response.tablesArray.length - 1
        },
        {
          onSuccess: () => {
            if (index === response.tablesArray.length - 1) {
              setIsImportInProgress(false);
            }
          },
          onError: handleImportError
        }
      );
    }
  };

  const handleImportTables = async () => {
    if (!excelSelected) return;

    const params = {
      url: excelSelected.url,
      selectedExcelPages
    };
    setIsImportInProgress(true);

    downloadExcelMutation.mutate(params, {
      onSuccess: handleTableSuccess,
      onError: handleDownloadError
    });
  };

  return (
    <>
      <WizardLayout
        title={t('components.add_table.sample_excel_title')}
        contentWidth="full"
        onClose={onBack}
        hasFooter
      >
        {isImportInProgress && <FullPageSpinner />}
        <div className="mx-auto mt-6 max-w-[1200px] px-7">
          <ApiErrorBanner
            translationPrefix="components.add_table.errors"
            error={createTemplateObjects?.error}
            data-testid="error-banner"
            fallbackKey="errors.generic_error"
          />
          <div className="-mx-14">
            <p className="mb-6">
              {excelSelected
                ? t('components.add_table.sample_excel_selected_header')
                : t('components.add_table.sample_excel_header')}
            </p>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {excelSelected ? (
                <SampleExcelCard
                  key={excelSelected.key}
                  excel={excelSelected}
                  isSelected
                  onClick={handleCardClick}
                  selectedExcelsPages={selectedExcelPages}
                  toggleSelectedExcelPage={toggleSelectedExcelPage}
                />
              ) : (
                getTableSorted().map((excel: SampleExcel) => (
                  <SampleExcelCard
                    key={excel.key}
                    excel={excel}
                    onClick={handleCardClick}
                    selectedExcelsPages={[]}
                    toggleSelectedExcelPage={toggleSelectedExcelPage}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </WizardLayout>

      <div className="fixed bottom-0 flex h-16 w-full items-center justify-center bg-base px-7 shadow-sm">
        {t('components.add_table.step', { step1: excelSelected ? 3 : 2, step2: 3 })}
        <div className="fixed right-0">
          <Button
            disabled={isImportInProgress}
            intent="secondary"
            onClick={excelSelected ? handleBackClick : onBack}
            data-testid="go-back-button"
          >
            {t('actions.back')}
          </Button>
          <Button
            className="mx-4"
            onClick={handleImportTables}
            disabled={!excelSelected || selectedExcelPages.length === 0 || isImportInProgress}
            data-testid="import-sample-tables-button"
          >
            {!excelSelected || selectedExcelPages.length === 0
              ? t('actions.continue')
              : t('components.add_table.create_x_tables', { count: selectedExcelPages.length })}
          </Button>
        </div>
      </div>
    </>
  );
}
