import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type DeleteFieldParams = {
  tableKey: string;
  fieldKey: string;
};

async function deleteField({ tableKey, fieldKey }: DeleteFieldParams) {
  const endpoint = `/v1/objects/${tableKey}/fields/${fieldKey}`;

  const { data } = await axios.delete(endpoint, { withCredentials: true });

  return data;
}

export function useDeleteFieldMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteField,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
