import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsStarFill as StarFillIcon,
  BsStarHalf as StarHalfIcon,
  BsStar as StarIcon
} from 'react-icons/bs';
import { MdOutlineClose as CloseIcon } from 'react-icons/md';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type RatingField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type RatingProps = {
  maxRatingNumber: number;
  ratingNumber: number;
  allowHalf: boolean;
  className?: string;
  onChange?: (value: number) => void;
  isEditable?: boolean;
};

function Rating(props: RatingProps) {
  const {
    maxRatingNumber,
    ratingNumber,
    className,
    onChange,
    allowHalf,
    isEditable = false
  } = props;
  const container = useRef<HTMLButtonElement>(null);

  const [currentValue, setCurrentValue] = useState(ratingNumber);

  let currentlyFilled = 0;

  const RatingStars = new Array(maxRatingNumber).fill(null).map(() => {
    currentlyFilled += 1;

    if (currentlyFilled <= currentValue) {
      return (
        <StarFillIcon
          data-testid="full-star"
          key={currentlyFilled}
          className="pointer-events-none text-yellow-500"
        />
      );
    }

    // We are adding 1 to currentlyFilled, so if we extract 0.5 and its smaller to the value, we are missing a half star
    if (currentlyFilled - 0.5 <= currentValue) {
      return (
        <StarHalfIcon
          data-testid="half-star"
          key={currentlyFilled}
          className="pointer-events-none text-yellow-500"
        />
      );
    }

    return (
      <StarIcon
        data-testid="empty-star"
        key={currentlyFilled}
        className="pointer-events-none text-yellow-500"
      />
    );
  });

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!container.current) {
      return;
    }

    const { left, width } = container.current.getBoundingClientRect();

    const relativeLeft = e.clientX - left;
    const relativePercentage = relativeLeft / width;

    if (allowHalf) {
      // Start with 0.5 filled
      const newValue = relativePercentage * maxRatingNumber + 0.5;
      const newValueRounded = Math.floor(newValue * 2) / 2;

      setCurrentValue(newValueRounded);
      return;
    }

    // Start with 1 star filled
    const newValue = relativePercentage * maxRatingNumber + 1;
    const newValueRounded = Math.floor(newValue);

    setCurrentValue(newValueRounded);
  };

  const handleMouseDown = () => {
    if (!onChange) return;

    onChange(currentValue);
  };

  const handleMouseLeave = () => {
    setCurrentValue(ratingNumber);
  };

  useEffect(() => {
    setCurrentValue(ratingNumber);
  }, [ratingNumber]);

  return (
    <button
      type="button"
      ref={container}
      data-testid="rating-container"
      className={cn('flex size-fit cursor-pointer', className, {
        'cursor-default': !isEditable
      })}
      {...(isEditable && {
        // Only load these events if we have an onChange function
        onMouseMove: handleMouseMove,
        onMouseDown: handleMouseDown,
        onMouseLeave: handleMouseLeave
      })}
    >
      {RatingStars}
    </button>
  );
}

export function RatingRender(props: FieldRenderProps<RatingField>) {
  const { rawValue, rowId, fieldId, type, isEditable, isFloating } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const [t] = useTranslation();

  const { saveCell } = useDataTableStore().use.actions();

  const handleRatingChange = useCallback(
    (value: number) => {
      const newValue = value;
      void saveCell(rowId, fieldId, newValue, { value: newValue.toString(), rawValue: newValue });
    },
    [fieldId, rowId, saveCell]
  );

  return (
    <div className="group flex p-2" data-testid={`short-text-render-${rowId}-${fieldId}`}>
      <Rating
        maxRatingNumber={parseInt(currentField.format.max, 10)}
        ratingNumber={rawValue}
        onChange={handleRatingChange}
        allowHalf={currentField.format.allow_half}
        isEditable={isEditable}
      />
      {isEditable && rawValue > 0 && (
        <button
          type="button"
          className="ml-1 flex align-middle group-hover:block"
          aria-label={t('attributes.field_labels.rating.clear_label')}
          onMouseDown={(e) => {
            e.stopPropagation();
            handleRatingChange(0);
          }}
        >
          <CloseIcon />
        </button>
      )}

      {/* We don't want the error to show when the cell is not selected */}
      {isFloating && (
        <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="rating-edit-error" />
      )}
    </div>
  );
}
