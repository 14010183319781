import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

export async function loadFlows() {
  const { data } = await axiosInstance.post<{ flowsUrl: string }>(
    '/v1/flows/get-embedded-ui-url',
    null,
    {
      withCredentials: true
    }
  );

  return data;
}

export function useFlowsQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.flows, accountSlug, appSlug],
    queryFn: () => loadFlows(),
    staleTime: 4 * 60 * 60 * 1000
  });
}
