import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Select, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { LINK_TARGETS, LINK_TEXT_FORMATS, type LinkField } from '@/types/schema/fields';

type LinkFormSettingsProps = {
  field: LinkField;
};

const baseLinkSchema = z.object({
  text_format: z.enum(['url', 'unique']),
  target: z.enum(LINK_TARGETS)
});

const sameTextFormatLinkSchema = z.object({
  text_format: z.literal('same'),
  label: z.string().optional()
});

export const linkSchema = z.object({
  format: z.discriminatedUnion('text_format', [
    baseLinkSchema,
    baseLinkSchema.merge(sameTextFormatLinkSchema)
  ])
});

export function LinkFormSettings({ field }: LinkFormSettingsProps) {
  const [t] = useTranslation();

  const { register, control, watch } = useFormContext();

  const isSameTextLinkFormatSelected = watch('format.text_format')?.includes('same');

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="condition-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.link.text_format')}
      </Label>
      <Controller
        control={control}
        name="format.text_format"
        defaultValue={field?.format?.text_format || LINK_TEXT_FORMATS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || LINK_TEXT_FORMATS[0]}
          >
            <Select.Trigger
              id="condition-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="link-form-settings-text-format-select"
              {...currentField}
            />
            <Select.Content>
              {LINK_TEXT_FORMATS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`link-settings-text-format-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.link.text_format_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />

      {isSameTextLinkFormatSelected && (
        <div>
          <p className="mb-2 font-medium">
            {t('components.data_table.attributes.field_settings.link.label_text')}
          </p>
          <Input
            type="text"
            className="w-full rounded-lg"
            data-testid="link-form-settings-same-text-label-input"
            defaultValue={field?.format?.label || ''}
            {...register('format.label')}
          />
        </div>
      )}

      <div className="mt-4 flex items-center">
        <Controller
          control={control}
          name="format.target"
          defaultValue={field?.format?.target || '_self'}
          render={({ field: { onChange, value } }) => (
            <Switch
              className="mr-2"
              data-testid="link-target-value-switch"
              checked={value === '_blank'}
              onCheckedChange={(checked) => {
                onChange(checked ? '_blank' : '_self');
              }}
            />
          )}
        />
        <Label className="font-medium">
          {t('components.data_table.attributes.field_settings.link.target_text')}
        </Label>
      </div>
    </div>
  );
}
