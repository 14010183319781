import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { DataTableStoreProvider } from '@/components/data-table/useDataTableStore';
import { TablesPageLayout } from '@/pages/tables/TablesPageLayout';
import { ROUTES, type PageUrlParams } from '@/Router';
import { FieldsStoreProvider } from './fields/useFieldsStore';

export function TablesPageWrapper({ objectKey }: { objectKey: KnackObject['key'] }) {
  return (
    // If you need the state of this component outside the provider, use the globalStore instead of moving the provider to a parent.
    <DataTableStoreProvider>
      <FieldsStoreProvider>
        <Outlet />
        <TablesPageLayout objectKey={objectKey} data-testid="table-page-layout" />
      </FieldsStoreProvider>
    </DataTableStoreProvider>
  );
}

export function TablesPageContent({ isIndex = false }: { isIndex?: boolean }) {
  const urlParams = useParams<PageUrlParams>();
  const { data: app } = useApplicationQuery();

  const isStandardTable = (tableType: KnackObject['type']) => tableType === 'StandardObject';

  const firstValidTable = app?.objects.find((table) => isStandardTable(table.type));

  const defaultRedirectPath = firstValidTable
    ? generatePath(ROUTES.TABLES_ID, { id: firstValidTable.key })
    : generatePath(ROUTES.TABLES);

  if (isIndex) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  const { id: tableKey } = urlParams;
  if (!tableKey) {
    throw new Error('No table key provided');
  }

  const tableType = app?.objects.find((obj) => obj.key === tableKey)?.type;
  if (tableType && !isStandardTable(tableType)) {
    // If the table type is not standard we redirect to the correct section
    if (isFlagEnabled(FEATURE_FLAGS.fe_user_roles) && tableType === 'UserObject') {
      return <Navigate to={generatePath(ROUTES.ROLES_ID, { id: tableKey })} replace />;
    }
    return <Navigate to={defaultRedirectPath} replace />;
  }

  // Force a complete remount using key when tableKey changes, this way we ensure that the zustand store is not re-used
  return <TablesPageWrapper objectKey={tableKey as KnackObject['key']} key={tableKey} />;
}
