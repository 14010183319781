import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { cn } from '@/utils/tailwind';
import { DragInsertIndicator } from './DragInsertIndicator';

interface VerticalListSortableItemProps {
  id: string;
  children: React.ReactNode;
  insertIndicatorClassName?: string;
}

export function VerticalListSortableItem({
  id,
  insertIndicatorClassName,
  children
}: VerticalListSortableItemProps) {
  const { active, over } = useDndContext();

  const { attributes, setNodeRef, transform, transition, isSorting } = useSortable({
    id
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const overIndex: number = over?.data.current?.sortable.index ?? -1;
  const activeIndex: number = active?.data.current?.sortable.index ?? -1;

  let insertPosition: 'after' | 'before' | null = null;

  if (over?.id === id && active?.id !== id) {
    if (overIndex > activeIndex) {
      insertPosition = 'after';
    } else if (overIndex < activeIndex) {
      insertPosition = 'before';
    }
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} className="cursor-default">
      <DragInsertIndicator
        isVisible={insertPosition === 'before'}
        className={cn('mb-2', insertIndicatorClassName)}
      />

      {children}

      <DragInsertIndicator
        isVisible={insertPosition === 'after'}
        className={cn('mt-2', insertIndicatorClassName)}
      />
    </div>
  );
}
