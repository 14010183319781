import { Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/layout/MainLayout';
import { FlowsPageContextProvider } from '@/pages/flows/FlowsPageContext';

export function FlowsPage() {
  return (
    <MainLayout>
      <FlowsPageContextProvider>
        <Outlet />
      </FlowsPageContextProvider>
    </MainLayout>
  );
}
