/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import type { ReportView } from '@/types/schema/views/ReportView';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ReportGeneralOptions() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={`${t('pages.element_settings.report.categories.general.options')} (${t('keywords.coming_soon')})`}
    >
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="show-hide-data-table"
          checked={view.rows[0].reports[0].options.shouldShowDataTable}
          disabled={!hasAccessToNonPublicFeatures}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].options.shouldShowDataTable = state;
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="show-hide-data-table">
          {t('pages.element_settings.report.categories.general.show_hide_data_table')}
        </Label>
      </div>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="hide-empty-groups"
          checked={view.rows[0].reports[0].options.exclude_empties}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].options.exclude_empties = state;
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="hide-empty-groups">
          {t('pages.element_settings.report.categories.general.hide_empty_groups')}
        </Label>
      </div>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="hide-negative-ranges"
          checked={view.rows[0].reports[0].options.hide_negatives}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].options.hide_negatives = state;
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="hide-negative-ranges">
          {t('pages.element_settings.report.categories.general.hide_negative_ranges')}
        </Label>
      </div>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="expand-details-in-popup"
          checked={view.rows[0].reports[0].options.child_records}
          disabled={!hasAccessToNonPublicFeatures}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].options.child_records = state;
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="expand-details-in-popup">
          {t('pages.element_settings.report.categories.general.expand_details_in_popup')}
        </Label>
      </div>
      <div className="flex items-center gap-2">
        <Switch
          id="allow-exporting"
          checked={view.rows[0].reports[0].options.export_links}
          disabled={!hasAccessToNonPublicFeatures}
          onCheckedChange={(state) => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].options.export_links = state;
            updateViewSchema(newSchema);
          }}
        />
        <Label htmlFor="allow-exporting">
          {t('pages.element_settings.report.categories.general.allow_exporting')}
        </Label>
      </div>
    </BuilderAccordion.Item>
  );
}
