/* eslint-disable no-console */
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Badge, Button, Divider, Label, RadioGroup, Select, Switch } from '@knack/asterisk-react';

import { type KnackFilter } from '@/types/schema/KnackFilter';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewSettingsDefaultFiltersCard } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersCard';
import { ViewSettingsDefaultFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersDialog';

interface ViewSettingsFilteringOption {
  filter_type: 'fields' | 'menu' | 'none';
  filter_fields: 'view' | 'object';
  preset_filters: KnackFilter[];
}
interface ViewSettingsFilteringOptions {
  updateViewSchema: (options: Partial<ViewSettingsFilteringOption>) => void;
  options: ViewSettingsFilteringOption;
}

export function ViewSettingsFiltering({ updateViewSchema, options }: ViewSettingsFilteringOptions) {
  const [t] = useTranslation();

  const shouldAllowFiltering = options.filter_type !== 'none';

  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="allow-filtering"
          checked={shouldAllowFiltering}
          onCheckedChange={(state) => {
            const value = state ? 'fields' : 'none';

            updateViewSchema({
              filter_type: value
            });
          }}
        />
        <Label htmlFor="allow-filtering">
          {t('pages.element_settings.report.categories.filtering.allow_filtering')}
        </Label>
      </div>
      {shouldAllowFiltering && (
        <div>
          <RadioGroup
            onValueChange={(value: 'fields' | 'menu') => updateViewSchema({ filter_type: value })}
            value={options.filter_type}
            defaultValue="fields"
          >
            <RadioGroup.Container>
              <RadioGroup.Item value="fields" id="custom-radio" />
              <Label htmlFor="custom-radio">
                {t(
                  'pages.element_settings.report.categories.filtering.allow_users_to_add_custom_filters'
                )}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item value="menu" id="menu-radio" />
              <Label htmlFor="menu-radio">
                {t('pages.element_settings.report.categories.filtering.use_a_filter_menu')}
              </Label>
            </RadioGroup.Container>
          </RadioGroup>
          {options.filter_type === 'menu' ? (
            <div className="mt-4 flex items-center gap-2">
              <Button intent="secondary" disabled>
                <PlusIcon size={16} className="mt mr-1" />
                {t('pages.element_settings.report.categories.filtering.menu_links')}
              </Button>
              <Badge className="text-subtle">{t('keywords.coming_soon')}</Badge>
            </div>
          ) : (
            <div className="mb-2 mt-4 flex flex-col gap-2">
              <Label>
                {t(
                  'pages.element_settings.report.categories.filtering.which_fields_can_be_filtered'
                )}
              </Label>
              <Select
                value={options.filter_fields}
                onValueChange={(value: 'view' | 'object') =>
                  updateViewSchema({
                    filter_fields: value
                  })
                }
              >
                <Select.Trigger className="w-full" />
                <Select.Content>
                  <Select.Item value="object">{t('pages.element_settings.all_fields')}</Select.Item>
                  <Select.Item value="view">
                    {t(
                      'pages.element_settings.report.categories.filtering.only_fields_used_by_this_element'
                    )}
                  </Select.Item>
                </Select.Content>
              </Select>
            </div>
          )}
          <BuilderAccordion>
            {options.filter_type === 'fields' && (
              <>
                <Divider className="my-6" />
                <BuilderAccordion.Item
                  isDefaultOpen
                  label={t('pages.element_settings.report.categories.filtering.default_filters')}
                >
                  <div className="mb-4">
                    <ViewSettingsDefaultFiltersCard defaultFilters={options.preset_filters} />
                  </div>

                  <ViewSettingsDefaultFiltersDialog />
                </BuilderAccordion.Item>
              </>
            )}
            <BuilderAccordion.Item
              isDefaultOpen
              label={t('pages.element_settings.report.categories.filtering.restricted_connections')}
            >
              <div className="mb-2">
                {t(
                  'pages.element_settings.report.categories.filtering.users_can_filter_by_any_connection'
                )}
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <Button intent="secondary" disabled>
                  <PlusIcon size={16} className="mr-1" />
                  {t('pages.element_settings.report.categories.filtering.restricted_connections')}
                </Button>
                <Badge className="text-subtle">{t('keywords.coming_soon')}</Badge>
              </div>
            </BuilderAccordion.Item>
          </BuilderAccordion>
        </div>
      )}
    </>
  );
}
