import { useRef, useState } from 'react';
import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Input, Label } from '@knack/asterisk-react';
import get from 'lodash.get';
import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';
import { type KnackCriteriaDateTimeValue } from '@/types/schema/KnackCriteria';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useDateTimeHelpers } from '@/hooks/helpers/useDateTimeHelpers';
import { DAY_PICKER_FROM_YEAR } from '@/components/data-table/helpers/constants';
import { TimeInput } from '@/components/inputs/TimeInput';
import {
  getCriteriaTimeString,
  getInitialIsAnyDate,
  getInitialIsAnyTime,
  getNormalizedDateTimeCriteriaValue,
  getUpdatedCriteriaDateTimeValueOnTimeChange,
  getUpdatedCriteriaDateTimeValueOnTimePeriodChange,
  isManualDateInputValid
} from './utils';

interface DateTimeFieldInputProps<FormSchema extends FieldValues> {
  field: DateTimeField;
  formFieldName: Path<FormSchema>;
}

export function DateTimeCriteriaInput<FormSchema extends FieldValues>({
  field,
  formFieldName
}: DateTimeFieldInputProps<FormSchema>) {
  const [t] = useTranslation();

  const {
    formState: { errors },
    setValue,
    getValues
  } = useFormContext();
  const { getDefaultCriteriaValue } = useCriteriaHelpers();
  const { getFormattedDatePickerDate, getNormalizedDateFormat } = useDateTimeHelpers();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyDate, setIsAnyDate] = useState(() => getInitialIsAnyDate(getValues(formFieldName)));
  const [isAnyTime, setIsAnyTime] = useState(() => getInitialIsAnyTime(getValues(formFieldName)));

  const normalizedDateFormat = getNormalizedDateFormat(field.format);
  const defaultCriteriaDateTimeValue = getDefaultCriteriaValue(field) as KnackCriteriaDateTimeValue;

  // If the field doesn't ignore time and date, it means that the user can select 'any date' or 'any time' for each part of the date/time input
  const hasAnyDateAnyTimeOptions =
    field.format.time_format &&
    field.format.time_format !== 'Ignore Time' &&
    field.format.date_format &&
    field.format.date_format !== 'Ignore Date';

  return (
    <div className="flex flex-wrap items-center gap-2">
      {field.format.date_format !== 'Ignore Date' && (
        <div className="flex-1 *:w-full">
          <Controller
            name={formFieldName}
            render={({ field: { value, onChange } }) => {
              const selectedDateTimeValue: KnackCriteriaDateTimeValue = value;
              const formattedDatePickerValue = getFormattedDatePickerDate(
                selectedDateTimeValue.date,
                normalizedDateFormat
              );

              return (
                <>
                  <DatePicker
                    selected={formattedDatePickerValue}
                    onSelect={(newDateValue) => {
                      onChange({
                        ...selectedDateTimeValue,
                        date: DateTime.fromJSDate(newDateValue).toFormat(normalizedDateFormat)
                      });
                    }}
                    mode="single"
                    triggerRef={inputRef}
                    onDatePickerOpenChange={setIsDatePickerOpen}
                    calendarProps={{
                      mode: 'single',
                      fromYear: DAY_PICKER_FROM_YEAR
                    }}
                  >
                    <Input
                      id={`${formFieldName}-date-input`}
                      ref={inputRef}
                      autoComplete="off"
                      disabled={hasAnyDateAnyTimeOptions && isAnyDate}
                      className="text-sm"
                      intent={get(errors, formFieldName) ? 'destructive' : undefined}
                      placeholder={normalizedDateFormat}
                      value={selectedDateTimeValue.date}
                      onChange={(e) => {
                        const { value: manualDateInputValue } = e.target;

                        if (!isManualDateInputValid(manualDateInputValue)) {
                          return;
                        }

                        onChange({
                          ...selectedDateTimeValue,
                          date: manualDateInputValue
                        });
                      }}
                      onBlur={(e) => {
                        const { value: manualDateInputValue } = e.target;

                        // Attempt to parse using the field format
                        const dateTime = DateTime.fromFormat(
                          manualDateInputValue,
                          normalizedDateFormat
                        );

                        // If the date is not valid, reset the date value
                        if (!dateTime.isValid) {
                          setValue(formFieldName, {
                            ...getValues(formFieldName),
                            date: ''
                          });
                        }
                      }}
                      onClick={(e) => {
                        if (isDatePickerOpen) {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </DatePicker>

                  {hasAnyDateAnyTimeOptions && (
                    <Label
                      className="mt-2 flex items-center text-sm text-emphasis"
                      htmlFor={`any-date-checkbox-${formFieldName}`}
                    >
                      <Checkbox
                        id={`any-date-checkbox-${formFieldName}`}
                        className="mr-2"
                        checked={isAnyDate}
                        onCheckedChange={(isChecked: boolean) => {
                          setIsAnyDate(isChecked);

                          // Reset the date value when checked
                          if (isChecked) {
                            setValue(formFieldName, {
                              ...getValues(formFieldName),
                              date: ''
                            });

                            return;
                          }

                          // Set the date value to the default date when unchecked
                          setValue(formFieldName, {
                            ...getValues(formFieldName),
                            date: defaultCriteriaDateTimeValue.date
                          });
                        }}
                      />
                      {t('attributes.field_labels.date_time.any_date_capitalized')}
                    </Label>
                  )}
                </>
              );
            }}
          />
        </div>
      )}

      {field.format.time_format !== 'Ignore Time' && (
        <div className="flex-1">
          <Controller
            name={formFieldName}
            render={({ field: { value, onChange } }) => {
              const selectedDateTimeValue = getNormalizedDateTimeCriteriaValue(value);
              const timeStringForInput = getCriteriaTimeString(selectedDateTimeValue, field.format);

              return (
                <>
                  <TimeInput
                    value={timeStringForInput}
                    format={field.format.time_format}
                    disabled={hasAnyDateAnyTimeOptions && isAnyTime}
                    onValueChange={(newTime) => {
                      const updatedDateTimeValue = getUpdatedCriteriaDateTimeValueOnTimeChange(
                        field.format,
                        selectedDateTimeValue,
                        newTime
                      );

                      onChange(updatedDateTimeValue);
                    }}
                  >
                    {field.format.time_format !== 'HH MM (military)' && (
                      <TimeInput.TimeModeSelect
                        value={selectedDateTimeValue?.am_pm || 'AM'}
                        disabled={hasAnyDateAnyTimeOptions && isAnyTime}
                        onValueChange={(newPeriodValue) => {
                          const updatedDateTimeValue =
                            getUpdatedCriteriaDateTimeValueOnTimePeriodChange(
                              selectedDateTimeValue,
                              newPeriodValue
                            );

                          onChange(updatedDateTimeValue);
                        }}
                      />
                    )}
                  </TimeInput>

                  {hasAnyDateAnyTimeOptions && (
                    <Label
                      className="mt-2 flex items-center text-sm text-emphasis"
                      htmlFor={`any-time-checkbox-${formFieldName}`}
                    >
                      <Checkbox
                        id={`any-time-checkbox-${formFieldName}`}
                        className="mr-2"
                        checked={isAnyTime}
                        onCheckedChange={(isChecked: boolean) => {
                          setIsAnyTime(isChecked);

                          // Reset the time values when checked
                          if (isChecked) {
                            setValue(formFieldName, {
                              ...getValues(formFieldName),
                              time: '',
                              hours: '',
                              minutes: '',
                              am_pm: ''
                            });

                            return;
                          }

                          // Set the time values to the default time when unchecked
                          setValue(formFieldName, {
                            ...getValues(formFieldName),
                            time: defaultCriteriaDateTimeValue.time,
                            hours: defaultCriteriaDateTimeValue.hours,
                            minutes: defaultCriteriaDateTimeValue.minutes,
                            am_pm: defaultCriteriaDateTimeValue.am_pm
                          });
                        }}
                      />
                      {t('attributes.field_labels.date_time.any_time_capitalized')}
                    </Label>
                  )}
                </>
              );
            }}
          />
        </div>
      )}
    </div>
  );
}
