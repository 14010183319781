import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Input, Label, useToast, type AppTheme } from '@knack/asterisk-react';

import { useThemeMutation } from '@/hooks/api/mutations/useThemeMutation';

export function Dialogs({
  children,
  theme,
  currentDialogOpen
}: {
  children: React.ReactNode;
  theme: AppTheme;
  currentDialogOpen: string | null;
}) {
  const [t] = useTranslation();
  const { deleteMutation, updateMutation } = useThemeMutation();
  const { presentToast } = useToast();
  const [themeValue, setThemeValue] = useState(theme.name);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const renameTheme = () => {
    updateMutation.mutate(
      {
        themeId: theme.id,
        themeData: {
          ...theme,
          name: themeValue
        }
      },
      {
        onSuccess: () => {
          setIsConfirmDialogOpen(false);
        },
        onError: () => {
          presentToast({
            title: t('themes.error_rename_theme')
          });
        }
      }
    );
  };

  const deleteTheme = () => {
    deleteMutation.mutate(
      {
        themeId: theme.id
      },
      {
        onError: () => {
          presentToast({
            title: t('themes.error_delete_theme')
          });
        }
      }
    );
  };

  let content = <div>{t('themes.delete_theme_dialog.description')}</div>;

  if (currentDialogOpen === 'rename') {
    content = (
      <>
        <Label htmlFor="theme-name">{t('themes.rename_theme_dialog.label')}</Label>
        <Input
          id="theme-name"
          className="mt-2"
          value={themeValue}
          onChange={(e) => setThemeValue(e.target.value)}
        />
      </>
    );
  }

  return (
    <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
      {children}
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t(`themes.${currentDialogOpen}_theme_dialog.title`)}</Dialog.Title>
            <Dialog.Description>{theme.name}</Dialog.Description>
          </Dialog.Header>
          <div className="mt-4">{content}</div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button
            intent={currentDialogOpen === 'delete' ? 'destructive' : undefined}
            onClick={currentDialogOpen === 'rename' ? renameTheme : deleteTheme}
            isLoading={updateMutation.isPending || deleteMutation.isPending}
          >
            {t(`actions.${currentDialogOpen}`)}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
