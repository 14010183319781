import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  type Connection,
  type EdgeChange,
  type NodeChange,
  type OnConnect,
  type OnEdgesChange,
  type OnNodesChange,
  type OnSelectionChangeParams
} from 'reactflow';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';
import { type TableNode, type TableNodeField } from '@/pages/data-model/diagram/TableNodeComponent';
import { calculateEdges } from './calculateEdges';
import { getAnimatedEdges } from './getAnimatedEdges';
import { getFilteredTableEdges } from './getFilteredTableEdges';
import { useDataModelViewOptionsStore } from './useDataModelViewOptionsStore';

export type DataModelState = {
  nodes: TableNode[];
  edges: TableEdge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  onSelectionChange: (params: OnSelectionChangeParams) => void;
  setInitialData: (nodes: TableNode[], edges: TableEdge[]) => void;
  recalculateEdges: () => void;
  resetLayout: (nodes: TableNode[]) => void;
  filterTableEdges: (tableId: string, filteredFields: TableNodeField[]) => void;
};

const store = createWithEqualityFn<DataModelState>()(
  devtools(
    (set, get) => ({
      nodes: [],
      edges: [],
      onNodesChange: (changes: NodeChange[]) => {
        set(
          {
            edges: calculateEdges(changes, get().nodes, get().edges),
            nodes: applyNodeChanges(changes, get().nodes)
          },
          false,
          'onNodesChange'
        );
      },
      onEdgesChange: (changes: EdgeChange[]) => {
        set(
          {
            edges: applyEdgeChanges(changes, get().edges)
          },
          false,
          'onEdgesChange'
        );
      },
      onConnect: (connection: Connection) => {
        set(
          {
            edges: addEdge(connection, get().edges)
          },
          false,
          'onConnect'
        );
      },
      onSelectionChange: (params: OnSelectionChangeParams) => {
        set(
          {
            edges: getAnimatedEdges(params, get().edges),
            nodes:
              params.nodes.length > 0
                ? get().nodes.map((node) => {
                    const selectedNode = params.nodes.find((n) => n.id === node.id);
                    if (selectedNode) {
                      selectedNode.selected = true;
                      return selectedNode;
                    }
                    // eslint-disable-next-line no-param-reassign
                    node.selected = false;
                    return node;
                  })
                : get().nodes
          },
          false,
          'onSelectionChange'
        );
      },
      setInitialData: (nodes: TableNode[], edges: TableEdge[]) => {
        set({ edges, nodes }, false, 'setInitialData');
      },
      recalculateEdges: () => {
        set(
          {
            edges: calculateEdges([], get().nodes, get().edges)
          },
          false,
          'recalculateEdges'
        );
      },
      resetLayout: (nodes: TableNode[]) => {
        set(
          {
            edges: calculateEdges([], nodes, get().edges),
            nodes
          },
          false,
          'resetLayout'
        );
      },
      filterTableEdges: (tableKey: string, filteredOutConnections: TableNodeField[]) => {
        const { showAllEdges } = useDataModelViewOptionsStore.getState();
        if (!showAllEdges) return;
        set(
          {
            edges: getFilteredTableEdges(tableKey, filteredOutConnections, get().edges)
          },
          false,
          'filterTableEdges'
        );
      }
    }),
    { name: 'DataModelStore' }
  ),
  shallow
);

export const useDataModelStore = store;
