import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type ParagraphTextField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { getParagraphTextSchema } from '@/components/data-table/display/fields/paragraph-text/ParagraphTextSchema';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ParagraphTextEdit(props: FieldRenderProps<ParagraphTextField>) {
  const { rawValue, fieldId, rowId, type } = props;

  const { moveSelectionHorizontal } = useSelectionStrategy();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const paragraphFormSchema = getParagraphTextSchema(currentField);

  type ParagraphFormSchema = z.infer<typeof paragraphFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<ParagraphFormSchema>({
    resolver: zodResolver(paragraphFormSchema),
    defaultValues: {
      paragraph: rawValue
    }
  });

  const handleForm = useCallback(() => {
    const formState = getFieldState('paragraph');
    if (!formState.invalid) {
      const newValue = getValues('paragraph');

      void saveCell(rowId, fieldId, newValue, {
        value: newValue,
        rawValue: newValue
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);

    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('paragraph');
      if (formState.invalid) {
        setValue('paragraph', rawValue);
      }

      e.preventDefault();
    }

    if (e.key === 'Tab' && isValid) {
      moveSelectionHorizontal('right');
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.key === 'Enter' && isValid) {
      if (!selectedCell.isEditing) {
        e.preventDefault();
        setIsEditing(true);
      }
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('paragraph');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const paragraphRegister = register('paragraph', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(handleForm)();
    }
  });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset((state) => ({ mainAxis: -state.rects.reference.height })),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });
  return (
    <>
      <div ref={refs.setReference} className="flex size-full" />
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className={cn('size-full', {
          'h-[200px]': selectedCell.isEditing
        })}
      >
        <TextareaCell
          data-testid={`edit-paragraph-text-input-${rowId}-${fieldId}`}
          onKeyDown={handleKeyDown}
          onClick={(e) => {
            if (!selectedCell.isEditing) {
              setIsEditing(true);
              setCursorPositionAtTheEnd(e);
            }
          }}
          intent={cellErrors || errors.paragraph ? 'destructive' : 'default'}
          className={cn('h-[200px] w-[320px]', {
            'max-h-full max-w-full opacity-0': !selectedCell.isEditing
          })}
          onFocus={setCursorPositionAtTheEnd}
          {...paragraphRegister}
        />

        <CellErrors
          rowId={rowId}
          fieldId={fieldId}
          testIdPrefix="paragraph-edit-error"
          additionalErrors={selectedCell.isEditing && errors?.paragraph ? [errors.paragraph] : []}
        />
      </div>
    </>
  );
}
