import { type BooleanField } from '@/types/schema/fields/BooleanField';
import { type KnackFieldType } from '@/types/schema/KnackField';
import i18n from '@/i18n';

// IMPORTANT: getKnackFieldTypeGroups is a function that returns an object with different field types and their properties.
// This matches a utility we have in @knack/core that is used to generate the field type dropdown in the change field type flow.
// We're using it here so we don't need to add the whole dependency, but keep in mind to keep it in sync if it changes.

export type KnackFieldTypeGroupItem = {
  name: string;
  type: KnackFieldType;
  description: string;
  allowedTypeConversions: KnackFieldType[];
  isFormula?: boolean;
};

const basicStringFieldTypes: KnackFieldType[] = ['short_text', 'paragraph_text', 'rich_text'];
const basicNumericFieldTypes: KnackFieldType[] = ['number', 'currency'];
const limitedNumericFieldTypes: KnackFieldType[] = ['rating', 'boolean'];

export function getKnackFieldTypeGroups(): Record<string, KnackFieldTypeGroupItem[]> {
  return {
    text: [
      {
        name: 'Short Text',
        type: 'short_text',
        description: i18n.t('attributes.field_descriptions.short_text'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Paragraph Text',
        type: 'paragraph_text',
        description: i18n.t('attributes.field_descriptions.paragraph_text'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Rich Text',
        type: 'rich_text',
        description: i18n.t('attributes.field_descriptions.rich_text'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Text Formula',
        type: 'concatenation',
        description: '',
        allowedTypeConversions: basicStringFieldTypes
      }
    ],
    number: [
      {
        name: 'Number',
        type: 'number',
        description: i18n.t('attributes.field_descriptions.number'),
        allowedTypeConversions: [...basicNumericFieldTypes, ...basicStringFieldTypes]
      },
      {
        name: 'Currency',
        type: 'currency',
        description: i18n.t('attributes.field_descriptions.currency'),
        allowedTypeConversions: [...basicNumericFieldTypes, ...basicStringFieldTypes]
      },
      {
        name: 'Equation',
        type: 'equation',
        description: i18n.t('attributes.field_descriptions.equation'),
        allowedTypeConversions: ['number', ...basicStringFieldTypes]
      },
      {
        name: 'Auto Increment',
        type: 'auto_increment',
        description: i18n.t('attributes.field_descriptions.auto_increment'),
        allowedTypeConversions: [...basicStringFieldTypes, ...basicNumericFieldTypes]
      },
      {
        name: 'Sum',
        type: 'sum',
        description: i18n.t('attributes.field_descriptions.sum'),
        isFormula: true,
        allowedTypeConversions: [...basicStringFieldTypes, 'number']
      },
      {
        name: 'Min',
        type: 'min',
        description: i18n.t('attributes.field_descriptions.min'),
        isFormula: true,
        allowedTypeConversions: [...basicStringFieldTypes, 'number']
      },
      {
        name: 'Max',
        type: 'max',
        description: i18n.t('attributes.field_descriptions.max'),
        isFormula: true,
        allowedTypeConversions: [...basicStringFieldTypes, 'number']
      },
      {
        name: 'Average',
        type: 'average',
        description: i18n.t('attributes.field_descriptions.average'),
        isFormula: true,
        allowedTypeConversions: [...basicStringFieldTypes, 'number']
      },
      {
        name: 'Count',
        type: 'count',
        description: i18n.t('attributes.field_descriptions.count'),
        isFormula: true,
        allowedTypeConversions: [...basicStringFieldTypes, 'number']
      }
    ],
    choice: [
      {
        name: 'Multiple Choice',
        type: 'multiple_choice',
        description: i18n.t('attributes.field_descriptions.multiple_choice'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Yes/No',
        type: 'boolean',
        description: i18n.t('attributes.field_descriptions.boolean'),
        allowedTypeConversions: [
          ...basicStringFieldTypes,
          ...basicNumericFieldTypes,
          ...limitedNumericFieldTypes
        ]
      }
    ],
    date: [
      {
        name: 'Date/Time',
        type: 'date_time',
        description: i18n.t('attributes.field_descriptions.date_time'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Timer',
        type: 'timer',
        description: i18n.t('attributes.field_descriptions.timer'),
        allowedTypeConversions: basicStringFieldTypes
      }
    ],
    file: [
      {
        name: 'File',
        type: 'file',
        description: i18n.t('attributes.field_descriptions.file'),
        allowedTypeConversions: []
      },
      {
        name: 'Image',
        type: 'image',
        description: i18n.t('attributes.field_descriptions.image'),
        allowedTypeConversions: []
      }
    ],
    personal: [
      {
        name: 'Person',
        type: 'name',
        description: i18n.t('attributes.field_descriptions.name'),
        allowedTypeConversions: basicStringFieldTypes
      },

      {
        name: 'Email',
        type: 'email',
        description: i18n.t('attributes.field_descriptions.email'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Address',
        type: 'address',
        description: i18n.t('attributes.field_descriptions.address'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Phone',
        type: 'phone',
        description: i18n.t('attributes.field_descriptions.phone'),
        allowedTypeConversions: basicStringFieldTypes
      }
    ],
    other: [
      {
        name: 'Link',
        type: 'link',
        description: i18n.t('attributes.field_descriptions.link'),
        allowedTypeConversions: basicStringFieldTypes
      },
      {
        name: 'Signature',
        type: 'signature',
        description: i18n.t('attributes.field_descriptions.signature'),
        allowedTypeConversions: []
      },
      {
        name: 'Rating',
        type: 'rating',
        description: i18n.t('attributes.field_descriptions.rating'),
        allowedTypeConversions: [...basicNumericFieldTypes, ...basicStringFieldTypes]
      },
      {
        name: 'Connection',
        type: 'connection',
        description: i18n.t('attributes.field_descriptions.connection'),
        allowedTypeConversions: basicStringFieldTypes
      }
    ]
  };
}

export function getKnackFieldTypeGroupItem(fieldType: KnackFieldType) {
  const knackFieldTypeGroups = getKnackFieldTypeGroups();
  const fieldTypes = Object.values(knackFieldTypeGroups).flat();
  const currentField = fieldTypes.find((field) => field.type === fieldType);
  return currentField;
}

export function getBooleanFieldLabel(fieldFormat: BooleanField['format'], value: boolean) {
  const fallback = value
    ? i18n.t('attributes.field_labels.boolean.yes')
    : i18n.t('attributes.field_labels.boolean.no');

  if (!fieldFormat) {
    return fallback;
  }

  if (fieldFormat.format === 'true_false') {
    return value
      ? i18n.t('attributes.field_labels.boolean.true')
      : i18n.t('attributes.field_labels.boolean.false');
  }

  if (fieldFormat.format === 'on_off') {
    return value
      ? i18n.t('attributes.field_labels.boolean.on')
      : i18n.t('attributes.field_labels.boolean.off');
  }

  return fallback;
}
