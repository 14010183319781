// import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { EMAIL_TEXT_FORMATS, type EmailField } from '@/types/schema/fields';

type EmailFormSettingsProps = {
  field: EmailField;
};

export const emailSchema = z.object({
  format: z.object({
    text_format: z.enum(EMAIL_TEXT_FORMATS),
    label: z.string().optional()
  })
});

export function EmailFormSettings({ field }: EmailFormSettingsProps) {
  const [t] = useTranslation();

  const { register, control, watch } = useFormContext();

  const isSameTextEmailFormatSelected = !!watch('format.text_format')?.includes('same');

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="text-format-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.email.text_format')}
      </Label>
      <Controller
        control={control}
        name="format.text_format"
        defaultValue={field?.format?.text_format || EMAIL_TEXT_FORMATS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || EMAIL_TEXT_FORMATS[0]}
          >
            <Select.Trigger
              id="text-format-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="email-form-settings-text-format-select"
              {...currentField}
            />
            <Select.Content>
              {EMAIL_TEXT_FORMATS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`email-settings-text-format-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.email.text_format_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />

      {isSameTextEmailFormatSelected && (
        <div className="flex flex-col gap-2">
          <Label htmlFor="same-text-label-input" className="font-medium">
            {t('components.data_table.attributes.field_settings.email.email_text')}
          </Label>
          <Input
            id="same-text-label-input"
            type="text"
            className="w-full rounded-lg"
            data-testid="email-form-settings-same-text-label-input"
            defaultValue={field?.format?.label || ''}
            {...register('format.label')}
          />
        </div>
      )}
    </div>
  );
}
