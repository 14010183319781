import { type ThemeMode } from '@knack/asterisk-react';

import { buildGradientString } from '@/pages/themes/theme-editor/utils';
import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export function ColorPicker({
  mode,
  firstColor,
  secondColor,
  angle,
  isFirstColor,
  setColor
}: {
  mode: ThemeMode;
  firstColor: string;
  secondColor: string;
  angle: number;
  isFirstColor?: boolean;
  setColor: (color: string) => void;
}) {
  const { setThemeField } = useThemesPageContext();

  return (
    <div className="flex gap-2">
      <input
        type="color"
        value={isFirstColor ? firstColor : secondColor}
        className="m-0 h-10 cursor-pointer bg-transparent p-0"
        onChange={(e) => {
          setColor(e.target.value);
          setThemeField((draft) => {
            draft.background[mode] = buildGradientString(
              isFirstColor ? e.target.value : firstColor,
              !isFirstColor ? e.target.value : secondColor,
              angle
            );
          });
        }}
      />
    </div>
  );
}
