import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type BuilderPage, type BuilderPageKey, type PageType } from '@/types/schema/BuilderPage';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { type LoginViewRegistrationType } from '@/types/schema/views/LoginView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { type PageChanges } from '@/pages/pages/page-editor/helpers/types';

type UpdatePageParams = {
  updatedPage: BuilderPage;
  pageChanges?: PageChanges;
};

type UpdateDropdownParams = {
  pageKey: BuilderPageKey;
  name: string;
  menuPages: null | BuilderPageKey[];
};

type DuplicatePageParams = {
  pageKey: BuilderPageKey;
  name: string;
};

type AddLoginToPageParams = {
  pageKey: BuilderPageKey;
  registrationType: LoginViewRegistrationType;
  limitProfileAccess: boolean;
  allowedProfiles: KnackObjectProfileKey[];
};

export interface NewPagePayload {
  type: PageType;
  name: string;
  parent?: null | string;
  views?: [];

  // Used if the new page is a login page
  login_vars?: null | {
    allowed_profiles: KnackObjectProfileKey[];
    authenticated: boolean;
    limit_profile_access: boolean;
    users?: {
      registration: LoginViewRegistrationType;
    };
  };

  // Used if the new page is a dropdown menu
  menu_pages?: null | BuilderPageKey[];

  // Used if the new page is under an authentication page, or if creating a new user page
  authenticated?: boolean;

  // Used when creating user pages
  allowed_profiles?: KnackObjectProfileKey[];
  limit_profile_access?: boolean;
}

async function sortPages(order: string[]) {
  const { data } = await axios.post(
    '/v1/scenes/sort',
    { order },
    {
      withCredentials: true
    }
  );
  return data;
}

async function newPage(newPagePayload: NewPagePayload) {
  const payload = {
    // Set default values
    parent: null,
    authenticated: false,

    // Set the rest of the payload
    ...newPagePayload
  };

  const { data } = await axios.post('/v1/scenes', payload, {
    withCredentials: true
  });

  return data;
}

async function deletePage(pageKey: string) {
  const { data } = await axios.delete(`/v1/scenes/${pageKey}`, {
    withCredentials: true
  });
  return data;
}

async function duplicatePage(pageKey: string, name: string) {
  const { data } = await axios.post(
    `/v1/scenes/${pageKey}/copy`,
    { name },
    {
      withCredentials: true
    }
  );
  return data;
}

async function updateDropdown({ pageKey, name, menuPages }: UpdateDropdownParams) {
  const { data } = await axios.put(
    `/v1/scenes/${pageKey}`,
    { name, menu_pages: menuPages, views: [] },
    {
      withCredentials: true
    }
  );
  return data;
}

async function addLogin({
  pageKey,
  registrationType,
  limitProfileAccess,
  allowedProfiles
}: AddLoginToPageParams) {
  const payload = {
    authenticated: true,
    limit_profile_access: limitProfileAccess,
    allowed_profiles: allowedProfiles,
    users: {
      registration: registrationType
    }
  };

  const { data } = await axios.put(`/v1/scenes/${pageKey}`, payload, {
    withCredentials: true
  });
  return data;
}

async function removeLogin(page: BuilderPage) {
  const views = page.views.map((view) => ({
    ...view,
    _id: view.id,
    limit_profile_access: false
  }));

  const { data } = await axios.put(
    `/v1/scenes/${page.key}`,
    { authenticated: false, views },
    {
      withCredentials: true
    }
  );
  return data;
}

async function updatePage({ updatedPage, pageChanges }: UpdatePageParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  const { data } = await axios.put<{ updatedPage: BuilderPage }>(
    `/v1/builder/${accountSlug}/application/${appSlug}/pages/${updatedPage.key}`,
    { updatedPage, pageChanges },
    {
      withCredentials: true
    }
  );

  return data.updatedPage;
}

export function usePageMutation() {
  const queryClient = useQueryClient();

  return {
    sortMutation: useMutation({
      mutationFn: (order: string[]) => sortPages(order),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    createMutation: useMutation({
      mutationFn: newPage,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    updateMutation: useMutation({
      mutationFn: ({ updatedPage, pageChanges }: UpdatePageParams) =>
        updatePage({ updatedPage, pageChanges }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    updateDropdownMutation: useMutation({
      mutationFn: ({ pageKey, name, menuPages }: UpdateDropdownParams) =>
        updateDropdown({ pageKey, name, menuPages }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    deleteMutation: useMutation({
      mutationFn: (pageKey: string) => deletePage(pageKey),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    addLoginMutation: useMutation({
      mutationFn: addLogin,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    removeLoginMutation: useMutation({
      mutationFn: (page: BuilderPage) => removeLogin(page),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    }),
    duplicateMutation: useMutation({
      mutationFn: ({ pageKey, name }: DuplicatePageParams) => duplicatePage(pageKey, name),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.applicationPages] });
      }
    })
  };
}
