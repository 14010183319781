import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getProfileApprovalTemplate(profileId: string): Promise<string> {
  const { data } = await axios.get(`/v1/profiles/${profileId}/user_templates/approvals`, {
    withCredentials: true
  });
  return data.template;
}

export function useApprovalTemplateQuery(profileId: string) {
  return useQuery({
    queryKey: [queryKeys.approvalTemplates, profileId],
    queryFn: async () => getProfileApprovalTemplate(profileId)
  });
}
