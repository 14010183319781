import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { TASK_HISTORY_ROWS_PER_PAGE_OPTIONS } from '@/pages/tables/toolkit-sidebar/tasks/constants';
import {
  useTaskHistoryContext,
  type RowsPerPage
} from '@/pages/tables/toolkit-sidebar/tasks/task-history/TaskHistoryContext';

export function RowsPerPageSelect() {
  const [t] = useTranslation();
  const { rowsPerPage, onRowsPerPageChange } = useTaskHistoryContext();

  return (
    <div className="flex w-full items-center justify-center gap-2 md:justify-normal">
      <span className="text-xs font-medium">{t('keywords.show')}</span>
      <Select
        value={String(rowsPerPage)}
        onValueChange={(value) => onRowsPerPageChange(Number(value) as RowsPerPage)}
      >
        <Select.Trigger className="p-1 text-sm text-subtle" />
        <Select.Content className="min-w-36" align="end">
          {TASK_HISTORY_ROWS_PER_PAGE_OPTIONS.map((option) => (
            <Select.Item className="text-sm" key={option} value={String(option)}>
              {t('components.data_table.right_sidebar.tasks.task_history.per_page', {
                rowsPerPage: option
              })}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  );
}
