import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { NAME_FIELD_FORMATS, type NameField } from '@/types/schema/fields';

type NameFormSettingsProps = {
  field: NameField;
};

export const nameSchema = z.object({
  format: z.object({
    format: z.enum(NAME_FIELD_FORMATS)
  })
});

export function NameFormSettings({ field }: NameFormSettingsProps) {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="display-format-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.name.display_format')}
      </Label>
      <Controller
        name="format.format"
        defaultValue={field?.format?.format || NAME_FIELD_FORMATS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={currentField.value || NAME_FIELD_FORMATS[0]}
          >
            <Select.Trigger
              id="display-format-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="name-form-settings-format-select"
              {...currentField}
            />
            <Select.Content>
              {NAME_FIELD_FORMATS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`name-settings-format-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.name.format_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      <p className="text-xs font-normal">
        {t('components.data_table.attributes.field_settings.name.display_format_subtitle')}
      </p>
    </div>
  );
}
