import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  ALL_SYMBOL_FORMATS,
  DECIMAL_SEPARATOR_OPTIONS,
  PRECISION_OPTIONS,
  ROUNDING_OPTIONS,
  THOUSANDS_SEPARATOR_OPTIONS,
  type AverageField,
  type CountField,
  type MaxField,
  type MinField,
  type SumField
} from '@/types/schema/fields';
import { useApplication } from '@/hooks/useApplication';
import { cn } from '@/utils/tailwind';
import { AggregateFieldSelector } from '@/components/field-settings/aggregate/AggregateFieldSelector';
import { CountFieldSelector } from '@/components/field-settings/aggregate/CountFieldSelector';
import { CustomFormatInputs } from '@/components/field-settings/aggregate/CustomFormatInputs';
import { Filters } from '@/components/field-settings/aggregate/Filters';
import { Preview } from '@/components/field-settings/aggregate/Preview';

export type AggregateField = SumField | MaxField | MinField | AverageField | CountField;

type AggregateFormSettingsProps = {
  field: AggregateField;
  objectKey: string;
};

export const aggregateSchema = z.object({
  format: z.object({
    connection: z.object({
      key: z.string()
    }),
    field: z.object({
      key: z.string()
    }),
    format: z.preprocess(
      (incomingValue) => (incomingValue === '' ? ALL_SYMBOL_FORMATS[0] : incomingValue),
      z.enum(ALL_SYMBOL_FORMATS)
    ),
    pre: z.string().optional(),
    post: z.string().optional(),
    mark_decimal: z.enum(DECIMAL_SEPARATOR_OPTIONS),
    mark_thousands: z.enum(THOUSANDS_SEPARATOR_OPTIONS),
    precision: z.preprocess(
      (incomingValue) => (typeof incomingValue === 'number' ? PRECISION_OPTIONS[0] : incomingValue),
      z.enum(PRECISION_OPTIONS).default(PRECISION_OPTIONS[0])
    ),
    rounding: z.enum(ROUNDING_OPTIONS),
    filters: z
      .array(
        z.object({
          field: z.string(),
          operator: z.string(),
          value: z.string()
        })
      )
      .optional()
  })
});

export const countSchema = z.object({
  format: z
    .object({
      ...aggregateSchema.shape.format.shape,
      connection: z.string(),
      count_field: z.string().optional()
    })
    .omit({ field: true })
});

export function AggregateFormSettings({ field, objectKey }: AggregateFormSettingsProps) {
  const [t] = useTranslation();

  const { getValues } = useFormContext();

  const application = useApplication();
  const knackObjects = application?.objects;

  const hasDecimalPlaces = getValues('format.mark_decimal') !== 'none';
  const hasCustomFormat = getValues('format.format') === 'custom';

  const isCount = field.type === 'count';

  const selectedConnectionFieldKey = isCount
    ? field?.format?.connection
    : field?.format?.connection?.key;

  const aggregateFieldTable = useMemo(
    () => knackObjects?.find((object) => object.key === objectKey),
    [knackObjects, objectKey]
  );

  const aggregateFieldConnections = useMemo(() => {
    // We only care about many-to-x or x-to-many or many-to-many connections
    const inboundFieldConnections =
      aggregateFieldTable?.connections?.inbound?.filter((conn) => conn.belongs_to === 'many') || [];
    const outboundFieldConnections =
      aggregateFieldTable?.connections?.outbound?.filter((conn) => conn.has === 'many') || [];
    return [...inboundFieldConnections, ...outboundFieldConnections];
  }, [aggregateFieldTable]);

  const selectedAggregateFieldConnection = useMemo(
    () =>
      aggregateFieldConnections.find((conn) => conn.key === selectedConnectionFieldKey) ||
      aggregateFieldConnections[0],
    [aggregateFieldConnections, selectedConnectionFieldKey]
  );

  const connectedTable = useMemo(
    () => knackObjects?.find((object) => object.key === selectedAggregateFieldConnection?.object),
    [knackObjects, selectedAggregateFieldConnection?.object]
  );

  return (
    <div className="flex flex-col gap-4">
      {!isCount && (
        <AggregateFieldSelector
          field={field}
          aggregateFieldConnections={aggregateFieldConnections}
        />
      )}
      {isCount && (
        <CountFieldSelector field={field} aggregateFieldConnections={aggregateFieldConnections} />
      )}

      <div className="flex flex-row justify-between">
        <div
          className={cn('flex w-full flex-col gap-2', {
            'w-40': hasDecimalPlaces
          })}
        >
          <Label htmlFor="mark-decimal-select" className="font-medium">
            {t('components.data_table.attributes.field_settings.number.decimal_separators')}
          </Label>
          <Controller
            name="format.mark_decimal"
            defaultValue={field?.format?.mark_decimal || DECIMAL_SEPARATOR_OPTIONS[0]}
            render={({ field: currentField }) => (
              <Select
                onValueChange={currentField.onChange}
                defaultValue={currentField.value || DECIMAL_SEPARATOR_OPTIONS[0]}
              >
                <Select.Trigger
                  id="mark-decimal-select"
                  placeholder={t('actions.select')}
                  className="w-full rounded-lg"
                  data-testid="aggregate-form-settings-mark-decimal-select"
                  {...currentField}
                />
                <Select.Content>
                  {DECIMAL_SEPARATOR_OPTIONS.map((option) => (
                    <Select.Item
                      key={option}
                      value={option}
                      data-testid={`aggregate-settings-mark-decimal-value-${option}`}
                    >
                      {t(
                        `components.data_table.attributes.field_settings.number.decimal_separators_options.${option}`
                      )}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
        </div>

        {hasDecimalPlaces && (
          <div className="flex w-40 flex-col gap-2">
            <Label htmlFor="precision-select" className="font-medium">
              {t('components.data_table.attributes.field_settings.number.decimal_places')}
            </Label>
            <Controller
              name="format.precision"
              defaultValue={field?.format?.precision || PRECISION_OPTIONS[0]}
              render={({ field: currentField }) => (
                <Select
                  onValueChange={currentField.onChange}
                  defaultValue={currentField.value || PRECISION_OPTIONS[0]}
                >
                  <Select.Trigger
                    id="precision-select"
                    placeholder={t('actions.select')}
                    className="w-full rounded-lg"
                    data-testid="aggregate-form-settings-precision-select"
                    {...currentField}
                  />
                  <Select.Content>
                    {PRECISION_OPTIONS.map((option) => (
                      <Select.Item
                        key={option}
                        value={option}
                        data-testid={`aggregate-settings-precision-value-${option}`}
                        className="truncate"
                      >
                        {`${option} (1${option !== '0' ? '.' : ''}${'0'.repeat(
                          parseInt(option, 10)
                        )})`}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              )}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="mark-thousands-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.number.thousands_separators')}
        </Label>
        <Controller
          name="format.mark_thousands"
          defaultValue={field?.format?.mark_thousands || THOUSANDS_SEPARATOR_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || THOUSANDS_SEPARATOR_OPTIONS[0]}
            >
              <Select.Trigger
                id="mark-thousands-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="aggregate-form-settings-mark-thousands-select"
                {...currentField}
              />
              <Select.Content>
                {THOUSANDS_SEPARATOR_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`aggregate-settings-mark-thousands-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.number.thousands_separators_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="rounding-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.number.rounding')}
        </Label>
        <Controller
          name="format.rounding"
          defaultValue={field?.format?.rounding || ROUNDING_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || ROUNDING_OPTIONS[0]}
            >
              <Select.Trigger
                id="rounding-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="aggregate-form-settings-rounding-select"
                {...currentField}
              />
              <Select.Content>
                {ROUNDING_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`aggregate-settings-rounding-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.number.rounding_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.number.format')}
        </Label>
        <Controller
          name="format.format"
          defaultValue={field?.format?.format || ALL_SYMBOL_FORMATS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || ALL_SYMBOL_FORMATS[0]}
            >
              <Select.Trigger
                id="format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="aggregate-form-settings-format-select"
                {...currentField}
              />
              <Select.Content>
                {ALL_SYMBOL_FORMATS.map((format) => (
                  <Select.Item
                    key={format}
                    value={format}
                    data-testid={`aggregate-settings-format-value-${format}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.number.format_options.${format}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      {hasCustomFormat && <CustomFormatInputs field={field} />}

      <Preview />

      {connectedTable && <Filters connectedTable={connectedTable} />}
    </div>
  );
}
