import { useQuery } from '@tanstack/react-query';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type TaskHistoryData, type TaskKey } from '@/types/schema/tasks/KnackTask';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance } from '@/utils/axiosConfig';

interface GetTaskHistoryQueryParams {
  taskKey: TaskKey;
  tableKey: KnackObject['key'];
}

export async function getTaskHistory(
  appId: string | undefined,
  taskKey: TaskKey,
  tableKey: KnackObject['key']
): Promise<TaskHistoryData[]> {
  const { data } = await axiosInstance.get<TaskHistoryData[]>(
    `/v1/applications/${appId}/joblogs/?task_key=${taskKey}&object_key=${tableKey}&limit=0&sort=-1`,
    {
      withCredentials: true
    }
  );

  return data;
}

export function useTasksHistoryQuery({ taskKey, tableKey }: GetTaskHistoryQueryParams) {
  const { data: app } = useApplicationQuery();

  return useQuery({
    queryKey: [queryKeys.tasks, app?.id, taskKey, tableKey],
    queryFn: () => getTaskHistory(app?.id, taskKey, tableKey),
    staleTime: 1000 * 60 * 5
  });
}
