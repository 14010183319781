import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useApplication } from '@/hooks/useApplication';

// For now, we only need to update the app name - this type can be expanded as we need more properties to be updated
interface AppData {
  app_name: string;
}

async function updateApplication(appData: AppData, application: BuilderApplication) {
  const { account, id } = application;
  const endpoint = `${import.meta.env.PUBLIC_API_URL}/v1/accounts/${account.id}/apps/${id}`;

  const applicationDataToUpdate = {
    id,
    ...appData
  };

  const { data } = await axios.put(endpoint, applicationDataToUpdate, {
    withCredentials: true
  });

  return data;
}

export function useApplicationMutation() {
  const queryClient = useQueryClient();
  const application = useApplication();

  if (!application) {
    throw new Error('Application not found');
  }

  return useMutation({
    mutationFn: (appData: AppData) => updateApplication(appData, application),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
