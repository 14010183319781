import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, Label, Select } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  FilterInputValue,
  getFirstOperator,
  OperatorSelect
} from '@/components/data-table/display/header/filters/Filters';

type FiltersProps = {
  connectedTable: KnackObject;
};

export function Filters({ connectedTable }: FiltersProps) {
  const [t] = useTranslation();

  const { setValue, getValues } = useFormContext();

  const filters = getValues('format.filters') || [];

  return (
    <div className="flex flex-col gap-2">
      <Label>
        {t('components.data_table.attributes.field_settings.aggregate.formula_filters_label')}
      </Label>
      {filters.map((filter, index) => {
        const currentField = connectedTable?.fields.find((field) => field.key === filter.field);

        if (!currentField) return null;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="flex flex-col gap-2 rounded-lg bg-muted p-4">
            <div className="flex items-center justify-between">
              <Label>
                {t('components.data_table.attributes.field_settings.aggregate.filter_id', {
                  id: index + 1
                })}
              </Label>
              <Button
                intent="minimal"
                className="h-8 w-6 p-0"
                data-testid={`remove-filter-${index}`}
                onClick={() => {
                  const updatedFilters = filters.filter((_, i) => i !== index);
                  setValue('format.filters', updatedFilters);
                }}
              >
                <CloseIcon size={24} />
              </Button>
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex items-center gap-1">
                <Select
                  onValueChange={(fieldSelected) => {
                    if (fieldSelected === filter.field) return;

                    const updatedFilters = getValues('format.filters');
                    updatedFilters[index] = {
                      ...filters[index],
                      field: fieldSelected,
                      operator: getFirstOperator(connectedTable?.fields, fieldSelected)
                    };
                    setValue('format.filters', filters);
                  }}
                  defaultValue={filter?.field}
                >
                  <Select.Trigger
                    className="w-full rounded-lg"
                    id="filters-field-select"
                    data-testid="filters-field-select"
                  />
                  <Select.Content className="max-h-[200px]">
                    {connectedTable?.fields.map((f) => (
                      <Select.Item key={f.key} value={f.key}>
                        {f.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
                <OperatorSelect
                  {...{
                    field: currentField,
                    ruleIndex: index,
                    rule: filter,
                    onValueChange: (operatorSelected) => {
                      if (operatorSelected === filter.operator) return;

                      const updatedFilters = getValues('format.filters');
                      updatedFilters[index] = {
                        ...filters[index],
                        operator: operatorSelected
                      };
                      setValue('format.filters', filters);
                    }
                  }}
                />
              </div>
              <FilterInputValue
                ruleIndex={index}
                {...{
                  rule: filter,
                  field: currentField,
                  defaultValue: filter?.value,
                  onChange: (value) => {
                    const updatedFilters = getValues('format.filters');
                    updatedFilters[index] = { ...filters[index], value };
                    setValue('format.filters', updatedFilters);
                  }
                }}
              />
            </div>
          </div>
        );
      })}
      <div>
        <Button
          className="pl-0.5 pr-1"
          data-testid="add-option-button"
          intent="minimal"
          onClick={() => {
            const connectedTableFields = connectedTable?.fields || [];
            const defaultField = connectedTableFields[0]?.key;
            if (!defaultField) return;

            const updatedFilters = filters?.concat({
              field: defaultField,
              operator: getFirstOperator(connectedTableFields, defaultField),
              value: ''
            });
            setValue('format.filters', updatedFilters);
          }}
        >
          <PlusIcon size={24} className="mr-2" />{' '}
          {t('components.data_table.attributes.field_settings.aggregate.add_filter')}
        </Button>
      </div>
    </div>
  );
}
