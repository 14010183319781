import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleValues
} from '@/types/schema/KnackField';

export const LINK_TEXT_FORMATS = ['url', 'same', 'unique'] as const;
export const LINK_TARGETS = ['_blank', '_self'] as const;

export interface LinkField extends BaseKnackField {
  type: 'link';
  format: {
    label?: string;
    target?: (typeof LINK_TARGETS)[number];
    text_format: (typeof LINK_TEXT_FORMATS)[number];
  };
  rules: LinkFieldConditionalRule[];
}

interface LinkFieldConditionalRule extends ConditionalRule {
  values: LinkFieldConditionalRuleValues[];
}

interface LinkFieldConditionalRuleValues extends ConditionalRuleValues {
  value: LinkFieldConditionalRuleValue;
}

export interface LinkFieldConditionalRuleValue {
  url: string;
}
