import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import { type FormView, type FormViewInput } from '@/types/schema/views/FormView';
import { TabListItemCountChip } from '@/components/TabListItemCountChip';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { FormDisplayRules } from './display-rules/FormDisplayRules';
import { FormFieldManagement } from './field-management/FormFieldManagement';

export function FormFieldsCategory() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();

  // The non-static field inputs that have been already added to the form
  const activeFieldInputs = useMemo(
    () =>
      view.groups.reduce((acc, group) => {
        group.columns.forEach((column) => {
          column.inputs.forEach((input) => {
            if (input.type !== 'divider' && input.type !== 'section_break') {
              acc.push(input);
            }
          });
        });
        return acc;
      }, [] as FormViewInput[]),
    [view.groups]
  );

  const activeFieldInputsCount = activeFieldInputs.length;
  const displayRulesCount = view.rules.fields?.length ?? 0;

  const tabListItems: TabsListItem[] = [
    {
      value: 'fields',
      children: (
        <>
          {t('pages.element_settings.form.categories.form_fields.field_management.label')}
          <TabListItemCountChip count={activeFieldInputsCount} />
        </>
      )
    },
    {
      value: 'display-rules',
      children: (
        <>
          {t('pages.element_settings.form.categories.form_fields.display_rules.label')}
          <TabListItemCountChip count={displayRulesCount} />
        </>
      )
    }
  ];

  return (
    <Tabs defaultValue="fields">
      <Tabs.List
        // Using a key to force the component to re-render when the count changes.
        // This is necessary because `TabsList` is not reflecting the changes to the `items` prop (Bug FE-3391)
        key={`${activeFieldInputsCount}-${displayRulesCount}`}
        items={tabListItems}
        intent="page"
        shouldDisableResponsive
        shouldUseGradient
      />
      <Tabs.Content value="fields" className="p-0 text-default">
        <FormFieldManagement />
      </Tabs.Content>
      <Tabs.Content value="display-rules" className="p-0 text-default">
        {import.meta.env.PUBLIC_IS_PRODUCTION === 'true' ? 'Coming soon' : <FormDisplayRules />}
      </Tabs.Content>
    </Tabs>
  );
}
