import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, Input, Label, useToast } from '@knack/asterisk-react';
import i18n from 'i18next';
import { z } from 'zod';

import { useApplicationSettingsMutation } from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

interface GoogleSsoModalProps {
  onOpenChange: (isOpen: boolean) => void;
}

const googleSsoFormSchema = z.object({
  client_id: z.string().min(1, i18n.t('pages.settings.access.google_sso.client_id_required')),
  client_secret: z
    .string()
    .min(1, i18n.t('pages.settings.access.google_sso.client_secret_required')),
  domain_restriction: z.string()
});

type GoogleSsoSettingsFormSchema = z.infer<typeof googleSsoFormSchema>;

export function GoogleSsoModal({ onOpenChange }: GoogleSsoModalProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { data: application } = useApplicationQuery();
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<GoogleSsoSettingsFormSchema>({
    resolver: zodResolver(googleSsoFormSchema),
    defaultValues: {
      client_id: application?.settings.sso.google?.client_id ?? '',
      client_secret: application?.settings.sso.google?.client_secret ?? '',
      domain_restriction: application?.settings.sso.google?.domain_restriction ?? ''
    }
  });

  const onSubmit = (data: GoogleSsoSettingsFormSchema) => {
    updateApplicationSettings(
      {
        settings: {
          sso_google: data
        }
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('pages.settings.access.google_sso.save_success'),
            intent: 'success'
          });

          onOpenChange(false);
        },
        onError: () => {
          presentToast({
            title: t('pages.settings.access.google_sso.save_error'),
            intent: 'destructive'
          });
        }
      }
    );
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <Dialog.MainContent>
            <Dialog.Header>
              <Dialog.Title>
                {t('pages.settings.access.google_sso.configure_settings')}
              </Dialog.Title>
              <Dialog.Description className="sr-only">
                {t('pages.settings.access.google_sso.configure_settings')}
              </Dialog.Description>
            </Dialog.Header>

            <div className="mt-6 text-sm">
              <div className="mb-4">
                <Label className="mb-1 inline-block font-medium" htmlFor="google-sso-client-id">
                  {t('pages.settings.access.google_sso.client_id')}
                </Label>
                <Input
                  id="google-sso-client-id"
                  className="text-sm"
                  intent={errors.client_id ? 'destructive' : undefined}
                  {...register('client_id')}
                />
                {errors.client_id && (
                  <p className="mt-1 text-xs text-destructive">{errors.client_id.message}</p>
                )}
              </div>
              <div className="mb-4">
                <Label className="mb-1 inline-block font-medium" htmlFor="google-sso-client-secret">
                  {t('pages.settings.access.google_sso.client_secret')}
                </Label>
                <Input
                  type="password"
                  id="google-sso-client-secret"
                  className="text-sm"
                  intent={errors.client_secret ? 'destructive' : undefined}
                  {...register('client_secret')}
                />
                {errors.client_secret && (
                  <p className="mt-1 text-xs text-destructive">{errors.client_secret.message}</p>
                )}
              </div>
              <div>
                <Label
                  className="mb-1 inline-block font-medium"
                  htmlFor="google-sso-restrict-by-domain"
                >
                  {t('pages.settings.access.google_sso.restrict_by_domain')}
                </Label>
                <p className="mb-2 text-xs text-subtle">
                  {t('pages.settings.access.google_sso.restrict_by_domain_description')}
                </p>
                <Input
                  id="google-sso-restrict-by-domain"
                  className="text-sm"
                  placeholder={t('pages.settings.access.google_sso.restrict_by_domain_placeholder')}
                  {...register('domain_restriction')}
                />
              </div>
            </div>
          </Dialog.MainContent>

          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button intent="minimal">{t('actions.cancel')}</Button>
            </Dialog.Close>
            <Button type="submit">{t('actions.save')}</Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog>
  );
}
