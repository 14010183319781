import { useCallback, useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { AddressEditInput } from '@/components/data-table/display/fields/address/AddressEditInput';
import { AddressRender } from '@/components/data-table/display/fields/address/AddressRender';
import { getAddressSchema } from '@/components/data-table/display/fields/address/AddressSchema';
import {
  formatGoogleAutocomplete,
  type GoogleMapsPlace
} from '@/components/data-table/display/fields/address/formatters/formatGoogleAutocomplete';
import { LatLongEditInput } from '@/components/data-table/display/fields/address/LatLongEditInput';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type AddressField as AddressFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function AddressEdit(props: FieldRenderProps<AddressFieldDisplay>) {
  const { rawValue, fieldId, rowId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { setIsEditing, saveCell } = useDataTableStore().use.actions();

  const [editAutocompleteAddress, setEditAutocompleteAddress] = useState(rawValue.street);
  const { moveSelectionVertical } = useSelectionStrategy();

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.PUBLIC_GOOGLE_MAPS_KEY,
    onPlaceSelected: async (place: GoogleMapsPlace) => {
      if (!place) return;

      // This is a special case for when we don't capture a google place but the user typed something
      if (place?.name) {
        setEditAutocompleteAddress(place.name);
        return;
      }

      const formattedPlace = formatGoogleAutocomplete(place) as AddressFormSchema;
      setEditAutocompleteAddress(formattedPlace.street || '');

      await saveCell(rowId, fieldId, formattedPlace, {
        value: '',
        rawValue: formattedPlace
      });

      setIsEditing(false);
    },
    options: {
      types: ['address']
    }
  });

  const { format: addressFormat } = currentField;

  const inputFormat = addressFormat?.input || 'address';

  const hasAutocompleteEnabled =
    (addressFormat?.enable_geocoding && addressFormat?.enable_address_autocomplete) ?? false;
  const isLatLongFormat = inputFormat === 'lat_long';

  const addressFormSchema = getAddressSchema(currentField);

  type AddressFormSchema = z.infer<typeof addressFormSchema>;

  const formMethods = useForm<AddressFormSchema>({
    resolver: zodResolver(addressFormSchema),
    defaultValues: {
      street: rawValue.street,
      street2: rawValue.street2,
      city: rawValue.city,
      state: rawValue.state,
      zip: rawValue.zip,
      country: rawValue.country,
      latitude: rawValue.latitude,
      longitude: rawValue.longitude
    },
    mode: 'onChange',
    shouldFocusError: false
  });

  const {
    setFocus,
    formState: { errors }
  } = formMethods;

  const onValid = useCallback(
    async (data: AddressFormSchema) => {
      const updatedData = { ...data };
      if (hasAutocompleteEnabled && editAutocompleteAddress) {
        const result = addressFormSchema.safeParse({ street: editAutocompleteAddress });
        if (!result.success) {
          return;
        }
        updatedData.street = result.data.street;
      }

      await saveCell(rowId, fieldId, updatedData, {
        value: '',
        rawValue: updatedData
      });
    },
    // We cannot add addressFormSchema or debounce will break as it will be re-created each keystroke
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editAutocompleteAddress, fieldId, hasAutocompleteEnabled, rawValue, rowId, saveCell]
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset((state) => ({ mainAxis: -state.rects.reference.height })),
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ elements }) {
          elements.floating.style.width = `${elements.reference.getBoundingClientRect().width}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      moveSelectionVertical('down');
    }

    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      if (selectedCell?.isEditing) {
        if (isLatLongFormat) {
          setFocus('latitude');
        } else if (hasAutocompleteEnabled) {
          ref.current?.focus();
        } else {
          setFocus('street');
        }
      }
    }, 0);
  }, [setFocus, selectedCell, isLatLongFormat, hasAutocompleteEnabled, ref]);

  return (
    <>
      <button
        type="button"
        className={cn('size-full appearance-none bg-base text-left', {
          'ring-2 ring-destructive':
            errors.street || errors.street2 || errors.latitude || errors.longitude,
          hidden: selectedCell?.isEditing
        })}
        data-testid={`edit-address-field-floating-cell-${rowId}-${fieldId}`}
        ref={refs.setReference}
        onClick={() => {
          setIsEditing(!selectedCell?.isEditing);
        }}
      >
        <AddressRender {...props} isFloating={false} />
      </button>
      <div
        ref={refs.setReference}
        className={cn('flex size-full bg-base', {
          hidden: !selectedCell?.isEditing
        })}
      />
      <FormProvider {...formMethods}>
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={cn(
            // We don't have ring-emphasis, we should either stop using borders or add the missing color.
            // This is an edge case where we want the black border to be in internal inputs instead of the external div.
            'flex min-w-[335px] flex-col gap-2 border-0 bg-base px-1 py-0.5 ring-2 ring-[#898088]',
            {
              hidden: !selectedCell?.isEditing,
              'min-w-[250px] p-1': isLatLongFormat,
              'ring-destructive': cellErrors
            }
          )}
          data-testid="address-edit-form"
        >
          {isLatLongFormat ? (
            <LatLongEditInput
              rowId={rowId}
              fieldId={fieldId}
              onValid={onValid}
              handleKeyDown={handleKeyDown}
            />
          ) : (
            <AddressEditInput
              rowId={rowId}
              fieldId={fieldId}
              onValid={onValid}
              handleKeyDown={handleKeyDown}
              hasAutoCompleteEnabled={hasAutocompleteEnabled}
              setEditAutocompleteAddress={setEditAutocompleteAddress}
              autoCompleteRef={ref}
              editAutocompleteAddress={editAutocompleteAddress}
              addressFormat={addressFormat?.format || 'US'}
            />
          )}
          <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="address-edit-input-error" />
        </div>
      </FormProvider>
      {!selectedCell?.isEditing && (
        <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="address-edit-error" />
      )}
    </>
  );
}
