import { useTranslation } from 'react-i18next';
import { Label } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackField } from '@/components/InlineKnackField';

interface RuleFormHeaderProps {
  table: KnackObject;
  fieldKey?: string;
}

export function RuleFormHeader({ table, fieldKey }: RuleFormHeaderProps) {
  const { fields } = table;
  const field = fields.find((f) => f.key === fieldKey);
  const fieldType = field?.type;
  const fieldName = field?.name;
  const { t } = useTranslation();
  if (!fieldType || !fieldName) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1" data-testid="rule-form-header">
      <Label>{t('components.data_table.sorting.field')}</Label>
      <InlineKnackField
        fieldType={fieldType}
        fieldName={fieldName}
        className="inline-flex items-center border-none p-1 align-middle text-emphasis"
      />
    </div>
  );
}
