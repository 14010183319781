import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Button, Collapsible, Tooltip } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { getKnackFieldTypeGroups, type KnackFieldTypeGroupItem } from '@/utils/fields';
import { FieldIcon } from '@/components/FieldIcon';

type FieldTypeGroupsSelectorProps = {
  field: KnackField;
  objectKey: KnackObject['key'];
  onSelectNewFieldTypeItem: (newFieldTypeItem: KnackFieldTypeGroupItem | null) => void;
};

export function FieldTypeGroupsSelector({
  field,
  objectKey,
  onSelectNewFieldTypeItem
}: FieldTypeGroupsSelectorProps) {
  const [t] = useTranslation();

  const { data: app } = useApplicationQuery();
  const table = app?.objects?.find((obj) => obj?.key === objectKey);

  const knackFieldTypeGroups = getKnackFieldTypeGroups();

  const isAggregateField = (type: KnackFieldType) =>
    ['sum', 'min', 'max', 'average', 'count'].includes(type);

  const hasInboundManyConnection = table?.connections?.inbound?.some(
    (connection) => connection.belongs_to === 'many'
  );
  const hasOutboundManyConnection = table?.connections?.outbound?.some(
    (connection) => connection.has === 'many'
  );

  const shouldDisableField = (type: KnackFieldType) =>
    isAggregateField(type) && !hasInboundManyConnection && !hasOutboundManyConnection;

  return (
    <div className="min-w-[220px] overflow-y-auto py-1">
      {Object.keys(knackFieldTypeGroups).map((group) => (
        <Collapsible defaultOpen data-testid="field-type-groups-collapsible" key={group}>
          <Collapsible.Trigger
            className="group flex w-full items-center justify-between pr-5"
            data-testid="field-type-group-collapsible-trigger"
          >
            <h3 className="py-2.5 font-semibold">{t(`attributes.field_categories.${group}`)}</h3>
            <ChevronDownIcon
              size={16}
              className="transition-transform duration-300 group-data-[state=open]:rotate-180"
            />
          </Collapsible.Trigger>
          <Collapsible.Content className="collapsible-animation">
            <div className="flex flex-col gap-1 py-1 pr-4">
              {knackFieldTypeGroups[group].map((knackFieldItem) => (
                <Tooltip key={knackFieldItem.type}>
                  <Tooltip.Trigger>
                    <Button
                      key={knackFieldItem.type}
                      onClick={() => {
                        onSelectNewFieldTypeItem(
                          knackFieldItem.type === field.type ? null : knackFieldItem
                        );
                      }}
                      intent="minimalStandalone"
                      className="max-h-8 w-full justify-start whitespace-nowrap rounded-md bg-subtle px-2 font-light"
                      disabled={shouldDisableField(knackFieldItem.type)}
                    >
                      <FieldIcon name={knackFieldItem.type} size={16} className="mr-2 shrink-0" />
                      {knackFieldItem.name}
                    </Button>
                  </Tooltip.Trigger>
                  {shouldDisableField(knackFieldItem.type) && (
                    <Tooltip.Content>
                      {t(
                        'components.data_table.attributes.field_settings.common.aggregate_field_disabled_tooltip'
                      )}
                    </Tooltip.Content>
                  )}
                </Tooltip>
              ))}
            </div>
          </Collapsible.Content>
        </Collapsible>
      ))}
    </div>
  );
}
