/**
 * Check if a string is unique within a list of existing strings, case sensitive
 */
export function isStringUniqueInList(string: string, existingStrings: string[]) {
  if (!string || existingStrings?.length === 0) {
    return true;
  }

  return !existingStrings.includes(string.trim());
}

/**
 * Generate a unique string by appending a number to the baseString ensuring it is unique within a list of existing strings
 */
export function generateUniqueStringInList(existingStrings: string[], baseString: string = '') {
  if (isStringUniqueInList(baseString, existingStrings)) {
    return baseString;
  }

  let newString = baseString;
  let index = 1;
  while (!isStringUniqueInList(newString, existingStrings)) {
    newString = `${baseString} ${index}`;
    index += 1;
  }

  return newString;
}
