import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TbStarFilled as StarIcon } from 'react-icons/tb';
import { Label, Select, Switch } from '@knack/asterisk-react';
import { z } from 'zod';

import { MAX_STARS_OPTIONS, type RatingField } from '@/types/schema/fields';

type RatingFormSettingsProps = {
  field: RatingField;
};

export const ratingSchema = z.object({
  format: z.object({
    allow_half: z.boolean(),
    max: z.enum(MAX_STARS_OPTIONS)
  })
});

export function RatingFormSettings({ field }: RatingFormSettingsProps) {
  const [t] = useTranslation();

  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="max-stars-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.rating.number_of_stars')}
        </Label>
        <Controller
          control={control}
          name="format.max"
          defaultValue={field?.format?.max || MAX_STARS_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || MAX_STARS_OPTIONS[0]}
            >
              <Select.Trigger
                id="max-stars-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="rating-form-settings-max-stars-select"
                {...currentField}
              />
              <Select.Content>
                {MAX_STARS_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`rating-settings-max-stars-value-${option}`}
                  >
                    <div className="flex items-center gap-1">
                      {t(
                        `components.data_table.attributes.field_settings.rating.number_of_stars_options.${option}`
                      )}
                      {[...Array(Number(option))].map((e, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <StarIcon key={i} size={14} />
                      ))}
                    </div>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex items-center">
        <Controller
          control={control}
          name="format.allow_half"
          defaultValue={field?.format?.allow_half || false}
          render={({ field: { onChange, value } }) => (
            <Switch
              id="half-stars-switch"
              className="mr-2"
              data-testid="allow-half-stars-value-switch"
              checked={value}
              onCheckedChange={onChange}
            />
          )}
        />
        <Label htmlFor="half-stars-switch" className="font-medium">
          {t('components.data_table.attributes.field_settings.rating.allow_half')}
        </Label>
      </div>
    </div>
  );
}
