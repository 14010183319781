import { Crisp } from 'crisp-sdk-web';

import { type Session } from '@/types/session';

export const initCrisp = () => {
  // Remove HubSpot chat if it's present
  window.HubSpotConversations?.widget?.remove();
  if (!import.meta.env.PUBLIC_CRISP_ID) {
    return;
  }
  Crisp.configure(import.meta.env.PUBLIC_CRISP_ID);
};

export const setCrispUserData = (session: Session, accountName: string, signature: string) => {
  Crisp.user?.setEmail(session.user.email, signature);
  Crisp.user?.setNickname(`${session.user.firstName} ${session.user.lastName}`);
  Crisp.user?.setCompany(accountName, {});
};

export const sendEventsForMeasuring = () => {
  const crispInstance = window.$crisp;

  try {
    if (crispInstance) {
      // Assign a segment programatically to track the number of users who were sent the automation
      crispInstance.push(['set', 'session:segments', [['new_user_agent_offline']]]);

      // Check users who started a conversation
      crispInstance.push([
        'on',
        'message:received',
        () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'CrispInteractions',
            'event-label': 'message_received'
          });
        }
      ]);
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const removeCrisp = () => {
  if (!import.meta.env.PUBLIC_CRISP_ID) {
    return;
  }
  if (!Crisp.isCrispInjected()) {
    return;
  }
  Crisp.chat?.hide();
};
