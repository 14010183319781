import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import get from 'lodash.get';

import { type DateTimeRangeType } from '@/types/schema/fields';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { isDateTimeRangeOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';

interface DateTimeRangeInputProps<FormSchema extends FieldValues> {
  operator: KnackOperator;
  rangeTypeFormFieldName: Path<FormSchema>;
  rangeValueFormFieldName: Path<FormSchema>;
}

export function DateTimeRangeInput<FormSchema extends FieldValues>({
  operator,
  rangeTypeFormFieldName,
  rangeValueFormFieldName
}: DateTimeRangeInputProps<FormSchema>) {
  const [t] = useTranslation();

  const {
    formState: { errors },
    setValue
  } = useFormContext();

  if (!isDateTimeRangeOperator(operator)) {
    return null;
  }

  if (operator === 'is during the current') {
    const dateTimeSingularRangeTypes = [
      'week',
      'month',
      'quarter',
      'year'
    ] satisfies DateTimeRangeType[];

    return (
      <Controller
        name={rangeTypeFormFieldName}
        render={({ field: { onChange, value: selectedRangeType } }) => {
          const isSelectedRangeTypeValid =
            selectedRangeType && dateTimeSingularRangeTypes.includes(selectedRangeType);
          const fallbackRangeType = dateTimeSingularRangeTypes[0];

          // Persist the fallback value in the form
          if (!isSelectedRangeTypeValid) {
            setValue(rangeTypeFormFieldName, fallbackRangeType as typeof selectedRangeType);
          }

          return (
            <Select
              value={isSelectedRangeTypeValid ? selectedRangeType : fallbackRangeType}
              onValueChange={onChange}
            >
              <Select.Trigger
                placeholder={t('actions.select')}
                className={cn('w-full', {
                  'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                    get(errors, rangeTypeFormFieldName)
                })}
              />
              <Select.Content>
                {dateTimeSingularRangeTypes.map((option) => (
                  <Select.Item key={option} value={option}>
                    {option}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          );
        }}
      />
    );
  }

  // The range value options are values from 1 to 31
  const rangeValueOptions = Array.from(Array(31), (_, i) => (i + 1).toString());
  const dateTimePluralRangeTypes = [
    'days',
    'weeks',
    'months',
    'years',
    'rolling weeks',
    'rolling months',
    'rolling years'
  ] satisfies DateTimeRangeType[];

  return (
    <div className="flex items-center gap-2">
      <div className="flex-1">
        <Controller
          name={rangeValueFormFieldName}
          render={({ field: { onChange, value: selectedRangeValue } }) => {
            const isSelectedRangeValueValid =
              selectedRangeValue && rangeValueOptions.includes(selectedRangeValue);

            const fallbackRangeValue = rangeValueOptions[0];

            // Persist the fallback value in the form
            if (!isSelectedRangeValueValid) {
              setValue(rangeValueFormFieldName, fallbackRangeValue as typeof selectedRangeValue);
            }

            return (
              <Select
                value={selectedRangeValue ? String(selectedRangeValue) : rangeValueOptions[0]}
                onValueChange={onChange}
              >
                <Select.Trigger
                  placeholder={t('actions.select')}
                  className={cn('w-full', {
                    'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                      get(errors, rangeValueFormFieldName)
                  })}
                />
                <Select.Content>
                  {rangeValueOptions.map((option) => (
                    <Select.Item key={option} value={option}>
                      {option}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            );
          }}
        />
      </div>
      <div className="flex-1">
        <Controller
          name={rangeTypeFormFieldName}
          render={({ field: { onChange, value: selectedRangeType } }) => {
            const isSelectedRangeTypeValid =
              selectedRangeType && dateTimePluralRangeTypes.includes(selectedRangeType);

            const fallbackRangeType = dateTimePluralRangeTypes[0];

            // Persist the fallback value in the form
            if (!isSelectedRangeTypeValid) {
              setValue(rangeTypeFormFieldName, fallbackRangeType as typeof selectedRangeType);
            }

            return (
              <Select
                value={isSelectedRangeTypeValid ? selectedRangeType : dateTimePluralRangeTypes[0]}
                onValueChange={onChange}
              >
                <Select.Trigger
                  placeholder={t('actions.select')}
                  className={cn('w-full', {
                    'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                      get(errors, rangeTypeFormFieldName)
                  })}
                />
                <Select.Content>
                  {dateTimePluralRangeTypes.map((option) => (
                    <Select.Item key={option} value={option}>
                      {option}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            );
          }}
        />
      </div>
    </div>
  );
}
