import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Input, Label } from '@knack/asterisk-react';

import type { ReportView, ReportViewCalculation } from '@/types/schema/views/ReportView';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  CalculationFieldSelector,
  type CalculationFieldSelectorItem
} from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationFieldSelector';
import { CalculationFiltersDialog } from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationFiltersDialog';
import { CalculationFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationFiltersGroupCard';
import { CalculationSelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/CalculationSelector';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

const MAX_CALCULATIONS_ALLOWED = 5;

export function ReportDataDisplayCalculation() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();
  const { groups, calculations } = view.rows[0].reports[0];

  const isPieChart = view.rows[0].reports[0].type === 'pie';

  const isGroupedByTotalsOrAverages = view.rows[0].reports[0].groups.some(({ type }) =>
    ['totals', 'averages'].includes(type)
  );

  // Get all numeric field keys from the source object
  const availableNumericFields: CalculationFieldSelectorItem[] = sourceObject.fields
    .filter((field) => NUMERIC_FIELD_TYPES.includes(field.type))
    .map((field) => ({
      key: field.key,
      name: field.name,
      type: field.type
    }));

  // Add a count field if is not grouped by totals or averages
  if (!isGroupedByTotalsOrAverages) {
    availableNumericFields.push({
      key: 'count',
      name: t('pages.element_settings.report.categories.data_display.total_record_count'),
      type: 'count'
    });
  }

  const defaultCalculation: ReportViewCalculation = {
    calculation: 'sum',
    field: availableNumericFields[0]?.key,
    label: availableNumericFields[0]?.name
  };

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.data_display.y_axis')}
    >
      {calculations.map((calculation, index) => (
        <BuilderAccordion.Item
          key={calculation.label}
          isDefaultOpen
          label={t('pages.element_settings.report.categories.data_display.calculation_n', {
            count: index + 1
          })}
          className="mb-4 rounded-xl bg-subtle p-2"
          removeItemButton={
            calculations.length > 1 ? (
              <Button
                intent="minimal"
                onClick={() => {
                  const newSchema = getBaseReportViewObject(view);
                  newSchema.rows[0].reports[0].calculations.splice(index, 1);
                  updateViewSchema(newSchema);
                }}
              >
                <RemoveIcon className="text-subtle" />
              </Button>
            ) : undefined
          }
        >
          <div className="mb-4">
            <Label className="mb-2 block" htmlFor="calculation-label">
              {t('pages.element_settings.report.categories.data_display.label')}
            </Label>
            <Input
              id="calculation-label"
              value={calculation.label}
              className="text-sm"
              onChange={(e) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].calculations[index].label = e.target.value;
                updateViewSchema(newSchema);
              }}
            />
          </div>
          <div className="mb-4">
            <CalculationFieldSelector
              id={`calculation-field-selector-${index}`}
              availableNumericFields={availableNumericFields}
              defaultValue={calculation.field}
              onSelectCalculationField={(calculationField) => {
                const newSchema = getBaseReportViewObject(view);
                newSchema.rows[0].reports[0].calculations[index].field = calculationField.key;
                if (calculationField.key === 'count') {
                  newSchema.rows[0].reports[0].calculations[index].label = calculationField.name;
                  newSchema.rows[0].reports[0].calculations[index].calculation = 'count';
                } else {
                  newSchema.rows[0].reports[0].calculations[index].label = calculationField.name;
                  newSchema.rows[0].reports[0].calculations[index].calculation = 'sum';
                }
                updateViewSchema(newSchema);
              }}
            />
            {!isGroupedByTotalsOrAverages && calculation.calculation !== 'count' && (
              <>
                <div className="mb-2 mt-4">
                  {t('pages.element_settings.report.categories.data_display.calculation')}
                </div>
                <CalculationSelector
                  id={`calculation-selector-${index}`}
                  defaultValue={calculation.calculation}
                  onValueChange={(value) => {
                    const newSchema = getBaseReportViewObject(view);
                    newSchema.rows[0].reports[0].calculations[index].calculation = value;
                    updateViewSchema(newSchema);
                  }}
                />
              </>
            )}
            <div className="mt-4 flex flex-col">
              <Label className="mb-2">
                {t(
                  'pages.element_settings.report.categories.data_display.calculation_filters.filters_label'
                )}
              </Label>
              <div className="space-y-4">
                <CalculationFiltersGroupsCard
                  sourceObject={sourceObject}
                  calculationFilters={calculation.filters ?? []}
                />
                <CalculationFiltersDialog
                  sourceObject={sourceObject}
                  calculationFilters={calculation.filters ?? []}
                  onFormSubmit={({ filters }) => {
                    const newSchema = getBaseReportViewObject(view);
                    newSchema.rows[0].reports[0].calculations[index].filters = filters;
                    updateViewSchema(newSchema);
                  }}
                />
              </div>
            </div>
          </div>
        </BuilderAccordion.Item>
      ))}
      {!isPieChart && (
        <Button
          intent="secondary"
          disabled={groups.length > 1 || calculations.length >= MAX_CALCULATIONS_ALLOWED}
          onClick={() => {
            const newSchema = getBaseReportViewObject(view);
            newSchema.rows[0].reports[0].calculations.push(defaultCalculation);
            updateViewSchema(newSchema);
          }}
        >
          <PlusIcon size={16} className="mr-1" />
          {t('pages.element_settings.report.categories.data_display.y_axis_calculation')}
        </Button>
      )}
    </BuilderAccordion.Item>
  );
}
