import { t } from 'i18next';
import { z } from 'zod';

import { type AddressField } from '@/types/schema/fields';

export function getAddressSchema(field: AddressField) {
  const { required, format: addressFormat } = field;

  const inputFormat = addressFormat?.input || 'address';

  const isLatLongFormat = inputFormat === 'lat_long';

  return z.object({
    street: z
      .string()
      .nullish()
      .refine(
        (street) => !required || isLatLongFormat || street,
        t('components.data_table.errors.required_field')
      ),
    street2: z.string().nullish(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    zip: z.string().nullish(),
    country: z.string().nullish(),
    latitude: z.coerce
      .number()
      .min(-90)
      .max(90)
      .nullish()
      .refine(
        (lat) => !required || !isLatLongFormat || lat,
        t('components.data_table.errors.required_field')
      ),
    longitude: z.coerce
      .number()
      .min(-180)
      .max(180)
      .nullish()
      .refine(
        (lng) => !required || !isLatLongFormat || lng,
        t('components.data_table.errors.required_field')
      )
  });
}
