import { Trans, useTranslation } from 'react-i18next';
import { HiExclamationCircle as InfoIcon } from 'react-icons/hi2';
import { Banner } from '@knack/asterisk-react';

import { LearnMoreLink } from '@/components/LearnMoreLink';

export function SourceFiltersWarningBanner({ className }: { className?: string }) {
  const [t] = useTranslation();

  return (
    <Banner
      intent="warning"
      title={t(
        'pages.element_settings.common.categories.data_display.general_settings.source_filters_warning_title'
      )}
      icon={InfoIcon}
      className={className}
    >
      <Banner.Message className="text-xs">
        <Trans i18nKey="pages.element_settings.common.categories.data_display.general_settings.source_filters_warning_message">
          {/* //TODO: Add the URL for this link - FE-3305 - part 4 */}
          <LearnMoreLink href="/" target="_blank" className="text-warning-emphasis" />
        </Trans>
      </Banner.Message>
    </Banner>
  );
}
