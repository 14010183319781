import { type MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog, useToast } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { useDeleteFieldMutation } from '@/hooks/api/mutations/useDeleteFieldMutation';

export function DeleteFieldDialog({
  tableKey,
  field,
  onClose
}: {
  tableKey: string;
  field: KnackField;
  onClose: () => void;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const deleteFieldMutation = useDeleteFieldMutation();

  const handleDeleteFieldClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    deleteFieldMutation.mutate(
      {
        tableKey,
        fieldKey: field.key
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.delete_field_dialog.success')
          });
          onClose();
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.delete_field_dialog.error')
          });
          onClose();
        }
      }
    );
  };

  return (
    <Dialog open onOpenChange={() => onClose()}>
      <Dialog.Content data-testid="delete-field-dialog-content">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('components.data_table.delete_field_dialog.title', {
                fieldName: field.name
              })}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>
              <Trans
                i18nKey="components.data_table.delete_field_dialog.description"
                values={{
                  fieldName: field.name
                }}
              >
                <span className="font-semibold">everywhere</span>
                <span className="font-semibold">This cannot be undone</span>
              </Trans>
            </p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal" data-testid="delete-field-discard-changes-button">
              {t('components.data_table.delete_field_dialog.cancel')}
            </Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              isLoading={deleteFieldMutation.isPending}
              onClick={handleDeleteFieldClick}
              data-testid="delete-field-confirm-button"
            >
              {t('components.data_table.delete_field_dialog.delete')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
