import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PiPlugs as ConnectionIcon } from 'react-icons/pi';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useApplication } from '@/hooks/useApplication';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  getGroupedConnectionFields,
  getSourceObjectName
} from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/helper';
import { TableViewColumnFieldItem } from '@/pages/pages/settings-panel/view-settings/table/data-display/field-management/TableViewColumnFieldItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

function generateTableFieldColumn(field: KnackField): TableViewColumn {
  return {
    grouping: false,
    group_sort: '',
    ignore_edit: false,
    ignore_summary: false,
    conn_separator: '',
    conn_link: '',
    width: {
      type: 'default',
      units: 'px',
      amount: '50'
    },
    link_type: 'text',
    link_text: '',
    link_field: '',
    link_design_active: false,
    icon: {
      icon: '',
      align: 'left'
    },
    img_gallery: '',
    align: 'left',
    rules: [],
    id: field.key,
    field: {
      key: field.key
    },
    header: field.name,
    type: 'field'
  };
}

export function TableFieldManagement() {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<TableView>();
  const updateViewSchema = useUpdateView<TableView>();
  const application = useApplication();

  const nonConnectionFields: KnackField[] = [];
  const connectionFields: ConnectionField[] = [];

  sourceObject.fields.forEach((field) => {
    if (field.type === 'connection') {
      connectionFields.push(field);
    } else {
      nonConnectionFields.push(field);
    }
  });

  const [visibleFields, setVisibleFields] = useState<KnackField[]>(nonConnectionFields);
  const [visibleConnectionFields, setVisibleConnectionFields] =
    useState<ConnectionField[]>(connectionFields);
  const [searchValue, setSearchValue] = useState('');

  const groupedConnectionFields = getGroupedConnectionFields(visibleConnectionFields);

  const onAddFieldInput = (field: KnackField) => {
    updateViewSchema({
      columns: [...view.columns, generateTableFieldColumn(field)]
    });
  };

  const onRemoveFieldInput = (field: KnackField) => {
    const newColumns = view.columns.filter((col) => col.field?.key !== field.key);

    updateViewSchema({
      columns: newColumns
    });
  };

  const onAddAllFieldColumns = () => {
    const columnsToAdd: TableViewColumn[] = [];

    // Create columns for all the fields that are not already in the table
    sourceObject.fields.forEach((field) => {
      if (view.columns.some((input) => input.field?.key === field.key)) {
        return;
      }

      columnsToAdd.push(generateTableFieldColumn(field));
    });

    updateViewSchema({
      columns: [...view.columns, ...columnsToAdd]
    });
  };

  const onRemoveAllFieldColumns = () => {
    updateViewSchema({ columns: [] });
  };

  const onSearchColumn = (value: string) => {
    setSearchValue(value);

    const trimmedValue = value.trim().toLowerCase();

    if (trimmedValue === '') {
      setVisibleFields(nonConnectionFields);
      setVisibleConnectionFields(connectionFields);
      return;
    }

    setVisibleFields(
      nonConnectionFields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );

    setVisibleConnectionFields(
      connectionFields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-1">
        <span className="text-xs font-medium text-emphasis">
          {t('pages.element_settings.form.categories.form_fields.field_management.manage_fields')}
        </span>
        <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
          {t(
            'pages.element_settings.form.categories.form_fields.field_management.active_inputs_count',
            {
              currentCount: view.columns.length,
              totalCount: visibleFields.length
            }
          )}
        </span>
      </div>
      <p className="mb-4 text-xs text-subtle">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.manage_fields_description'
        )}
      </p>
      <InputSearch
        className="mb-4 text-sm"
        aria-label={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        placeholder={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        value={searchValue}
        onChange={(e) => onSearchColumn(e.target.value)}
      />

      <div className="mb-4 flex gap-3">
        <Button
          disabled={visibleFields.length === 0}
          intent="link"
          className="text-xs"
          onClick={onAddAllFieldColumns}
        >
          {t('pages.element_settings.form.categories.form_fields.field_management.add_all')}
        </Button>
        <Button
          disabled={visibleFields.length === 0}
          intent="link"
          className="text-xs"
          onClick={onRemoveAllFieldColumns}
        >
          {t('pages.element_settings.form.categories.form_fields.field_management.remove_all')}
        </Button>
      </div>

      {visibleFields.length > 0 && (
        <div className="space-y-2">
          {visibleFields.map((visibleField) => {
            const columnItem = view.columns.find((col) => col.field?.key === visibleField.key);

            return (
              <TableViewColumnFieldItem
                key={visibleField.key}
                field={visibleField}
                columnId={columnItem?.id}
                isChecked={columnItem?.field?.key === visibleField.key}
                onCheck={(isChecked) => {
                  if (isChecked) {
                    onAddFieldInput(visibleField);
                  } else {
                    onRemoveFieldInput(visibleField);
                  }
                }}
              />
            );
          })}
        </div>
      )}
      <BuilderAccordion>
        {groupedConnectionFields.map((group) => {
          const groupSourceObjectName = getSourceObjectName(group.sourceObject, application);
          return (
            <BuilderAccordion.Item
              key={group.sourceObject}
              isDefaultOpen
              customLabel={
                <div className="mt-4 flex items-start gap-2">
                  <ConnectionIcon size={16} className="mt-1 shrink-0" />
                  <div className="flex flex-col items-start justify-start">
                    <p className="text-sm font-medium">{groupSourceObjectName}</p>
                    <p className="text-xs text-subtle">{`${sourceObject.name} > ${groupSourceObjectName}`}</p>
                  </div>
                </div>
              }
            >
              {group.fields.map((field) => {
                const columnItem = view.columns.find((col) => col.field?.key === field.key);

                return (
                  <TableViewColumnFieldItem
                    key={field.key}
                    field={field}
                    columnId={columnItem?.id}
                    isChecked={columnItem?.field?.key === field.key}
                    onCheck={(isChecked) => {
                      if (isChecked) {
                        onAddFieldInput(field);
                      } else {
                        onRemoveFieldInput(field);
                      }
                    }}
                  />
                );
              })}
            </BuilderAccordion.Item>
          );
        })}
      </BuilderAccordion>
      {visibleFields.length === 0 && groupedConnectionFields.length === 0 && (
        <div className="text-sm text-subtle">
          <Trans
            i18nKey="pages.element_settings.form.categories.form_fields.field_management.no_results"
            components={[<p key="0" className="mb-2" />, <p key="1" />]}
            values={{
              objectName: sourceObject.name
            }}
          />
        </div>
      )}
    </div>
  );
}
