import { useTranslation } from 'react-i18next';
import { HiDocumentPlus as NewDocumentIcon, HiTableCells as TableIcon } from 'react-icons/hi2';

import { isFlagEnabled } from '@/utils/flagsmith';
import { cn } from '@/utils/tailwind';
import ExcelIcon from '@/assets/svg/excel.svg';
import GoogleSheetsIcon from '@/assets/svg/google-sheets.svg';
import { AddTableSourceItem } from './AddTableSourceItem';

interface AddTableSourceListProps {
  onClick: (id: string) => void;
}

export function AddTableSourceList({ onClick }: AddTableSourceListProps) {
  const [t] = useTranslation();
  const isImportV2Enabled = isFlagEnabled('gt_add_import_v2');

  const generalItems = [
    {
      id: 'blank',
      icon: NewDocumentIcon,
      title: t('components.add_table.blank_table')
    },
    {
      id: 'pre-made',
      icon: TableIcon,
      title: t('components.add_table.pre_made_table')
    },
    {
      id: 'google-sheets',
      icon: GoogleSheetsIcon,
      title: t('components.add_table.google_sheets')
    },
    {
      id: 'excel-csv',
      icon: ExcelIcon,
      title: t('components.add_table.excel_csv')
    }
  ];

  const sortedItems = isImportV2Enabled
    ? ['google-sheets', 'pre-made', 'excel-csv', 'blank']
    : ['blank', 'pre-made', 'google-sheets'];

  const itemsToRender = generalItems
    .filter((item) => isImportV2Enabled || item.id !== 'excel-csv')
    .sort((a, b) => sortedItems.indexOf(a.id) - sortedItems.indexOf(b.id));

  return (
    <div data-testid="add-table-list-items" className="my-6">
      <div
        className={cn('mt-2 flex flex-col gap-2', {
          'flex-row flex-wrap gap-4': isImportV2Enabled
        })}
      >
        {itemsToRender.map((item) => (
          <div key={item.id} className="flex-1">
            <AddTableSourceItem
              item={item}
              onClick={onClick}
              data-testid={`add-table-${item.id}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
