import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon } from 'react-icons/hi2';
import { PiEqualsBold as EqualsIcon } from 'react-icons/pi';
import { Button, Select } from '@knack/asterisk-react';

import { useImportStore } from '@/components/import/useImportStore';
import { getNonMatchableKnackFields } from '@/components/import/utils';

export function MatchRuleCard({ id }: { id: number }) {
  const [t] = useTranslation();
  const { columns, existingTable, matchRules, setMatchRules } = useImportStore((state) => ({
    columns: state.columns,
    existingTable: state.existingTable,
    matchRules: state.matchRules,
    setMatchRules: state.setMatchRules
  }));

  const updateRule = (
    ruleId: number,
    options: { field: 'columnIndex'; value: number } | { field: 'fieldKey'; value: string }
  ) => {
    setMatchRules(
      matchRules.map((rule) => {
        if (rule.ruleId === ruleId) {
          return {
            ...rule,
            [options.field]: options.value
          };
        }
        return rule;
      })
    );
  };

  const matchableFields = existingTable?.fields.filter(
    (field) => !getNonMatchableKnackFields().includes(field.type)
  );
  const defaultMatchableFieldKey = matchableFields?.[0]?.key;

  return (
    <div className="mb-2 mt-1 flex items-end" data-testid="import-advanced-options-rule-wrapper">
      <Select
        defaultValue="0"
        onValueChange={(value) => updateRule(id, { field: 'columnIndex', value: Number(value) })}
      >
        <Select.Trigger
          placeholder={t('components.add_into_existing_table.column_name')}
          className="w-[160px] truncate"
          data-testid="import-advanced-options-rule-column-trigger"
        />
        <Select.Content>
          {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Select.Item key={`${column}-${index}`} value={String(index)}>
              {String(column.header)}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
      <EqualsIcon className="mx-4 mb-3" />
      <Select
        defaultValue={defaultMatchableFieldKey}
        onValueChange={(value) => updateRule(id, { field: 'fieldKey', value })}
      >
        <Select.Trigger
          placeholder={t('components.add_into_existing_table.knack_field')}
          className="w-[160px] truncate"
          data-testid="import-advanced-options-rule-field-trigger"
        />
        <Select.Content>
          {matchableFields?.map((field) => {
            const { key, name } = field;
            return (
              <Select.Item key={key} value={key}>
                {name}
              </Select.Item>
            );
          })}
        </Select.Content>
      </Select>
      <Button
        intent="minimal"
        className="ml-2"
        data-testid="import-advanced-options-remove-rule-button"
        onClick={() => setMatchRules([...matchRules.filter((item) => item.ruleId !== id)])}
      >
        <CloseIcon />
      </Button>
    </div>
  );
}
