import { useReactFlow, useViewport } from 'reactflow';

export function useDataModelZoom() {
  const { zoomTo, zoomIn, zoomOut, fitView } = useReactFlow();
  const { zoom } = useViewport();
  const zoomValue = (zoom * 100).toFixed(0);

  const animationOption = {
    duration: 500
  };

  return {
    zoomIn: () => zoomIn(animationOption),
    zoomOut: () => zoomOut(animationOption),
    zoomValue,
    zoomTo: (percentage: number) => {
      const percentageFormatted = percentage / 100;
      return zoomTo(percentageFormatted, animationOption);
    },
    fitView: () => fitView(animationOption)
  };
}
