import type { AppTheme, Theming } from '@knack/asterisk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

const { accountSlug, appSlug } = getApplicationBasePathSegments();

async function addTheme({ appTheme }: { appTheme: AppTheme }) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/themes`;
  const { data } = await axios.post(endpoint, { appTheme }, { withCredentials: true });
  return data;
}

async function deleteTheme({ themeId }: { themeId: string }) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/themes/${themeId}`;
  const { data } = await axios.delete(endpoint, { withCredentials: true });
  return data;
}

async function applyTheming({ theming }: { theming: Theming }) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/theming`;
  const { data } = await axios.put(endpoint, { theming }, { withCredentials: true });
  return data;
}

async function updateTheme({ themeId, themeData }: { themeId: string; themeData: AppTheme }) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/themes/${themeId}`;
  const { data } = await axios.put(endpoint, { appTheme: themeData }, { withCredentials: true });
  return data;
}

export function useThemeMutation() {
  const queryClient = useQueryClient();

  return {
    addMutation: useMutation({
      mutationFn: addTheme,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.theming] });
      }
    }),
    deleteMutation: useMutation({
      mutationFn: deleteTheme,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.theming] });
      }
    }),
    applyMutation: useMutation({
      mutationFn: applyTheming,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.theming] });
      }
    }),
    updateMutation: useMutation({
      mutationFn: updateTheme,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.theming] });
      }
    })
  };
}
