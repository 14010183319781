import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SEPARATORS_MAP } from '@/types/schema/fields';

export function Preview() {
  const [t] = useTranslation();

  const { watch, getValues } = useFormContext();

  const hasDecimalPlaces = watch('format.mark_decimal') !== 'none';
  const hasCustomFormat = watch('format.format') === 'custom';
  const hasPrecision = watch('format.precision') !== '0';
  const hasThousandsSeparator = watch('format.mark_thousands') !== 'none';
  const preFormatValue = watch('format.pre');
  const postFormatValue = watch('format.post');

  const getPreview = () => {
    const markThousands = SEPARATORS_MAP[getValues('format.mark_thousands')];
    const markDecimal = SEPARATORS_MAP[getValues('format.mark_decimal')];
    const precision = getValues('format.precision');
    const format = getValues('format.format');

    let formattedValue = `1${hasThousandsSeparator ? markThousands : ''}345${
      hasDecimalPlaces && hasPrecision ? `${markDecimal}${'0'.repeat(parseInt(precision, 10))}` : ''
    }`;

    const hasPostFormat = format === '€_after' || format === '%';

    if (format !== 'none' && !hasPostFormat && !hasCustomFormat) {
      formattedValue = `${format}${formattedValue}`;
    }

    if (hasPostFormat) {
      formattedValue = `${formattedValue}${format === '€_after' ? '€' : '%'}`;
    }

    if (hasCustomFormat) {
      formattedValue = `${preFormatValue || ''}${formattedValue}${postFormatValue || ''}`;
    }

    return formattedValue;
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="font-medium">
        {t('components.data_table.attributes.field_settings.number.preview')}
      </p>
      <div className="rounded-lg bg-base">
        <span className="font-light">{getPreview()}</span>
      </div>
    </div>
  );
}
