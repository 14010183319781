import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  DATE_FORMAT_OPTIONS,
  MINUTES_FORMAT_OPTIONS,
  TIME_FORMAT_OPTIONS,
  TOTAL_FORMAT_OPTIONS,
  type TimerField
} from '@/types/schema/fields';

type DurationFormSettingsProps = {
  field: TimerField;
};

export const durationSchema = z.object({
  format: z.object({
    date_format: z.enum(DATE_FORMAT_OPTIONS),
    time_format: z.enum(TIME_FORMAT_OPTIONS),
    minutes: z.enum(MINUTES_FORMAT_OPTIONS),
    total_format: z.enum(TOTAL_FORMAT_OPTIONS)
  })
});

export function DurationFormSettings({ field }: DurationFormSettingsProps) {
  const [t] = useTranslation();

  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="date-format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.duration.date_format')}
        </Label>
        <Controller
          control={control}
          name="format.date_format"
          defaultValue={field?.format?.date_format || DATE_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || DATE_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="date-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="duration-form-settings-date-format-select"
                {...currentField}
              />
              <Select.Content>
                {DATE_FORMAT_OPTIONS.map((format) => (
                  <Select.Item
                    key={format}
                    value={format}
                    data-testid={`duration-settings-date-format-value-${format}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.duration.date_format_options.${format}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="time-format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.duration.time_format')}
        </Label>
        <Controller
          control={control}
          name="format.time_format"
          defaultValue={field?.format?.time_format || TIME_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || TIME_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="time-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="duration-form-settings-time-format-select"
                {...currentField}
              />
              <Select.Content>
                {TIME_FORMAT_OPTIONS.map(
                  (format) =>
                    format !== 'Ignore Time' && ( // Hide the 'Ignore Time' option as it's not a valid selection for Duration field
                      <Select.Item
                        key={format}
                        value={format}
                        data-testid={`duration-settings-time-format-value-${format}`}
                      >
                        {t(
                          `components.data_table.attributes.field_settings.duration.time_format_options.${format}`,
                          { nsSeparator: false } // Prevents i18next from splitting the key (HH:MM => HH: MM)
                        )}
                      </Select.Item>
                    )
                )}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="minutes-format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.duration.minutes_format')}
        </Label>
        <Controller
          control={control}
          name="format.minutes"
          defaultValue={field?.format?.minutes || MINUTES_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || MINUTES_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="minutes-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="duration-form-settings-minutes-format-select"
                {...currentField}
              />
              <Select.Content>
                {MINUTES_FORMAT_OPTIONS.map((format) => (
                  <Select.Item
                    key={format}
                    value={format}
                    data-testid={`duration-settings-minutes-format-value-${format}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.duration.minutes_format_options.${format}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="total-format-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.duration.total_format')}
        </Label>
        <Controller
          control={control}
          name="format.total_format"
          defaultValue={field?.format?.total_format || TOTAL_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || TOTAL_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="total-format-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="duration-form-settings-total-format-select"
                {...currentField}
              />
              <Select.Content>
                {TOTAL_FORMAT_OPTIONS.map((format) => (
                  <Select.Item
                    key={format}
                    value={format}
                    data-testid={`duration-settings-total-format-value-${format}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.duration.total_format_options.${format}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>
    </div>
  );
}
