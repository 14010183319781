import { useTranslation } from 'react-i18next';

import { type SignatureField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { SignatureEdit } from '@/components/data-table/display/fields/signature/SignatureEdit';

export function SignatureRender(props: FieldRenderProps<SignatureField>) {
  const [t] = useTranslation();
  const { rawValue, rowId, fieldId, isFloating } = props;

  if (isFloating) {
    return <SignatureEdit {...props} />;
  }

  return (
    <div className="flex size-full p-1" data-testid={`signature-render-${rowId}-${fieldId}`}>
      {rawValue.svg && (
        <img
          className="object-contain"
          alt={t('components.data_table.attributes.field_labels.signature.signature')}
          src={`data:image/svg+xml;base64,${btoa(rawValue.svg)}`}
        />
      )}
    </div>
  );
}
