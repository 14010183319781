import { useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IMask, useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

import { type PhoneField } from '@/types/schema/fields';
import { PHONE_EXTENSION_MASK, PHONE_FORMAT_TO_MASK } from '@/utils/constants';

type PhoneInputProps = {
  targetField: PhoneField;
  name: string;
  id: string;
};

export function PhoneInput(props: PhoneInputProps) {
  const {
    targetField: {
      format = {
        extension: false,
        format: 'any'
      }
    },
    name,
    id
  } = props;

  const { setValue, control } = useFormContext();
  const firstOnAcceptCall = useRef(true);
  const [localValue, setLocalValue] = useState('');
  const mask =
    format.format === 'any'
      ? Number
      : `${PHONE_FORMAT_TO_MASK[format.format]}${format.extension ? PHONE_EXTENSION_MASK : ''}`;

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      const phoneMask = IMask.createPipe({
        mask: mask as any,
        lazy: false,
        placeholderChar: '_'
      });
      return phoneMask(currentValue);
    },
    [mask]
  );

  const { ref: inputRef } = useIMask<HTMLInputElement>(
    {
      mask: mask as any,
      lazy: false,
      placeholderChar: '_'
    },
    {
      onAccept: (val) => {
        if (firstOnAcceptCall.current) {
          firstOnAcceptCall.current = false;
          return;
        }

        const valueWithoutPlaceholder = getCurrentValueWithoutPlaceholder(val);
        setLocalValue(valueWithoutPlaceholder);
        setValue(name, valueWithoutPlaceholder);
      }
    }
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Input
          id={id}
          data-testid="phone-input"
          placeholder={PHONE_FORMAT_TO_MASK[format.format]}
          ref={inputRef}
          defaultValue={localValue || field.value?.formatted || ''}
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(0, 0);
          }}
        />
      )}
    />
  );
}
