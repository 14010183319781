import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { type ValidationRule } from '@/types/schema/KnackField';

type DiscardRuleDialogProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRenderForm: React.Dispatch<React.SetStateAction<boolean>>;
  rule?: ValidationRule;
};

export function DiscardRuleDialog(props: DiscardRuleDialogProps) {
  const { showModal, setShowModal, setShouldRenderForm, rule } = props;

  const [t] = useTranslation();

  return (
    <Dialog open={showModal} onOpenChange={setShowModal}>
      <Dialog.Content data-testid="validation-rule-discard-confirmation-modal">
        <Dialog.MainContent className="pb-0">
          <Dialog.Header>
            <Dialog.Title className="text-xl font-medium capitalize leading-6">
              {t('components.data_table.right_sidebar.validation_rules.discard_title', {
                discardTarget: !rule
                  ? t('components.data_table.right_sidebar.validation_rules.validation_rule')
                  : t('components.data_table.right_sidebar.validation_rules.changes')
              })}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p className="text-sm">
              {t('components.data_table.right_sidebar.validation_rules.discard_text', {
                discardTarget: !rule
                  ? t('components.data_table.right_sidebar.validation_rules.progress')
                  : t('components.data_table.right_sidebar.validation_rules.changes')
              })}
            </p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              className="capitalize"
              onClick={() => setShouldRenderForm(false)}
              data-testid="validation-rule-discard-rule-button"
            >
              {t('components.data_table.right_sidebar.validation_rules.discard_confirm', {
                discardTarget: !rule
                  ? t('components.data_table.right_sidebar.validation_rules.rule')
                  : t('components.data_table.right_sidebar.validation_rules.changes')
              })}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
