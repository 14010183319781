import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import {
  convertBuilderApplicationToAppSettingsPayload,
  useApplicationSettingsMutation
} from '@/hooks/api/mutations/useApplicationSettingsMutation';

interface SettingsPageContextProviderProps {
  initialApplication: BuilderApplication;
  children: React.ReactNode;
}

type PageEditorContextState = {
  application: BuilderApplication;
  applicationInitialState: BuilderApplication;
  isApplicationModified: boolean;
  updateApplication: (partialApplicationData: Partial<BuilderApplication>) => void;
  saveChanges: () => void;
  discardChanges: () => void;
} | null;

const SettingsPageContext = createContext<PageEditorContextState>(null);

export function SettingsPageContextProvider({
  initialApplication,
  children
}: SettingsPageContextProviderProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const applicationSettingsMutation = useApplicationSettingsMutation();

  // Store the application in its original state so it can be reset if the changes are discarded
  const applicationInitialState = useRef(initialApplication);

  const [application, setApplication] = useState(initialApplication);
  const [isApplicationModified, setIsApplicationModified] = useState(false);

  const updateApplication = useCallback(
    (partialApplication: Partial<BuilderApplication>) => {
      setApplication({
        ...application,
        ...partialApplication
      });
      setIsApplicationModified(true);
    },
    [application]
  );

  const saveChanges = useCallback(() => {
    application.name = 'basic 5';
    const appSettingsPayload = convertBuilderApplicationToAppSettingsPayload(application);

    applicationSettingsMutation.mutate(appSettingsPayload, {
      onSuccess: () => {
        setIsApplicationModified(false);
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
      },
      onError: () => {
        presentToast({
          title: t('errors.generic_error')
        });
      }
    });
  }, [application, applicationSettingsMutation, presentToast, t]);

  const discardChanges = () => {
    setApplication(applicationInitialState.current);
    setIsApplicationModified(false);
  };

  const contextValue = useMemo(
    () => ({
      application,
      applicationInitialState: applicationInitialState.current,
      updateApplication,
      saveChanges,
      discardChanges,
      isApplicationModified
    }),
    [application, isApplicationModified, saveChanges, updateApplication]
  );

  return (
    <SettingsPageContext.Provider value={contextValue}>{children}</SettingsPageContext.Provider>
  );
}

export const useSettingsPageContext = () => {
  const context = useContext(SettingsPageContext);

  if (!context) {
    throw new Error('useSettingsPageContext must be used within a SettingsPageContextProvider');
  }

  return context;
};
