import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { type ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ShowLabels() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="show-labels"
        checked={view.rows[0].reports[0].layout.data_labels}
        onCheckedChange={(state) => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].layout.data_labels = state;
          updateViewSchema(newSchema);
        }}
      />
      <Label htmlFor="show-labels">
        {t('pages.element_settings.report.categories.general.show_labels')}
      </Label>
    </div>
  );
}
