export interface ThemeEditorToken {
  label: string;
  names: {
    name: string;
    category: 'bg' | 'content' | 'border' | 'ring' | 'stroke';
  }[];
}

export const BRAND: ThemeEditorToken[] = [
  {
    label: 'Primary/Brand',
    names: [
      {
        category: 'bg',
        name: 'brand-accent'
      },
      {
        category: 'content',
        name: 'brand'
      },
      {
        category: 'content',
        name: 'brand-default'
      },
      {
        category: 'ring',
        name: 'default'
      }
    ]
  },
  {
    label: 'Secondary',
    names: [
      {
        category: 'bg',
        name: 'brand-subtle'
      },
      {
        category: 'bg',
        name: 'selection-muted'
      }
    ]
  },
  {
    label: 'Tertiary',
    names: [
      {
        category: 'bg',
        name: 'brand-accent-muted'
      },
      {
        category: 'bg',
        name: 'selection-subtle'
      }
    ]
  }
];

export const TEXTS: ThemeEditorToken[] = [
  {
    label: 'Default',
    names: [
      {
        category: 'content',
        name: 'default'
      }
    ]
  },
  {
    label: 'Muted',
    names: [
      {
        category: 'content',
        name: 'muted'
      }
    ]
  },
  {
    label: 'Subtle',
    names: [
      {
        category: 'content',
        name: 'subtle'
      }
    ]
  },
  {
    label: 'Emphasis',
    names: [
      {
        category: 'content',
        name: 'emphasis'
      }
    ]
  },
  {
    label: 'Success',
    names: [
      {
        category: 'content',
        name: 'success-default'
      }
    ]
  },
  {
    label: 'Warning',
    names: [
      {
        category: 'content',
        name: 'warning-default'
      }
    ]
  },
  {
    label: 'Error',
    names: [
      {
        category: 'content',
        name: 'destructive'
      }
    ]
  }
];

export const SURFACE: ThemeEditorToken[] = [
  {
    label: 'Default',
    names: [
      {
        category: 'bg',
        name: 'default'
      }
    ]
  },
  {
    label: 'Muted',
    names: [
      {
        category: 'bg',
        name: 'muted'
      }
    ]
  },
  {
    label: 'Subtle',
    names: [
      {
        category: 'bg',
        name: 'subtle'
      }
    ]
  },
  {
    label: 'Card',
    names: [
      {
        category: 'bg',
        name: 'card'
      }
    ]
  },
  {
    label: 'Menu',
    names: [
      {
        category: 'bg',
        name: 'menu'
      }
    ]
  },
  {
    label: 'App Navigation',
    names: [
      {
        category: 'bg',
        name: 'navigation'
      }
    ]
  },
  {
    label: 'Input',
    names: [
      {
        category: 'bg',
        name: 'input'
      }
    ]
  },
  {
    label: 'Error',
    names: [
      {
        category: 'bg',
        name: 'destructive'
      }
    ]
  }
];

export const BORDERS: ThemeEditorToken[] = [
  {
    label: 'Default',
    names: [
      {
        category: 'border',
        name: 'default'
      }
    ]
  },
  {
    label: 'Subtle',
    names: [
      {
        category: 'border',
        name: 'subtle'
      }
    ]
  },
  {
    label: 'Emphasis',
    names: [
      {
        category: 'border',
        name: 'emphasis'
      }
    ]
  },
  {
    label: 'Brand',
    names: [
      {
        category: 'border',
        name: 'brand-default'
      }
    ]
  },
  {
    label: 'Error',
    names: [
      {
        category: 'border',
        name: 'destructive'
      }
    ]
  }
];
