import { type ComponentProps } from 'react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from './FieldIcon';

interface InlineKnackFieldProps extends ComponentProps<'span'> {
  fieldType: KnackFieldType;
  fieldName: string;
}

export function InlineKnackField({
  fieldType,
  fieldName,
  className,
  ...props
}: InlineKnackFieldProps) {
  const classes = cn(
    'inline-flex items-center rounded-md border border-subtle p-1 align-middle leading-none text-emphasis',
    className
  );
  return (
    <span className={classes} {...props}>
      <FieldIcon size={16} name={fieldType} className="mr-1 text-subtle" />
      <span>{fieldName}</span>
    </span>
  );
}
