import { queryOptions, useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useApplication } from '@/hooks/useApplication';
import { axiosInstance } from '@/utils/axiosConfig';
import { sanitizeHTMLTags } from '@/utils/formatters';

interface ConnectionRecordResponse {
  total_pages: number;
  current_page: number;
  total_records: number;
  records: ConnectionRecord[];
}

export interface ConnectionRecord {
  id: string;
  identifier: string | number;
}

async function getConnectionRecords(applicationId: string, fieldKey: string) {
  const { data: records } = await axiosInstance.get<ConnectionRecordResponse>(
    `/v1/applications/${applicationId}/connections/${fieldKey}?rows_per_page=all_records`,
    { withCredentials: true }
  );

  const sanitizedRecords =
    records?.records?.map((item) => ({
      ...item,
      identifier: sanitizeHTMLTags(String(item.identifier))
    })) || [];

  return {
    ...records,
    records: sanitizedRecords
  };
}

export function connectionRecordsQuery(pageKey: string, fieldKey: string) {
  return queryOptions({
    queryKey: [queryKeys.connectionRecord, fieldKey],
    queryFn: async () => getConnectionRecords(pageKey, fieldKey)
  });
}

export function useGetConnectionRecords(fieldKey: string) {
  const application = useApplication();
  if (!application) throw new Error('Application not found');

  return useQuery(connectionRecordsQuery(application.id, fieldKey));
}
