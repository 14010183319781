import { Spinner } from '@knack/asterisk-react';

import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function CellLoading(props: { rowId: string; fieldId: string }) {
  const { rowId, fieldId } = props;
  const cellHasFilesPending = useDataTableStore().use.cellHasFilesPending(rowId, fieldId);

  if (!cellHasFilesPending) {
    return null;
  }

  return (
    <div className="absolute inset-0 flex items-center bg-base/80">
      <Spinner className="m-auto" />
    </div>
  );
}
