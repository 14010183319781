import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';

export function ToolbarDropdownButton({ children, ...props }: { children: React.ReactNode }) {
  return (
    <div
      className="h-6 whitespace-nowrap rounded border border-subtle bg-base p-0.5 px-1 text-sm"
      {...props}
    >
      <p className="m-0 mt-[-1px] flex items-center gap-0.5 p-0">
        <span>{children}</span>
        <ChevronDownIcon size={18} />
      </p>
    </div>
  );
}
