import { type ComponentProps } from 'react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { cn } from '@/utils/tailwind';
import { KnackTableIcon } from '@/components/KnackTableIcon';

interface InlineKnackTableProps extends ComponentProps<'span'> {
  tableType: KnackObject['type'];
  tableName: string;
}

export function InlineKnackTable({
  tableType,
  tableName,
  className,
  ...props
}: InlineKnackTableProps) {
  return (
    <span
      className={cn(
        'relative inline-flex items-center rounded-sm border-l-2 border-brand p-1 align-middle leading-none text-emphasis before:absolute before:inset-0 before:rounded-md before:rounded-l-none before:border before:border-l-0',
        className
      )}
      {...props}
    >
      <KnackTableIcon tableType={tableType} size={16} className="mr-1" />
      <span>{tableName}</span>
    </span>
  );
}
