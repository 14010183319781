import { type StoreApi, type UseBoundStore } from 'zustand';
import { type StateStorage } from 'zustand/middleware';

// Returns an object if it is an stringified JSON or the string
function getStringifiedObject(str: string) {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
}

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

/**
 * Creates the selectors automatically
 *
 * Zustand doesn't have a built in way to create selectors automatically but they recommend this in their docs:
 * https://docs.pmnd.rs/zustand/guides/auto-generating-selectors
 *
 * This function is a copy paste but with a few changes:
 * - Allow other functions to use the use object by only creating it if needed
 * - Refactor to use `forEach` to make ESLint happy
 *
 * Sometimes we need custom selectors, so we created a way to add those into the `.use.` object with the `createCustomSelectors` function.
 */
export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;
  if (!store.use) store.use = {};

  Object.keys(store.getState()).forEach((key) => {
    (store.use as any)[key] = () => store((s) => s[key as keyof typeof s]);
  });

  return store;
};

/**
 * Adds an array of custom selectors to the store
 *
 * Receives a store and an object with a key and the selector function.
 */
export const createCustomSelectors = <
  S extends UseBoundStore<StoreApi<object>>,
  T extends Record<string, unknown>
>(
  _store: S,
  selectors: T
) => {
  const store = _store as typeof _store & {
    use: typeof selectors | Record<string, never>;
  };

  if (!store.use) store.use = {};

  Object.entries(selectors).forEach(([key, value]) => {
    (store.use as any)[key] = value;
  });

  return store;
};

// We use a custom storage to store the state in the URL hash.
// We are ignoring the zustandKey that Zustand use to differenciate from different zustand stores.
// This means that only one Zustand store can use this storage at the same time.
export const zustandHashStorage: StateStorage = {
  getItem: (): string => {
    const searchParams = new URLSearchParams(window.location.hash.slice(1));

    const savedState = { state: {} };
    searchParams.forEach((value, key) => {
      savedState.state[key] = getStringifiedObject(value);
    });

    return JSON.stringify(savedState);
  },
  setItem: (_zustandKey, newValue): void => {
    const searchParams = new URLSearchParams();
    const parsedValue = JSON.parse(newValue);

    Object.entries(parsedValue.state).forEach(([key, value]) => {
      if (value === null) return;
      // If it is an object, stringify it
      if (typeof value === 'object') {
        searchParams.set(key, JSON.stringify(value));
        return;
      }
      searchParams.set(key, value as string);
    });

    if (searchParams.toString() !== '') {
      window.history.replaceState(undefined, '', `#${searchParams.toString()}`);
    }
  },
  removeItem: (): void => {
    // We clean the entire hash. This is because we don't know the keys that Zustand has used as we are not using the zustandKey.
    window.history.replaceState(undefined, '', ``);
  }
};
