import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';
import { type AxiosError } from 'axios';

import { useApplication } from '@/hooks/useApplication';
import { axiosInstance } from '@/utils/axiosConfig';
import { type ImageField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { apiHelper } from '@/components/data-table/helpers/apiHelper';
import {
  useDataTableStore,
  type ServerErrorResponseType
} from '@/components/data-table/useDataTableStore';

const MAX_FILE_SIZE_IN_BYTES = 5 * 1000 * 1000;

export const getImageFieldUploadErrorMessage = (error, t) => {
  switch (error.code) {
    case 'file-too-large':
      return t('components.data_table.errors.file_too_large', {
        size: MAX_FILE_SIZE_IN_BYTES / 1000 / 1000
      });
    case 'file-invalid-type':
      return t('components.data_table.errors.image_invalid');
    case 'too-many-files':
      return t('components.data_table.errors.too_many_files');
    default:
      return t('components.data_table.errors.image_invalid');
  }
};

export function useImageUpload(props: FieldRenderProps<ImageField>) {
  const { rowId, fieldId } = props;
  const { saveCell, setFileIsBeingUploaded, cancelFieldEdit, setCellErrors, clearCellErrors } =
    useDataTableStore().use.actions();

  const { presentToast } = useToast();
  const [t] = useTranslation();

  const application = useApplication();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0 || !application?.id) {
        return;
      }

      clearCellErrors(rowId, fieldId);

      // We don't have the asset id until we upload it to the server.
      let currentAssetId = '';
      const preview = URL.createObjectURL(acceptedFiles[0]);

      setFileIsBeingUploaded(rowId, fieldId, true);

      await saveCell(rowId, fieldId, currentAssetId, {
        rawValue: { url: preview, thumb_url: preview, isBeingUploaded: true },
        value: preview
      });

      try {
        // create formData object
        const response = await apiHelper(axiosInstance).uploadImageAsset({
          applicationId: application.id,
          fieldId,
          file: acceptedFiles[0]
        });
        setFileIsBeingUploaded(rowId, fieldId, false);

        // Now we have the asset id and can update the cell
        currentAssetId = response.data[0].id;
        await saveCell(rowId, fieldId, currentAssetId, {
          rawValue: {
            ...response.data[0],
            url: response.data[0].public_url,
            application_id: application?.id,
            s3: true,
            field_key: fieldId
          },
          value: response.data[0].thumb_url
        });
        // Revoke preview now we don't need it to avoid memory leaks
        URL.revokeObjectURL(preview);
      } catch (error: unknown) {
        const errorResponse = error as AxiosError<ServerErrorResponseType>;

        if (errorResponse.response?.data.errors) {
          setCellErrors(rowId, fieldId, errorResponse.response.data.errors);
        } else {
          setCellErrors(rowId, fieldId, [
            {
              message: t('components.data_table.errors.file_invalid')
            }
          ]);
        }

        cancelFieldEdit(rowId, fieldId);
        setFileIsBeingUploaded(rowId, fieldId, false);
        URL.revokeObjectURL(preview);
      }
    },
    [
      application?.id,
      cancelFieldEdit,
      clearCellErrors,
      fieldId,
      rowId,
      saveCell,
      setCellErrors,
      setFileIsBeingUploaded,
      t
    ]
  );

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_IN_BYTES,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp']
    },
    onDropRejected: (rejections) => {
      rejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          presentToast({
            title: getImageFieldUploadErrorMessage(error, t)
          });
        });
      });
    },
    onDrop,
    noClick: true
  });

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    fileRejections
  };
}
