import { t } from 'i18next';

import { type RichTextView } from '@/types/schema/views/RichTextView';
import { generateNewViewKey } from './generateNewViewKey';

export function getRichTextViewSchema() {
  const richTextViewSchema: Partial<RichTextView> = {
    key: generateNewViewKey(),
    type: 'rich_text',
    description: '',
    name: t('views.new_view_defaults.rich_text.name'),
    content: `<p>${t('views.new_view_defaults.rich_text.content')}</p>`
  };

  return richTextViewSchema;
}
