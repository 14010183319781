import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiCodeBracketSquare as CodeBracketIcon,
  HiCodeBracket as CodeIcon,
  HiSquare2Stack as CopyIcon,
  HiCreditCard as CreditCardIcon,
  HiPaintBrush as DesignIcon,
  HiChevronDown as DownIcon,
  HiArrowTopRightOnSquare as GoToLiveAppIcon,
  HiArrowTopRightOnSquare as GoToPageIcon,
  HiQuestionMarkCircle as HelpIcon,
  HiLockClosed as LockIcon,
  HiCog6Tooth as SettingsIcon,
  HiUsers as UsersIcon
} from 'react-icons/hi2';
import { useParams } from 'react-router-dom';
import {
  AsteriskLogo,
  Button,
  DropdownMenu,
  ThemeProviderContext,
  Tooltip
} from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { copyToClipboard } from '@/utils/copy-to-clipboard';
import { FEATURE_FLAGS, isFlagEnabled } from '@/utils/flagsmith';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { cn } from '@/utils/tailwind';
import { getHasTrialExpired, getTrialDaysLeft } from '@/utils/trials';
import { GoToLiveAppMenu } from '@/components/GoToLiveAppMenu';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { HeaderSaving } from '@/components/layout/HeaderSaving';
import { EditableAppName } from './EditableAppName';

export const HEADER_HEIGHT = 64;

export function Header() {
  const { setIsOpen: setIsHelpPanelOpen } = useHelpPanelStore((state) => ({
    setIsOpen: state.setIsOpen
  }));
  const { isDarkMode } = useContext(ThemeProviderContext);
  const [t] = useTranslation();
  const { data: application } = useApplicationQuery();
  const { id: tableKey } = useParams();

  const { hasAccessToNonPublicFeatures } = useGlobalState((state) => ({
    hasAccessToNonPublicFeatures: state.hasAccessToNonPublicFeatures
  }));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const menuItemClass = 'gap-2';
  const menuItemIconClass = 'text-subtle';
  const liveAppUrl = `${import.meta.env.PUBLIC_LIVE_APP_URL}/${application?.account.slug}/${application?.slug}`;
  const oldBuilderUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${
    application?.account.slug
  }/${application?.slug}`;
  const dashboardUrl = `${import.meta.env.PUBLIC_DASHBOARD_URL}`;
  const plansPageUrl = `${dashboardUrl}/settings/billing/plans`;
  const shareFeedbackUrl = 'https://apps.knack.com/ziggystardustbowie/early-access-feedback';

  const hasTrialExpired = application ? getHasTrialExpired(application.account.betaDeadline) : true;
  const trialDaysLeft =
    !hasTrialExpired && application ? getTrialDaysLeft(application.account.betaDeadline) : 0;

  const trackDashboardLinkClickEvent = () => {
    safeLogRocketTrack('NavigateToDashboard', application?.account.isHipaa);
  };

  const trackBuilderLinkClickEvent = () => {
    safeLogRocketTrack('NavigateToBuilder', application?.account.isHipaa);
  };
  return (
    <div
      className="relative flex items-center justify-between gap-2 bg-muted px-3 pl-[0.70rem]"
      style={{ height: HEADER_HEIGHT }}
      data-testid="header"
    >
      <div className="flex w-2/3 items-center gap-2">
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Button
              intent="minimal"
              size="sm"
              aria-label={t('components.layout.header.go_to_dashboard')}
              asChild
            >
              <a
                href={dashboardUrl}
                aria-label="Dashboard Link"
                onClick={trackDashboardLinkClickEvent}
              >
                <div
                  className={cn('size-5 text-brand', {
                    'text-subtle': isDarkMode
                  })}
                >
                  <AsteriskLogo />
                </div>
              </a>
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content className="z-20" side="right" sideOffset={8}>
            {t('components.layout.header.go_to_dashboard')}
          </Tooltip.Content>
        </Tooltip>
        <div className="shrink-0 rounded-2xl bg-action-gradient py-px pl-[1.5px] pr-[1.5px]">
          <div className="flex h-6 rounded-2xl bg-base px-2 font-medium">
            <span
              style={{
                background: 'linear-gradient(to right, #86307b, #e61875)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Early Access
            </span>
          </div>
        </div>
        <div className="flex items-center gap-1 overflow-x-hidden rounded-lg p-1">
          {application && <EditableAppName appName={application.name} />}
          <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
            <DropdownMenu.Trigger asChild>
              <Button
                intent="minimal"
                size="sm"
                className={cn('ml-2', { 'bg-subtle': isDropdownOpen })}
                aria-label={t('components.layout.header.menu')}
                data-testid="header-menu-button"
              >
                <DownIcon size={16} />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="start">
              <DropdownMenu.Item asChild>
                <a href={liveAppUrl} className={menuItemClass}>
                  <GoToPageIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.go_to_live_app')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={menuItemClass}
                onSelect={() => copyToClipboard(liveAppUrl)}
              >
                <CopyIcon size={16} className={menuItemIconClass} />
                {t('components.layout.header.copy_live_app_url')}
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/app`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <SettingsIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.app_settings')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/design`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <DesignIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.live_app_design')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/users`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <LockIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.user_logins')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/ecommerce`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <CreditCardIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.e_commerce')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/api`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <CodeIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.api_and_code')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Item asChild>
                <a
                  href={`${oldBuilderUrl}/settings/embeds`}
                  className={menuItemClass}
                  onClick={trackBuilderLinkClickEvent}
                >
                  <CodeBracketIcon size={16} className={menuItemIconClass} />
                  {t('components.layout.header.embed')}
                </a>
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item asChild>
                <a
                  href={dashboardUrl}
                  className={menuItemClass}
                  onClick={trackDashboardLinkClickEvent}
                >
                  <div className={cn('ml-0.5 h-3.5 w-3.5', menuItemIconClass)}>
                    <AsteriskLogo />
                  </div>
                  {t('components.layout.header.go_to_knack_dashboard')}
                </a>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu>
          <HeaderSaving />
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center justify-end gap-5">
        {isFlagEnabled(FEATURE_FLAGS.data_table_v1) && (
          <a
            href={`${oldBuilderUrl}/records/objects/${tableKey}`}
            className="text-emphasis underline"
          >
            {t('components.layout.header.classic_builder')}
            <span className="text-xs"> </span>
            <span className="relative bottom-0.5 text-xs">🔀</span>
          </a>
        )}
        <a
          href={shareFeedbackUrl}
          className="hidden text-emphasis underline sm:block"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('components.layout.header.share_feedback')}
          <span className="text-xs"> </span>
          <span className="relative bottom-0.5 text-xs">💜</span>
        </a>
        {application?.account.isTrial && (
          <div className="flex items-center gap-2">
            <span className="hidden text-sm text-subtle sm:block md:text-base">
              {t('components.layout.header.trial_days_left', { count: trialDaysLeft })}
            </span>
            <a href={plansPageUrl} className="text-sm border-b-gradient text-brand-gradient">
              {t('actions.upgrade')}
            </a>
          </div>
        )}
        <div>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Button
                intent="minimal"
                size="sm"
                className="px-1.5 py-1 text-default hover:bg-subtle disabled:pointer-events-auto hover:disabled:bg-transparent"
                disabled={!hasAccessToNonPublicFeatures}
                aria-label={t('components.layout.header.help_and_support')}
                data-testid="help-button"
                onClick={() => {
                  if (!hasAccessToNonPublicFeatures) return;
                  setIsHelpPanelOpen(true);
                }}
              >
                <HelpIcon size={24} />
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content className="z-20">
              {hasAccessToNonPublicFeatures
                ? t('components.layout.header.help_and_support')
                : t('keywords.feature_coming_soon', {
                    feature: 'Help & Support'
                  })}
            </Tooltip.Content>
          </Tooltip>
          <Tooltip>
            <Tooltip.Trigger asChild>
              <Button
                intent="minimal"
                size="sm"
                className="px-1.5 py-1 text-default disabled:pointer-events-auto hover:disabled:bg-transparent"
                aria-label={t('components.layout.header.help_and_support')}
                disabled={!hasAccessToNonPublicFeatures}
              >
                <UsersIcon size={24} />
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Content side="bottom" className="z-10">
              {hasAccessToNonPublicFeatures
                ? t('components.layout.header.manage_builders')
                : t('keywords.feature_coming_soon', {
                    feature: 'Manage Builders'
                  })}
            </Tooltip.Content>
          </Tooltip>
        </div>

        <div className="flex items-center">
          <Button
            intent="secondary"
            size="sm"
            className="rounded-md rounded-r-none focus:outline-offset-[-2px]"
            asChild
          >
            <a href={liveAppUrl} target="_blank" rel="noreferrer">
              <span className="hidden md:inline-block">
                {t('components.layout.header.go_to_live_app')}
              </span>
              <GoToLiveAppIcon size={16} className="md:hidden" />
            </a>
          </Button>
          <GoToLiveAppMenu />
        </div>
      </div>
    </div>
  );
}
