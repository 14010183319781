import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

export function MatchSelector({ ruleIndex, name }: { ruleIndex: number; name: string }) {
  const [t] = useTranslation();
  const { control, getValues } = useFormContext();
  return (
    <div className="min-w-[70px] text-right">
      {ruleIndex === 0 && <p>{t('components.data_table.filtering.when')}</p>}
      {ruleIndex === 1 && (
        <Controller
          name={name}
          control={control}
          defaultValue="and"
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              onValueChange={(matchSelected) => onChange(matchSelected)}
              defaultValue={value}
            >
              <Select.Trigger
                placeholder={t('components.data_table.filtering.select')}
                className="w-full rounded-lg"
                data-testid="operator-select"
              />
              <Select.Content>
                <Select.Item value="and">{t('components.data_table.filtering.and')}</Select.Item>
                <Select.Item value="or">{t('components.data_table.filtering.or')}</Select.Item>
              </Select.Content>
            </Select>
          )}
        />
      )}
      {ruleIndex > 1 && <p>{t(`components.data_table.filtering.${getValues(name)}`)}</p>}{' '}
    </div>
  );
}
