import { useMutation } from '@tanstack/react-query';

import { ApiWrapper } from '@/utils/api-wrapper';

interface GoogleSheetsPreviewParams {
  spreadsheetId: string;
  token: string;
}

async function fetchGoogleSheetsPreview(spreadsheetId: string, token: string) {
  const response = await ApiWrapper.getGoogleSheetsPreview(spreadsheetId, token);
  return response;
}

export function useGoogleSheetsPreviewMutation() {
  return useMutation({
    mutationFn: (params: GoogleSheetsPreviewParams) =>
      fetchGoogleSheetsPreview(params.spreadsheetId, params.token)
  });
}
