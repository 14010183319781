import { cn } from '@/utils/tailwind';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { MenuItem as HelpMenuItem } from './MenuItem';
import { HELP_MENU_PANEL, type HelpMenuOption, type HelpMenuPanel } from './types';

interface SubPanelProps {
  helpMenuOptions: Record<Exclude<HelpMenuPanel, 'main'>, HelpMenuOption>;
}

export function SubPanel({ helpMenuOptions }: SubPanelProps) {
  const { activePanel, activeSupportFormId } = useHelpPanelStore((state) => ({
    activePanel: state.activePanel,
    activeSupportFormId: state.activeSupportFormId
  }));
  const panelClasses =
    'absolute w-full translate-x-[1000px] space-y-4 py-4 opacity-0 transition-all duration-500';

  return (
    <div
      className={cn(panelClasses, {
        'translate-x-0 opacity-100': activePanel !== HELP_MENU_PANEL.main && !activeSupportFormId,
        '-translate-x-[1000px]': activeSupportFormId
      })}
    >
      {helpMenuOptions[activePanel]?.children?.map((option: HelpMenuOption) => (
        <HelpMenuItem key={option.title} {...option} />
      ))}
    </div>
  );
}
