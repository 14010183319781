import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';

import { cn } from '@/utils/tailwind';

interface DragButtonProps {
  sortableId: string;
  className?: string;
}

export function DragButton({ sortableId, className }: DragButtonProps) {
  const { listeners, isDragging, setActivatorNodeRef } = useSortable({
    id: sortableId
  });

  return (
    <button
      type="button"
      className={cn(
        'invisible absolute -left-2.5 top-4 cursor-grab rounded-l bg-subtle align-middle active:cursor-grabbing group-hover:visible',
        className,
        {
          'visible z-10': isDragging
        }
      )}
      ref={setActivatorNodeRef}
      {...listeners}
    >
      <DragIcon size={16} />
    </button>
  );
}
