import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '@knack/asterisk-react';

import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';

export function Searchbar() {
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const { setVisibleFields } = useFieldsStore().use.actions();
  const fields = useFieldsStore().use.fields();
  const shouldShowFieldKeys = useFieldsStore().use.shouldShowFieldKeys();

  const onSearch = (value: string) => {
    const lowerCaseValue = value.toLowerCase();

    setSearchValue(lowerCaseValue);

    const visibleFields = fields.filter(
      (field) =>
        field.name.toLowerCase().includes(lowerCaseValue) ||
        (shouldShowFieldKeys ? field.key.toLowerCase().includes(lowerCaseValue) : false)
    );

    setVisibleFields(visibleFields);
  };

  return (
    <InputSearch
      aria-label={t('keywords.search')}
      className="w-full"
      value={searchValue}
      placeholder={
        shouldShowFieldKeys
          ? t('pages.tables_page.fields_list.header.search_by_name_or_key')
          : t('keywords.search')
      }
      onChange={(e) => onSearch(e.target.value)}
      data-testid="search-field-input"
    />
  );
}
