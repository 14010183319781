import { type BuilderView, type BuilderViewKey } from '@/types/schema/BuilderView';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';

export type PageType = 'page' | 'user' | 'authentication' | 'menu';

export type BuilderPageKey = `scene_${string}`;

export interface BuilderPage {
  id: string;
  key: BuilderPageKey;
  slug: string;
  name: string;
  parentSlug: null | string;
  type: PageType;
  sourceObjectKey: null | `object_${string}`;
  menuPageKey: null | BuilderPageKey;
  dropdownPages: null | BuilderPageKey[];
  requiresAuthentication: boolean;
  isTitleVisible: boolean;
  allowedProfileKeys?: KnackObjectProfileKey[];
  limitProfileAccess?: boolean;
  rules: BuilderPageRule[] | null;
  views: BuilderView[];
  groups: BuilderPageSection[];
}

export interface BuilderPageRule {
  key: string;
  type: 'neutral' | 'alert' | 'warning' | 'confirmation';
  action: 'message' | 'hide_views' | 'existing_page' | 'url';
  criteria: Array<{
    field: string;
    operator: string;
    value: string;
  }>;
  message: string;
  close_link: boolean;
  existing_page: string;
  view_keys: string[];
  url: string;
}

export interface BuilderPageSection {
  id: string;
  columns: BuilderPageColumn[];
  layout: SectionLayout;
}

export interface BuilderPageColumn {
  id: string;
  viewKeys: BuilderViewKey[];
  width: '25%' | '33%' | '50%' | '66%' | '75%' | '100%';
}

export const LAYOUT_COLUMNS = ['1', '2', '3'] as const;
export const LAYOUT_TWO_COLUMN_STRUCTURES = ['50/50', '66/33', '33/66', '25/75', '75/25'] as const;
export const LAYOUT_THREE_COLUMN_STRUCTURES = [
  '33/33/33',
  '50/25/25',
  '25/50/25',
  '25/25/50'
] as const;

export type SectionLayoutColumns = (typeof LAYOUT_COLUMNS)[number];
export type SectionLayoutTwoColumnStructure = (typeof LAYOUT_TWO_COLUMN_STRUCTURES)[number];
export type SectionLayoutThreeColumnStructure = (typeof LAYOUT_THREE_COLUMN_STRUCTURES)[number];

export type SectionLayout =
  | {
      columns: '1';
      structure: '100';
    }
  | {
      columns: '2';
      structure: SectionLayoutTwoColumnStructure;
    }
  | {
      columns: '3';
      structure: SectionLayoutThreeColumnStructure;
    };
