import { useMutation, useQueryClient } from '@tanstack/react-query';
import FormData from 'form-data';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { axiosInstance } from '@/utils/axiosConfig';

export interface LogoUploadResponse {
  logo: string;
  logo_url: string;
}

async function updateLogo(file: File, appId: string | undefined, userId: string) {
  const formData = new FormData();
  formData.append('files', file);

  const { data } = await axiosInstance.post<LogoUploadResponse>(
    `/v1/applications/${appId}/users/${userId}/logo/swfupload`,
    formData,
    {
      headers: {
        Accept: 'application/json'
      },
      withCredentials: true
    }
  );
  return data;
}

export function useSettingsMutation() {
  const queryClient = useQueryClient();
  const session = useSession();
  const { data: app } = useApplicationQuery();

  return {
    updateLogoMutation: useMutation({
      mutationFn: ({ file }: { file: File }) => updateLogo(file, app?.id, session.user.id),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      }
    })
  };
}
