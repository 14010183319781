import {
  HiDocument as DocumentIcon,
  HiFolderOpen as FolderIcon,
  HiLockClosed as LockIcon
} from 'react-icons/hi2';
import { IoDocumentLock as DocumentLock } from 'react-icons/io5';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { cn } from '@/utils/tailwind';

interface KnackPageIconProps {
  page: BuilderPage;
  size?: number;
  isGradient?: boolean;
  className?: string;
}

export function KnackPageIcon({ page, size = 16, isGradient, className }: KnackPageIconProps) {
  let IconElement = DocumentIcon;
  if (page.type === 'page' && page.requiresAuthentication) {
    IconElement = LockIcon;
  } else if (page.type === 'authentication') {
    IconElement = DocumentLock;
  } else if (page.type === 'menu') {
    IconElement = FolderIcon;
  }

  return (
    <IconElement
      size={size}
      className={cn(
        'text-subtle',
        {
          'fill-[url(#svg-gradient-1)]': isGradient
        },
        className
      )}
    />
  );
}
