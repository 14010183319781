import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { type Column } from '@/components/import/types';
import { ExistingFields } from './ExistingFields';
import { NewFields } from './NewFields';

export function FieldsWrapper({
  column,
  shouldShowExistingFields = false
}: {
  column: Column;
  shouldShowExistingFields?: boolean;
}) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [t] = useTranslation();

  return (
    <>
      <div className="p-2">
        <Input
          placeholder={t('keywords.search')}
          data-testid="import-field-type-dropdown-search-input"
          className="text-sm"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </div>
      <div className="max-h-[calc(100vh-100px)] overflow-auto p-2">
        {shouldShowExistingFields ? (
          <ExistingFields searchKeyword={searchKeyword} column={column} />
        ) : (
          <NewFields searchKeyword={searchKeyword} column={column} />
        )}
      </div>
    </>
  );
}
