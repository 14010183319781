import { useTranslation } from 'react-i18next';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { PageSettings } from './page-settings/PageSettings';
import { SectionSettings } from './SectionSettings';
import { ViewSettings } from './view-settings/ViewSettings';

export function SettingsPanel() {
  const [t] = useTranslation();
  const { settingsPanelItem } = usePagesPageContext();

  if (!settingsPanelItem) {
    return (
      <CollapsiblePanel.Content title={t('pages.element_settings.no_element_selected')}>
        {t('pages.element_settings.select_element')}
      </CollapsiblePanel.Content>
    );
  }

  if (settingsPanelItem.type === 'page' && settingsPanelItem.page.type !== 'menu') {
    return <PageSettings key={settingsPanelItem.page.key} />;
  }

  if (settingsPanelItem.type === 'view') {
    return <ViewSettings key={settingsPanelItem.view.key} view={settingsPanelItem.view} />;
  }

  if (settingsPanelItem.type === 'section') {
    return (
      <SectionSettings
        key={settingsPanelItem.section.id}
        sectionId={settingsPanelItem.section.id}
      />
    );
  }

  return null;
}
