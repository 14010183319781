import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { Button } from '@knack/asterisk-react';

import { isFlagEnabled } from '@/utils/flagsmith';
import { cn } from '@/utils/tailwind';

interface AddTableSectionItem {
  id: string;
  title: string;
  icon: string | IconType;
}

interface AddTableSourceItemProps {
  item: AddTableSectionItem;
  isDisabled?: boolean;
  RightComponent?: React.ReactNode;
  onClick: (id: string) => void;
}

export function AddTableSourceItem({
  item,
  isDisabled,
  RightComponent,
  onClick,
  ...props
}: AddTableSourceItemProps) {
  const [t] = useTranslation();
  const isImportV2Enabled = isFlagEnabled('gt_add_import_v2');

  return (
    <Button
      intent="secondary"
      className={cn('flex w-full justify-start gap-2 font-normal', {
        'min-h-[88px] min-w-[200px] flex-col items-center hover:bg-brand-muted': isImportV2Enabled
      })}
      disabled={isDisabled}
      onClick={() => onClick(item.id)}
      {...props}
    >
      {typeof item.icon === 'string' ? (
        <div
          className={cn({
            'size-7': isImportV2Enabled,
            'size-5 overflow-hidden': !isImportV2Enabled
          })}
        >
          <img src={item.icon} alt={`${item.title} ${t('components.add_table.logo')}`} />
        </div>
      ) : (
        <item.icon
          className={cn({
            'w-full justify-center fill-[url(#svg-gradient-1)]': isImportV2Enabled
          })}
          size={isImportV2Enabled ? 70 : 20}
        />
      )}
      <p
        className={cn({
          'mt-2 text-center font-semibold': isImportV2Enabled
        })}
      >
        {item.title}
      </p>
      {RightComponent && <div className="ml-auto">{RightComponent}</div>}
    </Button>
  );
}
