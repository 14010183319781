import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Button, Divider } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { ROUTES } from '@/Router';

function useUserRolesTables() {
  const { data: application } = useApplicationQuery();

  const userRoles = application?.objects.filter((o) => o.type === 'UserObject');

  return {
    allUsers: userRoles?.find((table) => table.profile_key === 'all_users'),
    userRole: userRoles?.filter((table) => table.profile_key !== 'all_users')
  };
}

export function RoleSettingsTree({
  table,
  allUsersNameOverride
}: {
  table: KnackObject;
  allUsersNameOverride?: string;
}) {
  const tableKeyDataTable = useDataTableStore().use.objectKey();
  const tables = useUserRolesTables();
  const navigate = useNavigate();

  const selectedSetting = useParams().settingId;

  if (!selectedSetting || !tables) {
    return null;
  }

  return (
    <div className="max-h-96 space-y-2 overflow-y-auto">
      {tables.allUsers && (
        <Button
          intent="minimal"
          className={cn([
            'w-full items-center justify-between p-2',
            {
              'bg-brand-100 focus:bg-brand-100': table.key === tables.allUsers.key
            }
          ])}
          key={tables?.allUsers.key}
          role="button"
          onClick={() => {
            navigate(
              generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, {
                id: tableKeyDataTable,
                selectedTableKey: tables.allUsers?.key ?? '',
                settingId: selectedSetting
              })
            );
          }}
          onKeyDown={() => {}}
        >
          <span className="text-sm font-normal">
            {allUsersNameOverride || tables.allUsers.name}
          </span>
        </Button>
      )}
      <Divider />
      {tables?.userRole?.map((table2) => (
        <Button
          intent="minimal"
          className={cn([
            'h-9 w-full items-center justify-between rounded-lg p-2',
            {
              'bg-brand-100 focus:bg-brand-100': table.key === table2.key
            }
          ])}
          key={table2.key}
          role="button"
          onClick={() => {
            navigate(
              generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, {
                id: tableKeyDataTable,
                selectedTableKey: table2.key,
                settingId: selectedSetting
              })
            );
          }}
          onKeyDown={() => {}}
        >
          <span className="text-sm font-normal">{table2.name}</span>
        </Button>
      ))}
    </div>
  );
}
