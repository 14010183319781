import { type BaseKnackField } from '@/types/schema/KnackField';

export const PHONE_FIELD_FORMATS = [
  '(999) 999-9999',
  '999.999.9999',
  '+99 999 999 99?99',
  '+99 (0)999 999 99?99',
  '99 9999 9999',
  '9999 999 999',
  'any'
] as const;

export type PhoneFieldFormat = (typeof PHONE_FIELD_FORMATS)[number];

export interface PhoneField extends BaseKnackField {
  type: 'phone';
  format: {
    extension: boolean;
    format: PhoneFieldFormat;
  };
}
