import { type KnackObject } from '@/types/schema/KnackObject';
import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';
import { getEdgeConnectionDetail } from './getEdgeConnectionDetail';
import { getEdgeLabel } from './getEdgeConnectionLabel';

export function calculateInitialEdges(knackObjects: KnackObject[] | undefined) {
  const initialEdges: TableEdge[] = [];

  if (!knackObjects || !knackObjects.length) {
    return initialEdges;
  }

  knackObjects.forEach((object) => {
    object.connections.inbound.forEach((inboundConnection) => {
      if (
        initialEdges.some(
          (edge) =>
            edge.source === inboundConnection.object &&
            edge.target === object.key &&
            edge.sourceHandle?.includes(inboundConnection.key)
        )
      ) {
        return;
      }

      initialEdges.push({
        id: `${inboundConnection.object}-${inboundConnection.key}-${object.key}`,
        source: inboundConnection.object,
        sourceHandle: `${inboundConnection.key}-right`,
        target: object.key,
        targetHandle: `${object.key}-left`,
        data: {
          connectionLabel: getEdgeLabel(inboundConnection.belongs_to, inboundConnection.has),
          connectionDetail: getEdgeConnectionDetail({
            connectionType: 'incoming',
            connection: inboundConnection,
            object,
            knackObjects
          })
        }
      });
    });

    object.connections.outbound.forEach((outboundConnection) => {
      if (
        initialEdges.some(
          (edge) =>
            edge.source === object.key &&
            edge.target === outboundConnection.object &&
            edge.sourceHandle?.includes(outboundConnection.key)
        )
      ) {
        return;
      }

      initialEdges.push({
        id: `${object.key}-${outboundConnection.key}-${outboundConnection.object}`,
        source: object.key,
        sourceHandle: `${outboundConnection.key}-right`,
        target: outboundConnection.object,
        targetHandle: `${outboundConnection.object}-left`,
        data: {
          connectionLabel: getEdgeLabel(outboundConnection.belongs_to, outboundConnection.has),
          connectionDetail: getEdgeConnectionDetail({
            connectionType: 'outgoing',
            connection: outboundConnection,
            object,
            knackObjects
          })
        }
      });
    });
  });

  return initialEdges;
}
