import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import {
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import { type RecordRuleEmailRecipient } from '@/types/schema/rules/RecordRule';
import { type KnackTask, type TaskValueTypeValue } from '@/types/schema/tasks/KnackTask';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';

export const NOT_IMPLEMENTED_FIELD_TYPES_IN_TASKS: KnackFieldType[] = ['date_time'];

export const NON_COMPATIBLE_FIELD_TYPES_FOR_TASK_VALUES: KnackFieldType[] = [
  'auto_increment',
  'concatenation',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'equation'
];

export function useTaskFormHelpers() {
  const { getBaseFieldOperators } = useFieldHelpers();

  // Find the first field in the object that is valid for tasks criteria (fields not yet implemented are skipped, e.g. date/time field)
  const getFirstCriteriaFieldInObject = (availableFields: KnackField[]) => {
    if (!availableFields) {
      return undefined;
    }

    const firstFieldInObject = availableFields.find(
      (field) => !NOT_IMPLEMENTED_FIELD_TYPES_IN_TASKS.includes(field.type)
    );

    return firstFieldInObject;
  };

  const getFirstValueFieldInObject = (availableFields: KnackField[]) => {
    if (!availableFields) {
      return undefined;
    }

    const firstFieldInObject = availableFields.find(
      (field) => !NON_COMPATIBLE_FIELD_TYPES_FOR_TASK_VALUES.includes(field.type)
    );

    return firstFieldInObject;
  };

  const getRecordValueTypeAvailableFields = (
    targetField: KnackField,
    availableFields: KnackField[]
  ) => {
    switch (targetField.type) {
      case 'signature':
      case 'address':
      case 'name':
      case 'timer':
        return availableFields.filter((f) => f.type === targetField.type);
      case 'file':
      case 'image':
        return availableFields.filter((f) => f.type === 'file' || f.type === 'image');
      case 'connection':
        return [targetField];
      default:
        return availableFields;
    }
  };

  const getRecordValueTypeDefaultTargetField = (
    currentFieldKey: KnackFieldKey,
    availableFields: KnackField[]
  ) => {
    const currentField = availableFields.find((field) => field.key === currentFieldKey);

    if (!availableFields || !currentField) {
      return undefined;
    }

    const availableTargetFields = getRecordValueTypeAvailableFields(currentField, availableFields);

    if (!availableTargetFields || availableTargetFields.length === 0) {
      return undefined;
    }

    return availableTargetFields[0].key;
  };

  const getDefaultTaskCriteria = (availableFields: KnackField[]) => {
    if (!availableFields) {
      return undefined;
    }

    const firstFieldInObject = getFirstCriteriaFieldInObject(availableFields);

    if (!firstFieldInObject) {
      return undefined;
    }

    const getDefaultCriteriaValue = (fieldType: string) => {
      switch (fieldType) {
        case 'boolean':
          return true;
        case 'connection':
          return [];
        default:
          return '';
      }
    };

    const defaultTaskCriteria: KnackCriteria = {
      field: firstFieldInObject.key,
      operator: getBaseFieldOperators(firstFieldInObject)[0],
      value: getDefaultCriteriaValue(firstFieldInObject.type)
    };

    return defaultTaskCriteria;
  };

  const getDefaultTaskValue = (availableFields: KnackField[]) => {
    if (!availableFields) {
      return undefined;
    }

    const firstFieldInObject = getFirstValueFieldInObject(availableFields);

    if (!firstFieldInObject) {
      return undefined;
    }

    const defaultTaskValue: TaskValueTypeValue = {
      field: firstFieldInObject.key,
      type: 'value',
      value: ''
    };

    return defaultTaskValue;
  };

  const getDefaultTaskCustomEmailRecipient = () => {
    const defaultCustomEmailRecipient: RecordRuleEmailRecipient = {
      recipient_mode: 'to',
      recipient_type: 'custom',
      email: '',
      field: null
    };
    return defaultCustomEmailRecipient;
  };

  const parseTask = (task: KnackTask) => {
    const taskTimeValue = task.schedule.time;

    const hasTaskTimeMode = taskTimeValue.includes('AM') || taskTimeValue.includes('PM');

    let formattedTimeValue = hasTaskTimeMode ? taskTimeValue : `${taskTimeValue}AM`;

    // Add an extra 0 to the time value if hours are single digit
    const [hours] = formattedTimeValue.split(':');
    if (hours.length === 1) {
      formattedTimeValue = `0${formattedTimeValue}`;
    }

    return {
      ...task,
      schedule: {
        ...task.schedule,
        time: formattedTimeValue
      }
    };
  };

  return {
    getFirstCriteriaFieldInObject,
    getFirstValueFieldInObject,
    getDefaultTaskCustomEmailRecipient,
    getDefaultTaskCriteria,
    getDefaultTaskValue,
    getRecordValueTypeAvailableFields,
    getRecordValueTypeDefaultTargetField,
    parseTask
  };
}
