import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider, Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  CONNECTION_DEFAULT_OPTIONS,
  CONNECTION_INPUT_OPTIONS,
  type ConnectionField
} from '@/types/schema/fields';
import { Relationships } from '@/components/field-settings/connection/Relationships';

type ConnectionFormSettingsProps = {
  field: ConnectionField;
  isNewField?: boolean;
  objectKey: string;
};

export const connectionSchema = z.object({
  format: z
    .object({
      input: z.enum(CONNECTION_INPUT_OPTIONS).default(CONNECTION_INPUT_OPTIONS[0]),
      conn_default: z.enum(CONNECTION_DEFAULT_OPTIONS).default(CONNECTION_DEFAULT_OPTIONS[0])
    })
    .default({
      input: CONNECTION_INPUT_OPTIONS[0],
      conn_default: CONNECTION_DEFAULT_OPTIONS[0]
    }),
  relationship: z.object({
    belongs_to: z.enum(['one', 'many']),
    has: z.enum(['one', 'many']),
    object: z.string()
  })
});

export function ConnectionFormSettings({
  field,
  isNewField = false,
  objectKey
}: ConnectionFormSettingsProps) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const isOutgoingConnectionOne = getValues('relationship.has') === 'one';
  const isOutgoingConnectionMany = getValues('relationship.has') === 'many';
  const isRadioButtonsSelected = getValues('format.input') === 'radio';
  const isDropdownSelected = getValues('format.input') === 'chosen';

  return (
    <div className="flex flex-col gap-4">
      <Divider className="mt-2" />
      <Relationships field={field} isNewField={isNewField} objectKey={objectKey} />
      <div className="flex flex-col gap-2">
        <Label htmlFor="input-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.connection.input_label')}
        </Label>
        <Select
          onValueChange={(value) => setValue('format.input', value)}
          value={getValues('format.input') || CONNECTION_INPUT_OPTIONS[0]}
          defaultValue={getValues('format.input') || CONNECTION_INPUT_OPTIONS[0]}
        >
          <Select.Trigger
            id="input-select"
            placeholder={t('actions.select')}
            className="w-full"
            data-testid="connection-form-settings-input-select"
          />
          <Select.Content>
            {CONNECTION_INPUT_OPTIONS.map((option) =>
              (isOutgoingConnectionMany && option === 'radio') ||
              (isOutgoingConnectionOne && option === 'checkbox') ? null : (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`connection-field-settings-input-value-${option}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.connection.input_options.${option}`
                  )}
                </Select.Item>
              )
            )}
          </Select.Content>
        </Select>
        {isRadioButtonsSelected && (
          <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
            {t('components.data_table.attributes.field_settings.connection.radio_buttons_info')}
          </p>
        )}
        {isDropdownSelected && isOutgoingConnectionMany && (
          <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
            {t('components.data_table.attributes.field_settings.connection.dropdown_info')}
          </p>
        )}
      </div>
    </div>
  );
}
