import { useTranslation } from 'react-i18next';

import { type ImageField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ImageUrlEdit } from '@/components/data-table/display/fields/image/url/ImageUrlEdit';

export function ImageUrlRender(props: FieldRenderProps<ImageField>) {
  const { rawValue, rowId, fieldId, isFloating } = props;

  const [t] = useTranslation();

  let thumbUrl = '';
  let altText = '';
  if (typeof rawValue === 'string') {
    thumbUrl = rawValue;
    altText = rawValue;
  } else {
    thumbUrl = rawValue.thumb_url;
    altText = rawValue.filename;
  }

  if (isFloating) {
    return <ImageUrlEdit {...props} />;
  }

  if (!thumbUrl) {
    return (
      <div data-testid={`image-field-no-image-${rowId}-${fieldId}`}>
        <span className="sr-only">{t('components.data_table.image_field.no_preview')}</span>
      </div>
    );
  }

  return (
    <div className="flex size-full p-2" data-testid={`image-render-${rowId}-${fieldId}`}>
      <img
        src={thumbUrl}
        alt={altText}
        className="size-full object-contain"
        data-testid={`image-field-render-${rowId}-${fieldId}`}
      />
    </div>
  );
}
