import { useTranslation } from 'react-i18next';

import { useGlobalState } from '@/hooks/useGlobalStore';

export function HeaderSaving() {
  const savingState = useGlobalState.use.savingState();
  const [t] = useTranslation();

  if (savingState === 'idle') return null;
  return (
    <span className="text-subtle" data-testid="header-saving-indicator">
      {t(`components.layout.header.save_state.${savingState}`)}
    </span>
  );
}
