import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TbArrowBackUp as ArrowBack } from 'react-icons/tb';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '@knack/asterisk-react';

import {
  jSignatureToSignaturePad,
  signaturePadToJSignature
} from '@/components/data-table/display/fields/signature/utils/jSignatureUtils';

const decodeDataURL = (dataURL: string) => {
  const parts = dataURL.split(';base64,');
  return atob(parts[1]);
};

export function SignatureInput({ name }: { name: string }) {
  const sigCanvas = useRef<SignatureCanvas>(null);
  const { setValue, resetField, getValues } = useFormContext();
  const [t] = useTranslation();
  const [hasDrawing, setHasDrawing] = useState(false);
  useEffect(() => {
    if (sigCanvas.current) {
      sigCanvas.current.getSignaturePad().maxWidth = 1.5;
      sigCanvas.current.getSignaturePad().minWidth = 1.5;

      const existingSignature = getValues(name);

      if (existingSignature?.base30 && existingSignature.base30 !== '') {
        sigCanvas.current.fromData(jSignatureToSignaturePad(existingSignature.base30));
        setHasDrawing(true);
      }
    }
  }, [getValues, name]);

  const handleEndSignature = () => {
    if (sigCanvas.current) {
      const svgUrl = sigCanvas.current.toDataURL('image/svg+xml');

      const points = sigCanvas.current.toData();

      const base30 = signaturePadToJSignature(points);

      setValue(`${name}.points`, [...points]);
      setValue(`${name}.svg`, decodeDataURL(svgUrl));
      setValue(`${name}.base30`, base30);
      setHasDrawing(true);
    }
  };

  const clearSignature = () => {
    if (!sigCanvas.current) return;

    sigCanvas.current.clear();
    setHasDrawing(false);
    resetField(`${name}.points`);
    resetField(`${name}.svg`);
    resetField(`${name}.base30`);
  };

  const undoLastStroke = () => {
    if (!sigCanvas.current) return;

    const data = sigCanvas.current.toData();
    if (data && data.length > 0) {
      data.pop();
      sigCanvas.current.fromData(data);
      if (data.length === 0) {
        setHasDrawing(false);
      }
      handleEndSignature();
    }
  };

  return (
    <div className="space-y-2">
      <SignatureCanvas
        onEnd={handleEndSignature}
        onBegin={(e) => e.preventDefault()}
        data-testid="signature-input-pad"
        canvasProps={{
          className: 'w-full border-brand-200 border-[1.5px] border-dashed bg-default rounded-xl'
        }}
        penColor="black"
        ref={sigCanvas}
      />
      <div className="flex w-full items-center gap-2">
        <Button
          data-testid="signature-input-clear-button"
          intent="secondary"
          size="sm"
          disabled={!hasDrawing}
          onClick={clearSignature}
        >
          {t('components.data_table.attributes.field_labels.signature.reset_signature_pad')}
        </Button>
        <Button
          data-testid="signature-input-undo-button"
          intent="secondary"
          size="sm"
          disabled={!hasDrawing}
          onClick={undoLastStroke}
        >
          <ArrowBack size={16} className="mr-1" />
          {t('components.data_table.attributes.field_labels.signature.undo_last_stroke')}
        </Button>
      </div>
    </div>
  );
}
