import { type FieldError } from 'react-hook-form';

import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function CellErrors(props: {
  rowId: string;
  fieldId: string;
  testIdPrefix?: string;
  additionalErrors?: FieldError[] | undefined;
}) {
  const { rowId, fieldId, additionalErrors, testIdPrefix } = props;
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  if (!cellErrors && (!additionalErrors || additionalErrors.length === 0)) {
    return null;
  }

  return (
    <div className="absolute left-[-2px] top-full mt-0.5 w-[calc(100%+4px)]">
      <div
        data-testid={
          testIdPrefix ? `${testIdPrefix}-${rowId}-${fieldId}` : `server-error-${rowId}-${fieldId}`
        }
        className="text-sm"
      >
        {additionalErrors &&
          additionalErrors.map((error) => (
            <p key={error.message} className="bg-subtle p-1">
              {error.message}
            </p>
          ))}

        {cellErrors &&
          cellErrors.map((error) => (
            <p
              key={error.message}
              className="inline-block w-full rounded-sm bg-destructive p-1 text-destructive"
            >
              {error.message}
            </p>
          ))}
      </div>
    </div>
  );
}
