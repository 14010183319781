import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type SidePanelItem = {
  tableKey: string;
  fieldKey?: string;
};

type DataModelSidePanelState = {
  isSidePanelOpen: boolean;
  sidePanelItem: SidePanelItem | null;
  setIsSidePanelOpen: (isSidePanelOpen: boolean) => void;
  setSidePanelItem: (sidePanelItem: SidePanelItem | null) => void;
};

const store = createWithEqualityFn<DataModelSidePanelState>(
  (set) => ({
    isSidePanelOpen: false,
    sidePanelItem: null,
    setIsSidePanelOpen: (isSidePanelOpen: boolean) => {
      set({ isSidePanelOpen });
    },
    setSidePanelItem: (sidePanelItem: SidePanelItem | null) => {
      const isSidePanelOpen = sidePanelItem !== null;
      set({ sidePanelItem, isSidePanelOpen });
    }
  }),
  shallow
);

export const useDataModelSidePanelStore = store;
