import { LiveAppFrame } from '@/components/LiveAppFrame';
import { useThemesMessagingContext } from '@/pages/themes/theme-editor/MessagingContext';

export function PreviewPage() {
  const { registerIframeRef } = useThemesMessagingContext();

  return (
    <div className="flex size-full justify-center bg-subtle">
      <LiveAppFrame registerIFrame={registerIframeRef} pagePath="theme?preview=true" />
    </div>
  );
}
