import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select, Spinner } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { useGetConnectionRecords } from '@/components/data-table/display/fields/connection/useGetConnectionQuery';

type ConnectionInputProps = {
  field: ConnectionField;
  name: string;
};

export function ConnectionInput(props: ConnectionInputProps) {
  const { field, name } = props;
  const [t] = useTranslation();

  const getConnectionsQuery = useGetConnectionRecords(field.relationship?.object || '');

  if (getConnectionsQuery.isFetching) {
    return (
      <div className="flex h-10 flex-col items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Controller
        name={name}
        render={({ field: formField }) => (
          <Select
            onValueChange={(value) => {
              formField.onChange([value]);
            }}
            defaultValue={formField.value?.[0]}
          >
            <Select.Trigger
              placeholder={t('actions.select')}
              className="w-full truncate rounded-lg"
              data-testid="rules-connection-single-select"
            />
            <Select.Content>
              {getConnectionsQuery.data?.records.map((item) => (
                <Select.Item key={item.id} value={item.id}>
                  {item.identifier}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
    </div>
  );
}
