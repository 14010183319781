import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type BooleanField } from '@/types/schema/fields';
import { OPTIONS_BY_FORMAT } from '@/components/data-table/display/fields/boolean/constants';

export function BooleanInput({
  targetField,
  name,
  id
}: {
  targetField: BooleanField;
  name: string;
  id: string;
}) {
  const booleanFormat = targetField.format?.format || 'yes_no';
  const optionsMap = OPTIONS_BY_FORMAT[booleanFormat];
  const options = Object.keys(optionsMap);
  const defaultValue = options[0];
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const currentValue = getValues(name);
  const initialValue =
    options.find((option) => optionsMap[option] === currentValue) || defaultValue;

  useEffect(() => {
    setValue(name, optionsMap[initialValue]);
  }, [setValue, optionsMap, initialValue, name]);

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Select
          onValueChange={(selectedLabel: string) => {
            const selectedBoolean = optionsMap[selectedLabel];
            field.onChange(selectedBoolean);
          }}
          defaultValue={initialValue}
        >
          <Select.Trigger
            id={id}
            placeholder={t('actions.select')}
            className="w-full truncate rounded-lg"
            {...field}
          />
          <Select.Content>
            {options.map((option: string) => (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      )}
    />
  );
}
