import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';

function getNonCompatibleRuleFields(): KnackFieldType[] {
  return ['auto_increment', 'concatenation', 'sum', 'min', 'max', 'average', 'count', 'equation'];
}

export function getCompatibleFields(fields: KnackField[]): KnackField[] {
  const nonCompatibleFields = getNonCompatibleRuleFields();
  return fields.filter((field) => !nonCompatibleFields.includes(field.type));
}
