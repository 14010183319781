import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { type TimeFormatValue } from '@/types/schema/fields';
import { cn } from '@/utils/tailwind';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type DateAmPmTimeFormat = 'AM' | 'PM';

type TimeProps = {
  dateAmPmTimeFormat: DateAmPmTimeFormat | undefined;
  setDateAmPmTimeFormat: (value: DateAmPmTimeFormat) => void;
  timeFormat: TimeFormatValue;
  hasDate: boolean;
  hasAmPmTimeFormat: boolean;
  rowId: string;
  fieldId: string;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formPropName: string;
};

export function Time(props: TimeProps) {
  const {
    dateAmPmTimeFormat,
    setDateAmPmTimeFormat,
    handleKeyDown,
    hasDate,
    hasAmPmTimeFormat,
    rowId,
    fieldId,
    timeFormat,
    onTimeChange,
    formPropName
  } = props;
  const [t] = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const { setIsEditing } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  return (
    <>
      {hasDate && (
        <span className={cn('mx-0.5 bg-base px-1', { hidden: !selectedCell.isEditing })}>
          {t('components.data_table.attributes.field_labels.date_time.at')}
        </span>
      )}
      <div className="flex gap-1">
        <input
          placeholder={`${timeFormat}`}
          className={cn(
            'max-w-[60px] truncate border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
            {
              hidden: !selectedCell.isEditing && hasDate,
              'opacity-0': !selectedCell.isEditing,
              'ring-2 ring-destructive focus:ring-2 focus:ring-destructive':
                cellErrors || errors?.time,
              'size-full max-w-full': !hasDate,
              'max-h-7': selectedCell.isEditing
            }
          )}
          onClick={(e) => {
            if (!selectedCell.isEditing) {
              setIsEditing(true);
              setCursorPositionAtTheEnd(e);
            }
          }}
          onKeyDown={handleKeyDown}
          data-testid={`edit-${formPropName}-input-${rowId}-${fieldId}`}
          onFocus={setCursorPositionAtTheEnd}
          {...register(formPropName, {
            onChange: onTimeChange
          })}
        />
        {hasAmPmTimeFormat && (
          <Button
            size="sm"
            intent="minimal"
            className={cn(
              'mx-1 max-h-7 rounded-md border-0 border-transparent bg-base px-0.5 hover:border-transparent hover:bg-base focus:border-transparent',
              {
                hidden: !selectedCell.isEditing
              }
            )}
            onClick={() => setDateAmPmTimeFormat(dateAmPmTimeFormat === 'AM' ? 'PM' : 'AM')}
            data-testid={`edit-${formPropName}-am-pm-toggle-${rowId}-${fieldId}`}
          >
            {dateAmPmTimeFormat}
          </Button>
        )}
      </div>
    </>
  );
}
