const YES_NO_OPTIONS = {
  Yes: true,
  No: false
};

const ON_OFF_OPTIONS = {
  On: true,
  Off: false
};

const TRUE_FALSE_OPTIONS = {
  True: true,
  False: false
};

export const OPTIONS_BY_FORMAT = {
  yes_no: YES_NO_OPTIONS,
  on_off: ON_OFF_OPTIONS,
  true_false: TRUE_FALSE_OPTIONS
};
