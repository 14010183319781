import { useEffect, useRef, useState } from 'react';
import { Resizable } from 're-resizable';

import { cn } from '@/utils/tailwind';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';

interface ResizableCollapsiblePanelProps {
  title: string;
  panelContent: React.ReactNode;
  mainContent: React.ReactNode;
  autoSaveId: string;
  position?: 'left' | 'right';
  shouldForceOpen?: boolean;
  onToggleCollapse?: (isCollapsed: boolean) => void;
}

interface StoredPanelState {
  [key: string]: {
    isCollapsed: boolean;
    expandedWidth: number;
  };
}

const MIN_WIDTH_PIXELS = 325;
const MAX_WIDTH_PIXELS = 800;
const COLLAPSED_WIDTH_PIXELS = 40;
const PANEL_SAVE_ID_PREFIX = 'kn-resizable-panel:';

// Determine if the panel should initialize as collapsed or not based on the saved panel state in local storage
function getStoredPanelState(saveId: string) {
  if (!saveId) return null;

  const savedPanelState = localStorage.getItem(`${PANEL_SAVE_ID_PREFIX}${saveId}`);

  if (savedPanelState) {
    const parsedState = JSON.parse(savedPanelState);

    // Ensure we get the correct key that contains the panel state
    const relevantKey = Object.keys(parsedState).find((key) => key.includes(saveId));

    if (!relevantKey) return false;

    return parsedState[relevantKey];
  }

  return null;
}

export function ResizableCollapsiblePanel({
  title,
  panelContent,
  mainContent,
  autoSaveId,
  position = 'left',
  shouldForceOpen = false,
  onToggleCollapse
}: ResizableCollapsiblePanelProps) {
  const storedPanelState = getStoredPanelState(autoSaveId);

  const [isCollapsed, setIsCollapsed] = useState(() => {
    if (storedPanelState) {
      return storedPanelState.isCollapsed;
    }
    return false;
  });
  const [lastExpandedWidth, setLastExpandedWidth] = useState<number>(() => {
    if (storedPanelState) {
      return storedPanelState.expandedWidth;
    }
    return MIN_WIDTH_PIXELS;
  });

  const [isResizing, setIsResizing] = useState(false);

  const panelRef = useRef<Resizable>(null);

  function storePanelState(newIsCollapsed: boolean, newExpandedWidth: number) {
    const panelState: StoredPanelState = {
      [autoSaveId]: {
        isCollapsed: newIsCollapsed,
        expandedWidth: newExpandedWidth
      }
    };

    localStorage.setItem(`${PANEL_SAVE_ID_PREFIX}${autoSaveId}`, JSON.stringify(panelState));
  }

  function collapsePanel() {
    const expandedWidth = panelRef.current?.size.width ?? MIN_WIDTH_PIXELS;
    panelRef.current?.updateSize({ width: COLLAPSED_WIDTH_PIXELS });
    setLastExpandedWidth(expandedWidth);
    setIsCollapsed(true);
    storePanelState(true, expandedWidth);
  }

  function expandPanel() {
    const expandedWidth = lastExpandedWidth ?? MIN_WIDTH_PIXELS;
    panelRef.current?.updateSize({ width: expandedWidth });
    setIsCollapsed(false);
    storePanelState(false, expandedWidth);
  }

  useEffect(() => {
    if (shouldForceOpen && isCollapsed) {
      expandPanel();

      if (onToggleCollapse) {
        onToggleCollapse(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldForceOpen, isCollapsed]);

  const resizableContent = (
    <Resizable
      className="relative"
      ref={panelRef}
      handleWrapperClass={cn(
        'before:absolute before:top-0 before:h-full before:w-[1px] before:bg-subtle',
        {
          'before:right-0': position === 'left',
          'before:left-0': position === 'right',
          'hover:before:bg-brand': !isCollapsed,
          'before:bg-brand': isResizing
        }
      )}
      defaultSize={{
        width: isCollapsed ? COLLAPSED_WIDTH_PIXELS : lastExpandedWidth
      }}
      minWidth={isCollapsed ? COLLAPSED_WIDTH_PIXELS : MIN_WIDTH_PIXELS}
      maxWidth={MAX_WIDTH_PIXELS}
      enable={{
        right: position === 'left' && !isCollapsed,
        left: position === 'right' && !isCollapsed
      }}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={() => {
        setIsResizing(false);
        storePanelState(isCollapsed, panelRef.current?.size.width ?? MIN_WIDTH_PIXELS);
      }}
    >
      <CollapsiblePanel
        isResizable
        isCollapsed={isCollapsed}
        position={position}
        title={title}
        toggleCollapse={() => {
          if (isCollapsed) {
            expandPanel();
          } else {
            collapsePanel();
          }

          if (onToggleCollapse) {
            onToggleCollapse(!isCollapsed);
          }
        }}
      >
        {panelContent}
      </CollapsiblePanel>
    </Resizable>
  );

  const mainContentClasses = 'flex-1 overflow-hidden';

  return (
    <div className="flex h-full flex-1 overflow-hidden">
      {position === 'left' ? (
        <>
          {resizableContent}
          <div className={mainContentClasses}>{mainContent}</div>
        </>
      ) : (
        <>
          <div className={mainContentClasses}>{mainContent}</div>
          {resizableContent}
        </>
      )}
    </div>
  );
}
