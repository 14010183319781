import { useEffect, useState } from 'react';

import { useScript } from '@/hooks/useScript';

export const GOOGLE_API_SCOPES = {
  driveMetadataReadonly: 'https://www.googleapis.com/auth/drive.metadata.readonly',
  spreadsheetsReadonly: 'https://www.googleapis.com/auth/spreadsheets.readonly'
} as const;

export const SPREADSHEET_FORMATS = {
  googleSheets: 'application/vnd.google-apps.spreadsheet'
} as const;

export const useGooglePickerApi = (
  pickerCallback: (data: google.picker.ResponseObject, token: string) => void
) => {
  const googleApiScriptStatus = useScript('https://apis.google.com/js/api.js');
  const googleSignInScriptStatus = useScript('https://accounts.google.com/gsi/client');
  const [isPickerReady, setPickerReady] = useState(false);

  const onPickerApiLoad = () => {
    setPickerReady(true);
  };

  useEffect(() => {
    if (googleApiScriptStatus === 'ready' && googleSignInScriptStatus === 'ready') {
      gapi.load('picker', onPickerApiLoad);
    }
  }, [googleApiScriptStatus, googleSignInScriptStatus]);

  const openPicker = () => {
    if (!isPickerReady) {
      return;
    }

    const showPicker = async (accessToken: string) => {
      const docsView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
        .setMode(google.picker.DocsViewMode.LIST)
        .setMimeTypes(SPREADSHEET_FORMATS.googleSheets);

      const picker = new google.picker.PickerBuilder()
        .addView(docsView)
        .setOAuthToken(accessToken)
        .setCallback((data) => {
          if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
            pickerCallback(data, accessToken);
          }
        })
        .build();

      picker.setVisible(true);
    };

    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: import.meta.env.PUBLIC_GOOGLE_ID,
      scope: `${GOOGLE_API_SCOPES.driveMetadataReadonly} ${GOOGLE_API_SCOPES.spreadsheetsReadonly}`,
      callback: async (response) => {
        if (response.error !== undefined) {
          throw new Error(response.error);
        }
        await showPicker(response.access_token);
      }
    });

    tokenClient.requestAccessToken({ prompt: 'consent' });
  };

  return openPicker;
};
