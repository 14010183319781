import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Input, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackObject } from '@/types/schema/KnackObject';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { CopyTableOptions } from '@/pages/tables/tables-tree/table-dropdown-menu/duplicate-table-form/DuplicateTableForm';

interface SourceTargetSelectStepProps {
  table: KnackObject;
  copyTableOption: CopyTableOptions;
  app: BuilderApplication;
  isUser?: boolean;
  handleSelectChange: (value: string) => void;
}

export function SourceTargetSelectStep({
  table,
  copyTableOption,
  app,
  isUser,
  handleSelectChange
}: SourceTargetSelectStepProps) {
  const [t] = useTranslation();

  const { control, register, formState } = useFormContext();

  return (
    <>
      {!isUser && (
        <Form.Section className="space-y-2">
          <Form.Label
            className="text-sm font-medium"
            htmlFor="table-name-input"
            data-testid="duplicate-table-radio-buttons-label"
          >
            {t('components.dialogs.tables.duplicate.copy_fields_from', {
              tableName: table.name
            })}
          </Form.Label>
          <Controller
            control={control}
            name="copyTableOption"
            render={({ field }) => (
              <RadioGroup onValueChange={field.onChange} value={field.value}>
                <RadioGroup.Container>
                  <RadioGroup.Item
                    value={CopyTableOptions.NewTable}
                    id="new-table-option"
                    data-testid="new-table-option"
                  />
                  <Label htmlFor="new-table-option">
                    {t('components.dialogs.tables.duplicate.new_table')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item
                    value={CopyTableOptions.ExistingTable}
                    id="existing-table-option"
                    data-testid="existing-table-option"
                  />
                  <Label htmlFor="existing-table-option">
                    {t('components.dialogs.tables.duplicate.existing_table')}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            )}
          />
        </Form.Section>
      )}

      {copyTableOption === CopyTableOptions.NewTable && (
        <Form.Section>
          <Form.Label
            className="text-sm font-medium"
            htmlFor="table-name-input"
            data-testid="duplicate-table-radio-buttons-label"
          >
            {!isUser
              ? t('components.dialogs.tables.duplicate.new_table_name', {
                  tableName: table.name
                })
              : t('components.dialogs.roles.copy.new_user_role_name')}
          </Form.Label>
          <Input.Container>
            <Input
              data-testid="table-name-input"
              id="table-name-input"
              type="text"
              title={t('components.dialogs.tables.table_name')}
              intent={formState.errors.tableName && 'destructive'}
              {...register('tableName')}
            />
          </Input.Container>
          <FormErrorMessage name="tableName" errors={formState.errors} />
        </Form.Section>
      )}

      {copyTableOption === CopyTableOptions.ExistingTable && (
        <Form.Section>
          <Form.Label
            className="text-sm font-medium"
            htmlFor="table-name-select"
            data-testid="duplicate-table-radio-buttons-label"
          >
            {t('components.dialogs.tables.duplicate.select_a_table')}
          </Form.Label>
          <Select
            onValueChange={handleSelectChange}
            data-testid="display-field-select"
            defaultValue={app?.objects[0].key}
          >
            <Select.Trigger className="w-full truncate rounded-lg" />
            <Select.Content>
              {app?.objects.map((field) => (
                <Select.Item
                  key={field.key}
                  className="truncate hover:bg-muted"
                  value={field.key}
                  data-testid={`display-field-select-option-${field.key}`}
                >
                  {field.name}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </Form.Section>
      )}
    </>
  );
}
