import { useEffect, useState } from 'react';
import { Input } from '@knack/asterisk-react';

import { useApplicationMutation } from '@/hooks/api/mutations/useApplicationMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { useGlobalState } from '@/hooks/useGlobalStore';

interface EditableAppNameProps {
  appName: string;
}

export function EditableAppName({ appName }: EditableAppNameProps) {
  const presentErrorToast = useErrorToast();
  const { mutate, isPending } = useApplicationMutation();

  const [newAppName, setNewAppName] = useState(appName);
  const [isEditingName, setIsEditingName] = useState(false);

  const { createPendingTask, stopPendingTask } = useGlobalState.use.actions();

  const cancelEditing = () => {
    setIsEditingName(false);
    if (newAppName !== appName) {
      setNewAppName(appName);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!newAppName || newAppName === appName) {
      cancelEditing();
      return;
    }

    const taskId = createPendingTask();

    if (appName && newAppName) {
      mutate(
        {
          app_name: newAppName.trim()
        },
        {
          onSuccess: () => {
            setIsEditingName(false);
            stopPendingTask(taskId);
          },
          onError: (error) => {
            presentErrorToast({
              error,
              fallbackKey: 'errors.save_unsuccessful',
              translationPrefix: 'errors',
              i18nOptions: {
                appName: newAppName
              }
            });

            cancelEditing();
            stopPendingTask(taskId);
          }
        }
      );
    }
  };

  // Calculate the width of the input based on the number of characters of the app name without white spaces.
  const inputWidth = newAppName?.replace(/\s/g, '').length;

  useEffect(() => {
    if (appName !== newAppName) {
      setNewAppName(appName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appName]);

  if (isEditingName) {
    return (
      <form onSubmit={handleSubmit} className="min-w-[15ch]" style={{ width: `${inputWidth}ch` }}>
        <Input
          data-testid="editable-app-name-input"
          className="ml-2 h-8 w-full bg-transparent px-1 py-0 font-medium focus:border-emphasis focus:outline-0"
          value={newAppName}
          onChange={(event) => setNewAppName(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              cancelEditing();
            }
          }}
          onBlur={cancelEditing}
          onFocus={(event) => event.target.select()}
          autoFocus
        />
      </form>
    );
  }

  return (
    <button
      type="button"
      data-testid="editable-app-name-button"
      className="truncate rounded-lg p-1 font-semibold text-emphasis hover:bg-subtle"
      onClick={() => setIsEditingName(true)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          setIsEditingName(true);
        }
      }}
    >
      {isPending ? appName : newAppName}
    </button>
  );
}
