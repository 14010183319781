import { useTranslation } from 'react-i18next';
import { MultiSelect, RadioCardGroup, type MultiSelectOption } from '@knack/asterisk-react';

import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

interface UserPageAccessSettingsProps {
  userRoleObjects: KnackObject[];
}

export function UserPageAccessSettings({ userRoleObjects }: UserPageAccessSettingsProps) {
  const [t] = useTranslation();

  const { page, updatePage } = usePageEditorContext();

  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  const selectedUserRoleOptions = userRoleOptions.filter((option) =>
    page.allowedProfileKeys?.includes(option.key as KnackObjectProfileKey)
  );

  const onLimitProfileAccessChange = (limitProfileAccess: 'true' | 'false') => {
    updatePage({
      action: 'update',
      type: 'page',
      pageUpdates: {
        limitProfileAccess: limitProfileAccess === 'true'
      }
    });
  };

  const onSelectUserRoles = (newSelectedOptions: MultiSelectOption[]) => {
    updatePage({
      action: 'update',
      type: 'page',
      pageUpdates: {
        allowedProfileKeys: newSelectedOptions.map((option) => option.key as KnackObjectProfileKey)
      }
    });
  };

  return (
    <>
      <h3 className="mb-2 font-medium">{t('pages.settings.access.access_control')}</h3>
      <RadioCardGroup
        value={String(page.limitProfileAccess || false)}
        onValueChange={onLimitProfileAccessChange}
      >
        <RadioCardGroup.Card
          value="false"
          className="items-center p-3 outline-2 sm:p-3"
          contentClassName="pointer-events-auto"
          width="100%"
        >
          <span className="text-emphasis">{t('pages.settings.access.all_logged_in_users')}</span>
        </RadioCardGroup.Card>
        <RadioCardGroup.Card
          value="true"
          className="p-3 outline-2 sm:p-3"
          contentClassName="pointer-events-auto"
          width="100%"
        >
          <span className="mb-2 text-emphasis">
            {t('pages.settings.access.specific_user_roles_only')}
          </span>
          <p className="mb-1 font-medium text-default">
            {t('pages.settings.access.select_the_user_roles')}
          </p>
          <MultiSelect
            id="page-settings-access-user-roles"
            options={userRoleOptions}
            selectedOptions={selectedUserRoleOptions}
            onSelectOptions={onSelectUserRoles}
          />
        </RadioCardGroup.Card>
      </RadioCardGroup>
    </>
  );
}
