export const HELP_MENU_PANEL = {
  main: 'main',
  getStarted: 'get-started',
  demos: 'demos',
  knowledgeBase: 'knowledge-base',
  extendApp: 'extend-app',
  hireAnExpert: 'hire-an-expert',
  support: 'support',
  community: 'community'
} as const;

export type HelpMenuPanel = (typeof HELP_MENU_PANEL)[keyof typeof HELP_MENU_PANEL];

export const SUPPORT_FORM_PANEL = {
  getHelp: 'get-help',
  feedback: 'feedback',
  reportBug: 'report-bug',
  emergency: 'emergency'
} as const;

export type SupportFormPanel = (typeof SUPPORT_FORM_PANEL)[keyof typeof SUPPORT_FORM_PANEL];

export interface HelpMenuOption {
  icon: string;
  title: string;
  description: string;
  link?: string;
  children?: HelpMenuOptionChildren[];
}

export interface HelpMenuOptionChildren extends HelpMenuOption {
  id?: string;
  collapsibleItems?: HelpMenuOption[];
  onClick?: () => void;
  enabled?: boolean;
}
