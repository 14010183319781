import { nanoid } from 'nanoid';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { createSelectors } from '@/utils/zustand';

type SaveState = 'saving' | 'saved' | 'idle';

const initialState = {
  savingState: 'idle',
  savingTimeout: undefined,
  pendingBackgroundTask: {},
  hasAccessToNonPublicFeatures: import.meta.env.DEV,
  isDraftModeEnabled: false
} satisfies Partial<GlobalStore>;

type GlobalStore = {
  savingState: SaveState;
  savingTimeout?: ReturnType<typeof setTimeout>;
  pendingBackgroundTask: Record<string, boolean>;
  hasAccessToNonPublicFeatures: boolean;
  isDraftModeEnabled: boolean;
  actions: {
    /**
     * You can create a pending task for any operation in the app, it will be used to show a saving indicator
     *
     * When you want the show indicator to show you can call:
     * ```
     *   const taskId = createPendingTask();
     * ```
     *
     * When the operation is done you can stop the pending task by calling:
     *
     * ```
     *   stopPendingTask(taskId);
     * ```
     */
    createPendingTask: () => string;
    /**
     * Stop a pending task, you have to call `createPendingTask` before
     */
    stopPendingTask: (pendingTaskId: string) => void;

    setIsDraftModeEnabled: (isDraftModeEnabled: boolean) => void;
    reset: () => Promise<void>;
  };
};

const globalStateBase = create<GlobalStore>()(
  immer((set, get) => ({
    ...initialState,
    actions: {
      createPendingTask: () => {
        const operationId = nanoid(5);
        set((draft) => {
          // Clean the saving timeout as we are again in saving mode
          if (draft.savingTimeout) {
            clearTimeout(draft.savingTimeout);
          }

          draft.pendingBackgroundTask[operationId] = true;
          draft.savingState = 'saving';
        });
        return operationId;
      },
      stopPendingTask: (pendingTaskId) => {
        const { pendingBackgroundTask } = get();
        if (!pendingBackgroundTask[pendingTaskId]) {
          return;
        }

        set((draft) => {
          delete draft.pendingBackgroundTask[pendingTaskId];

          if (Object.keys(draft.pendingBackgroundTask).length === 0) {
            draft.savingState = 'saved';

            // Wait a second before hiding the saving indicator
            draft.savingTimeout = setTimeout(() => {
              set(() => ({ savingState: 'idle' }));
            }, 1000);
          }
        });
      },
      reset: async () => {
        // We want to ensure that the saving state is idle before resetting the store.
        // If not we could have an api call in progress and the store will be modified by that call after the reset.
        do {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, 50);
          });
        } while (get().savingState === 'saving');

        if (get().savingTimeout) clearTimeout(get().savingTimeout);

        set(initialState);
      },
      setIsDraftModeEnabled: (isDraftModeEnabled) => {
        set((draft) => {
          draft.isDraftModeEnabled = isDraftModeEnabled;
        });
      }
    }
  }))
);

export const useGlobalState = createSelectors(globalStateBase);
