import { type XYPosition } from 'reactflow';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type SavedDataModelDiagram } from '@/hooks/api/queries/useDataModelDiagramQuery';
import { tableNodeTypeName, type TableNode } from '@/pages/data-model/diagram/TableNodeComponent';
import { DEFAULT_NODE_WIDTH } from '@/pages/data-model/helpers/constants';

export function calculateInitialNodes(
  knackObjects: KnackObject[] | undefined,
  recordCounts: { [key: string]: number } | undefined,
  savedDiagram?: SavedDataModelDiagram
) {
  const initialNodes: TableNode[] = [];

  if (!knackObjects || !knackObjects.length) {
    return initialNodes;
  }

  // If there is a saved diagram, we want to keep track of how many new tables have been created since the diagram was last saved, so we can position those new nodes correctly
  let numberOfNewNodesFromLastSaved = 0;

  knackObjects.forEach((object) => {
    let nodePosition: XYPosition = { x: 0, y: 0 };

    if (savedDiagram?.nodes.length) {
      const savedNode = savedDiagram.nodes.find((node) => node.id === object.key);

      if (savedNode) {
        nodePosition = savedNode.position;
      } else {
        numberOfNewNodesFromLastSaved += 1;
        nodePosition = {
          // We want to position the new nodes that aren't in the saved diagram at the top left of the bounding box of the diagram
          // Each new node will be positioned 50px to the left of the previous node, so they don't stack on top of each other
          x: savedDiagram.nodesBounds.x - (DEFAULT_NODE_WIDTH + 50) * numberOfNewNodesFromLastSaved,
          y: savedDiagram.nodesBounds.y
        };
      }
    }

    initialNodes.push({
      id: object.key,
      type: tableNodeTypeName,
      position: nodePosition,
      data: {
        key: object.key,
        name: object.name,
        type: object.type,
        totalRecords: recordCounts?.[object.key] ?? null,
        totalIncomingConnections: object.connections.inbound.length,
        totalOutgoingConnections: object.connections.outbound.length,
        fields: object.fields.map((field) => ({
          key: field.key,
          name: field.name,
          type: field.type,
          isDisplayField: field.key === object.identifier
        }))
      },
      deletable: false
    });
  });

  return initialNodes;
}
