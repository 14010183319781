import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Checkbox, useToast } from '@knack/asterisk-react';

import { BooleanEdit } from '@/components/data-table/display/fields/boolean/BooleanEdit';
import { type BooleanField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function BooleanRender(props: FieldRenderProps<BooleanField>) {
  const { rawValue, fieldId, rowId, type, isFloating, value, isEditable } = props;

  const { presentToast } = useToast();
  const [t] = useTranslation();
  const [showOptionsIcon, setShowOptionsIcon] = useState(false);

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { setCellErrors, saveCell } = useDataTableStore().use.actions();

  const { format } = currentField;

  const handleCheckChange = async (checked: boolean) => {
    try {
      if (!isEditable) {
        return;
      }

      if (format?.required && !checked) {
        setCellErrors(rowId, fieldId, [
          { message: t('components.data_table.errors.required_field') }
        ]);
        return;
      }

      await saveCell(rowId, fieldId, checked, { value: checked, rawValue: checked });
    } catch (error) {
      presentToast({
        title: t('components.data_table.errors.update_record'),
        intent: 'destructive'
      });
    }
  };

  if (format?.input === 'checkbox') {
    return (
      <div className="size-full bg-base p-2">
        <div className="flex items-center truncate">
          <Checkbox
            data-testid={`boolean-field-checkbox-${rowId}-${fieldId}`}
            className="ml-1 mr-2"
            checked={rawValue}
            onCheckedChange={handleCheckChange}
          />
          <p>{format?.text}</p>
        </div>

        <div className="absolute bottom-0 mt-1 w-full bg-base">
          {cellErrors && (
            <div className="w-20 min-w-full bg-action-destructive-primary/20">
              <p
                key={`boolean-checkbox-error-${rowId}-${fieldId}`}
                data-testid={`boolean-checkbox-error-${rowId}-${fieldId}`}
                className="text-sm"
              >
                {cellErrors.map((error) => (
                  <span key={error.message}>{error.message}</span>
                ))}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (isFloating) {
    return <BooleanEdit {...props} />;
  }

  return (
    <div
      className="flex size-full justify-between bg-base p-2 text-right"
      data-testid={`boolean-field-${rowId}-${fieldId}`}
      onMouseEnter={() => {
        setShowOptionsIcon(true);
      }}
      onMouseLeave={() => {
        setShowOptionsIcon(false);
      }}
    >
      <p className="truncate" data-testid="boolean-field-current-option">
        {value}
      </p>
      {showOptionsIcon && <ChevronDownIcon className="ml-auto" data-testid="boolean-field-icon" />}
    </div>
  );
}
