import { useFormContext, type FieldError, type FieldValues, type Path } from 'react-hook-form';
import { Input } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type TableView } from '@/types/schema/views/TableView';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from '@/components/filters/ConnectionFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

interface SourceFiltersInputProps<T extends FieldValues> {
  fieldKey: KnackFieldKey;
  formFieldName: Path<T>;
  error: FieldError | undefined;
}

export function FiltersInput<T extends FieldValues>({
  fieldKey,
  formFieldName,
  error
}: SourceFiltersInputProps<T>) {
  const { sourceObject } = useActiveViewContext<TableView>();
  const { register } = useFormContext<T>();

  const field = sourceObject.fields.find((sourceObjectField) => sourceObjectField.key === fieldKey);

  if (!field) {
    return null;
  }

  switch (field.type) {
    case 'connection':
      return <ConnectionFieldInput<T> formFieldName={formFieldName} field={field} />;
    // TODO: Update when finish date_time input
    // case 'date_time':
    case 'multiple_choice':
      return <MultipleChoiceFieldInput<T> formFieldName={formFieldName} field={field} />;
    case 'boolean':
      return <BooleanFieldInput<T> formFieldName={formFieldName} field={field} />;
    default:
      break;
  }

  return (
    <Input
      className="text-sm"
      intent={error ? 'destructive' : 'default'}
      {...register(formFieldName)}
    />
  );
}
