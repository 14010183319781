import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type KnackApplication } from '@/types/schema/KnackApplication';
import { type KnackObject } from '@/types/schema/KnackObject';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getBuilderBaseUrl } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { ROUTES } from '@/Router';

type CreateEmptyObjectParams = {
  objectName?: string;
  user?: boolean;
};

async function createEmptyObject({ objectName = '', user = false }: CreateEmptyObjectParams) {
  const response = await axios.post(
    '/v1/objects',
    {
      name: objectName,
      user,
      fields: [],
      template: ''
    },
    {
      withCredentials: true
    }
  );

  return response;
}

export function useCreateEmptyObjectMutation(
  {
    shouldForceRedirectToV4,
    shouldSkipRedirect
  }: { shouldForceRedirectToV4: boolean; shouldSkipRedirect?: boolean } = {
    shouldForceRedirectToV4: false,
    shouldSkipRedirect: false
  }
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return useMutation({
    mutationFn: createEmptyObject,
    onSuccess: (response, variables) => {
      // Add the new object to the application state
      const newObject = response.data.object as KnackObject;
      void queryClient.setQueriesData<KnackApplication>(
        { queryKey: [queryKeys.application] },
        (prevData) => {
          if (!prevData) return undefined;
          return {
            ...prevData,
            objects: [...prevData.objects, newObject]
          };
        }
      );
      if (shouldSkipRedirect) return response;

      const { user } = variables;

      const newPath = user ? ROUTES.ROLES_ID : ROUTES.TABLES_ID;
      const newBuilderPath = `${generatePath(newPath, { id: response.data.object.key ?? null })}`;

      const shouldRedirectToNewBuilder =
        (searchParams.has('origin') && searchParams.get('origin') === 'builder-next') ||
        shouldForceRedirectToV4;

      const oldBuilderPath = `${getBuilderBaseUrl()}/schema/list/objects/${response.data.object.key}`;

      if (shouldRedirectToNewBuilder) {
        navigate(newBuilderPath);
      } else {
        window.location.replace(oldBuilderPath);
      }
      return response;
    }
  });
}
