import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { type ChartGroupingCategory } from '@/types/schema/views/ReportView';
import { FieldIcon } from '@/components/FieldIcon';

type CategorySelectorProps = {
  id: string;
  availableCategories: ChartGroupingCategory[];
  defaultValue: ChartGroupingCategory['value'];
  onSelectCategory: (category: ChartGroupingCategory) => void;
};

export function CategorySelector({
  id,
  availableCategories,
  defaultValue,
  onSelectCategory
}: CategorySelectorProps) {
  const [t] = useTranslation();

  const isFieldCategory = (category: ChartGroupingCategory) =>
    category.value !== 'totals' && category.value !== 'averages';

  return (
    <>
      <Label className="mb-2 block">
        {t('pages.element_settings.report.categories.data_display.x_axis_group')}
      </Label>
      <Select
        onValueChange={(value) => {
          const selectedCategory = availableCategories.find((category) => category.value === value);
          if (selectedCategory) {
            onSelectCategory(selectedCategory);
          }
        }}
        defaultValue={defaultValue}
      >
        <Select.Trigger
          id={id}
          placeholder={t('actions.select')}
          className="w-full truncate"
          data-testid={id}
        />
        <Select.Content>
          {availableCategories?.map((category) => (
            <Select.Item
              key={category.value}
              value={category.value}
              data-testid={`category-item-${category.value}`}
            >
              <span className="flex items-center gap-1" data-testid="category-select-item">
                {isFieldCategory(category) && (
                  <FieldIcon name={category.value.split('-')[0] as KnackFieldType} size={14} />
                )}
                {category.label}
              </span>
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </>
  );
}
