import { useFieldArray, useFormContext } from 'react-hook-form';

import { type BuilderViewSourceSchemaCriteria } from '@/types/schema/BuilderView';
import { type TableView } from '@/types/schema/views/TableView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SourceFilterDialogGroupItem } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFilterDialogGroupItem';

export function SourceFilterDialogGroups({ groupFieldIndex }: { groupFieldIndex: number }) {
  const { sourceObject } = useActiveViewContext<TableView>();
  const { control, getValues, setValue } = useFormContext<BuilderViewSourceSchemaCriteria>();

  const {
    fields: groupFieldRules,
    remove,
    append,
    update
  } = useFieldArray({
    control,
    name: `groups.${groupFieldIndex}`
  });

  const handleRemoveGroupFilter = (index: number) => {
    remove(index);

    // If it is the last filter of the group, we remove the group
    if (groupFieldRules.length === 1) {
      const filteredGroups = getValues('groups').filter((group, i) => i !== groupFieldIndex);
      setValue('groups', filteredGroups);
    }
  };

  return (
    <>
      {groupFieldRules.map((ruleField, ruleFieldIndex) => (
        <SourceFilterDialogGroupItem
          key={ruleField.id}
          availableFields={sourceObject.fields}
          formFieldName={`groups.${groupFieldIndex}.${ruleFieldIndex}`}
          ruleField={ruleField}
          ruleFieldIndex={ruleFieldIndex}
          update={update}
          append={append}
          remove={handleRemoveGroupFilter}
        />
      ))}
    </>
  );
}
