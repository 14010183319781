import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  HiChevronDown as ChevronDownIcon,
  HiArrowLeftOnRectangle as InboundConnectionIcon,
  HiArrowRightOnRectangle as OutboundConnectionIcon
} from 'react-icons/hi2';
import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Chip, Collapsible, useToast } from '@knack/asterisk-react';

import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useDndUtils } from '@/hooks/useDndUtils';
import { ConnectionCardWrapper, type ConnectionType } from './ConnectionCardWrapper';

export function ConnectionCollapsible({
  open,
  onOpenChange,
  connectionList,
  setConnectionList,
  table,
  connectionType
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  connectionList: KnackConnection[];
  setConnectionList: (connectionList: KnackConnection[]) => void;
  table: KnackObject;
  connectionType: ConnectionType;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const { updateConnectionsMutation } = useTableMutation();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const draggedConnection = connectionList.find((f) => f.key === active.id);

      if (!draggedConnection) return;

      const oldIndex = connectionList.findIndex((v) => v.key === active.id);
      const newIndex = connectionList.findIndex((v) => v.key === over.id);
      const updatedTable = {
        ...table,
        connections: {
          ...table.connections,
          [connectionType]: arrayMove(connectionList, oldIndex, newIndex)
        }
      };

      setConnectionList(updatedTable.connections[connectionType]);

      updateConnectionsMutation.mutate(
        {
          object_key: table.key,
          connections: updatedTable.connections
        },
        {
          onError: () => {
            presentToast({
              title: t('components.data_table.right_sidebar.connections.update_error')
            });
          }
        }
      );
    }
  };

  const ConnectionTypeIcon: IconType =
    connectionType === 'inbound' ? InboundConnectionIcon : OutboundConnectionIcon;

  if (!connectionList.length) return null;

  return (
    <Collapsible
      open={open}
      onOpenChange={onOpenChange}
      defaultOpen
      data-testid={`${connectionType}-connections-collapsible`}
    >
      <Collapsible.Trigger
        className="group flex w-full items-center transition-all"
        data-testid="connection-collapsible-trigger"
      >
        <span className="flex items-center">
          <ConnectionTypeIcon size={16} className="mr-0.5 shrink-0" />
          <span className="mr-1 text-sm font-semibold text-emphasis">
            {t(`components.data_table.right_sidebar.connections.${connectionType}_connections`)}
          </span>
          <Chip
            className="m-0"
            size="sm"
            data-testid={`${connectionType}-connections-count`}
            text={String(connectionList.length)}
          />
        </span>
        <ChevronDownIcon
          size={16}
          className="ml-auto transition-transform duration-300 group-data-[state=open]:rotate-180"
        />
      </Collapsible.Trigger>
      <Collapsible.Content className="mt-2">
        <DndContext
          sensors={optimizedSensors}
          collisionDetection={verticalListCollisionDetection}
          onDragEnd={(e) => handleDragEnd(e)}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={connectionList.map((connection: KnackConnection) => connection.key)}
          >
            <div className="space-y-2">
              {connectionList.map((connection: KnackConnection) => (
                <ConnectionCardWrapper
                  connection={connection}
                  table={table}
                  key={connection.key}
                  connectionType={connectionType}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </Collapsible.Content>
    </Collapsible>
  );
}
