import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';

export function useSessionHelpers() {
  const { data: application } = useApplicationQuery();
  const { data: session } = useSessionQuery();

  const isSharedBuilder = () => {
    const appId = application?.id;
    const appRights = session?.user.appRights;
    if (!appId || !appRights) {
      return false;
    }

    const sharedApps = appRights.filter((appRight) => appRight.role === 'app_editor');
    return sharedApps.some((apps) => apps.app_id === appId);
  };

  return { isSharedBuilder };
}
