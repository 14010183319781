import { useMutation } from '@tanstack/react-query';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { useApplication } from '@/hooks/useApplication';
import { getDomainAsCrm } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

interface ShareFeedbackData {
  shareType: 'builder' | 'live-app' | 'knowledge-base' | 'other';
  shareFeedback: string;
}

async function shareFeedback(data: ShareFeedbackData, application: BuilderApplication) {
  const url = `/v1/applications/${application.id}/help/share-feedback`;
  const ticketData = {
    devLink: getDomainAsCrm(),
    ...data
  };

  const response = await axiosInstance.post(url, ticketData, {
    withCredentials: true
  });
  return response;
}

export function useShareFeedbackMutation() {
  const application = useApplication();

  return useMutation({
    mutationFn: (data: ShareFeedbackData) => {
      if (!application) throw new Error('Application not found');
      return shareFeedback(data, application);
    }
  });
}
