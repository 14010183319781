import { useCallback, useMemo } from 'react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import {
  type KnackConnection,
  type KnackObject,
  type KnackObjectProfileKey
} from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';

export function useObjectHelpers() {
  const { data: application } = useApplicationQuery();
  const objects = useMemo(() => application?.objects ?? [], [application]);

  // Retrieves an object from its key
  const getObjectByKey = useCallback(
    (objectKey: string) => objects.find((obj) => obj.key === objectKey),
    [objects]
  );

  // Retrieves a user role object from its profile key
  const getUserRoleObjectFromProfileKey = useCallback(
    (profileKey: KnackObjectProfileKey) =>
      objects.find((object) => object.profile_key === profileKey),
    [objects]
  );

  // Retrieves the object of the field with the given key
  const getObjectByFieldKey = useCallback(
    (fieldKey: string) =>
      objects.find((object) => object.fields.some((field) => field.key === fieldKey)),
    [objects]
  );

  // Checks if an object has at least one field that is considered to be 'numeric'
  const hasNumericField = useCallback(
    ({ fromObject, fromObjectKey }: { fromObject?: KnackObject; fromObjectKey?: string }) => {
      const object = fromObject ?? getObjectByKey(fromObjectKey ?? '');

      if (!object) {
        return false;
      }

      return object.fields.some((field) => NUMERIC_FIELD_TYPES.includes(field.type));
    },
    [getObjectByKey]
  );

  // Checks if an object has a field of type 'address'
  const hasAddressField = useCallback(
    ({ fromObject, fromObjectKey }: { fromObject?: KnackObject; fromObjectKey?: string }) => {
      const object = fromObject ?? getObjectByKey(fromObjectKey ?? '');

      if (!object) {
        return false;
      }

      return object.fields.some((field) => field.type === 'address');
    },
    [getObjectByKey]
  );

  // Checks if an object has a field of type 'address' with geocoding enabled
  const hasGeocodedAddressField = useCallback(
    ({ fromObject, fromObjectKey }: { fromObject?: KnackObject; fromObjectKey?: string }) => {
      const object = fromObject ?? getObjectByKey(fromObjectKey ?? '');

      if (!object) {
        return false;
      }

      return object.fields.some(
        (field) => field.type === 'address' && !!field.format.enable_geocoding
      );
    },
    [getObjectByKey]
  );

  // Checks if an object has at least one field of type 'date_time' (or a field that can store a date/time value)
  const hasDateTimeField = useCallback(
    ({ fromObject, fromObjectKey }: { fromObject?: KnackObject; fromObjectKey?: string }) => {
      const object = fromObject ?? getObjectByKey(fromObjectKey ?? '');

      if (!object) {
        return false;
      }

      return object.fields.some((field) => {
        if (field.type === 'date_time') {
          return true;
        }

        // Check if the field is an equation field that stores a date
        if (
          field.type === 'equation' &&
          field.format.equation_type === 'date' &&
          field.format.date_result === 'date'
        ) {
          return true;
        }

        return false;
      });
    },
    [getObjectByKey]
  );

  // Get the objects in the application that are user objects and have a 'profile_key' that refers to an actual role
  const getRoleObjects = useCallback(
    () =>
      objects.filter(
        (object) =>
          object.type === 'UserObject' && object.profile_key && object.profile_key !== 'all_users'
      ),
    [objects]
  );

  // Checks if there is at least one role object in the application with a 'profile_key' that refers to an actual role
  const hasRoleObjects = useCallback(() => {
    const roleObjects = getRoleObjects();
    return roleObjects.length > 0;
  }, [getRoleObjects]);

  // Retrieves the connections of type 'many' of an object
  // If `includeFurtherConnections` is true, it retrieves the connections of the connections as well
  const getConnectionsToManyFromObjectKey = useCallback(
    (objectKey: string, includeFurtherConnections: boolean = false) => {
      const connectionsToMany: KnackConnection[] = [];

      const object: KnackObject | undefined = getObjectByKey(objectKey);

      if (object) {
        object.connections.inbound.forEach((conn) => {
          // For incoming connections, we check the 'belongs_to' property
          if (conn.belongs_to === 'many') {
            connectionsToMany.push(conn);
          }
          // If we need to include further connections, we do so recursively
          if (includeFurtherConnections) {
            connectionsToMany.push(...getConnectionsToManyFromObjectKey(conn.object));
          }
        });
        object.connections.outbound.forEach((conn) => {
          // For incoming connections, we check the 'has' property
          if (conn.has === 'many') {
            connectionsToMany.push(conn);
          }
          // If we need to include further connections, we do so recursively
          if (includeFurtherConnections) {
            connectionsToMany.push(...getConnectionsToManyFromObjectKey(conn.object));
          }
        });
      }

      return connectionsToMany;
    },
    [getObjectByKey]
  );

  // Retrieves the connections of type 'many' of the user objects associated to a page
  // If `includeFurtherConnections` is true, it retrieves the connections of the connections as well
  // If `includeAccountsObject` is true, it includes the main `account` object (role 'all_users')
  const getUserConnectionsToManyFromPage = useCallback(
    (
      page: BuilderPage,
      includeAccountsObject: boolean = false,
      includeFurtherConnections: boolean = false
    ) => {
      const userConnectionsToMany: KnackConnection[] = [];

      // If the page is not behind a login, there are no user objects associated to the page
      if (!page.requiresAuthentication) {
        return userConnectionsToMany;
      }

      // If the page's access is restricted to specific profiles, we need to get the connections associated to those profile objects
      if (page.allowedProfileKeys) {
        const allUserRoleObjects: KnackObject[] = [];

        page.allowedProfileKeys.forEach((profileKey) => {
          const roleObject = getUserRoleObjectFromProfileKey(profileKey);

          if (!roleObject) {
            throw new Error(
              `Invalid profile '${profileKey}' found on page '${page.key}'. No matching object.`
            );
          }

          allUserRoleObjects.push(roleObject);
        });

        // Add the accounts object ('all_users') if needed
        if (includeAccountsObject) {
          const accountsObject = getUserRoleObjectFromProfileKey('all_users');
          if (accountsObject) {
            allUserRoleObjects.push(accountsObject);
          }
        }

        allUserRoleObjects.forEach((userRoleObject) => {
          userConnectionsToMany.push(
            ...getConnectionsToManyFromObjectKey(userRoleObject.key, includeFurtherConnections)
          );
        });
      }

      return userConnectionsToMany;
    },
    [getUserRoleObjectFromProfileKey, getConnectionsToManyFromObjectKey]
  );

  function getFormattedConnectedObjects(sourceObject: KnackObject) {
    if (!application) {
      return [];
    }

    const { connections } = sourceObject;

    // Combine inbound and outbound connections into a single array and map directly to the objects
    const allConnectedObjects = [...connections.inbound, ...connections.outbound].reduce<
      { connectionFieldKey: KnackFieldKey; object: KnackObject; connectionName?: string }[]
    >((acc, connection) => {
      const connectedSourceObject = application.objects.find(
        (object) => object.key === connection.object
      );

      if (!connectedSourceObject) {
        return acc;
      }

      const isInbound = connectedSourceObject.fields.some((f) => f.key === connection.key);

      const connectedObject = {
        connectionFieldKey: connection.key,
        object: connectedSourceObject
      };

      if (connectedObject.object !== undefined) {
        acc.push({
          connectionFieldKey: connection.key,
          object: connectedObject.object,
          ...(isInbound && { connectionName: connection.name })
        });
      }

      return acc;
    }, []);

    return allConnectedObjects;
  }

  return {
    hasNumericField,
    hasDateTimeField,
    hasAddressField,
    hasGeocodedAddressField,
    hasRoleObjects,
    getRoleObjects,
    getObjectByKey,
    getUserRoleObjectFromProfileKey,
    getObjectByFieldKey,
    getConnectionsToManyFromObjectKey,
    getUserConnectionsToManyFromPage,
    getFormattedConnectedObjects
  };
}
