import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronLeft as ChevronLeftIcon,
  HiChevronRight as ChevronRightIcon
} from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function FocusChanges() {
  const [t] = useTranslation();
  const draftRows = useDataTableStore().use.draftRows();
  const dataOrder = useDataTableStore().use.dataOrder();
  const selectedCell = useDataTableStore().use.selectedCell();
  const { setSelectedCell } = useDataTableStore().use.actions();
  const fields = useDataTableStore().use.fields();

  const [currentFocusedChange, setCurrentFocusedChange] = useState<number>();
  const totalChanges = Object.keys(draftRows).length;

  const changesListByField = useMemo(
    () =>
      Object.keys(draftRows)
        .reduce(
          (acc, rowId2) => {
            const rowErrors = draftRows[rowId2];
            if (!rowErrors) return acc;

            Object.keys(rowErrors).forEach((element) => {
              acc.push({ rowId: rowId2, fieldId: element });
            });
            return acc;
          },
          [] as { rowId: string; fieldId: string }[]
        )
        .sort(
          (a, b) =>
            fields.findIndex(({ key }) => key === a.fieldId) -
            fields.findIndex(({ key }) => key === b.fieldId)
        )
        .sort((a, b) => dataOrder.indexOf(a.rowId) - dataOrder.indexOf(b.rowId)),
    [dataOrder, draftRows, fields]
  );

  useEffect(() => {
    if (selectedCell) {
      const draftRowsListOrderedIndex = changesListByField.findIndex(
        (element) =>
          element.rowId === selectedCell.rowId && element.fieldId === selectedCell.fieldId
      );

      setCurrentFocusedChange(
        draftRowsListOrderedIndex !== -1 ? draftRowsListOrderedIndex + 1 : undefined
      );
    } else {
      setCurrentFocusedChange(undefined);
    }
  }, [dataOrder, draftRows, changesListByField, selectedCell]);

  const focusChange = (changeIndex: number) => {
    setCurrentFocusedChange(changeIndex);

    setSelectedCell(
      changesListByField[changeIndex - 1].rowId,
      changesListByField[changeIndex - 1].fieldId
    );
  };

  if (changesListByField.length === 0) return null;

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center gap-1">
        <Button
          intent="minimal"
          disabled={!currentFocusedChange || currentFocusedChange === 1}
          className="h-fit p-1"
          onClick={() => {
            if (!currentFocusedChange) {
              focusChange(totalChanges);
              return;
            }
            focusChange(currentFocusedChange - 1);
          }}
        >
          <ChevronLeftIcon size={16} />
        </Button>
        <div className="mt-0.5 flex flex-col items-center">
          <p>
            {t('components.data_table.footer.unsaved_row', {
              count: changesListByField.length
            })}
          </p>
        </div>
        <Button
          intent="minimal"
          disabled={currentFocusedChange === changesListByField.length}
          className="h-fit p-1"
          onClick={(e) => {
            e.preventDefault();
            if (!currentFocusedChange) {
              focusChange(1);
              return;
            }

            focusChange(currentFocusedChange + 1);
          }}
        >
          <ChevronRightIcon size={16} />
        </Button>
      </div>

      {currentFocusedChange && (
        <p className="h-4 text-xs text-subtle">
          {t('components.data_table.footer.x_of_total', {
            current: currentFocusedChange,
            total: changesListByField.length
          })}
        </p>
      )}
    </div>
  );
}
