import { cn } from '@/utils/tailwind';
import { EmergencyRequestForm } from '@/components/help-menu/EmergencyRequestForm';
import { GetHelpForm } from '@/components/help-menu/GetHelpForm';
import { ReportBugForm } from '@/components/help-menu/ReportBugForm';
import { ShareFeedbackForm } from '@/components/help-menu/ShareFeedbackForm';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { SUPPORT_FORM_PANEL } from './types';

export function SupportFormsPanel() {
  const { activeSupportFormId } = useHelpPanelStore((state) => ({
    activeSupportFormId: state.activeSupportFormId
  }));
  const panelClasses =
    'absolute w-full translate-x-[1000px] space-y-4 py-4 opacity-0 transition-all duration-500 mt-8';

  return (
    <div
      className={cn(panelClasses, {
        'translate-x-0 opacity-100': activeSupportFormId
      })}
    >
      {activeSupportFormId === SUPPORT_FORM_PANEL.getHelp && <GetHelpForm />}
      {activeSupportFormId === SUPPORT_FORM_PANEL.feedback && <ShareFeedbackForm />}
      {activeSupportFormId === SUPPORT_FORM_PANEL.reportBug && <ReportBugForm />}
      {activeSupportFormId === SUPPORT_FORM_PANEL.emergency && <EmergencyRequestForm />}
    </div>
  );
}
