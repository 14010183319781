import { t } from 'i18next';
import capitalize from 'lodash.capitalize';

export function formatAddressPlaceholder(placeholder?: string, format?: string) {
  if (!placeholder) return '';

  if (placeholder === 'state' && format?.includes('international')) {
    return t(
      'components.data_table.attributes.field_labels.address.state_international_placeholder'
    );
  }

  if (placeholder === 'zip' && format?.includes('international')) {
    return t('components.data_table.attributes.field_labels.address.zip_international_placeholder');
  }

  if (placeholder === 'street2') {
    return t('components.data_table.attributes.field_labels.address.labels.street2');
  }

  if (placeholder === 'street') {
    return t('components.data_table.attributes.field_labels.address.labels.street');
  }

  return capitalize(placeholder);
}
