import { useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMask, useIMask } from 'react-imask';
import { Input, Label, Switch } from '@knack/asterisk-react';

import { PHONE_EXTENSION_MASK, PHONE_FORMAT_TO_MASK } from '@/utils/constants';

export function DefaultValue({ defaultValue }: { defaultValue: string }) {
  const [t] = useTranslation();
  const firstOnAcceptCall = useRef(true);

  const [showEditDefaultValue, setShowEditDefaultValue] = useState(!!defaultValue || false);

  const { setValue, getValues } = useFormContext();

  const currentFormat = getValues('format.format');
  const hasExtension = getValues('format.extension');

  const mask =
    currentFormat === 'any'
      ? Number
      : `${PHONE_FORMAT_TO_MASK[currentFormat]}${hasExtension ? PHONE_EXTENSION_MASK : ''}`;

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      const phoneMask = IMask.createPipe({
        mask: mask as any
      });
      return phoneMask(currentValue);
    },
    [mask]
  );

  const { ref: inputRef } = useIMask<HTMLInputElement>(
    {
      mask: mask as any,
      lazy: currentFormat === 'any',
      placeholderChar: '_'
    },
    {
      defaultValue: (defaultValue as string) || '',
      onAccept: (val) => {
        if (firstOnAcceptCall.current) {
          firstOnAcceptCall.current = false;
          return;
        }

        setValue('default', getCurrentValueWithoutPlaceholder(val));
      }
    }
  );

  return (
    <div className="flex">
      <Switch
        className="mr-2"
        data-testid="phone-default-value-switch"
        checked={showEditDefaultValue}
        onCheckedChange={() => {
          if (showEditDefaultValue) {
            setValue('default', '');
          }
          setShowEditDefaultValue(!showEditDefaultValue);
        }}
      />

      <div className="flex flex-grow flex-col">
        <Label htmlFor="field-settings-default-value-input">
          {t('components.data_table.attributes.field_settings.common.add_default_value')}
        </Label>
        {showEditDefaultValue && (
          <Input
            placeholder={PHONE_FORMAT_TO_MASK[currentFormat]}
            id="phone-field-settings-default-value-input"
            className="mt-2 w-full rounded-lg"
            data-testid="default-value-input"
            ref={inputRef}
          />
        )}
      </div>
    </div>
  );
}
