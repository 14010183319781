import { useMemo } from 'react';
import { Controller, useFormContext, type FieldArrayWithId } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as RemoveIcon } from 'react-icons/hi2';
import { useSortable } from '@dnd-kit/sortable';
import { Button, Label, Select } from '@knack/asterisk-react';

import { type ListView, type ListViewInput } from '@/types/schema/views/ListView';
import { cn } from '@/utils/tailwind';
import { DragButton } from '@/components/dnd/DragButton';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getViewSettingsSortingOptions } from '@/pages/pages/settings-panel/view-settings/common/sorting/helper';

type SortingFieldWithId = FieldArrayWithId<
  {
    sort: ListView['source']['sort'];
  },
  'sort',
  'id'
>;

interface SortingDialogItemProps {
  sortingFields: SortingFieldWithId[];
  sortingField: SortingFieldWithId;
  sortingIndex: number;
  removeSorting: (index: number) => void;
}

export function SortingDialogItem({
  sortingFields,
  sortingField,
  sortingIndex,
  removeSorting
}: SortingDialogItemProps) {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ListView>();
  const { formState, watch } = useFormContext();

  const { isDragging, isSorting } = useSortable({
    id: sortingField.id
  });

  const fieldValue = watch(`sort.${sortingIndex}.field`);
  const fieldType = sourceObject.fields.find((field) => field.key === fieldValue)?.type;

  const activeFields = useMemo(
    () =>
      view.columns.reduce<ListViewInput[]>((acc, outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              if (input.type === 'field') {
                acc.push(input);
              }
            });
          });
        });
        return acc;
      }, []),
    [view.columns]
  );

  return (
    <div
      className={cn(
        'group relative mb-2 flex w-full items-start gap-2 rounded-lg bg-subtle px-4 py-2 text-sm',
        {
          'after:absolute after:inset-0 after:rounded-lg after:bg-muted/80': isDragging,
          'pointer-events-none': isSorting
        }
      )}
    >
      <DragButton sortableId={sortingField.id} />

      <div className="w-full">
        <Label htmlFor={`${sortingField.field}-${sortingField.id}-field`}>
          {t('keywords.field')}
        </Label>
        <Controller
          name={`sort.${sortingIndex}.field`}
          render={({ field }) => (
            <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
              <Select.Trigger
                placeholder={t(
                  'pages.element_settings.common.categories.data_display.general_settings.select_field'
                )}
                className="mt-1 w-full"
                id={`${sortingField.field}-${sortingField.id}-field`}
              />
              <Select.Content>
                {activeFields.map((activeField) => {
                  if (!activeField?.key) return null;

                  return (
                    <Select.Item key={activeField.key} value={activeField.key}>
                      {activeField.name}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select>
          )}
        />
        {formState.errors.sort?.[sortingIndex]?.field && (
          <span className="mt-1 text-xs text-destructive">
            {formState.errors.sort[sortingIndex].field.message}
          </span>
        )}
      </div>
      <div className="w-full space-y-1">
        <Label htmlFor={`${sortingField.field}-${sortingField.id}-order`}>
          {t(
            'pages.element_settings.common.categories.data_display.general_settings.sort_direction'
          )}
        </Label>
        <div className="flex w-full items-center gap-2 space-y-1">
          <Controller
            name={`sort.${sortingIndex}.order`}
            render={({ field, fieldState }) => (
              <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                <Select.Trigger
                  id={`${sortingField.field}-${sortingField.id}-order`}
                  className={cn('w-full', {
                    'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                      fieldState.error
                  })}
                  placeholder={`${t('actions.select')}...`}
                />
                <Select.Content>
                  {getViewSettingsSortingOptions(fieldType).map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      {option.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            )}
          />
          {sortingFields.length > 1 && (
            <Button intent="minimal" size="xs" onClick={() => removeSorting(sortingIndex)}>
              <RemoveIcon size={16} />
            </Button>
          )}
        </div>
        {formState.errors.sort?.[sortingIndex]?.order && (
          <span className="mt-1 text-xs text-destructive">
            {formState.errors.sort[sortingIndex].order.message}
          </span>
        )}
      </div>
    </div>
  );
}
