import { type AppTheme } from '@knack/asterisk-react';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

/**
 * Check if the structure of the imported theme is valid by comparing it with the default theme.
 */
export function isThemeStructureValid(importedTheme: AppTheme, defaultAppTheme: AppTheme) {
  if (
    typeof importedTheme !== 'object' ||
    importedTheme === null ||
    typeof defaultAppTheme !== 'object' ||
    defaultAppTheme === null
  ) {
    return false;
  }

  const keys1 = Object.keys(importedTheme);
  const keys2 = Object.keys(defaultAppTheme);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // Helper function to get the structure and types of the object
  function getStructureAndTypes(obj) {
    return mapValues(obj, (value) => {
      if (isArray(value)) {
        return value.map((item) => typeof item);
      }
      if (isObject(value)) {
        return getStructureAndTypes(value); // Recursively get structure for nested objects
      }
      return typeof value; // Return type for primitive values
    });
  }

  // Get the structure and types of both objects
  const structure1 = getStructureAndTypes(importedTheme);
  const structure2 = getStructureAndTypes(defaultAppTheme);

  // Use lodash's isEqual to compare the structures
  return isEqual(structure1, structure2);
}
