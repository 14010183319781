/**
 * Replaces all variables in a string with the corresponding values.
 * Variables are denoted by curly braces, e.g. {variable}.
 */
export function replaceTemplateVariables({
  text,
  variableList,
  valueList
}: {
  text: string;
  variableList: string[];
  valueList: string[];
}) {
  let newText = text;

  variableList.forEach((stringToReplace, index) => {
    const replacement = valueList[index];
    newText = newText.replace(new RegExp(`{${stringToReplace}}`, 'g'), `${replacement}`);
  });

  return newText;
}
