import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon, HiChevronUp as ChevronUpIcon } from 'react-icons/hi2';
import { PiPlugs as ConnectionIcon } from 'react-icons/pi';
import { Collapsible } from '@knack/asterisk-react';

import { type ConnectionOption } from '@/types/schema/fields/ConnectionField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { capitalize } from '@/utils/formatters';
import { InlineKnackTable } from '@/components/InlineKnackTable';
import { KnackTableIcon } from '@/components/KnackTableIcon';
import { SidePanel } from '@/components/SidePanel';

interface TableSidePanelContentProps {
  table: KnackObject;
  allTables: KnackObject[];
}

type TableIncomingConnection = {
  sourceHas: string;
  sourceBelongsTo: ConnectionOption;
  sourceTableType: KnackObject['type'];
  sourceTableName: {
    singular: string;
    plural: string;
  };

  // needed for generating a unique key and testid for the DOM element when looping through the array
  sourceTableKey: string;
  sourceFieldKey: string;
};

type TableOutgoingConnection = {
  targetHas: string;
  targetBelongsTo: ConnectionOption;
  targetTableType: KnackObject['type'];
  targetTableName: {
    singular: string;
    plural: string;
  };

  // needed for generating a unique key and testid for the DOM element when looping through the array
  targetTableKey: string;
  sourceFieldKey: string;
};

export function TableSidePanelContent({ table, allTables }: TableSidePanelContentProps) {
  const [t] = useTranslation();

  const incomingConnections = useMemo(
    () =>
      table.connections.inbound.reduce((acc: TableIncomingConnection[], connection) => {
        const connectionSourceTable = allTables.find((obj) => obj.key === connection.object);
        if (!connectionSourceTable) {
          return acc;
        }

        acc.push({
          sourceHas: connection.has,
          sourceBelongsTo: connection.belongs_to,
          sourceTableType: connectionSourceTable.type,
          sourceTableName: {
            singular: connectionSourceTable.inflections.singular,
            plural: connectionSourceTable.inflections.plural
          },
          sourceTableKey: connectionSourceTable.key,
          sourceFieldKey: connection.key
        });

        return acc;
      }, []),
    [allTables, table.connections.inbound]
  );

  const outgoingConnections = useMemo(
    () =>
      table.connections.outbound.reduce((acc: TableOutgoingConnection[], connection) => {
        const connectionTargetTable = allTables.find((obj) => obj.key === connection.object);
        if (!connectionTargetTable) {
          return acc;
        }

        acc.push({
          targetHas: connection.has,
          targetBelongsTo: connection.belongs_to,
          targetTableType: connectionTargetTable.type,
          targetTableName: {
            singular: connectionTargetTable.inflections.singular,
            plural: connectionTargetTable.inflections.plural
          },
          targetTableKey: connectionTargetTable.key,
          sourceFieldKey: connection.key
        });

        return acc;
      }, []),
    [allTables, table.connections.outbound]
  );

  return (
    <>
      <SidePanel.Header>
        <SidePanel.Title
          className="flex items-center text-xl"
          data-testid="data-model-sidepanel-table-name"
        >
          <KnackTableIcon tableType={table.type} size={26} className="mr-2 text-emphasis" />{' '}
          {table.name}
        </SidePanel.Title>
      </SidePanel.Header>
      <Collapsible className="mt-12" defaultOpen>
        <Collapsible.Trigger className="group flex w-full items-center rounded-sm text-subtle focus:outline-none focus:ring-2">
          <>
            <ConnectionIcon size={20} className="mr-2" />
            <span>
              <span className="font-semibold">
                {t('components.data_model.incoming_connections')}
              </span>{' '}
              <span className="text-sm" data-testid="incoming-connections-count">
                ({incomingConnections.length})
              </span>
            </span>
          </>
          <ChevronDownIcon size={14} className="ml-auto group-data-[state=open]:hidden" />
          <ChevronUpIcon size={14} className="ml-auto group-data-[state=closed]:hidden" />
        </Collapsible.Trigger>

        <Collapsible.Content className="mt-2">
          {incomingConnections.length > 0 ? (
            incomingConnections.map((connection) => {
              const sourceNameWithInflection =
                connection.sourceBelongsTo === 'one'
                  ? connection.sourceTableName.singular
                  : connection.sourceTableName.plural;
              const targetNameWithInflection =
                connection.sourceHas === 'one'
                  ? table.inflections.singular
                  : table.inflections.plural;

              return (
                <div
                  key={`${connection.sourceTableKey}-${connection.sourceFieldKey}-${table.key}`}
                  data-testid={`incoming-connection-${connection.sourceTableKey}-${connection.sourceFieldKey}-${table.key}`}
                  className="rounded-md border border-subtle px-2 py-1.5 shadow-sm [&:not(:last-child)]:mb-2"
                >
                  {capitalize(
                    connection.sourceBelongsTo === 'one' ? t('keywords.one') : t('keywords.many')
                  )}{' '}
                  <InlineKnackTable
                    tableName={sourceNameWithInflection}
                    tableType={connection.sourceTableType}
                  />{' '}
                  {t('keywords.to')}{' '}
                  {connection.sourceHas === 'one' ? t('keywords.one') : t('keywords.many')}{' '}
                  <InlineKnackTable tableName={targetNameWithInflection} tableType={table.type} />
                </div>
              );
            })
          ) : (
            <span>{t('components.data_model.incoming_connections_empty')}</span>
          )}
        </Collapsible.Content>
      </Collapsible>
      <Collapsible className="mt-8" defaultOpen>
        <Collapsible.Trigger className="group flex w-full items-center rounded-sm text-subtle focus:outline-none focus:ring-2">
          <>
            <ConnectionIcon size={20} className="mr-2" />
            <span>
              <span className="font-semibold">
                {t('components.data_model.outgoing_connections')}
              </span>{' '}
              <span className="text-sm" data-testid="outgoing-connections-count">
                ({outgoingConnections.length})
              </span>
            </span>
          </>
          <ChevronDownIcon size={14} className="ml-auto group-data-[state=open]:hidden" />
          <ChevronUpIcon size={14} className="ml-auto group-data-[state=closed]:hidden" />
        </Collapsible.Trigger>

        <Collapsible.Content className="mt-2">
          {outgoingConnections.length > 0 ? (
            outgoingConnections.map((connection) => {
              const sourceNameWithInflection =
                connection.targetBelongsTo === 'one'
                  ? table.inflections.singular
                  : table.inflections.plural;
              const targetNameWithInflection =
                connection.targetHas === 'one'
                  ? connection.targetTableName.singular
                  : connection.targetTableName.plural;

              return (
                <div
                  key={`${table.key}-${connection.sourceFieldKey}-${connection.targetTableKey}`}
                  data-testid={`outgoing-connection-${table.key}-${connection.sourceFieldKey}-${connection.targetTableKey}`}
                  className="rounded-md border border-subtle px-2 py-1.5 shadow-sm [&:not(:last-child)]:mb-2"
                >
                  {capitalize(
                    connection.targetBelongsTo === 'one' ? t('keywords.one') : t('keywords.many')
                  )}{' '}
                  <InlineKnackTable tableName={sourceNameWithInflection} tableType={table.type} />{' '}
                  {t('keywords.to')}{' '}
                  {connection.targetHas === 'one' ? t('keywords.one') : t('keywords.many')}{' '}
                  <InlineKnackTable
                    tableName={targetNameWithInflection}
                    tableType={connection.targetTableType}
                  />
                </div>
              );
            })
          ) : (
            <span>{t('components.data_model.outgoing_connections_empty')}</span>
          )}
        </Collapsible.Content>
      </Collapsible>
    </>
  );
}
