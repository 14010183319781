import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { ApiWrapper } from '@/utils/api-wrapper';
import { getApplicationBasePathSegments } from '@/utils/application';

interface AssetInfoQueryParams {
  assetId: string;
  enabled?: boolean;
}

async function getAssetInfo(assetId: string) {
  const { asset } = await ApiWrapper.getAssetInfo(assetId);
  return asset;
}

export function useAssetInfoQuery({ assetId, enabled = true }: AssetInfoQueryParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.assetInfo, accountSlug, appSlug, assetId],
    queryFn: () => getAssetInfo(assetId),
    staleTime: 1000 * 60,
    retry: false,
    enabled
  });
}
