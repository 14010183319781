import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type AggregateField } from '@/components/field-settings/aggregate/AggregateFormSettings';

type CustomFormatInputsProps = {
  field: AggregateField;
};

export function CustomFormatInputs({ field }: CustomFormatInputsProps) {
  const [t] = useTranslation();

  const { register } = useFormContext();

  return (
    <div className="flex flex-row gap-2">
      <div className="flex flex-col gap-2">
        <Label htmlFor="format-pre-input" className="font-medium">
          {t('components.data_table.attributes.field_settings.number.before')}
        </Label>
        <Input
          id="format-pre-input"
          type="text"
          className="w-full rounded-lg"
          data-testid="number-form-settings-before-input"
          defaultValue={field?.format?.pre}
          {...register('format.pre')}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="format-after-input" className="font-medium">
          {t('components.data_table.attributes.field_settings.number.after')}
        </Label>
        <Input
          id="format-after-input"
          type="text"
          className="w-full rounded-lg"
          data-testid="number-form-settings-after-input"
          defaultValue={field?.format?.post}
          {...register('format.post')}
        />
      </div>
    </div>
  );
}
