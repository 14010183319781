import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { FieldSelect } from '@/components/FieldSelect';
import { getDefaultValue } from '@/components/import/ConfirmImport';
import { getCompatibleFields } from '@/pages/tables/toolkit-sidebar/rules/utils';

export function ConditionalRuleFormTargetFieldKey({ table }: { table: KnackObject }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  return (
    <Form.Section>
      <Form.Label htmlFor="conditional-rule-field-select">
        {t('components.data_table.right_sidebar.conditional_rules.select_field_label')}
      </Form.Label>
      <Controller
        name="targetFieldKey"
        render={({ field }) => (
          <FieldSelect
            id="conditional-rule-field-select"
            data-testid="conditional-rule-field-select"
            fields={getCompatibleFields(table.fields)}
            onFieldChange={(selectedFieldKey) => {
              const knackField = table.fields.find((f) => f.key === selectedFieldKey);
              if (knackField) {
                setValue('action.value', getDefaultValue(knackField));
                setValue('action.type', 'value');
                field.onChange(selectedFieldKey);
              }
            }}
            {...field}
          />
        )}
      />
    </Form.Section>
  );
}
