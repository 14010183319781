import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { type LinkField } from '@/types/schema/fields';

interface LinkInputProps {
  targetField: LinkField;
  name: string;
  id: string;
}

export function LinkInput(props: LinkInputProps) {
  const { name, targetField, id } = props;
  const { register } = useFormContext();
  const [t] = useTranslation();
  const showLabelInput = targetField.format?.text_format === 'unique';
  return (
    <div className="space-y-2">
      {showLabelInput && (
        <Input
          placeholder={t('components.add_into_existing_table.link_text')}
          {...register(`${name}.label`)}
        />
      )}
      <Input
        id={id}
        placeholder={t('components.add_into_existing_table.link_example')}
        {...register(`${name}.url`)}
      />
    </div>
  );
}
