import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { assertTruthiness } from '@/utils/assert';
import { MainLayout } from '@/components/layout/MainLayout';
import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { SettingsPageContextProvider } from './SettingsPageContext';
import { SettingsSectionTree } from './SettingsSectionTree';
import { SettingsTopBar } from './SettingsTopBar';

export function SettingsPage() {
  const [t] = useTranslation();

  const { data: application, isPending } = useApplicationQuery();

  if (isPending) {
    return null;
  }

  if (!application) {
    assertTruthiness(application, 'Application is missing');
  }

  return (
    <MainLayout>
      <SettingsPageContextProvider initialApplication={application}>
        <ResizableCollapsiblePanel
          title={t('components.layout.left_sidebar.settings')}
          panelContent={<SettingsSectionTree />}
          mainContent={
            <>
              <SettingsTopBar />
              <Outlet />
            </>
          }
          autoSaveId="app-settings-panel"
        />
      </SettingsPageContextProvider>
    </MainLayout>
  );
}
