import { createContext, useContext, useMemo } from 'react';

import {
  type BuilderView,
  type BuilderViewKey,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

type ActiveViewContextState<T extends BuilderView> = {
  view: T;
  viewInitialState: T | null;

  // If the view has a `source` property, the `sourceObject` will be of type KnackObject. Otherwise, it will be null
  sourceObject: T extends { source: BuilderViewSourceSchema } ? KnackObject : null;
};

type ActiveViewContextProviderProps = {
  viewKey: BuilderViewKey;
  children: React.ReactNode;
};

const ActiveViewContext = createContext<ActiveViewContextState<BuilderView> | null>(null);

export function ActiveViewContextProvider({ viewKey, children }: ActiveViewContextProviderProps) {
  const { data: application } = useApplicationQuery();
  const { page, pageInitialState } = usePageEditorContext();

  // Get the view from the page editor's active page, so we always have the most up-to-date version of the view
  const view = useMemo(() => page.views.find((v) => v.key === viewKey), [page.views, viewKey]);

  // Get the initial state of the view from the page's initial state. If the view is not found in the page's initial state it means it's a new view, so we set it to null
  const viewInitialState = useMemo(
    () => pageInitialState.views.find((v) => v.key === viewKey) || null,
    [pageInitialState.views, viewKey]
  );

  if (!view) {
    throw new Error(`View with key '${viewKey}' not found`);
  }

  const sourceObject = useMemo(() => {
    // Check if the view has a 'source' property
    if ('source' in view) {
      return application?.objects.find((object) => object.key === view.source.object) || null;
    }
    return null;
  }, [application?.objects, view]);

  const contextValue = useMemo(
    () => ({ view, viewInitialState, sourceObject }),
    [view, viewInitialState, sourceObject]
  );

  return <ActiveViewContext.Provider value={contextValue}>{children}</ActiveViewContext.Provider>;
}

export const useActiveViewContext = <T extends BuilderView>() => {
  const context = useContext(ActiveViewContext);

  if (!context) {
    throw new Error('useActiveViewContext must be used within an ActiveViewContextProvider');
  }

  return context as ActiveViewContextState<T>;
};
