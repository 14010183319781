import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Tooltip } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { FieldIcon } from '@/components/FieldIcon';

type FieldSelectProps = {
  id: string;
  fields: KnackField[] | undefined;
  defaultValue?: string;
  onFieldChange: (value: string) => void;
  disabledFields?: KnackFieldType[];
};

export const FieldSelect = forwardRef<HTMLButtonElement, FieldSelectProps>(
  ({ id, fields, defaultValue, onFieldChange, disabledFields, ...props }, forwardedRef) => {
    const [t] = useTranslation();

    return (
      <Select onValueChange={onFieldChange} defaultValue={defaultValue}>
        <Select.Trigger
          id={id}
          placeholder={t('actions.select')}
          className="w-full truncate rounded-lg"
          data-testid="field-select"
          ref={forwardedRef}
          {...props}
        />
        <Select.Content>
          {fields?.map((field) => {
            const isDisabled = !!disabledFields?.find((f) => f === field.type);
            return isDisabled ? (
              <Tooltip key={field.key}>
                <Tooltip.Trigger asChild>
                  <Select.Item value={field.key} disabled={isDisabled}>
                    <span className="flex items-center gap-2" data-testid="field-select-item">
                      <FieldIcon name={field.type} size={12} className="text-emphasis" />
                      {field.name}
                    </span>
                  </Select.Item>
                </Tooltip.Trigger>
                <Tooltip.Content>{t('keywords.coming_soon')}</Tooltip.Content>
              </Tooltip>
            ) : (
              <Select.Item
                key={field.key}
                value={field.key}
                disabled={isDisabled}
                data-testid={`field-item-${field.key}`}
              >
                <span className="flex items-center gap-2" data-testid="field-select-item">
                  <FieldIcon name={field.type} size={12} className="text-emphasis" />
                  {field.name}
                </span>
              </Select.Item>
            );
          })}
        </Select.Content>
      </Select>
    );
  }
);

FieldSelect.displayName = 'FieldSelect';
