import { DropdownMenu, Label } from '@knack/asterisk-react';

import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { FieldIcon } from '@/components/FieldIcon';
import { type Column } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';
import { getDefaultSubFieldParts, isReadOnlyField } from '@/components/import/utils';

export function ExistingFields({
  searchKeyword = '',
  column
}: {
  searchKeyword?: string;
  column: Column;
}) {
  const { existingTable, columns, setColumns, getColumnVisibility } = useImportStore((state) => ({
    existingTable: state.existingTable,
    columns: state.columns,
    setColumns: state.setColumns,
    getColumnVisibility: state.getColumnVisibility
  }));

  const updateColumns = (existingField: KnackField) => {
    if (existingField.key === column.meta?.existingKnackField?.key) return;
    const isTheExistingFieldTheSameAsTheNewField = existingField.type === column.meta.newFieldType;

    const { thisColumnHasActiveSubfields, mappedSubFieldsInThisColumn } = column.meta.parts.reduce<{
      thisColumnHasActiveSubfields: boolean;
      mappedSubFieldsInThisColumn: number[];
    }>(
      (acc, part) => {
        if (part.mapped && part.mappedColumnIndex) {
          acc.thisColumnHasActiveSubfields = true;
          acc.mappedSubFieldsInThisColumn.push(part.mappedColumnIndex);
        }
        return acc;
      },
      { thisColumnHasActiveSubfields: false, mappedSubFieldsInThisColumn: [] }
    );

    const { mappedColumns, isThisColumnMappedByAnotherColumn } = columns.reduce<{
      mappedColumns: Column[];
      isThisColumnMappedByAnotherColumn: boolean;
    }>(
      (acc, col: Column) => {
        if (col.meta?.isThisColumnMapped) {
          acc.mappedColumns.push(col);
          acc.isThisColumnMappedByAnotherColumn = col.meta.mappedColumnIndex === column.accessorKey;
        }
        return acc;
      },
      { mappedColumns: [], isThisColumnMappedByAnotherColumn: false }
    );

    const updatedColumn: Column = {
      ...column,
      meta: {
        ...column.meta,
        newFieldType: existingField.type,
        existingKnackField: existingField,
        // If the existing field type is the same as the new field type, we don't want to reset the subfield mapping.
        parts:
          thisColumnHasActiveSubfields && isTheExistingFieldTheSameAsTheNewField
            ? column.meta.parts
            : getDefaultSubFieldParts(existingField.type)
      }
    };

    const updatedColumns = columns.map((col) =>
      col.header === updatedColumn.header ? updatedColumn : col
    );

    // We need to reset the mapping of all columns that are mapped as subfields of this column, if the new field type is different from the existing field type.
    if (isThisColumnMappedByAnotherColumn && !isTheExistingFieldTheSameAsTheNewField) {
      mappedColumns.forEach((mappedColumn) => {
        // We only want to reset the mapping of the subfields of this column.
        if (mappedSubFieldsInThisColumn.includes(Number(mappedColumn.accessorKey))) {
          const isMappedToTheOriginColumn =
            mappedColumn.accessorKey === mappedColumn.meta.mappedColumnIndex;

          const updatedMappedColumn = {
            ...mappedColumn,
            meta: {
              ...mappedColumn.meta,
              isThisColumnMapped: false,
              mappedColumnIndex: undefined,
              existingKnackField: isMappedToTheOriginColumn
                ? existingField
                : mappedColumn.meta.existingKnackField
            }
          };
          updatedColumns[mappedColumn.accessorKey] = updatedMappedColumn;
        }
      });
    }

    setColumns(updatedColumns);
  };

  const columnLookup = {};
  columns.forEach((col) => {
    if (!col.meta?.existingKnackField) return;
    const { _id: existingKnackFieldId } = col.meta.existingKnackField;
    columnLookup[existingKnackFieldId] = col;
  });

  return existingTable?.fields.map((field) => {
    const { _id: fieldId, key, name, type } = field;
    if (isReadOnlyField(type)) return null;

    const mappedField = columnLookup[fieldId];

    const isMappedFieldVisible = mappedField && getColumnVisibility(mappedField);

    if (searchKeyword && !name.toLowerCase().includes(searchKeyword.toLowerCase())) {
      return null;
    }

    return (
      <DropdownMenu.Item
        key={key}
        onSelect={() => updateColumns(field)}
        disabled={!!mappedField && isMappedFieldVisible}
      >
        <div className="flex items-center">
          <FieldIcon name={type as KnackFieldType} className="mr-2" />
          <Label>{name}</Label>
        </div>
      </DropdownMenu.Item>
    );
  });
}
