import { create } from 'zustand';

type LastVisitedState = {
  lastVisitedTableKey: string | null;
  lastVisitedRoleKey: string | null;
  setLastVisitedTableKey: (tableKey: string) => void;
  setLastVisitedRoleKey: (roleKey: string) => void;
};

const initialState = {
  lastVisitedTableKey: null,
  lastVisitedRoleKey: null
};

export const useLastVisitedStore = create<LastVisitedState>()((set) => ({
  ...initialState,

  setLastVisitedTableKey: (tableKey: string) => {
    set({ lastVisitedTableKey: tableKey });
  },
  setLastVisitedRoleKey: (roleKey: string) => {
    set({ lastVisitedRoleKey: roleKey });
  }
}));
