import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type ChangeUsersStatusParams = {
  tableKey: string;
  ids: string[];
  accountStatus: string;
  sendEmailApproval: boolean;
};

async function ChangeUsersStatus({
  ids,
  tableKey,
  accountStatus,
  sendEmailApproval
}: ChangeUsersStatusParams) {
  const endpoint = `/v1/objects/${tableKey}/records/users/status`;

  const { data } = await axios.post(
    endpoint,
    { ids, account_status: accountStatus, send_email_approval: sendEmailApproval },
    { withCredentials: true }
  );

  return data;
}

export function useChangeUsersStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ChangeUsersStatus,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
    }
  });
}
