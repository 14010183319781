import { AddressEdit } from '@/components/data-table/display/fields/address/AddressEdit';
import { formatRegion } from '@/components/data-table/display/fields/address/formatters/formatRegion';
import { type AddressField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function AddressRender(props: FieldRenderProps<AddressField>) {
  const { rawValue, fieldId, type, isFloating } = props;
  const { street, street2, city, state, zip, country, latitude, longitude } = rawValue;
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { format: addressFormat } = currentField;

  if (isFloating) {
    return <AddressEdit {...props} />;
  }

  if (
    addressFormat?.input === 'lat_long' &&
    latitude &&
    longitude &&
    !addressFormat?.enable_geocoding
  ) {
    return (
      <div className="size-full truncate whitespace-nowrap p-2">
        <p data-testid="render-latlong-address">
          {latitude}, {longitude}
        </p>
      </div>
    );
  }

  const isInternationalFormat = addressFormat?.format === 'international_country';
  return (
    <div
      className="size-full truncate whitespace-nowrap p-2"
      data-testid={`${fieldId}-render-full-address`}
    >
      {street && (
        <p>
          {street}
          {street2 && `, ${street2}`}
        </p>
      )}
      {(city || state || zip) && <p>{formatRegion(city, state, zip)}</p>}
      {country && isInternationalFormat && <p>{country}</p>}
    </div>
  );
}
