import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import { CURRENCY_FORMAT_OPTIONS, type CurrencyField } from '@/types/schema/fields';

type CurrencyFormSettingsProps = {
  field: CurrencyField;
};

export const currencySchema = z.object({
  format: z.object({
    format: z.enum(CURRENCY_FORMAT_OPTIONS)
  })
});

export function CurrencyFormSettings({ field }: CurrencyFormSettingsProps) {
  const [t] = useTranslation();

  const { control, watch } = useFormContext();

  const getPreview = () => {
    const format = watch('format.format') || CURRENCY_FORMAT_OPTIONS[0];

    const formattedValue = '1,234.56';

    if (format === '€_after') {
      return `${formattedValue}€`;
    }
    return `${format}${formattedValue}`;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Label htmlFor="currency-input-select" className="font-medium">
          {t('components.data_table.attributes.field_settings.currency.format_options_label')}
        </Label>

        <Controller
          control={control}
          name="format.format"
          defaultValue={field?.format?.format || CURRENCY_FORMAT_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || CURRENCY_FORMAT_OPTIONS[0]}
            >
              <Select.Trigger
                id="currency-input-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="currency-form-settings-input-select"
                {...currentField}
              />

              <Select.Content>
                {CURRENCY_FORMAT_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`currency-settings-input-value-${option}`}
                  >
                    <div className="flex items-center gap-1">
                      <span className="text-subtle">{option === '€_after' ? '€' : option}</span>
                      {t(
                        `components.data_table.attributes.field_settings.currency.format_options.${option}`
                      )}
                    </div>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-medium">
          {t('components.data_table.attributes.field_settings.currency.preview_label')}
        </p>
        <div className="rounded-lg bg-base">
          <span className="font-light">{getPreview()}</span>
        </div>
      </div>
    </div>
  );
}
