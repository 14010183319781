import { useTranslation } from 'react-i18next';

import { cn } from '@/utils/tailwind';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';

export function NonEditableFieldRender({
  value,
  isFloating
}: FieldRenderProps<{ value: string | number }>) {
  const [t] = useTranslation();

  return (
    <div
      className={cn('flex min-h-full w-full flex-col justify-between self-start bg-base p-2', {
        'bg-subtle ring-2 ring-black': isFloating
      })}
    >
      <p
        className={cn('min-h-[1em] truncate', {
          'text-right': typeof value === 'number'
        })}
      >
        {value}
      </p>
      {isFloating && (
        <p className="mt-2 text-xs text-subtle">{t('components.data_table.cannot_be_edited')}</p>
      )}
    </div>
  );
}
