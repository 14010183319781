import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import {
  DATE_FORMAT_OPTIONS,
  TIME_FORMAT_OPTIONS,
  type DateTimeField
} from '@/types/schema/fields';
import { capitalize } from '@/utils/formatters';

interface IncludeDateTimeSwitchProps {
  field: DateTimeField;
  type: 'date' | 'time';
}

export function IncludeDateTimeSwitch({ field, type }: IncludeDateTimeSwitchProps) {
  const [t] = useTranslation();

  const options = type === 'date' ? DATE_FORMAT_OPTIONS : TIME_FORMAT_OPTIONS;
  const typeFormat = (field?.format && field.format[`${type}_format`]) || options[0];

  return (
    <div className="flex items-center">
      <Controller
        name={`format.${type}_format`}
        defaultValue={
          typeFormat === `Ignore ${capitalize(type)}`
            ? `Ignore ${capitalize(type)}`
            : typeFormat || options[0]
        }
        render={({ field: { onChange, value } }) => (
          <Switch
            id={`include-${type}-format-switch`}
            className="mr-2"
            data-testid={`include-${type}-format-switch`}
            checked={value !== `Ignore ${capitalize(type)}`}
            onCheckedChange={(checked) => {
              onChange(
                checked ? options[0] : `Ignore ${capitalize(type)}` // Default to the first format option)
              );
            }}
          />
        )}
      />
      <Label htmlFor={`include-${type}-format-switch`} className="font-medium">
        {t(`components.data_table.attributes.field_settings.date_time.include_${type}`)}
      </Label>
    </div>
  );
}
