const fullAddressEditableValues = ['street', 'street2', 'city', 'state', 'zip'];
const coordinatesEditableValues = ['latitude', 'longitude'];

export const EDITABLE_VALUES_BY_FORMAT = {
  US: fullAddressEditableValues,
  international: fullAddressEditableValues,
  international_country: [...fullAddressEditableValues, 'country']
};

export const COORDINATES_EDITABLE_VALUES = coordinatesEditableValues;
