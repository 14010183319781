import { useMutation } from '@tanstack/react-query';
import LogRocket from 'logrocket';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { useApplication } from '@/hooks/useApplication';
import { getDomainAsCrm } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

interface EmergencyReportData {
  emergencyIssue: string;
  emergencyUrl: string;
  emergencyWhen: string;
  emergencyChanges: string;
}

async function sendEmergencyReport(data: EmergencyReportData, application: BuilderApplication) {
  const url = `/v1/applications/${application.id}/help/report-emergency`;
  const logRocketSessionUrl = import.meta.env.PUBLIC_LOGROCKET_APP_ID ? LogRocket.sessionURL : null;

  const ticketData = {
    devLink: getDomainAsCrm(),
    builderLink: window.location.href,
    logRocketSessionUrl,
    ...data
  };

  const response = await axiosInstance.post(url, ticketData, {
    withCredentials: true
  });
  return response;
}

export function useEmergencyReportMutation() {
  const application = useApplication();

  return useMutation({
    mutationFn: (data: EmergencyReportData) => {
      if (!application) throw new Error('Application not found');
      return sendEmergencyReport(data, application);
    }
  });
}
