import { t } from 'i18next';
import { z } from 'zod';

import { type DateTimeField } from '@/types/schema/fields';
import { dateRegex, timeRegex } from '@/components/data-table/helpers/zod';

export function getDateTimeSchema(field: DateTimeField) {
  const { date_format: dateFormat, time_format: timeFormat } = field.format;

  const dateFormatRegex = dateFormat ? dateRegex[dateFormat] : dateRegex['mm/dd/yyyy'];
  const timeFormatRegex = timeFormat ? timeRegex[timeFormat] : timeRegex['HH:MM am'];
  return z.object({
    date: z
      .string()
      .regex(dateFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'date' })
      })
      .optional()
      .or(z.literal('')),
    time: z
      .string()
      .regex(timeFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'time' })
      })
      .optional()
      .or(z.literal(''))
  });
}
