import { useForm, type SubmitHandler } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Label, Spinner, Textarea, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { useEmergencyReportMutation } from '@/hooks/api/mutations/useEmergencyReportMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';

export function EmergencyRequestForm() {
  const { resetPanel } = useHelpPanelStore((state) => ({
    resetPanel: state.resetPanel
  }));
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const presentErrorToast = useErrorToast();
  const { mutate, isPending } = useEmergencyReportMutation();

  const emergencyRequestFormSchema = z.object({
    emergencyIssue: z.string().min(1),
    emergencyUrl: z.string().min(1),
    emergencyWhen: z.string().min(1),
    emergencyChanges: z.string().min(1)
  });

  type EmergencyRequestFormSchema = z.infer<typeof emergencyRequestFormSchema>;

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors }
  } = useForm<EmergencyRequestFormSchema>({
    resolver: zodResolver(emergencyRequestFormSchema),
    defaultValues: {
      emergencyIssue: '',
      emergencyUrl: '',
      emergencyWhen: '',
      emergencyChanges: ''
    }
  });

  const onSubmitHandler: SubmitHandler<EmergencyRequestFormSchema> = (data) => {
    mutate(data, {
      onSuccess: () => {
        resetPanel();
        presentToast({
          title: t('components.help_menu.forms.emergency_request.message_success')
        });
      },
      onError: (error) => {
        presentErrorToast({
          error,
          translationPrefix: 'components.help_menu.forms.emergency_request',
          fallbackKey: 'components.help_menu.forms.emergency_request.error_sending_message'
        });
      }
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        id="emergency-request-form"
        data-testid="emergency-request-form"
        className="space-y-4 *:space-y-2"
      >
        <p className="mb-4" data-testid="emergency-request-form-description">
          <Trans i18nKey="components.help_menu.forms.emergency_request.description">
            <strong>only</strong>
          </Trans>
        </p>
        <div>
          <Label
            className="text-sm font-medium text-default"
            isRequired
            data-testid="emergency-request-form-describe"
          >
            {t('components.help_menu.forms.emergency_request.describe')}
          </Label>
          <p className="text-sm text-subtle">
            {t('components.help_menu.forms.emergency_request.helper_text')}
          </p>
          <Textarea {...register('emergencyIssue')} data-testid="emergency-request-form-issue" />
          {errors.emergencyIssue && (
            <p
              className="text-sm text-destructive"
              data-testid="emergency-request-form-error-message"
            >
              {t('components.help_menu.forms.emergency_request.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label isRequired className="text-sm font-medium text-default">
            {t('components.help_menu.forms.emergency_request.what_the_url')}
          </Label>
          <Input
            placeholder={t('keywords.url')}
            {...register('emergencyUrl')}
            data-testid="emergency-request-form-url-input"
          />
          {errors.emergencyUrl && (
            <p className="text-sm text-destructive">
              {t('components.help_menu.forms.emergency_request.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label isRequired className="mb-2 text-sm font-medium text-default">
            {t('components.help_menu.forms.emergency_request.when')}
          </Label>
          <Textarea
            spellCheck={false}
            {...register('emergencyWhen')}
            data-testid="emergency-request-form-when"
          />
          {errors.emergencyWhen && (
            <p className="text-sm text-destructive">
              {t('components.help_menu.forms.emergency_request.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label isRequired className="text-sm font-medium text-default">
            {t('components.help_menu.forms.emergency_request.any_changes')}
          </Label>
          <Textarea
            spellCheck={false}
            {...register('emergencyChanges')}
            data-testid="emergency-request-form-changes"
          />
          {errors.emergencyChanges && (
            <p className="text-sm text-destructive">
              {t('components.help_menu.forms.emergency_request.this_field_is_required')}
            </p>
          )}
        </div>
      </form>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          intent="minimal"
          disabled={isPending}
          onClick={() => resetForm()}
          data-testid="emergency-request-form-clear-button"
        >
          {t('actions.clear')}
        </Button>
        <Button
          type="submit"
          form="emergency-request-form"
          disabled={isPending}
          data-testid="emergency-request-form-submit-button"
        >
          {isPending ? <Spinner /> : t('actions.submit')}
        </Button>
      </div>
    </div>
  );
}
