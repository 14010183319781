import { type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import {
  EQUATION_MARK_DECIMAL_OPTIONS,
  EQUATION_NUMBER_FORMAT_OPTIONS,
  EQUATION_ROUNDING_OPTIONS,
  EQUATION_THOUSANDS_OPTIONS
} from '@/types/schema/fields';
import { type EquationSchemaType } from '@/components/field-settings/equation/EquationFormSettings';
import { SelectWithLabel } from '@/components/field-settings/utils/SelectWithLabel';

type EquationTypeSelectorProps = {
  currentField: ControllerRenderProps<EquationSchemaType, 'format'>;
};

export function EquationNumericResultFormat({ currentField }: EquationTypeSelectorProps) {
  const [t] = useTranslation();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="flex w-1/2 grow flex-col gap-2">
          <SelectWithLabel
            label={t(
              'components.data_table.attributes.field_settings.equation.decimal_delimiter.label'
            )}
            values={EQUATION_MARK_DECIMAL_OPTIONS}
            defaultValue={currentField.value.mark_decimal || EQUATION_MARK_DECIMAL_OPTIONS[0]}
            onValueChange={(value) => {
              currentField.onChange({
                ...currentField.value,
                mark_decimal: value
              });
            }}
            translationPrefix="components.data_table.attributes.field_settings.equation.decimal_delimiter"
            data_testid="equation-settings-precision"
          />
        </div>
        {currentField.value.mark_decimal !== 'none' && (
          <div className="flex w-1/2 grow flex-col gap-2">
            <SelectWithLabel
              label={t(
                'components.data_table.attributes.field_settings.equation.decimal_places.label'
              )}
              values={['0', '1', '2', '3', '4', '5', '10']}
              defaultValue={currentField.value.precision || '0'}
              onValueChange={(value) => {
                currentField.onChange({
                  ...currentField.value,
                  precision: value
                });
              }}
              translationPrefix="components.data_table.attributes.field_settings.equation.decimal_places"
              data_testid="equation-settings-mark-decimal"
            />
          </div>
        )}
      </div>
      <SelectWithLabel
        label={t('components.data_table.attributes.field_settings.equation.rounding.label')}
        values={EQUATION_ROUNDING_OPTIONS}
        defaultValue={currentField.value.rounding || EQUATION_ROUNDING_OPTIONS[0]}
        onValueChange={(value) => {
          currentField.onChange({
            ...currentField.value,
            rounding: value
          });
        }}
        translationPrefix="components.data_table.attributes.field_settings.equation.rounding"
        data_testid="equation-settings-rounding"
      />
      <SelectWithLabel
        label={t(
          'components.data_table.attributes.field_settings.equation.thousands_delimiter.label'
        )}
        values={EQUATION_THOUSANDS_OPTIONS}
        defaultValue={currentField.value.mark_thousands || EQUATION_THOUSANDS_OPTIONS[0]}
        onValueChange={(value) => {
          currentField.onChange({
            ...currentField.value,
            mark_thousands: value
          });
        }}
        translationPrefix="components.data_table.attributes.field_settings.equation.thousands_delimiter"
        data_testid="equation-settings-thousands-delimiter"
      />

      <SelectWithLabel
        label={t('components.data_table.attributes.field_settings.equation.number_format.label')}
        values={EQUATION_NUMBER_FORMAT_OPTIONS}
        defaultValue={currentField.value.format || EQUATION_NUMBER_FORMAT_OPTIONS[0]}
        onValueChange={(value) => {
          currentField.onChange({
            ...currentField.value,
            format: value
          });
        }}
        translationPrefix="components.data_table.attributes.field_settings.equation.number_format"
        data_testid="equation-settings-number-format"
      />

      {currentField.value.format === 'custom' && (
        <div className="flex gap-2">
          <div className="flex w-1/2 grow flex-col gap-2">
            <Label htmlFor="before-input" className="font-medium">
              {t('components.data_table.attributes.field_settings.equation.before')}
            </Label>
            <Input
              id="before-input"
              defaultValue={currentField.value.pre}
              onChange={(event) => {
                currentField.onChange({
                  ...currentField.value,
                  pre: event.target.value
                });
              }}
            />
          </div>
          <div className="flex w-1/2 grow flex-col gap-2">
            <Label htmlFor="after-input" className="font-medium">
              {t('components.data_table.attributes.field_settings.equation.after')}
            </Label>
            <Input
              id="after-input"
              defaultValue={currentField.value.post}
              onChange={(event) => {
                currentField.onChange({
                  ...currentField.value,
                  post: event.target.value
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
