import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageTreeItem } from '@/pages/pages/page-tree/Item';

export function DragAndDropContext({ isUserPages }: { isUserPages?: boolean }) {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
  const { sortMutation } = usePageMutation();
  const { userPages, nonUserRootPages, setUserPages, setNonUserRootPages } = useLeftPanelContext();
  const pages = isUserPages ? userPages : nonUserRootPages;
  const setPages = isUserPages ? setUserPages : setNonUserRootPages;

  function handleSortPages(pagesForSorting: BuilderPage[]) {
    const newPageOrder = pagesForSorting.map((page: BuilderPage) => page.key);
    sortMutation.mutate(newPageOrder);
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = pages.findIndex((page) => page.key === active.id);
      const newIndex = pages.findIndex((page) => page.key === over.id);
      const newPages = arrayMove(pages, oldIndex, newIndex);
      if (setPages) {
        setPages(newPages);
      }
      handleSortPages(newPages);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={pages.map((page) => page.key)}>
        {pages.map((page) => (
          <PageTreeItem key={page.key} page={page} isRootItem />
        ))}
      </SortableContext>
    </DndContext>
  );
}
