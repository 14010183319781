import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  HELP_MENU_PANEL,
  type HelpMenuPanel,
  type SupportFormPanel
} from '@/components/help-menu/types';

type State = {
  isOpen: boolean;
  activePanel: HelpMenuPanel;
  activeSupportFormId: SupportFormPanel | null;
};

type Actions = {
  setIsOpen: (isOpen: boolean) => void;
  setActivePanel: (activePanel: HelpMenuPanel) => void;
  setActiveSupportFormId: (activeSupportFormId: SupportFormPanel | null) => void;
  resetPanel: () => void;
};

const initialState: State = {
  isOpen: false,
  activePanel: HELP_MENU_PANEL.main,
  activeSupportFormId: null
};

export const helpPanelStore = createWithEqualityFn<State & Actions>()(
  devtools((set) => ({
    ...initialState,
    setIsOpen: (isOpen) => set({ isOpen }, false, 'setIsOpen'),
    setActivePanel: (activePanel) => set({ activePanel }, false, 'setActivePanel'),
    setActiveSupportFormId: (activeSupportFormId) =>
      set({ activeSupportFormId }, false, 'setActiveSupportFormId'),
    resetPanel: () => {
      set(
        {
          activePanel: HELP_MENU_PANEL.main,
          activeSupportFormId: null,
          isOpen: false
        },
        false,
        'resetPanel'
      );
    }
  })),
  shallow
);

export const useHelpPanelStore = helpPanelStore;
