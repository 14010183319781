import { type UserRolesField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { UserRolesEdit } from '@/components/data-table/display/fields/user-roles/UserRolesEdit';
import { UserRolesValues } from '@/components/data-table/display/fields/user-roles/UserRolesValues';

export function UserRolesRender(props: FieldRenderProps<UserRolesField>) {
  const { isFloating, rawValue } = props;

  if (isFloating) {
    return <UserRolesEdit {...props} />;
  }

  return <UserRolesValues rawValue={rawValue} />;
}
