import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { FormErrorMessage } from '@/components/errors/FormErrorMessage';

interface PasswordInputProps {
  id: string;
  name: string;
}

export function PasswordInput(props: PasswordInputProps) {
  const { id, name } = props;
  const [t] = useTranslation();
  const {
    getValues,
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <div className="space-y-2">
      <Input
        id={id}
        placeholder={t('attributes.field_types.password')}
        value={getValues(`${name}.password`)}
        data-testid="password-input"
        type="password"
        {...register(`${name}.password`)}
      />
      <Input
        id="confirm-password-input"
        placeholder={t('components.data_table.password_field.confirm_password')}
        data-testid="confirm-password-input"
        type="password"
        {...register(`${name}.password_confirmation`)}
      />

      <FormErrorMessage
        name={`${name}.password_confirmation`}
        errors={errors}
        className="text-md text-destructive"
      />
    </div>
  );
}
