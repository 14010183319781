import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Label,
  MultiSelect,
  RadioGroup,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { type NewPageFormSchema } from './NewPageForm';

export function NewPageFormUserPageSection() {
  const [t] = useTranslation();

  const { getRoleObjects } = useObjectHelpers();
  const { control, watch } = useFormContext<NewPageFormSchema>();

  const { limitProfileAccess } = watch();

  const userRoleObjects = getRoleObjects();
  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  return (
    <Form.Section className="mt-4">
      <Label
        htmlFor="new-login-page-limit-profile-access"
        className="mb-2 inline-block text-sm font-medium"
      >
        {t(`pages.settings.access.which_user_roles_can_access`)}
      </Label>
      <Controller
        control={control}
        name="limitProfileAccess"
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            id="new-user-page-limit-profile-access"
            className="text-sm"
            value={String(value)}
            onValueChange={(newValue) => {
              onChange(newValue === 'true');
            }}
          >
            <RadioGroup.Container>
              <RadioGroup.Item id="new-user-page-limit-profile-access-false" value="false" />
              <Label htmlFor="new-user-page-limit-profile-access-false">
                {t('pages.settings.access.all_logged_in_users')}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item id="new-user-page-limit-profile-access-true" value="true" />
              <Label htmlFor="new-user-page-limit-profile-access-true">
                {t('pages.settings.access.specific_user_roles_only')}
              </Label>
            </RadioGroup.Container>
          </RadioGroup>
        )}
      />

      {limitProfileAccess && (
        <div className="mt-2 pl-6">
          <Controller
            control={control}
            name="allowedProfileKeys"
            render={({ field: { onChange, value: selectedProfileKeys } }) => {
              const selectedUserRoleOptions =
                userRoleOptions.filter((option) =>
                  selectedProfileKeys?.includes(option.key as KnackObjectProfileKey)
                ) || [];

              return (
                <MultiSelect
                  id="new-user-page-user-roles"
                  options={userRoleOptions}
                  selectedOptions={selectedUserRoleOptions}
                  onSelectOptions={(newSelectedProfileKeys) => {
                    const selectedOptions = newSelectedProfileKeys.map(
                      (option) => option.key as KnackObjectProfileKey
                    );
                    onChange(selectedOptions);
                  }}
                />
              );
            }}
          />
        </div>
      )}
    </Form.Section>
  );
}
