import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Button, Dialog } from '@knack/asterisk-react';

interface NavigateAwayModalProps {
  condition: boolean;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
}

export function NavigateAwayModal({
  condition,
  title,
  description,
  cancelText,
  confirmText
}: NavigateAwayModalProps) {
  const [t] = useTranslation();

  // Using React Router's `useBlocker` to block internal navigations
  const routerBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      condition && currentLocation.pathname !== nextLocation.pathname
  );

  // Using the browser's `beforeunload` event to block external/browser navigations
  useEffect(() => {
    if (!condition) {
      return undefined;
    }

    function beforeUnloadHandler(event: BeforeUnloadEvent) {
      event.preventDefault();
    }

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [condition]);

  if (routerBlocker.state !== 'blocked') {
    return null;
  }

  return (
    <Dialog open onOpenChange={() => routerBlocker.reset()}>
      <Dialog.Content data-testid="navigate-away-modal">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{title || t('components.dialogs.navigate_away.title')}</Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>{description || t('components.dialogs.navigate_away.description')}</p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button
              intent="minimal"
              data-testid="navigate-away-modal-cancel-button"
              onClick={() => routerBlocker.reset()}
            >
              {cancelText || t('components.dialogs.navigate_away.cancel')}
            </Button>
          </Dialog.Close>
          <Dialog.Close asChild>
            <Button
              intent="destructive"
              onClick={() => routerBlocker.proceed()}
              data-testid="navigate-away-modal-confirm-button"
            >
              {confirmText || t('components.dialogs.navigate_away.confirm')}
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
