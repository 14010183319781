import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  IMAGE_RESIZE_TYPE_OPTIONS,
  IMAGE_SOURCE_OPTIONS,
  IMAGE_THUMB_TYPE_OPTIONS,
  type ImageField
} from '@/types/schema/fields';
import { ResizeImage } from '@/components/field-settings/image/ResizeImage';
import { Thumbnails } from '@/components/field-settings/image/Thumbnails';

type ImageFormSettingsProps = {
  field: ImageField;
};

export const imageSchema = z.object({
  format: z.object({
    source: z.enum(IMAGE_SOURCE_OPTIONS),
    resize: z
      .object({
        type: z.enum(IMAGE_RESIZE_TYPE_OPTIONS),
        maxHeight: z.number().optional(),
        maxWidth: z.number().optional()
      })
      .optional(),
    thumbs: z
      .array(
        z.object({
          key: z.string().optional(),
          thumb_type: z.enum(IMAGE_THUMB_TYPE_OPTIONS),
          width: z.string().or(z.number()),
          height: z.string().or(z.number()),
          length: z.string().or(z.number()),
          percentage: z.string().or(z.number())
        })
      )
      .optional()
  })
});

export function ImageFormSettings({ field }: ImageFormSettingsProps) {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Label htmlFor="source-select">
          {t('components.data_table.attributes.field_settings.image.source_label')}
        </Label>
        <Controller
          name="format.source"
          defaultValue={field?.format?.source || IMAGE_SOURCE_OPTIONS[0]}
          render={({ field: currentField }) => (
            <Select
              onValueChange={currentField.onChange}
              defaultValue={currentField.value || IMAGE_SOURCE_OPTIONS[0]}
            >
              <Select.Trigger
                id="source-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="image-form-settings-source-select"
                {...currentField}
              />
              <Select.Content>
                {IMAGE_SOURCE_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`image-field-settings-source-value-${option}`}
                  >
                    {t(
                      `components.data_table.attributes.field_settings.image.source_options.${option}`
                    )}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
      </div>

      <ResizeImage field={field} />
      <Thumbnails />
    </div>
  );
}
