import { useTranslation } from 'react-i18next';
import { BaseEdge, EdgeLabelRenderer, getBezierPath, type Edge, type EdgeProps } from 'reactflow';

import { type ConnectionOption } from '@/types/schema/fields';
import { type KnackObject } from '@/types/schema/KnackObject';
import { capitalize } from '@/utils/formatters';
import { cn } from '@/utils/tailwind';
import { InlineKnackTable } from '@/components/InlineKnackTable';
import { EDGE_COLOR_DEFAULT, EDGE_COLOR_HIGHLIGHTED } from '@/pages/data-model/helpers/constants';
import { useDataModelViewOptionsStore } from '@/pages/data-model/helpers/useDataModelViewOptionsStore';

type EdgeData = {
  connectionLabel: string;
  connectionDetail:
    | {
        sourceHas: string;
        sourceBelongsTo: ConnectionOption;
        sourceTableType: KnackObject['type'];
        sourceTableName: string;
        targetTableType: KnackObject['type'];
        targetTableName: string;
      }
    | undefined;
};

export type TableEdge = Edge<EdgeData>;

export const tableEdgeTypeName = 'table-edge';

export const defaultEdgeOptions = {
  type: tableEdgeTypeName,
  deletable: false
};

export function TableEdgeComponent({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  animated,
  data
}: EdgeProps<EdgeData>) {
  const [t] = useTranslation();

  const { showConnectionTypeLabels, showAllEdges } = useDataModelViewOptionsStore((state) => ({
    showConnectionTypeLabels: state.showConnectionTypeLabels,
    showAllEdges: state.showAllEdges
  }));

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    curvature: 0.75
  });

  const edgeColor = animated ? EDGE_COLOR_HIGHLIGHTED : EDGE_COLOR_DEFAULT;
  const markerStart = animated ? 'circleHighlighted' : 'circle';
  const markerEnd = animated ? 'arrowHighlighted' : 'arrow';

  const { connectionDetail } = data || {};

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={`url(#${markerStart})`}
        markerEnd={`url(#${markerEnd})`}
        style={{ strokeWidth: 2, stroke: edgeColor, opacity: showAllEdges ? 1 : 0 }}
      />
      {showConnectionTypeLabels && showAllEdges && (
        <EdgeLabelRenderer>
          {connectionDetail && (
            <div
              className="nodrag nopan pointer-events-auto absolute z-[2002] cursor-pointer bg-white text-xs"
              style={{
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
              }}
              data-testid="table-edge-connection-label"
            >
              <div
                className={cn('rounded-md border border-subtle px-1.5 py-1 shadow-sm', {
                  'border-brand-300': animated
                })}
              >
                {capitalize(
                  connectionDetail.sourceBelongsTo === 'one'
                    ? t('keywords.one')
                    : t('keywords.many')
                )}{' '}
                <InlineKnackTable
                  tableName={connectionDetail.sourceTableName}
                  tableType={connectionDetail.sourceTableType}
                  className="text-default"
                />{' '}
                {t('keywords.to')}{' '}
                {connectionDetail.sourceHas === 'one' ? t('keywords.one') : t('keywords.many')}{' '}
                <InlineKnackTable
                  tableName={connectionDetail.targetTableName}
                  tableType={connectionDetail.targetTableType}
                  className="text-default"
                />
              </div>
            </div>
          )}
        </EdgeLabelRenderer>
      )}
    </>
  );
}
