import { useTranslation } from 'react-i18next';

import { type ListView } from '@/types/schema/views/ListView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewPaginationSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewPaginationSettings';
import { ViewSettingsSwitchGroup } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewSettingsSwitchGroup';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function ListGeneralSettingsCategory() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ListView>();

  const listUserSettings = [
    {
      key: 'keyword_search',
      label: t('pages.element_settings.common.categories.general_settings.keyword_search'),
      checked: view.keyword_search
    },
    {
      key: 'allow_exporting',
      label: t('pages.element_settings.common.categories.general_settings.record_exporting'),
      checked: view.allow_exporting
    }
  ];

  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings />

      <BuilderAccordion.Item
        isDefaultOpen
        label={t('pages.element_settings.common.categories.general_settings.user_options')}
      >
        <ViewSettingsSwitchGroup options={listUserSettings} />
      </BuilderAccordion.Item>

      <ViewPaginationSettings />
    </BuilderAccordion>
  );
}
