import { type BuilderView } from '@/types/schema/BuilderView';
import { type KnackField, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormViewFieldInput } from '@/types/schema/views/FormView';

export function useViewHelpers() {
  // Returns the fields that are used as inputs in the view (e.g. form inputs, table columns, list items, etc.)
  const getViewFields = ({
    view,
    sourceObject,
    fieldTypesToIgnore
  }: {
    view: BuilderView;
    sourceObject: KnackObject;
    fieldTypesToIgnore?: KnackFieldType[];
  }) => {
    const viewFields: KnackField[] = [];

    if (!sourceObject.fields) {
      return viewFields;
    }

    if (view.type === 'form') {
      const formViewInputs: FormViewFieldInput[] = [];

      view.groups.forEach((group) => {
        group.columns.forEach((column) => {
          column.inputs.forEach((input) => {
            if (input.type === 'section_break' || input.type === 'divider') {
              return;
            }

            if (fieldTypesToIgnore?.includes(input.type)) {
              return;
            }

            formViewInputs.push(input);
          });
        });
      });

      if (!formViewInputs.length) {
        return viewFields;
      }

      formViewInputs.forEach((input) => {
        const field = sourceObject.fields.find((f) => f.key === input.field.key);

        if (field) {
          viewFields.push(field);
        }
      });
    }

    return viewFields;
  };

  return {
    getViewFields
  };
}
