import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { MULTIPLE_CHOICE_SORTING_OPTIONS } from '@/types/schema/fields';

type SortingSwitchProps = {
  onSortOptions: () => void;
};

export function SortingSwitch({ onSortOptions }: SortingSwitchProps) {
  const [t] = useTranslation();

  const { getValues } = useFormContext();

  return (
    <div className="flex items-center">
      <Controller
        name="format.sorting"
        defaultValue={getValues('format.sorting') || MULTIPLE_CHOICE_SORTING_OPTIONS[0]}
        render={({ field: { onChange, value } }) => (
          <Switch
            className="mr-2"
            data-testid="multiple-choice-sorting-value-switch"
            checked={value === 'alphabetical'}
            onCheckedChange={(checked) => {
              if (checked) {
                onSortOptions();
              }
              onChange(checked ? 'alphabetical' : 'custom');
            }}
          />
        )}
      />
      <Label className="font-medium">
        {t('components.data_table.attributes.field_settings.multiple_choice.sorting_label')}
      </Label>
    </div>
  );
}
