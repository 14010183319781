import { type BuilderPage } from '@/types/schema/BuilderPage';

export function getApplicationBasePathSegments() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const [accountSlug, appSlug] = pathSegments;
  return { accountSlug, appSlug };
}

export function getApplicationBasePath() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  if (!accountSlug || !appSlug) {
    return '/';
  }

  return `/${accountSlug}/${appSlug}`;
}

export function getBuilderBaseUrl() {
  const applicationBasePath = getApplicationBasePath();
  return `${import.meta.env.PUBLIC_BUILDER_URL}${applicationBasePath}`;
}

// Returns the current URL but replaces the domain with the CRM domain, while keeping the rest of the URL (like protocol, path, query string, etc.) the same.
export function getDomainAsCrm() {
  const devDomain = 'builder.knackcrm.com';

  return window.location.href.replace(/^(https?:\/\/)[^/]+(\/.+)$/i, `$1${devDomain}$2`);
}

export function getPageAncestorsUrl(page: BuilderPage, pages: BuilderPage[] | undefined) {
  if (!page || !pages) {
    return '';
  }

  let currentPage: BuilderPage | null = page;
  const path: string[] = [];

  while (currentPage) {
    if (currentPage.parentSlug) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const parentPage = pages.find((p) => p.slug === currentPage?.parentSlug);
      if (!parentPage?.requiresAuthentication) {
        path.unshift(currentPage.parentSlug);
        if (parentPage) {
          currentPage = parentPage;
        } else {
          currentPage = null;
        }
      } else {
        currentPage = null;
      }
    } else {
      currentPage = null;
    }
  }

  return path.length > 0 ? `/${path.join('/')}` : '';
}
