import { type MultiSelectOption } from '@knack/asterisk-react';
import { t } from 'i18next';

import { type BuilderPage } from '@/types/schema/BuilderPage';

export function getDropdownMenuOptions({
  allPages,
  dropdownPageKey,
  appSingleLoginPage
}: {
  allPages: BuilderPage[] | undefined;
  dropdownPageKey?: string;
  appSingleLoginPage: BuilderPage | null;
}): MultiSelectOption[] {
  const onlyPages = allPages?.filter(
    (page) =>
      (page.type === 'page' || page.type === 'authentication') &&
      page.key !== appSingleLoginPage?.key
  );

  return (
    onlyPages?.map((page) => {
      const parentPageName = allPages?.find((p) => {
        if (p.key === dropdownPageKey) {
          return false;
        }
        if (page.parentSlug && page.parentSlug !== appSingleLoginPage?.slug) {
          return p.slug === page.parentSlug;
        }
        if (page.menuPageKey) {
          return p.key === page.menuPageKey;
        }
        return false;
      })?.name;

      return {
        label: parentPageName
          ? t(`pages.dialogs.new_menu.currently_under`, {
              pageName: page.name,
              menuName: parentPageName
            })
          : page.name,
        key: page.key,
        value: page.id,
        isDisabled: !!parentPageName
      };
    }) || []
  );
}
