import { useQuery } from '@tanstack/react-query';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export async function getApplicationPages(accountSlug: string, appSlug: string) {
  const endpoint = `/v1/builder/${accountSlug}/application/${appSlug}/pages`;
  const { data } = await axios.get<BuilderPage[]>(endpoint, {
    withCredentials: true
  });

  return data;
}

export function usePagesQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.applicationPages, accountSlug, appSlug],
    queryFn: () => getApplicationPages(accountSlug, appSlug),
    staleTime: 1000 * 60 * 5
  });
}
