import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type ReportViewCalculation } from '@/types/schema/views/ReportView';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { SourceFilterDialogGroupItem } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFilterDialogGroupItem';

type CalculationFiltersDialogProps = {
  sourceObject: KnackObject;
  calculationFilters: BuilderViewSourceCriteriaRule[];
  onFormSubmit: (data: ReportViewCalculation) => void;
};

function CalculationFiltersDialogContent({
  sourceObject,
  calculationFilters,
  onFormSubmit
}: CalculationFiltersDialogProps) {
  const [t] = useTranslation();
  const { getOperatorsForField } = useFieldHelpers();

  const calculationFiltersSchema = z.object({
    field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
    operator: z.string().min(1, t('errors.value_required')),
    value: z
      .union([z.string(), z.boolean(), z.array(z.string()), z.record(z.any())])
      .refine((value) => value, t('errors.value_required'))
  });

  const calculationFiltersFormSchema = z.object({
    filters: z.array(calculationFiltersSchema)
  });

  const form = useForm<ReportViewCalculation>({
    resolver: zodResolver(calculationFiltersFormSchema),
    defaultValues: {
      filters: calculationFilters
    }
  });

  const {
    fields: rulesFields,
    append: addRuleField,
    remove: removeRuleField,
    update: updateRuleField
  } = useFieldArray({
    control: form.control,
    name: 'filters'
  });

  const hasCalculationFilters = rulesFields.length > 0;

  const getDefaultCriteriaRule = (fieldKey: KnackFieldKey) => ({
    field: fieldKey,
    operator: getOperatorsForField(fieldKey, sourceObject.fields)[0],
    value: ''
  });

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.report.categories.data_display.calculation_filters.dialog_title'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.report.categories.data_display.calculation_filters.dialog_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasCalculationFilters && (
            <div className="mt-6 text-sm">
              <p className="mb-4">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
                )}
              </p>
              <div className="space-y-2 rounded-lg bg-subtle p-3">
                {rulesFields.map((ruleField, ruleFieldIndex) => (
                  <SourceFilterDialogGroupItem
                    key={ruleField.id}
                    availableFields={sourceObject.fields}
                    formFieldName={`filters.${ruleFieldIndex}`}
                    ruleField={ruleField}
                    ruleFieldIndex={ruleFieldIndex}
                    update={updateRuleField}
                    append={addRuleField}
                    remove={removeRuleField}
                  />
                ))}
              </div>
            </div>
          )}
          <Button
            intent="secondary"
            className="mt-4 gap-1"
            onClick={() => {
              if (hasCalculationFilters) {
                const currentFilters = form.getValues('filters');
                form.setValue('filters', [
                  ...(currentFilters as BuilderViewSourceCriteriaRule[]),
                  getDefaultCriteriaRule(sourceObject.fields[0].key)
                ]);
              } else {
                addRuleField(getDefaultCriteriaRule(sourceObject.fields[0].key));
              }
            }}
            data-testid="calculation-filters-modal-add-source-filter-button"
          >
            <Button.Icon icon={AddIcon} />
            {hasCalculationFilters
              ? t(
                  'pages.element_settings.common.categories.data_display.general_settings.filter_group'
                )
              : t(
                  'pages.element_settings.report.categories.data_display.calculation_filters.filters_label'
                )}
          </Button>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function CalculationFiltersDialog({
  sourceObject,
  calculationFilters,
  onFormSubmit
}: CalculationFiltersDialogProps) {
  const [t] = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const hasCalculationFilters = calculationFilters.length > 0;

  const handleSourceFiltersSubmit = (data: ReportViewCalculation) => {
    onFormSubmit(data);
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className="gap-1"
          size="sm"
          data-testid="chart-view-settings-add-calculation-filters-button"
        >
          <Button.Icon icon={hasCalculationFilters ? EditIcon : AddIcon} />
          {hasCalculationFilters
            ? t(
                'pages.element_settings.report.categories.data_display.calculation_filters.edit_filters_button_label'
              )
            : t(
                'pages.element_settings.report.categories.data_display.calculation_filters.add_filters_button_label'
              )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <CalculationFiltersDialogContent
          sourceObject={sourceObject}
          calculationFilters={calculationFilters}
          onFormSubmit={handleSourceFiltersSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
