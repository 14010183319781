import i18n from 'i18next';

import { type RepeatOptions } from '@/components/data-table/display/fields/Field';

export const defaultDateFormatMap = {
  'dd/mm/yyyy': 'dd/MM/yyyy',
  'mm/dd/yyyy': 'MM/dd/yyyy',
  'M D, yyyy': 'MM/dd/yyyy',
  'Ignore Date': 'none'
} as const;

// Maps have to be a function because of the i18n.t
export const selectPeriodOption = () =>
  ({
    daily: i18n.t(
      'components.data_table.attributes.field_labels.date_time.repeat_frenquency.daily'
    ),
    weekly: i18n.t(
      'components.data_table.attributes.field_labels.date_time.repeat_frenquency.weekly'
    ),
    monthly: i18n.t(
      'components.data_table.attributes.field_labels.date_time.repeat_frenquency.monthly'
    ),
    yearly: i18n.t(
      'components.data_table.attributes.field_labels.date_time.repeat_frenquency.yearly'
    )
  }) as const;

export type SelectPeriodOption = keyof typeof selectPeriodOption;

export const weeklyCheckboxOptionsMap = () =>
  ({
    SU: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.sunday'),
    MO: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.monday'),
    TU: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.tuesday'),
    WE: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.wednesday'),
    TH: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.thursday'),
    FR: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.friday'),
    SA: i18n.t('components.data_table.attributes.field_labels.date_time.weekdays.saturday')
  }) as const;

export type DayKey = keyof typeof weeklyCheckboxOptionsMap;

export type SaveDateTimeCellValue = {
  date?: string;
  time?: string;
  endDate?: string;
  endTime?: string;
  repeat?: RepeatOptions;
};
