import { DateTime } from 'luxon';

import {
  type DateFormatValue,
  type DateTimeFieldFormat
} from '@/types/schema/fields/DateTimeField';

export type LuxonDateFormat = 'none' | 'dd/MM/yyyy' | 'MM/dd/yyyy';

const DEFAULT_LUXON_TIME_FORMAT = 'hh:mma';

export function useDateTimeHelpers() {
  const checkIsValidDate = (value: string, format: LuxonDateFormat) =>
    DateTime.fromFormat(value, format).isValid ||
    DateTime.fromFormat(value, format.replace('yyyy', 'yy')).isValid;

  const isTimeStringInMilitaryFormat = (timeString: string) => {
    // Check if the time contains AM or PM (12-hour format)
    const is12HourFormat = /[APMapm]/.test(timeString);

    // If AM or PM is present, it's a 12-hour format
    if (is12HourFormat) {
      return false;
    }

    // If no AM/PM and hour is <= 12, assume it's still 24-hour format (e.g., "05:00" can be 24-hour)
    return true;
  };

  const getHourMinutesStringPadded = (hours?: number, minutes?: number) => {
    if (hours !== undefined && minutes !== undefined) {
      const hoursFormatted = hours < 10 ? `0${hours}` : String(hours);
      const minutesFormatted = minutes < 10 ? `0${minutes}` : String(minutes);
      return `${hoursFormatted}:${minutesFormatted}`;
    }

    return '';
  };

  const getFormattedDatePickerDate = (date: string | undefined, format: LuxonDateFormat) =>
    !date || !checkIsValidDate(date, format)
      ? DateTime.now().toJSDate()
      : DateTime.fromFormat(date, format.replace('yyyy', 'yy')).toJSDate();

  const getDefaultTimeToString = (fieldFormat: DateTimeFieldFormat, additionalHours?: number) => {
    const defaultTime =
      fieldFormat.time_format === 'HH:MM am'
        ? fieldFormat.default_time
        : fieldFormat.default_time?.slice(0, -2);
    const defaultFormat = fieldFormat.time_format === 'HH:MM am' ? 'hh:mm' : 'HH:mm';

    if (fieldFormat.time_type === 'current') {
      if (additionalHours) {
        return DateTime.now().plus({ hours: additionalHours }).toFormat(defaultFormat);
      }
      return DateTime.now().toFormat(defaultFormat);
    }

    if (fieldFormat.time_type === 'time' && defaultTime) {
      if (additionalHours) {
        return DateTime.fromFormat(defaultTime, defaultFormat)
          .plus({ hours: additionalHours })
          .toFormat(defaultFormat);
      }
      return DateTime.fromFormat(defaultTime, defaultFormat).toFormat(defaultFormat);
    }

    return '';
  };

  const getRepeatDefaultOptions = () => ({
    frequency: 'daily',
    interval: '1',
    SU: false,
    MO: false,
    TU: false,
    WE: false,
    TH: false,
    FR: false,
    SA: false,
    repeatby: 'dom',
    endson: 'never',
    end_count: '',
    end_date: ''
  });

  // Returns the current date in the specified luxon format
  const getCurrentDate = (format: LuxonDateFormat) => DateTime.now().toFormat(format);

  // Returns a future date in the specified luxon format. Defaults to tomorrow if no 'daysFromNow' are passed.
  const getFutureDate = (format: LuxonDateFormat, daysFromNow: number = 1) =>
    DateTime.now().plus({ days: daysFromNow }).toFormat(format);

  // Returns the current time in the default luxon format
  const getCurrentTime = () => DateTime.now().toFormat(DEFAULT_LUXON_TIME_FORMAT);

  const getNormalizedDateFormat = (fieldFormat: DateTimeFieldFormat) => {
    const formatToNormalize = fieldFormat.date_format || 'mm/dd/yyyy';

    const dateFormatMap: {
      [key in DateFormatValue]: LuxonDateFormat;
    } = {
      'dd/mm/yyyy': 'dd/MM/yyyy',
      'mm/dd/yyyy': 'MM/dd/yyyy',
      'M D, yyyy': 'MM/dd/yyyy',
      'Ignore Date': 'none'
    };

    return dateFormatMap[formatToNormalize];
  };

  return {
    isTimeStringInMilitaryFormat,
    getHourMinutesStringPadded,
    getFormattedDatePickerDate,
    getDefaultTimeToString,
    getRepeatDefaultOptions,
    getCurrentDate,
    getFutureDate,
    getCurrentTime,
    getNormalizedDateFormat
  };
}
