import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleValues
} from '@/types/schema/KnackField';

export const NAME_FIELD_FORMATS = [
  'First Last',
  'First Middle Last',
  'Last, First',
  'Last, First Middle',
  'Title First Last'
] as const;

export type NameFieldFormat = (typeof NAME_FIELD_FORMATS)[number];

export interface NameField extends BaseKnackField {
  type: 'name';
  format?: {
    format: NameFieldFormat;
  };
  rules: NameFieldConditionalRule[];
}

interface NameFieldConditionalRule extends ConditionalRule {
  values: NameFieldConditionalRuleValues[];
}

interface NameFieldConditionalRuleValues extends ConditionalRuleValues {
  value: NameFieldConditionalRuleValue;
}

export interface NameFieldConditionalRuleValue {
  first: string;
  last: string;
  title?: string;
}
