import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';
import { useIsMutating } from '@tanstack/react-query';
import i18n from 'i18next';

import { mutationKeys } from '@/hooks/api/mutationKeys';
import { DiscardChangesModal } from '@/components/DiscardChangesModal';
import { ROUTES } from '@/Router';
import { useSettingsPageContext } from './SettingsPageContext';

function getSettingsTopBarHeading(pathname: string) {
  switch (pathname) {
    case ROUTES.SETTINGS_GENERAL:
      return i18n.t('app_settings.sections.general');
    case ROUTES.SETTINGS_USERS:
      return i18n.t('app_settings.sections.user_logins');
    case ROUTES.SETTINGS_PAYMENTS:
      return i18n.t('app_settings.sections.payments');
    case ROUTES.SETTINGS_API:
      return i18n.t('app_settings.sections.api');
    default:
      return i18n.t('app_settings.sections.general');
  }
}

export function SettingsTopBar() {
  const [t] = useTranslation();
  const { pathname } = useLocation();

  // This will return the number of active mutations matching the mutation key
  const activeApplicationSettingsMutations = useIsMutating({
    mutationKey: [mutationKeys.applicationSettings]
  });

  const { isApplicationModified, saveChanges, discardChanges } = useSettingsPageContext();

  const [isConfirmDiscardChangesModalOpen, setIsConfirmDiscardChangesModalOpen] = useState(false);

  const topbarHeading = getSettingsTopBarHeading(pathname);
  const isSaving = activeApplicationSettingsMutations > 0;

  return (
    <>
      <div className="flex items-center justify-between border-b border-subtle bg-muted px-6 py-4">
        <div className="flex items-center">
          <h2 className="mr-2 truncate text-xl font-medium text-emphasis">{topbarHeading}</h2>
        </div>
        <div className="flex items-center">
          <Button
            size="sm"
            intent="minimal"
            className="mr-2"
            disabled={!isApplicationModified}
            onClick={() => setIsConfirmDiscardChangesModalOpen(true)}
          >
            {t('actions.discard_changes')}
          </Button>
          <Button
            size="sm"
            disabled={!isApplicationModified}
            isLoading={isSaving}
            onClick={saveChanges}
          >
            {t('actions.save_changes')}
          </Button>
        </div>
      </div>

      <DiscardChangesModal
        isOpen={isConfirmDiscardChangesModalOpen}
        onOpenChange={setIsConfirmDiscardChangesModalOpen}
        onConfirm={discardChanges}
      />
    </>
  );
}
