import { type ImageField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ImageUploadRender } from '@/components/data-table/display/fields/image/upload/ImageUploadRender';
import { ImageUrlRender } from '@/components/data-table/display/fields/image/url/ImageUrlRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ImageRender(props: FieldRenderProps<ImageField>) {
  const { fieldId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { format } = currentField;

  if (format.source === 'upload') {
    return <ImageUploadRender {...props} />;
  }

  return <ImageUrlRender {...props} />;
}
