import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function editAccountInfoTemplate({
  template,
  profileId
}: {
  template: string;
  profileId: string;
}) {
  const { data } = await axios.post(
    `/v1/profiles/${profileId}/user_templates/intros`,
    { template },
    { withCredentials: true }
  );
  return data;
}

export function useEditAccountInfoTemplateMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editAccountInfoTemplate,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.accountInfoTemplates] });
    }
  });
}
