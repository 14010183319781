import { useRef } from 'react';

import { cn } from '@/utils/tailwind';
import { SidePanelContent as HelpMenuSidePanelContent } from '@/components/help-menu/SidePanelContent';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';
import { LeftSidebar } from '@/components/navigation/LeftSidebar';
import { SidePanel } from '@/components/SidePanel';
import { Header, HEADER_HEIGHT } from './Header';
import { type RightSidebar } from './RightSidebar';

interface MainLayoutProps {
  children: React.ReactNode;
  rightSidebar?: React.ReactElement<typeof RightSidebar>;
}

export function MainLayout({ children, rightSidebar }: MainLayoutProps) {
  const { isOpen: isHelpPanelOpen, resetPanel: resetHelpPanel } = useHelpPanelStore((state) => ({
    isOpen: state.isOpen,
    resetPanel: state.resetPanel
  }));
  const mainContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-screen">
      <Header />
      <div className="flex" style={{ height: `calc(100vh - ${HEADER_HEIGHT}px` }}>
        <LeftSidebar />
        <main
          ref={mainContainerRef}
          className={cn(
            'relative w-full overflow-auto rounded-tl-lg border-l border-t border-subtle',
            {
              'flex h-full': rightSidebar
            }
          )}
        >
          {children}
          {rightSidebar && (
            <div data-testid="right-sidebar" className="z-20 basis-14">
              {rightSidebar}
            </div>
          )}

          <SidePanel open={isHelpPanelOpen} onOpenChange={resetHelpPanel}>
            <HelpMenuSidePanelContent containerRef={mainContainerRef} />
          </SidePanel>
        </main>
      </div>
    </div>
  );
}
