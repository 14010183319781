import { useContext, useState } from 'react';
import { useDropzone, type DropEvent, type FileError, type FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  HiXMark as CloseIcon,
  HiExclamationTriangle as ErrorIcon,
  HiArrowUpTray as UploadIcon
} from 'react-icons/hi2';
import { Banner, Button, Spinner, ThemeProviderContext } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export interface FilePreviewData {
  src: string;
  name: string;
  size: number;
}

interface DropzoneProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
  allowedFormats?: { [mimeType: string]: string[] };
  description?: string;
  maxFileSize: number;
  errorMapping: (error: FileError) => string;
  filePreviewData?: FilePreviewData;
  setFilePreviewData?: (data: FilePreviewData) => void;
  onClearFile?: () => void;
}

export function FileDropzone({
  onDrop,
  allowedFormats = {},
  description,
  maxFileSize,
  errorMapping,
  filePreviewData,
  setFilePreviewData,
  onClearFile,
  ...props
}: DropzoneProps) {
  const [t] = useTranslation();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { isDarkMode } = useContext(ThemeProviderContext);

  const resetFilePreviewData = () => {
    if (setFilePreviewData) {
      setFilePreviewData({
        src: '',
        name: '',
        size: 0
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop: async (files: File[], rejections: FileRejection[], event: DropEvent) => {
      if (rejections.length > 0) {
        setUploadedFiles([]);
        resetFilePreviewData();
        setIsUploading(false);
        return;
      }

      await onDrop(files, rejections, event);
      setIsUploading(false);
    },
    onDropAccepted: (files: File[]) => {
      setIsUploading(true);
      setUploadedFiles(files);
    },
    multiple: false,
    maxFiles: 1,
    maxSize: maxFileSize,
    accept: allowedFormats
  });

  const isImage = (name: string) => /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(name);
  const combinedFileDropzoneWrapperProps = { ...props, ...getRootProps() };

  let content = (
    <>
      <input
        {...getInputProps({ style: {} })}
        className="absolute mb-2 size-full cursor-pointer opacity-0"
        aria-label={t('components.file_dropzone.aria_label')}
        data-testid="file-dropzone-input"
      />
      <UploadIcon size={40} className="mb-2" />
      <div className="text-center">
        <p className="mb-2 font-semibold text-emphasis">{t('components.file_dropzone.title')}</p>
        <div className="text-sm">
          {description && <p>{description}</p>}
          <p>
            {t('components.file_dropzone.size_restriction', {
              size: maxFileSize / 1000 / 1000
            })}
          </p>
        </div>
      </div>
    </>
  );

  if (filePreviewData?.src) {
    content = (
      <div className="flex w-full items-center justify-between rounded-md bg-card p-2">
        <div className="flex truncate">
          {isImage(filePreviewData.name) && (
            <img
              src={filePreviewData?.src}
              alt={t('keywords.file_preview')}
              className="mr-2 size-9 rounded-md"
              data-testid="file-preview-image"
            />
          )}
          <div className="flex flex-col truncate">
            <span className="truncate text-sm">
              {uploadedFiles[0]?.name || filePreviewData.name}
            </span>
            <span className="text-xs text-subtle">
              {((uploadedFiles[0]?.size || filePreviewData.size) / 1000 / 1000).toFixed(2)} MB`
            </span>
          </div>
        </div>
        <Button
          intent="minimal"
          size="sm"
          onClick={() => {
            setUploadedFiles([]);
            resetFilePreviewData();
            if (onClearFile) onClearFile();
          }}
          className="ml-2"
        >
          <CloseIcon size={20} />
        </Button>
      </div>
    );
  }

  if (isUploading) {
    content = (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div
        {...combinedFileDropzoneWrapperProps}
        className={cn(
          'relative mt-7 flex flex-col items-center justify-center rounded-md border-2 border-dashed border-pink-600 p-6',
          {
            'border-blue-600': isDragActive,
            'bg-gradient-1-white': !isDarkMode,
            'bg-gradient-1-dark': isDarkMode,
            'p-2': filePreviewData?.src
          }
        )}
      >
        {content}
      </div>

      {fileRejections.length > 0 && (
        <div className="mb-2 flex flex-col gap-2 text-sm text-destructive">
          {fileRejections.map((rejection) => (
            <Banner key={rejection.file.name} intent="destructive" icon={ErrorIcon}>
              {rejection.errors.map((error) => (
                <p key={error.code}>{errorMapping(error)}</p>
              ))}
            </Banner>
          ))}
        </div>
      )}
    </>
  );
}
