import { type FormView, type FormViewInput } from '@/types/schema/views/FormView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';
import { FormFieldInputEditModal } from './field-management/edit-field-input/FormFieldInputEditModal';
import { FormSectionBreakInputEditModal } from './field-management/FormSectionBreakInputEditModal';

function getFormViewInput(view: FormView, viewInputId: string) {
  let fieldInput: FormViewInput | undefined;

  view.groups.forEach((group) => {
    group.columns.forEach((column) => {
      column.inputs.forEach((input) => {
        const isStaticInput = input.type === 'divider' || input.type === 'section_break';
        if (
          (isStaticInput && input.id === viewInputId) ||
          (!isStaticInput && input.field.key === viewInputId)
        ) {
          fieldInput = input;
        }
      });
    });
  });

  return fieldInput;
}

export function FormInputEditModal() {
  const { view } = useActiveViewContext<FormView>();
  const { isViewInputEditMode, setIsViewInputEditMode, viewInputId } =
    useViewInputEditModeContext();

  if (isViewInputEditMode && viewInputId) {
    const formInput = getFormViewInput(view, viewInputId);

    if (!formInput || formInput.type === 'divider') {
      return null;
    }

    if (formInput.type === 'section_break') {
      return (
        <FormSectionBreakInputEditModal
          existingInput={formInput}
          onOpenChange={setIsViewInputEditMode}
        />
      );
    }

    return <FormFieldInputEditModal fieldInput={formInput} onOpenChange={setIsViewInputEditMode} />;
  }

  return null;
}
