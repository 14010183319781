import { useQuery } from '@tanstack/react-query';

import { type KnackConnectionRecord } from '@/types/schema/KnackRecord';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useApplication } from '@/hooks/useApplication';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

interface ConnectionRecordsQueryParams {
  objectKey: string;
  enabled?: boolean;
  options?: Partial<GetConnectionRecordsOptions>;
}

interface ConnectionRecordResponse {
  total_pages: number;
  current_page: number;
  total_records: number;
  records: KnackConnectionRecord[];
}

export interface GetConnectionRecordsOptions {
  rowsPerPage: 'all_records' | number;
  limitReturn: boolean;
  filters?: Array<{
    field: string;
    operator: string;
    value: string;
  }>;
}

const defaultConnectionRecordOptions: GetConnectionRecordsOptions = {
  rowsPerPage: 2000,
  limitReturn: true
};

async function getConnectionRecords(
  applicationId: string,
  objectKey: string,
  options: Partial<GetConnectionRecordsOptions> = defaultConnectionRecordOptions
) {
  const queryParams = new URLSearchParams({
    rows_per_page: String(options.rowsPerPage) || String(defaultConnectionRecordOptions.rowsPerPage)
  });

  if (options.limitReturn === true) {
    queryParams.append('limit_return', String(options.limitReturn));
  }

  // Only add 'filters' param if they are provided
  if (options.filters?.length) {
    queryParams.append('filters', JSON.stringify(options.filters));
  }

  const { data } = await axiosInstance.get<ConnectionRecordResponse>(
    `/v1/applications/${applicationId}/connections/${objectKey}`,
    { params: queryParams, withCredentials: true }
  );

  return data;
}

export function useConnectionRecordsQuery({
  objectKey,
  enabled = true,
  options = defaultConnectionRecordOptions
}: ConnectionRecordsQueryParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  const application = useApplication();

  if (!application) throw new Error('Application not found');
  return useQuery({
    queryKey: [queryKeys.connectionRecords, accountSlug, appSlug, objectKey],
    queryFn: async () => getConnectionRecords(application.id, objectKey, options),
    enabled
  });
}
