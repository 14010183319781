import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldsWrapper } from '@/components/import/field-type-dropdown/FieldsWrapper';
import { Submenus } from '@/components/import/field-type-dropdown/Submenus';
import { type Column, type ColumnMeta } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';

export function FieldTypeDropdown({ column }: { column: Column }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [t] = useTranslation();
  const { existingTable, getColumnVisibility } = useImportStore((state) => ({
    existingTable: state.existingTable,
    getColumnVisibility: state.getColumnVisibility
  }));

  if (!column) return null;
  const { meta } = column;
  const isColumnVisible = getColumnVisibility(column);
  const columnMeta = meta as ColumnMeta;
  let selectedItem = '';
  if (columnMeta.existingKnackField) {
    selectedItem = columnMeta.existingKnackField.name;
  } else if (columnMeta.newFieldType) {
    selectedItem = t(`attributes.field_types.${columnMeta.newFieldType}`);
  }

  return (
    <DropdownMenu open={isDropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenu.Trigger
        asChild
        disabled={
          !isColumnVisible ||
          (column.meta.isThisColumnMapped && column.meta.mappedColumnIndex !== column.accessorKey)
        }
      >
        <Button
          size="sm"
          intent="secondary"
          className="w-48 justify-between overflow-hidden"
          data-testid="import-field-type-dropdown-trigger"
          aria-expanded={isDropdownOpen}
        >
          <div className="flex items-center">
            {!!existingTable && (
              <span className="mr-1 text-xs text-subtle">
                {t(
                  `components.add_into_existing_table.${
                    columnMeta.existingKnackField ? 'existing_field' : 'new_field'
                  }`
                )}
              </span>
            )}
            <FieldIcon
              size={16}
              name={
                (columnMeta.existingKnackField?.type as KnackFieldType) || columnMeta.newFieldType
              }
              className="mr-1.5 text-subtle"
            />
            <span className="truncate" title={selectedItem}>
              {selectedItem}
            </span>
          </div>
          <ChevronDownIcon
            size={16}
            className={cn('transition-all', {
              '-rotate-90': isDropdownOpen
            })}
          />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        side={existingTable ? undefined : 'right'}
        className="my-2 flex w-48 flex-col p-0 text-sm"
        data-testid="import-field-type-dropdown-content"
      >
        {existingTable ? <Submenus column={column} /> : <FieldsWrapper column={column} />}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
