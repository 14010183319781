import { type Field } from '@/components/data-table/display/fields/Field';

export const DEFAULT_ROW_HEIGHT = 42;
export const ROW_HEIGHTS: Partial<Record<Field['type'], number>> = {
  image: 81,
  paragraph_text: 81,
  rich_text: 81,
  connection: 81,
  address: 81,
  multiple_choice: 81
};

export const COLUMNS_WIDTH: Partial<Record<Field['type'] | 'date_time_calendar', number>> = {
  file: 150,
  image: 120,
  name: 300,
  date_time: 280,
  date_time_calendar: 530,
  multiple_choice: 300,
  phone: 300,
  timer: 530
};

export const DEFAULT_WIDTH = 200;
export const ID_COLUMN_WIDTH = 75;

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100, 500, 1000];
export const ROWS_PER_PAGE = 100;
export const PAGINATION_MODE_MAX_RECORDS = 1000;

export const DATA_TABLE_FLOATING_ID = 'data-table';
export const DATA_TABLE_MARGIN_BOTTOM = 60;
export const DATA_TABLE_PAGINATION_DEBOUNCE_TIME = 100;

export const DATA_TABLE_STORE_ID = 'data-table';

// Look ahead and behind this number of rows to load pages in advance
export const NUMBER_OF_OVERSCAN_ROWS = 51;

export const CELL_BORDER_WIDTH = 2;

export const NUMBER_OF_SKELETON_ROWS = 10;

export const DAY_PICKER_FROM_YEAR = 1910;
export const DAY_PICKER_TO_YEAR = 2099;

export const SIGNATURE_CANVAS_WIDTH = 500;
export const SIGNATURE_CANVAS_HEIGHT = 125;
