import { useCallback, useEffect, useRef, useState } from 'react';
import { IMask, useIMask } from 'react-imask';

import { PHONE_EXTENSION_MASK, PHONE_FORMAT_TO_MASK } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type PhoneField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function PhoneEdit(props: FieldRenderProps<PhoneField>) {
  const { value, fieldId, rowId, type } = props;
  // Imask calls onAccept on component creation, so we need to ignore the first call
  const firstOnAcceptCall = useRef(true);
  const isDirty = useRef(false);

  const [localValue, setLocalValue] = useState(value);

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();
  const initialCursorPosition = useRef(0);

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const mask =
    currentField.format?.format === 'any'
      ? Number
      : `${PHONE_FORMAT_TO_MASK[currentField.format?.format]}${
          currentField.format?.extension ? PHONE_EXTENSION_MASK : ''
        }`;

  const getCurrentValueWithoutPlaceholder = useCallback(
    (currentValue: string) => {
      // When using lazy:false, the value will include the placeholder. It doesn't seem possible to get the value without the placeholder
      // So here we run the mask again without lazy manually to get the phone number without the placeholder characters
      const phoneMask = IMask.createPipe({
        // We can add Number or string to the mask, but if we add a variable that is typed as Number of string typescript complains. A problem with the library types?
        mask: mask as any
      });
      return phoneMask(currentValue);
    },
    [mask]
  );

  const { ref: inputRef } = useIMask<HTMLTextAreaElement>(
    {
      mask: mask as any,
      lazy: currentField.format?.format === 'any',
      placeholderChar: '_'
    },
    {
      onAccept: (val) => {
        if (firstOnAcceptCall.current) {
          firstOnAcceptCall.current = false;
          return;
        }

        isDirty.current = true;
        clearCellErrors(rowId, fieldId);

        const valueWithoutPlaceholder = getCurrentValueWithoutPlaceholder(val);
        setLocalValue(val);

        if (!selectedCell.isEditing) {
          setIsEditing(true);
        }

        void saveCell(rowId, fieldId, valueWithoutPlaceholder, {
          value: valueWithoutPlaceholder,
          rawValue: valueWithoutPlaceholder
        });
      }
    }
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab') {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter') {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  return (
    <div className="size-full">
      <TextareaCell
        ref={inputRef}
        defaultValue={localValue}
        intent={cellErrors ? 'destructive' : undefined}
        className={cn({
          'opacity-0': !selectedCell.isEditing
        })}
        onFocus={(e) => {
          // We save the initial cursor position, so we can restore it when the user clicks on the input
          // This is needed because when the user clicks on the input, the cursor goes to the end of the text mask
          initialCursorPosition.current = e.currentTarget.selectionStart;
        }}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);

            e?.currentTarget?.setSelectionRange(
              initialCursorPosition.current,
              initialCursorPosition.current
            );
          }
        }}
        data-testid={`edit-phone-input-${rowId}-${fieldId}`}
      />
      <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="phone-edit-error" />
    </div>
  );
}
