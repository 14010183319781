import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputSearch } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type DetailsView, type DetailsViewInput } from '@/types/schema/views/DetailsView';
import { type ListView, type ListViewInput } from '@/types/schema/views/ListView';
import { generateListViewFieldInput } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/listViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { ViewDetailsAddStaticInputButton } from './ViewDetailsAddStaticInputButton';
import { ViewDetailsFieldInputItem } from './ViewDetailsFieldInputItem';

export type ViewWithDetails = ListView | DetailsView;
export type ViewWithDetailsInput = ListViewInput | DetailsViewInput;

export function ViewDetailsFieldManagement() {
  const [t] = useTranslation();
  const updateViewSchema = useUpdateView<ViewWithDetails>();
  const { view, sourceObject } = useActiveViewContext<ViewWithDetails>();
  const [visibleFields, setVisibleFields] = useState<KnackField[]>(sourceObject.fields);
  const [searchValue, setSearchValue] = useState('');

  // The field inputs that have been already added to the view
  const activeFieldInputs = useMemo(
    () =>
      view.columns.reduce<ViewWithDetailsInput[]>((acc, outerColumn) => {
        outerColumn.groups.forEach((group) => {
          group.columns.forEach((column) => {
            column.forEach((input) => {
              if (input.type === 'field') {
                acc.push(input);
              }
            });
          });
        });
        return acc;
      }, []),
    [view.columns]
  );

  const onAddFieldInput = (field: KnackField) => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        // Add the new field input to the last column in the last group
        groups: outerColumn.groups.map((group, groupIndex) => {
          if (groupIndex === view.columns[0].groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return [...column, generateListViewFieldInput(field)];
                }
                return column;
              })
            };
          }
          return group;
        })
      }))
    };

    updateViewSchema(updatedView);
  };

  const onRemoveFieldInput = (field: KnackField) => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        groups: outerColumn.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) => column.filter((input) => input.key !== field.key))
        }))
      }))
    };

    updateViewSchema(updatedView);
  };

  const onAddAllFieldInputs = () => {
    const inputsToAdd: ViewWithDetailsInput[] = [];

    // Create inputs for all the fields that are not already in the view
    sourceObject.fields.forEach((field) => {
      if (activeFieldInputs.some((input) => input.key === field.key)) {
        return;
      }

      inputsToAdd.push(generateListViewFieldInput(field));
    });

    const updatedView: Partial<ViewWithDetails> = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        // Add the new field inputs to the last column in the last group
        groups: outerColumn.groups.map((group, groupIndex) => {
          if (groupIndex === view.columns[0].groups.length - 1) {
            return {
              ...group,
              columns: group.columns.map((column, columnIndex) => {
                if (columnIndex === group.columns.length - 1) {
                  return [...column, ...inputsToAdd];
                }
                return column;
              })
            };
          }
          return group;
        })
      }))
    };

    updateViewSchema(updatedView);
  };

  const onRemoveAllFieldInputs = () => {
    const updatedView: Partial<ViewWithDetails> = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        groups: outerColumn.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) =>
            column.filter(
              (input) =>
                input.type !== 'field' || !visibleFields.some((field) => input.key === field.key)
            )
          )
        }))
      }))
    };

    updateViewSchema(updatedView);
  };

  const onSearch = (value: string) => {
    const trimmedValue = value.trim().toLowerCase();
    setSearchValue(trimmedValue);

    if (trimmedValue === '') {
      setVisibleFields(sourceObject.fields);
      return;
    }

    setVisibleFields(
      sourceObject.fields.filter((field) => field.name.toLowerCase().includes(trimmedValue))
    );
  };

  return (
    <>
      <div className="mb-4">
        <div className="mb-2 flex items-center gap-1">
          <span className="text-xs font-medium text-emphasis">
            {t(
              'pages.element_settings.common.categories.data_display.field_management.manage_fields'
            )}
          </span>
          <span className="rounded-sm bg-subtle px-1 py-0.5 text-xs font-medium text-default">
            {t(
              'pages.element_settings.common.categories.data_display.field_management.active_inputs_count',
              {
                currentCount: activeFieldInputs.length,
                totalCount: sourceObject.fields.length
              }
            )}
          </span>
        </div>
        <p className="mb-4 text-xs text-subtle">
          {t(
            'pages.element_settings.common.categories.data_display.field_management.manage_fields_description'
          )}
        </p>

        <InputSearch
          className="mb-4"
          aria-label={t(
            'pages.element_settings.common.categories.data_display.field_management.search_fields'
          )}
          placeholder={t(
            'pages.element_settings.common.categories.data_display.field_management.search_fields'
          )}
          value={searchValue}
          onChange={(e) => onSearch(e.target.value)}
        />

        <div className="mb-4 flex gap-3">
          <Button
            disabled={
              visibleFields.length === activeFieldInputs.length || visibleFields.length === 0
            }
            intent="link"
            className="text-xs"
            onClick={onAddAllFieldInputs}
          >
            {t('pages.element_settings.common.categories.data_display.field_management.add_all')}
          </Button>
          <Button
            disabled={visibleFields.length === 0 || activeFieldInputs.length === 0}
            intent="link"
            className="text-xs"
            onClick={onRemoveAllFieldInputs}
          >
            {t('pages.element_settings.common.categories.data_display.field_management.remove_all')}
          </Button>
        </div>

        {visibleFields.length > 0 && (
          <div className="space-y-2">
            {visibleFields.map((field) => {
              const fieldInput = activeFieldInputs.find((input) => input.key === field.key);

              return (
                <ViewDetailsFieldInputItem
                  key={field.key}
                  field={field}
                  fieldInput={fieldInput}
                  isChecked={fieldInput?.key === field.key}
                  onCheck={(isChecked) => {
                    if (isChecked) {
                      onAddFieldInput(field);
                    } else {
                      onRemoveFieldInput(field);
                    }
                  }}
                />
              );
            })}
          </div>
        )}
      </div>

      <div className="sticky -bottom-6 -mb-6 mt-auto border-t border-t-subtle bg-muted py-4">
        <ViewDetailsAddStaticInputButton />
      </div>
    </>
  );
}
