import { type ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare as LinkIcon } from 'react-icons/hi2';

import { cn } from '@/utils/tailwind';

interface LearnMoreLinkProps extends ComponentPropsWithoutRef<'a'> {
  href: string;
}

export function LearnMoreLink({ href, className, target, rel, children }: LearnMoreLinkProps) {
  const [t] = useTranslation();

  return (
    <a
      href={href}
      target={target || '_blank'}
      rel={rel || 'noreferrer'}
      className={cn('inline-flex items-center text-emphasis', className)}
    >
      <span className="underline">{children || t('actions.learn_more')}</span>
      <LinkIcon size={14} className="ml-1 shrink-0" />
    </a>
  );
}
