import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label, RadioGroup, Select } from '@knack/asterisk-react';
import { DateTime } from 'luxon';

import { type DateFormatValue } from '@/types/schema/fields';
import { RepeatsOnOptions } from '@/components/data-table/display/fields/date-time/components/RepeatsOnOptions';
import { type RepeatOptions as RepeatOptionsType } from '@/components/data-table/display/fields/Field';
import { selectPeriodOption, type SaveDateTimeCellValue, type SelectPeriodOption } from './types';

interface RepeatOptionsProps {
  repeatOptions?: RepeatOptionsType;
  selectedDate?: string;
  dateFormat: DateFormatValue;
  saveCell: (value: SaveDateTimeCellValue) => void;
  rowId: string;
  fieldId: string;
}

const DEFAULT_INTERVAL_VALUE = '1';

export const DEFAULT_REPEAT_OPTIONS: RepeatOptionsType = {
  frequency: 'daily',
  interval: DEFAULT_INTERVAL_VALUE,
  SU: false,
  MO: false,
  TU: false,
  WE: false,
  TH: false,
  FR: false,
  SA: false,
  repeatby: 'dom',
  endson: 'never',
  end_count: '',
  end_date: ''
};

export function RepeatOptions({
  repeatOptions,
  selectedDate,
  dateFormat,
  saveCell,
  rowId,
  fieldId
}: RepeatOptionsProps) {
  const [t] = useTranslation();
  const [endInputValue, setEndInputValue] = useState(repeatOptions?.end_date || '');
  const [intervalValue, setIntervalValue] = useState(
    repeatOptions?.interval || DEFAULT_INTERVAL_VALUE
  );

  const repeatOptionFrequency = {
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
    yearly: 'years'
  } as const;

  const isNotInteger = (value: string): boolean => !/^\d+$/.test(value);
  const isNotDate = (date: string): boolean => !/^[0-9/]*$/.test(date);

  const handleEndsOnDateInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isNotDate(value)) {
      return;
    }

    setEndInputValue(value);

    const isValid =
      DateTime.fromFormat(value, dateFormat).isValid ||
      DateTime.fromFormat(value, dateFormat?.replace('yyyy', 'yy')).isValid;

    if (isValid && repeatOptions) {
      await saveCell({
        repeat: {
          ...repeatOptions,
          end_date: value
        }
      });
    }
  };

  const handleRepeatOptionChange = async (key: 'frequency' | 'interval', optionValue: string) => {
    const isChangingFrequency = key === 'frequency';

    if (key === 'interval') {
      if (optionValue !== '' && isNotInteger(optionValue)) {
        setIntervalValue(DEFAULT_INTERVAL_VALUE);
        return;
      }

      setIntervalValue(optionValue);
    }

    if (repeatOptions) {
      await saveCell({
        repeat: {
          ...repeatOptions,
          [key]: optionValue,
          ...(isChangingFrequency && {
            SU: false,
            MO: false,
            TU: false,
            WE: false,
            TH: false,
            FR: false,
            SA: false
          })
        }
      });
    }
  };

  const handleEndOptionChange = async (optionValue: 'date' | 'limit' | 'never') => {
    if (repeatOptions) {
      await saveCell({
        repeat: {
          ...repeatOptions,
          endson: optionValue
        }
      });
    }
  };

  const handleEndsOnOccurrencesInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isNotInteger(value)) {
      return;
    }

    if (repeatOptions) {
      await saveCell({
        repeat: {
          ...repeatOptions,
          end_count: value
        }
      });
    }
  };

  const handleResetInputOnBlur = async () => {
    if (!!repeatOptions && repeatOptions?.interval === '') {
      await saveCell({
        repeat: {
          ...repeatOptions,
          interval: '1'
        }
      });
    }
  };

  return (
    <div className="space-y-4 pl-1 pt-2" data-testid={`repeat-options-${rowId}-${fieldId}`}>
      <p className="font-semibold">
        {t('components.data_table.attributes.field_labels.date_time.repeats')}:
      </p>
      <div className="flex items-center gap-2">
        <div className="w-full items-center gap-2 sm:flex">
          <div className="flex items-center gap-2">
            <Select
              defaultValue="daily"
              value={repeatOptions?.frequency}
              onValueChange={(value) => handleRepeatOptionChange('frequency', value)}
            >
              <Select.Trigger className="w-full" />
              <Select.Content>
                {Object.keys(selectPeriodOption()).map((option) => (
                  <Select.Item value={option} key={option}>
                    {selectPeriodOption()[option as SelectPeriodOption]}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
            <span>{t('components.data_table.attributes.field_labels.date_time.every')}</span>
            <Input
              className="w-16"
              size="sm"
              value={intervalValue}
              onChange={(e) => handleRepeatOptionChange('interval', e.target.value)}
              onBlur={handleResetInputOnBlur}
            />
            {repeatOptions?.frequency
              ? t(
                  `components.data_table.attributes.field_labels.date_time.${
                    repeatOptionFrequency[repeatOptions.frequency]
                  }`
                )
              : t('components.data_table.attributes.field_labels.date_time.days')}
          </div>
        </div>
      </div>
      {(repeatOptions?.frequency === 'weekly' || repeatOptions?.frequency === 'monthly') && (
        <div className="flex w-full flex-col gap-2">
          <p className="font-semibold">
            {t('components.data_table.attributes.field_labels.date_time.repeats_on')}
          </p>
          <RepeatsOnOptions repeatOptions={repeatOptions} saveCell={saveCell} />
        </div>
      )}
      <div className="flex w-full items-center gap-2">
        <p className="font-semibold">
          {t('components.data_table.attributes.field_labels.date_time.starts_on')}
        </p>
        {selectedDate || ''}
      </div>
      <div className="flex-col gap-2 sm:flex">
        <p className="mb-2 font-semibold">
          {t('components.data_table.attributes.field_labels.date_time.ends')}
        </p>
        <RadioGroup
          onValueChange={handleEndOptionChange}
          defaultValue={repeatOptions?.endson || 'never'}
          className="space-y-1"
        >
          <RadioGroup.Container className="flex gap-4">
            <RadioGroup.Item id="never" value="never" />
            <Label htmlFor="never">
              {t('components.data_table.attributes.field_labels.date_time.never')}
            </Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="limit" />
            <Label htmlFor="after" className="flex items-center gap-2">
              {t('components.data_table.attributes.field_labels.date_time.after')}{' '}
              <Input
                type="number"
                onChange={handleEndsOnOccurrencesInputChange}
                className="w-16 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                size="sm"
                disabled={repeatOptions?.endson !== 'limit'}
              />
              {t('components.data_table.attributes.field_labels.date_time.occurrences')}
            </Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="date" />
            <Label htmlFor="on" className="space-x-2">
              <span>{t('components.data_table.attributes.field_labels.date_time.on')}</span>
            </Label>
            {repeatOptions?.endson === 'date' && (
              <Input
                value={endInputValue}
                onChange={handleEndsOnDateInputChange}
                className="w-[120px]"
                disabled={repeatOptions?.endson !== 'date'}
                placeholder={dateFormat}
              />
            )}
          </RadioGroup.Container>
        </RadioGroup>
      </div>
    </div>
  );
}
