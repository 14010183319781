import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import {
  type AverageField,
  type MaxField,
  type MinField,
  type SumField
} from '@/types/schema/fields';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackConnection } from '@/types/schema/KnackObject';
import { NUMERIC_FIELD_TYPES } from '@/hooks/helpers/useFieldHelpers';
import { useApplication } from '@/hooks/useApplication';

type AggregateFieldSelectorProps = {
  field: AggregateField;
  aggregateFieldConnections: KnackConnection[];
};

type AggregateField = SumField | MaxField | MinField | AverageField;

export function AggregateFieldSelector({
  field,
  aggregateFieldConnections
}: AggregateFieldSelectorProps) {
  const [t] = useTranslation();

  const application = useApplication();
  const knackObjects = application?.objects;

  const { setValue, getValues } = useFormContext();

  const connectedTables = useMemo(
    // get the tables that are in the aggregateFieldConnections
    () =>
      knackObjects?.filter((object) =>
        aggregateFieldConnections?.some((conn) => conn.object === object.key)
      ),
    [knackObjects, aggregateFieldConnections]
  );

  const numericConnectedFields = useMemo(() => {
    if (!connectedTables) return [];

    const connectedFields: (KnackField & {
      tableKey: string;
      tableName: string;
      connectionFieldName: string;
    })[] = [];
    connectedTables.forEach((table) => {
      table.fields.forEach((connectedField) => {
        if (NUMERIC_FIELD_TYPES.includes(connectedField?.type)) {
          connectedFields.push({
            ...connectedField,
            tableKey: table.key,
            tableName: table.name,
            connectionFieldName:
              aggregateFieldConnections?.find((conn) => conn.object === table.key)?.name || ''
          });
        }
      });
    });
    return connectedFields;
  }, [connectedTables, aggregateFieldConnections]);

  // Initialize the connection field on the Add new field form
  useEffect(() => {
    if (!getValues('format.connection')) {
      setValue('format.connection.key', numericConnectedFields[0]?.tableKey);
    }

    if (!getValues('format.field.key')) {
      setValue('format.field.key', numericConnectedFields[0]?.key);
    }
  }, [numericConnectedFields, setValue, getValues]);

  return (
    numericConnectedFields?.length > 0 && (
      <div className="flex flex-col gap-2">
        <Label htmlFor="connection-item-value">
          {t('components.data_table.attributes.field_settings.aggregate.field_to_type_label', {
            type: field?.type
          })}
        </Label>
        <Select
          defaultValue={field?.format?.field?.key || numericConnectedFields[0]?.key}
          onValueChange={(value) => {
            setValue('format.field.key', value);
          }}
        >
          <Select.Trigger className="w-full truncate" />
          <Select.Content>
            {numericConnectedFields?.map((f) => (
              <Select.Item key={f.key} value={f.key} className="truncate">
                {`${f.tableName} (${f.connectionFieldName}) > ${f.name}`}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    )
  );
}
