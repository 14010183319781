import {
  jSignatureBase30Decode,
  jSignatureBase30Encode
} from '@/components/data-table/display/fields/signature/utils/jSignatureBase30';

type JSignaturePoints = { x: number[]; y: number[] }[];

export function signaturePadToJSignature(signaturePadPoints: SignaturePad.Point[][]) {
  // Convert from signaturePad format to jSignature base30 format
  const jSignatureFormat: JSignaturePoints = [];
  signaturePadPoints?.forEach((stroke, index) => {
    stroke.forEach((points) => {
      if (!jSignatureFormat[index]) {
        jSignatureFormat[index] = { x: [], y: [] };
      }
      jSignatureFormat[index].x.push(points.x);
      jSignatureFormat[index].y.push(points.y);
    });
  });
  // We are using the old jSignature format to save the points in the base30 parameter to ensure retro-compatibility.
  return jSignatureBase30Encode(jSignatureFormat);
}

export function jSignatureToSignaturePad(base30: string) {
  // Convert from jSignature base30 format to signaturePad
  const jSignatureFormat = jSignatureBase30Decode(base30);
  const dataPoints: SignaturePad.Point[][] = [];

  jSignatureFormat.forEach((el, index) => {
    if (!dataPoints[index]) dataPoints[index] = [];
    dataPoints[index] = el.x.map((ele: number, index2: number) => ({
      x: ele,
      y: el.y[index2],
      time: index2,
      color: 'black'
    }));
  });

  return dataPoints;
}
