import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { CONNECTION_DEFAULT_OPTIONS } from '@/types/schema/fields';

export function DefaultValue() {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="default-select">
        {t('components.data_table.attributes.field_settings.connection.default_value_label')}
      </Label>
      <Select
        onValueChange={(value) => {
          setValue('format.conn_default', value);
        }}
        defaultValue={getValues('format.conn_default') || CONNECTION_DEFAULT_OPTIONS[0]}
        value={getValues('format.conn_default')}
      >
        <Select.Trigger
          id="default-select"
          placeholder={t('actions.select')}
          className="w-full rounded-lg"
          data-testid="connection-form-settings-default-select"
        />
        <Select.Content>
          {CONNECTION_DEFAULT_OPTIONS.map((option) => (
            <Select.Item
              key={option}
              value={option}
              data-testid={`connection-field-settings-default-value-${option}`}
            >
              {t(
                `components.data_table.attributes.field_settings.connection.default_value_options.${option}`
              )}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </div>
  );
}
