import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackTask, type TaskKey } from '@/types/schema/tasks/KnackTask';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type CreateTaskParams = {
  tableKey: KnackObject['key'];
  taskData: KnackTask;
};

type SortTaskParams = {
  tableKey: KnackObject['key'];
  taskOrder: TaskKey[];
};

type EditTaskParams = {
  tableKey: KnackObject['key'];
  taskKey: TaskKey;
  taskData: KnackTask;
};

type DeleteTaskParams = {
  tableKey: KnackObject['key'];
  taskKey: TaskKey;
};

type RunTaskJobParams = {
  appId: string | undefined;
  taskKey: TaskKey;
};

type RunTaskJobResponse = {
  success: boolean;
  jobId: string;
};

async function createTask({ tableKey, taskData }: CreateTaskParams) {
  const endpoint = `/v1/objects/${tableKey}/tasks`;
  const { data } = await axios.post(endpoint, taskData, { withCredentials: true });
  return data;
}

async function sortTask({ tableKey, taskOrder }: SortTaskParams) {
  const endpoint = `/v1/objects/${tableKey}/tasks/sort`;
  const { data } = await axios.post(endpoint, { order: taskOrder }, { withCredentials: true });
  return data;
}

async function editTask({ tableKey, taskKey, taskData }: EditTaskParams) {
  const endpoint = `/v1/objects/${tableKey}/tasks/${taskKey}`;
  const { data } = await axios.put(endpoint, taskData, { withCredentials: true });
  return data;
}

async function deleteTask({ tableKey, taskKey }: DeleteTaskParams) {
  const endpoint = `/v1/objects/${tableKey}/tasks/${taskKey}`;
  const { data } = await axios.delete(endpoint, { withCredentials: true });
  return data;
}

async function getRunTaskJob({ appId, taskKey }: RunTaskJobParams): Promise<RunTaskJobResponse> {
  const { data } = await axios.get(`/v1/applications/${appId}/tasks/${taskKey}/runtaskjob`, {
    withCredentials: true
  });

  return data;
}

export function useTaskMutation() {
  const queryClient = useQueryClient();
  const { data: app } = useApplicationQuery();

  return {
    addMutation: useMutation({
      mutationFn: createTask,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.tasks] });
      }
    }),
    sortMutation: useMutation({
      mutationFn: sortTask,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.tasks] });
      }
    }),
    editMutation: useMutation({
      mutationFn: editTask,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.tasks] });
      }
    }),
    deleteMutation: useMutation({
      mutationFn: deleteTask,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.tasks] });
      }
    }),
    runTaskJobMutation: useMutation({
      mutationFn: ({ taskKey }: { taskKey: TaskKey }) => getRunTaskJob({ appId: app?.id, taskKey }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.tasks] });
      }
    })
  };
}
