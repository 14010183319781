import { useEffect, useState } from 'react';
import { HiPlus as PlusIcon, HiUser as UserIcon } from 'react-icons/hi2';
import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Button, Dialog, Divider, InputSearch } from '@knack/asterisk-react';
import { t } from 'i18next';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useDndUtils } from '@/hooks/useDndUtils';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { CreateRoleDialog } from '@/pages/roles/CreateRoleDialog';
import { RoleDropdownMenu } from '@/pages/roles/role-dropdown-menu/RoleDropdownMenu';
import { TableTreeItem } from '@/pages/tables/tables-tree/TablesTreeItem';
import { ROUTES } from '@/Router';

export function UserRolesTableTree() {
  const { data: app } = useApplicationQuery();
  const [search, setSearch] = useState('');
  const [filteredTables, setFilteredTables] = useState<KnackObject[]>([]);
  const { sortMutation } = useTableMutation();
  const [createTableDialogOpen, setCreateTableDialogOpen] = useState(false);

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  function handleSortTables(tables: KnackObject[]) {
    if (tables) {
      const newTableOrder = tables.map((table: KnackObject) => table.key);
      sortMutation.mutate(newTableOrder);
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = filteredTables.findIndex((table) => table.key === active.id);
      const newIndex = filteredTables.findIndex((table) => table.key === over.id);
      const newTables = arrayMove(filteredTables, oldIndex, newIndex);
      setFilteredTables(newTables);
      handleSortTables(newTables);
    }
  };

  const handleAddRoleButton = () => {
    setCreateTableDialogOpen(true);
  };

  useEffect(() => {
    const filtered =
      app?.objects.filter(
        (table) =>
          table.name.toLowerCase().includes(search.toLowerCase()) && table.type === 'UserObject'
      ) || [];

    const otherTables = filtered.filter((table) => table.profile_key !== 'all_users');

    setFilteredTables(otherTables);
  }, [search, app]);
  return (
    <Dialog open={createTableDialogOpen} onOpenChange={setCreateTableDialogOpen}>
      <CollapsiblePanel.Content
        data-testid="table-list-container"
        title={t('components.layout.left_sidebar.users')}
        shouldHideDivider
      >
        <div className="sticky top-0 z-10 -mt-1 mb-1 bg-muted pb-4 pt-1">
          <InputSearch
            aria-label={t('keywords.search')}
            className="w-full"
            value={search}
            placeholder={t('keywords.search')}
            onChange={(e) => setSearch(e.target.value)}
            data-testid="search-table-input"
          />
        </div>
        <Divider className="mb-4" />
        <Button
          intent="secondary"
          className="w-full"
          data-testid="add-role-button"
          onClick={handleAddRoleButton}
        >
          <PlusIcon size={20} data-testid="add-data-icon" className="mr-1" />
          {t('components.roles_list.role')}
        </Button>
        <div className="flex flex-col gap-1 pt-4" data-testid="tables-list">
          {app?.objects
            .filter((table) => table.profile_key === 'all_users')
            .map((table) => (
              <TableTreeItem
                key={table.key}
                route={ROUTES.ROLES_ID}
                table={table}
                Icon={UserIcon}
                isSortingDisabled
                dropdownMenuComponent={RoleDropdownMenu}
              />
            ))}
          <Divider className="my-4" />
          <div>
            <DndContext
              sensors={optimizedSensors}
              collisionDetection={verticalListCollisionDetection}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext items={filteredTables.map((table) => table.key)}>
                {filteredTables.map((table) => (
                  <TableTreeItem
                    key={table.key}
                    route={ROUTES.ROLES_ID}
                    table={table}
                    Icon={UserIcon}
                    dropdownMenuComponent={RoleDropdownMenu}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </CollapsiblePanel.Content>
      <Dialog.Content data-testid="table-dropdown-menu-dialog-content">
        <Dialog.MainContent>
          <CreateRoleDialog setCreateTableDialogOpen={setCreateTableDialogOpen} />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
