import { Controller } from 'react-hook-form';
import { Rating } from '@knack/asterisk-react';

import { type RatingField } from '@/types/schema/fields';

type RatingInputProps = {
  targetField: RatingField;
  name: string;
};

export function RatingInput(props: RatingInputProps) {
  const {
    targetField: { format },
    name
  } = props;

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Rating
          ratingNumber={field.value}
          onChange={field.onChange}
          maxRatingNumber={Number(format?.max ?? 3)}
          allowHalf={format?.allow_half ?? false}
        />
      )}
    />
  );
}
