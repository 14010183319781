import { nanoid } from 'nanoid';

import { type BuilderPageColumn } from '@/types/schema/BuilderPage';
import { NEW_COLUMN_ID_PREFIX } from './constants';

type GenerateNewColumnOptions = {
  width?: BuilderPageColumn['width'];
  viewKeys?: BuilderPageColumn['viewKeys'];
};

export function generateNewColumn({ width, viewKeys }: GenerateNewColumnOptions = {}) {
  const newColumn: BuilderPageColumn = {
    id: `${NEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
    viewKeys: viewKeys ?? [],
    width: width ?? '100%'
  };

  return newColumn;
}
