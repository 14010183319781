export function setCursorPositionAtTheEnd(event) {
  event?.currentTarget?.setSelectionRange(
    event.currentTarget.value.length,
    event.currentTarget.value.length
  );
}

export function setCursorPositionFullText(event) {
  event?.currentTarget?.setSelectionRange(0, event.currentTarget.value.length);
}
