import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleValues
} from '@/types/schema/KnackField';

export const EMAIL_TEXT_FORMATS = ['url', 'same', 'unique'] as const;

export interface EmailField extends BaseKnackField {
  type: 'email';
  format?: {
    text_format: (typeof EMAIL_TEXT_FORMATS)[number];
    label?: string;
  };
  rules: EmailFieldConditionalRule[];
}

interface EmailFieldConditionalRule extends ConditionalRule {
  values: EmailFieldConditionalRuleValues[];
}

interface EmailFieldConditionalRuleValues extends ConditionalRuleValues {
  value: EmailFieldConditionalRuleValue;
}

export interface EmailFieldConditionalRuleValue {
  email: string;
}
