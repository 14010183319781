import { t } from 'i18next';
import { z } from 'zod';

import { type TimerField } from '@/types/schema/fields';
import { dateRegex, timeRegex } from '@/components/data-table/helpers/zod';

export function getDurationSchema(field: TimerField) {
  const { date_format: dateFormat, time_format: timeFormat } = field.format;

  const dateFormatRegex = dateFormat ? dateRegex[dateFormat] : dateRegex['mm/dd/yyyy'];
  const timeFormatRegex = timeFormat ? timeRegex[timeFormat] : timeRegex['HH:MM am'];
  return z.object({
    startDate: z.string().regex(dateFormatRegex, {
      message: t('components.data_table.errors.no_valid_field', { field: 'start date' })
    }),
    startTime: z.string().regex(timeFormatRegex, {
      message: t('components.data_table.errors.no_valid_field', { field: 'start time' })
    }),
    endDate: z.string().regex(dateFormatRegex, {
      message: t('components.data_table.errors.no_valid_field', { field: 'end date' })
    }),
    endTime: z.string().regex(timeFormatRegex, {
      message: t('components.data_table.errors.no_valid_field', { field: 'end time' })
    })
  });
}
