import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleValues
} from '@/types/schema/KnackField';

export const ADDRESS_INPUT_OPTIONS = ['address', 'lat_long'] as const;
export const FORMAT_OPTIONS = ['US', 'international', 'international_country'] as const;

export type AddressFormatOptions = (typeof FORMAT_OPTIONS)[number];

export interface AddressField extends BaseKnackField {
  type: 'address';
  format: {
    enable_address_autocomplete?: boolean;
    enable_autocomplete?: boolean;
    enable_geocoding?: boolean;
    format?: AddressFormatOptions;
    input?: (typeof ADDRESS_INPUT_OPTIONS)[number];
  };
  rules: AddressFieldConditionalRule[];
}

interface AddressFieldConditionalRule extends ConditionalRule {
  values: AddressFieldConditionalRuleValues[];
}

export interface AddressFieldConditionalRuleValues extends ConditionalRuleValues {
  type: 'current_location' | 'value' | 'record';
  value: AddressFieldConditionalRuleValue;
}

export interface AddressFieldConditionalRuleValue {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  latitude?: string;
  longitude?: string;
}
