import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

interface TaskDeleteModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  handleDeleteTask: () => void;
}

export function TaskDeleteModal({ isOpen, onOpenChange, handleDeleteTask }: TaskDeleteModalProps) {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content data-testid="delete-task-modal">
        <Dialog.MainContent className="pb-0">
          <Dialog.Header>
            <Dialog.Title>
              {t('components.data_table.right_sidebar.tasks.delete_task.title')}
            </Dialog.Title>
          </Dialog.Header>
          <div className="mt-6">
            <p>
              <Trans i18nKey="components.data_table.right_sidebar.tasks.delete_task.description">
                <span className="font-semibold">everywhere</span>
                <br />
                <span className="font-semibold">This cannot be undone</span>
              </Trans>
            </p>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer className="gap-2">
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button
            intent="destructive"
            onClick={handleDeleteTask}
            data-testid="confirm-delete-task-button"
          >
            {t('actions.delete')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
