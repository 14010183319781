import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  HiExclamationCircle as ExclamationIcon,
  HiTableCells as TableIcon,
  HiUser as UserIcon
} from 'react-icons/hi2';
import { Banner, Button, Checkbox, Chip, Form, InputSearch, Label } from '@knack/asterisk-react';
import isEmpty from 'lodash.isempty';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { FieldIcon } from '@/components/FieldIcon';
import { CopyTableOptions } from './DuplicateTableForm';

interface ConfirmCopyStepProps {
  table: KnackObject;
  copyTableOption: CopyTableOptions;
  selectedTableKey: string | null;
  app: BuilderApplication;
  selectNoneFields: () => void;
  handleBack: () => void;
  selectAllFields: () => void;
  isUser?: boolean;
  selectedFields: string[];
}

export function ConfirmCopyStep({
  table,
  copyTableOption,
  selectedTableKey,
  app,
  selectNoneFields,
  selectAllFields,
  handleBack,
  isUser,
  selectedFields
}: ConfirmCopyStepProps) {
  const [t] = useTranslation();

  const { control, formState } = useFormContext();
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredFields = table.fields.filter((field) =>
    field.name.toLowerCase().includes(searchValue)
  );

  const getIcon = () => {
    if (isUser) {
      return <UserIcon size={16} />;
    }
    return <TableIcon size={16} />;
  };

  const isFieldDisabled = (field: KnackField) => field.user;

  const renderCopyMessage = () => {
    if (isUser) {
      return <span>{t('components.dialogs.roles.copy.into_new_role')}</span>;
    }

    if (copyTableOption === CopyTableOptions.NewTable) {
      return <span>{t('components.dialogs.tables.duplicate.fields_into_new_table')}</span>;
    }

    return (
      <>
        <span>{t('components.dialogs.tables.duplicate.fields_into')}</span>
        <div className="flex items-center gap-1 rounded bg-subtle px-1 py-0.5">
          {getIcon()}
          <span className="max-w-xs truncate">
            {app?.objects.find((object) => object.key === selectedTableKey)?.name}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className="space-y-4">
      {!isEmpty(formState.errors) && (
        <Banner closeMode="text" intent="destructive" icon={ExclamationIcon} type="inline">
          <Banner.Message>{t('components.dialogs.tables.duplicate.please_correct')}</Banner.Message>
          <ul className="ml-2 list-disc">
            {Object.values(formState.errors).map((error, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{`${error?.message}`}</li>
            ))}
          </ul>
        </Banner>
      )}
      <div className="flex">
        <div className="flex flex-wrap items-center gap-1">
          <span>{t('components.dialogs.tables.duplicate.copying')}</span>
          <div className="flex items-center gap-1 rounded bg-subtle px-1 py-0.5">
            {getIcon()}

            <span className="max-w-xs truncate">{table.name}</span>
          </div>
          {renderCopyMessage()}
        </div>
        <Button intent="minimalStandalone" className="ml-auto underline" onClick={handleBack}>
          {t('components.dialogs.tables.duplicate.change')}
        </Button>
      </div>
      <InputSearch
        aria-label={t('keywords.search')}
        className="w-full"
        value={searchValue}
        placeholder={t(
          'pages.element_settings.form.categories.form_fields.field_management.search_fields'
        )}
        onChange={(e) => setSearchValue(e.target.value)}
        data-testid="search-field-input"
      />
      <div>
        {!searchValue && (
          <div className="flex">
            <div className="flex w-full items-center justify-between">
              <div className="flex gap-2">
                <Button intent="minimalStandalone" className="underline" onClick={selectAllFields}>
                  {t('components.dialogs.tables.duplicate.select_all')}
                </Button>
                <Button
                  intent="minimalStandalone"
                  className="flex items-center underline"
                  onClick={selectNoneFields}
                >
                  <span>{t('components.dialogs.tables.duplicate.select_none')}</span>
                  <span className="ml-1">{`(${selectedFields.length})`}</span>
                </Button>
              </div>
            </div>
          </div>
        )}

        <Form.Section className="flex max-h-56 flex-col gap-1 overflow-auto">
          {filteredFields.map((field: KnackField) => (
            <Controller
              key={field.key}
              name="fieldsToCopy"
              control={control}
              render={({ field: formField }) => (
                <Button asChild intent="secondary">
                  <Label
                    className="flex items-center justify-start gap-2"
                    htmlFor={`field-${field.key}`}
                  >
                    <Checkbox
                      id={`field-${field.key}`}
                      disabled={isFieldDisabled(field)}
                      checked={formField.value.includes(field.key)}
                      onCheckedChange={(checked) => {
                        const newValues = checked
                          ? [...formField.value, field.key]
                          : formField.value.filter((k) => k !== field.key);
                        formField.onChange(newValues);
                      }}
                    />
                    <FieldIcon name={field.type} size={16} className="shrink-0" />
                    <span className="text-sm font-normal">{field.name}</span>
                    {isFieldDisabled(field) && (
                      <div className="ml-auto">
                        <Chip size="sm">
                          {t('components.data_table.attributes.field_settings.common.required')}
                        </Chip>
                      </div>
                    )}
                  </Label>
                </Button>
              )}
            />
          ))}
        </Form.Section>
      </div>
    </div>
  );
}
