import {
  type BaseKnackField,
  type ConditionalRule,
  type ConditionalRuleValues
} from '@/types/schema/KnackField';

export const BOOLEAN_FORMAT_OPTIONS = ['yes_no', 'on_off', 'true_false'] as const;
export const BOOLEAN_INPUT_OPTIONS = ['dropdown', 'checkbox', 'radios'] as const;

export interface BooleanField extends BaseKnackField {
  type: 'boolean';
  default: boolean;
  format?: {
    default: boolean;
    format: (typeof BOOLEAN_FORMAT_OPTIONS)[number];
    input: (typeof BOOLEAN_INPUT_OPTIONS)[number];
    required: boolean;
    text?: string;
  };
  rules: BooleanFieldConditionalRule[];
}

interface BooleanFieldConditionalRule extends ConditionalRule {
  values: BooleanFieldConditionalRuleValues[];
}

interface BooleanFieldConditionalRuleValues extends ConditionalRuleValues {
  // Due to a bug in Knack, the value of a `false` boolean field conditional rule can be an empty string.
  value: boolean | '';
}
