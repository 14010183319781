import { useTranslation } from 'react-i18next';

import { type ValidationRule } from '@/types/schema/KnackField';

type FormHeaderProps = {
  rule?: ValidationRule;
  ruleToEdit?: ValidationRule;
};

export function FormHeader(props: FormHeaderProps) {
  const { rule, ruleToEdit } = props;

  const [t] = useTranslation();

  const getActionLabel = () => {
    if (!rule) {
      return t('components.data_table.right_sidebar.validation_rules.new');
    }
    if (ruleToEdit) {
      return t('actions.edit');
    }
    return t('actions.duplicate');
  };

  return (
    <h4 className="text-sm font-semibold">
      {t('components.data_table.right_sidebar.validation_rules.validation_rule_form_header', {
        action: getActionLabel()
      })}
    </h4>
  );
}
