import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';

import { type BooleanField } from '@/types/schema/fields/BooleanField';
import { cn } from '@/utils/tailwind';
import { OPTIONS_BY_FORMAT } from '@/components/data-table/display/fields/boolean/constants';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type BooleanField as BooleanFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function BooleanEdit({
  fieldId,
  value,
  rowId,
  type
}: FieldRenderProps<BooleanFieldDisplay>) {
  const [t] = useTranslation();

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const selectedCell = useDataTableStore().use.selectedCell();

  const { setIsEditing, setCellErrors, clearCellErrors, saveCell } =
    useDataTableStore().use.actions();

  const containerRef = useRef<HTMLDivElement>(null);

  if (!selectedCell) throw new Error('No selected cell');

  const [currentValue, setCurrentValue] = useState(value);

  const selectedOptionRef = useRef<HTMLButtonElement>(null);

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const [searchKeyword, setSearchKeyword] = useState('');

  const { required, format } = currentField as BooleanField;
  const booleanFormat = format?.format || 'yes_no';

  const filteredOptions = Object.keys(OPTIONS_BY_FORMAT[booleanFormat]).filter((option) =>
    option.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      size({
        padding: 500,
        apply({ availableHeight, elements }) {
          if (parseInt(elements.floating.style.height, 10) < availableHeight) {
            elements.floating.style.height = `${availableHeight}px`;
          }
        }
      }),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const onSelection = async (option: string) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    setCurrentValue(option);

    if (option === currentValue) {
      setIsEditing(false);
      return;
    }

    const optionValue = OPTIONS_BY_FORMAT[booleanFormat][option];
    if (required && !optionValue) {
      setCellErrors(rowId, fieldId, [
        { message: t('components.data_table.errors.required_field') }
      ]);
      return;
    }

    await saveCell(rowId, fieldId, option, {
      value: option,
      rawValue: option
    });

    (refs.reference.current as HTMLTextAreaElement).value = option;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLButtonElement>) => {
    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (selectedCell.isEditing) {
      if (e.key === 'ArrowUp') {
        if (!filteredOptions) return;

        const currentIndex = filteredOptions.indexOf(currentValue);
        let nextIndex = currentIndex - 1;
        if (nextIndex < 0) nextIndex = filteredOptions.length - 1;

        if (filteredOptions[nextIndex] !== currentValue) {
          void onSelection(filteredOptions[nextIndex]);
        }

        e.preventDefault();
        e.stopPropagation();
      }
      if (e.key === 'ArrowDown') {
        if (!filteredOptions) return;
        const currentIndex = filteredOptions.indexOf(currentValue);

        let nextIndex = currentIndex + 1;
        if (nextIndex > filteredOptions.length - 1) nextIndex = 0;

        if (filteredOptions[nextIndex] !== currentValue) {
          void onSelection(filteredOptions[nextIndex]);
          selectedOptionRef.current?.focus();
        }

        e.preventDefault();
        e.stopPropagation();
      }
    }

    if (e.key === 'Tab' && selectedCell.isEditing) {
      moveSelectionHorizontal('right');
      setIsEditing(false);
      e.stopPropagation();
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (selectedCell.isEditing) {
        moveSelectionVertical('down');
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onBlurHandler = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.relatedTarget)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      (refs.reference.current as HTMLTextAreaElement).focus();
    }, 0);
  }, [refs.reference]);

  // The selected option gets the focus when it changes or when you start editing
  // This way if you have a scrollbar the element will be scrolled into view
  useEffect(() => {
    if (selectedCell.isEditing) {
      selectedOptionRef.current?.focus();
    }
  }, [selectedCell.isEditing, currentValue]);

  return (
    <div className="flex size-full flex-col" ref={containerRef}>
      <TextareaCell
        ref={refs.setReference}
        defaultValue={currentValue}
        intent={cellErrors ? 'destructive' : 'default'}
        className={cn({
          'opacity-0': !selectedCell.isEditing
        })}
        onKeyDown={handleKeyDown}
        onBlur={onBlurHandler}
        onChange={(e) => {
          setIsEditing(true);
          setSearchKeyword(e.target.value);
        }}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionAtTheEnd(e);
          }
        }}
        data-testid={`edit-boolean-input-${rowId}-${fieldId}`}
        onFocus={setCursorPositionAtTheEnd}
      />

      {selectedCell.isEditing && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="mt-1 flex max-h-[400px] w-full flex-col items-start gap-1 overflow-auto rounded-lg border-2 border-default bg-base p-2"
          data-testid={`boolean-menu-options-${rowId}-${fieldId}`}
        >
          {searchKeyword !== '' && filteredOptions?.length === 0 ? (
            <div>{t('components.data_table.no_matching_options')}</div>
          ) : (
            filteredOptions?.map((option) => (
              <button
                type="button"
                ref={option === currentValue ? selectedOptionRef : null}
                className={cn(
                  'w-full text-clip rounded-md p-2 text-left text-default hover:bg-subtle hover:text-emphasis',
                  { 'bg-subtle': option === currentValue }
                )}
                key={option}
                onKeyDown={handleKeyDown}
                data-testid={`boolean-menu-options-${option}-${rowId}-${fieldId}`}
                onClick={async () => {
                  setIsEditing(false);
                  await onSelection(option);
                }}
              >
                {option}
              </button>
            ))
          )}
        </div>
      )}

      {!selectedCell?.isEditing && (
        <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="boolean-edit-error" />
      )}
    </div>
  );
}
