import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuArrowUpDown as ArrowUpDownIcon } from 'react-icons/lu';
import { Button, Label, Popover, Select, Switch } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import {
  useDataTableStore,
  useDataTableStorePersist
} from '@/components/data-table/useDataTableStore';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';

export function SortingIndicator() {
  const fields = useDataTableStore().use.fields();
  const shouldSortAutomatically = useDataTableStorePersist().use.shouldSortAutomatically();
  const sortBy = useDataTableStorePersist().use.sortBy();
  const sortOrder = useDataTableStorePersist().use.sortOrder();
  const { setSort } = useDataTableStorePersist().use.actions();

  const isDraftModeEnabled = useDataTableStore().use.isDraftModeEnabled();

  const { refetchPagesInViewport } = useDataTableStore().use.actions();
  const { setShouldSortAutomatically } = useDataTableStorePersist().use.actions();

  const { t } = useTranslation();

  // We cannot return null before this hook in case sortBy is empty, so we are forcing it to be a string
  const selectedField = useDataTableStore().use.getField(sortBy as string);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <TextTooltip
        label={isDraftModeEnabled ? t('components.data_table.disabled_because_draftmode') : ''}
        asChild
      >
        <div>
          <Popover.Trigger data-testid="data-table-sorting-indicator-button" asChild>
            <Button
              intent="secondary"
              disabled={isDraftModeEnabled}
              className={cn('h-9 p-2', {
                'whitespace-nowrap border-brand-200 bg-brand-50': shouldSortAutomatically
              })}
            >
              <ArrowUpDownIcon className="mr-2 flex-shrink-0" />
              <span className="text-sm text-emphasis">
                {shouldSortAutomatically
                  ? t('components.data_table.sorting.sorted_by')
                  : t('components.data_table.sorting.sort_by')}
              </span>
              {shouldSortAutomatically && selectedField && (
                <div className="ml-2 flex h-6 max-w-64 items-center truncate rounded bg-brand-100/50 px-1">
                  <FieldIcon name={selectedField.type} size={13} className="mr-1" />
                  {selectedField.name}
                </div>
              )}
            </Button>
          </Popover.Trigger>
        </div>
      </TextTooltip>
      <Popover.Content align="end" className="w-[480px] max-w-[100vw] p-6">
        <h2 className="mb-1 text-xl">{t('components.data_table.sorting.sort_by')}</h2>
        <p className="mb-6 text-xs">{t('components.data_table.sorting.choose_field')}</p>
        <div className="flex justify-between">
          <div className="mr-2 min-w-0 flex-1">
            <Label className="mb-1 inline-block text-sm">
              {t('components.data_table.sorting.field')}
            </Label>
            <Select
              value={sortBy || undefined}
              onValueChange={(value) => {
                setSort(value, sortOrder || 'asc');
                if (shouldSortAutomatically) {
                  refetchPagesInViewport();
                }
              }}
            >
              <Select.Trigger
                className="w-full truncate"
                data-testid="sorting-field-select-trigger"
              />
              <Select.Content data-testid="sorting-field-select-content">
                {fields.map((field) => (
                  <Select.Item
                    key={field.key}
                    value={field.key}
                    data-testid={`sorting-field-select-content-${field.key}`}
                  >
                    <div className="flex items-center text-base">
                      <FieldIcon name={field.type} size={13} className="mr-1 w-5" />
                      <p className="truncate">{field.name}</p>
                    </div>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>
          <div className="w-[160px]">
            <Label className="mb-1 inline-block text-sm">
              {t('components.data_table.sorting.sort_type')}
            </Label>
            <Select
              value={sortOrder || undefined}
              onValueChange={(value: 'asc' | 'desc') => {
                setSort(sortBy, value);
                if (shouldSortAutomatically) {
                  refetchPagesInViewport();
                }
              }}
            >
              <Select.Trigger data-testid="sorting-order-select-trigger" className="w-full" />
              <Select.Content data-testid="delete-account-select-content" position="item-aligned">
                <Select.Item
                  data-testid="sorting-order-select-content-asc"
                  value="asc"
                  className="text-base"
                >
                  {t('components.data_table.sorting.a_z')}
                </Select.Item>
                <Select.Item
                  data-testid="sorting-order-select-content-desc"
                  value="desc"
                  className="text-base"
                >
                  {t('components.data_table.sorting.z_a')}
                </Select.Item>
              </Select.Content>
            </Select>
          </div>
        </div>
        <div>
          <div className="mt-4 flex items-center">
            <Switch
              className="mr-2"
              data-testid="sorting-order-automatic-switch"
              checked={shouldSortAutomatically || false}
              onCheckedChange={(value) => {
                setShouldSortAutomatically(value);
                if (value === true) {
                  refetchPagesInViewport();
                }
              }}
            />
            <Label className="font-medium">
              {t('components.data_table.sorting.sort_automatically')}
            </Label>
          </div>
          {!shouldSortAutomatically && (
            <div className="mt-4 flex items-center justify-end gap-2">
              <Button
                intent="minimal"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t('components.data_table.sorting.cancel')}
              </Button>
              <Button
                onClick={() => {
                  refetchPagesInViewport();
                  setIsOpen(false);
                }}
              >
                {t('components.data_table.sorting.sort')}
              </Button>
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover>
  );
}
