import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HiTableCells as TableIcon } from 'react-icons/hi2';
import {
  Badge,
  Button,
  Dialog,
  Divider,
  Input,
  Label,
  RadioGroup,
  Tabs,
  type TabsListItem
} from '@knack/asterisk-react';

import { assertTruthiness } from '@/utils/assert';
import { FieldIcon } from '@/components/FieldIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import {
  type ViewWithDetails,
  type ViewWithDetailsInput
} from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

interface ViewDetailsFieldInputEditModalProps {
  title: string;
  fieldInput: ViewWithDetailsInput;
  onOpenChange: (isOpen: boolean) => void;
}

enum LabelFormat {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  None = 'none',
  Bold = 'strong',
  Italic = 'em',
  H1 = 'h1',
  H2 = 'h2'
}

export function ViewDetailsFieldInputEditModal({
  title,
  fieldInput,
  onOpenChange
}: ViewDetailsFieldInputEditModalProps) {
  const [t] = useTranslation();
  const { view, sourceObject } = useActiveViewContext<ViewWithDetails>();
  const updateViewSchema = useUpdateView<ViewWithDetails>();
  const field = sourceObject.fields.find((f) => f.key === fieldInput.key);
  const [fieldInputToUpdate, setFieldInputToUpdate] = useState<ViewWithDetailsInput>(fieldInput);
  const shouldShowLabelStyleSettings =
    fieldInputToUpdate.format.label_custom || fieldInputToUpdate.format.styles.length > 0;

  const availableFormats = {
    textStyles: [LabelFormat.Bold, LabelFormat.Italic],
    headerStyles: [LabelFormat.H1, LabelFormat.H2]
  };

  const labelAlignmentItems: TabsListItem[] = [
    {
      value: LabelFormat.Left,
      children: t('keywords.left')
    },
    {
      value: LabelFormat.Right,
      children: t('keywords.right')
    },
    {
      value: LabelFormat.Top,
      children: t('keywords.top')
    },
    {
      value: LabelFormat.None,
      children: t('keywords.hide_label')
    }
  ];

  const labelTextStylingItems: TabsListItem[] = [
    {
      value: LabelFormat.None,
      children: t('keywords.none')
    },
    {
      value: LabelFormat.Bold,
      children: t('keywords.bold')
    },
    {
      value: LabelFormat.Italic,
      children: t('keywords.italic')
    }
  ];

  const labelHeaderStylesItems: TabsListItem[] = [
    {
      value: LabelFormat.None,
      children: t('keywords.none')
    },
    {
      value: LabelFormat.H1,
      children: t('keywords.header_h1')
    },
    {
      value: LabelFormat.H2,
      children: t('keywords.header_h2')
    }
  ];

  assertTruthiness(field, `Field for list field input with key ${fieldInput.key} not found`);

  const applyChangesToActivePage = () => {
    const updatedListDetailsView = {
      columns: view.columns.map((outerColumn) => ({
        ...outerColumn,
        groups: outerColumn.groups.map((group) => ({
          ...group,
          columns: group.columns.map((column) =>
            column.map((input) => {
              if (input.id === fieldInput.id) {
                return fieldInputToUpdate;
              }
              return input;
            })
          )
        }))
      }))
    };

    updateViewSchema(updatedListDetailsView);
    onOpenChange(false);
  };

  const getDefaultLabelTextStyle = () =>
    fieldInputToUpdate.format.styles.find((style) => style === LabelFormat.Bold) ||
    fieldInputToUpdate.format.styles.find((style) => style === LabelFormat.Italic) ||
    LabelFormat.None;

  const getDefaultLabelHeadingStyle = () =>
    fieldInputToUpdate.format.styles.find((style) => style === 'h1') ||
    fieldInputToUpdate.format.styles.find((style) => style === 'h2') ||
    LabelFormat.None;

  const handleOnTextStyleChange = (value: LabelFormat) => {
    // TODO: Both bold and italics cannot be selected at the same time because of the tab element

    // Remove all current text styles
    const currentStyles = fieldInputToUpdate.format.styles.filter(
      (styles) => !availableFormats.textStyles.includes(styles as LabelFormat)
    );

    // If a new style different than 'none' is selected, add it to the list
    if (value !== LabelFormat.None) {
      currentStyles.push(value);
    }

    setFieldInputToUpdate((prev) => ({
      ...prev,
      format: {
        ...prev.format,
        // In v3, adding styles doesn't sets the label_custom to true, so is not set here either.
        // It is set only when the alignment styles changes
        styles: currentStyles
      }
    }));
  };

  const handleOnHeadingStyleChange = (value: LabelFormat) => {
    // Remove all current header styles
    const currentStyles = fieldInputToUpdate.format.styles.filter(
      (styles) => !availableFormats.headerStyles.includes(styles as LabelFormat)
    );

    // If a new style different than 'none' is selected, add it to the list
    if (value !== LabelFormat.None) {
      currentStyles.push(value);
    }

    setFieldInputToUpdate((prev) => ({
      ...prev,
      format: {
        ...prev.format,
        // In v3, adding styles doesn't sets the label_custom to true, so is not setted here either.
        // It is setted only when the alignment styles changes
        styles: currentStyles
      }
    }));
  };

  if (!fieldInput) {
    return null;
  }

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{title}</Dialog.Title>
            <Dialog.Description>
              {t(
                'pages.element_settings.list.categories.data_display.field_management.edit_field_modal.description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          <div className="mt-6">
            <div className="mb-4">
              <span className="mb-2 block text-sm">
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source'
                )}
              </span>
              <Badge className="inline-flex items-center rounded-md">
                <TableIcon size={16} name={fieldInput.type} className="mr-1 text-subtle" />
                <Trans
                  i18nKey="pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_source_description"
                  values={{ tableName: view.name, fieldName: fieldInput.name }}
                >
                  <span className="mr-1 font-semibold">fieldName</span>
                  <span className="font-semibold">tableName</span>
                </Trans>
              </Badge>
            </div>
            <div>
              <span className="mb-2 block text-sm">
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_type'
                )}
              </span>
              <Badge className="inline-flex items-center rounded-md">
                <FieldIcon size={16} name={field.type} className="mr-1" />
                {t(`attributes.field_types.${field.type}`)}
              </Badge>
            </div>

            <Divider className="my-6" />
            <div className="mb-4">
              <Label
                className="mb-2 block text-sm"
                htmlFor={`field-label-${fieldInputToUpdate.key}`}
              >
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.field_label'
                )}
              </Label>
              <Input
                id={`field-label-${fieldInputToUpdate.key}`}
                value={fieldInputToUpdate.label}
                onChange={(e) =>
                  setFieldInputToUpdate({ ...fieldInputToUpdate, label: e.target.value })
                }
              />
            </div>

            <Divider className="my-6" />
            <div className="mb-4">
              <Label
                className="mb-2 block text-sm"
                htmlFor={`field-label-${fieldInputToUpdate.key}`}
              >
                {t(
                  'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.label_styling'
                )}

                <RadioGroup
                  defaultValue={shouldShowLabelStyleSettings ? 'custom' : 'default'}
                  className="mt-1 flex flex-col"
                  id="label-format-options"
                  data-testid="label-format-options"
                  onValueChange={(value) => {
                    setFieldInputToUpdate((prev) => ({
                      ...prev,
                      format: {
                        ...prev.format,
                        label_custom: value === 'custom',
                        label_format: LabelFormat.Left,
                        styles: []
                      }
                    }));
                  }}
                >
                  <RadioGroup.Container>
                    <RadioGroup.Item value="default" id="radio-item-use-default-style" />
                    <Label htmlFor="radio-item-use-default-style">
                      {t(
                        'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.use_default'
                      )}
                    </Label>
                  </RadioGroup.Container>
                  <RadioGroup.Container>
                    <RadioGroup.Item value="custom" id="radio-item-use-custom-style" />
                    <Label htmlFor="radio-item-set-custom-style text-subtle">
                      {t(
                        'pages.element_settings.common.categories.data_display.field_management.edit_field_modal.set_custom'
                      )}
                    </Label>
                  </RadioGroup.Container>
                </RadioGroup>
                {shouldShowLabelStyleSettings && (
                  <div className="ml-6 mt-4 flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                      <p className="text-subtle">
                        {t(
                          'pages.element_settings.list.categories.data_display.field_management.edit_field_modal.label_format_label_alignment'
                        )}
                      </p>
                      <Tabs
                        defaultValue={fieldInputToUpdate.format.label_format}
                        onValueChange={(value) =>
                          setFieldInputToUpdate((prev) => ({
                            ...prev,
                            format: {
                              ...prev.format,
                              label_custom: true,
                              label_format: value
                            }
                          }))
                        }
                      >
                        <Tabs.List items={labelAlignmentItems} shouldDisableResponsive />
                      </Tabs>
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="text-subtle">
                        {t(
                          'pages.element_settings.list.categories.data_display.field_management.edit_field_modal.label_format_label_text_styling'
                        )}
                      </p>
                      <Tabs
                        defaultValue={getDefaultLabelTextStyle()}
                        onValueChange={(value) => handleOnTextStyleChange(value as LabelFormat)}
                      >
                        <Tabs.List items={labelTextStylingItems} shouldDisableResponsive />
                      </Tabs>
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-subtle">
                        {t(
                          'pages.element_settings.list.categories.data_display.field_management.edit_field_modal.label_format_label_header_styles'
                        )}
                      </p>
                      <Tabs
                        defaultValue={getDefaultLabelHeadingStyle()}
                        onValueChange={(value) => handleOnHeadingStyleChange(value as LabelFormat)}
                      >
                        <Tabs.List items={labelHeaderStylesItems} shouldDisableResponsive />
                      </Tabs>
                    </div>
                  </div>
                )}
              </Label>
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button onClick={applyChangesToActivePage}>{t('actions.apply')}</Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
