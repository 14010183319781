import { createContext, useContext, useMemo } from 'react';

import { useFlowsQuery } from '@/hooks/api/queries/useFlowsQuery';

type FlowsPageContextState = {
  flowsUrl?: string;
  isError: boolean;
  isLoadingFlowsUrl: boolean;
} | null;

type FlowsPageContextProviderProps = {
  children: React.ReactNode;
};

const FlowsPageContext = createContext<FlowsPageContextState>(null);

export function FlowsPageContextProvider({ children }: FlowsPageContextProviderProps) {
  const { data, isPending, isError } = useFlowsQuery();

  const contextValue = useMemo(
    () => ({
      flowsUrl: data?.flowsUrl,
      isError,
      isLoadingFlowsUrl: isPending
    }),
    [data, isPending, isError]
  );

  return <FlowsPageContext.Provider value={contextValue}>{children}</FlowsPageContext.Provider>;
}

export const useFlowsPageContext = () => {
  const context = useContext(FlowsPageContext);
  if (!context) {
    throw new Error('useFlowsPageContext must be used within a FlowsPageContextProvider');
  }
  return context;
};
