import {
  type BuilderPageColumn,
  type BuilderPageSection,
  type SectionLayoutColumns
} from '@/types/schema/BuilderPage';
import { generateNewColumn } from './generate-new-column';

type GetUpdatedSectionFromLayoutColumnsChangeParams = {
  section: BuilderPageSection;
  selectedLayoutColumns: SectionLayoutColumns;
};

export function getUpdatedSectionFromLayoutColumnsChange({
  section,
  selectedLayoutColumns
}: GetUpdatedSectionFromLayoutColumnsChangeParams) {
  if (section.layout.columns === selectedLayoutColumns) {
    return section;
  }

  let newSectionLayout = section.layout;
  let newSectionColumns: BuilderPageColumn[] = [];

  if (selectedLayoutColumns === '1') {
    newSectionLayout = {
      columns: selectedLayoutColumns,
      structure: '100'
    };
    section.columns.forEach((column, index) => {
      // Keep the first column, delete the rest, and move their views to the first column
      if (index === 0) {
        newSectionColumns.push({
          ...column,
          width: '100%'
        });
      } else {
        newSectionColumns[0].viewKeys.push(...column.viewKeys);
      }
    });
  }

  if (selectedLayoutColumns === '2') {
    newSectionLayout = {
      columns: selectedLayoutColumns,
      structure: '50/50'
    };

    // If the section has only one column, keep the existing column and add a new one
    if (section.columns.length === 1) {
      newSectionColumns = [
        {
          ...section.columns[0],
          width: '50%'
        },
        generateNewColumn({ width: '50%' })
      ];
    }

    // If the section has three columns, delete the last column and move its views to the second column
    if (section.columns.length === 3) {
      section.columns.forEach((column, index) => {
        if (index <= 1) {
          newSectionColumns.push({
            ...column,
            width: '50%'
          });
        } else {
          newSectionColumns[index - 1].viewKeys.push(...column.viewKeys);
        }
      });
    }
  }

  if (selectedLayoutColumns === '3') {
    newSectionLayout = {
      columns: selectedLayoutColumns,
      structure: '33/33/33'
    };

    // If the section has only one column, add two new columns
    if (section.columns.length === 1) {
      newSectionColumns = [
        {
          ...section.columns[0],
          width: '33%'
        },
        generateNewColumn({ width: '33%' }),
        generateNewColumn({ width: '33%' })
      ];
    }

    // If the section has two columns, add a new column
    if (section.columns.length === 2) {
      newSectionColumns = [
        {
          ...section.columns[0],
          width: '33%'
        },
        {
          ...section.columns[1],
          width: '33%'
        },
        generateNewColumn({ width: '33%' })
      ];
    }
  }

  const updatedSection: BuilderPageSection = {
    ...section,
    layout: newSectionLayout,
    columns: newSectionColumns
  };

  return updatedSection;
}
