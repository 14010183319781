export function getTrialDaysLeft(deadline: string) {
  const deadlineDate = new Date(deadline);
  const today = new Date();
  const timeLeft = (deadlineDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000);

  let daysLeft = Math.floor(timeLeft);
  if (today.getHours() / 24 + (timeLeft - daysLeft) > 1) {
    daysLeft += 1;
  }

  return daysLeft;
}

export function getHasTrialExpired(deadline: string) {
  const deadlineDate = new Date(deadline).getTime();
  const today = new Date().getTime();
  return deadlineDate < today;
}
