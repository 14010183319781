import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

export function usePageAccessSettings() {
  const { page } = usePageEditorContext();
  const { getRoleObjects } = useObjectHelpers();
  const { getClosestLoginPage } = usePageHelpers();

  const userRoleObjects = getRoleObjects();

  // If the page is public, we don't need to show any access settings
  if (!page.requiresAuthentication) {
    return null;
  }

  // If the page is a user page, we have the limitProfileAccess and allowedProfileKeys directly in the page itself
  if (page.type === 'user') {
    return {
      limitProfileAccess: page.limitProfileAccess || false,
      allowedUserRoleObjects: userRoleObjects.filter(
        (object) => object.profile_key && page.allowedProfileKeys?.includes(object.profile_key)
      ),
      userRoleObjects
    };
  }

  // Otherwise, we need to find the respective login page and view.
  // The login page can be the page itself or the closest parent login page
  const loginPage = page.type === 'authentication' ? page : getClosestLoginPage(page);
  if (!loginPage) {
    return null;
  }

  const loginView = loginPage.views.find((view) => view.type === 'login');
  if (!loginView) {
    throw new Error('Page login view not found in the page');
  }

  return {
    limitProfileAccess: loginView.limitProfileAccess,
    allowedUserRoleObjects: userRoleObjects.filter(
      (object) => object.profile_key && loginView.allowedProfileKeys.includes(object.profile_key)
    ),
    userRoleObjects,
    loginPage,
    loginView
  };
}
