import { type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EQUATION_DATE_RESULT_OPTIONS,
  EQUATION_DATE_TYPE_OPTIONS,
  EQUATION_TYPE_OPTIONS
} from '@/types/schema/fields';
import { type EquationSchemaType } from '@/components/field-settings/equation/EquationFormSettings';
import { SelectWithLabel } from '@/components/field-settings/utils/SelectWithLabel';

type EquationTypeSelectorProps = {
  currentField: ControllerRenderProps<EquationSchemaType, 'format'>;
};

export function EquationTypeSelector({ currentField }: EquationTypeSelectorProps) {
  const [t] = useTranslation();
  return (
    <>
      <SelectWithLabel
        label={t('components.data_table.attributes.field_settings.equation.equation_type.label')}
        values={EQUATION_TYPE_OPTIONS}
        defaultValue={currentField.value.equation_type || EQUATION_TYPE_OPTIONS[0]}
        onValueChange={(value) => {
          currentField.onChange({
            ...currentField.value,
            equation_type: value
          });
        }}
        translationPrefix="components.data_table.attributes.field_settings.equation.equation_type"
        data_testid="equation-settings-equation-type"
      />

      {currentField.value.equation_type === 'date' && (
        <>
          <SelectWithLabel
            label={t('components.data_table.attributes.field_settings.equation.date_type.label')}
            values={EQUATION_DATE_TYPE_OPTIONS}
            defaultValue={currentField.value.date_type || EQUATION_DATE_TYPE_OPTIONS[0]}
            onValueChange={(value) => {
              currentField.onChange({
                ...currentField.value,
                date_type: value
              });
            }}
            translationPrefix="components.data_table.attributes.field_settings.equation.date_type"
            data_testid="equation-settings-date-type"
          />
          <SelectWithLabel
            label={t('components.data_table.attributes.field_settings.equation.date_result.label')}
            values={EQUATION_DATE_RESULT_OPTIONS}
            defaultValue={currentField.value.date_result || EQUATION_DATE_RESULT_OPTIONS[0]}
            onValueChange={(value) => {
              currentField.onChange({
                ...currentField.value,
                date_result: value
              });
            }}
            translationPrefix="components.data_table.attributes.field_settings.equation.date_result"
            data_testid="equation-settings-date-result"
          />
        </>
      )}
    </>
  );
}
