import { useTranslation } from 'react-i18next';

import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { InlineKnackValue } from '@/components/InlineKnackValue';

interface InlineKnackRecordValueProps {
  tableKey: string | undefined;
  recordId: string;
  className?: string;
  dataTestId?: string;
}

export function InlineKnackRecordValue({
  tableKey,
  recordId,
  className = '',
  dataTestId
}: InlineKnackRecordValueProps) {
  const [t] = useTranslation();

  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    objectKey: tableKey || '',
    enabled: !!tableKey
  });

  if (isLoading) {
    return (
      <InlineKnackValue
        value={t('keywords.loading')}
        data-testid={dataTestId}
        className={className}
      />
    );
  }

  const connectionRecord = connectionRecords?.records.find((record) => record.id === recordId);
  const valueToRender = connectionRecord ? (connectionRecord.identifier as string) : '';

  if (!valueToRender) return null;

  return <InlineKnackValue value={valueToRender} data-testid={dataTestId} className={className} />;
}
