import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type ReportViewCalculation } from '@/types/schema/views/ReportView';
import { FieldIcon } from '@/components/FieldIcon';

type CalculationFieldSelectorProps = {
  id: string;
  availableNumericFields: CalculationFieldSelectorItem[];
  defaultValue: ReportViewCalculation['field'];
  onSelectCalculationField: (calculationField: CalculationFieldSelectorItem) => void;
};

export type CalculationFieldSelectorItem = {
  key: KnackFieldKey | 'count';
  name: string;
  type: KnackFieldType;
};

export function CalculationFieldSelector({
  id,
  availableNumericFields,
  defaultValue,
  onSelectCalculationField
}: CalculationFieldSelectorProps) {
  const [t] = useTranslation();

  const isCalculationFieldItem = (calculationField: CalculationFieldSelectorItem) =>
    calculationField.key !== 'count';

  return (
    <>
      <Label className="mb-2 block" htmlFor="calculation-field">
        {t('pages.element_settings.report.categories.data_display.field')}
      </Label>
      <Select
        onValueChange={(value) => {
          const selectedCalculationField = availableNumericFields.find(
            (field) => field.key === value
          );
          if (selectedCalculationField) {
            onSelectCalculationField(selectedCalculationField);
          }
        }}
        defaultValue={defaultValue}
      >
        <Select.Trigger
          id={id}
          placeholder={t('actions.select')}
          className="w-full truncate"
          data-testid={id}
        />
        <Select.Content>
          {availableNumericFields?.map((field) => (
            <Select.Item
              key={field.key}
              value={field.key}
              data-testid={`calculation-field-item-${field}`}
            >
              <span
                className="flex items-center gap-1"
                data-testid={`calculation-field-item-${field.key}`}
              >
                {isCalculationFieldItem(field) && <FieldIcon name={field.type} size={14} />}
                {field.name}
              </span>
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </>
  );
}
