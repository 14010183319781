import { EmailEdit } from '@/components/data-table/display/fields/email/EmailEdit';
import { type EmailField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function EmailRender(props: FieldRenderProps<EmailField>) {
  const { rawValue, rowId, fieldId, isFloating, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { format: emailFormat } = currentField;

  if (isFloating) {
    return <EmailEdit {...props} />;
  }

  const getEmailLabel = () => {
    if (emailFormat?.text_format === 'same') {
      return emailFormat?.label;
    }

    if (emailFormat?.text_format === 'unique' && rawValue.label) {
      return rawValue.label;
    }

    return rawValue.email;
  };

  return (
    <div className="flex size-full p-2" data-testid={`email-render-${rowId}-${fieldId}`}>
      <p className="w-full truncate underline">
        {rawValue.email && (
          <a href={`mailto:${rawValue.email}`} className="underline">
            {getEmailLabel() || ''}
          </a>
        )}
      </p>
    </div>
  );
}
