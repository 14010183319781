import { useFormContext } from 'react-hook-form';
import { Form, Input, RichTextEditor, Textarea } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { DateTimeInput } from '@/components/import/confirm-import/date-time/DateTimeInput';
import { TimerInput } from '@/components/import/confirm-import/timer/TimerInput';
import { AddressInput } from '@/components/inputs/AddressInput';
import { BooleanInput } from '@/components/inputs/BooleanInput';
import { ConnectionInput } from '@/components/inputs/ConnectionInput';
import { CurrencyInput } from '@/components/inputs/CurrencyInput';
import { EmailInput } from '@/components/inputs/EmailInput';
import { FileInput } from '@/components/inputs/FileInput';
import { LinkInput } from '@/components/inputs/LinkInput';
import { MultipleChoiceInput } from '@/components/inputs/MultipleChoiceInput';
import { NumberInput } from '@/components/inputs/NumberInput';
import { PasswordInput } from '@/components/inputs/PasswordInput';
import { PersonInput } from '@/components/inputs/PersonInput';
import { PhoneInput } from '@/components/inputs/PhoneInput';
import { RatingInput } from '@/components/inputs/RatingInput';
import { SignatureInput } from '@/components/inputs/SignatureInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';

export function FieldInput({ field, table }: { field: KnackField; table: KnackObject }) {
  const fieldType = field.type;
  const {
    setValue,
    register,
    getValues,
    formState: { errors }
  } = useFormContext();
  const renderError = (fieldKey: string) => {
    const error = errors[fieldKey];
    if (error && error.message && typeof error.message === 'string') {
      return <p className="text-destructive">{error.message}</p>;
    }
    return null;
  };
  const inputHtmlId = `${field.key}-${fieldType}-input`;

  if (fieldType === 'address') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <AddressInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'connection') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <ConnectionInput id={inputHtmlId} field={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'boolean') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <BooleanInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'multiple_choice') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <MultipleChoiceInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }

  if (fieldType === 'currency') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <CurrencyInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'email') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <EmailInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'link') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <LinkInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'name') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <PersonInput id={inputHtmlId} targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'rich_text') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <div className="flex max-h-64">
          <RichTextEditor
            content={getValues(field.key)}
            data-testid="default-value-rich-text"
            onUpdate={({ editor }) => {
              const content = editor.isEmpty ? '' : editor.getHTML();
              setValue(field.key, content);
            }}
          />
          {renderError(field.key)}
        </div>
      </>
    );
  }
  if (fieldType === 'rating') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <RatingInput targetField={field} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'number') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <NumberInput id={inputHtmlId} name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'short_text') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <Input
          id={inputHtmlId}
          data-testid="short-text-input"
          intent={errors[field.key] && 'destructive'}
          {...register(field.key)}
        />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'paragraph_text') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} id={field.name} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <Textarea
          id={inputHtmlId}
          data-testid="paragraph-text-input"
          intent={errors[field.key] && 'destructive'}
          {...register(field.key)}
        />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'phone') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} id={field.name} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <PhoneInput targetField={field} name={field.key} id={inputHtmlId} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'file') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <FileInput name={field.key} targetField={field} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'image') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <FileInput name={field.key} targetField={field} />
        {renderError(field.key)}
      </>
    );
  }

  if (fieldType === 'timer') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <TimerInput id={inputHtmlId} name={field.key} targetField={field} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'signature') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <SignatureInput name={field.key} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'date_time') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <DateTimeInput id={inputHtmlId} name={field.key} targetField={field} />
        {renderError(field.key)}
      </>
    );
  }
  if (fieldType === 'password') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <PasswordInput name={field.key} id={inputHtmlId} />
        {renderError(field.key)}
      </>
    );
  }

  if (fieldType === 'user_roles') {
    return (
      <>
        <Form.Label htmlFor={inputHtmlId} isRequired={field.required}>
          {field.name}
        </Form.Label>
        <UserRolesInput name={field.key} id={inputHtmlId} selectedRole={table} />
        {renderError(field.key)}
      </>
    );
  }
}
