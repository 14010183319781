import { type BuilderPageKey } from '@/types/schema/BuilderPage';
import {
  type BuilderViewBase,
  type BuilderViewColumnIcon,
  type BuilderViewLinkColumnType,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';

export const LIST_VIEW_DIVIDER_INPUT_ID_PREFIX = 'divider_';
export const LIST_VIEW_RICH_TEXT_INPUT_ID_PREFIX = 'rich_text_';

export interface ListView extends BuilderViewBase {
  type: 'list';
  columns: ListViewOuterColumn[];
  links: unknown[];
  inputs: unknown[];
  groups: unknown[];
  keyword_search: boolean;
  rows_per_page: string;
  allow_exporting: boolean;
  allow_limit: boolean;
  allow_preset_filters: boolean;
  filter_type: 'fields' | 'menu' | 'none';
  menu_filters: KnackMenuFilter[];
  filter_fields: 'view' | 'object';
  preset_filters: KnackFilter[];
  keyword_search_fields: 'view' | 'all';
  source: BuilderViewSourceSchema;
  layout: 'full' | 'one-column' | 'two-column' | 'three-column';
  list_layout: 'full' | 'one-column' | 'two-column' | 'three-column';
  label_format: 'left' | 'right' | 'top' | 'none';
  rules: {
    fields: []; // Always empty - List elements don't have display rules
  };
  design: unknown;
  no_data_text: string;
  hide_fields: boolean;
}

export interface ListViewOuterColumn {
  id: string;
  groups: ListViewOuterColumnGroup[];
  width: number;
}

export interface ListViewOuterColumnGroup {
  id: string;
  columns: Array<ListViewInput[]>;
}

export interface ListViewActionRule {
  key?: string;
  link_text: string;
  criteria: KnackCriteria[];
  record_rules: ListViewRecordRule[];
  submit_rules: ListViewSubmitRule[];
}

export interface ListViewRecordRule {
  key: string;
  criteria: null;
  action: string;
  values: Array<{
    field: KnackFieldKey;
    type: string;
    input: string;
    connection_field: KnackFieldKey;
    value: string;
  }>;
}

export interface ListViewSubmitRule {
  key: string;
  action: 'message' | 'existing_page' | 'child_page' | 'url';
  criteria?: KnackCriteria[];
  reload_show: boolean;
  message: string;
  reload_auto?: boolean;
  is_default: boolean;
  existing_page?: string;
  scene?: BuilderPageKey | null;
  url?: string;
}

export interface ListViewInput {
  id: string;
  key: KnackFieldKey;
  type: 'field' | 'scene_link' | 'action_link' | 'delete' | 'divider' | 'special_title';
  name: string;
  label?: string;
  scene?: string;
  action_rules?: ListViewActionRule[];
  conn_separator: string;
  conn_link: string;
  link_type: BuilderViewLinkColumnType;
  link_text: string;
  link_field: string;
  link_design_active: boolean;
  icon: BuilderViewColumnIcon;
  img_gallery: string;
  show_map: boolean;
  map_width: number;
  map_height: number;
  value: string;
  copy: string;
  format: {
    label_custom: boolean;
    label_format: string;
    styles: unknown[];
  };
}
