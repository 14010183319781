import { useQueryClient } from '@tanstack/react-query';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';

export function useApplication() {
  const queryClient = useQueryClient();
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return queryClient.getQueryData<BuilderApplication>([
    queryKeys.application,
    accountSlug,
    appSlug
  ]);
}
