import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';
import { type TableNodeField } from '@/pages/data-model/diagram/TableNodeComponent';

export function getFilteredTableEdges(
  tableKey: string,
  filteredOutConnections: TableNodeField[],
  edges: TableEdge[]
) {
  return edges.map((edge) => {
    if (edge.source !== tableKey) {
      return edge;
    }

    // eslint-disable-next-line no-param-reassign
    edge.hidden = filteredOutConnections.some((field) => edge.sourceHandle?.includes(field.key));

    return edge;
  });
}
