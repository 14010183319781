export const lightBackgroundColorPresets = [
  '#fafafa', // bg-base
  '#eff6ff', // bg-blue-50
  '#ecfeff', // bg-cyan-50
  '#f7fee7', // bg-lime-50
  '#effdf4', // bg-green-50
  '#fdf2f8', // bg-pink-50
  '#fef5ff', // bg-fuchsia-50
  '#f5f3ff', // bg-violet-50
  '#fffbeb', // bg-amber-50
  ['#FBEDDA', '#F0DDF8'],
  ['#DFE2F6', '#F3E2F3'],
  ['#DCEBFA', '#E3F2F0'],
  ['#D9E9FC', '#E9DBFB'],
  ['#FAE7DB', '#FBF2DA'],
  ['#D8FEE5', '#FFFED7'],
  ['#DCF9EB', '#F7DEF6']
] as const;

export const darkBackgroundColorPresets = [
  '#030712', // bg-base
  '#172554', // bg-blue-950
  '#093344', // bg-cyan-950
  '#1b2d06', // bg-lime-950
  '#052e16', // bg-green-950
  '#500724', // bg-pink-950
  '#4a044e', // bg-fuchsia-950
  '#2e1065', // bg-violet-950
  '#451b03', // bg-amber-950
  ['#401238', '#20091C'],
  ['#111828', '#14264B'],
  ['#281117', '#29144B'],
  ['#18005B', '#0B0029'],
  ['#58232F', '#29144B']
] as const;
