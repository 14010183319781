import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select, Switch } from '@knack/asterisk-react';

import { OPTIONS_BY_FORMAT } from '@/components/data-table/display/fields/boolean/constants';
import { OPTIONS_VALUE_BY_FORMAT } from '@/components/field-settings/boolean/constants';

type BooleanDefaultValueProps = {
  defaultValue: boolean;
};

const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const getOptionFromDefaultValue = (currentDefaultValue, formatSelected) => {
  const optionsByFormat = OPTIONS_BY_FORMAT[formatSelected];
  return getKeyByValue(optionsByFormat, currentDefaultValue);
};

export function DefaultValue({ defaultValue }: BooleanDefaultValueProps) {
  const [t] = useTranslation();

  const { setValue, getValues, watch } = useFormContext();

  const getDefaultValueOptions = () => Object.keys(OPTIONS_BY_FORMAT[getValues('format.format')]);

  const formDefaultValue = getValues('format.default');
  const selectedFormat = getValues('format.format');

  const setDefaultValue = () => {
    const currentDefaultValue = formDefaultValue !== undefined ? formDefaultValue : false;
    const newDefaultValue = getOptionFromDefaultValue(currentDefaultValue, selectedFormat);
    if (formDefaultValue === undefined && newDefaultValue !== undefined) {
      setValue('format.default', OPTIONS_VALUE_BY_FORMAT[newDefaultValue]);
    }
    return newDefaultValue;
  };

  const isCheckboxSelected = getValues('format.input') === 'checkbox';
  const hasFormatOptions = !!watch('format.format');

  return (
    <>
      {isCheckboxSelected && (
        <div className="flex items-center">
          <Controller
            name="format.default"
            defaultValue={defaultValue || false}
            render={({ field: { onChange, value } }) => (
              <Switch
                id="checkbox-selected-by-default-switch"
                className="mr-2"
                data-testid="checkbox-selected-by-default-switch"
                checked={value}
                onCheckedChange={onChange}
              />
            )}
          />
          <Label htmlFor="checkbox-selected-by-default-switch">
            {t(
              'components.data_table.attributes.field_settings.boolean.checkbox_selected_by_default'
            )}
          </Label>
        </div>
      )}

      {hasFormatOptions && !isCheckboxSelected && (
        <div className="flex flex-col gap-2">
          <Label htmlFor="default-select">
            {t('components.data_table.attributes.field_settings.boolean.default_label')}
          </Label>
          <Select
            onValueChange={(value) => {
              setValue('format.default', OPTIONS_VALUE_BY_FORMAT[value]);
            }}
            defaultValue={setDefaultValue()}
            value={setDefaultValue()}
          >
            <Select.Trigger
              id="default-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="boolean-form-settings-default-select"
            />
            <Select.Content>
              {getDefaultValueOptions().map((option) => (
                <Select.Item
                  key={option}
                  value={option}
                  data-testid={`boolean-field-settings-default-value-${option}`}
                >
                  {option}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </div>
      )}
    </>
  );
}
