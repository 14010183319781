export const DEFAULT_NODE_WIDTH = 440;
export const INITIAL_VISIBLE_FIELDS_PER_TABLE = 10;

export const EDGE_COLOR_DEFAULT = '#A9A2A8';
export const EDGE_COLOR_HIGHLIGHTED = '#C079B4';

export enum Cardinality {
  OneToOne = 'one-to-one',
  OneToMany = 'one-to-many',
  ManyToOne = 'many-to-one',
  ManyToMany = 'many-to-many'
}
