import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Chip } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { capitalizeAllWords, sanitizeHTMLTags } from '@/utils/formatters';
import { cn } from '@/utils/tailwind';
import { type MultipleChoiceField } from '@/components/data-table/display/fields/Field';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

function isUserStatusField(field: KnackField) {
  // There is no identification we can use to ensure the field is a user status field
  // We can only assume that the field is a user status field if it is a multiple choice field and it is immutable
  // We don't use the `user` property because it is not reliable and the Server doesn't use it either
  // https://github.com/knackhq/Server/blob/aa8f2fb9cdc9f69eb3bc368b8d3dc2567fdf510b/app/routes/crm/discover/users/with-knack-emails.js#L99-L103
  return field.type === 'multiple_choice' && field.immutable;
}

export function MultipleChoiceValues(props: {
  rawValue: MultipleChoiceField['rawValue'];
  onDeleteOption?: (option: string) => void;
  fieldId: string;
}) {
  const { rawValue, onDeleteOption, fieldId } = props;
  const selectedCell = useDataTableStore().use.selectedCell();
  const field = useDataTableStore().use.getField(fieldId);

  const isUserStatus = isUserStatusField(field);

  return (
    <div
      className="group size-full overflow-auto bg-base pr-6"
      data-testid="multiple-choice-values"
    >
      <div
        className={cn(
          'right-1 top-1 hidden h-6 items-center rounded bg-default group-hover:absolute group-hover:flex',
          {
            'bg-emphasis': selectedCell?.isEditing
          }
        )}
      >
        <ChevronDownIcon className="mx-0.5" size={16} />
      </div>

      <div className="flex flex-wrap">
        {typeof rawValue === 'string' && rawValue && (
          <Chip
            text={sanitizeHTMLTags(isUserStatus ? capitalizeAllWords(rawValue) : rawValue)}
            key={sanitizeHTMLTags(rawValue)}
            className={cn('m-1 mb-0', {
              'bg-success text-success-emphasis': isUserStatus && rawValue === 'active',
              'bg-destructive text-destructive-emphasis': isUserStatus && rawValue === 'inactive',
              'bg-warning text-warning-emphasis': isUserStatus && rawValue === 'pending approval'
            })}
            {...(onDeleteOption && {
              onClose: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteOption(rawValue);
              }
            })}
          />
        )}
        {typeof rawValue === 'object' &&
          rawValue.length > 0 &&
          rawValue.map((item) => (
            <Chip
              text={sanitizeHTMLTags(item)}
              key={sanitizeHTMLTags(item)}
              className="m-1 mb-0"
              {...(onDeleteOption && {
                onClose: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteOption(item);
                }
              })}
            />
          ))}
      </div>
    </div>
  );
}
