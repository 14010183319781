import { getConnectedEdges, type OnSelectionChangeParams } from 'reactflow';

import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';

export const getAnimatedEdges = (
  params: OnSelectionChangeParams,
  edges: TableEdge[]
): TableEdge[] => {
  // First, check if there are nodes selected, so we only animate the associated edges of those nodes
  if (params.nodes.length > 0) {
    const connectedEdges = getConnectedEdges(params.nodes, edges);
    return edges.map((edge) => {
      if (connectedEdges.find((connectedEdge) => connectedEdge.id === edge.id)) {
        return {
          ...edge,
          animated: true
        };
      }
      return { ...edge, animated: false };
    });
  }

  // If no nodes are selected but there are invidual edges selected, we animate those
  if (params.edges.length > 0) {
    return edges.map((edge) => {
      if (params.edges.find((selectedEdge) => selectedEdge.id === edge.id)) {
        return {
          ...edge,
          animated: true
        };
      }
      return { ...edge, animated: false };
    });
  }

  // If there are no selections, we remove all animations
  return edges.map((edge) => ({ ...edge, animated: false }));
};
