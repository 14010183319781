import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode
} from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { ROUTES } from '@/Router';

type LeftPanelContextState = {
  allPages: BuilderPage[] | undefined;
  search: string;
  setSearch: (value: string) => void;
  isSearching: boolean;
  filteredPages: BuilderPage[] | undefined;
  setFilteredPages: (value: BuilderPage[] | undefined) => void;
  nonUserPages: BuilderPage[];
  userPages: BuilderPage[];
  setUserPages: (value: BuilderPage[]) => void;
  nonUserPagesWithoutParent: BuilderPage[];
  nonUserRootPages: BuilderPage[];
  setNonUserRootPages: (value: BuilderPage[]) => void;
  newPageKey?: string;
  setNewPageKey: (value: string | undefined) => void;
  hasAppSingleLoginPage: boolean;
  appSingleLoginPage: BuilderPage | null;
};

const contextDefaultValues: LeftPanelContextState = {
  allPages: undefined,
  search: '',
  setSearch: () => {},
  isSearching: false,
  filteredPages: undefined,
  setFilteredPages: () => {},
  nonUserPages: [],
  userPages: [],
  setUserPages: () => {},
  nonUserPagesWithoutParent: [],
  nonUserRootPages: [],
  setNonUserRootPages: () => {},
  newPageKey: undefined,
  setNewPageKey: () => {},
  appSingleLoginPage: null,
  hasAppSingleLoginPage: false
};

export const LeftPanelContext = createContext<LeftPanelContextState>(contextDefaultValues);

export function LeftPanelContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();

  const { data: app } = useApplicationQuery();
  const { pages: allPages } = usePagesPageContext();

  // This state is used to track the search query
  const [search, setSearch] = useState('');
  const isSearching = search !== '';
  // This state is used to filter the pages based on the search query
  // This state is the source of truth for all the pages that are displayed in the left panel
  const [filteredPages, setFilteredPages] = useState<BuilderPage[] | undefined>(
    contextDefaultValues.filteredPages
  );

  // This state is used to track the page key of the new page that is being created, so that the left panel can scroll to it
  const [newPageKey, setNewPageKey] = useState<string | undefined>(contextDefaultValues.newPageKey);
  // This state is used to track the single login page in the app, if it exists
  const [appSingleLoginPage, setAppSingleLoginPage] = useState<BuilderPage | null>(
    contextDefaultValues.appSingleLoginPage
  );
  // This state is used to track the non-user pages that do not have a parent
  const [nonUserPagesWithoutParent, setNonUserPagesWithoutParent] = useState<BuilderPage[]>(
    contextDefaultValues.nonUserPagesWithoutParent
  );
  // This state is used to track the root non-user pages in the app
  const [nonUserRootPages, setNonUserRootPages] = useState<BuilderPage[]>(
    contextDefaultValues.nonUserRootPages
  );

  const hasAppSingleLoginPage = app?.users.scope === 'application';

  // This effect is used to filter the pages based on the search query
  useEffect(() => {
    if (allPages) {
      if (isSearching) {
        const pages = allPages.filter((page) =>
          page.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredPages(pages);
      } else {
        setFilteredPages(allPages);
      }
    }
  }, [allPages, isSearching, search, setFilteredPages]);

  // This function initializes the non-user and user pages
  const initializePages = useCallback(() => {
    if (!filteredPages) {
      return {
        nonUserPages: contextDefaultValues.nonUserPages,
        userPages: contextDefaultValues.userPages
      };
    }

    return filteredPages.reduce<{
      nonUserPages: BuilderPage[];
      userPages: BuilderPage[];
    }>(
      (accumulator, page) => {
        if (page.type === 'user') {
          accumulator.userPages.push(page);
        } else {
          accumulator.nonUserPages.push(page);
        }
        return accumulator;
      },
      { nonUserPages: [], userPages: [] }
    );
  }, [filteredPages]);

  const initialPages = initializePages();
  const [userPages, setUserPages] = useState(initialPages.userPages);
  const [nonUserPages, setNonUserPages] = useState(initialPages.nonUserPages);

  // This effect is to find the root non-user pages of the app
  // The PageTreeItem will recursively render all the children of the root pages
  // The user pages don't have children, so there's no need to find their root user pages
  useEffect(() => {
    if (filteredPages) {
      const updatedPages = initializePages();
      setNonUserPages(updatedPages.nonUserPages);
      setUserPages(updatedPages.userPages);

      if (!isSearching) {
        // Get all the non-user pages without a parent
        const foundNonUserPagesWithoutParent = updatedPages.nonUserPages.filter(
          (page) => !page.parentSlug && !page.menuPageKey
        );
        setNonUserPagesWithoutParent(foundNonUserPagesWithoutParent);

        let foundNonUserRootPages: BuilderPage[];

        if (hasAppSingleLoginPage) {
          // When the app has a single login page, that page is always the first one in the list
          setAppSingleLoginPage(foundNonUserPagesWithoutParent[0]);

          // Get all the pages that are direct children of the single login page, which is always the first page in the list
          foundNonUserRootPages = updatedPages.nonUserPages.filter(
            (page) =>
              page.parentSlug === foundNonUserPagesWithoutParent[0]?.slug && !page.menuPageKey
          );
        } else {
          foundNonUserRootPages = foundNonUserPagesWithoutParent;
        }
        setNonUserRootPages(foundNonUserRootPages);
      } else {
        setNonUserRootPages(filteredPages);
      }
    }
  }, [filteredPages, hasAppSingleLoginPage, initializePages, isSearching]);

  // This effect is used to scroll to the new page that was created
  useEffect(() => {
    if (newPageKey) {
      if (allPages?.find((page) => page.key === newPageKey)) {
        // If a new page was created, navigate to the new page
        navigate(generatePath(ROUTES.PAGES_ID, { id: newPageKey }));
        setNewPageKey(undefined);
      }
    }
  }, [allPages, newPageKey, navigate, setNewPageKey]);

  const leftPanelProviderValue = useMemo(
    () => ({
      allPages,
      search,
      setSearch,
      isSearching,
      filteredPages,
      setFilteredPages,
      nonUserPages,
      userPages,
      setUserPages,
      nonUserPagesWithoutParent,
      nonUserRootPages,
      setNonUserRootPages,
      newPageKey,
      setNewPageKey,
      hasAppSingleLoginPage,
      appSingleLoginPage
    }),
    [
      allPages,
      search,
      setSearch,
      isSearching,
      filteredPages,
      setFilteredPages,
      nonUserPages,
      userPages,
      setUserPages,
      nonUserPagesWithoutParent,
      nonUserRootPages,
      setNonUserRootPages,
      newPageKey,
      setNewPageKey,
      hasAppSingleLoginPage,
      appSingleLoginPage
    ]
  );

  return (
    <LeftPanelContext.Provider value={leftPanelProviderValue}>{children}</LeftPanelContext.Provider>
  );
}

export const useLeftPanelContext = () => {
  const context = useContext(LeftPanelContext);
  if (!context) {
    throw new Error('useLeftPanelContext must be used within a LeftPanelContextProvider');
  }
  return context;
};
