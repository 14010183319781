import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { ErrorFallback } from '@/components/errors/ErrorFallback';

export function RouterErrorBoundary() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
      <Outlet />
    </ErrorBoundary>
  );
}
