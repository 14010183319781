import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { DeleteTableForm } from './DeleteTableForm';
import {
  DuplicateTableForm,
  INITIAL_STEP as initialDuplicateFormStep,
  FINAL_STEP as totalDuplicateFormSteps,
  type FormSteps as DuplicateTableFormSteps
} from './duplicate-table-form/DuplicateTableForm';
import { type DialogContentType } from './TableDropdownMenu';
import { TableSettingsForm } from './TableSettingsForm';

interface DialogContentTypeProps {
  table: KnackObject;
  closeDialog: () => void;
}

interface DialogContentProps extends DialogContentTypeProps {
  contentType: DialogContentType | null;
}

function TableSettingsDialogContent({ table, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>{t('components.dialogs.tables.settings.title')}</Dialog.Title>
      </Dialog.Header>
      <TableSettingsForm table={table} onSave={closeDialog} onCancel={closeDialog} />
    </>
  );
}

function DuplicateTableDialogContent({ table, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();
  const [currentFormStep, setCurrentFormStep] =
    useState<DuplicateTableFormSteps>(initialDuplicateFormStep);

  const handleStepChange = (newStep: DuplicateTableFormSteps) => {
    setCurrentFormStep(newStep);
  };

  return (
    <>
      <Dialog.Header>
        <span className="text-xs">
          {t('components.dialogs.tables.step', {
            currentStep: currentFormStep,
            totalSteps: totalDuplicateFormSteps
          })}
        </span>
        <Dialog.Title>
          {t('components.dialogs.tables.duplicate.title', { tableName: table.name })}
        </Dialog.Title>
      </Dialog.Header>
      <DuplicateTableForm
        table={table}
        onStepChange={handleStepChange}
        onSave={closeDialog}
        onCancel={closeDialog}
      />
    </>
  );
}

function DeleteTableDialogContent({ table, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>
          {t('components.dialogs.tables.delete.title', { tableName: table.name })}
        </Dialog.Title>
      </Dialog.Header>
      <Dialog.Description>
        <Trans
          i18nKey="components.dialogs.tables.delete.description"
          values={{ tableName: table.name }}
        >
          <span className="font-semibold">tableName</span>
        </Trans>
      </Dialog.Description>
      <DeleteTableForm table={table} onDelete={closeDialog} onCancel={closeDialog} />
    </>
  );
}

export function DialogContent({ contentType, table, closeDialog }: DialogContentProps) {
  if (!contentType) {
    return null;
  }

  return (
    <Dialog.Content data-testid="table-dropdown-menu-dialog-content">
      <Dialog.MainContent className="flex flex-col gap-5">
        {contentType === 'settings' && (
          <TableSettingsDialogContent table={table} closeDialog={closeDialog} />
        )}

        {contentType === 'duplicate' && (
          <DuplicateTableDialogContent table={table} closeDialog={closeDialog} />
        )}

        {contentType === 'delete' && (
          <DeleteTableDialogContent table={table} closeDialog={closeDialog} />
        )}
      </Dialog.MainContent>
    </Dialog.Content>
  );
}
