import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type ConnectionField } from '@/types/schema/fields';

interface ConnectionFieldGroup {
  sourceObject: string;
  fields: ConnectionField[];
}

export function getGroupedConnectionFields(
  connectionFields: ConnectionField[]
): ConnectionFieldGroup[] {
  const groupedFields = {};

  connectionFields.forEach((field) => {
    const sourceObject = field.relationship.object;

    if (!sourceObject) {
      return;
    }

    // If there is no group for this sourceObject, we create it
    if (!groupedFields[sourceObject]) {
      groupedFields[sourceObject] = [];
    }

    // Add the field to the corresponding group
    groupedFields[sourceObject].push(field);
  });

  // Convert the object of groups into an array with the required format
  const formattedConnectionFields = Object.keys(groupedFields).map((sourceObject) => ({
    sourceObject,
    fields: groupedFields[sourceObject]
  }));

  return formattedConnectionFields;
}

export const getSourceObjectName = (
  sourceObjectKey: string,
  application: BuilderApplication | undefined
) => {
  if (!application) return null;

  return application.objects.find((object) => object.key === sourceObjectKey)?.name || '';
};
