import { HiXMark as XIcon } from 'react-icons/hi2';
import { Button, Tooltip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { CollapsiblePanelContent } from '@/components/layout/CollapsiblePanelContent';
import ExpandPanelIcon from '@/assets/svg/expand-panel.svg?react';

interface CollapsiblePanelProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
  title: string;
  children: React.ReactNode;
  isResizable?: boolean;
  position?: 'left' | 'right';
}

function CollapsiblePanel({
  isCollapsed,
  toggleCollapse,
  title,
  children,
  isResizable = false,
  position = 'left'
}: CollapsiblePanelProps) {
  const rootClasses = cn('size-full bg-muted flex flex-col overflow-hidden', {
    'hover:bg-subtle': isCollapsed
  });
  const nonResizableClasses = cn('w-80 flex-shrink-0', {
    'w-9': isCollapsed,
    'border-r': position === 'left',
    'border-l': position === 'right'
  });

  return (
    <div
      className={cn(rootClasses, !isResizable ? nonResizableClasses : '')}
      data-testid="collapsible-panel-container"
    >
      {isCollapsed && (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Button
              className="flex size-full items-start rounded-none bg-none p-2 pt-6"
              onClick={toggleCollapse}
              data-testid="expand-collapsible-panel-button"
            >
              <ExpandPanelIcon
                className={cn({
                  'rotate-180 transform': position === 'right'
                })}
                data-testid="expand-panel-icon"
              />
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>{title}</Tooltip.Content>
        </Tooltip>
      )}

      <Button
        intent="minimal"
        size="sm"
        onClick={toggleCollapse}
        data-testid="collapsible-panel-close-button"
        className={cn('absolute right-4 top-3 z-10', { hidden: isCollapsed })}
      >
        <XIcon size={20} data-testid="collapse-panel-icon" />
      </Button>

      <div className={cn('flex grow flex-col', { hidden: isCollapsed })}>{children}</div>
    </div>
  );
}

// Attach Content as a static property of CollapsiblePanel
CollapsiblePanel.Content = CollapsiblePanelContent;

export { CollapsiblePanel };
