import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { getFormViewSchemaFromPartialView } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/formViewSchema';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

// Gets the original connection input from the initial state of the view, before any page changes
function getOriginalConnectionInput(originalView: FormView, inputId: KnackFieldKey) {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of originalView.groups) {
    // eslint-disable-next-line no-restricted-syntax
    for (const column of group.columns) {
      const foundInput = column.inputs.find((input) => input.id === inputId);
      if (foundInput && foundInput.type === 'connection') {
        return foundInput;
      }
    }
  }

  return null;
}

interface AllowNewConnectedRecordsSwitchProps {
  connectionInputToUpdate: FormViewConnectionInput;
  setConnectionInputToUpdate: (input: FormViewConnectionInput) => void;
  connectionField: ConnectionField;
}

export function AllowNewConnectedRecordsSwitch({
  connectionInputToUpdate,
  setConnectionInputToUpdate,
  connectionField
}: AllowNewConnectedRecordsSwitchProps) {
  const [t] = useTranslation();
  const { getObjectByKey } = useObjectHelpers();
  const { view, viewInitialState } = useActiveViewContext<FormView>();

  const [childFormViewToAdd, setChildFormViewToAdd] = useState<FormView | null>(null);

  const originalConnectionInput = useMemo(() => {
    if (!viewInitialState) {
      return null;
    }

    return getOriginalConnectionInput(viewInitialState, connectionInputToUpdate.id);
  }, [viewInitialState, connectionInputToUpdate.id]);

  const onAllowNewConnectedRecords = (allowsNewConnectedRecords: boolean) => {
    const updatedConnectionFieldInput = {
      ...connectionInputToUpdate,
      allow_option_inserts: allowsNewConnectedRecords
    };

    // If adding new records is disallowed
    if (!allowsNewConnectedRecords) {
      // If there is already a child view associated with the input (the `view` property), we need to remove it from the input since the child view will no longer exist
      if (updatedConnectionFieldInput.view) {
        delete updatedConnectionFieldInput.view;
      }

      setConnectionInputToUpdate(updatedConnectionFieldInput);
      return;
    }

    // If the initial state of the input already had a child form view associated with it, we can use that one instead of creating a new one
    if (originalConnectionInput?.view) {
      setConnectionInputToUpdate({
        ...updatedConnectionFieldInput,
        view: originalConnectionInput.view
      });

      return;
    }

    // If there is already a new child form view in the local state, we can use that one
    if (childFormViewToAdd) {
      setConnectionInputToUpdate({
        ...updatedConnectionFieldInput,
        view: childFormViewToAdd
      });

      return;
    }

    // If adding new records is allowed, we need to create a new child form view
    const childFormSourceObject = connectionField.relationship.object
      ? getObjectByKey(connectionField.relationship.object)
      : undefined;

    if (!childFormSourceObject) {
      return;
    }

    const childFormView = getFormViewSchemaFromPartialView(
      {
        action: 'insert',
        name: t('views.new_view_defaults.form.title_add', {
          objectName: childFormSourceObject.inflections.singular
        }),
        parent: view.key,
        source: {
          object: childFormSourceObject.key,
          criteria: {
            match: 'all',
            rules: [],
            groups: []
          }
        }
      },
      childFormSourceObject
    );

    setConnectionInputToUpdate({
      ...updatedConnectionFieldInput,
      view: childFormView
    });

    // Keep track of the child form view so we don't have to recreate it if the user toggles the switch again
    setChildFormViewToAdd(childFormView);
  };

  // New connected records can only be added if:
  //   - The form is a registration form
  //   - Or, the form is not a child view (otherwise it means it's already a child form that is creating a connected record)
  const shouldShowAllowNewConnectedRecordsSwitch = view.type === 'registration' || !view.parent;

  if (!shouldShowAllowNewConnectedRecordsSwitch) {
    return null;
  }

  return (
    <Label className="flex items-center text-sm">
      <Switch
        className="mr-2"
        checked={connectionInputToUpdate.allow_option_inserts}
        onCheckedChange={onAllowNewConnectedRecords}
      />
      {t(
        'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_allow_new_connected_records'
      )}
    </Label>
  );
}
