import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Label,
  MultiSelect,
  RadioGroup,
  Switch,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { LOGIN_VIEW_REGISTRATION_TYPES } from '@/types/schema/views/LoginView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { type NewPageFormSchema } from './NewPageForm';

export function NewPageFormLoginSection() {
  const [t] = useTranslation();

  const { getRoleObjects } = useObjectHelpers();
  const { control, watch } = useFormContext<NewPageFormSchema>();

  const { requiresLogin, loginOptions } = watch();

  const userRoleObjects = getRoleObjects();
  const userRoleOptions: MultiSelectOption[] = userRoleObjects.map((role) => ({
    key: role.profile_key as string,
    label: role.name
  }));

  return (
    <Form.Section className="mt-4">
      <Label className="flex text-sm">
        <div className="mr-2 mt-1">
          <Controller
            control={control}
            name="requiresLogin"
            render={({ field: { onChange, value } }) => (
              <Switch checked={value} onCheckedChange={onChange} />
            )}
          />
        </div>
        <div>
          <span className="mb-1 block font-medium">{t('pages.dialogs.new_page.protect_page')}</span>
          <span className="block text-xs text-subtle">
            {t('pages.dialogs.new_page.protect_page_description')}
          </span>
        </div>
      </Label>

      {requiresLogin && (
        <>
          <div className="mt-4">
            <Label
              htmlFor="new-login-page-registration-type"
              className="mb-2 inline-block text-sm font-medium"
            >
              {t(`pages.settings.access.can_new_users_register`)}
            </Label>
            <Controller
              control={control}
              name="loginOptions.registrationType"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  id="new-login-page-registration-type"
                  className="text-sm"
                  value={value}
                  onValueChange={onChange}
                >
                  {LOGIN_VIEW_REGISTRATION_TYPES.map((registrationType) => (
                    <RadioGroup.Container key={registrationType}>
                      <RadioGroup.Item id={registrationType} value={registrationType} />
                      <Label htmlFor={registrationType}>
                        {t(`pages.settings.access.registration_type_labels.${registrationType}`)}
                      </Label>
                    </RadioGroup.Container>
                  ))}
                </RadioGroup>
              )}
            />
          </div>

          <div className="mt-4">
            <Label
              htmlFor="new-login-page-limit-profile-access"
              className="mb-2 inline-block text-sm font-medium"
            >
              {t(`pages.settings.access.which_user_roles_can_access`)}
            </Label>
            <Controller
              control={control}
              name="loginOptions.limitProfileAccess"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  id="new-login-page-limit-profile-access"
                  className="text-sm"
                  value={String(value)}
                  onValueChange={(newValue) => {
                    onChange(newValue === 'true');
                  }}
                >
                  <RadioGroup.Container>
                    <RadioGroup.Item id="new-login-page-limit-profile-access-false" value="false" />
                    <Label htmlFor="new-login-page-limit-profile-access-false">
                      {t('pages.settings.access.all_logged_in_users')}
                    </Label>
                  </RadioGroup.Container>
                  <RadioGroup.Container>
                    <RadioGroup.Item id="new-login-page-limit-profile-access-true" value="true" />
                    <Label htmlFor="new-login-page-limit-profile-access-true">
                      {t('pages.settings.access.specific_user_roles_only')}
                    </Label>
                  </RadioGroup.Container>
                </RadioGroup>
              )}
            />

            {loginOptions?.limitProfileAccess && (
              <div className="mt-2 pl-6">
                <Controller
                  control={control}
                  name="loginOptions.allowedProfileKeys"
                  render={({ field: { onChange, value: selectedProfileKeys } }) => {
                    const selectedUserRoleOptions =
                      userRoleOptions.filter((option) =>
                        selectedProfileKeys?.includes(option.key as KnackObjectProfileKey)
                      ) || [];

                    return (
                      <MultiSelect
                        id="new-login-page-user-roles"
                        options={userRoleOptions}
                        selectedOptions={selectedUserRoleOptions}
                        onSelectOptions={(newSelectedProfileKeys) => {
                          const selectedOptions = newSelectedProfileKeys.map(
                            (option) => option.key as KnackObjectProfileKey
                          );
                          onChange(selectedOptions);
                        }}
                      />
                    );
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Form.Section>
  );
}
