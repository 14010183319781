import { useRef } from 'react';
import { Controller, type FieldPath } from 'react-hook-form';
import {
  MdFormatBold as BoldIcon,
  MdFormatItalic as ItalicIcon,
  MdOutlineStrikethroughS as StrikeThroughIcon
} from 'react-icons/md';
import { Button, Input } from '@knack/asterisk-react';

import {
  type TableDisplayRuleActionType,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';

interface TextStyleActionSelectProps {
  formName: FieldPath<TableViewDisplayRule>;
}

const DEFAULT_COLOR_PICKER_COLOR = '#000000';

interface ColorPickerProps {
  value: string;
  onChange: (value: string) => void;
}

interface TableDisplayRuleFormActionPickerProps {
  action: TableDisplayRuleActionType;
  formName: FieldPath<TableViewDisplayRule>;
}

function TextStyleActionSelect({ formName }: TextStyleActionSelectProps) {
  return (
    <div className="flex gap-2">
      <Controller
        name={`${formName}.bold`}
        render={({ field: { onChange, value: bold } }) => (
          <Button
            intent="secondary"
            className={cn({ 'bg-action': bold })}
            onClick={() => onChange(!bold)}
            size="sm"
          >
            <BoldIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.italic`}
        render={({ field: { onChange, value: italic } }) => (
          <Button
            intent="secondary"
            onClick={() => onChange(!italic)}
            size="sm"
            className={cn({ 'bg-action': italic })}
          >
            <ItalicIcon size={16} />
          </Button>
        )}
      />
      <Controller
        name={`${formName}.strikethrough`}
        render={({ field: { onChange, value: strikethrough } }) => (
          <Button
            intent="secondary"
            onClick={() => onChange(!strikethrough)}
            size="sm"
            className={cn({ 'bg-action': strikethrough })}
          >
            <StrikeThroughIcon size={16} />
          </Button>
        )}
      />
    </div>
  );
}

function ColorPicker({ value, onChange }: ColorPickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className="flex items-center rounded-lg border border-default bg-input">
      <button
        type="button"
        className="h-auto rounded-l-lg border-r border-default p-2 hover:cursor-pointer hover:bg-muted"
        onClick={handleClick}
        aria-label="Color picker"
      >
        <div
          className="border-muted size-6 rounded-md border p-px py-0"
          style={{ backgroundColor: value }}
        >
          <input
            ref={inputRef}
            type="color"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className="invisible"
            tabIndex={-1}
          />
        </div>
      </button>
      <Input
        className="rounded-l-none border-0 text-sm focus:-outline-offset-1"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export function TableDisplayRuleFormActionPicker({
  action,
  formName
}: TableDisplayRuleFormActionPickerProps) {
  if (action === 'text-style') {
    return <TextStyleActionSelect formName={formName} />;
  }

  if (action === 'text-color') {
    return (
      <Controller
        name={`${formName}.color`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          return <ColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  if (action === 'bg-color') {
    return (
      <Controller
        name={`${formName}.value`}
        render={({ field: { value, onChange } }) => {
          const defaultValue = value || DEFAULT_COLOR_PICKER_COLOR;

          return <ColorPicker value={defaultValue} onChange={onChange} />;
        }}
      />
    );
  }

  return null;
}
