import formatHelper from '@knack/format-helper';
import replace from 'lodash/replace';

import { type NumberField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { NumberEdit } from '@/components/data-table/display/fields/number/NumberEdit';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function NumberRender(props: FieldRenderProps<NumberField>) {
  const { rawValue, isFloating, fieldId } = props;
  const currentField = useDataTableStore().use.getField<'number'>(fieldId);
  if (isFloating) {
    return <NumberEdit {...props} />;
  }

  const formattedNumber = replace(
    formatHelper.formatNumber(rawValue, { ...currentField.format }),
    /&nbsp;/g,
    ' '
  );

  return <p className="size-full truncate p-2 text-right">{formattedNumber}</p>;
}
