import { useQuery } from '@tanstack/react-query';

import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export const sessionDefault: Session = {
  account: {
    id: '',
    name: '',
    slug: '',
    status: '',
    settings: {
      hipaa: {
        enabled: false,
        region: null
      }
    },
    productPlan: {
      id: ''
    }
  },
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatarUri: '',
    isTwoFactorAuthEnabled: false,
    isGoogleUser: false,
    hasSetPassword: false,
    appRights: []
  }
};

export async function getSession() {
  const endpoint = '/v1/dashboard/session/state';

  const { data } = await axios.get<{ session: Session }>(endpoint, {
    withCredentials: true
  });

  return data.session;
}

export function useSessionQuery() {
  return useQuery({
    queryKey: [queryKeys.session],
    queryFn: getSession,

    // prevent fetching the data each time this hook is called
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,

    // refetch in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    placeholderData: sessionDefault
  });
}
