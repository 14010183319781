import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import {
  FORM_VIEW_COLUMN_ID_PREFIX,
  FORM_VIEW_LAYOUT_COLUMNS,
  type FormView,
  type FormViewGroupColumn,
  type FormViewLayoutColumns
} from '@/types/schema/views/FormView';
import { InlineRadioCardGroup } from '@/components/InlineRadioCardGroup';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

function generateNewFormColumn() {
  const column: FormViewGroupColumn = {
    id: `${FORM_VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
    inputs: []
  };

  return column;
}

export function FormLayout() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<FormView>();
  const { updatePage } = usePageEditorContext();

  const selectedLayoutColumns = String(view.groups[0].columns.length);

  const handleLayoutColumnsChange = (value: string) => {
    const newSelectedLayoutColumns = value as FormViewLayoutColumns;

    const existingGroup = view.groups[0];
    const updatedGroupColumns: FormViewGroupColumn[] = [];

    if (newSelectedLayoutColumns === '1') {
      existingGroup.columns.forEach((column, index) => {
        // Keep the first column, delete the rest, and move their inputs to the first column
        if (index === 0) {
          updatedGroupColumns.push(column);
        } else {
          updatedGroupColumns[0].inputs.push(...column.inputs);
        }
      });
    }

    if (newSelectedLayoutColumns === '2') {
      // If the group has only one column, keep the existing column and add a new one
      if (existingGroup.columns.length === 1) {
        updatedGroupColumns.push(existingGroup.columns[0], generateNewFormColumn());
      }

      // If the form has three columns, delete the last column and move its inputs to the second column
      if (existingGroup.columns.length === 3) {
        existingGroup.columns.forEach((column, index) => {
          if (index <= 1) {
            updatedGroupColumns.push(column);
          } else {
            updatedGroupColumns[index - 1].inputs.push(...column.inputs);
          }
        });
      }
    }

    if (newSelectedLayoutColumns === '3') {
      // If the group has only one column, add two new columns
      if (existingGroup.columns.length === 1) {
        updatedGroupColumns.push(
          existingGroup.columns[0],
          generateNewFormColumn(),
          generateNewFormColumn()
        );
      }

      // If the group has two columns, add a new column
      if (existingGroup.columns.length === 2) {
        updatedGroupColumns.push(
          existingGroup.columns[0],
          existingGroup.columns[1],
          generateNewFormColumn()
        );
      }
    }

    updatePage({
      type: 'view',
      action: 'update',
      origin: 'builder',
      viewKey: view.key,
      viewSchema: {
        ...view,
        groups: [
          {
            ...view.groups[0],
            columns: updatedGroupColumns
          }
        ]
      }
    });
  };

  return (
    <div className="mb-6">
      <span className="mb-2 block text-xs font-medium text-emphasis">
        {t('pages.element_settings.form.categories.form_fields.field_management.input_columns')}
      </span>
      <InlineRadioCardGroup
        options={FORM_VIEW_LAYOUT_COLUMNS}
        value={selectedLayoutColumns}
        onValueChange={handleLayoutColumnsChange}
        itemClassName="size-10"
      />
    </div>
  );
}
