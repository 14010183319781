import { t } from 'i18next';
import { z } from 'zod';

import { type NumberField } from '@/types/schema/fields';
import { numericStringRegex } from '@/components/data-table/helpers/zod';

export function getNumberSchema(field: NumberField) {
  const { required } = field;
  return z.object({
    number: z
      .string()
      .regex(numericStringRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'number' })
      })
      .optional()
      .or(z.literal(''))
      .refine((number) => !required || number, t('components.data_table.errors.required_field'))
  });
}
