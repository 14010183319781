import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

type NumberInputProps = {
  name: string;
  id: string;
};

export function NumberInput(props: NumberInputProps) {
  const { name, id } = props;
  const { register, formState } = useFormContext();
  const [t] = useTranslation();

  return (
    <Input
      id={id}
      type="number"
      data-testid="number-input"
      className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
      placeholder={t('components.add_into_existing_table.enter_number')}
      intent={formState.errors[name] && 'destructive'}
      onKeyDown={(e) => {
        if (e.key === 'e' || e.key === 'E') {
          e.preventDefault();
        }
      }}
      {...register(name, {
        setValueAs: (value) => (value !== '' ? String(value) : '')
      })}
    />
  );
}
