import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { PiBracketsCurly as TextFormulaIcon } from 'react-icons/pi';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  Form,
  Popover,
  RichTextEditor,
  Spinner,
  Tabs,
  useToast,
  type TabsListItem
} from '@knack/asterisk-react';
import { type Editor } from '@tiptap/react';
import { z } from 'zod';

import { type KnackObject, type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { useCreateEmptyObjectMutation } from '@/hooks/api/mutations/useCreateEmptyObjectMutation';
import { useEditAccountInfoTemplateMutation } from '@/hooks/api/mutations/useEditAccountInfoTemplateMutation';
import { useEditApprovalTemplateMutation } from '@/hooks/api/mutations/useEditApprovalTemplateMutation';
import { useAccountInfoTemplateQuery } from '@/hooks/api/queries/useAccountInfoTemplatesQuery';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useApprovalTemplateQuery } from '@/hooks/api/queries/useApprovalTemplatesQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { assertTruthiness } from '@/utils/assert';
import { generateUniqueStringInList } from '@/utils/uniqueStrings';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { replaceTemplateVariables } from '@/components/field-settings/utils/replaceTemplateVariables';
import { NavigateAwayModal } from '@/components/NavigateAwayModal';
import { ToolbarDropdownButton } from '@/components/rich-text/ToolbarDropdownButton';
import { EmailTemplateSelector } from '@/pages/roles/role-settings/EmailTemplateSelector';
import { RoleSettingsTree } from '@/pages/roles/role-settings/RoleSettingsTree';
import { useEmailTemplateKeyName } from '@/pages/roles/role-settings/useEmailTemplateKeyName';
import { TableSettingsForm } from '@/pages/tables/tables-tree/table-dropdown-menu/TableSettingsForm';
import { ROUTES } from '@/Router';

export type RoleSettingsModalOutletContext = {
  object: KnackObject;
};

interface TemplateProps {
  profile: KnackObjectProfileKey;
  setting: RoleSettingsOptions;
  objectKey: string;
}

export type RoleSettingsOptions = 'edit' | 'approval-template' | 'account-info-template';

function Template({ profile, setting }: TemplateProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const table = useCurrentTable();
  const { presentToast } = useToast();

  const editApprovalTemplateMutation = useEditApprovalTemplateMutation();
  const editAccountInfoTemplateMutation = useEditAccountInfoTemplateMutation();

  const approvalTemplate = useApprovalTemplateQuery(profile);
  const accountInfoTemplate = useAccountInfoTemplateQuery(profile);
  const isLoading = approvalTemplate.isLoading || accountInfoTemplate.isLoading;
  const template =
    setting === 'approval-template' ? approvalTemplate.data : accountInfoTemplate.data;

  const { emailTemplateKeyVariables, emailTemplateNameVariables } = useEmailTemplateKeyName();

  const templateSchema = z.object({
    template: z.string().min(1, t('components.dialogs.roles.settings.template_required'))
  });

  type TemplateSchema = z.infer<typeof templateSchema>;

  const formMethods = useForm<TemplateSchema>({
    defaultValues: {
      template
    },
    resolver: zodResolver(templateSchema)
  });

  const { handleSubmit, getValues, formState, reset, control } = formMethods;

  const [isConfirmingReset, setIsConfirmingReset] = useState(false);

  const editorRef = useRef<Editor | null>(null);

  const onFormSubmit = (data: TemplateSchema) => {
    if (setting === 'approval-template') {
      editApprovalTemplateMutation.mutate(
        {
          template: data.template,
          profileId: profile
        },
        {
          onSuccess: () => {
            if (!table) return;

            navigate(
              generatePath(ROUTES.ROLES_ID, {
                id: table.key
              })
            );
            presentToast({
              title: t('components.dialogs.roles.settings.template_updated_successfully')
            });
          }
        }
      );
    }
    if (setting === 'account-info-template') {
      editAccountInfoTemplateMutation.mutate(
        {
          template: data.template,
          profileId: profile
        },
        {
          onSuccess: () => {
            if (!table) return;

            navigate(
              generatePath(ROUTES.ROLES_ID, {
                id: table.key
              })
            );
            presentToast({
              title: t('components.dialogs.roles.settings.template_updated_successfully')
            });
          }
        }
      );
    }
  };

  const handleResetButton = () => {
    setIsConfirmingReset(true);
  };

  const confirmReset = () => {
    reset({ template });

    if (editorRef.current) {
      editorRef.current.commands.setContent(template || '');
    }

    setIsConfirmingReset(false);
  };

  const cancelReset = () => {
    setIsConfirmingReset(false);
  };

  useEffect(() => {
    if (!template) return;
    const updatedTemplate = template;

    if (updatedTemplate !== getValues('template')) {
      reset({ template: updatedTemplate }, { keepDirty: false });

      if (editorRef.current) {
        editorRef.current.commands.setContent(updatedTemplate);
      }
    }
  }, [getValues, reset, template]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          control={control}
          name="template"
          render={({ field: { onChange } }) => (
            <RichTextEditor
              data-testid="template-editor"
              toolbarChildren={
                <Popover>
                  <Popover.Trigger>
                    <ToolbarDropdownButton>
                      <TextFormulaIcon />
                    </ToolbarDropdownButton>
                  </Popover.Trigger>
                  <Popover.Content>
                    <EmailTemplateSelector editorRef={editorRef} />
                  </Popover.Content>
                </Popover>
              }
              content={
                // Here we replace the field key with the field name in the editor.
                // This is because we want the user to see the field name instead of the field key. {Number 1} instead of {field_321}
                replaceTemplateVariables({
                  text: template || '',
                  variableList: emailTemplateKeyVariables,
                  valueList: emailTemplateNameVariables.map((name) => `{${name}}`)
                })
              }
              onUpdate={({ editor }) => {
                const content = editor.getText() ? editor.getHTML() : '';

                onChange(
                  // Here we replace the field name back to the field key before saving it to the database. {field_321} instead of {Number 1}
                  replaceTemplateVariables({
                    text: content,
                    variableList: emailTemplateNameVariables,
                    valueList: emailTemplateKeyVariables.map((key) => `{${key}}`)
                  })
                );
              }}
              onCreate={({ editor }) => {
                editorRef.current = editor;
              }}
            />
          )}
        />
        {
          //  To do: FE-3568
        }
        {false && formState.isDirty && (
          <div className="mt-4">
            {!isConfirmingReset ? (
              <Button intent="destructiveSecondary" onClick={handleResetButton}>
                {t('components.dialogs.roles.settings.reset_to_default_template')}
              </Button>
            ) : (
              <div className="flex items-center justify-between">
                <span>{t('components.dialogs.roles.settings.confirm_reset')}</span>
                <div className="flex space-x-2">
                  <Button intent="minimal" onClick={cancelReset}>
                    {t('actions.cancel')}
                  </Button>
                  <Button intent="destructiveSecondary" onClick={confirmReset}>
                    {t('components.dialogs.roles.settings.reset_to_default_template')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mt-4 flex justify-end">
          <Button type="submit" data-testid="templates-confirm-button" disabled={isConfirmingReset}>
            {t('actions.save')}
          </Button>
        </div>
      </Form>
      <NavigateAwayModal condition={formState.isDirty && !formState.isSubmitSuccessful} />
    </FormProvider>
  );
}

function RoleSettingsDialogContent({
  defaultTable,
  selectedSetting
}: {
  defaultTable: KnackObject;
  selectedSetting: RoleSettingsOptions;
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const table = useCurrentTable();
  const tableKey = defaultTable.key;
  const createEmptyObject = useCreateEmptyObjectMutation({
    shouldForceRedirectToV4: true,
    shouldSkipRedirect: true
  });

  const { data: app } = useApplicationQuery();

  const existingRoleNames =
    app?.objects?.filter((o) => o.type === 'UserObject').map((o) => o.name) || [];

  const closeDialog = () => {
    navigate(generatePath(ROUTES.ROLES_ID, { id: tableKey }));
  };

  const items: TabsListItem[] = [
    {
      children: <span>{t('components.dialogs.roles.settings.settings')}</span>,
      value: 'edit'
    },
    {
      children: <span>{t('components.dialogs.roles.settings.approval_template')}</span>,
      value: 'approval-template'
    },
    {
      children: <span>{t('components.dialogs.roles.settings.account_info_template')}</span>,
      value: 'account-info-template'
    }
  ];

  const handleTabChange = (value: string) => {
    navigate(
      generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, {
        id: table?.key || tableKey,
        settingId: value,
        selectedTableKey: tableKey
      })
    );
  };

  const handleAddRoleButton = () => {
    const objectName = generateUniqueStringInList(
      existingRoleNames,
      t('components.roles_list.user_role')
    );
    createEmptyObject.mutate(
      { objectName, user: true },
      {
        onSuccess: (response: any) => {
          navigate(
            generatePath(ROUTES.ROLES_ID_SETTINGS_SETTING_ID, {
              id: table?.key || response.data.object.key,
              selectedTableKey: response.data.object.key,
              settingId: 'edit'
            })
          );
        }
      }
    );
  };

  if (!defaultTable?.profile_key) return null;

  return (
    <Dialog open onOpenChange={closeDialog}>
      {
        // Disable animations to avoid it to be triggered when changing the table in the url
      }
      <Dialog.Content width="lg" className="animate-none data-[state=open]:animate-none">
        <Dialog.MainContent>
          <div className="space-y-4">
            <Dialog.Header>
              <Dialog.Title>{t('components.dialogs.roles.settings.title')}</Dialog.Title>
            </Dialog.Header>
            <Tabs value={selectedSetting} onValueChange={handleTabChange}>
              <Tabs.List items={items} intent="page" shouldDisableResponsive shouldUseGradient />
              {selectedSetting === 'edit' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <Button intent="secondary" className="w-full" onClick={handleAddRoleButton}>
                      <PlusIcon size={20} data-testid="add-data-icon" className="mr-1" />
                      {t('components.dialogs.roles.settings.user_role')}
                    </Button>
                    <div>
                      <RoleSettingsTree table={defaultTable} />
                    </div>
                  </div>
                  <div className="w-2/3">
                    <TableSettingsForm
                      key={defaultTable.key}
                      table={defaultTable}
                      onSave={closeDialog}
                      isUser
                    />
                  </div>
                </div>
              )}
              {selectedSetting === 'approval-template' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <span className="text-subtle">
                      {t('components.dialogs.roles.settings.approval_templates')}
                    </span>
                    <RoleSettingsTree table={defaultTable} allUsersNameOverride="Default" />
                  </div>
                  <div className="w-2/3">
                    <Template
                      profile={defaultTable.profile_key}
                      setting="approval-template"
                      objectKey={defaultTable.key}
                    />
                  </div>
                </div>
              )}
              {selectedSetting === 'account-info-template' && (
                <div className="flex gap-8">
                  <div className="w-1/3 space-y-4">
                    <span className="text-subtle">
                      {t('components.dialogs.roles.settings.account_info_templates')}
                    </span>
                    <RoleSettingsTree table={defaultTable} allUsersNameOverride="Default" />
                  </div>
                  <div className="w-2/3">
                    <Template
                      profile={defaultTable.profile_key}
                      setting="account-info-template"
                      objectKey={defaultTable.key}
                    />
                  </div>
                </div>
              )}
            </Tabs>
          </div>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}

export function RoleSettingsDialog() {
  const { id, settingId, selectedTableKey } = useParams();
  assertTruthiness(id && settingId && selectedTableKey, 'Wrong url parameters');

  const { getObjectByKey } = useObjectHelpers();

  const table = getObjectByKey(selectedTableKey);
  if (!table) return null;

  return (
    <RoleSettingsDialogContent
      defaultTable={table}
      selectedSetting={settingId as RoleSettingsOptions}
    />
  );
}
