import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, Textarea } from '@knack/asterisk-react';

export function FormMessage() {
  const [t] = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Form.Section>
      <Form.Label htmlFor="message" intent={errors.message && 'destructive'}>
        {t('components.data_table.right_sidebar.validation_rules.text_area_label')}
      </Form.Label>
      <Textarea
        id="message"
        intent={errors.message && 'destructive'}
        data-testid="validation-rule-message"
        {...register('message')}
      />
      {errors.message && (
        <Form.Message type="error" data-testid="validation-rule-message-error">
          {errors.message.message as string}
        </Form.Message>
      )}
    </Form.Section>
  );
}
