import { Trans, useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { AddLoginForm } from './AddLoginForm';
import { DeletePageForm } from './DeletePageForm';
import { DropdownMenuSettings } from './DropdownMenuSettings';
import { DuplicatePageForm } from './DuplicatePageForm';
import { type DialogContentType } from './PageDropdownMenu';
import { RemoveLoginForm } from './RemoveLoginForm';

interface DialogContentTypeProps {
  page: BuilderPage;
  closeDialog: () => void;
}

interface DialogContentProps extends DialogContentTypeProps {
  contentType: DialogContentType | null;
}

function DuplicatePageDialogContent({ page, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>{t('pages.dialogs.duplicate.title', { pageName: page.name })}</Dialog.Title>
      </Dialog.Header>
      <DuplicatePageForm page={page} onDuplicate={closeDialog} onCancel={closeDialog} />
    </>
  );
}

function DeletePageDialogContent({ page, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>
          {t(`pages.dialogs.delete.${page.type === 'menu' ? 'title_dropdown' : 'title'}`, {
            pageName: page.name
          })}
        </Dialog.Title>
      </Dialog.Header>
      <Dialog.Description>
        <Trans
          i18nKey={
            page.type === 'menu'
              ? 'pages.dialogs.delete.description_dropdown'
              : 'pages.dialogs.delete.description'
          }
          values={{ pageName: page.name }}
        >
          <span className="font-semibold">pageName</span>
        </Trans>
      </Dialog.Description>
      <DeletePageForm page={page} onDelete={closeDialog} onCancel={closeDialog} />
    </>
  );
}

function RemoveLoginDialogContent({ page, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>{t('pages.dialogs.remove_login.title')}</Dialog.Title>
      </Dialog.Header>
      <RemoveLoginForm page={page} onRemove={closeDialog} onCancel={closeDialog} />
    </>
  );
}

function AddLoginDialogContent({ page, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>{t('pages.dialogs.add_login.title')}</Dialog.Title>
        <Dialog.Description>{t('pages.dialogs.add_login.description')}</Dialog.Description>
      </Dialog.Header>
      <AddLoginForm page={page} closeDialog={closeDialog} />
    </>
  );
}

function MenuSettingsDialogContent({ page, closeDialog }: DialogContentTypeProps) {
  const [t] = useTranslation();

  return (
    <>
      <Dialog.Header>
        <Dialog.Title>
          {t('pages.dialogs.menu_settings.title', { pageName: page.name })}
        </Dialog.Title>
      </Dialog.Header>
      <DropdownMenuSettings page={page} onSubmitCallback={closeDialog} onCancel={closeDialog} />
    </>
  );
}

export function DialogContent({ contentType, page, closeDialog }: DialogContentProps) {
  if (!contentType) {
    return null;
  }

  return (
    <Dialog.Content data-testid="page-dropdown-menu-dialog-content">
      <Dialog.MainContent className="flex flex-col gap-4">
        {contentType === 'duplicate' && (
          <DuplicatePageDialogContent page={page} closeDialog={closeDialog} />
        )}

        {contentType === 'delete' && (
          <DeletePageDialogContent page={page} closeDialog={closeDialog} />
        )}

        {contentType === 'remove_login' && (
          <RemoveLoginDialogContent page={page} closeDialog={closeDialog} />
        )}

        {contentType === 'add_login' && (
          <AddLoginDialogContent page={page} closeDialog={closeDialog} />
        )}

        {contentType === 'menu_settings' && (
          <MenuSettingsDialogContent page={page} closeDialog={closeDialog} />
        )}
      </Dialog.MainContent>
    </Dialog.Content>
  );
}
