import { type BuilderViewBase, type BuilderViewKey } from '@/types/schema/BuilderView';
import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';

export const LOGIN_VIEW_REGISTRATION_TYPES = ['open', 'approval', 'closed'] as const;
export type LoginViewRegistrationType = (typeof LOGIN_VIEW_REGISTRATION_TYPES)[number];

export interface LoginView extends BuilderViewBase {
  type: 'login';
  allowedProfileKeys: KnackObjectProfileKey[];
  registrationType: LoginViewRegistrationType;
  registrationForms?:
    | {
        // Using `Record<string, never>` to represent an empty object, which is what is passed as the value when creating a new registration form for a profile.
        // Once the new registration view is created in the server, the empty object is replaced with the key of the new view.
        [key in `profile_${string}` | 'all_users']?: BuilderViewKey | null | Record<string, never>;
      }
    // Can sometimes be an empty object if profile access is limited but no profiles have been selected
    | Record<string, never>;
  limitProfileAccess: boolean;
  submitButtonText: string;
  sso_google?: boolean;
  columns: unknown[];
  links: unknown[];
  inputs: unknown[];
}
