import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';

interface GetEdgeConnectionDetailParams {
  connectionType: 'incoming' | 'outgoing';
  connection: KnackConnection;
  object: KnackObject;
  knackObjects: KnackObject[];
}

export function getEdgeConnectionDetail({
  connectionType,
  connection,
  object,
  knackObjects
}: GetEdgeConnectionDetailParams) {
  if (connectionType === 'incoming') {
    const connectionSourceObject = knackObjects.find((obj) => obj.key === connection.object);

    if (!connectionSourceObject) {
      return undefined;
    }

    const sourceNameWithInflection =
      connection.belongs_to === 'one'
        ? connectionSourceObject.inflections.singular
        : connectionSourceObject.inflections.plural;

    const targetNameWithInflection =
      connection.has === 'one' ? object.inflections.singular : object.inflections.plural;

    return {
      sourceHas: connection.has,
      sourceBelongsTo: connection.belongs_to,
      sourceTableType: connectionSourceObject.type,
      sourceTableName: sourceNameWithInflection,
      targetTableType: object.type,
      targetTableName: targetNameWithInflection
    };
  }

  const connectionTargetObject = knackObjects.find((obj) => obj.key === connection.object);

  if (!connectionTargetObject) {
    return undefined;
  }

  const sourceNameWithInflection =
    connection.belongs_to === 'one' ? object.inflections.singular : object.inflections.plural;

  const targetNameWithInflection =
    connection.has === 'one'
      ? connectionTargetObject.inflections.singular
      : connectionTargetObject.inflections.plural;

  return {
    sourceHas: connection.has,
    sourceBelongsTo: connection.belongs_to,
    sourceTableType: object.type,
    sourceTableName: sourceNameWithInflection,
    targetTableType: connectionTargetObject.type,
    targetTableName: targetNameWithInflection
  };
}
