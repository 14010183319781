import { nanoid } from 'nanoid';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  VIEW_COLUMN_ID_PREFIX,
  VIEW_GROUP_ID_PREFIX,
  type BuilderViewSourceSchema
} from '@/types/schema/BuilderView';
import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  LIST_VIEW_DIVIDER_INPUT_ID_PREFIX,
  LIST_VIEW_RICH_TEXT_INPUT_ID_PREFIX,
  type ListView,
  type ListViewInput
} from '@/types/schema/views/ListView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const LIST_MAX_INPUT_FIELD_NUMBER = 7;
const INPUT_FIELD_ROWS_PER_PAGE = '25';

const baseListViewSchema: Partial<ListView> = {
  type: 'list',
  description: '',
  groups: [],
  inputs: [],
  links: [],
  preset_filters: [],
  menu_filters: [],
  label_format: 'left',
  layout: 'full',
  list_layout: 'full',
  rows_per_page: INPUT_FIELD_ROWS_PER_PAGE,
  keyword_search: true,
  keyword_search_fields: 'view',
  filter_fields: 'view',
  filter_type: 'fields',
  allow_exporting: false,
  allow_preset_filters: false,
  allow_limit: true,
  hide_fields: false,
  rules: {
    fields: []
  }
};

const baseListViewInputSchema: Partial<ListViewInput> = {
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left'
  },
  img_gallery: '',
  show_map: false,
  map_width: 400,
  map_height: 300,
  value: '',
  copy: '',
  format: {
    label_custom: false,
    label_format: 'left',
    styles: []
  }
};

export function generateListViewFieldInput(field: KnackField) {
  return {
    ...baseListViewInputSchema,
    id: `${field.key}_${nanoid(10)}`,
    type: 'field',
    key: field.key,
    name: field.name
  } satisfies Partial<ListViewInput> as ListViewInput;
}

export function generateListViewRichTextInput() {
  return {
    ...baseListViewInputSchema,
    id: `${LIST_VIEW_RICH_TEXT_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'special_title'
  } satisfies Partial<ListViewInput> as ListViewInput;
}

export function generateListViewDividerInput() {
  return {
    ...baseListViewInputSchema,
    id: `${LIST_VIEW_DIVIDER_INPUT_ID_PREFIX}${nanoid(10)}`,
    type: 'divider'
  } satisfies Partial<ListViewInput> as ListViewInput;
}

function generateListViewInputs({
  page,
  viewSourceObject,
  viewSourceSchema
}: {
  page: BuilderPage;
  viewSourceObject: KnackObject;
  viewSourceSchema: BuilderViewSourceSchema;
}) {
  const listInputs: ListViewInput[] = [];

  let addedInputsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedInputsCount >= LIST_MAX_INPUT_FIELD_NUMBER) {
      return;
    }

    // Name and email fields are allowed by default, so we can add it and skip the rest of the checks
    if (field.type === 'email' || field.type === 'name') {
      listInputs.push(generateListViewFieldInput(field));
      addedInputsCount += 1;
      return;
    }

    // Skip password fields and any other user fields
    if (field.type === 'password' || field.user) {
      return;
    }

    // Skip connection fields if they are connected to the source object of the page
    if (
      field.type === 'connection' &&
      page.sourceObjectKey &&
      field.relationship.object === page.sourceObjectKey
    ) {
      return;
    }

    // Skip immutable fields if the view is behind authentication
    if (viewSourceSchema.authenticated_user && field.immutable) {
      return;
    }

    listInputs.push(generateListViewFieldInput(field));

    addedInputsCount += 1;
  });

  return listInputs;
}

export function getListViewSchema(
  page: BuilderPage,
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const listViewSchema: Partial<ListView> = {
    ...baseListViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: [
      {
        id: `${VIEW_COLUMN_ID_PREFIX}${nanoid(10)}`,
        groups: [
          {
            id: `${VIEW_GROUP_ID_PREFIX}${nanoid(10)}`,
            columns: [
              generateListViewInputs({
                page,
                viewSourceObject: viewSource.object,
                viewSourceSchema
              })
            ]
          }
        ],
        width: 100
      }
    ]
  };

  return listViewSchema;
}
