import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Dialog, Label } from '@knack/asterisk-react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { t as i18nT } from 'i18next';

import { type BuilderView, type KnackViewType } from '@/types/schema/BuilderView';
import { CHART_TYPES, type ChartType, type ReportViewChart } from '@/types/schema/views/ReportView';
import { cn } from '@/utils/tailwind';
import { ReportViewIcon } from '@/components/ReportViewIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { ViewIcon } from '@/components/ViewIcon';
import { AddRecordViewFlowContextProvider } from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlowContext';
import { getNewStaticViewSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/viewSchemas';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';
import { AddRecordViewFlow } from './add-record-view/AddRecordViewFlow';
import { AddEcommerceViewFlow } from './AddEcommerceViewFlow';
import { ECOMMERCE_VIEWS, RECORD_VIEWS, STATIC_VIEWS } from './helpers/constants';
import { type SelectableView } from './helpers/types';
import { useAllowedViewTypes } from './helpers/useAllowedViewTypes';
import { usePageDataSources, type PageDataSource } from './helpers/usePageDataSources';

enum AddViewSteps {
  'selectViewType',
  'viewSubFlow'
}

interface SelectableViewItemProps {
  selectableView: SelectableView;
  isAllowed: boolean;
}

// Current views that are fully supported by the 'add view' flow.
// Expand list as more new view schemas are added in `/helpers/view-schema/viewSchemas.ts`.
const VIEWS_AVAILABLE_FOR_ADD_VIEW_FLOW = [
  'form',
  'rich_text',
  'report',
  'table',
  'list',
  'details'
];

function getViewDisallowedReason(viewType: KnackViewType) {
  if (viewType === 'details' || viewType === 'map' || viewType === 'calendar') {
    return i18nT(`views.add_view_dialog.disallowed_view_reasons.${viewType}`);
  }

  return '';
}

function SelectableViewItem({ selectableView, isAllowed }: SelectableViewItemProps) {
  const [t] = useTranslation();

  const isViewAvailableForAddViewFlow = VIEWS_AVAILABLE_FOR_ADD_VIEW_FLOW.includes(
    selectableView.type
  );
  const canViewBeAdded = isViewAvailableForAddViewFlow && isAllowed;
  const disallowedReason = !isAllowed ? getViewDisallowedReason(selectableView.type) : '';
  const viewIconClasses =
    'mx-auto mb-0.5 size-12 text-subtle group-hover:text-brand-400 group-data-[state=checked]:text-brand-400';

  return (
    <div className="relative">
      <TextTooltip label={disallowedReason} asChild>
        {/* FE-2582 - Explore the possibility of using RadioCardGroup from Asterisk once it's flexible enough to accommodate for this use case */}
        <RadioGroupPrimitive.Item
          disabled={!canViewBeAdded}
          key={selectableView.subType || selectableView.type}
          id={selectableView.subType || selectableView.type}
          value={selectableView.subType || selectableView.type}
          className={cn(
            'flex min-h-28 w-32 items-center justify-center rounded-lg border border-default bg-default p-2 text-sm font-medium',
            {
              'group hover:border-brand hover:bg-brand-50 hover:text-emphasis data-[state=checked]:border-brand data-[state=checked]:bg-brand-50':
                canViewBeAdded,
              'cursor-not-allowed opacity-30': !canViewBeAdded
            }
          )}
        >
          <div className="pointer-events-none cursor-pointer">
            {selectableView.type === 'report' && selectableView.subType ? (
              <ReportViewIcon type={selectableView.subType} className={viewIconClasses} />
            ) : (
              <ViewIcon type={selectableView.type} className={viewIconClasses} />
            )}
            <Label htmlFor={selectableView.type}>{selectableView.label}</Label>
          </div>
        </RadioGroupPrimitive.Item>
      </TextTooltip>

      {!isViewAvailableForAddViewFlow && (
        <Badge
          intent="neutral"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap"
        >
          {t('keywords.coming_soon')}
        </Badge>
      )}
    </div>
  );
}

function AddViewModalContent({ pageDataSources }: { pageDataSources: PageDataSource[] }) {
  const [t] = useTranslation();
  const { messageFromLiveApp } = usePageEditorMessagingContext();
  const { page, setIsAddViewModalOpen, updatePage } = usePageEditorContext();
  const { getPageAllowedViewTypes } = useAllowedViewTypes();

  const [currentStep, setCurrentStep] = useState<AddViewSteps>(AddViewSteps.selectViewType);
  const [selectedViewType, setSelectedViewType] = useState<KnackViewType | undefined>(undefined);
  const [selectedViewSubType, setSelectedViewSubType] = useState<
    ReportViewChart['type'] | undefined
  >(undefined);

  const pageAllowedViewTypes = useMemo(
    () => getPageAllowedViewTypes(page),
    [getPageAllowedViewTypes, page]
  );

  if (!messageFromLiveApp || messageFromLiveApp.action !== 'start-add-view') {
    return null;
  }

  const { columnId, sectionId, originViewKey, position } = messageFromLiveApp;

  let selectedViewCategory: SelectableView['category'] | undefined;
  if (RECORD_VIEWS.some((view) => view.type === selectedViewType)) {
    selectedViewCategory = 'record';
  } else if (STATIC_VIEWS.some((view) => view.type === selectedViewType)) {
    selectedViewCategory = 'static';
  } else if (ECOMMERCE_VIEWS.some((view) => view.type === selectedViewType)) {
    selectedViewCategory = 'ecommerce';
  }

  const handleAddView = (viewSchema: Partial<BuilderView>) => {
    if (!selectedViewType) return;

    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'add',
      columnId,
      sectionId,
      viewType: selectedViewType,
      viewSchema,
      originViewKey,
      position
    });

    setIsAddViewModalOpen(false);
  };

  const onInitialContinue = () => {
    // Static views do not need any extra configuration so they can be added right away after being selected
    if (selectedViewCategory === 'static' && selectedViewType) {
      handleAddView(getNewStaticViewSchema(selectedViewType));
      return;
    }

    setCurrentStep(AddViewSteps.viewSubFlow);
  };

  return (
    <Dialog open onOpenChange={setIsAddViewModalOpen}>
      <Dialog.Content
        data-testid="add-view-modal"
        width={currentStep === AddViewSteps.selectViewType ? 'lg' : undefined}
      >
        {currentStep === AddViewSteps.selectViewType && (
          <>
            <Dialog.MainContent className="flex max-h-[75vh] flex-col">
              <Dialog.Header>
                <Dialog.Title>{t('views.add_view_dialog.initial_step_title')}</Dialog.Title>
                <Dialog.Description>
                  {t('views.add_view_dialog.initial_step_description')}
                </Dialog.Description>
              </Dialog.Header>
              <div className="flex overflow-auto">
                <div className="mt-6 overflow-y-auto sm:block">
                  {currentStep === AddViewSteps.selectViewType && (
                    <RadioGroupPrimitive.Root
                      onValueChange={(value) => {
                        const isChart = CHART_TYPES.includes(value as ChartType);
                        const type: KnackViewType = isChart ? 'report' : (value as KnackViewType);
                        setSelectedViewType(type);
                        setSelectedViewSubType(
                          isChart ? (value as ReportViewChart['type']) : undefined
                        );
                      }}
                      value={selectedViewSubType || selectedViewType}
                      data-testid="add-view-modal-view-list"
                    >
                      <div className="mb-6">
                        <h2 className="mb-2 text-sm font-medium">
                          {t('views.add_view_dialog.record_views')}
                        </h2>
                        <div className="flex flex-wrap gap-4">
                          {RECORD_VIEWS.map((selectableView) => (
                            <SelectableViewItem
                              key={selectableView.subType || selectableView.type}
                              selectableView={selectableView}
                              isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="mb-6">
                        <h2 className="mb-2 text-sm font-medium">
                          {t('views.add_view_dialog.static_views')}
                        </h2>
                        <div className="flex flex-wrap gap-4">
                          {STATIC_VIEWS.map((selectableView) => (
                            <SelectableViewItem
                              key={selectableView.type}
                              selectableView={selectableView}
                              isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            />
                          ))}
                        </div>
                      </div>

                      <div>
                        <h2 className="mb-2 text-sm font-medium">{t('keywords.ecommerce')}</h2>
                        <div className="flex flex-wrap gap-4">
                          {ECOMMERCE_VIEWS.map((selectableView) => (
                            <SelectableViewItem
                              key={selectableView.type}
                              selectableView={selectableView}
                              isAllowed={pageAllowedViewTypes.includes(selectableView.type)}
                            />
                          ))}
                        </div>
                      </div>
                    </RadioGroupPrimitive.Root>
                  )}
                </div>
              </div>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal" data-testid="add-view-modal-cancel-button">
                  {t('actions.cancel')}
                </Button>
              </Dialog.Close>
              <Button
                onClick={onInitialContinue}
                disabled={!selectedViewType}
                data-testid="add-view-modal-continue-button"
              >
                {t('actions.continue')}
              </Button>
            </Dialog.Footer>
          </>
        )}

        {currentStep === AddViewSteps.viewSubFlow && selectedViewType && (
          <>
            {selectedViewCategory === 'record' && (
              <AddRecordViewFlowContextProvider
                page={page}
                pageDataSources={pageDataSources}
                selectedViewType={selectedViewType}
                selectedViewSubType={selectedViewSubType}
                handleAddView={handleAddView}
                onBack={() => setCurrentStep(AddViewSteps.selectViewType)}
              >
                <AddRecordViewFlow />
              </AddRecordViewFlowContextProvider>
            )}
            {selectedViewCategory === 'ecommerce' && (
              <AddEcommerceViewFlow selectedViewType={selectedViewType} />
            )}
          </>
        )}
      </Dialog.Content>
    </Dialog>
  );
}

export function AddViewModal() {
  const { messageFromLiveApp } = usePageEditorMessagingContext();
  const { isAddViewModalOpen, page } = usePageEditorContext();
  const { getPageDataSources } = usePageDataSources();

  if (
    !isAddViewModalOpen ||
    !messageFromLiveApp ||
    messageFromLiveApp.action !== 'start-add-view'
  ) {
    return null;
  }

  const pageDataSources = getPageDataSources(page);

  // Conditionally render the modal content to force a remount every time it's opened in order to clear any previous state
  return <AddViewModalContent pageDataSources={pageDataSources} />;
}
