import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import DataModelEmptyStateSvg from '@/assets/svg/data-model-empty-state.svg?react';
import { ROUTES } from '@/Router';

function AddTableLink({ linkText }: { linkText: string }) {
  return (
    <Button asChild>
      <Link to={`${ROUTES.TABLES_ADD}?origin=builder-next`}>{linkText}</Link>
    </Button>
  );
}

export function DataModelEmptyState() {
  const [t] = useTranslation();

  return (
    <div className="relative z-10 flex h-full w-full items-center justify-center">
      <div className="my-auto max-w-xl pb-[15vh] text-center">
        <DataModelEmptyStateSvg className="mx-auto mb-8" />
        <h3 className="mb-3 text-xl font-medium text-emphasis">
          {t('components.data_model.empty_state_heading')}
        </h3>
        <p className="mb-6 text-emphasis">{t('components.data_model.empty_state_subheading')}</p>
        <AddTableLink linkText={t('components.data_model.empty_state_link_text')} />
      </div>
    </div>
  );
}
