import { useRef, useState, type FormEvent, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { HiViewColumns as FieldIcon, HiTableCells as StandardTableIcon } from 'react-icons/hi2';
import { useReactFlow } from 'reactflow';
import { InputSearch } from '@knack/asterisk-react';

import { useApplication } from '@/hooks/useApplication';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { type TableNode } from './diagram/TableNodeComponent';
import { useDataModelStore } from './helpers/useDataModelStore';

type TableSearchResult = {
  type: 'table';
  name: string;
  key: string;
};

export type FieldSearchResult = {
  type: 'field';
  name: string;
  key: string;
  tableName: string;
  tableKey: string;
};

interface SearchResults {
  tableResults: TableSearchResult[];
  fieldResults: FieldSearchResult[];
}

interface SearchResultsItemProps {
  result: FieldSearchResult | TableSearchResult;
  onResultSelect: (event: MouseEvent<HTMLButtonElement>, tableKey: string) => void;
}

export function SearchResultsItem({ result, onResultSelect }: SearchResultsItemProps) {
  const Icon = result.type === 'table' ? StandardTableIcon : FieldIcon;
  return (
    <button
      key={result.key}
      data-testid="data-model-search-result-item"
      type="button"
      role="option"
      aria-selected="false"
      className="flex w-full rounded-md p-1 text-left hover:bg-subtle [&:not(:last-child)]:mb-1"
      onClick={(e) => onResultSelect(e, result.type === 'field' ? result.tableKey : result.key)}
    >
      <Icon size="22" className="mr-2 flex-shrink-0 pt-1" />
      <div>
        <span className="block text-base">{result.name}</span>
        {result.type === 'field' && (
          <span className="block text-sm text-subtle">{result.tableName}</span>
        )}
      </div>
    </button>
  );
}

export function DataModelSearch() {
  const [t] = useTranslation();
  const application = useApplication();
  const knackObjects = application?.objects;

  const containerRef = useRef(null);

  const [isResultsListOpen, setResultsListOpen] = useState(false);
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResults>({
    tableResults: [],
    fieldResults: []
  });

  const { tableResults: tableSearchResults, fieldResults: fieldSearchResults } = searchResults;

  const { getNode, setCenter } = useReactFlow();
  const { onSelectionChange } = useDataModelStore((state) => ({
    onSelectionChange: state.onSelectionChange
  }));

  useOnClickOutside(containerRef, () => {
    setResultsListOpen(false);
  });

  const onSearchHandle = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const searchValue = formData.get('search');

    if (searchValue) {
      const resultAccumulator: SearchResults = {
        tableResults: [],
        fieldResults: []
      };

      const results = knackObjects?.reduce(({ fieldResults, tableResults }, object) => {
        const fields = object.fields.filter((field) =>
          field.name.toLowerCase().includes(searchValue.toString().toLowerCase())
        );

        if (fields.length > 0) {
          fieldResults.push(
            ...fields.map(
              (field) =>
                ({
                  type: 'field',
                  name: field.name,
                  key: field.key,
                  tableName: object.name,
                  tableKey: object.key
                }) as FieldSearchResult
            )
          );
        }

        if (object.name.toLowerCase().includes(searchValue.toString().toLowerCase())) {
          tableResults.push({ type: 'table', name: object.name, key: object.key });
        }

        return { fieldResults, tableResults };
      }, resultAccumulator);

      setSearchResults(results ?? { tableResults: [], fieldResults: [] });
      setHasActiveSearch(true);
    } else {
      setHasActiveSearch(false);
      setSearchResults({ tableResults: [], fieldResults: [] });
    }
  };

  const onResultSelect = (event: MouseEvent<HTMLButtonElement>, tableKey: string) => {
    event.preventDefault();

    const node = getNode(tableKey) as TableNode;

    // We need to add half of the node's width to the node's X position to center the node at the center of the viewport horizontally.
    const xPosition = (node?.position.x as number) + (node?.width as number) / 2;

    // We can't position the node at the center of the viewport vertically, because if the node is taller than the viewport, it will be pulled up so much that the top of the node will not be visible.
    // To address this, we need to use a fixed pixel value to add some clearance/space to the node's Y position so that the node will be pulled up just enough to be visually centered, while also showing the top of the node.
    const yPosition = (node?.position.y as number) + 250;

    setCenter(xPosition, yPosition, { duration: 500, zoom: 1 });
    onSelectionChange({ nodes: [node], edges: [] });
  };

  return (
    <div className="relative w-60 text-sm" ref={containerRef}>
      <form onSubmit={onSearchHandle}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="searchLabel" className="sr-only">
          {t('components.data_model.search_placeholder')}
        </label>
        <InputSearch
          id="searchLabel"
          onFocus={() => setResultsListOpen(true)}
          name="search"
          role="combobox"
          aria-expanded={isResultsListOpen}
          placeholder={t('components.data_model.search_placeholder')}
          className="text-sm"
          data-testid="data-model-search-input"
        />
      </form>
      {isResultsListOpen && (
        <div
          className="absolute top-[45px] z-10 max-h-[450px] w-full overflow-y-auto rounded-xl bg-menu shadow-md"
          role="group"
        >
          <span
            className="sticky top-0 block bg-menu p-4 text-subtle"
            data-testid="data-model-search-result-count"
          >
            {hasActiveSearch
              ? t('components.data_model.search_results', {
                  count: fieldSearchResults.length + tableSearchResults.length
                })
              : t('components.data_model.search_enter_to_search')}
          </span>
          {(tableSearchResults.length > 0 || fieldSearchResults.length > 0) && (
            <div className="px-4 pb-4">
              {tableSearchResults.length > 0 && (
                <>
                  <div className="my-2 h-[1px] bg-subtle" />
                  <p className="py-2 text-sm text-subtle">{t('components.data_model.tables')}</p>
                </>
              )}
              {tableSearchResults.map((result) => (
                <SearchResultsItem
                  result={result}
                  key={result.key}
                  onResultSelect={onResultSelect}
                />
              ))}
              {fieldSearchResults.length > 0 && (
                <>
                  <div className="my-2 h-[1px] bg-subtle" />
                  <p className="py-2 text-sm text-subtle">{t('components.data_model.fields')}</p>
                </>
              )}
              {fieldSearchResults.map((result) => (
                <SearchResultsItem
                  result={result}
                  key={result.key}
                  onResultSelect={onResultSelect}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
