import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

export function UniqueSwitch() {
  const [t] = useTranslation();

  const { control } = useFormContext();

  return (
    <div className="flex items-center">
      <Controller
        control={control}
        name="unique"
        render={({ field: { onChange, value } }) => (
          <Switch
            id="field-settings-unique-switch"
            className="mr-2"
            data-testid="unique-value-switch"
            checked={value}
            onCheckedChange={onChange}
          />
        )}
      />
      <Label htmlFor="field-settings-unique-switch">
        {t('components.data_table.attributes.field_settings.common.unique_value')}
      </Label>
    </div>
  );
}
