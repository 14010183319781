import { DateTimeCalendarEdit } from '@/components/data-table/display/fields/date-time/calendar/DateTimeCalendarEdit';
import { formatDateTimeRawValue } from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import { type DateTimeField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function DateTimeCalendarRender(props: FieldRenderProps<DateTimeField>) {
  const { rawValue, rowId, fieldId, type, isFloating } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  if (isFloating) {
    return <DateTimeCalendarEdit {...props} />;
  }

  return (
    <div
      className="flex size-full flex-col p-2"
      data-testid={`date-time-calendar-render-${rowId}-${fieldId}`}
    >
      <p>{formatDateTimeRawValue(rawValue, currentField.format)}</p>
    </div>
  );
}
