import { type Theming } from '@knack/asterisk-react';
import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance } from '@/utils/axiosConfig';

export async function getTheming(accountSlug: string, appSlug: string): Promise<Theming> {
  const { data } = await axiosInstance.get(
    `/v1/builder/${accountSlug}/application/${appSlug}/theming`,
    {
      withCredentials: true
    }
  );

  return data;
}

export function useThemingQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.theming, accountSlug, appSlug],
    queryFn: () => getTheming(accountSlug, appSlug),
    staleTime: 1000 * 60 * 5
  });
}
