import { useTranslation } from 'react-i18next';
import { Badge, Tabs, type TabsListItem } from '@knack/asterisk-react';

import { ViewDetailsFieldManagement } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldManagement';

export function DetailsDataDisplaySettingsCategory() {
  const [t] = useTranslation();

  const tabListItems: TabsListItem[] = [
    {
      value: 'fields',
      children: t('keywords.fields')
    },
    {
      value: 'display-rules',
      children: t(
        'pages.element_settings.common.categories.data_display.field_management.display_rules'
      )
    }
  ];

  return (
    <Tabs defaultValue="fields">
      <Tabs.List items={tabListItems} intent="page" shouldDisableResponsive shouldUseGradient />
      <Tabs.Content value="fields" className="p-0 text-default">
        <ViewDetailsFieldManagement />
      </Tabs.Content>
      <Tabs.Content value="display-rules" className="p-0 text-default">
        <Badge className="px-2 py-0.5 text-xs text-default">Coming Soon</Badge>
      </Tabs.Content>
    </Tabs>
  );
}
