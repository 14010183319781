import { Trans, useTranslation } from 'react-i18next';
import { HiLockClosed as LockIcon, HiGlobeAmericas as PublicIcon } from 'react-icons/hi2';
import { generatePath, Link } from 'react-router-dom';
import { Divider } from '@knack/asterisk-react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { ROUTES } from '@/Router';
import { LoginPageAccessSettings } from './LoginPageAccessSettings';
import { usePageAccessSettings } from './usePageAccessSettings';
import { UserPageAccessSettings } from './UserPageAccessSettings';

interface PageAccessBannerProps {
  limitProfileAccess: boolean;
  allowedUserRoleObjects: KnackObject[];
}

function PageAccessBanner({ limitProfileAccess, allowedUserRoleObjects }: PageAccessBannerProps) {
  const [t] = useTranslation();

  function getAccessBannerContent() {
    if (!limitProfileAccess) {
      return t('pages.settings.access.all_logged_in_users_can_access_page');
    }

    if (allowedUserRoleObjects.length === 0) {
      return t('pages.settings.access.no_roles_can_access_page');
    }

    if (allowedUserRoleObjects.length === 1) {
      return (
        <Trans
          i18nKey="pages.settings.access.only_role_can_access_page"
          components={[<span key="0" className="font-medium" />]}
          values={{
            roleName: allowedUserRoleObjects[0].name
          }}
        />
      );
    }

    return t('pages.settings.access.roles_can_access', {
      count: allowedUserRoleObjects.length
    });
  }

  return (
    <div className="mt-4 flex items-center text-subtle">
      <LockIcon size={16} />
      <span className="ml-1 text-xs">{getAccessBannerContent()}</span>
    </div>
  );
}

export function PageAccessSettings() {
  const [t] = useTranslation();

  const { page } = usePageEditorContext();
  const pageAccessSettings = usePageAccessSettings();

  // If there are no page access settings it means it's a public page, so we only show a simple banner
  if (!pageAccessSettings) {
    return (
      <div className="mt-4 flex items-center text-subtle">
        <PublicIcon size={16} />
        <span className="ml-1 text-xs">
          {t('pages.settings.access.page_is_publicly_accessible')}
        </span>
      </div>
    );
  }

  const { limitProfileAccess, userRoleObjects, allowedUserRoleObjects, loginPage, loginView } =
    pageAccessSettings;

  return (
    <>
      <PageAccessBanner
        limitProfileAccess={limitProfileAccess}
        allowedUserRoleObjects={allowedUserRoleObjects}
      />

      <Divider className="my-4" />

      {/* For user pages */}
      {page.type === 'user' && <UserPageAccessSettings userRoleObjects={userRoleObjects} />}

      {/* For login pages */}
      {page.type === 'authentication' && loginView && (
        <LoginPageAccessSettings loginView={loginView} userRoleObjects={userRoleObjects} />
      )}

      {/* For child pages of a login page */}
      {page.type !== 'authentication' && loginPage && (
        <span className="text-xs text-subtle">
          <Trans
            i18nKey="pages.settings.access.go_to_page_to_edit_permissions"
            components={[
              <Link
                key="0"
                to={generatePath(ROUTES.PAGES_ID, { id: loginPage.key })}
                className="underline"
              >
                {loginPage.name}
              </Link>
            ]}
            values={{
              pageName: loginPage.name
            }}
          />
        </span>
      )}
    </>
  );
}
