import { useTranslation } from 'react-i18next';

import { ResizableCollapsiblePanel } from '@/components/layout/ResizableCollapsiblePanel';
import { LeftPanelContent } from '@/pages/themes/theme-editor/LeftPanelContent';
import { PreviewBar } from '@/pages/themes/theme-editor/PreviewBar';
import { PreviewPage } from '@/pages/themes/theme-editor/PreviewPage';

export function ThemeEditor() {
  const [t] = useTranslation();

  return (
    <ResizableCollapsiblePanel
      title={t('themes.left_panel_title')}
      autoSaveId="theme-editor-left-panel"
      panelContent={<LeftPanelContent />}
      mainContent={
        <>
          <PreviewBar />
          <PreviewPage />
        </>
      }
    />
  );
}
