import { useTranslation } from 'react-i18next';
import { Label, RadioCardGroup, Switch, type CornerStyle } from '@knack/asterisk-react';

import { useThemesPageContext } from '@/pages/themes/ThemesPageContext';

export function Appearance() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const cornersOptions = [
    {
      i18nKey: 'corners_rounded',
      value: 'rounded'
    },
    {
      i18nKey: 'corners_sharp',
      value: 'sharp'
    }
  ];

  return (
    <div>
      <div className="my-2 text-sm">{t('themes.corners')}</div>
      <RadioCardGroup
        value={theme.appearance.corners}
        onValueChange={(value) => {
          setThemeField((draft) => {
            draft.appearance.corners = value as CornerStyle;
          });
        }}
      >
        {cornersOptions.map((option) => (
          <RadioCardGroup.Card
            key={option.value}
            value={option.value}
            className="items-center p-3 outline-2 sm:p-3"
            width="100%"
          >
            {t(`themes.${option.i18nKey}`)}
          </RadioCardGroup.Card>
        ))}
      </RadioCardGroup>
      <div className="mb-2 mt-4 flex items-center gap-2">
        <Switch
          id="shadows-in-cards-toggle"
          checked={theme.appearance.shadowsInCards}
          onCheckedChange={(state) =>
            setThemeField((draft) => {
              draft.appearance.shadowsInCards = state;
            })
          }
        />
        <Label htmlFor="shadows-in-cards-toggle" className="text-sm">
          {t('themes.shadows_in_cards')}
        </Label>
      </div>
    </div>
  );
}
