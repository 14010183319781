import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';

import { type ReportViewCalculation } from '@/types/schema/views/ReportView';

export function CalculationSelector({
  id,
  defaultValue,
  onValueChange
}: {
  id: string;
  defaultValue: ReportViewCalculation['calculation'];
  onValueChange: (value: ReportViewCalculation['calculation']) => void;
}) {
  const [t] = useTranslation();

  return (
    <Select value={defaultValue} onValueChange={onValueChange}>
      <Select.Trigger
        id={id}
        placeholder={`${t('actions.select')}`}
        className="w-full"
        data-testid={id}
      />
      <Select.Content>
        <Select.Item value="sum">{t('keywords.sum')}</Select.Item>
        <Select.Item value="average">{t('keywords.average')}</Select.Item>
        <Select.Item value="min">{t('keywords.minimum')}</Select.Item>
        <Select.Item value="max">{t('keywords.maximum')}</Select.Item>
      </Select.Content>
    </Select>
  );
}
