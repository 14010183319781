import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getAccountInfoTemplate(profileId: string): Promise<string> {
  const { data } = await axios.get(`/v1/profiles/${profileId}/user_templates/intros`, {
    withCredentials: true
  });
  return data.template;
}

export function useAccountInfoTemplateQuery(profileId: string) {
  return useQuery({
    queryKey: [queryKeys.accountInfoTemplates, profileId],
    queryFn: async () => getAccountInfoTemplate(profileId)
  });
}
