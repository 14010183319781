import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

import type { ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function ShowTotal() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  return (
    <div className="mb-2 flex items-center gap-2">
      <Switch
        id="show-total"
        checked={view.rows[0].reports[0].layout.shouldShowTotal}
        disabled={view.rows[0].reports[0].layout.pie_type === 'filled'}
        onCheckedChange={(state) => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].layout.shouldShowTotal = state;
          updateViewSchema(newSchema);
        }}
      />
      <Label htmlFor="show-total">
        {t('pages.element_settings.report.categories.general.show_total')}
      </Label>
    </div>
  );
}
