import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

export function DefaultInputValue({ name, defaultValue }: { name: string; defaultValue: string }) {
  const [t] = useTranslation();
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <Input
          value={value}
          placeholder={t('components.data_table.filtering.enter_value')}
          data-testid="field-default"
          onChange={(event) => onChange(event.target.value)}
        />
      )}
    />
  );
}
