import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export enum FieldsVisibilityStatus {
  ALL_FIELDS = 'all-fields',
  ONLY_CONNECTIONS = 'only-connections'
}

export enum FieldsCollapsibleStatus {
  ALL_COLLAPSED = 'all-collapsed',
  ALL_EXPANDED = 'all-expanded',
  MIXED = 'mixed'
}

type DataModelViewOptionsState = {
  isMultiSelect: boolean;
  showGrid: boolean;
  showFields: boolean;
  showFieldKeys: boolean;
  showAllEdges: boolean;
  showConnectionTypeLabels: boolean;
  fieldsVisibilityStatus: FieldsVisibilityStatus;
  fieldsCollapsibleStatus: FieldsCollapsibleStatus;
  toggleIsMultiSelect: () => void;
  setShowGrid: (showGrid: boolean) => void;
  setShowFields: (showFields: boolean) => void;
  setShowFieldKeys: (showFieldKeys: boolean) => void;
  setShowAllEdges: (showAllEdges: boolean) => void;
  setShowConnectionTypeLabels: (showConnectionTypeLabels: boolean) => void;
  setFieldsVisibilityStatus: (fieldsVisibilityStatus: FieldsVisibilityStatus) => void;
  setFieldsCollapsibleStatus: (fieldsCollapsibleStatus: FieldsCollapsibleStatus) => void;
};

const store = createWithEqualityFn<DataModelViewOptionsState>(
  (set, get) => ({
    isMultiSelect: false,
    showGrid: true,
    showFields: true,
    showFieldKeys: true,
    showAllEdges: true,
    showConnectionTypeLabels: false,
    fieldsVisibilityStatus: FieldsVisibilityStatus.ALL_FIELDS,
    fieldsCollapsibleStatus: FieldsCollapsibleStatus.ALL_EXPANDED,
    toggleIsMultiSelect: () => {
      set({ isMultiSelect: !get().isMultiSelect });
    },
    setShowGrid: (showGrid: boolean) => {
      set({ showGrid });
    },
    setShowFields: (showFields: boolean) => {
      set({ showFields });
    },
    setShowFieldKeys: (showFieldKeys: boolean) => {
      set({ showFieldKeys });
    },
    setShowAllEdges: (showAllEdges: boolean) => {
      set({ showAllEdges });
    },
    setShowConnectionTypeLabels: (showConnectionTypeLabels: boolean) => {
      set({ showConnectionTypeLabels });
    },
    setFieldsVisibilityStatus: (fieldsVisibilityStatus: FieldsVisibilityStatus) => {
      set({ fieldsVisibilityStatus });
    },
    setFieldsCollapsibleStatus: (fieldsCollapsibleStatus: FieldsCollapsibleStatus) => {
      set({ fieldsCollapsibleStatus });
    }
  }),
  shallow
);

export const useDataModelViewOptionsStore = store;
