type GoogleMapsAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type GoogleMapsAddressGeometry = {
  location: {
    toJSON: () => {
      lat: number;
      lng: number;
    };
  };
};

export type GoogleMapsPlace = {
  address_components: GoogleMapsAddressComponent[];
  formatted_address: string;
  geometry?: GoogleMapsAddressGeometry;
  name?: string;
};

export function formatGoogleAutocomplete(googleMapsPlace: GoogleMapsPlace) {
  const { lat, lng } = googleMapsPlace.geometry?.location?.toJSON() || {};
  const addressComponents = googleMapsPlace.address_components || [];
  const streetComponents = addressComponents.filter(
    (component: GoogleMapsAddressComponent) =>
      component?.types.includes('street_number') || component?.types.includes('route')
  );

  // Helper to get the value from the address component
  const getComponentValue = (type: string, nameType: string) => {
    const value = addressComponents.find((component) => component.types.includes(type));
    return value ? value[nameType] : '';
  };

  return {
    street: streetComponents.length
      ? streetComponents
          .map((component: GoogleMapsAddressComponent) => component.long_name)
          .join(' ')
      : '',
    street2: '',
    city: getComponentValue('locality', 'long_name'),
    state: getComponentValue('administrative_area_level_1', 'short_name'),
    zip: getComponentValue('postal_code', 'long_name'),
    country: getComponentValue('country', 'long_name'),
    latitude: lat && lng ? lat : null,
    longitude: lat && lng ? lng : null
  };
}
