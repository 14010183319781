import { type LinkField as LinkFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { LinkEdit } from '@/components/data-table/display/fields/link/LinkEdit';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function LinkRender(props: FieldRenderProps<LinkFieldDisplay>) {
  const { rawValue, rowId, fieldId, isFloating, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { format: linkFormat } = currentField;

  if (isFloating) {
    return <LinkEdit {...props} />;
  }

  const getLinkLabel = () => {
    if (linkFormat?.text_format === 'same') {
      return linkFormat?.label;
    }

    if (linkFormat?.text_format === 'unique' && rawValue.label) {
      return rawValue.label;
    }

    return rawValue.url;
  };

  const getUrlWithProtocol = () => {
    if (!rawValue?.url.includes('http') && !rawValue?.url.includes('https')) {
      return `//${rawValue.url}`;
    }

    return rawValue.url;
  };

  return (
    <div className="flex size-full p-2" data-testid={`link-render-${rowId}-${fieldId}`}>
      <p className="w-full truncate underline">
        {rawValue.url && (
          <a target={linkFormat?.target} href={getUrlWithProtocol()} rel="noreferrer">
            {getLinkLabel() || ''}
          </a>
        )}
      </p>
    </div>
  );
}
