export async function copyToClipboard(text: string) {
  // The following "hack" is needed to copy to clipboard in dev mode
  // because navigator.clipboard is only supported in https urls and localhost
  if (import.meta.env.DEV) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Unable to copy to clipboard');
    }

    document.body.removeChild(textArea);
  } else if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  }
}
