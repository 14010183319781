import { useTranslation } from 'react-i18next';
import { Tabs } from '@knack/asterisk-react';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { Layout } from '@/pages/themes/theme-editor/layout/Layout';
import { Styles } from '@/pages/themes/theme-editor/styles/Styles';

export function LeftPanelContent() {
  const [t] = useTranslation();

  const items = [
    {
      children: <span>{t('themes.styles')}</span>,
      value: 'styles'
    },
    {
      children: <span>{t('themes.layout')}</span>,
      value: 'layout'
    }
  ];

  return (
    <CollapsiblePanel.Content title={t('themes.left_panel_title')} shouldHideDivider>
      <div className="pb-4">
        <Tabs defaultValue="styles">
          <Tabs.List items={items} intent="page" shouldDisableResponsive shouldUseGradient />
          <Tabs.Content value="styles">
            <Styles />
          </Tabs.Content>
          <Tabs.Content value="layout">
            <Layout />
          </Tabs.Content>
        </Tabs>
      </div>
    </CollapsiblePanel.Content>
  );
}
