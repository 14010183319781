import { cn } from '@/utils/tailwind';

interface ViewSourceBannerProps {
  children: React.ReactNode;
  className?: string;
}

function ViewSourceBannerObjectName({ objectName }: { objectName: string }) {
  return <span className="font-medium">{objectName}</span>;
}

function ViewSourceBanner({ className, children }: ViewSourceBannerProps) {
  return <div className={cn('rounded-lg bg-subtle p-2 text-xs', className)}>{children}</div>;
}

const CompoundViewSourceBanner = Object.assign(ViewSourceBanner, {
  ObjectName: ViewSourceBannerObjectName
});

export { CompoundViewSourceBanner as ViewSourceBanner };
