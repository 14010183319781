import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowTitle() {
  const [t] = useTranslation();
  const { selectedViewSource, currentStep } = useAddRecordViewFlowContext();

  const getTitle = () => {
    if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
      return t('views.add_view_dialog.select_source_table');
    }

    if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
      if (selectedViewSource?.recordDisplayQuantity === 'one') {
        return t('views.add_view_dialog.select_record_display_option');
      }
      return t('views.add_view_dialog.select_records_display_option');
    }
    return t('views.add_view_dialog.confirm_add_view.title');
  };

  return <Dialog.Title>{getTitle()}</Dialog.Title>;
}
