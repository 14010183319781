import { useContext } from 'react';
import { type AppTheme, type ThemeMode } from '@knack/asterisk-react';

import { createMessagingContext, MessagingContextProvider } from '@/contexts/MessagingContext';

export type MessageToLiveApp =
  | {
      action: 'set-theme';
      theme: AppTheme;
    }
  | {
      action: 'set-theme-preview-mode';
      mode: ThemeMode;
    };

export type MessageFromLiveApp = {
  action: 'request-page-sync';
};

const ThemesMessagingContext = createMessagingContext<MessageToLiveApp, MessageFromLiveApp>();

export function ThemesMessagingContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <MessagingContextProvider context={ThemesMessagingContext}>{children}</MessagingContextProvider>
  );
}

export const useThemesMessagingContext = () => {
  const context = useContext(ThemesMessagingContext);
  if (!context) {
    throw new Error(
      'useThemesMessagingContext must be used within a ThemesMessagingContextProvider'
    );
  }
  return context;
};
