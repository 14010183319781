import { ReactFlowProvider } from 'reactflow';

import { DataModelHeader } from './DataModelHeader';
import { DataModelDiagram } from './diagram/DataModelDiagram';

import './dataModelStyles.css';

export function DataModelPage() {
  return (
    <ReactFlowProvider>
      <div className="flex h-screen flex-col">
        <DataModelHeader />
        <DataModelDiagram />
      </div>
    </ReactFlowProvider>
  );
}
