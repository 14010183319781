import { type ShortTextField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ShortTextEdit } from '@/components/data-table/display/fields/short-text/ShortTextEdit';

export function ShortTextRender(props: FieldRenderProps<ShortTextField>) {
  const { value, rowId, fieldId, isFloating } = props;

  if (isFloating) {
    return <ShortTextEdit {...props} />;
  }

  return (
    <div className="flex size-full p-2" data-testid={`short-text-render-${rowId}-${fieldId}`}>
      <p className="truncate">{value}</p>
    </div>
  );
}
