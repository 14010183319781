import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { KnackTableIcon } from '@/components/KnackTableIcon';
import { useAddRecordViewFlowContext } from '@/pages/pages/page-editor/add-view/add-record-view/AddRecordViewFlowContext';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';

function ViewSourceListItem({ viewSource }: { viewSource: ViewSource }) {
  return (
    <RadioGroupPrimitive.Item
      id={viewSource.object.key}
      value={viewSource.object.key}
      className="flex w-full items-center rounded-lg border border-default bg-default p-2 text-sm font-medium hover:border-emphasis hover:bg-subtle hover:text-emphasis data-[state=checked]:border-emphasis data-[state=checked]:bg-subtle data-[state=checked]:text-emphasis [&:not(:last-child)]:mb-2"
    >
      <KnackTableIcon tableType={viewSource.object.type} className="mr-2 shrink-0" />
      <Label htmlFor={viewSource.object.key} className="pointer-events-none">
        {viewSource.object.inflections.plural}
      </Label>
    </RadioGroupPrimitive.Item>
  );
}

function FormViewSources() {
  const [t] = useTranslation();
  const { viewSourcesForFormView } = useAddRecordViewFlowContext();
  const { selectedFormRecordAction, setSelectedFormRecordAction, setSelectedViewSource } =
    useAddRecordViewFlowContext();

  if (!viewSourcesForFormView) {
    return null;
  }

  const viewSourcesToRender =
    selectedFormRecordAction === 'add'
      ? viewSourcesForFormView.adding
      : viewSourcesForFormView.updating;

  return (
    <>
      <Select
        value={selectedFormRecordAction}
        onValueChange={(value) => {
          setSelectedFormRecordAction(value as 'add' | 'update');

          // Clear the selected view source when the record display quantity changes (e.g. from 'update' to 'add')
          setSelectedViewSource(undefined);
        }}
      >
        <Select.Trigger className="mb-4 w-full" />
        <Select.Content>
          <Select.Item disabled={viewSourcesForFormView.adding.length === 0} value="add">
            {t('views.add_view_dialog.form_view_select_action_add')}
          </Select.Item>
          <Select.Item disabled={viewSourcesForFormView.updating.length === 0} value="update">
            {t('views.add_view_dialog.form_view_select_action_update')}
          </Select.Item>
        </Select.Content>
      </Select>

      <div className="max-h-[410px] overflow-y-auto">
        {viewSourcesToRender.map((viewSource) => (
          <ViewSourceListItem key={viewSource.object.key} viewSource={viewSource} />
        ))}
      </div>
    </>
  );
}

export function ViewSourceList() {
  const {
    selectedViewType,
    viewSources,
    viewSourcesForFormView,
    selectedViewSource,
    selectedFormRecordAction,
    setSelectedViewSource,
    setSelectedViewSourcePath,
    setSelectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey
  } = useAddRecordViewFlowContext();

  const onValueChange = (newViewSourceKey: string) => {
    let viewSourcesToSearch = viewSources;

    // If the selected view type is a 'form', we need to determine what view sources to search based on the form record action ('add' or 'update')
    if (selectedViewType === 'form' && viewSourcesForFormView) {
      viewSourcesToSearch =
        selectedFormRecordAction === 'add'
          ? viewSourcesForFormView.adding
          : viewSourcesForFormView.updating;
    }

    const newSelectedViewSource = viewSourcesToSearch.find(
      (source) => source.object.key === newViewSourceKey
    );

    setSelectedViewSource(newSelectedViewSource);

    // Clear any selections made by the user in the view source or view source path steps
    setSelectedViewSourcePathDirectConnectionFieldKey(undefined);
    setSelectedViewSourcePathParentConnectionFieldKey(undefined);

    // Select the first view source path by default, if there is one
    if (newSelectedViewSource?.paths.length) {
      setSelectedViewSourcePath(newSelectedViewSource.paths[0]);

      if (newSelectedViewSource.paths[0].connections) {
        // If there are multiple direct connections the user can choose from, default to the first one
        const hasMultipleDirectConnections =
          newSelectedViewSource.paths[0].connections.direct.length > 1;
        if (hasMultipleDirectConnections) {
          setSelectedViewSourcePathDirectConnectionFieldKey(
            newSelectedViewSource.paths[0].connections.direct[0].field.key
          );
        }

        // If there are multiple parent connections the user can choose from, default to the first one
        const hasMultipleParentConnections =
          newSelectedViewSource.paths[0].connections.parent.length > 1;
        if (hasMultipleParentConnections) {
          setSelectedViewSourcePathParentConnectionFieldKey(
            newSelectedViewSource.paths[0].connections.parent[0].field.key
          );
        }
      }
    }
  };

  return (
    <RadioGroupPrimitive.Root
      onValueChange={onValueChange}
      value={selectedViewSource?.object.key || ''}
      data-testid="add-view-modal-view-list"
    >
      {selectedViewType === 'form' ? (
        <FormViewSources />
      ) : (
        <div className="max-h-[410px] overflow-y-auto">
          {viewSources.map((viewSource) => (
            <ViewSourceListItem key={viewSource.object.key} viewSource={viewSource} />
          ))}
        </div>
      )}
    </RadioGroupPrimitive.Root>
  );
}
