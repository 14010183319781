import { Controller, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select } from '@knack/asterisk-react';
import get from 'lodash.get';

import { type ConnectionField } from '@/types/schema/fields';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { cn } from '@/utils/tailwind';

interface ConnectionFieldInputProps<FormSchema extends FieldValues> {
  field: ConnectionField;
  formFieldName: Path<FormSchema>;
  isDisabled?: boolean;
}

export function ConnectionFieldInput<FormSchema extends FieldValues>({
  field,
  formFieldName,
  isDisabled
}: ConnectionFieldInputProps<FormSchema>) {
  const [t] = useTranslation();
  const {
    formState: { errors }
  } = useFormContext();

  const { data: connectionRecords, isPending } = useConnectionRecordsQuery({
    objectKey: field.relationship.object || ''
  });

  const getRecordIdentifier = (recordId: string) =>
    connectionRecords?.records.find((record) => record.id === recordId)?.identifier;

  return (
    <Controller
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        const isValidRecordId =
          Array.isArray(value) && value.length > 0
            ? connectionRecords?.records.some((record) => record.id === value[0])
            : false;

        return (
          <Select
            onValueChange={(newValue) => {
              onChange([newValue]);
            }}
            value={isValidRecordId ? value[0] : undefined}
            disabled={isPending || isDisabled}
          >
            <Select.Trigger
              shouldHideValue
              className={cn('w-full', {
                'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                  get(errors, formFieldName)
              })}
            >
              {isPending
                ? t('keywords.loading')
                : getRecordIdentifier(value[0]) || t('actions.select')}
            </Select.Trigger>
            {connectionRecords && (
              <Select.Content>
                {connectionRecords.records.map((record) => (
                  <Select.Item key={record.id} value={record.id}>
                    {record.identifier}
                  </Select.Item>
                ))}
              </Select.Content>
            )}
          </Select>
        );
      }}
    />
  );
}
