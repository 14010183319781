import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type CurrencyField } from '@/types/schema/fields';

interface CurrencyInputProps {
  targetField: CurrencyField;
  name: string;
  id: string;
}

export function CurrencyInput(props: CurrencyInputProps) {
  const { name, targetField, id } = props;
  const [t] = useTranslation();
  const { setValue: setFormValue, getValues, register } = useFormContext();

  const defaultValue = getValues(name);
  const inputValue = defaultValue;
  const currencyFormat = targetField.format?.format;
  const { ref: formRef, ...formRegister } = register(name);

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      radix: '.',
      padFractionalZeros: true
    },
    {
      defaultValue: inputValue,
      onAccept: (value: string) => {
        setFormValue(name, value);
      }
    }
  );

  return (
    <div className="relative">
      <InputWithDisabledText
        ref={inputRef}
        id={id}
        placeholder={t('components.add_into_existing_table.enter_currency')}
        data-testid="currency-input"
        value={typedValue !== undefined ? iMaskValue : inputValue}
        {...(currencyFormat === '€_after'
          ? { disabledTextRight: '€' }
          : { disabledTextLeft: currencyFormat })}
        {...formRegister}
      />
    </div>
  );
}
