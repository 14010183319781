import { useTranslation } from 'react-i18next';
import { HiOutlineClock as ClockIcon } from 'react-icons/hi2';
import { Checkbox, Divider } from '@knack/asterisk-react';

import { type DateFormatValue } from '@/types/schema/fields';
import {
  DEFAULT_REPEAT_OPTIONS,
  RepeatOptions
} from '@/components/data-table/display/fields/date-time/components/RepeatOptions';
import { type SaveDateTimeCellValue } from '@/components/data-table/display/fields/date-time/components/types';
import { type DateTimeRawValue } from '@/components/data-table/display/fields/Field';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

type FormPropName = 'date' | 'time' | 'endDate' | 'endTime';

type CalendarFooterProps = {
  currentValue?: DateTimeRawValue;
  fieldId: string;
  rowId: string;
  formPropName: FormPropName;
  currentDateTimestamp: string;
  hasAmPmTimeFormat: boolean;
  dateAmPmTimeFormat: string;
  hasEndTime: boolean;
  allDayFormat?: boolean;
  setAllDayFormat?: (value: boolean) => void;
  hasRepeatFormat?: boolean;
  setHasRepeatFormat?: (value: boolean) => void;
  dateFormat?: DateFormatValue;
  saveCell?: (value: SaveDateTimeCellValue) => void;
};

export function CalendarFooter(props: CalendarFooterProps) {
  const {
    currentValue,
    setAllDayFormat,
    allDayFormat,
    hasRepeatFormat,
    setHasRepeatFormat,
    fieldId,
    rowId,
    formPropName,
    hasAmPmTimeFormat,
    dateAmPmTimeFormat,
    hasEndTime,
    currentDateTimestamp,
    dateFormat,
    saveCell
  } = props;
  const [t] = useTranslation();

  const selectedCell = useDataTableStore().use.selectedCell();

  if (!selectedCell) throw new Error('No selected cell');

  const handleCheckRepeatOptions = async () => {
    if (saveCell) {
      await saveCell({
        repeat: hasRepeatFormat ? undefined : DEFAULT_REPEAT_OPTIONS
      });
    }

    if (setHasRepeatFormat) {
      setHasRepeatFormat(!hasRepeatFormat);
    }
  };

  return (
    <>
      <Divider className="my-2" />
      {!allDayFormat && (
        <div className="flex flex-row items-center justify-between rounded-md border border-default p-1">
          <div className="flex items-center">
            <ClockIcon className="mx-1" />
            <p className="text-sm">
              {currentDateTimestamp || t('components.data_table.no_time_selected')}
            </p>
          </div>
          {hasAmPmTimeFormat && (
            <span
              className="ml-1 rounded bg-subtle px-1 text-sm"
              data-testid={`date-picker-${formPropName}-footer-am-pm-${rowId}-${fieldId}`}
            >
              {dateAmPmTimeFormat}
            </span>
          )}
        </div>
      )}
      {hasEndTime && (
        <div className="mt-2 flex items-center truncate p-1">
          <Checkbox
            data-testid={`all-day-${formPropName}-checkbox-${rowId}-${fieldId}`}
            className="mr-2"
            checked={allDayFormat}
            onCheckedChange={() => {
              if (setAllDayFormat) {
                setAllDayFormat(!allDayFormat);
              }
            }}
          />
          <p className="text-sm">
            {t('components.data_table.attributes.field_labels.date_time.all_day')}
          </p>
          <Checkbox
            data-testid={`repeat-${formPropName}-checkbox-${rowId}-${fieldId}`}
            className="mx-2"
            checked={hasRepeatFormat}
            onCheckedChange={handleCheckRepeatOptions}
          />
          <p className="text-sm">
            {t('components.data_table.attributes.field_labels.date_time.repeat')}
          </p>
        </div>
      )}
      {hasRepeatFormat && saveCell && (
        <RepeatOptions
          selectedDate={currentValue?.date_formatted}
          dateFormat={dateFormat || 'mm/dd/yyyy'}
          repeatOptions={currentValue?.repeat}
          saveCell={saveCell}
          fieldId={fieldId}
          rowId={rowId}
        />
      )}
    </>
  );
}
