import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';
import { useQueryClient } from '@tanstack/react-query';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type KnackConnectionRecord } from '@/types/schema/KnackRecord';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { queryKeys } from '@/hooks/api/queryKeys';
import { usePagesPageContext } from '@/pages/pages/PagesPageContext';
import { getSelectedItem } from './helpers/get-selected-item';
import { getUpdatedPageChanges } from './helpers/get-updated-changes';
import {
  type PageChanges,
  type PageEditorItemToSelect,
  type PageEditorSelectedItem,
  type PageEditorUpdate
} from './helpers/types';
import { updatePage } from './helpers/update-page';
import { usePageEditorMessagingContext, type MessageToLiveApp } from './PageEditorMessagingContext';

type PageEditorContextState = {
  page: BuilderPage;
  pageInitialState: BuilderPage;
  pageChanges: PageChanges;
  pageSourceObjectRecords: KnackConnectionRecord[] | null;
  isPageModified: boolean;
  isPreviewMode: boolean;
  isAddViewModalOpen: boolean;
  setIsPreviewMode: (isPreviewMode: boolean) => void;
  setIsAddViewModalOpen: (isOpen: boolean) => void;
  updatePage: (update: PageEditorUpdate) => void;
  savePageChanges: () => void;
  discardPageChanges: () => void;
} | null;

type PageEditorContextProviderProps = {
  activePage: BuilderPage;
  activePageSourceObjectRecords: KnackConnectionRecord[] | null;
  children: React.ReactNode;
};

const PageEditorContext = createContext<PageEditorContextState>(null);

const pageChangesInitialState: PageChanges = {
  inserts: {
    views: [],
    sections: []
  },
  deletes: {
    views: [],
    sections: []
  },
  updates: {
    views: [],
    sections: []
  }
};

export function PageEditorContextProvider({
  activePage,
  activePageSourceObjectRecords,
  children
}: PageEditorContextProviderProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const queryClient = useQueryClient();
  const { updateMutation } = usePageMutation();
  const { messageFromLiveApp, sendMessageToLiveApp, resetMessageFromLiveApp } =
    usePageEditorMessagingContext();
  const { settingsPanelItem, setSettingsPanelItem } = usePagesPageContext();
  // Store the initial page in its original state so it can be reset if the changes are discarded
  const pageInitialState = useRef(activePage);
  const [page, setPage] = useState(activePage);
  const [pageChanges, setPageChanges] = useState<PageChanges>(pageChangesInitialState);
  const [isPageModified, setIsPageModified] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isAddViewModalOpen, setIsAddViewModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      const isPagesQuery = event.query.queryKey.includes(queryKeys.applicationPages);
      if (isPagesQuery && event.type === 'updated' && event.action.type === 'success') {
        sendMessageToLiveApp({ action: 'update-main-navigation' });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient, sendMessageToLiveApp]);

  const resetContextState = useCallback(
    (newPageEditorPage: BuilderPage) => {
      // Update the local page
      setPage(newPageEditorPage);
      pageInitialState.current = newPageEditorPage;

      // Reset other relevant states
      setPageChanges(pageChangesInitialState);
      setIsPageModified(false);
      resetMessageFromLiveApp();
    },
    [resetMessageFromLiveApp]
  );

  const savePageChanges = useCallback(() => {
    // We need to keep track of the index of the view or section that was selected before a save.
    // This is needed so they can be properly re-selected again, since their unique identifiers change after saving (e.g. 'new_view_1NfDB5ETm7' -> 'view_1', 'new_group_LtHxifywJz' -> 'LtHxifywJz')
    let selectedViewIndexBeforeSave: number | undefined;
    let selectedSectionIndexBeforeSave: number | undefined;

    // Track the last selected view or section index before saving so it can be re-selected after saving
    if (settingsPanelItem?.type === 'view') {
      const indexOfLastSelectedView = page.views.findIndex(
        (view) => view.key === settingsPanelItem.view.key
      );
      if (indexOfLastSelectedView >= 0) {
        selectedViewIndexBeforeSave = indexOfLastSelectedView;
      }
    } else if (settingsPanelItem?.type === 'section') {
      const indexOfLastSelectedSection = page.groups.findIndex(
        (section) => section.id === settingsPanelItem.section.id
      );
      if (indexOfLastSelectedSection >= 0) {
        selectedSectionIndexBeforeSave = indexOfLastSelectedSection;
      }
    }

    updateMutation.mutate(
      { updatedPage: page, pageChanges },
      {
        onSuccess: (updatedPage) => {
          presentToast({
            title: t('pages.save_page_success')
          });

          resetContextState(updatedPage);

          const messageToLiveApp: MessageToLiveApp = {
            action: 'update',
            updatedPage
          };

          // If a view or section was selected before saving, re-select it using their index
          if (selectedViewIndexBeforeSave !== undefined) {
            const selectedView = updatedPage.views[selectedViewIndexBeforeSave];
            const selectedItem: PageEditorSelectedItem = {
              type: 'view',
              view: selectedView
            };
            setSettingsPanelItem(selectedItem);
            messageToLiveApp.selectedItem = selectedItem;
          } else if (selectedSectionIndexBeforeSave !== undefined) {
            const selectedSection = updatedPage.groups[selectedSectionIndexBeforeSave] || undefined;
            const selectedItem: PageEditorSelectedItem = {
              type: 'section',
              section: selectedSection
            };
            setSettingsPanelItem(selectedItem);
            messageToLiveApp.selectedItem = selectedItem;
          }

          // Send the updated page to the Live App
          sendMessageToLiveApp(messageToLiveApp);
        },
        onError: () => {
          presentToast({
            title: t('pages.save_page_error')
          });
        }
      }
    );
  }, [
    page,
    pageChanges,
    presentToast,
    resetContextState,
    sendMessageToLiveApp,
    setSettingsPanelItem,
    settingsPanelItem,
    updateMutation,
    t
  ]);

  const discardPageChanges = useCallback(() => {
    resetContextState(pageInitialState.current);

    // If the settings panel item is no longer valid, restore it to an empty state
    if (settingsPanelItem) {
      if (
        settingsPanelItem.type === 'view' &&
        !pageInitialState.current.views.some((view) => view.key === settingsPanelItem.view.key)
      ) {
        setSettingsPanelItem(null);
      }

      if (
        settingsPanelItem.type === 'section' &&
        !pageInitialState.current.groups.some(
          (section) => section.id === settingsPanelItem.section.id
        )
      ) {
        setSettingsPanelItem(null);
      }
    }

    sendMessageToLiveApp({
      action: 'update',
      updatedPage: pageInitialState.current
    });
  }, [resetContextState, sendMessageToLiveApp, setSettingsPanelItem, settingsPanelItem]);

  const handleSelectItem = (itemToSelect: PageEditorItemToSelect) => {
    const selectedItem = getSelectedItem(itemToSelect, page);

    if (!selectedItem) {
      return;
    }

    setSettingsPanelItem(selectedItem);
    sendMessageToLiveApp({ action: 'select', selectedItem });
  };

  const handlePageUpdate = useCallback(
    (update: PageEditorUpdate) => {
      const { updatedPage, updatedItem } = updatePage(page, update);

      // If the page wasn't actually updated, don't do anything
      if (!updatedPage) {
        return;
      }

      if (!isPageModified) {
        setIsPageModified(true);
      }

      setPage(updatedPage);

      const messageToLiveApp: MessageToLiveApp = {
        action: 'update',
        updatedPage
      };

      // If there is no updated item, we can just send the updated page to the Live App and return
      if (!updatedItem) {
        sendMessageToLiveApp(messageToLiveApp);
        return;
      }

      // If the update is an add, delete, or update, then update the list of page changes
      if (update.action === 'add' || update.action === 'delete' || update.action === 'update') {
        const updatedPageChanges = getUpdatedPageChanges({
          currentChanges: pageChanges,
          updateAction: update.action,
          updatedItem
        });
        setPageChanges(updatedPageChanges);
      }

      // If a new item was added, set it as the selected item
      if (update.action === 'add') {
        let itemToSelect: PageEditorItemToSelect | null = null;

        if (updatedItem.type === 'section') {
          itemToSelect = {
            type: updatedItem.type,
            sectionId: updatedItem.section.id
          };
        }

        if (updatedItem.type === 'view') {
          itemToSelect = {
            type: updatedItem.type,
            viewKey: updatedItem.view.key
          };
        }

        if (itemToSelect) {
          const selectedItem = getSelectedItem(itemToSelect, updatedPage);
          messageToLiveApp.selectedItem = selectedItem || undefined;
          setSettingsPanelItem(selectedItem);
        }
      }

      // If a view or section was deleted, unselect it if it was previously selected
      if (update.action === 'delete') {
        const shouldUnselectDeletedView =
          updatedItem.type === 'view' &&
          settingsPanelItem?.type === 'view' &&
          settingsPanelItem.view.key === updatedItem.view.key;
        const shouldUnselectDeletedSection =
          updatedItem.type === 'section' &&
          settingsPanelItem?.type === 'section' &&
          settingsPanelItem.section.id === updatedItem.section.id;

        if (shouldUnselectDeletedView || shouldUnselectDeletedSection) {
          messageToLiveApp.selectedItem = undefined;
          setSettingsPanelItem(null);
        }
      }

      sendMessageToLiveApp(messageToLiveApp);
    },
    [
      page,
      isPageModified,
      sendMessageToLiveApp,
      pageChanges,
      setSettingsPanelItem,
      settingsPanelItem
    ]
  );

  const contextValue = useMemo(
    () => ({
      page,
      pageInitialState: pageInitialState.current,
      pageChanges,
      pageSourceObjectRecords: activePageSourceObjectRecords,
      isPageModified,
      isPreviewMode,
      isAddViewModalOpen,
      setIsPreviewMode,
      setIsAddViewModalOpen,
      updatePage: handlePageUpdate,
      savePageChanges,
      discardPageChanges
    }),
    [
      page,
      pageChanges,
      activePageSourceObjectRecords,
      isPageModified,
      isPreviewMode,
      isAddViewModalOpen,
      handlePageUpdate,
      savePageChanges,
      discardPageChanges
    ]
  );

  useEffect(() => {
    // If the active page key changes, we just need to reset the page and inform the Live App
    if (activePage.key !== page.key) {
      resetContextState(activePage);
      sendMessageToLiveApp({
        action: 'update',
        updatedPage: activePage
      });

      return;
    }

    const hasPageAccessChanged = activePage.requiresAuthentication !== page.requiresAuthentication;
    const hasParentSlugChanged = activePage.parentSlug !== page.parentSlug;
    const pageRequiresAuthentication =
      activePage.requiresAuthentication && page.requiresAuthentication;

    if (hasPageAccessChanged || (pageRequiresAuthentication && hasParentSlugChanged)) {
      // If the page's authentication properties change (e.g. adding or removing login), we need to update the local page in the page editor to reflect the changes
      const updatedAuthProperties: Partial<BuilderPage> = {
        requiresAuthentication: activePage.requiresAuthentication,
        limitProfileAccess: activePage.limitProfileAccess,
        allowedProfileKeys: activePage.allowedProfileKeys,
        parentSlug: activePage.parentSlug
      };

      const localPageWithUpdatedAuthProperties = {
        ...page,
        ...updatedAuthProperties
      };

      const initialPageWithUpdatedAuthProperties = {
        ...pageInitialState.current,
        ...updatedAuthProperties
      };

      setPage(localPageWithUpdatedAuthProperties);
      pageInitialState.current = initialPageWithUpdatedAuthProperties;

      sendMessageToLiveApp({
        action: 'update',
        updatedPage: localPageWithUpdatedAuthProperties
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    if (!messageFromLiveApp) {
      return;
    }

    /**
     * We treat the builder as the source of truth for the page data, so when the Live App iFrame first loads, it requests the page from the builder and updates its own local page to match.
     * This is necessary for two reasons:
     *   - If the iFrame needs to be mounted under a different component (e.g. in Preview mode), it will know the current state of the page
     *   - Ensures both the builder and Live App have the same section and column IDs
     *     Context:
     *      When fetching pages from the server, unique identifiers are generated on-the-fly for sections and columns that lack an ID,
     *      which results in the builder and the Live App having different IDs for the same entities, causing issues when communicating
     *      page editor changes between the two applications.
     */
    if (messageFromLiveApp.action === 'request-page-sync') {
      // If the settings panel item is a page, we don't need set any initial selected item in the Live App
      if (!settingsPanelItem || settingsPanelItem.type === 'page') {
        sendMessageToLiveApp({ action: 'page-sync', page });
        return;
      }

      // If the settings panel item is a child form view, we set the parent view as the initial selected item.
      // Child form views are only editable in a modal, so we need to select the parent view instead when first syncing the page.
      if (
        settingsPanelItem.type === 'view' &&
        settingsPanelItem.view.type === 'form' &&
        settingsPanelItem.view.parent
      ) {
        const selectedItem = getSelectedItem(
          {
            type: 'view',
            viewKey: settingsPanelItem.view.parent
          },
          page
        );

        setSettingsPanelItem(selectedItem);
        sendMessageToLiveApp({
          action: 'page-sync',
          page,
          initialSelectedItem: selectedItem ?? undefined
        });
      }

      sendMessageToLiveApp({ action: 'page-sync', page, initialSelectedItem: settingsPanelItem });
    }

    if (messageFromLiveApp.action === 'select') {
      handleSelectItem(messageFromLiveApp.itemToSelect);
    }

    if (messageFromLiveApp.action === 'update') {
      handlePageUpdate(messageFromLiveApp.update);
    }

    if (messageFromLiveApp.action === 'start-add-view') {
      setIsAddViewModalOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFromLiveApp]);

  return <PageEditorContext.Provider value={contextValue}>{children}</PageEditorContext.Provider>;
}

export const usePageEditorContext = () => {
  const context = useContext(PageEditorContext);
  if (!context) {
    throw new Error('usePageEditorContext must be used within a PageEditorContextProvider');
  }
  return context;
};
