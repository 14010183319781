import { useEffect, type RefObject } from 'react';

// Based on: https://usehooks-ts.com/react-hook/use-on-click-outside
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
  useEffect(() => {
    const actualHandler = (event: MouseEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(mouseEvent, actualHandler);

    return () => {
      document.removeEventListener(mouseEvent, actualHandler);
    };
  }, [handler, mouseEvent, ref]);
}
