import { type PasswordField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { PasswordEdit } from '@/components/data-table/display/fields/password/PasswordEdit';

export function PasswordRender(props: FieldRenderProps<PasswordField>) {
  const { value, rowId, fieldId, isFloating } = props;

  if (isFloating) {
    return <PasswordEdit {...props} />;
  }

  return (
    <div className="flex size-full p-2" data-testid={`password-render-${rowId}-${fieldId}`}>
      <p className="truncate">{value}</p>
    </div>
  );
}
