import React from 'react';
import { Tooltip } from '@knack/asterisk-react';

export function TextTooltip({
  label,
  children,
  shouldPortal = false,
  asChild = false
}: {
  label: string;
  children: React.ReactNode;
  shouldPortal?: boolean;
  asChild?: boolean;
}) {
  const tooltipContent = <Tooltip.Content>{label}</Tooltip.Content>;
  if (label !== '') {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild={asChild}>{children}</Tooltip.Trigger>
        {shouldPortal ? <Tooltip.Portal>{tooltipContent}</Tooltip.Portal> : tooltipContent}
      </Tooltip>
    );
  }

  return children;
}
