import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { DataTableStoreProvider } from '@/components/data-table/useDataTableStore';
import { FieldsStoreProvider } from '@/pages/tables/fields/useFieldsStore';
import { TablesPageLayout } from '@/pages/tables/TablesPageLayout';
import { ROUTES, type PageUrlParams } from '@/Router';

export function RolesPageWrapper({ object }: { object: KnackObject }) {
  return (
    <DataTableStoreProvider>
      <FieldsStoreProvider>
        <Outlet />
        <TablesPageLayout objectKey={object.key} data-testid="roles-page-layout" />
      </FieldsStoreProvider>
    </DataTableStoreProvider>
  );
}

export function RolesPageContent({ isIndex = false }: { isIndex?: boolean }) {
  const urlParams = useParams<PageUrlParams>();
  const { data: app } = useApplicationQuery();

  const isUsersTable = (tableType: KnackObject['type']) => tableType === 'UserObject';

  // Redirect to the All Users table
  const allUsersTable = app?.objects.find((table) => table.profile_key === 'all_users');

  const defaultRedirectPath = allUsersTable
    ? generatePath(ROUTES.ROLES_ID, { id: allUsersTable.key })
    : generatePath(ROUTES.ROLES);

  if (isIndex) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  const { id: tableKey } = urlParams;
  if (!tableKey) {
    throw new Error('No table key provided');
  }

  const table = app?.objects.find((obj) => obj.key === tableKey) || null;

  if (!table) {
    return null;
  }

  if (table.type && !isUsersTable(table.type)) {
    return <Navigate to={defaultRedirectPath} replace />;
  }

  // Force a complete remount using key when tableKey changes, this way we ensure that the zustand store is not re-used
  return <RolesPageWrapper object={table} key={tableKey} />;
}
