import { t } from 'i18next';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type BuilderView } from '@/types/schema/BuilderView';
import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

export type ConnectionParentOption = {
  type: 'input' | 'user' | 'scene' | 'record';
  name: string;
  connectionFieldKey: KnackFieldKey;
  remoteFieldKey: KnackFieldKey | 'null'; // the remote key can be a literal 'null' string if there is no remote field key
};

export type SelectableConnectionParentOption = {
  label: string;

  // e.g. 'all', 'field_123-field_456-input', 'field_123-null-user', etc.
  value:
    | 'all'
    | `${ConnectionParentOption['connectionFieldKey']}-${ConnectionParentOption['remoteFieldKey']}-${ConnectionParentOption['type']}`;
};

type ViewGenericInput = {
  field: {
    key: KnackFieldKey;
  };
};

function getViewGenericInputs(view: BuilderView) {
  const viewGenericInputs: ViewGenericInput[] = [];

  if (view.type === 'form' || view.type === 'registration') {
    view.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.inputs.forEach((input) => {
          if (input.type !== 'divider' && input.type !== 'section_break') {
            viewGenericInputs.push({
              field: input.field
            });
          }
        });
      });
    });
  }

  if (view.type === 'checkout') {
    view.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.inputs.forEach((input) => {
          viewGenericInputs.push({
            field: input.field
          });
        });
      });
    });
  }

  if (view.type === 'calendar') {
    view.form.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.inputs.forEach((input) => {
          viewGenericInputs.push({
            field: input.field
          });
        });
      });
    });
  }

  if (view.type === 'search') {
    view.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.fields.forEach((field) => {
          if (field.field !== 'keyword_search') {
            viewGenericInputs.push({
              field: {
                key: field.field
              }
            });
          }
        });
      });
    });
  }

  return viewGenericInputs;
}

function mapConnectionParentOptionsToSelectables(
  connectionParentOptions: ConnectionParentOption[],
  inputConnectedObject: KnackObject
) {
  return connectionParentOptions.map((option) => {
    const value: SelectableConnectionParentOption['value'] = `${option.connectionFieldKey}-${option.remoteFieldKey}-${option.type}`;

    let labelI18nKey: string;

    switch (option.type) {
      case 'input':
        labelI18nKey = 'object_records_connected_to_this_form';
        break;
      case 'user':
        labelI18nKey = 'object_records_connected_to_the_logged_in_user';
        break;
      case 'scene':
        labelI18nKey = 'object_records_connected_to_this_page';
        break;
      case 'record':
        labelI18nKey = 'object_records_connected_to_this_record';
        break;
      default:
        labelI18nKey = '';
    }

    return {
      value,
      label: labelI18nKey
        ? t(`pages.element_settings.connection_parent_options.${labelI18nKey}`, {
            objectNamePlural: inputConnectedObject.inflections.plural,
            optionName: option.name
          })
        : ''
    } as SelectableConnectionParentOption;
  });
}

export function useConnectionParentOptions({
  field,
  optionsFormat = 'selectable',
  ignoreViewInputs = false
}: {
  field: ConnectionField;
  optionsFormat?: 'selectable' | 'raw';
  ignoreViewInputs?: boolean;
}) {
  const { page } = usePageEditorContext();
  const { view, sourceObject } = useActiveViewContext();

  const { getObjectByKey, getObjectByFieldKey } = useObjectHelpers();
  const { getFieldByKey } = useFieldHelpers();
  const { getPageBySlug, getPageRoleObjects } = usePageHelpers();

  if (!sourceObject) {
    // eslint-disable-next-line no-console
    console.error('useConnectionParentOptions must be used within a view that has a source object');
    return [];
  }

  const inputConnectedObject = field.relationship?.object
    ? getObjectByKey(field.relationship.object)
    : getObjectByFieldKey(field.key);

  if (!inputConnectedObject) {
    return [];
  }

  const connectionParentOptions: ConnectionParentOption[] = [];

  const sourceObjectConnections = [
    ...sourceObject.connections.inbound,
    ...sourceObject.connections.outbound
  ];
  const inputConnectedObjectConnections = [
    ...inputConnectedObject.connections.inbound,
    ...inputConnectedObject.connections.outbound
  ];

  // Ensure that the connection field has a relationship
  if (!field.relationship) {
    // Get the object where the field lives
    const fieldObject = getObjectByFieldKey(field.key);

    // Find a potential connection between the view's source object and the connection field object
    const connection = fieldObject
      ? sourceObjectConnections.find((conn) => conn.object === fieldObject.key)
      : null;

    if (fieldObject && connection) {
      const isOutgoingConnection = sourceObject.fields.some((f) => f.key === connection.key);

      field.relationship = {
        object: fieldObject.key,
        has: isOutgoingConnection ? connection.has : connection.belongs_to,
        belongs_to: isOutgoingConnection ? connection.belongs_to : connection.has
      };
    }
  }

  if (!ignoreViewInputs) {
    const viewInputs = getViewGenericInputs(view);

    // Check if there is an input on this view that can conditionally control this connection
    viewInputs.forEach((viewInput) => {
      const viewInputField = getFieldByKey(viewInput.field.key);
      const viewInputFieldObject = viewInputField?.relationship?.object
        ? getObjectByFieldKey(viewInputField.relationship.object)
        : null;

      if (!viewInputField) {
        return;
      }

      if (viewInputField.type === 'connection') {
        // Check if this input's connection field object has any other connection fields to the parentField's object
        inputConnectedObject.fields.forEach((f) => {
          if (
            f.type === 'connection' &&
            f.relationship.object === viewInputField.relationship.object
          ) {
            connectionParentOptions.push({
              type: 'input',
              name: viewInputField.name,
              remoteFieldKey: viewInputField.key,
              connectionFieldKey: field.key
            });
          }
        });

        if (viewInputFieldObject) {
          // Check if this input's connected object has any other connection fields to the parentField's object
          viewInputFieldObject.fields.forEach((f) => {
            if (f.type === 'connection' && f.relationship.object === field.relationship.object) {
              connectionParentOptions.push({
                type: 'input',
                name: viewInputField.name,
                remoteFieldKey: viewInputField.key,
                connectionFieldKey: field.key
              });
            }
          });
        }
      }
    });
  }

  if ('source' in view) {
    const viewSourceConnectionField = view.source.connection_key
      ? getFieldByKey(view.source.connection_key)
      : undefined;

    // Check if this view source is based off the page object.
    // We're looking for an object that both the page object and the input object connect to.
    // This only applies if this view is NOT connected to the logged-in user.
    if (viewSourceConnectionField && !view.source.authenticated_user) {
      const viewSourceConnectionObjectKey =
        view.source.relationship_type === 'foreign'
          ? getObjectByFieldKey(viewSourceConnectionField.key)?.key
          : viewSourceConnectionField.relationship?.object;

      if (viewSourceConnectionObjectKey) {
        inputConnectedObjectConnections.forEach((conn) => {
          if (conn.object === viewSourceConnectionObjectKey && field.key !== conn.key) {
            connectionParentOptions.push({
              type: 'scene',
              name: viewSourceConnectionField.name,
              remoteFieldKey: viewSourceConnectionField.key,
              connectionFieldKey: conn.key
            });
          }
        });
      }
    }
  }

  // Check for forms that update an object, and that object has a parent connection to the same parent object as the child connection
  if (view.type === 'form' && view.action === 'update') {
    sourceObjectConnections.forEach((sourceObjectConn) => {
      const isSourceObjectConnOutgoing = sourceObject.fields.some((f) => f.key);

      if (isSourceObjectConnOutgoing && sourceObjectConn.has === 'one') {
        // See if the parent object has the same connection
        inputConnectedObjectConnections.forEach((inputConnectedObjectConn) => {
          const isInputConnectedObjectConnOutgoing = sourceObject.fields.some((f) => f.key);

          if (
            isInputConnectedObjectConnOutgoing &&
            inputConnectedObjectConn.has === 'one' &&
            sourceObjectConn.object === inputConnectedObjectConn.object
          ) {
            connectionParentOptions.push({
              type: 'record',
              name: sourceObjectConn.name,
              remoteFieldKey: sourceObjectConn.key,
              connectionFieldKey: inputConnectedObjectConn.key
            });
          }
        });
      }
    });
  } else {
    // Otherwise, check if the parent object has the same connection
    inputConnectedObjectConnections.forEach((conn) => {
      let parentPageSlug: string | null = page.slug;

      while (parentPageSlug) {
        const parentPage: BuilderPage | undefined =
          parentPageSlug === page.slug ? page : getPageBySlug(parentPageSlug);

        if (!parentPage) {
          break;
        }

        if (parentPage.sourceObjectKey && parentPage.sourceObjectKey === conn.object) {
          const pageSourceObject = getObjectByKey(parentPage.sourceObjectKey);
          if (!pageSourceObject) {
            break;
          }

          const isOutgoingConnection = inputConnectedObject.fields.some((f) => f.key === conn.key);
          const connectionName = isOutgoingConnection
            ? `${pageSourceObject.inflections.singular} (${inputConnectedObject.name} > ${conn.name})`
            : `${pageSourceObject.inflections.singular} (${pageSourceObject.inflections.singular} > ${conn.name})`;

          connectionParentOptions.push({
            type: 'scene',
            name: connectionName,
            remoteFieldKey: 'null',
            connectionFieldKey: conn.key
          });

          break;
        } else {
          parentPageSlug = parentPage.parentSlug;
        }
      }
    });
  }

  // Check if there are connections to the logged-in user
  if (page.requiresAuthentication) {
    const allPageRoleObjects = getPageRoleObjects(page, true);

    allPageRoleObjects.forEach((userObject) => {
      const userObjectConnections = [
        ...userObject.connections.inbound,
        ...userObject.connections.outbound
      ];

      // Check direct connections
      userObjectConnections.forEach((conn) => {
        const isOutgoingConnection = userObject.fields.some((f) => f.key === conn.key);

        if (
          ((isOutgoingConnection && (conn.belongs_to === 'many' || conn.has === 'many')) ||
            (!isOutgoingConnection && (conn.belongs_to === 'many' || conn.has === 'many'))) &&
          conn.object === inputConnectedObject.key
        ) {
          const connectionName = isOutgoingConnection
            ? conn.name
            : `${inputConnectedObject.inflections.singular} > ${conn.name}`;

          connectionParentOptions.push({
            type: 'user',
            name: `${userObject.inflections.singular} (${connectionName})`,
            remoteFieldKey: 'null',
            connectionFieldKey: conn.key
          });
        }
      });

      // Check all parents: user > connections
      userObjectConnections.forEach((userObjectConn) => {
        const isUserObjectConnectionOutgoing = userObject.fields.some((f) => f.key);

        if (
          (isUserObjectConnectionOutgoing && userObjectConn.has === 'one') ||
          (!isUserObjectConnectionOutgoing && userObjectConn.has === 'many')
        ) {
          // See if the connected input object has the same connection
          inputConnectedObjectConnections.forEach((inputConnectedObjectConn) => {
            const isInputConnectedObjectConnOutgoing = userObject.fields.some((f) => f.key);

            if (
              isInputConnectedObjectConnOutgoing &&
              userObjectConn.object === inputConnectedObjectConn.object
            ) {
              let connectionName = userObjectConn.name;

              if (!isInputConnectedObjectConnOutgoing) {
                const inputConnectedObjectConnObject = getObjectByKey(
                  inputConnectedObjectConn.object
                );
                if (inputConnectedObjectConnObject) {
                  connectionName = inputConnectedObjectConnObject.inflections.plural;
                }
              }

              connectionParentOptions.push({
                type: 'user',
                name: `${userObject.inflections.singular} > ${connectionName} (${inputConnectedObjectConn.name})`,
                remoteFieldKey: userObjectConn.key,
                connectionFieldKey: inputConnectedObjectConn.key
              });
            }
          });
        }
      });

      // Check all grandparents: user > parent > connections
      userObjectConnections.forEach((userObjectConn) => {
        const connObject = getObjectByKey(userObjectConn.object);

        if (connObject) {
          const connObjectConnections = connObject
            ? [...connObject.connections.inbound, ...connObject.connections.outbound]
            : [];

          connObjectConnections.forEach((connObjectFurtherConn) => {
            const isConnObjectFurtherConnOutgoing = connObject.fields.some((f) => f.key);

            if (
              isConnObjectFurtherConnOutgoing &&
              connObjectFurtherConn.has === 'many' &&
              connObjectFurtherConn.object === inputConnectedObject.key
            ) {
              connectionParentOptions.push({
                type: 'user',
                name: `${userObject.inflections.singular} > ${connObject.name} (${connObjectFurtherConn.name})`,
                remoteFieldKey: userObjectConn.key,
                connectionFieldKey: connObjectFurtherConn.key
              });
            }
          });
        }
      });
    });
  }

  if (optionsFormat === 'selectable') {
    const selectableConnectionParentOptions: SelectableConnectionParentOption[] = [
      {
        value: 'all',
        label: t('pages.element_settings.connection_parent_options.all_records', {
          objectName: inputConnectedObject.inflections.singular
        })
      },
      ...mapConnectionParentOptionsToSelectables(connectionParentOptions, inputConnectedObject)
    ];

    return selectableConnectionParentOptions;
  }

  return connectionParentOptions;
}
