import { useTranslation } from 'react-i18next';

import { BuilderAccordion } from '@/components/BuilderAccordion';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function MenuSettings() {
  const [t] = useTranslation();

  return (
    <CollapsiblePanel.Content title={t('pages.element_settings.menu.heading')}>
      {import.meta.env.PUBLIC_IS_PRODUCTION === 'true' ? (
        t('keywords.coming_soon')
      ) : (
        <BuilderAccordion>
          <ViewTitleDescriptionSettings />
        </BuilderAccordion>
      )}
    </CollapsiblePanel.Content>
  );
}
