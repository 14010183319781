import { memo, useMemo, type RefObject } from 'react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useApplication } from '@/hooks/useApplication';
import { SidePanel } from '@/components/SidePanel';
import { useDataModelSidePanelStore } from '@/pages/data-model/helpers/useDataModelSidePanelStore';
import { FieldSidePanelContent } from './FieldSidePanelContent';
import { TableSidePanelContent } from './TableSidePanelContent';

interface DataModelSidePanelProps {
  containerRef: RefObject<HTMLDivElement>;
}

function DataModelSidePanel({ containerRef }: DataModelSidePanelProps) {
  const application = useApplication();
  const knackObjects = application?.objects;
  const { isSidePanelOpen, setIsSidePanelOpen, sidePanelItem } = useDataModelSidePanelStore(
    (state) => ({
      isSidePanelOpen: state.isSidePanelOpen,
      setIsSidePanelOpen: state.setIsSidePanelOpen,
      sidePanelItem: state.sidePanelItem
    })
  );

  const table = useMemo(
    () => knackObjects?.find((object) => object.key === sidePanelItem?.tableKey),
    [knackObjects, sidePanelItem?.tableKey]
  );
  const field = useMemo(
    () => table?.fields.find((f) => f.key === sidePanelItem?.fieldKey),
    [sidePanelItem?.fieldKey, table?.fields]
  );

  return (
    <SidePanel open={isSidePanelOpen} onOpenChange={setIsSidePanelOpen}>
      <SidePanel.Content
        portalContainer={containerRef.current}
        position="absolute"
        onInteractOutside={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="z-10 bg-menu focus:outline-none"
      >
        {field ? (
          <FieldSidePanelContent field={field} table={table as KnackObject} />
        ) : (
          <TableSidePanelContent
            table={table as KnackObject}
            allTables={knackObjects as KnackObject[]}
          />
        )}
      </SidePanel.Content>
    </SidePanel>
  );
}

const memoized = memo(DataModelSidePanel);
export { memoized as DataModelSidePanel };
