import { useMutation } from '@tanstack/react-query';

import { useSession } from '@/hooks/useSession';
import { axiosInstance as axios } from '@/utils/axiosConfig';

type LogoutResponse = {
  logout: boolean;
};

type LogoutParams = {
  accountId: string;
  userId: string;
};

async function logoutUser({ accountId, userId }: LogoutParams) {
  const endpoint = accountId
    ? `/v1/accounts/${accountId}/session/${userId}`
    : `/v1/accounts/shared/session/${userId}`;

  const { data } = await axios.delete<LogoutResponse>(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useLogoutUserMutation() {
  const session = useSession();

  return useMutation({
    mutationFn: () =>
      logoutUser({
        accountId: session.account.id,
        userId: session.user.id
      }),
    onSuccess: (data: LogoutResponse) => {
      if (data.logout) {
        window.location.replace(`${import.meta.env.PUBLIC_DASHBOARD_URL}/sign-in`);
      }
    }
  });
}
