import { DateTime } from 'luxon';

import { type DateTimeFieldFormat } from '@/types/schema/fields';
import { type LuxonDateFormat } from '@/hooks/helpers/useDateTimeHelpers';

export const getDefaultDate = (inputFormat: DateTimeFieldFormat) => {
  // Replace 'mm' with 'MM' to correctly handle months instead of minutes, since 'mm' is used for minutes in Luxon
  const correctedFormat = inputFormat.date_format
    ? inputFormat.date_format.replace(/mm/g, 'MM')
    : 'MM/dd/yyyy';

  const todayFormatted = DateTime.now().toFormat(correctedFormat);

  if (inputFormat.default_type === 'date' && inputFormat.default_date) {
    return inputFormat.default_date;
  }
  if (inputFormat.date_format !== 'Ignore Date' && inputFormat.default_type === 'current') {
    return todayFormatted;
  }
  return '';
};

export function getDefaultTimeToString(inputFormat: DateTimeFieldFormat, additionalHours?: number) {
  const defaultTime =
    inputFormat.time_format === 'HH:MM am'
      ? inputFormat.default_time
      : inputFormat.default_time?.slice(0, -2);
  const defaultFormat = inputFormat.time_format === 'HH:MM am' ? 'hh:mm' : 'HH:mm';

  if (inputFormat.time_type === 'current') {
    if (additionalHours) {
      return DateTime.now().plus({ hours: additionalHours }).toFormat(defaultFormat);
    }
    return DateTime.now().toFormat(defaultFormat);
  }

  if (inputFormat.time_type === 'time' && defaultTime) {
    if (additionalHours) {
      return DateTime.fromFormat(defaultTime, defaultFormat)
        .plus({ hours: additionalHours })
        .toFormat(defaultFormat);
    }
    return DateTime.fromFormat(defaultTime, defaultFormat).toFormat(defaultFormat);
  }

  return '';
}

export const getDefaultDatePayload = (
  inputFormat: DateTimeFieldFormat,
  dateFormat: LuxonDateFormat,
  isRangeMode: boolean
) => {
  const todayFormatted = DateTime.now().toFormat(dateFormat);
  const defaultDate =
    inputFormat.default_type === 'date' && inputFormat.default_date
      ? inputFormat.default_date
      : todayFormatted;

  if (inputFormat.time_format === 'Ignore Time') {
    return {
      all_day: false,
      date: getDefaultDate(inputFormat),
      dateFormatted: getDefaultDate(inputFormat),
      ...(isRangeMode && {
        to: {
          date: defaultDate || todayFormatted,
          dateFormatted: defaultDate || todayFormatted
        }
      })
    };
  }
  return {
    date: getDefaultDate(inputFormat),
    dateFormatted: getDefaultDate(inputFormat),
    am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM',
    hours: DateTime.now().hour,
    minutes: DateTime.now().minute,
    rawTime: getDefaultTimeToString(inputFormat),
    ...(isRangeMode && {
      to: {
        date: getDefaultDate(inputFormat),
        dateFormatted: getDefaultDate(inputFormat),
        am_pm: DateTime.now().hour + 1 < 12 ? 'AM' : 'PM',
        hours: DateTime.now().hour + 1,
        minutes: DateTime.now().minute,
        rawTime: getDefaultTimeToString(inputFormat, 1)
      }
    })
  };
};
