import { useTranslation } from 'react-i18next';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { InlineKnackField } from '@/components/InlineKnackField';

type CalculationFiltersGroupCardProps = {
  sourceObject: KnackObject;
  calculationFilters: BuilderViewSourceCriteriaRule[];
};

function FilterGroup({ index, field, filterRule }) {
  const [t] = useTranslation();

  const firstItem = index === 0;

  return (
    <div>
      <span>{firstItem ? t('components.rules.where') : t('components.rules.and_uppercase')}</span>
      <InlineKnackField
        fieldName={field.name}
        fieldType={field.type}
        className="mx-1 bg-action text-default"
      />
      {filterRule.operator} <span className="rounded-lg bg-action p-1">{filterRule.value}</span>
    </div>
  );
}

export function CalculationFiltersGroupsCard({
  sourceObject,
  calculationFilters
}: CalculationFiltersGroupCardProps) {
  const [t] = useTranslation();

  const hasCalculationFilters = calculationFilters.length > 0;

  const getKnackField = (fieldKey: KnackFieldKey) =>
    sourceObject.fields.find((f) => f.key === fieldKey);

  if (!hasCalculationFilters) {
    return (
      <p>
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.all_records_are_shown'
        )}
      </p>
    );
  }

  return (
    <div className="space-y-1">
      <p className="text-xs">
        {t(
          'pages.element_settings.common.categories.data_display.general_settings.show_records_where'
        )}
      </p>
      <div className="rounded-lg bg-muted p-2" data-testid="calculation-filters-group-card">
        {calculationFilters.map((rule, ruleIndex) => {
          const knackField = getKnackField(rule.field);

          if (!knackField) {
            return null;
          }

          return (
            <FilterGroup
              // eslint-disable-next-line react/no-array-index-key
              key={`${rule.field}-${ruleIndex}-group`}
              index={ruleIndex}
              field={knackField}
              filterRule={rule}
            />
          );
        })}
      </div>
    </div>
  );
}
