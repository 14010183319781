import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Collapsible } from '@knack/asterisk-react';

export function Section({
  sectionNameKey,
  children
}: {
  sectionNameKey: string;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  return (
    <div className="[&:not(:last-child)]:mb-4">
      <Collapsible defaultOpen data-testid={`themes-${sectionNameKey}-collapsible`}>
        <Collapsible.Trigger
          className="group flex w-full items-center transition-all"
          data-testid={`themes-${sectionNameKey}-collapsible-trigger`}
        >
          <span className="flex items-center text-sm">{t(`themes.${sectionNameKey}`)}</span>
          <ChevronDownIcon
            size={20}
            className="ml-auto mr-2 transition-transform duration-300 group-data-[state=open]:rotate-180"
          />
        </Collapsible.Trigger>
        <Collapsible.Content className="my-2 mt-4">{children}</Collapsible.Content>
      </Collapsible>
    </div>
  );
}
