import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type NumberField as NumberFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { getNumberSchema } from '@/components/data-table/display/fields/number/NumberSchema';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function NumberEdit(props: FieldRenderProps<NumberFieldDisplay>) {
  const { value, rawValue, fieldId, rowId, type } = props;

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const numberFormSchema = getNumberSchema(currentField);

  type NumberFormSchema = z.infer<typeof numberFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<NumberFormSchema>({
    resolver: zodResolver(numberFormSchema),
    defaultValues: {
      number: rawValue.toString()
    }
  });

  const handleForm = useCallback(async () => {
    const formState = getFieldState('number');
    if (!formState.invalid) {
      const newValue = getValues('number')?.startsWith('.')
        ? `0${getValues('number')}`
        : getValues('number');

      await saveCell(rowId, fieldId, newValue, {
        value: newValue,
        rawValue: newValue
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('number');
      if (formState.invalid) {
        setValue('number', rawValue.toString());
      }

      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab' && isValid) {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter' && isValid) {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('number');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const numberRegister = register('number', {
    onChange: async () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      await handleSubmit(handleForm)();
    }
  });
  return (
    <div className="flex size-full">
      <TextareaCell
        defaultValue={value}
        intent={cellErrors || errors.number ? 'destructive' : 'default'}
        className={cn({
          'opacity-0': !selectedCell.isEditing
        })}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionAtTheEnd(e);
          }
        }}
        data-testid={`edit-number-input-${rowId}-${fieldId}`}
        onFocus={setCursorPositionAtTheEnd}
        {...numberRegister}
      />

      <CellErrors
        rowId={rowId}
        fieldId={fieldId}
        testIdPrefix="number-edit-error"
        additionalErrors={selectedCell.isEditing && errors?.number ? [errors.number] : []}
      />
    </div>
  );
}
