import { useState } from 'react';
import { Input, Select } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { type TimeValues } from '@/components/import/confirm-import/date-time/types';

// TODO: Moved to a helper file
export const formatTimeFromString = (
  value: string,
  is12HourFormat: boolean,
  mode: 'AM' | 'PM' | ''
): { time: TimeValues; timePeriodFormat: 'AM' | 'PM' } => {
  const [stringHours, stringMinutes] = value.split(':');
  let hours = parseInt(stringHours, 10);
  let minutes = parseInt(stringMinutes, 10);

  if (value === '') {
    return {
      time: { hours: 0, minutes: 0, am_pm: 'AM', rawTime: '00:00' },
      timePeriodFormat: 'AM'
    };
  }

  if (minutes < 0) minutes = 0;
  if (minutes > 59) minutes = 59;

  if (hours > 23) hours = 23;
  if (hours < 0) hours = 0;

  let time: TimeValues = {
    hours,
    minutes: minutes || 0,
    am_pm: mode === 'AM' ? 'AM' : 'PM',
    rawTime: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  };
  let timePeriodFormat: 'AM' | 'PM' = mode === 'AM' ? 'AM' : 'PM';

  if (is12HourFormat) {
    let adjustedHours = hours > 12 ? hours - 12 : hours;
    if (adjustedHours === 0) adjustedHours = 12;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    let am_pm: 'AM' | 'PM';
    if (mode === 'AM' || mode === 'PM') {
      am_pm = mode;
    } else {
      am_pm = hours >= 12 ? 'PM' : 'AM';
    }

    time = {
      hours: adjustedHours,
      minutes: minutes || 0,
      am_pm,
      rawTime: `${adjustedHours < 10 ? `0${adjustedHours}` : adjustedHours}:${
        minutes < 10 ? `0${minutes}` : minutes || '00'
      }`
    };

    timePeriodFormat = am_pm;
  }

  return { time, timePeriodFormat };
};

interface TimeInputProps {
  selectedTime: TimeValues;
  format: 'HH:MM am' | 'HH MM (military)';
  onChangeTime: (time: TimeValues) => void;
  isDisabled?: boolean;
}

export function TimeInput({ selectedTime, format, onChangeTime, isDisabled }: TimeInputProps) {
  const [mode, setMode] = useState<'AM' | 'PM' | ''>(selectedTime.am_pm || '');

  // Update the mode if the value from the prop has changed
  if (selectedTime.am_pm && mode !== selectedTime.am_pm) {
    setMode(selectedTime.am_pm);
  }

  const is12HourFormat = format === 'HH:MM am';
  const rawTime = selectedTime.rawTime || '';

  const handleChangeMode = () => {
    if (is12HourFormat) {
      setMode(mode === 'AM' ? 'PM' : 'AM');
      onChangeTime({
        hours: selectedTime.hours,
        minutes: selectedTime.minutes,
        am_pm: mode === 'AM' ? 'PM' : 'AM',
        rawTime
      });
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Allow empty string or numbers and colons only and max length of 5
    if (/^$|^[0-9:]*$/.test(value) && value.length <= 5) {
      onChangeTime({
        hours: parseInt(value.split(':')[0], 10),
        minutes: parseInt(value.split(':')[1], 10),
        am_pm: 'AM',
        rawTime: value
      });
    }
  };

  const handleFormatInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { time, timePeriodFormat } = formatTimeFromString(value, is12HourFormat, mode);

    // If the time is the same, don't update the state
    if (time.rawTime === rawTime) return;

    onChangeTime(time);
    if (is12HourFormat) {
      setMode(timePeriodFormat);
    }
  };

  return (
    <Input.Container className="flex">
      <Input
        className={cn('w-16 px-2', {
          'rounded-r-none focus:-outline-offset-2': is12HourFormat
        })}
        disabled={isDisabled}
        value={rawTime}
        onChange={handleChangeInput}
        placeholder={is12HourFormat ? '12:00' : '00:00'}
        onBlur={handleFormatInputOnBlur}
      />
      {is12HourFormat && (
        <Select value={mode} onValueChange={handleChangeMode} disabled={isDisabled}>
          <Select.Trigger className="right-0 top-0 w-16 justify-end rounded-lg rounded-bl-none rounded-tl-none border-l-0 p-2 hover:border-l focus:-outline-offset-2" />
          <Select.Content className="min-w-20">
            <Select.Item value="AM">AM</Select.Item>
            <Select.Item value="PM">PM</Select.Item>
          </Select.Content>
        </Select>
      )}
    </Input.Container>
  );
}
