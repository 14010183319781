import { type BuilderViewKey } from '@/types/schema/BuilderView';
import { NEW_SECTION_ID_PREFIX, NEW_VIEW_KEY_PREFIX } from './constants';
import { type PageChanges, type PageEditorUpdatedItem } from './types';

type GetUpdatedPageChangesParams = {
  currentChanges: PageChanges;
  updateAction: 'add' | 'delete' | 'update';
  updatedItem: PageEditorUpdatedItem;
};

export function getUpdatedPageChanges({
  currentChanges,
  updateAction,
  updatedItem
}: GetUpdatedPageChangesParams): PageChanges {
  // Map the updateAction to the corresponding PageChange action
  const updateActionMap = {
    add: 'inserts',
    delete: 'deletes',
    update: 'updates'
  } as const;

  const pageChangeAction = updateActionMap[updateAction];

  if (updatedItem.type === 'view') {
    // If the view being deleted is a new view, we only need to remove it from the list of views to add
    if (updateAction === 'delete' && updatedItem.view.key.includes(NEW_VIEW_KEY_PREFIX)) {
      return {
        ...currentChanges,
        inserts: {
          ...currentChanges.inserts,
          views: currentChanges.inserts.views.filter((viewKey) => viewKey !== updatedItem.view.key)
        }
      };
    }

    // If the view being updated is a new view, we don't need to include it in the list of views to update
    if (updateAction === 'update' && updatedItem.view.key.includes(NEW_VIEW_KEY_PREFIX)) {
      return currentChanges;
    }

    // If the view is already in the corresponding list of views to add/delete/update, we don't need to include it again
    if (
      currentChanges[pageChangeAction].views.some((viewKey) => viewKey === updatedItem.view.key)
    ) {
      return currentChanges;
    }

    return {
      ...currentChanges,
      [pageChangeAction]: {
        ...currentChanges[pageChangeAction],
        views: [...currentChanges[pageChangeAction].views, updatedItem.view.key]
      }
    };
  }

  if (updatedItem.type === 'section') {
    if (updateAction === 'delete') {
      // If a section is being deleted, we also need to remove all the views in that section
      const viewsToDelete = updatedItem.section.columns.reduce<BuilderViewKey[]>((acc, column) => {
        column.viewKeys.forEach((viewKey) => {
          // We only want to delete views that are not new views
          if (!viewKey.includes(NEW_VIEW_KEY_PREFIX)) {
            acc.push(viewKey);
          }
        });

        return acc;
      }, []);

      // If the section being deleted is a new section, we only need to remove it from the list of sections to add
      if (updatedItem.section.id.includes(NEW_SECTION_ID_PREFIX)) {
        return {
          ...currentChanges,
          inserts: {
            ...currentChanges.inserts,
            sections: currentChanges.inserts.sections.filter(
              (sectionId) => sectionId !== updatedItem.section.id
            )
          },
          deletes: {
            ...currentChanges.deletes,
            views: [...currentChanges.deletes.views, ...viewsToDelete]
          }
        };
      }

      return {
        ...currentChanges,
        deletes: {
          ...currentChanges.deletes,
          sections: [...currentChanges[pageChangeAction].sections, updatedItem.section.id],
          views: [...currentChanges.deletes.views, ...viewsToDelete]
        }
      };
    }

    // If the section being updated is a new section, we don't need to include it in the list of sections to update
    if (updateAction === 'update' && updatedItem.section.id.includes(NEW_SECTION_ID_PREFIX)) {
      return currentChanges;
    }

    // If the section is already in the corresponding list of sections to add/delete/update, we don't need to include it again
    if (
      currentChanges[pageChangeAction].sections.some(
        (sectionId) => sectionId === updatedItem.section.id
      )
    ) {
      return currentChanges;
    }

    return {
      ...currentChanges,
      [pageChangeAction]: {
        ...currentChanges[pageChangeAction],
        sections: [...currentChanges[pageChangeAction].sections, updatedItem.section.id]
      }
    };
  }

  return currentChanges;
}
