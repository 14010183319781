import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Badge, Button, Checkbox, DropdownMenu, Label } from '@knack/asterisk-react';

import { type Column } from '@/components/import/types';

export function SubField({ columns, subField, updateColumns, header }) {
  const [t] = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<Column | undefined>();

  function handleCheckboxChange() {
    setIsChecked(!isChecked);

    // Reset the selected column if the checkbox is unchecked
    if (isChecked) {
      setSelectedColumn(undefined);
      updateColumns({
        ...subField,
        mapped: false,
        mappedColumnIndex: undefined
      });
    }
  }

  // Update the selected column when user press Use first row as headers after selecting and mapping a column
  useEffect(() => {
    if (subField.mappedColumnIndex !== undefined) {
      const selectedColumnIndex = columns.findIndex(
        (columnToFind) => Number(columnToFind.accessorKey) === subField.mappedColumnIndex
      );
      setSelectedColumn(columns[selectedColumnIndex]);
    }
  }, [header, columns, subField.mappedColumnIndex]);

  return (
    <div data-testid={`map-columns-subfield-${subField.part.key}`} className="text-sm">
      <div className="mb-2">
        <Checkbox
          checked={isChecked}
          onClick={() => handleCheckboxChange()}
          data-testid={`subfield-selection-checkbox-${subField.part.key}`}
        />
        <Label className="ml-2">{subField.part.label}</Label>
      </div>
      {isChecked && (
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button
              size="sm"
              intent="secondary"
              className="w-full justify-between"
              data-testid="subfield-selection-button"
            >
              {selectedColumn ? selectedColumn.header : t('components.add_table.select_column')}
              <ChevronDownIcon size={20} />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            data-testid={`subfield-selection-dropdown-${subField.part.key}`}
            collisionPadding={8}
            align="end"
          >
            {columns.map((columnToMap: Column, index: number) => {
              const hasMappedParts = columnToMap.meta?.parts.some((part) => part.mapped === true);
              const shouldDisableColumn =
                columnToMap.meta?.isThisColumnMapped ||
                // Disable this column from being selected if has an active mapping and it's a different column than the current one
                (hasMappedParts && columnToMap.header !== header);

              return (
                <DropdownMenu.Item
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${columnToMap}-${index}`}
                  data-testid={`subfield-selection-dropdown-item-${columnToMap.header}`}
                  className="text-sm"
                  disabled={shouldDisableColumn}
                  onSelect={() => {
                    setSelectedColumn(columnToMap);
                    updateColumns({
                      ...subField,
                      mapped: true,
                      mappedColumnIndex: index
                    });
                  }}
                >
                  <div className="flex w-full items-center justify-between">
                    <span>{columnToMap.header}</span>
                    {(hasMappedParts || columnToMap.meta?.isThisColumnMapped) && (
                      <Badge>
                        {hasMappedParts
                          ? t('components.add_table.mapping')
                          : t('components.add_table.mapped')}
                      </Badge>
                    )}
                  </div>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </div>
  );
}
