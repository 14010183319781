import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { type FileField, type ImageField } from '@/types/schema/fields';
import { useApplication } from '@/hooks/useApplication';
import { axiosInstance } from '@/utils/axiosConfig';
import { imageAllowedFileFormats } from '@/utils/constants';
import { apiHelper } from '@/components/data-table/helpers/apiHelper';
import { FileDropzone, type FilePreviewData } from '@/components/FileDropzone';
import { useImportStore } from '@/components/import/useImportStore';

export const MAX_FILE_SIZE_IN_BYTES = 20 * 1000 * 1000; // 250 MB

export function FileInput({
  name,
  targetField
}: {
  name: string;
  targetField: FileField | ImageField;
}) {
  const [t] = useTranslation();
  const { setValue, getValues } = useFormContext();

  const { setHasPendingUploads } = useImportStore((state) => ({
    setHasPendingUploads: state.setHasPendingUploads
  }));

  const [previewData, setPreviewData] = useState<FilePreviewData>();

  const application = useApplication();
  const allowedFormats: { [mimeType: string]: string[] } =
    targetField.type === 'image' ? imageAllowedFileFormats : {};

  useEffect(() => {
    const initialValue = getValues(name);
    if (initialValue && initialValue.id) {
      const initialFileData = {
        id: initialValue.id,
        name: initialValue.filename,
        size: initialValue.size,
        url: initialValue.url
      };
      setPreviewData({
        src: initialFileData.url,
        name: initialFileData.name,
        size: initialFileData.size
      });
      setValue(name, initialValue.id);
    }
  }, [getValues, setValue, name]);

  const uploadFile = async (uploadedFile: File) => {
    if (!application || !targetField) {
      return;
    }
    if (targetField.type === 'image') {
      const response = await apiHelper(axiosInstance).uploadImageAsset({
        applicationId: application.id,
        fieldId: targetField.key,
        file: uploadedFile
      });
      setValue(name, response.data[0].id);
    }
    if (targetField.type === 'file') {
      const response = await apiHelper(axiosInstance).uploadFileAsset({
        applicationId: application.id,
        fieldId: targetField.key,
        file: uploadedFile
      });
      const fileId = response.data[0].id;
      setValue(name, fileId);
    }

    setPreviewData({
      src: URL.createObjectURL(uploadedFile),
      name: uploadedFile.name,
      size: uploadedFile.size
    });
  };

  const handleClearFile = () => {
    setPreviewData(undefined);
    setValue(name, '');
  };

  return (
    <FileDropzone
      onDrop={async (files: File[]) => {
        setHasPendingUploads(true);
        await uploadFile(files[0]);
        setHasPendingUploads(false);
      }}
      allowedFormats={allowedFormats}
      filePreviewData={previewData}
      setFilePreviewData={setPreviewData}
      maxFileSize={MAX_FILE_SIZE_IN_BYTES}
      onClearFile={handleClearFile}
      errorMapping={(error) => {
        if (error.code === 'file-too-large') {
          return t('components.file_dropzone.file_too_large', {
            size: MAX_FILE_SIZE_IN_BYTES / 1000 / 1000
          });
        }
        if (error.code === 'file-invalid-type') {
          return t('components.file_dropzone.file_invalid');
        }

        return t('components.file_dropzone.file_upload_error');
      }}
      data-testid="add-table-file-dropzone"
    />
  );
}
