import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, Label, Select } from '@knack/asterisk-react';

import { IMAGE_THUMB_TYPE_OPTIONS, type ImageThumbnail } from '@/types/schema/fields';
import { ThumbnailsInput } from '@/components/field-settings/image/ThumbnailsInput';

const DEFAULT_DIMENSIONS = {
  width: '100',
  height: '100',
  length: '100',
  percentage: '100'
};

export function Thumbnails() {
  const [t] = useTranslation();

  const { setValue, getValues } = useFormContext();

  const getThumbnailKey = ({ key }: ImageThumbnail) => key?.split('_')?.pop() || '';

  const thumbs = getValues('format.thumbs') || [];
  const hasThumbnails = thumbs?.length > 0;

  return (
    <div className="flex flex-col gap-2">
      <Label>{t('components.data_table.attributes.field_settings.image.thumbnails_label')}</Label>
      {hasThumbnails &&
        thumbs.map((thumb, index) => (
          <div className="flex flex-col gap-4 rounded-lg bg-muted p-4" key={thumb.key || index}>
            <div className="flex items-center justify-between">
              <Label data-testid="thumbnail-key">
                {thumb.key
                  ? t('components.data_table.attributes.field_settings.image.thumbnail_key', {
                      key: getThumbnailKey(thumb)
                    })
                  : t('components.data_table.attributes.field_settings.image.new_thumbnail')}
              </Label>
              <Button
                intent="minimal"
                className="h-6 p-0"
                data-testid="remove-thumbnail"
                onClick={() => {
                  const updatedThumbs = thumbs.filter((_, i) => i !== index);
                  setValue('format.thumbs', updatedThumbs);
                }}
              >
                <CloseIcon size={16} />
              </Button>
            </div>
            <Select
              onValueChange={(value) => {
                setValue(`format.thumbs[${index}].thumb_type`, value);
              }}
              defaultValue={thumb.thumb_type || IMAGE_THUMB_TYPE_OPTIONS[0]}
              value={thumb.thumb_type}
            >
              <Select.Trigger
                id="thumbnail-type-select"
                placeholder={t('actions.select')}
                className="w-full rounded-lg"
                data-testid="thumbnail-type-form-settings-value-select"
              />
              <Select.Content>
                {IMAGE_THUMB_TYPE_OPTIONS.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    data-testid={`thumbnail-type-field-settings-${option}`}
                  >
                    {t('components.data_table.attributes.field_settings.image.resize_by_option', {
                      option
                    })}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
            <ThumbnailsInput thumb={thumb} index={index} />
          </div>
        ))}
      <Button
        className="mt-2 w-[152px]"
        intent="secondary"
        data-testid="add-thumbnail-button"
        onClick={() => {
          const updatedThumbs = thumbs?.concat({
            thumb_type: 'pixel',
            ...DEFAULT_DIMENSIONS
          });
          setValue('format.thumbs', updatedThumbs);
        }}
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('components.data_table.attributes.field_settings.image.add_thumbnail')}
      </Button>
    </div>
  );
}
