import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { type BuilderViewKey } from '@/types/schema/BuilderView';
import { usePageEditorMessagingContext } from '@/pages/pages/page-editor/PageEditorMessagingContext';

type ViewInputEditModeContextState = {
  isViewInputEditMode: boolean;
  setIsViewInputEditMode: (isViewInputEditMode: boolean) => void;
  viewInputId: string | null;
  setViewInputId: (viewInputId: string | null) => void;
} | null;

type ViewInputEditModeContextProviderProps = {
  children: React.ReactNode;
};

export type ViewInputEditModeData = {
  viewKey: BuilderViewKey;
  viewInputId: string;
};

const ViewInputEditModeContext = createContext<ViewInputEditModeContextState>(null);

export function ViewInputEditModeContextProvider({
  children
}: ViewInputEditModeContextProviderProps) {
  const { messageFromLiveApp } = usePageEditorMessagingContext();

  const [isViewInputEditMode, setIsViewInputEditMode] = useState(false);
  const [viewInputId, setViewInputId] = useState<string | null>(null);

  const contextValue = useMemo(
    () => ({
      isViewInputEditMode,
      viewInputId,
      setIsViewInputEditMode,
      setViewInputId
    }),
    [isViewInputEditMode, viewInputId]
  );

  useEffect(() => {
    if (!messageFromLiveApp) {
      return;
    }

    if (messageFromLiveApp.action === 'start-edit-view-input') {
      setIsViewInputEditMode(true);
      setViewInputId(messageFromLiveApp.viewInputId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFromLiveApp]);

  return (
    <ViewInputEditModeContext.Provider value={contextValue}>
      {children}
    </ViewInputEditModeContext.Provider>
  );
}

export const useViewInputEditModeContext = () => {
  const context = useContext(ViewInputEditModeContext);
  if (!context) {
    throw new Error('useViewInputEditModeContext must be used within a ViewInputEditModeContext');
  }
  return context;
};
