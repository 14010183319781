import { DateTime } from 'luxon';

import { type TimeFormatValue } from '@/types/schema/fields';

export function mapTimeFormatFromKnackSchema(timeFormat: TimeFormatValue) {
  switch (timeFormat) {
    case 'Ignore Time':
      return '';

    case 'HH MM (military)':
      return 'HH:mm';

    case 'HH:MM am':
      return 'h:mma';

    default:
      return 'h:mma';
  }
}

export function getHoursFromTime(time: string) {
  return time.split(':')[0];
}

export function getMinutesFromTime(time: string) {
  return time.split(':')[1];
}

export const getAmPmFormatFrom24HourTime = (time: string) => {
  const hours = parseInt(getHoursFromTime(time), 10);
  return hours >= 12 ? 'PM' : 'AM';
};

export function formatTime(time?: string, format?: TimeFormatValue) {
  if (!time || format === 'Ignore Time') return '';

  return DateTime.fromISO(time, {
    zone: 'utc'
  })
    .toFormat(mapTimeFormatFromKnackSchema(format || 'HH:MM am'))
    .toLowerCase();
}

export const getTimeFormat = (format?: TimeFormatValue) =>
  format === 'HH MM (military)' ? 'HH:mm' : 'hh:mm';

export const getFormattedTimeForInput = (dateTimeUTC: string, format?: TimeFormatValue) =>
  DateTime.fromISO(dateTimeUTC, {
    zone: 'utc'
  })
    .toFormat(getTimeFormat(format))
    .toLowerCase();
