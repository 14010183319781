import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Calendar } from '@knack/asterisk-react';
import isEmpty from 'lodash.isempty';
import { DateTime } from 'luxon';
import { z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CalendarFooter } from '@/components/data-table/display/fields/date-time/components/CalendarFooter';
import { CellError } from '@/components/data-table/display/fields/date-time/components/CellError';
import { Time } from '@/components/data-table/display/fields/date-time/components/Time';
import { type SaveDateTimeCellValue } from '@/components/data-table/display/fields/date-time/components/types';
import {
  getDateFormat,
  getFormattedDateForInput,
  getUTCFromRawValue
} from '@/components/data-table/display/fields/date-time/formatters/dateFormatter';
import {
  formatTime,
  getFormattedTimeForInput,
  getHoursFromTime,
  getMinutesFromTime
} from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import {
  type DateTimeField as DateTimeFieldDisplay,
  type DateTimeRawValue
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import {
  DAY_PICKER_FROM_YEAR,
  DAY_PICKER_TO_YEAR
} from '@/components/data-table/helpers/constants';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { dateRegex, timeRegex } from '@/components/data-table/helpers/zod';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function DateTimeCalendarEdit({
  fieldId,
  rawValue,
  rowId,
  type
}: FieldRenderProps<DateTimeFieldDisplay>) {
  const [t] = useTranslation();

  const useDataTableState = useDataTableStore();
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);
  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const [allDayFormat, setAllDayFormat] = useState(rawValue.all_day || false);
  const [hasRepeatFormat, setHasRepeatFormat] = useState(!isEmpty(rawValue.repeat));
  const [startDateAmPmTimeFormat, setStartDateAmPmTimeFormat] = useState(rawValue.am_pm);
  const [endDateAmPmTimeFormat, setEndDateAmPmTimeFormat] = useState(rawValue.to?.am_pm);

  const [isEditingEndDate, setIsEditingEndDate] = useState(false);

  const { moveSelectionVertical } = useSelectionStrategy();
  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const { date_format: dateFormat, time_format: timeFormat } = currentField.format;
  const hasDate = dateFormat !== 'Ignore Date';
  const hasTime = timeFormat !== 'Ignore Time';
  const hasAmPmTimeFormat = timeFormat === 'HH:MM am';

  const dateFormatRegex = dateFormat ? dateRegex[dateFormat] : dateRegex['mm/dd/yyyy'];
  const timeFormatRegex = timeFormat ? timeRegex[timeFormat] : timeRegex['HH:MM am'];

  const selectedDate = rawValue.iso_timestamp
    ? DateTime.fromISO(rawValue.iso_timestamp)
    : DateTime.now();

  const selectedEndDate = rawValue.to?.iso_timestamp
    ? DateTime.fromISO(rawValue.to?.iso_timestamp)
    : DateTime.now();

  const dateTimeFormSchema = z.object({
    date: z
      .string()
      .regex(dateFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'start date' })
      })
      .optional()
      .or(z.literal('')),
    time: z
      .string()
      .regex(timeFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'start time' })
      })
      .optional()
      .or(z.literal('')),
    endDate: z
      .string()
      .regex(dateFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'end date' })
      })
      .optional()
      .or(z.literal('')),
    endTime: z
      .string()
      .regex(timeFormatRegex, {
        message: t('components.data_table.errors.no_valid_field', { field: 'end time' })
      })
      .optional()
      .or(z.literal(''))
  });

  type DateTimeFormSchema = z.infer<typeof dateTimeFormSchema>;

  const formMethods = useForm<DateTimeFormSchema>({
    resolver: zodResolver(dateTimeFormSchema),
    defaultValues: {
      date: rawValue.iso_timestamp
        ? getFormattedDateForInput(rawValue.iso_timestamp, dateFormat) || ''
        : '',
      time: rawValue?.iso_timestamp
        ? getFormattedTimeForInput(rawValue.iso_timestamp, timeFormat) || ''
        : '',

      endDate: rawValue.to?.iso_timestamp
        ? getFormattedDateForInput(rawValue.to?.iso_timestamp, dateFormat) || ''
        : '',
      endTime: rawValue.to?.iso_timestamp
        ? getFormattedTimeForInput(rawValue?.to.iso_timestamp, timeFormat) || ''
        : ''
    },
    mode: 'onChange',
    shouldFocusError: false
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = formMethods;

  const hasErrors = Object.keys(errors).length > 0 || !!cellErrors?.length;

  const { refs: startDateRef, floatingStyles: startDateFloatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.maxHeight = '100%';
            elements.floating.style.maxWidth = '100%';
            return;
          }

          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const { refs: endDateRef, floatingStyles: endDateFloatingStyles } = useFloating({
    placement: 'bottom',
    middleware: [
      // Fill the available height for big lists
      flip({
        crossAxis: true
      }),
      size({
        padding: 25,
        apply({ availableHeight, availableWidth, elements }) {
          // This function is cached, so we need the zustand state directly to update this correctly
          if (!useDataTableState.getState().selectedCell?.isEditing) {
            elements.floating.style.maxHeight = '100%';
            elements.floating.style.maxWidth = '100%';
            return;
          }

          elements.floating.style.maxHeight = `${availableHeight}px`;
          elements.floating.style.maxWidth = `${availableWidth}px`;
        }
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const saveCellValue = useCallback(
    async (dateTime: SaveDateTimeCellValue) => {
      const updates = {
        date: dateTime.date,
        time: dateTime.time,
        am_pm: startDateAmPmTimeFormat,
        all_day: allDayFormat,
        repeat: dateTime.repeat,
        to: {
          date: dateTime.endDate,
          time: dateTime.endTime,
          am_pm: endDateAmPmTimeFormat,
          all_day: allDayFormat
        }
      };

      const updatedData = { ...updates } as DateTimeRawValue;

      const { date, time, endDate, endTime } = dateTime;

      if (hasRepeatFormat && !updatedData.repeat && !isEmpty(rawValue?.repeat)) {
        updatedData.repeat = rawValue.repeat;
      }

      if (!updatedData.to) {
        updatedData.to = {}; // Initialize `to` as an empty object if it's not already
      }

      if (!date) {
        updatedData.date = getValues('date');
      }

      if (!endDate) {
        updatedData.to.date = getValues('endDate');
      }

      if (!time) {
        updatedData.hours = getHoursFromTime(getValues('time') || '');
        updatedData.minutes = getMinutesFromTime(getValues('time') || '');
      }

      if (!endTime) {
        updatedData.to.hours = getHoursFromTime(getValues('endTime') || '');
        updatedData.to.minutes = getMinutesFromTime(getValues('endTime') || '');
      }

      if (time) {
        updatedData.hours = getHoursFromTime(time);
        updatedData.minutes = getMinutesFromTime(time);
      }

      if (endTime) {
        updatedData.to.hours = getHoursFromTime(endTime);
        updatedData.to.minutes = getMinutesFromTime(endTime);
      }

      // Do not include the offset. That way we ensure that the date is in the 0 timezone
      updatedData.iso_timestamp =
        getUTCFromRawValue(updatedData, currentField.format).toISO({ includeOffset: false }) || '';

      updatedData.to.iso_timestamp =
        getUTCFromRawValue(updatedData.to, currentField.format).toISO({ includeOffset: false }) ||
        '';

      await saveCell(rowId, fieldId, updatedData, {
        value: updatedData.date,
        rawValue: updatedData
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      startDateAmPmTimeFormat,
      allDayFormat,
      rawValue,
      endDateAmPmTimeFormat,
      currentField.format,
      saveCell,
      rowId,
      fieldId,
      getValues
    ]
  );

  const onDateSelection = async (date?: Date, isEndDate = false) => {
    if (!date) return;
    clearCellErrors(rowId, fieldId);

    const dateSelectedWithoutTimezone = DateTime.fromJSDate(date).toISO({ includeOffset: false });
    if (!dateSelectedWithoutTimezone) return;

    const formattedDate = getFormattedDateForInput(dateSelectedWithoutTimezone, dateFormat);
    setValue(isEndDate ? 'endDate' : 'date', formattedDate);

    await saveCellValue(isEndDate ? { endDate: formattedDate } : { date: formattedDate });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);

    if (e.key === 'Escape') {
      setIsEditing(false);
      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (selectedCell.isEditing) {
        moveSelectionVertical('down');
        setIsEditing(false);
      } else {
        setIsEditing(true);
      }

      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onValueChange = async () => {
    if (!selectedCell?.isEditing) {
      setIsEditing(true);
    }

    await handleSubmit(saveCellValue)();
  };

  useEffect(() => {
    if (
      startDateAmPmTimeFormat !== rawValue.am_pm ||
      endDateAmPmTimeFormat !== rawValue.to?.am_pm ||
      allDayFormat !== rawValue.all_day
    ) {
      void saveCellValue({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateAmPmTimeFormat, endDateAmPmTimeFormat, allDayFormat]);

  if (!hasDate && !hasTime) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <div
        className={cn('flex size-full flex-row items-center px-2', {
          'bg-base': selectedCell.isEditing,
          'ring-2 ring-destructive focus:ring-2 focus:ring-destructive':
            cellErrors || errors?.date || errors?.endDate || errors?.time || errors?.endTime
        })}
        ref={startDateRef.setReference}
        data-testid={`date-time-edit-${rowId}-${fieldId}`}
      >
        {hasDate && (
          <>
            <input
              placeholder={getDateFormat(dateFormat)}
              className={cn('max-w-[120px] flex-grow border-0 bg-base p-2', {
                'ring-2 ring-black focus:ring-2 focus:ring-black':
                  !cellErrors && selectedCell.isEditing,
                'ring-2 ring-destructive focus:ring-2 focus:ring-destructive': cellErrors,
                'size-full max-w-full opacity-0': !selectedCell.isEditing,
                'size-full max-w-full': !hasTime,
                'max-h-7': selectedCell.isEditing
              })}
              onClick={(e) => {
                if (!selectedCell.isEditing) {
                  setIsEditing(true);
                  setCursorPositionAtTheEnd(e);
                }

                setIsEditingEndDate(false);
              }}
              defaultValue={rawValue.date_formatted}
              onKeyDown={handleKeyDown}
              data-testid={`edit-start-date-input-${rowId}-${fieldId}`}
              onFocus={setCursorPositionAtTheEnd}
              {...register('date', {
                onChange: () => onValueChange()
              })}
            />

            {selectedCell.isEditing && !isEditingEndDate && (
              <div
                ref={startDateRef.setFloating}
                style={startDateFloatingStyles}
                className={cn('mt-1 flex rounded-lg border-2 border-default bg-base', {
                  hidden: !selectedCell.isEditing
                })}
                data-testid={`start-date-date-picker-${rowId}-${fieldId}`}
              >
                <div className="overflow-auto">
                  <Calendar
                    mode="single"
                    fromYear={DAY_PICKER_FROM_YEAR}
                    toYear={DAY_PICKER_TO_YEAR}
                    selected={
                      // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                      !errors?.date?.message && rawValue.iso_timestamp
                        ? selectedDate.toJSDate()
                        : undefined
                    }
                    onSelect={(date) => onDateSelection(date)}
                    month={
                      !errors?.date?.message && rawValue.iso_timestamp
                        ? selectedDate.toJSDate()
                        : undefined
                    }
                    onMonthChange={(month) =>
                      onDateSelection(
                        new Date(
                          month.getFullYear(),
                          month.getMonth(),
                          !errors?.date?.message && rawValue.iso_timestamp
                            ? selectedDate.day
                            : undefined
                        )
                      )
                    }
                    footer={
                      hasTime ? (
                        <CalendarFooter
                          saveCell={saveCellValue}
                          hasRepeatFormat={hasRepeatFormat}
                          setHasRepeatFormat={setHasRepeatFormat}
                          setAllDayFormat={setAllDayFormat}
                          allDayFormat={allDayFormat}
                          fieldId={fieldId}
                          rowId={rowId}
                          formPropName="date"
                          currentDateTimestamp={
                            formatTime(rawValue?.iso_timestamp, timeFormat) || ''
                          }
                          hasAmPmTimeFormat={hasAmPmTimeFormat}
                          dateAmPmTimeFormat={startDateAmPmTimeFormat || 'AM'}
                          currentValue={rawValue}
                          dateFormat={dateFormat}
                          hasEndTime
                        />
                      ) : null
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}

        {hasTime && !allDayFormat && (
          <Time
            dateAmPmTimeFormat={startDateAmPmTimeFormat || 'AM'}
            setDateAmPmTimeFormat={setStartDateAmPmTimeFormat}
            handleKeyDown={handleKeyDown}
            hasDate={hasDate}
            hasAmPmTimeFormat={hasAmPmTimeFormat}
            rowId={rowId}
            fieldId={fieldId}
            timeFormat={timeFormat || 'HH:MM am'}
            onTimeChange={() => onValueChange()}
            formPropName="time"
          />
        )}

        <div className="flex size-full flex-row items-center" ref={endDateRef.setReference}>
          <span className={cn('ml-1 mr-2 bg-base', { hidden: !selectedCell.isEditing })}>
            {t('components.data_table.attributes.field_labels.date_time.to')}
          </span>

          {hasDate && (
            <>
              <input
                placeholder={getDateFormat(dateFormat)}
                className={cn(
                  'max-w-[120px] flex-grow border-0 bg-base p-2 ring-2 ring-black focus:ring-2 focus:ring-black',
                  {
                    'ring-destructive focus:ring-destructive': cellErrors,
                    'size-full max-w-full opacity-0': !selectedCell.isEditing,
                    'size-full max-w-full': !hasTime,
                    'max-h-7': selectedCell.isEditing
                  }
                )}
                onClick={(e) => {
                  if (!selectedCell.isEditing) {
                    setIsEditing(true);
                    setCursorPositionAtTheEnd(e);
                  }

                  setIsEditingEndDate(true);
                }}
                defaultValue={rawValue?.to?.date_formatted}
                onKeyDown={handleKeyDown}
                data-testid={`edit-end-date-input-${rowId}-${fieldId}`}
                onFocus={setCursorPositionAtTheEnd}
                {...register('endDate', {
                  onChange: () => onValueChange()
                })}
              />

              {selectedCell.isEditing && isEditingEndDate && (
                <div
                  ref={endDateRef.setFloating}
                  style={endDateFloatingStyles}
                  className={cn('mt-1 flex rounded-lg border-2 border-default bg-default', {
                    hidden: !selectedCell.isEditing
                  })}
                  data-testid={`end-date-date-picker-${rowId}-${fieldId}`}
                >
                  <div className="overflow-auto">
                    <Calendar
                      mode="single"
                      fromYear={DAY_PICKER_FROM_YEAR}
                      toYear={DAY_PICKER_TO_YEAR}
                      selected={
                        // We don't need to change the zone here, the date picker will show a wrong date if we do it.
                        !errors?.endDate?.message && rawValue.to?.iso_timestamp
                          ? selectedEndDate.toJSDate()
                          : undefined
                      }
                      onSelect={(endDate) => onDateSelection(endDate, true)}
                      month={
                        !errors?.endDate?.message && rawValue.to?.iso_timestamp
                          ? selectedEndDate.toJSDate()
                          : undefined
                      }
                      onMonthChange={(month) =>
                        onDateSelection(
                          new Date(
                            month.getFullYear(),
                            month.getMonth(),
                            !errors?.endDate?.message && rawValue.to?.iso_timestamp
                              ? selectedEndDate.day
                              : undefined
                          ),
                          true
                        )
                      }
                      footer={
                        hasTime ? (
                          <CalendarFooter
                            saveCell={saveCellValue}
                            setHasRepeatFormat={setHasRepeatFormat}
                            hasRepeatFormat={hasRepeatFormat}
                            setAllDayFormat={setAllDayFormat}
                            allDayFormat={allDayFormat}
                            fieldId={fieldId}
                            rowId={rowId}
                            formPropName="endDate"
                            currentDateTimestamp={
                              formatTime(rawValue?.to?.iso_timestamp, timeFormat) || ''
                            }
                            hasAmPmTimeFormat={hasAmPmTimeFormat}
                            dateAmPmTimeFormat={endDateAmPmTimeFormat || 'AM'}
                            currentValue={rawValue}
                            dateFormat={dateFormat}
                            hasEndTime
                          />
                        ) : null
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {hasTime && !allDayFormat && (
            <Time
              dateAmPmTimeFormat={endDateAmPmTimeFormat || 'AM'}
              setDateAmPmTimeFormat={setEndDateAmPmTimeFormat}
              handleKeyDown={handleKeyDown}
              hasDate={hasDate}
              hasAmPmTimeFormat={hasAmPmTimeFormat}
              rowId={rowId}
              fieldId={fieldId}
              timeFormat={timeFormat || 'HH:MM am'}
              onTimeChange={() => onValueChange()}
              formPropName="endTime"
            />
          )}
        </div>

        {hasErrors && (
          <div className="absolute left-0 top-full w-full bg-base">
            {selectedCell.isEditing && hasDate && errors?.date?.message && (
              <CellError
                message={errors.date.message}
                testId={`edit-start-date-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasTime && errors?.time?.message && (
              <CellError
                message={errors.time.message}
                testId={`edit-start-time-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasDate && errors?.endDate?.message && (
              <CellError
                message={errors.endDate.message}
                testId={`edit-end-date-error-${rowId}-${fieldId}`}
              />
            )}

            {selectedCell.isEditing && hasTime && errors?.endTime?.message && (
              <CellError
                message={errors.endTime.message}
                testId={`edit-end-time-error-${rowId}-${fieldId}`}
              />
            )}

            <CellErrors rowId={rowId} fieldId={fieldId} />
          </div>
        )}
      </div>
    </FormProvider>
  );
}
