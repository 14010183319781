import { useCallback, useMemo } from 'react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import {
  type KnackConnection,
  type KnackObject,
  type KnackObjectProfileKey
} from '@/types/schema/KnackObject';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';

export type PageDataSource = {
  type: 'page' | 'user' | 'all';
  object: KnackObject;
  parentObjectKey: string | null;
  roleObjectKey: string | null;
  recordDisplayQuantity: 'one' | 'many';
  source: {
    objectKey: string;
    isAuthenticatedUser: boolean;
    connectionKey?: string;
    relationshipType?: 'local' | 'foreign';
    parentSource?: {
      connectionKey: string;
      objectKey: string;
    };
  };
  sourceParts: {
    fromObject: PageDataSourcePartObject;
    toObject?: PageDataSourcePartObject;
    parentObject?: PageDataSourcePartObject;
  };
  connections: {
    direct: {
      object: {
        key: string;
        name: string;
        inflections: KnackObject['inflections'];
      };
      field: {
        key: string;
        names: {
          object: string;
          field: string;
        };
      };
      relationshipType: 'local' | 'foreign';
    };
    parent?: {
      object: {
        key: string;
        name: string;
        inflections: KnackObject['inflections'];
      };
      field: {
        key: string;
        names: {
          object: string;
          field: string;
        };
      };
    };
  } | null;
};

export type PageDataSourcePartObject = {
  key: string;
  type: KnackObject['type'];
  inflections: KnackObject['inflections'];
  origin: 'pageObject' | 'userObject' | 'none';
};

export function usePageDataSources() {
  const { getObjectByKey, getObjectByFieldKey } = useObjectHelpers();
  const { data: application } = useApplicationQuery();

  const objects = useMemo(() => application?.objects ?? [], [application]);

  const getConnectionNameParts = useCallback(
    (fieldKey: string) => {
      const object = getObjectByFieldKey(fieldKey);
      const field = object?.fields.find((f) => f.key === fieldKey);

      if (object && field) {
        return {
          object: object.inflections.singular,
          field: field.name
        };
      }

      return {
        object: '',
        field: ''
      };
    },
    [getObjectByFieldKey]
  );

  // Gets page data sources from the further connected objects of a connection object
  const getDataSourcesFromFurtherConnections = useCallback(
    (
      connection: KnackConnection,
      isOutgoingConnection: boolean,
      connectionObject: KnackObject,
      originObject: KnackObject,
      isUser: boolean
    ) => {
      if (!connectionObject.connections) {
        return [];
      }

      const pageDataSources: PageDataSource[] = [];

      // Map of the connection object's fields by key
      const connectionObjectFieldKeys = new Set(connectionObject.fields.map((field) => field.key));

      [...connectionObject.connections.inbound, ...connectionObject.connections.outbound].forEach(
        (subConnection) => {
          const isOutgoingSubConnection = connectionObjectFieldKeys.has(subConnection.key);

          const subConnectionHas = isOutgoingSubConnection
            ? subConnection.has
            : subConnection.belongs_to;
          const connectionHas = isOutgoingConnection ? connection.has : connection.belongs_to;

          // Don't reconnect single connections back to original object
          if (subConnectionHas === 'one' && subConnection.key === connection.key) {
            return;
          }

          // We can't connect to a further single record from many connected records
          if (subConnectionHas === 'one' && connectionHas === 'many') {
            return;
          }

          const subConnectionObject = getObjectByKey(subConnection.object);

          if (
            !subConnectionObject ||
            connectionObject.type === 'EcommercePaymentObject' ||
            connectionObject.type === 'EcommercePaymentMethodObject'
          ) {
            return;
          }

          pageDataSources.push({
            type: isUser ? 'user' : 'page',
            recordDisplayQuantity: connectionHas === 'many' ? connectionHas : subConnectionHas,
            object: subConnectionObject,
            parentObjectKey: connectionObject.key,
            roleObjectKey: isUser && originObject ? originObject.key : null,
            sourceParts: {
              fromObject: {
                type: subConnectionObject.type,
                key: subConnectionObject.key,
                inflections: subConnectionObject.inflections,
                origin: 'none'
              },
              toObject: {
                type: connectionObject.type,
                key: connectionObject.key,
                inflections: connectionObject.inflections,
                origin: 'none'
              },
              parentObject: {
                type: originObject.type,
                key: originObject.key,
                inflections: originObject.inflections,
                origin: isUser ? 'userObject' : 'pageObject'
              }
            },
            source: {
              isAuthenticatedUser: isUser,
              objectKey: subConnection.object,
              connectionKey: subConnection.key,
              relationshipType: isOutgoingSubConnection ? 'local' : 'foreign',
              parentSource: {
                connectionKey: connection.key,
                objectKey: connectionObject.key
              }
            },
            connections: {
              direct: {
                object: {
                  key: connectionObject.key,
                  name: connectionObject.name,
                  inflections: connectionObject.inflections
                },
                field: {
                  key: subConnection.key,
                  names: getConnectionNameParts(subConnection.key)
                },
                relationshipType: isOutgoingSubConnection ? 'local' : 'foreign'
              },
              parent: {
                object: {
                  key: originObject.key,
                  name: originObject.name,
                  inflections: originObject.inflections
                },
                field: {
                  key: connection.key,
                  names: getConnectionNameParts(connection.key)
                }
              }
            }
          });
        }
      );

      return pageDataSources;
    },
    [getConnectionNameParts, getObjectByKey]
  );

  // Gets page data sources from the page source object's connections
  const getDataSourcesFromPageObject = useCallback(
    (pageSourceObject: KnackObject) => {
      if (!pageSourceObject.connections) {
        return [];
      }

      const pageDataSourcesFromPageObject: PageDataSource[] = [];

      // Map of the page source object's fields by key
      const pageSourceObjectFieldKeys = new Set(pageSourceObject.fields.map((field) => field.key));

      [...pageSourceObject.connections.inbound, ...pageSourceObject.connections.outbound].forEach(
        (connection) => {
          const connectionObject = getObjectByKey(connection.object);

          if (
            !connectionObject ||
            connectionObject.type === 'EcommercePaymentObject' ||
            connectionObject.type === 'EcommercePaymentMethodObject'
          ) {
            return;
          }

          const isOutgoingConnection = pageSourceObjectFieldKeys.has(connection.key);
          const connectionHas = isOutgoingConnection ? connection.has : connection.belongs_to;

          pageDataSourcesFromPageObject.push({
            type: 'page',
            recordDisplayQuantity: connectionHas,
            object: connectionObject,
            parentObjectKey: null,
            roleObjectKey: null,
            sourceParts: {
              fromObject: {
                type: connectionObject.type,
                key: connectionObject.key,
                inflections: connectionObject.inflections,
                origin: 'none'
              },
              toObject: {
                type: pageSourceObject.type,
                key: pageSourceObject.key,
                inflections: pageSourceObject.inflections,
                origin: 'pageObject'
              }
            },
            connections: {
              direct: {
                object: {
                  key: pageSourceObject.key,
                  name: pageSourceObject.name,
                  inflections: pageSourceObject.inflections
                },
                field: {
                  key: connection.key,
                  names: getConnectionNameParts(connection.key)
                },
                relationshipType: isOutgoingConnection ? 'local' : 'foreign'
              }
            },
            source: {
              isAuthenticatedUser: false,
              objectKey: connection.object,
              connectionKey: connection.key,
              relationshipType: isOutgoingConnection ? 'local' : 'foreign'
            }
          });

          const dataSourcesFromFurtherConnections = getDataSourcesFromFurtherConnections(
            connection,
            isOutgoingConnection,
            connectionObject,
            pageSourceObject,
            false
          );

          pageDataSourcesFromPageObject.push(...dataSourcesFromFurtherConnections);
        }
      );

      return pageDataSourcesFromPageObject;
    },
    [getConnectionNameParts, getDataSourcesFromFurtherConnections, getObjectByKey]
  );

  // Gets page data sources from the user roles (allowedProfileKeys) the page allows access to
  const getDataSourcesFromUserRoles = useCallback(
    (profileKeys: BuilderPage['allowedProfileKeys']) => {
      const pageDataSourcesFromUserRoles: PageDataSource[] = [];
      const rolesToSearch: KnackObjectProfileKey[] = profileKeys
        ? [...profileKeys, 'all_users']
        : ['all_users'];

      rolesToSearch.forEach((role) => {
        const roleObject = objects.find((object) => object.profile_key === role);

        if (!roleObject) {
          return;
        }

        pageDataSourcesFromUserRoles.push({
          type: 'user',
          recordDisplayQuantity: 'one',
          object: roleObject,
          roleObjectKey: roleObject.key,
          parentObjectKey: null,
          sourceParts: {
            fromObject: {
              type: roleObject.type,
              key: roleObject.key,
              inflections: roleObject.inflections,
              origin: 'userObject'
            }
          },
          source: {
            isAuthenticatedUser: true,
            objectKey: roleObject.key
          },
          connections: null
        });

        // Map of the role object's fields by key
        const roleObjectFieldKeys = new Set(roleObject.fields.map((field) => field.key));

        [...roleObject.connections.inbound, ...roleObject.connections.outbound].forEach(
          (connection) => {
            const connectionObject = getObjectByKey(connection.object);

            if (!connectionObject) {
              return;
            }

            const isOutgoingConnection = roleObjectFieldKeys.has(connection.key);

            pageDataSourcesFromUserRoles.push({
              type: 'user',
              recordDisplayQuantity: isOutgoingConnection ? connection.has : connection.belongs_to,
              object: connectionObject,
              roleObjectKey: roleObject.key,
              parentObjectKey: null,
              sourceParts: {
                fromObject: {
                  type: connectionObject.type,
                  key: connectionObject.key,
                  inflections: connectionObject.inflections,
                  origin: 'none'
                },
                toObject: {
                  type: roleObject.type,
                  key: roleObject.key,
                  inflections: roleObject.inflections,
                  origin: 'userObject'
                }
              },
              source: {
                isAuthenticatedUser: true,
                objectKey: connection.object,
                connectionKey: connection.key,
                relationshipType: isOutgoingConnection ? 'local' : 'foreign'
              },
              connections: {
                direct: {
                  object: {
                    key: roleObject.key,
                    name: roleObject.name,
                    inflections: roleObject.inflections
                  },
                  field: {
                    key: connection.key,
                    names: getConnectionNameParts(connection.key)
                  },
                  relationshipType: isOutgoingConnection ? 'local' : 'foreign'
                }
              }
            });

            const dataSourcesFromFurtherConnections = getDataSourcesFromFurtherConnections(
              connection,
              isOutgoingConnection,
              connectionObject,
              roleObject,
              true
            );

            pageDataSourcesFromUserRoles.push(...dataSourcesFromFurtherConnections);
          }
        );
      });

      return pageDataSourcesFromUserRoles;
    },
    [getConnectionNameParts, getDataSourcesFromFurtherConnections, getObjectByKey, objects]
  );

  // Gets the data sources of a page to be used by the views
  const getPageDataSources = useCallback(
    (page: BuilderPage) => {
      const pageSourceObject: KnackObject | undefined = page.sourceObjectKey
        ? objects.find((object) => object.key === page.sourceObjectKey)
        : undefined;

      // If there is a page source object, we only need the page source object itself and its connected objects
      if (pageSourceObject) {
        const singleRecordDataSource: PageDataSource = {
          type: 'page',
          object: pageSourceObject,
          recordDisplayQuantity: 'one',
          parentObjectKey: null,
          roleObjectKey: null,
          connections: null,
          source: {
            isAuthenticatedUser: false,
            objectKey: pageSourceObject.key
          },
          sourceParts: {
            fromObject: {
              type: pageSourceObject.type,
              key: pageSourceObject.key,
              inflections: pageSourceObject.inflections,
              origin: 'pageObject'
            }
          }
        };

        const dataSourcesFromPageObject = getDataSourcesFromPageObject(pageSourceObject);
        return [singleRecordDataSource, ...dataSourcesFromPageObject];
      }

      // If there's no page source object we need data sources from all objects
      const dataSourcesFromAllObjects: PageDataSource[] = objects.map((object) => ({
        type: 'all',
        recordDisplayQuantity: 'many',
        object,
        parentObjectKey: null,
        roleObjectKey: null,
        label: object.inflections.singular,
        sourceParts: {
          fromObject: {
            type: object.type,
            key: object.key,
            inflections: object.inflections,
            origin: 'none'
          }
        },
        source: {
          objectKey: object.key,
          isAuthenticatedUser: false
        },
        connections: null
      }));

      if (page.requiresAuthentication) {
        const dataSourcesFromUserObjects = getDataSourcesFromUserRoles(page.allowedProfileKeys);
        return [...dataSourcesFromAllObjects, ...dataSourcesFromUserObjects];
      }

      return dataSourcesFromAllObjects;
    },
    [objects, getDataSourcesFromPageObject, getDataSourcesFromUserRoles]
  );

  return { getPageDataSources };
}
