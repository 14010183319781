import { type KnackObject } from '@/types/schema/KnackObject';
import { KnackTableIcon } from '@/components/KnackTableIcon';

type ConnectionTableProps = {
  table: KnackObject;
  className?: string;
};

export function ConnectionTable({ table, className }: ConnectionTableProps) {
  return (
    <span
      key={table.name}
      className={`flex items-center gap-1 truncate rounded bg-muted px-2 py-1 ${className}`}
    >
      <KnackTableIcon tableType={table.type} className="shrink-0" size={16} />
      <span className="truncate">{table.name}</span>
    </span>
  );
}
