import { t } from 'i18next';
import { z } from 'zod';

import { type EmailField } from '@/types/schema/fields';

const LABEL_INPUT_MAX_LENGTH = 100;

export function getEmailSchema(field: EmailField) {
  const { required } = field;
  return z.object({
    email: z
      .string()
      .email({ message: t('components.data_table.errors.no_valid_field', { field: 'email' }) })
      .optional()
      .or(z.literal(''))
      .refine((email) => !required || email, t('components.data_table.errors.required_field')),
    label: z
      .string()
      .max(
        LABEL_INPUT_MAX_LENGTH,
        t('components.data_table.errors.field_max_length', {
          field: 'label',
          max: LABEL_INPUT_MAX_LENGTH
        })
      )
      .nullish()
      .or(z.literal(''))
  });
}
