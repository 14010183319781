import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { TextTooltip } from '@/components/TextTooltip';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';
import HideFieldKeysSvg from '@/assets/svg/hide-field-keys.svg?react';
import ShowFieldKeysSvg from '@/assets/svg/show-field-keys.svg?react';

export function ShowFieldKeys() {
  const [t] = useTranslation();

  const shouldShowFieldKeys = useFieldsStore().use.shouldShowFieldKeys();

  const { setShouldShowFieldKeys } = useFieldsStore().use.actions();

  return (
    <TextTooltip
      label={t(
        `pages.tables_page.fields_list.header.${shouldShowFieldKeys ? 'hide' : 'show'}_field_keys`
      )}
    >
      <Button
        intent="minimalStandalone"
        className="mr-2"
        onClick={() => setShouldShowFieldKeys(!shouldShowFieldKeys)}
      >
        {shouldShowFieldKeys ? <ShowFieldKeysSvg /> : <HideFieldKeysSvg />}
      </Button>
    </TextTooltip>
  );
}
