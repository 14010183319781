import { ChartLineType } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ChartLineType';
import { LegendPlacement } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/LegendPlacement';
import { ShowLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowLabels';
import { ShowPoints } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/ShowPoints';
import { TiltLabels } from '@/pages/pages/settings-panel/view-settings/report/appearance/shared/TiltLabels';

export function LineChartAppearanceSettings() {
  return (
    <>
      <ChartLineType />
      <TiltLabels />
      <ShowPoints />
      <ShowLabels />
      <LegendPlacement />
    </>
  );
}
