import { useTranslation } from 'react-i18next';
import { HiArrowPath, HiEnvelope as WelcomeEmailIcon } from 'react-icons/hi2';
import { MdContactMail } from 'react-icons/md';
import { generatePath, Link } from 'react-router-dom';
import { DropdownMenu } from '@knack/asterisk-react';

import { assertTruthiness } from '@/utils/assert';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { TextTooltip } from '@/components/TextTooltip';
import { ROUTES } from '@/Router';

export function UserRoleRowActions({ rowId }: { rowId: string }) {
  const [t] = useTranslation();
  const table = useCurrentTable();
  assertTruthiness(table, 'Table not found');

  const userStatusField = table.fields.find(
    (field) => field.user && field.type === 'multiple_choice'
  );
  assertTruthiness(userStatusField, 'User Status field not found');

  const userStatus = useDataTableStore().use.getValue(rowId, userStatusField.key);

  return (
    <>
      <TextTooltip
        label={
          userStatus.rawValue !== 'active'
            ? t('components.data_table.row_actions.send_welcome_email_activate')
            : ''
        }
      >
        <DropdownMenu.Item
          data-testid={`data-table-${rowId}-contextual-menu-send-welcome-email`}
          disabled={userStatus.rawValue !== 'active'}
          asChild
        >
          <Link
            to={generatePath(ROUTES.ROLES_ID_SEND_WELCOME_EMAIL, {
              id: table?.key || null,
              rowId
            })}
          >
            <WelcomeEmailIcon size={18} className="mr-2" />
            {t(`components.data_table.row_actions.send_welcome_email`)}
          </Link>
        </DropdownMenu.Item>
      </TextTooltip>

      <DropdownMenu.Item
        data-testid={`data-table-${rowId}-contextual-menu-send-account-info`}
        asChild
      >
        <Link
          to={generatePath(ROUTES.ROLES_ID_SEND_ACCOUNT_INFO, {
            id: table?.key || null,
            rowId
          })}
        >
          <MdContactMail size={18} className="mr-2" />
          {t(`components.data_table.row_actions.send_account_info`)}
        </Link>
      </DropdownMenu.Item>

      <DropdownMenu.Item data-testid={`data-table-${rowId}-contextual-menu-reset-password`} asChild>
        <Link
          to={generatePath(ROUTES.ROLES_ID_RESET_PASSWORD, {
            id: table?.key || null,
            rowId
          })}
        >
          <HiArrowPath size={18} className="mr-2" />
          {t(`components.data_table.row_actions.reset_password`)}
        </Link>
      </DropdownMenu.Item>

      <DropdownMenu.Separator />
    </>
  );
}
