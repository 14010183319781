import { useEffect } from 'react';
import { Chip } from '@knack/asterisk-react';

import { sanitizeHTMLTags } from '@/utils/formatters';
import { ConnectionsEdit } from '@/components/data-table/display/fields/connection/ConnectionsEdit';
import { useGetConnectionRecords } from '@/components/data-table/display/fields/connection/useGetConnectionQuery';
import { type ConnectionField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ConnectionValues(props: {
  rawValue: ConnectionField['rawValue'];
  onDeleteOption?: (option: { id: string; identifier: string }) => void;
}) {
  const { rawValue, onDeleteOption } = props;

  return (
    <div className="size-full overflow-auto">
      <div className="flex flex-wrap">
        {rawValue?.length > 0 &&
          rawValue?.map((item) => (
            <Chip
              text={sanitizeHTMLTags(item.identifier)}
              key={sanitizeHTMLTags(item.identifier)}
              className="m-1 mb-0"
              {...(onDeleteOption && {
                onClose: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteOption(item);
                }
              })}
            />
          ))}
      </div>
    </div>
  );
}

export function ConnectionRender(props: FieldRenderProps<ConnectionField>) {
  const { rawValue, isFloating, rowId, fieldId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { relationship, format } = currentField;

  const getConnectionsQuery = useGetConnectionRecords(relationship?.object || '');
  const draftRows = useDataTableStore().use.draftRows();
  const { saveCell } = useDataTableStore().use.actions();

  const isNewRow = rowId?.startsWith('new_row');
  const hasDefaultValue =
    format?.conn_default === 'first' && !!getConnectionsQuery.data?.records?.length;

  useEffect(() => {
    if (isNewRow && hasDefaultValue && !rawValue?.length && !draftRows[rowId][fieldId]) {
      const firstItem = getConnectionsQuery.data?.records[0];
      const newRawValue = [
        {
          id: firstItem?.id || '',
          identifier: firstItem?.identifier.toString() || ''
        }
      ];
      void saveCell(rowId, fieldId, newRawValue, {
        value: '',
        rawValue: newRawValue
      });
    }
  }, [
    draftRows,
    fieldId,
    getConnectionsQuery.data?.records,
    hasDefaultValue,
    isNewRow,
    rawValue?.length,
    rowId,
    saveCell
  ]);

  if (isFloating) {
    return <ConnectionsEdit {...props} />;
  }

  return <ConnectionValues rawValue={rawValue} />;
}
