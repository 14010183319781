import { type ParagraphTextField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ParagraphTextEdit } from '@/components/data-table/display/fields/paragraph-text/ParagraphTextEdit';

export function ParagraphTextValue(props: FieldRenderProps<ParagraphTextField>) {
  const { rawValue } = props;

  return (
    <div className="flex size-full p-2">
      <p className="line-clamp-3 w-full whitespace-pre-wrap break-words">{rawValue}</p>
    </div>
  );
}

export function ParagraphTextRender(props: FieldRenderProps<ParagraphTextField>) {
  const { isFloating } = props;

  if (isFloating) {
    return <ParagraphTextEdit {...props} />;
  }

  return <ParagraphTextValue {...props} />;
}
