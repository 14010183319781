import { Position } from 'reactflow';

export const calculateSourcePosition = (
  sourceNodeWidth: number,
  sourceNodeX: number,
  targetNodeWidth: number,
  targetNodeX: number
) => {
  if (sourceNodeX > targetNodeX + targetNodeWidth || sourceNodeX + sourceNodeWidth > targetNodeX) {
    return Position.Left;
  }
  return Position.Right;
};
