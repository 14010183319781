import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { type LinkField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { LinkRender } from '@/components/data-table/display/fields/link/LinkRender';
import { getLinkSchema } from '@/components/data-table/display/fields/link/LinkSchema';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function LinkEdit(props: FieldRenderProps<LinkField>) {
  const { value, rawValue, fieldId, rowId, type } = props;

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const [t] = useTranslation();

  const currentFieldSettings = useDataTableStore().use.currentFieldSettings();
  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { format: linkFormat } = currentField;

  const textFormat = linkFormat?.text_format || 'url';
  const isUniqueTextFormat = textFormat === 'unique';

  const linkFormSchema = getLinkSchema(currentField);

  type LinkFormSchema = z.infer<typeof linkFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<LinkFormSchema>({
    resolver: zodResolver(linkFormSchema),
    defaultValues: {
      url: rawValue.url,
      label: rawValue.label
    }
  });

  const handleForm = useCallback(async () => {
    const formState = getFieldState('url');
    if (!formState.invalid) {
      const newValue = { url: getValues('url'), label: getValues('label') };

      await saveCell(rowId, fieldId, newValue, {
        value: getValues('url'),
        rawValue: { url: getValues('url'), label: getValues('label') }
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('url');
      if (formState.invalid) {
        setValue('url', rawValue.url);
        setValue('label', rawValue.label);
      }

      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab' && isValid) {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter' && isValid) {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('url');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const urlRegister = register('url', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(handleForm)();
    }
  });

  const labelRegister = register('label', {
    onChange: () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      void handleSubmit(handleForm)();
    }
  });

  if (currentFieldSettings === fieldId) return null;

  return (
    <div className="flex size-full flex-col justify-start bg-base">
      {!selectedCell.isEditing && (
        // absolute needed to make it clickable when the cell is selected
        <div className="absolute w-full">
          <LinkRender {...props} isFloating={false} />
        </div>
      )}
      <TextareaCell
        placeholder={t('attributes.field_labels.link.url_placeholder')}
        defaultValue={value}
        intent={cellErrors ? 'destructive' : 'default'}
        className={cn('z-10 min-h-full flex-grow resize-none', {
          'opacity-0': !selectedCell.isEditing,
          'min-h-10': selectedCell.isEditing
        })}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionAtTheEnd(e);
          }
        }}
        data-testid={`edit-link-url-input-${rowId}-${fieldId}`}
        onFocus={setCursorPositionAtTheEnd}
        {...urlRegister}
      />
      <TextareaCell
        defaultValue={rawValue.label}
        placeholder={t('attributes.field_labels.link.label_placeholder')}
        className={cn('min-h-10 flex-grow resize-none', {
          hidden: !selectedCell.isEditing || !isUniqueTextFormat
        })}
        onKeyDown={handleKeyDown}
        data-testid={`edit-link-label-input-${rowId}-${fieldId}`}
        {...labelRegister}
      />

      <CellErrors
        rowId={rowId}
        fieldId={fieldId}
        testIdPrefix="link-edit-error"
        additionalErrors={Object.keys(errors).map((key) => errors[key])}
      />
    </div>
  );
}
