import { EDGE_COLOR_DEFAULT, EDGE_COLOR_HIGHLIGHTED } from '@/pages/data-model/helpers/constants';

export function EdgeMarkers() {
  return (
    <svg style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
      <defs>
        <marker id="circle" viewBox="0 0 6 6" markerWidth="6" markerHeight="6" refX="3" refY="3">
          <circle cx="3" cy="3" r="2" stroke="none" fill={EDGE_COLOR_DEFAULT} />
        </marker>
      </defs>
      <defs>
        <marker
          id="circleHighlighted"
          viewBox="0 0 6 6"
          markerWidth="6"
          markerHeight="6"
          refX="3"
          refY="3"
        >
          <circle cx="3" cy="3" r="2" stroke="none" fill={EDGE_COLOR_HIGHLIGHTED} />
        </marker>
      </defs>
      <marker
        id="arrow"
        viewBox="0 0 10 13"
        markerWidth="5"
        markerHeight="5"
        refX="10"
        refY="6.5"
        orient="auto-start-reverse"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5145 0.908697C0.84797 0.508782 0 0.988896 0 1.76619V12.2338C0 13.0111 0.847971 13.4912 1.5145 13.0913L10.2375 7.85749C10.8849 7.46909 10.8849 6.53091 10.2375 6.14251L1.5145 0.908697Z"
          fill={EDGE_COLOR_DEFAULT}
        />
      </marker>
      <marker
        id="arrowHighlighted"
        viewBox="0 0 10 13"
        markerWidth="5"
        markerHeight="5"
        refX="10"
        refY="6.5"
        orient="auto-start-reverse"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5145 0.908697C0.84797 0.508782 0 0.988896 0 1.76619V12.2338C0 13.0111 0.847971 13.4912 1.5145 13.0913L10.2375 7.85749C10.8849 7.46909 10.8849 6.53091 10.2375 6.14251L1.5145 0.908697Z"
          fill={EDGE_COLOR_HIGHLIGHTED}
        />
      </marker>
    </svg>
  );
}
