import { type FileError } from 'react-dropzone';
import { type useTranslation } from 'react-i18next';

const orderedLocales = [
  {
    code: 'en'
  }
];

export const SUPPORTED_LOCALES = orderedLocales.map(({ code }) => code);
export const DEFAULT_LOCALE = 'en';
export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const SHEET_NAME_MAX_LENGTH = 99;

export const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
] as const;

export const EMERGENCY_ALERT_FEATURE_LEVEL = 4;

export const TABLE_NAME_MAX_CHAR_LENGTH = 99;
export const PAGE_NAME_MAX_CHAR_LENGTH = 256;
export const FIELD_NAME_MAX_LENGTH = 100;

export const PHONE_FORMAT_TO_MASK = {
  '(999) 999-9999': '(000) 000-0000',
  '999.999.9999': '000.000.0000',
  '+99 999 999 99?99': '+00 000 000 0000',
  '+99 (0)999 999 99?99': '+00 (\\0)000 000 0000',
  '99 9999 9999': '00 0000 0000',
  '9999 999 999': '0000 000 000',
  any: ''
};

export const PHONE_EXTENSION_MASK = ' x00000';

export const importDataAllowedFileFormats = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv']
};

export const imageAllowedFileFormats = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp']
};

export const SPREADSHEET_MAX_FILE_SIZE_IN_BYTES = 250 * 1000 * 1000; // 250 MB

export const spreadsheetUploadErrorMapping = (
  error: FileError,
  t: ReturnType<typeof useTranslation>['t']
) => {
  if (error.code === 'file-too-large') {
    return t('components.file_dropzone.file_too_large', {
      size: SPREADSHEET_MAX_FILE_SIZE_IN_BYTES / 1000 / 1000
    });
  }
  if (error.code === 'file-invalid-type') {
    return t('components.file_dropzone.file_invalid_spreadsheet');
  }

  return t('components.file_dropzone.file_upload_error');
};
