import { useEffect, useState } from 'react';
import { type ELK, type ElkExtendedEdge, type ElkNode, type LayoutOptions } from 'elkjs';

import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';
import { type TableNode } from '@/pages/data-model/diagram/TableNodeComponent';

type GetAutoLayoutNodes = (nodes: TableNode[], edges: TableEdge[]) => Promise<TableNode[]>;

export const useElkAutoLayout = () => {
  const [elk, setElk] = useState<ELK>();
  const [getAutoLayoutNodes, setGetAutoLayoutNodes] = useState<GetAutoLayoutNodes>();
  const [isLoading, setIsLoading] = useState(true);

  // On mount, we lazy load the Elkjs library
  useEffect(() => {
    const loadElkjs = async () => {
      const ElkConstructor = await import('elkjs');
      // eslint-disable-next-line new-cap
      const Elk = new ElkConstructor.default();
      setElk(Elk);
    };

    void loadElkjs();
  }, []);

  // Once the Elkjs library is loaded, we set the `getAutoLayoutNodes` function which is what consumers of this hook will use
  useEffect(() => {
    if (!elk) return;

    setGetAutoLayoutNodes(() => async (nodes: TableNode[], edges: TableEdge[]) => {
      const defaultOptions: LayoutOptions = {
        'elk.algorithm': 'org.eclipse.elk.force',
        'elk.spacing.nodeNode': '100'
      };

      const layoutOptions: LayoutOptions = { ...defaultOptions };
      const graph: ElkNode = {
        id: 'root',
        layoutOptions,
        children: nodes as ElkNode[],
        edges: edges as unknown as ElkExtendedEdge[]
      };

      const { children } = await elk.layout(graph);

      return children?.map((node) => ({
        ...node,
        position: { x: node.x, y: node.y },
        positionAbsolute: { x: node.x, y: node.y }
      })) as TableNode[];
    });

    setIsLoading(false);
  }, [elk]);

  return { isLoading, getAutoLayoutNodes };
};
