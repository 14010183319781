import { memo, useContext, type MouseEvent } from 'react';
import { ThemeProviderContext } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';
import { type PartialField } from './fields/Field';
import { FieldRender } from './fields/FieldRender';

type CellRenderProps = {
  column: KnackField;
  rowId: string;
  isFloating: boolean;
};

export function CellRender({ rowId, column, isFloating }: CellRenderProps) {
  const value = useDataTableStore().use.getValue(rowId, column.key);
  const cellErrors = useDataTableStore().use.cellErrors(rowId, column.key);
  const isCellSelected = useDataTableStore().use.isCellSelected(rowId, column.key);
  const isFieldModified = useDataTableStore().use.isFieldModified(rowId, column.key);

  const { setSelectedCell } = useDataTableStore().use.actions();
  const { isDarkMode } = useContext(ThemeProviderContext);

  // TODO: FE-2515 - There is no field disabled, but we keep this code just in case
  const isFieldDisabled = false;

  // Conditional fields are not editable
  const isConditional: boolean = !!column.rules && column.rules.length > 0;

  const onMouseDownHandler = (e: MouseEvent) => {
    if (isFieldDisabled) {
      return;
    }
    const eventTarget = e.target as HTMLElement;
    // We don't want to select the cell if the user is interacting with a button or an interactive element
    if (
      eventTarget.hasAttribute('data-interactive') ||
      eventTarget.tagName === 'BUTTON' ||
      eventTarget.tagName === 'A'
    ) {
      return;
    }

    if (!isCellSelected && value) {
      setSelectedCell(rowId, column.key);
    }
  };

  const fieldRenderProps = {
    fieldId: column.key,
    type: column.type,
    value: value ? value.value : undefined,
    rawValue: value ? value.rawValue : undefined,
    rowId
  } as PartialField;

  const styles = cn('relative flex size-full items-center border-2 border-transparent group', {
    'bg-subtle': isFloating && isConditional,
    'border-black': isCellSelected && !isDarkMode,
    'border-white': isCellSelected && isDarkMode,
    'bg-muted': isFieldDisabled,
    'border-destructive': cellErrors,
    'hover:bg-subtle': !isFloating
  });

  if (isFloating && !isConditional) {
    return (
      <div
        role="dialog"
        data-testid={`data-table-cell-floating-${rowId}_${column.key}`}
        className={styles}
      >
        <FieldRender {...fieldRenderProps} isFloating isEditable={!isConditional} />
      </div>
    );
  }

  return (
    <div
      role="gridcell"
      aria-selected={isCellSelected || false}
      className={styles}
      onMouseDown={onMouseDownHandler}
      tabIndex={0}
      data-testid={`data-table-cell-${rowId}_${column.key}`}
    >
      {!isCellSelected && (
        <div
          className={cn(
            'absolute left-[-2px] top-[-2px] h-[calc(100%+4px)] border-l-4 border-transparent',
            {
              'border-l-green-500': isFieldModified,
              'border-destructive': cellErrors
            }
          )}
        />
      )}

      <FieldRender
        {...fieldRenderProps}
        isFloating={isFloating && !isConditional}
        isEditable={!isConditional}
      />
    </div>
  );
}

export const MemoizedCellRender = memo(CellRender);
