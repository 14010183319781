import { type CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';

export function SortableWrapper({
  pageKey,
  children
}: {
  pageKey: string;
  children: React.ReactNode;
}) {
  const { isSearching } = useLeftPanelContext();
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id: pageKey,
    disabled: isSearching
  });
  const sortableItemStyles: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  if (isDragging) {
    sortableItemStyles.zIndex = 10;
    sortableItemStyles.position = 'relative';
  }

  return (
    <div ref={setNodeRef} style={sortableItemStyles}>
      {children}
    </div>
  );
}
