import { useTranslation } from 'react-i18next';
import {
  amberTokens,
  blueTokens,
  cyanTokens,
  defaultTokens,
  Divider,
  fuchsiaTokens,
  greenTokens,
  Label,
  limeTokens,
  pinkTokens,
  presetNames,
  RadioCardGroup,
  RadioGroup,
  Select,
  Switch,
  violetTokens,
  type ThemeMode
} from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { Section } from '@/pages/themes/theme-editor/Section';
import { Appearance } from '@/pages/themes/theme-editor/styles/Appearance';
import {
  darkBackgroundColorPresets,
  lightBackgroundColorPresets
} from '@/pages/themes/theme-editor/styles/backgroundColorPresets';
import {
  ColorPresetStrip,
  ColorSampleStrip
} from '@/pages/themes/theme-editor/styles/ColorSampleStrip';
import { CustomColors } from '@/pages/themes/theme-editor/styles/CustomColors';
import { TableDesign } from '@/pages/themes/theme-editor/styles/TableDesign';
import {
  DEFAULT_KNACK_APP_THEME_COLOR_PRESET,
  useThemesPageContext
} from '@/pages/themes/ThemesPageContext';

// Keep the TW colors here, otherwise the parser won't pick them up
// and the interpolation in the button below will not work
const presetColors = [
  'bg-brand',
  'bg-blue-500',
  'bg-cyan-500',
  'bg-lime-500',
  'bg-green-500',
  'bg-pink-500',
  'bg-fuchsia-500',
  'bg-violet-500',
  'bg-amber-500'
] as const;

const fonts = [
  'Inter',
  'Fira Sans',
  'Libre Franklin',
  'Merriweather Sans',
  'Lato',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'PT Sans',
  'Roboto',
  'Rubik',
  'Source Sans 3'
] as const;

export function Styles() {
  const [t] = useTranslation();
  const { theme, setThemeField } = useThemesPageContext();
  const { user } = useSession();
  const themeModeOptions = [
    {
      i18nKey: 'system',
      value: 'system'
    },
    {
      i18nKey: 'light_mode_only',
      value: 'light'
    },
    {
      i18nKey: 'dark_mode_only',
      value: 'dark'
    }
  ];
  const filteredPresetNames = user.email.includes('knack') ? presetNames : presetNames.slice(1);
  const filteredPresetColors = user.email.includes('knack') ? presetColors : presetColors.slice(1);

  const getSelectedPresetTokens = () => {
    switch (theme.colors.preset) {
      case 'default':
        return defaultTokens;
      case 'amber':
        return amberTokens;
      case 'blue':
        return blueTokens;
      case 'cyan':
        return cyanTokens;
      case 'fuchsia':
        return fuchsiaTokens;
      case 'green':
        return greenTokens;
      case 'lime':
        return limeTokens;
      case 'pink':
        return pinkTokens;
      case 'violet':
        return violetTokens;
      default:
        return defaultTokens;
    }
  };

  return (
    <>
      <Section sectionNameKey="font">
        <Select
          value={theme.font}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.font = value;
            });
          }}
        >
          <Select.Trigger placeholder={t('keywords.default')} className="w-full rounded-lg" />
          <Select.Content>
            {fonts.map((font, index) => (
              <Select.Item key={`font-${font}`} value={font}>
                {/* The Inter (Knack's default font) name should not be displayed here due to IP reasons */}
                {index === 0 ? t('keywords.default') : font}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="table">
        <TableDesign />
      </Section>
      <Section sectionNameKey="appearance">
        <Appearance />
      </Section>
      <Divider className="my-4" />
      <Section sectionNameKey="colors">
        <RadioCardGroup
          value={theme.mode}
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.mode = value as ThemeMode;
              draft.shouldAllowModeToggle = value === 'system';
            });
          }}
        >
          {themeModeOptions.map((option) => (
            <RadioCardGroup.Card
              key={option.value}
              value={option.value}
              className="items-center p-3 outline-2 sm:p-3"
              width="100%"
            >
              {t(`themes.${option.i18nKey}`)}
            </RadioCardGroup.Card>
          ))}
        </RadioCardGroup>
        <div className="mb-4 mt-2 text-xs">{t('themes.system_mode')}</div>
        <div className="flex items-center gap-2">
          <Switch
            id="show-appearance-mode-toggle"
            checked={theme.shouldAllowModeToggle}
            disabled={theme.mode !== 'system'}
            onCheckedChange={(state) =>
              setThemeField((draft) => {
                draft.shouldAllowModeToggle = state;
              })
            }
          />
          <Label htmlFor="show-appearance-mode-toggle" className="text-sm">
            {t('themes.allow_mode_toggle')}
          </Label>
        </div>
        <div className="mt-6 text-sm">{t('themes.color_options')}</div>
        <RadioGroup
          onValueChange={(value) => {
            setThemeField((draft) => {
              draft.colors.preset =
                value === 'preset' ? DEFAULT_KNACK_APP_THEME_COLOR_PRESET : 'custom';
              draft.colors.customTokens =
                value === 'custom' ? draft.colors.customTokens || getSelectedPresetTokens() : null;
            });
          }}
          value={theme.colors.preset === 'custom' ? 'custom' : 'preset'}
          defaultValue="preset"
        >
          <RadioGroup.Container>
            <RadioGroup.Item value="preset" id="preset-radio" />
            <Label htmlFor="preset-radio">{t('themes.preset_colors')}</Label>
          </RadioGroup.Container>
          <RadioGroup.Container>
            <RadioGroup.Item value="custom" id="custom-radio" />
            <Label htmlFor="custom-radio">{t('themes.customize_all_colors')}</Label>
          </RadioGroup.Container>
        </RadioGroup>
        {theme.colors.preset !== 'custom' && (
          <>
            <div className="mt-6 text-sm">{t('themes.primary_color')}</div>
            <ColorPresetStrip
              presetNames={filteredPresetNames as readonly string[]}
              presetColors={filteredPresetColors as readonly string[]}
              onClick={(index) =>
                setThemeField((draft) => {
                  draft.colors.preset = presetNames[index];
                })
              }
              activeIndex={filteredPresetNames.findIndex((name) => name === theme.colors.preset)}
            />
            {(theme.mode === 'system' || theme.mode === 'light') && (
              <>
                <div className="mt-6 text-sm">{t('themes.light_mode_background_color')}</div>
                <ColorSampleStrip
                  presetColors={lightBackgroundColorPresets as readonly string[]}
                  onClick={(background) =>
                    setThemeField((draft) => {
                      draft.background.light = background as string;
                    })
                  }
                  activeBackground={theme.background.light}
                />
              </>
            )}
            {(theme.mode === 'system' || theme.mode === 'dark') && (
              <>
                <div className="mt-6 text-sm">{t('themes.dark_mode_background_color')}</div>
                <ColorSampleStrip
                  presetColors={darkBackgroundColorPresets as readonly string[]}
                  onClick={(background) =>
                    setThemeField((draft) => {
                      draft.background.dark = background as string;
                    })
                  }
                  activeBackground={theme.background.dark}
                  isInvertedCheckmark
                />
              </>
            )}
          </>
        )}
        {theme.colors.preset === 'custom' && <CustomColors />}
      </Section>
    </>
  );
}
