import { type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, InputSearch } from '@knack/asterisk-react';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { useLeftPanelContext } from '@/pages/pages/LeftPanelContext';
import { PageTree } from './page-tree/PageTree';

export function LeftPanelContent() {
  const [t] = useTranslation();
  const { search, setSearch } = useLeftPanelContext();

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <CollapsiblePanel.Content title={t('components.layout.left_sidebar.pages')} shouldHideDivider>
      <div className="sticky top-0 z-10 -mt-1 mb-1 bg-muted pb-4 pt-1">
        <InputSearch
          aria-label={t('keywords.search')}
          value={search}
          placeholder={t('keywords.search')}
          onChange={onSearch}
          data-testid="search-pages-input"
        />
      </div>
      <Divider />
      <PageTree />
    </CollapsiblePanel.Content>
  );
}
