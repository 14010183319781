import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

type SelectWithLabelProps = {
  onValueChange: (value: string) => void;
  defaultValue: string;
  label: string;
  values: readonly string[];
  translationPrefix: string;
  data_testid: string;
};

export function SelectWithLabel({
  onValueChange,
  defaultValue,
  values,
  translationPrefix,
  label,
  data_testid
}: SelectWithLabelProps) {
  const [t] = useTranslation();

  return (
    <>
      <Label htmlFor={`${data_testid}-select`} className="font-medium">
        {label}
      </Label>
      <Select onValueChange={onValueChange} defaultValue={defaultValue}>
        <Select.Trigger
          id={`${data_testid}-select`}
          className="w-full rounded-lg"
          data-testid={`${data_testid}-select`}
        />
        <Select.Content>
          {values.map((option) => (
            <Select.Item
              key={option}
              value={option}
              data-testid={`${data_testid}-select-value-${option}`}
            >
              {t(`${translationPrefix}.options.${option}`)}
            </Select.Item>
          ))}
        </Select.Content>
      </Select>
    </>
  );
}
