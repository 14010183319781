import { useTranslation } from 'react-i18next';
import { MdAdd as AddIcon } from 'react-icons/md';
import { Button } from '@knack/asterisk-react';

import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function AddNewRow() {
  const [t] = useTranslation();
  const { newRow } = useDataTableStore().use.actions();
  const dataOrder = useDataTableStore().use.dataOrder();

  const currentTable = useCurrentTable();
  const useDataTableState = useDataTableStore();

  return (
    <Button
      intent="secondary"
      className="h-9 whitespace-nowrap"
      onClick={() => {
        newRow();

        const rowVirtualizer = useDataTableState.getState()?.rowVirtualizer;
        if (!rowVirtualizer) return;

        setTimeout(() => {
          rowVirtualizer.scrollToIndex(dataOrder.length, {
            align: 'start',
            behavior: 'smooth'
          });
        }, 1);
      }}
    >
      <AddIcon size={20} className="mr-1 flex-shrink-0 text-default" />
      {currentTable?.type === 'UserObject'
        ? t('components.data_table.header.add_user')
        : t('components.data_table.header.add_record')}
    </Button>
  );
}
