import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { AddRecordViewFlowSteps, useAddRecordViewFlowContext } from './AddRecordViewFlowContext';

export function FlowContinueButton() {
  const [t] = useTranslation();
  const { selectedViewSourcePath, selectedViewSource, currentStep, onContinue } =
    useAddRecordViewFlowContext();

  if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
    return (
      <Button
        onClick={onContinue}
        disabled={!selectedViewSource}
        data-testid="add-view-modal-continue-button"
      >
        {t('actions.continue')}
      </Button>
    );
  }

  if (currentStep === AddRecordViewFlowSteps.selectViewSourcePath) {
    return (
      <Button
        onClick={onContinue}
        disabled={!selectedViewSourcePath}
        data-testid="add-view-modal-continue-button"
      >
        {t('actions.continue')}
      </Button>
    );
  }

  return (
    <Button onClick={onContinue} data-testid="add-view-modal-save-button">
      {t('views.add_view_dialog.confirm_add_view.confirm_button')}
    </Button>
  );
}
