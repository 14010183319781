import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Switch } from '@knack/asterisk-react';

export function RequiredSwitch() {
  const [t] = useTranslation();

  const { setValue, getValues } = useFormContext();

  return (
    <div className="flex items-center">
      <Controller
        name="format.required"
        render={({ field: { onChange, value } }) => (
          <Switch
            id="require-selection-switch"
            className="mr-2"
            data-testid="require-selection-value-switch"
            checked={value}
            onCheckedChange={(checked) => {
              onChange(checked);
              setValue('format.required', checked);
            }}
          />
        )}
      />
      <Label htmlFor="require-selection-switch">
        {t(
          `components.data_table.attributes.field_settings.boolean.required_label.${getValues('format.input') || 'dropdown'}`
        )}
      </Label>
    </div>
  );
}
