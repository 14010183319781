import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState, type RefObject } from 'react';
import { type Table } from '@tanstack/react-table';
import { type Virtualizer } from '@tanstack/react-virtual';

import { cn } from '@/utils/tailwind';
import { CellRender } from '@/components/data-table/display/CellRender';
import { CELL_BORDER_WIDTH } from '@/components/data-table/helpers/constants';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function FloatingCell({
  tableHeaderRef,
  rowVirtualizer,
  table
}: {
  tableHeaderRef: RefObject<HTMLDivElement>;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  table: Table<string>;
}) {
  const selectedCell = useDataTableStore().use.selectedCell();
  const selectedCellValues = useDataTableStore().use.getSelectedCellValues();
  const selectedField = useDataTableStore().use.getSelectedField();
  const { deselectCell } = useDataTableStore().use.actions();

  const ref = useRef<HTMLDivElement>(null);
  const [cellPosition, setCellPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    if (selectedCell && selectedCellValues) {
      const rowPosition = rowVirtualizer?.measurementsCache.find(
        (element) => element.key === selectedCell.rowId
      ) || {
        index: 0,
        start: 0,
        size: 0,
        end: 0,
        key: selectedCell.rowId,
        lane: 0
      };

      const fieldWidth =
        table
          ?.getAllColumns()
          .find((column) => column.id === selectedCell.fieldId)
          ?.getSize() || 0;

      let fieldLeft = 0;
      let found = false;
      const allColumns = table?.getAllColumns();
      allColumns?.forEach((column) => {
        if (column.id === selectedCell.fieldId) found = true;
        if (found) return;
        fieldLeft += column.getSize();
      });

      setCellPosition({
        top: rowPosition.start + (tableHeaderRef.current?.offsetHeight || 0),
        left: fieldLeft,
        width: fieldWidth - CELL_BORDER_WIDTH + 1, // Cover parent border
        height: rowPosition.size - 1 // Cover parent border
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableHeaderRef,
    // Measurements cache change while scrolling so we should not add it to the dependencies
    // rowVirtualizer?.measurementsCache,
    selectedCell,
    selectedCellValues,
    table
  ]);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: 'nearest' });
  }, [cellPosition]);

  // Deselect cell when clicking outside the cell
  useEffect(() => {
    const mouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        const isCellClicked = event.target.closest('[role="gridcell"]') !== null;
        const isDialogClicked = event.target.closest('[role="dialog"]') !== null;
        const isInsideRoot = event.target.closest('#root') !== null;
        const isToolkitPanelContentClicked =
          event.target.closest('[data-testid="toolkit-panel-content"]') !== null;
        if (
          // Clicking anything that is not a cell should deselect the cell
          (!isCellClicked &&
            // Unless it is a dialog, which means we are editing the cell (floating cell is a dialog)
            !isDialogClicked &&
            // Some elements use Portal and are not inside the root element, in this case we don't want to apply deselect as these are dropdowns/popovers used in the inline edit.
            isInsideRoot) ||
          // Clicking the toolkit panel content should deselect the cell
          isToolkitPanelContentClicked
        ) {
          deselectCell();
        }
      }
    };
    window.addEventListener('mousedown', mouseDown);
    return () => {
      window.removeEventListener('mousedown', mouseDown);
    };
  }, [deselectCell]);

  const cellToRender = useMemo(() => {
    if (selectedCell && selectedCellValues && selectedField) {
      return (
        <CellRender
          // We force a mount/unmount of the component to avoid the previous component to be reused
          // This is to fix a bug where inputs have the same value as the previous selected cell
          key={`floating-cell${selectedCell.fieldId}-${selectedCell.rowId}`}
          column={selectedField}
          rowId={selectedCell.rowId}
          isFloating
        />
      );
    }
    return null;
  }, [selectedCell, selectedCellValues, selectedField]);

  return (
    <div
      ref={ref}
      // We create a dummy div that will be used to position the floating element with the size and position of the selected cell
      style={{ ...cellPosition, scrollMarginTop: tableHeaderRef.current?.offsetHeight }}
      // Floating cell will show over other cells, but not over the sticky cell
      className={cn('absolute z-[1] scroll-m-10', { hidden: !selectedCell })}
      data-testid="floating-cell"
    >
      {cellToRender}
    </div>
  );
}

export const MemoizedFloatingCell = memo(FloatingCell);
