import { t } from 'i18next';

import { type PageType } from '@/types/schema/BuilderPage';

export function getNewPageDefaultName(pageType: PageType) {
  switch (pageType) {
    case 'page':
      return t('pages.page_tree.new_page_name');
    case 'menu':
      return t('pages.page_tree.new_dropdown_menu_name');
    case 'user':
      return t('pages.page_tree.new_user_page_name');
    default:
      return t('pages.page_tree.new_page_name');
  }
}
