import { useTranslation } from 'react-i18next';
import { HiOutlineUpload as UploadIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { Button, Tooltip } from '@knack/asterisk-react';

import { formatNumberForStorage } from '@/utils/formatters';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CellLoading } from '@/components/data-table/display/fields/CellLoading';
import {
  type ImageField,
  type ImageFieldRawValueUploadFormat
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import {
  getImageFieldUploadErrorMessage,
  useImageUpload
} from '@/components/data-table/display/fields/image/upload/useImageUpload';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ImageUploadEdit(props: FieldRenderProps<ImageField>) {
  const { rawValue, rowId, fieldId } = props;
  const { url, filename, size } = rawValue as ImageFieldRawValueUploadFormat;
  const [t] = useTranslation();

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useImageUpload(props);
  const { saveCell } = useDataTableStore().use.actions();

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset((state) => ({ mainAxis: -state.rects.reference.height })),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const removeImage = async () => {
    await saveCell(rowId, fieldId, '', { value: '', rawValue: '' });
  };

  return (
    <>
      <div ref={refs.setReference} className="flex size-full bg-black" />
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        className="min-h-full min-w-full bg-base p-1 ring-2 ring-black"
      >
        <div {...getRootProps()}>
          <CellLoading rowId={rowId} fieldId={fieldId} />
          <input {...getInputProps()} />

          <Tooltip>
            <Tooltip.Trigger asChild>
              <img
                src={url}
                alt={filename}
                className="mb-2 size-full min-w-[300px] rounded-lg"
                data-testid={`image-field-render-edit-${rowId}-${fieldId}`}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content className="z-50">{`${filename} (${formatNumberForStorage(size)})`}</Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>

          {fileRejections.length > 0 && (
            <div className="text-sm text-destructive">
              {fileRejections.map((rejection) => (
                <div key={rejection.file.name} className="mb-2">
                  {rejection.errors.map((error) => (
                    <p key={error.code} className="p-1">
                      {getImageFieldUploadErrorMessage(error, t)}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-2">
            <Button intent="secondary" className="h-8 w-full text-sm" onClick={open}>
              <UploadIcon size={16} className="mr-1" />
              {t('components.data_table.image_field.change')}
            </Button>

            <Button
              intent="secondary"
              className="h-8 w-full text-sm"
              data-testid={`image-field-remove-button-${rowId}-${fieldId}`}
              onClick={removeImage}
            >
              <RemoveIcon size={16} className="mr-1" />
              {t('components.data_table.image_field.remove')}
            </Button>
          </div>

          {isDragActive && (
            <div className="absolute left-0 right-0 top-0 flex size-full items-center justify-center bg-base/80 text-center">
              <UploadIcon size={16} className="mr-1" />
              {t('components.data_table.image_field.change')}
            </div>
          )}
          <CellErrors rowId={rowId} fieldId={fieldId} />
        </div>
      </div>
    </>
  );
}
