import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Chip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { type UserRolesField } from '@/components/data-table/display/fields/Field';
import { useCurrentTable } from '@/components/data-table/helpers/useCurrentTable';
import { useUserRolesTables } from '@/components/data-table/helpers/useUserRolesTables';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function UserRolesValues(props: {
  rawValue: UserRolesField['rawValue'];
  onDeleteOption?: (option: string) => void;
}) {
  const { rawValue, onDeleteOption } = props;
  const selectedCell = useDataTableStore().use.selectedCell();

  const userRolesTables = useUserRolesTables();
  const selectedUserRoles =
    userRolesTables?.filter(
      (table) => table.profile_key && rawValue?.includes(table.profile_key)
    ) || [];

  const currentTable = useCurrentTable();

  return (
    <div className="group size-full overflow-auto bg-base pr-6" data-testid="user-roles-values">
      <div
        className={cn(
          'right-1 top-1 hidden h-6 items-center rounded bg-default group-hover:absolute group-hover:flex',
          {
            'bg-emphasis': selectedCell?.isEditing
          }
        )}
      >
        <ChevronDownIcon className="mx-0.5" size={16} />
      </div>

      <div className="flex flex-wrap">
        {selectedUserRoles.length > 0 &&
          selectedUserRoles.map((item) => (
            <Chip
              text={item.name}
              key={item.profile_key}
              className="m-1 mb-0"
              {...(onDeleteOption &&
                // Only show the delete button if the user role is not the current one
                item.profile_key !== currentTable?.profile_key && {
                  onClose: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDeleteOption(item.profile_key || '');
                  }
                })}
            />
          ))}
      </div>
    </div>
  );
}
