import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Select } from '@knack/asterisk-react';

import { MULTIPLE_CHOICE_TYPE_OPTIONS } from '@/types/schema/fields';

export function TypeSelect() {
  const [t] = useTranslation();

  const { getValues } = useFormContext();

  const isRadioButtonsSelected = getValues('format.type') === 'radios';

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="condition-select" className="font-medium">
        {t('components.data_table.attributes.field_settings.multiple_choice.type_label')}
      </Label>
      <Controller
        name="format.type"
        defaultValue={getValues('format.type') || MULTIPLE_CHOICE_TYPE_OPTIONS[0]}
        render={({ field: currentField }) => (
          <Select
            onValueChange={currentField.onChange}
            defaultValue={getValues('format.type') || MULTIPLE_CHOICE_TYPE_OPTIONS[0]}
          >
            <Select.Trigger
              id="multiple-choice-type-select"
              placeholder={t('actions.select')}
              className="w-full rounded-lg"
              data-testid="multiple-choice-form-settings-type-select"
              {...currentField}
            />
            <Select.Content>
              {MULTIPLE_CHOICE_TYPE_OPTIONS.map((format) => (
                <Select.Item
                  key={format}
                  value={format}
                  data-testid={`multiple-choice-settings-type-value-${format}`}
                >
                  {t(
                    `components.data_table.attributes.field_settings.multiple_choice.type_options.${format}`
                  )}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        )}
      />
      {isRadioButtonsSelected && (
        <p className="text-xs font-normal" data-testid="radio-buttons-info-warning">
          {t('components.data_table.attributes.field_settings.multiple_choice.radio_buttons_info')}
        </p>
      )}
    </div>
  );
}
