import { useTranslation } from 'react-i18next';

import type { ChartType, ReportView } from '@/types/schema/views/ReportView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';
import { AreaChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/AreaChartAppearanceSettings';
import { BarChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/BarChartAppearanceSettings';
import { LineChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/LineChartAppearanceSettings';
import { PieChartAppearanceSettings } from '@/pages/pages/settings-panel/view-settings/report/appearance/PieChartAppearanceSettings';
import { ReportGeneralOptions } from '@/pages/pages/settings-panel/view-settings/report/ReportGeneralOptions';
import { ReportTableInfo } from '@/pages/pages/settings-panel/view-settings/report/ReportTableInfo';

export function ReportGeneral() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ReportView>();
  const chartType: ChartType = view.rows[0].reports[0].type;

  return (
    <>
      <ReportTableInfo />
      <BuilderAccordion>
        <ViewTitleDescriptionSettings />

        <BuilderAccordion.Item
          isDefaultOpen
          label={t('pages.element_settings.report.categories.general.appearance')}
        >
          {chartType === 'bar' && <BarChartAppearanceSettings />}
          {chartType === 'pie' && <PieChartAppearanceSettings />}
          {chartType === 'area' && <AreaChartAppearanceSettings />}
          {chartType === 'line' && <LineChartAppearanceSettings />}
        </BuilderAccordion.Item>
        <ReportGeneralOptions />
      </BuilderAccordion>
    </>
  );
}
