import { t } from 'i18next';
import { z } from 'zod';

import { type ImageField } from '@/types/schema/fields';

export function getImageSchema(field: ImageField) {
  const { required } = field;

  return z
    .string()
    .optional()
    .or(z.literal(''))
    .refine((url) => !required || url, t('components.data_table.errors.required_field'));
}
