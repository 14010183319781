import { CiFileOn as FileIcon } from 'react-icons/ci';
import { FaVideo as VideoIcon } from 'react-icons/fa';
import { HiPhoto as ImageIcon } from 'react-icons/hi2';
import { IoIosMusicalNotes as AudioIcon } from 'react-icons/io';

const FILE_CATEGORIES = {
  DOCUMENT: [
    'pdf',
    'doc',
    'html',
    'htm',
    'docx',
    'txt',
    'rtf',
    'odt',
    'xls',
    'xlsx',
    'csv',
    'ods',
    'ppt',
    'pptx',
    'odp'
  ],
  IMAGE: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tiff', 'tif', 'webp'],
  AUDIO: ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a'],
  VIDEO: ['mp4', 'avi', 'mov', 'wmv', 'flv', '3gp', 'mkv', 'webm', 'mpeg', 'mpg', 'm4v']
};

export function getFileIconByType(fileName: string) {
  const fileExtension = fileName.split('.').pop();
  if (!fileExtension) {
    return FileIcon;
  }

  if (FILE_CATEGORIES.DOCUMENT.includes(fileExtension)) {
    return FileIcon;
  }

  if (FILE_CATEGORIES.IMAGE.includes(fileExtension)) {
    return ImageIcon;
  }

  if (FILE_CATEGORIES.AUDIO.includes(fileExtension)) {
    return AudioIcon;
  }

  if (FILE_CATEGORIES.VIDEO.includes(fileExtension)) {
    return VideoIcon;
  }

  return FileIcon;
}
