import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Label, RadioGroup, Spinner, Textarea, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { useShareFeedbackMutation } from '@/hooks/api/mutations/useShareFeedbackMutation';
import { useErrorToast } from '@/hooks/useErrorToast';
import { useHelpPanelStore } from '@/components/help-menu/useHelpPanelStore';

export function ShareFeedbackForm() {
  const { resetPanel } = useHelpPanelStore((state) => ({
    resetPanel: state.resetPanel
  }));
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const presentErrorToast = useErrorToast();
  const { mutate, isPending } = useShareFeedbackMutation();

  const shareFeedbackFormSchema = z.object({
    shareType: z.enum(['builder', 'live-app', 'knowledge-base', 'other']),
    shareFeedback: z.string().min(1)
  });

  type SharedFeedbackFormSchema = z.infer<typeof shareFeedbackFormSchema>;

  const {
    register,
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors }
  } = useForm<SharedFeedbackFormSchema>({
    resolver: zodResolver(shareFeedbackFormSchema),
    defaultValues: {
      shareFeedback: ''
    }
  });

  const onSubmitHandler: SubmitHandler<SharedFeedbackFormSchema> = (data) => {
    mutate(data, {
      onSuccess: () => {
        resetPanel();
        presentToast({
          title: t('components.help_menu.forms.share_feedback.message_success')
        });
      },
      onError: (error) => {
        presentErrorToast({
          error,
          translationPrefix: 'components.help_menu.forms.share_feedback',
          fallbackKey: 'components.help_menu.forms.share_feedback.error_sending_message'
        });
      }
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        id="share-feedback-form"
        data-testid="share-feedback-form"
        className="space-y-4 *:space-y-2"
      >
        <div>
          <Label
            className="text-sm font-medium text-default"
            isRequired
            data-testid="share-feedback-form-what-type"
          >
            {t('components.help_menu.forms.share_feedback.what_type_of_feedback')}
          </Label>
          <Controller
            control={control}
            name="shareType"
            render={({ field }) => (
              <RadioGroup onValueChange={field.onChange} value={field.value} className="mt-2">
                <RadioGroup.Container>
                  <RadioGroup.Item
                    value="builder"
                    id="builder"
                    data-testid="share-feedback-form-builder-option"
                  />
                  <Label htmlFor="builder">
                    {t('components.help_menu.forms.share_feedback.builder')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="live-app" id="live-app" />
                  <Label htmlFor="live-app">
                    {t('components.help_menu.forms.share_feedback.live_app')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="knowledge-base" id="knowledge-base" />
                  <Label htmlFor="knowledge-base">
                    {t('components.help_menu.forms.share_feedback.knowledge_base')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="other" id="other" />
                  <Label htmlFor="other">
                    {t('components.help_menu.forms.share_feedback.other')}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            )}
          />
          {errors.shareType && (
            <p className="text-sm text-destructive" data-testid="share-feedback-form-error-message">
              {t('components.help_menu.forms.share_feedback.this_field_is_required')}
            </p>
          )}
        </div>
        <div>
          <Label
            isRequired
            className="text-sm font-medium text-default"
            data-testid="share-feedback-form-share"
          >
            {t('components.help_menu.forms.share_feedback.share_your_feedback')}
          </Label>
          <p className="text-sm text-muted">
            {t('components.help_menu.forms.share_feedback.helper_text')}
          </p>
          <Textarea
            spellCheck={false}
            {...register('shareFeedback')}
            data-testid="share-feedback-form-textarea"
          />
          {errors.shareFeedback && (
            <p className="text-sm text-destructive">
              {t('components.help_menu.forms.share_feedback.this_field_is_required')}
            </p>
          )}
        </div>
      </form>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          intent="minimal"
          disabled={isPending}
          onClick={() => resetForm()}
          data-testid="share-feedback-form-clear-button"
        >
          {t('actions.clear')}
        </Button>
        <Button
          type="submit"
          form="share-feedback-form"
          disabled={isPending}
          data-testid="share-feedback-form-submit-button"
        >
          {isPending ? <Spinner /> : t('actions.submit')}
        </Button>
      </div>
    </div>
  );
}
