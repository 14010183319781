import { type KnackObjectProfileKey } from '@/types/schema/KnackObject';
import { type LoginView } from '@/types/schema/views/LoginView';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';

export function useUpdateLoginView(loginView: LoginView) {
  const { updatePage, pageInitialState } = usePageEditorContext();

  // We need the initial state of the view (before any local updates) to reuse any existing registration forms when updating the view, instead of creating new ones
  const loginViewInitialState = pageInitialState.views.find((view) => view.type === 'login');
  const initialRegistrationForms = loginViewInitialState?.registrationForms;

  function getUpdatedAccessProperties(partialLoginView: Partial<LoginView>) {
    const {
      limitProfileAccess = loginView.limitProfileAccess,
      allowedProfileKeys = loginView.allowedProfileKeys,
      registrationType = loginView.registrationType
    } = partialLoginView;

    const updatedLoginView: Partial<LoginView> = {
      limitProfileAccess,
      allowedProfileKeys,
      registrationType
    };

    // If registration is closed, remove all registration forms and return
    if (registrationType === 'closed') {
      return { ...updatedLoginView, registrationForms: undefined };
    }

    // If there is no user role restrictions, we don't need any registration forms specific to a profile
    if (!limitProfileAccess) {
      // If there is already a registration form for all users, use that as the only registration form
      if (initialRegistrationForms?.all_users) {
        return {
          ...updatedLoginView,
          registrationForms: {
            all_users: initialRegistrationForms.all_users
          }
        };
      }

      // Otherwise, create a new registration form for all users
      return {
        ...updatedLoginView,
        registrationForms: {
          all_users: {}
        }
      };
    }

    // If there are user role restrictions but no profiles have been selected, return an empty registration forms object
    if (allowedProfileKeys.length === 0) {
      return { ...updatedLoginView, registrationForms: {} };
    }

    // If there are existing registration forms, filter out the ones for profiles that are not in the allowed list of profiles.
    updatedLoginView.registrationForms = Object.fromEntries(
      Object.entries(loginView.registrationForms || {}).filter(([key]) =>
        allowedProfileKeys.includes(key as KnackObjectProfileKey)
      )
    );

    // Add registration forms for each of the allowed profiles, if they don't already exist
    allowedProfileKeys.forEach((profileKey) => {
      if (!loginView.registrationForms?.[profileKey] && updatedLoginView.registrationForms) {
        // If there is an initial registration form for this profile, use that. Otherwise, create a new registration form
        if (initialRegistrationForms?.[profileKey]) {
          updatedLoginView.registrationForms[profileKey] = initialRegistrationForms[profileKey];
        } else {
          updatedLoginView.registrationForms[profileKey] = {};
        }
      }
    });

    return updatedLoginView;
  }

  function updateLoginView(partialLoginView: Partial<LoginView>) {
    // Only perform logic for updating access properties if they are present in the partial view
    const shouldUpdateAccessProperties =
      'limitProfileAccess' in partialLoginView ||
      'allowedProfileKeys' in partialLoginView ||
      'registrationType' in partialLoginView;

    const updatedPartialLoginView: Partial<LoginView> = {
      ...partialLoginView,
      ...(shouldUpdateAccessProperties && { ...getUpdatedAccessProperties(partialLoginView) })
    };

    updatePage({
      type: 'view',
      origin: 'builder',
      action: 'update',
      viewKey: loginView.key,
      viewSchema: updatedPartialLoginView
    });
  }

  return updateLoginView;
}
