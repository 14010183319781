import { type BaseKnackField } from '@/types/schema/KnackField';

export const MULTIPLE_CHOICE_TYPE_OPTIONS = ['single', 'multi', 'checkboxes', 'radios'] as const;
export const MULTIPLE_CHOICE_SORTING_OPTIONS = ['alphabetical', 'custom'] as const;

export type MultipleChoiceFieldType = (typeof MULTIPLE_CHOICE_TYPE_OPTIONS)[number];

export interface MultipleChoiceField extends BaseKnackField {
  type: 'multiple_choice';
  format: {
    blank?: string;
    default: string | 'kn-blank'; // If there is not a default, it will be 'kn-blank'
    options: string[];
    sorting?: (typeof MULTIPLE_CHOICE_SORTING_OPTIONS)[number];
    type?: MultipleChoiceFieldType;
  };
}
