import { t } from 'i18next';
import { nanoid } from 'nanoid';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type BuilderViewSourceSchema } from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { type ViewSource } from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { generateNewViewKey } from './generateNewViewKey';

const NEW_TABLE_COLUMN_LIMIT = 7;
const NEW_TABLE_ROWS_PER_PAGE = '25';

export const baseTableViewSchema: Partial<TableView> = {
  type: 'table',
  description: '',
  totals: [],
  preset_filters: [],
  menu_filters: [],
  groups: [],
  inputs: [],
  links: [],
  rows_per_page: NEW_TABLE_ROWS_PER_PAGE,
  no_data_text: t('views.new_view_defaults.table.no_data_text'),
  table_design_active: false,
  keyword_search: true,
  keyword_search_fields: 'view',
  filter_fields: 'view',
  filter_type: 'fields',
  allow_exporting: false,
  allow_preset_filters: false
};

export const baseTableViewColumnSchema: Partial<TableViewColumn> = {
  grouping: false,
  group_sort: 'asc',
  ignore_edit: false,
  ignore_summary: false,
  conn_separator: '',
  conn_link: '',
  link_type: 'text',
  link_text: '',
  link_field: '',
  link_design_active: false,
  icon: {
    icon: '',
    align: 'left'
  },
  img_gallery: '',
  width: {
    type: 'default',
    units: 'px',
    amount: '50'
  },
  align: 'left',
  rules: []
};

function generateTableViewColumns({
  page,
  viewSourceObject,
  viewSourceSchema
}: {
  page: BuilderPage;
  viewSourceObject: KnackObject;
  viewSourceSchema: BuilderViewSourceSchema;
}) {
  const tableColumns: TableViewColumn[] = [];

  let addedColumnsCount = 0;

  viewSourceObject.fields.forEach((field) => {
    if (addedColumnsCount >= NEW_TABLE_COLUMN_LIMIT) {
      return;
    }

    // Skip password fields
    if (field.type === 'password') {
      return;
    }

    // Skip connection fields if they are connected to the source object of the page
    if (
      field.type === 'connection' &&
      page.sourceObjectKey &&
      field.relationship.object === page.sourceObjectKey
    ) {
      return;
    }

    // Skip user roles and multiple choice fields if the field is a user field, and the view is behind authentication
    if (
      (field.type === 'user_roles' || field.type === 'multiple_choice') &&
      field.user &&
      viewSourceSchema.authenticated_user
    ) {
      return;
    }

    tableColumns.push({
      ...baseTableViewColumnSchema,
      id: `${field.key}_${nanoid(10)}`,
      type: 'field',
      header: field.name,
      field: {
        key: field.key
      }
    } satisfies Partial<TableViewColumn> as TableViewColumn);

    addedColumnsCount += 1;
  });

  return tableColumns;
}

export function getTableViewSchema(
  page: BuilderPage,
  viewSource: ViewSource,
  viewSourceSchema: BuilderViewSourceSchema
) {
  const viewTitle =
    viewSource.recordDisplayQuantity === 'many'
      ? viewSource.object.inflections.plural
      : viewSource.object.inflections.singular;

  const tableViewSchema: Partial<TableView> = {
    ...baseTableViewSchema,
    key: generateNewViewKey(),
    name: viewTitle,
    title: viewTitle,
    source: viewSourceSchema,
    columns: generateTableViewColumns({
      page,
      viewSourceObject: viewSource.object,
      viewSourceSchema
    })
  };

  return tableViewSchema;
}
