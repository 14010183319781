import { Avatar } from '@knack/asterisk-react';

import { useGravatarQuery } from '@/hooks/api/queries/useGravatarQuery';

interface ProfileCardProps {
  email: string;
  firstName?: string;
  avatarUri?: string;
  size?: 'sm' | 'lg' | 'default' | null;
}

export function ProfileCard({ email, firstName, avatarUri, size = 'default' }: ProfileCardProps) {
  const { data: gravatarData } = useGravatarQuery(avatarUri, email);

  const avatarSrc = avatarUri || gravatarData?.gravatarUrl;

  return (
    <div className="flex max-w-full items-center">
      <Avatar size={size} data-testid="avatar" className="border-2 border-subtle">
        <Avatar.Image src={avatarSrc} alt="avatar" />
        <Avatar.Fallback className="bg-pink-50 text-lg text-muted" delayMs={200}>
          {!firstName ? email?.[0]?.toUpperCase() : firstName?.[0]?.toUpperCase()}
        </Avatar.Fallback>
      </Avatar>
    </div>
  );
}
