import { useTranslation } from 'react-i18next';
import { Label, RadioGroup } from '@knack/asterisk-react';

import {
  type DateFormatValue,
  type DateTimeFieldFormat,
  type TimeFormatValue
} from '@/types/schema/fields';
import { type Column } from '@/components/import/types';
import { useImportStore } from '@/components/import/useImportStore';

export function DateTimeFormat({ column }: { column: Column }) {
  const [t] = useTranslation();
  const { columns, setColumns } = useImportStore((state) => ({
    setColumns: state.setColumns,
    columns: state.columns
  }));

  const dateTimeFormat = (column.meta.newFieldFormat as DateTimeFieldFormat) || {};

  const dateFormat = dateTimeFormat?.date_format || 'mm/dd/yyyy';
  const timeFormat = dateTimeFormat?.time_format || 'Ignore Time';

  const dateFormatValues: DateFormatValue[] = [
    'mm/dd/yyyy',
    'dd/mm/yyyy',
    'M D, yyyy',
    'Ignore Date'
  ];
  const timeFormatValues: TimeFormatValue[] = ['HH:MM am', 'HH MM (military)', 'Ignore Time'];

  const handleDateFormatChange = (value: DateFormatValue) => {
    const newColumn = {
      ...column,
      meta: {
        ...column.meta,
        newFieldFormat: {
          ...dateTimeFormat,
          date_format: value
        }
      }
    };

    const newColumns = columns.map((c) => (c.accessorKey === column.accessorKey ? newColumn : c));
    setColumns(newColumns);
  };

  const handleTimeFormatChange = (value: TimeFormatValue) => {
    const newColumn = {
      ...column,
      meta: {
        ...column.meta,
        newFieldFormat: {
          ...dateTimeFormat,
          time_format: value
        }
      }
    };

    const newColumns = columns.map((c) => (c.accessorKey === column.accessorKey ? newColumn : c));
    setColumns(newColumns);
  };

  return (
    <div className="flex flex-col gap-4 p-4" data-testid="import-columns-datetime-format">
      <span className="font-bold">{t('components.add_table.field_date_time_format')}</span>
      <div>
        <span>{t('components.add_table.select_the_date_format')}</span>
        <RadioGroup onValueChange={handleDateFormatChange} className="mt-2" value={dateFormat}>
          {dateFormatValues.map((dateFormatValue) => (
            <RadioGroup.Container key={`${dateFormatValue}-${column.accessorKey}`}>
              <RadioGroup.Item
                value={dateFormatValue}
                id={`date-format-${dateFormatValue}-${column.accessorKey}`}
                data-testid={`import-columns-date-format-radio-item-${dateFormatValue}`}
              />
              <Label htmlFor={`date-format-${dateFormatValue}-${column.accessorKey}`}>
                {dateFormatValue === 'Ignore Date'
                  ? t('components.add_table.ignore_date')
                  : dateFormatValue}
              </Label>
            </RadioGroup.Container>
          ))}
        </RadioGroup>
      </div>
      <div>
        <span>{t('components.add_table.select_the_time_format')}</span>
        <RadioGroup onValueChange={handleTimeFormatChange} className="mt-2" value={timeFormat}>
          {timeFormatValues.map((timeFormatValue) => (
            <RadioGroup.Container key={`${timeFormatValue}-${column.accessorKey}`}>
              <RadioGroup.Item
                value={timeFormatValue}
                id={`time-format-${timeFormatValue}-${column.accessorKey}`}
                data-testid={`import-columns-time-format-radio-item-${timeFormatValue}`}
              />
              <Label htmlFor={`time-format-${timeFormatValue}-${column.accessorKey}`}>
                {timeFormatValue === 'Ignore Time'
                  ? t('components.add_table.ignore_time')
                  : t(`attributes.field_labels.date_time.time_format_options.${timeFormatValue}`, {
                      nsSeparator: false
                    })}
              </Label>
            </RadioGroup.Container>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
