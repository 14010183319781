import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackObject } from '@/types/schema/KnackObject';
import { useTableMutation } from '@/hooks/api/mutations/useTableMutation';
import { ROUTES, type PageUrlParams } from '@/Router';

interface DeleteFormProps {
  table: KnackObject;
  onDelete?: () => void;
  onCancel?: () => void;
  isUser?: boolean;
}

export function DeleteTableForm({ table, onDelete, onCancel, isUser }: DeleteFormProps) {
  const [t] = useTranslation();
  const { deleteMutation } = useTableMutation();
  const navigate = useNavigate();
  const urlParams = useParams<PageUrlParams>();
  const { id: selectedTableKey } = urlParams;
  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    tableName: z.string().refine((val) => val === table.name.trim().replace(/\s+/g, ' '))
  });

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      tableName: ''
    }
  });

  function onSubmit() {
    deleteMutation.mutate(table.key, {
      onSuccess: () => {
        if (onDelete) {
          onDelete();
        }
        if (table.key === selectedTableKey) {
          if (isUser) {
            navigate(ROUTES.ROLES);
          } else {
            navigate(ROUTES.TABLES);
          }
        }
      }
    });
  }

  return (
    <Form
      data-testid="delete-table-form"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      <Form.Section>
        <Form.Label
          className="text-sm font-medium"
          htmlFor="delete-table-input"
          data-testid="delete-table-label"
        >
          {isUser
            ? t('components.dialogs.roles.delete.type_role_name')
            : t('components.dialogs.tables.delete.type_table_name')}
        </Form.Label>
        <div className="mt-1">
          <Input
            data-testid="delete-table-input"
            id="delete-table-input"
            type="text"
            title={t('components.dialogs.tables.table_name')}
            {...register('tableName')}
          />
        </div>
      </Form.Section>
      <div className="mt-4 flex justify-end gap-2" data-testid="delete-table-dialog-footer">
        {onCancel && (
          <Button
            onClick={() => onCancel()}
            intent="minimal"
            data-testid="delete-table-button-cancel"
          >
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          intent="destructive"
          type="submit"
          isLoading={deleteMutation.isPending}
          data-testid="delete-table-button-delete"
        >
          {t('actions.delete')}
        </Button>
      </div>
    </Form>
  );
}
