import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiChevronLeft as ChevronLeftIcon,
  HiChevronRight as ChevronRightIcon,
  HiExclamationTriangle as ExclamationTriangleIcon
} from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function FocusErrors() {
  const [t] = useTranslation();
  const draftRows = useDataTableStore().use.draftRows();
  const dataOrder = useDataTableStore().use.dataOrder();
  const selectedCell = useDataTableStore().use.selectedCell();
  const { setSelectedCell } = useDataTableStore().use.actions();
  const rowErrorList = useDataTableStore().use.rowErrorList();
  const fields = useDataTableStore().use.fields();

  const errorListByField = useMemo(
    () =>
      Object.keys(rowErrorList)
        .reduce(
          (acc, rowId2) => {
            const rowErrors = rowErrorList[rowId2];
            if (!rowErrors) return acc;

            Object.keys(rowErrors).forEach((element) => {
              acc.push({ rowId: rowId2, fieldId: element });
            });
            return acc;
          },
          [] as { rowId: string; fieldId: string }[]
        )
        .sort(
          (a, b) =>
            fields.findIndex(({ key }) => key === a.fieldId) -
            fields.findIndex(({ key }) => key === b.fieldId)
        )
        .sort((a, b) => dataOrder.indexOf(a.rowId) - dataOrder.indexOf(b.rowId)),
    [dataOrder, fields, rowErrorList]
  );

  const [currentFocusedError, setCurrentFocusedError] = useState<number>();

  useEffect(() => {
    // Focus a new error if there is no selected cell
    // Since the network calls are not ordered, this is not always selecting the first error
    if (!selectedCell && errorListByField.length > 0) {
      setSelectedCell(errorListByField[0].rowId, errorListByField[0].fieldId);
    }
    // Don't add selectedCell here, we want that the user is able to deselect cells without being automatically focused to the first error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorListByField, setSelectedCell]);

  useEffect(() => {
    if (selectedCell) {
      const draftRowsListOrderedIndex = errorListByField.findIndex(
        (element) =>
          element.rowId === selectedCell.rowId && element.fieldId === selectedCell.fieldId
      );

      setCurrentFocusedError(
        draftRowsListOrderedIndex !== -1 ? draftRowsListOrderedIndex + 1 : undefined
      );
    } else {
      setCurrentFocusedError(undefined);
    }
  }, [dataOrder, draftRows, errorListByField, selectedCell]);

  const focusChange = (changeIndex: number) => {
    setCurrentFocusedError(changeIndex);

    setSelectedCell(
      errorListByField[changeIndex - 1].rowId,
      errorListByField[changeIndex - 1].fieldId
    );
  };

  if (errorListByField.length === 0) return null;

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center gap-1">
        <Button
          intent="minimal"
          disabled={!currentFocusedError || currentFocusedError === 1}
          className="h-fit p-1"
          onClick={() => {
            if (!currentFocusedError) {
              focusChange(errorListByField.length);
              return;
            }
            focusChange(currentFocusedError - 1);
          }}
        >
          <ChevronLeftIcon size={16} />
        </Button>
        <div className="mt-0.5 flex flex-col items-center">
          <p className="flex items-center gap-1">
            <ExclamationTriangleIcon size={18} className="text-warning-default" />
            {t('components.data_table.footer.errors', {
              count: errorListByField.length
            })}
          </p>
        </div>
        <Button
          intent="minimal"
          disabled={currentFocusedError === errorListByField.length}
          className="h-fit p-1"
          onClick={(e) => {
            e.preventDefault();
            if (!currentFocusedError) {
              focusChange(1);
              return;
            }

            focusChange(currentFocusedError + 1);
          }}
        >
          <ChevronRightIcon size={16} />
        </Button>
      </div>

      {currentFocusedError && (
        <p className="h-4 text-xs text-subtle">
          {t('components.data_table.footer.x_of_total', {
            current: currentFocusedError,
            total: errorListByField.length
          })}
        </p>
      )}
    </div>
  );
}
