import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash.snakecase';

import { type ConditionalRule, type KnackField } from '@/types/schema/KnackField';
import { InlineKnackField } from '@/components/InlineKnackField';
import { ConditionalRuleActionValue } from '@/pages/data-model/diagram/ConditionalRuleActionValue';
import { ConditionalRuleCriteriaValue } from '@/pages/data-model/diagram/ConditionalRuleCriteriaValue';

interface FieldSidePanelConditionalRulesListProps {
  field: KnackField;
  tableFields: KnackField[];
}

export function FieldSidePanelConditionalRulesList({
  field,
  tableFields
}: FieldSidePanelConditionalRulesListProps) {
  const [t] = useTranslation();
  const { rules: conditionalRules } = field;

  if (!conditionalRules || conditionalRules.length === 0) {
    return <span>{t('components.data_model.fields_conditional_rules_empty')}</span>;
  }

  return conditionalRules.map((conditionalRule: ConditionalRule) => (
    <div
      key={conditionalRule.key}
      className="rounded-md border border-subtle px-2 py-1.5 shadow-sm [&:not(:last-child)]:mb-2"
      data-testid={`data-model-field-conditional-rule-${conditionalRule.key}`}
    >
      {conditionalRule.criteria.length > 0
        ? t('components.data_model.field_rule_when')
        : t('components.data_model.field_conditional_rule_run_with_every_record')}

      {conditionalRule.criteria.length > 0 &&
        conditionalRule.criteria.map((criteria, criteriaIndex) => {
          const criteriaField = tableFields.find((f) => f.key === criteria.field);
          if (!criteriaField) return null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`${criteria.field}-${criteriaIndex}`}>
              <InlineKnackField
                fieldType={criteriaField.type}
                fieldName={criteriaField.name}
                data-testid="data-model-field-conditional-rule-criteria-field"
              />{' '}
              <span data-testid="data-model-field-conditional-rule-criteria-operator">
                {t(`operators.${snakeCase(criteria.operator)}`)}
              </span>
              <ConditionalRuleCriteriaValue
                criteria={criteria}
                tableFields={tableFields}
                dataTestId="data-model-field-conditional-rule-criteria-value"
              />
              {criteriaIndex !== conditionalRule.criteria.length - 1 &&
                t('components.data_model.field_rule_and')}
            </Fragment>
          );
        })}

      {t('components.data_model.field_conditional_rule_action')}

      {conditionalRule.values.map((ruleValues, valuesIndex) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`${ruleValues.field}-${valuesIndex}`}
          data-testid="data-model-field-conditional-rule-action-value"
        >
          <ConditionalRuleActionValue
            ruleValues={ruleValues}
            field={field}
            tableFields={tableFields}
          />
        </span>
      ))}
    </div>
  ));
}
