import { useTranslation } from 'react-i18next';
import { HiMiniCheck as CheckIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { buildGradientString } from '@/pages/themes/theme-editor/utils';

const wrapperClasses = 'my-4 flex flex-wrap gap-3';

export function ColorPresetStrip({
  presetNames,
  presetColors,
  onClick,
  activeIndex
}: {
  presetNames: readonly string[];
  presetColors: readonly string[];
  onClick: (indexOrGradient: string | number) => void;
  activeIndex: number;
}) {
  const [t] = useTranslation();

  return (
    <div className={wrapperClasses}>
      {presetNames.map((preset, i) => (
        <Button
          intent="minimal"
          key={preset}
          title={preset}
          className={cn(
            `size-5 rounded-md border border-default p-0 ${presetColors[i]} hover:${presetColors[i]} active:${presetColors[i]} focus:${presetColors[i]}`,
            {
              'border-black': activeIndex === i
            }
          )}
          onClick={() => onClick(i)}
        >
          {activeIndex === i && (
            <>
              <Button.Icon icon={CheckIcon} />
              <span className="sr-only">{t('themes.selected_preset')}</span>
            </>
          )}
        </Button>
      ))}
    </div>
  );
}

export function ColorSampleStrip({
  presetColors,
  onClick,
  activeBackground,
  isInvertedCheckmark = false
}: {
  presetColors: readonly string[];
  onClick: (background: string) => void;
  activeBackground: string;
  isInvertedCheckmark?: boolean;
}) {
  const [t] = useTranslation();
  const gradientAngle = 90;

  return (
    <div
      className={cn(wrapperClasses, 'rounded-xl bg-base p-4', {
        'bg-gray-950': isInvertedCheckmark
      })}
    >
      {presetColors.map((preset, i) => {
        const isGradient = Array.isArray(presetColors[i]);
        const background = isGradient
          ? buildGradientString(presetColors[i][0], presetColors[i][1], gradientAngle)
          : buildGradientString(presetColors[i], presetColors[i], gradientAngle);
        const isActive = activeBackground === background || (!activeBackground && i === 0);
        return (
          <Button
            intent="minimal"
            key={preset}
            title={preset}
            className={cn(`size-5 rounded-md border border-default p-0`, {
              'border-gray-700 text-white hover:text-white focus:text-white active:text-white':
                isInvertedCheckmark,
              'border-black': isActive,
              'border-white': isInvertedCheckmark && isActive
            })}
            style={background ? { backgroundImage: background } : undefined}
            onClick={() => onClick(background)}
          >
            {isActive && (
              <>
                <Button.Icon icon={CheckIcon} />
                <span className="sr-only">{t('themes.selected_preset')}</span>
              </>
            )}
          </Button>
        );
      })}
    </div>
  );
}
