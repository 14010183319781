import { useState } from 'react';
import { autoUpdate, flip, size, useFloating } from '@floating-ui/react';
import { MultiSelect, Spinner } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { ConnectionValues } from '@/components/data-table/display/fields/connection/ConnectionRender';
import { InlineSingleSelect } from '@/components/data-table/display/fields/connection/InlineSingleSelect';
import { useGetConnectionRecords } from '@/components/data-table/display/fields/connection/useGetConnectionQuery';
import { type ConnectionField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ConnectionsEdit(props: FieldRenderProps<ConnectionField>) {
  const { rawValue = [], fieldId, rowId, type } = props;

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);
  const { relationship } = currentField;

  const getConnectionsQuery = useGetConnectionRecords(relationship?.object || '');

  const { saveCell, setIsEditing } = useDataTableStore().use.actions();
  const selectedCell = useDataTableStore().use.selectedCell();

  // The multiselect and single select components are controlled.
  // We need to change the key to force a re-render when the user removes an option using the chip component.
  const [selectKey, setSelectKey] = useState(0);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      // Fill the available height for big lists
      size({
        apply({ availableHeight, elements }) {
          if (parseInt(elements.floating.style.height, 10) < availableHeight) {
            elements.floating.style.height = `${availableHeight}px`;
          }
        }
      }),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });

  if (!getConnectionsQuery.data) {
    return null;
  }

  return (
    <button
      type="button"
      className="size-full"
      onClick={() => {
        setIsEditing(true);
      }}
      ref={refs.setReference}
    >
      <ConnectionValues
        rawValue={rawValue}
        onDeleteOption={(option) => {
          // Re-render the select components when the user removes an option.
          setSelectKey(Math.floor(Math.random() * 100000));
          const newRawValue = rawValue?.filter((item) => item.id !== option.id);
          void saveCell(rowId, fieldId, newRawValue, {
            value: '',
            rawValue: newRawValue
          });
        }}
      />
      {selectedCell?.isEditing && getConnectionsQuery?.isFetching && (
        <div className="absolute top-full min-w-full rounded-lg shadow-sm">
          <div className="flex flex-col items-center p-10">
            <Spinner />
          </div>
        </div>
      )}
      {relationship?.has === 'many' &&
        selectedCell?.isEditing &&
        !getConnectionsQuery.isFetching && (
          <div
            className="absolute top-full min-w-full"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <MultiSelect
              key={`multiple-select-${selectKey}`}
              id="new-menu-pages"
              shouldRenderInline
              options={
                getConnectionsQuery?.data?.records?.map((item) => ({
                  label: item.identifier.toString(),
                  key: item.id
                })) || []
              }
              selectedOptions={
                (rawValue &&
                  rawValue.map((item) => ({
                    key: item.id,
                    label: item.identifier.toString()
                  }))) ||
                []
              }
              isSearchEnabled
              contentClassName="z-50"
              onSelectOptions={(selectedOptions) => {
                const newRawValue = selectedOptions?.map((item) => ({
                  id: item.key,
                  identifier: item.label
                }));

                void saveCell(rowId, fieldId, newRawValue, {
                  value: '',
                  rawValue: newRawValue
                });
              }}
            />
          </div>
        )}
      {relationship?.has === 'one' &&
        selectedCell?.isEditing &&
        !getConnectionsQuery?.isFetching && (
          <div
            className="absolute top-full min-w-full"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            <InlineSingleSelect
              key={`single-select-${selectKey}`}
              options={
                getConnectionsQuery?.data?.records?.map((item) => ({
                  label: item.identifier.toString(),
                  value: item.id
                })) || []
              }
              defaultValue={rawValue[0]?.id}
              onChange={(selected) => {
                const selectedOption = getConnectionsQuery?.data?.records?.find(
                  (item) => item.id === selected
                );

                const newRawValue = [selectedOption];

                void saveCell(rowId, fieldId, newRawValue, {
                  value: '',
                  rawValue: newRawValue
                });
              }}
            />
          </div>
        )}
      {!selectedCell?.isEditing && (
        <CellErrors rowId={rowId} fieldId={fieldId} testIdPrefix="connection-edit-error" />
      )}
    </button>
  );
}
