import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import * as XLSX from 'xlsx';

import { type RawData } from '@/components/import/types';
import { getColumnTypes, type ColumnTypeFormat } from '@/components/import/utils';

export interface ImportedExcelPageData {
  data: RawData[];
  predictedColumnTypes: ColumnTypeFormat['type'][];
  predictedColumnFormats: ColumnTypeFormat['format'][];
  sheetName: string;
}

export interface DownloadExcelResponse {
  tablesArray: ImportedExcelPageData[];
  file: File;
}

const handleExcelFile = async (
  response: any,
  selectedTables: string[]
): Promise<DownloadExcelResponse> => {
  const tablesArray: ImportedExcelPageData[] = [];

  const workbook = XLSX.read(new Uint8Array(response.data), { type: 'array' });
  for (let i = 0; i < workbook.SheetNames.length; i += 1) {
    const sheetName = workbook.SheetNames[i];
    if (
      selectedTables.some((table) => table.trim().toLowerCase() === sheetName.trim().toLowerCase())
    ) {
      const worksheet = workbook.Sheets[sheetName];

      let data = XLSX.utils.sheet_to_json<RawData>(worksheet, {
        header: 1,
        defval: '',
        blankrows: false,
        raw: false
      });

      const unFilledData = XLSX.utils.sheet_to_json<RawData>(worksheet, {
        header: 1,
        blankrows: false,
        raw: false
      });

      const slicedData = data.slice(1);
      if (unFilledData[0].length < slicedData[0].length) {
        data.shift();
      }

      data = data.filter((row, index) => {
        if (index === 0) {
          return true;
        }
        return !row.some((item) => slicedData[0].includes(item));
      });

      const predictedColumn = getColumnTypes(data);
      let predictedColumnTypes = predictedColumn.map((column) => column.type);
      const predictedColumnFormats = predictedColumn.map((column) => column.format);

      if (predictedColumnTypes.length === 0) {
        predictedColumnTypes = Array<ColumnTypeFormat['type']>(data[0].length).fill('short_text');
      }

      tablesArray.push({ data, predictedColumnTypes, predictedColumnFormats, sheetName });
    }
  }

  if (tablesArray.length === 0) {
    throw new Error('No tables selected');
  }

  const newWorkbook = XLSX.utils.book_new();
  for (let i = 0; i < tablesArray.length; i += 1) {
    const table = tablesArray[i];
    const worksheet = XLSX.utils.json_to_sheet(table.data);
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, table.sheetName);
  }

  const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });

  const file = new File([wbout], 'data.xlsx', {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  return { tablesArray, file };
};

const downloadExcel = async (
  url: string,
  selectedExcelPages: string[]
): Promise<DownloadExcelResponse> => {
  const response = await axios.get(url, {
    responseType: 'arraybuffer'
  });

  const finalResponse = await handleExcelFile(response, selectedExcelPages);
  return finalResponse;
};

interface DownloadExcelParams {
  url: string;
  selectedExcelPages: string[];
}

export function useDownloadExcelMutation() {
  return useMutation({
    mutationFn: ({ url, selectedExcelPages }: DownloadExcelParams) =>
      downloadExcel(url, selectedExcelPages)
  });
}
