import { getIncomers, getOutgoers, type NodeChange } from 'reactflow';

import { type TableEdge } from '@/pages/data-model/diagram/TableEdgeComponent';
import { type TableNode } from '@/pages/data-model/diagram/TableNodeComponent';
import { calculateSourcePosition } from './calculateSourcePosition';
import { calculateTargetPosition } from './calculateTargetPosition';

export function calculateEdges(nodeChanges: NodeChange[], nodes: TableNode[], edges: TableEdge[]) {
  // If no node changes are passed, it means we just want to recalculate all edges from scratch
  if (!nodeChanges.length) {
    const newEdges: TableEdge[] = [];

    edges.forEach((edge) => {
      const edgeIdParts = edge.id.split('-');
      const sourceNode = nodes.find((n) => n.id === edgeIdParts[0]);
      const targetNode = nodes.find((n) => n.id === edgeIdParts[2]);

      if (!sourceNode || !targetNode) return;

      const calculationParams = [
        sourceNode.width as number,
        sourceNode.position.x as number,
        targetNode.width as number,
        targetNode.position.x
      ] as const;

      const sourcePosition = calculateSourcePosition(...calculationParams);
      const targetPosition = calculateTargetPosition(...calculationParams);

      newEdges.push({
        ...edge,
        sourceHandle: `${edge.sourceHandle?.split('-')[0]}-${sourcePosition}`,
        targetHandle: `${edge.targetHandle?.split('-')[0]}-${targetPosition}`
      });
    });

    return newEdges;
  }

  let recalculatedEdges: TableEdge[] = edges;
  nodeChanges.forEach((nodeChange) => {
    if (nodeChange.type === 'position' && nodeChange.positionAbsolute) {
      const nodeBeingChanged = nodes.find((n) => n.id === nodeChange.id);

      if (!nodeBeingChanged) {
        return;
      }

      const incomingNodes = getIncomers(nodeBeingChanged, nodes, edges);
      incomingNodes.forEach((incomingNode) => {
        // No need to recalculate the edge if it's a self-connection
        if (incomingNode.id === nodeBeingChanged.id) {
          return;
        }

        const incomingNodeEdge = edges.find(
          (e) => e.source === incomingNode.id && e.target === nodeBeingChanged.id
        );

        if (nodeChange.positionAbsolute?.x) {
          recalculatedEdges.forEach((recalculatedEdge) => {
            const newRecalculatedEdge = { ...recalculatedEdge };
            if (incomingNodeEdge?.id === recalculatedEdge.id) {
              const sourcePosition = calculateSourcePosition(
                incomingNode.width as number,
                incomingNode.position.x,
                nodeBeingChanged.width as number,
                nodeChange.positionAbsolute?.x as number
              );
              const targetPosition = calculateTargetPosition(
                incomingNode.width as number,
                incomingNode.position.x,
                nodeBeingChanged.width as number,
                nodeChange.positionAbsolute?.x as number
              );

              newRecalculatedEdge.sourceHandle = `${
                incomingNodeEdge.sourceHandle?.split('-')[0]
              }-${sourcePosition}`;
              newRecalculatedEdge.targetHandle = `${
                incomingNodeEdge.targetHandle?.split('-')[0]
              }-${targetPosition}`;
            }

            // Update the recalculatedEdges array and replace the edge that matches newRecalculatedEdge if there is one
            recalculatedEdges = recalculatedEdges.map((e) =>
              e.id === newRecalculatedEdge.id ? newRecalculatedEdge : e
            );
          });
        }
      });

      const outgoingNodes = getOutgoers(nodeBeingChanged, nodes, edges);
      outgoingNodes.forEach((outgoingNode) => {
        // No need to recalculate the edge if it's a self-connection
        if (outgoingNode.id === nodeBeingChanged.id) {
          return;
        }

        const outgoingNodeEdge = edges.find(
          (e) => e.source === nodeBeingChanged.id && e.target === outgoingNode.id
        );

        if (nodeChange.positionAbsolute?.x) {
          recalculatedEdges.forEach((recalculatedEdge) => {
            const newRecalculatedEdge = { ...recalculatedEdge };
            if (outgoingNodeEdge && recalculatedEdge.id === outgoingNodeEdge.id) {
              const sourcePosition = calculateSourcePosition(
                nodeBeingChanged.width as number,
                nodeChange.positionAbsolute?.x as number,
                outgoingNode.width as number,
                outgoingNode.position.x
              );
              const targetPosition = calculateTargetPosition(
                nodeBeingChanged.width as number,
                nodeChange.positionAbsolute?.x as number,
                outgoingNode.width as number,
                outgoingNode.position.x
              );

              newRecalculatedEdge.sourceHandle = `${
                outgoingNodeEdge.sourceHandle?.split('-')[0]
              }-${sourcePosition}`;
              newRecalculatedEdge.targetHandle = `${
                outgoingNodeEdge.targetHandle?.split('-')[0]
              }-${targetPosition}`;
            }

            // Update the recalculatedEdges array and replace the edge that matches newRecalculatedEdge if there is one
            recalculatedEdges = recalculatedEdges.map((edge) =>
              edge.id === newRecalculatedEdge.id ? newRecalculatedEdge : edge
            );
          });
        }
      });
    }
  });

  return recalculatedEdges;
}
