import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TbArrowBackUp as ArrowBack } from 'react-icons/tb';
import SignatureCanvas from 'react-signature-canvas';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react';
import { Button, Divider } from '@knack/asterisk-react';

import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CellLoading } from '@/components/data-table/display/fields/CellLoading';
import { type SignatureField } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import {
  jSignatureToSignaturePad,
  signaturePadToJSignature
} from '@/components/data-table/display/fields/signature/utils/jSignatureUtils';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import {
  SIGNATURE_CANVAS_HEIGHT,
  SIGNATURE_CANVAS_WIDTH
} from '@/components/data-table/helpers/constants';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function SignatureEdit(props: FieldRenderProps<SignatureField>) {
  const { rawValue, rowId, fieldId } = props;
  const [t] = useTranslation();

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const { saveCell } = useDataTableStore().use.actions();

  const sigCanvas = useRef<SignatureCanvas>(null);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset((state) => ({ mainAxis: -state.rects.reference.height })),
      flip({
        crossAxis: true
      })
    ],
    whileElementsMounted: autoUpdate
  });

  const decodeDataURL = (dataURL: string) => {
    const parts = dataURL.split(';base64,');
    return atob(parts[1]);
  };

  const saveSignature = async () => {
    if (!sigCanvas.current) return;

    if (sigCanvas.current.isEmpty()) {
      await saveCell(rowId, fieldId, null, { value: '', rawValue: '' });
    } else {
      const svgUrl = sigCanvas.current.toDataURL('image/svg+xml');
      const newData: SignatureField['rawValue'] = { base30: '', svg: decodeDataURL(svgUrl) };

      // We send the points in the signaturePad format to the server but we are not saving them or using them to initialize the plugin.
      newData.points = [...sigCanvas.current.toData()];

      // We are using the old jSignature format to save the points in the base30 parameter to ensure retro-compatibility.
      newData.base30 = signaturePadToJSignature(newData.points);

      await saveCell(rowId, fieldId, newData, {
        value: '',
        rawValue: newData
      });
    }
  };

  const clearSignature = async () => {
    const hasExistingSignature = !!rawValue?.base30;
    if (!sigCanvas.current?.isEmpty() || hasExistingSignature) {
      sigCanvas.current?.clear();
      await saveSignature();
    }
  };

  const undoLastStroke = () => {
    const data = sigCanvas.current?.toData();
    if (data && data.length > 0) {
      data.pop(); // remove the last dot or line
      sigCanvas.current?.fromData(data);
      void saveSignature();
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab') {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        moveSelectionVertical('down');
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (sigCanvas.current) {
      // https://github.com/szimek/signature_pad#handling-high-dpi-screens
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const canvas = sigCanvas.current.getCanvas();
      canvas.width = SIGNATURE_CANVAS_WIDTH * ratio;
      canvas.height = SIGNATURE_CANVAS_HEIGHT * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);

      // This settings ensure that the signatures created in the V3 renders almost identical in the new Builder.
      sigCanvas.current.getSignaturePad().maxWidth = 1.5;
      sigCanvas.current.getSignaturePad().minWidth = 1.5;

      if (rawValue.base30 && rawValue.base30 !== '') {
        // Convert from jSignature base30 format to signaturePad
        sigCanvas.current.fromData(jSignatureToSignaturePad(rawValue.base30));
      }
    }
  }, []);

  return (
    <>
      <div ref={refs.setReference} className="flex size-full items-center bg-base" />
      <div
        ref={refs.setFloating}
        role="button"
        style={floatingStyles}
        className="min-h-full min-w-full bg-base p-1 ring-2 ring-black"
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <CellLoading rowId={rowId} fieldId={fieldId} />
        <div className="flex h-[125px] w-[500px] flex-col items-center justify-between">
          <SignatureCanvas
            onEnd={() => {
              void saveSignature();
            }}
            onBegin={(e) => {
              // Avoid selecting text when drawing
              e.preventDefault();
            }}
            data-testid="signature-pad"
            penColor="black"
            canvasProps={{
              className: `sigCanvas h-[125px] w-[500px]`
            }}
            ref={sigCanvas}
          />
          <Divider className="mb-1" />
          <div className="flex w-full items-center justify-center gap-4">
            <Button
              data-testid="clear-signature-pad-button"
              intent="secondary"
              size="sm"
              className="rounded-md outline-offset-0 hover:border-gray-400 focus:outline-offset-[-2px]"
              onClick={clearSignature}
            >
              {t('components.data_table.attributes.field_labels.signature.reset_signature_pad')}
            </Button>
            <Button
              data-testid="undo-last-stroke-signature-pad-button"
              intent="secondary"
              size="sm"
              className="rounded-md outline-offset-0 hover:border-gray-400 focus:outline-offset-[-2px]"
              onClick={undoLastStroke}
            >
              <ArrowBack size={16} className="mr-1" />
              {t('components.data_table.attributes.field_labels.signature.undo_last_stroke')}
            </Button>
          </div>
        </div>

        <CellErrors rowId={rowId} fieldId={fieldId} />
      </div>
    </>
  );
}
