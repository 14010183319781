import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { usePageMutation } from '@/hooks/api/mutations/usePageMutation';
import { ROUTES, type PageUrlParams } from '@/Router';

interface DeleteFormProps {
  page: BuilderPage;
  onDelete?: () => void;
  onCancel?: () => void;
}

export function DeletePageForm({ page, onDelete, onCancel }: DeleteFormProps) {
  const [t] = useTranslation();
  const { deleteMutation } = usePageMutation();
  const navigate = useNavigate();
  const urlParams = useParams<PageUrlParams>();
  const { id: selectedPageKey } = urlParams;
  type FormSchema = z.infer<typeof formSchema>;

  const formSchema = z.object({
    pageName: z
      .string()
      .refine((val) => val === (page.type === 'menu' ? '' : page.name.trim().replace(/\s+/g, ' ')))
  });

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pageName: ''
    }
  });

  function onSubmit() {
    deleteMutation.mutate(page.key, {
      onSuccess: () => {
        if (onDelete) {
          onDelete();
        }
        if (page.key === selectedPageKey) {
          navigate(ROUTES.PAGES);
        }
      }
    });
  }

  return (
    <Form
      data-testid="delete-page-form"
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4"
    >
      {page.type !== 'menu' && (
        <Form.Section>
          <Form.Label
            className="text-sm font-medium"
            htmlFor="delete-page-input"
            data-testid="delete-page-label"
          >
            {t('pages.dialogs.delete.type_page_name')}
          </Form.Label>
          <Input
            data-testid="delete-page-input"
            id="delete-page-input"
            placeholder={t('pages.dialogs.delete.page_name')}
            {...register('pageName')}
          />
        </Form.Section>
      )}
      <div className="mt-4 flex justify-end gap-2" data-testid="delete-page-dialog-footer">
        {onCancel && (
          <Button onClick={onCancel} intent="minimal" data-testid="delete-page-button-cancel">
            {t('actions.cancel')}
          </Button>
        )}
        <Button
          disabled={!isValid}
          intent="destructive"
          type="submit"
          isLoading={deleteMutation.isPending}
          data-testid="delete-page-button-delete"
        >
          {t('actions.delete')}
        </Button>
      </div>
    </Form>
  );
}
