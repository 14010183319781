import { useTranslation } from 'react-i18next';

import { type ListView, type ListViewInput } from '@/types/schema/views/ListView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewDetailsFieldInputEditModal } from '@/pages/pages/settings-panel/view-settings/common/details-field-management/ViewDetailsFieldInputEditModal';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

function getListViewInput(view: ListView, viewInputId: string) {
  let listViewInput: ListViewInput | undefined;

  view.columns.forEach((outerColumn) => {
    outerColumn.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.forEach((input) => {
          if (input.type === 'field' && input.key === viewInputId) {
            listViewInput = input;
            return;
          }

          if (input.id === viewInputId) {
            listViewInput = input;
          }
        });
      });
    });
  });

  return listViewInput;
}

export function ListInputEditModal() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ListView>();
  const { isViewInputEditMode, setIsViewInputEditMode, viewInputId } =
    useViewInputEditModeContext();

  if (isViewInputEditMode && viewInputId) {
    const listInput = getListViewInput(view, viewInputId);

    if (!listInput || listInput.type === 'divider') {
      return null;
    }

    if (listInput.type === 'field') {
      return (
        <ViewDetailsFieldInputEditModal
          fieldInput={listInput}
          onOpenChange={setIsViewInputEditMode}
          title={t(
            'pages.element_settings.list.categories.data_display.field_management.edit_field_modal.title'
          )}
        />
      );
    }

    // TODO: FE-3622 Add more cases here for other types of column items (e.g. 'scene_link', 'action_link', 'delete', 'special_title')
    return null;
  }

  return null;
}
