import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { DndContext, DragOverlay, type DragEndEvent, type DragStartEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type ListView, type ListViewInput } from '@/types/schema/views/ListView';
import { useDndUtils } from '@/hooks/useDndUtils';
import { VerticalListSortableItem } from '@/components/dnd/VerticalListSortableItem';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { SortingDialogItem } from '@/pages/pages/settings-panel/view-settings/list/data-display/sorting/SortingDialogItem';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

function SortingDialogContent({
  setIsDialogOpen
}: {
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ListView>();
  const updateViewSchema = useUpdateView<ListView>();

  const { optimizedSensors, verticalListCollisionDetection } = useDndUtils();

  const [beingDraggedSummaryId, setBeingDraggedSummaryId] = useState<string | null>(null);

  const sortingSchema = z.object({
    sort: z.array(
      z.object({
        field: z.custom<KnackFieldKey>((val) => val.length, t('errors.value_required')),
        order: z.custom<'asc' | 'desc'>((val) => val.length, t('errors.value_required'))
      })
    )
  });

  type SortingSchemaType = z.infer<typeof sortingSchema>;

  const form = useForm<SortingSchemaType>({
    resolver: zodResolver(sortingSchema),
    defaultValues: {
      sort: view.source.sort || [
        { field: view.columns[0].groups[0].columns[0][0].key, order: 'asc' }
      ]
    },
    mode: 'onChange'
  });

  const {
    fields: sortingFields,
    move: moveSorting,
    append: addNewSorting,
    remove: removeSorting
  } = useFieldArray({
    control: form.control,
    name: 'sort'
  });

  const onApplySorting = (data: { sort: ListView['source']['sort'] }) => {
    updateViewSchema({
      source: {
        ...view.source,
        sort: data.sort
      }
    });

    setIsDialogOpen(false);
  };

  const handleDragStart = (event: DragStartEvent) => {
    setBeingDraggedSummaryId(event.active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!sortingFields) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = sortingFields.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = sortingFields.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      moveSorting(oldIndex, newIndex);

      setBeingDraggedSummaryId(null);
    }
  };

  const onAddSortingItem = () => {
    let firstEligibleFieldInput: ListViewInput | undefined;
    view.columns[0].groups[0].columns.forEach((innerColumn) => {
      if (firstEligibleFieldInput) {
        return;
      }
      firstEligibleFieldInput = innerColumn.find((input) => input.type === 'field' && input.key);
    });

    if (firstEligibleFieldInput) {
      addNewSorting({
        field: firstEligibleFieldInput.key,
        order: 'asc'
      });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onApplySorting)}>
        <Dialog.MainContent>
          <div className="mb-4">
            <Dialog.Title className="font-normal">{t('keywords.sorting')}</Dialog.Title>
            <Dialog.Description className="text-xs">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.sorting_description'
              )}
            </Dialog.Description>
          </div>
          <DndContext
            sensors={optimizedSensors}
            collisionDetection={verticalListCollisionDetection}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext
              items={sortingFields.map((s) => s.id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="mt-4 space-y-2">
                {sortingFields.map((sortingField, index) => (
                  <VerticalListSortableItem key={sortingField.id} id={sortingField.id}>
                    <SortingDialogItem
                      key={sortingField.id}
                      sortingFields={sortingFields}
                      sortingField={sortingField}
                      sortingIndex={index}
                      removeSorting={removeSorting}
                    />
                  </VerticalListSortableItem>
                ))}
              </div>
            </SortableContext>
            <DragOverlay>
              {sortingFields.map((sortingField, sortingIndex) => {
                if (sortingField.id !== beingDraggedSummaryId) {
                  return null;
                }

                return (
                  <SortingDialogItem
                    key={sortingField.id}
                    sortingFields={sortingFields}
                    sortingField={sortingField}
                    sortingIndex={sortingIndex}
                    removeSorting={removeSorting}
                  />
                );
              })}
            </DragOverlay>
          </DndContext>
          <Button intent="minimal" className="mt-2 gap-1" onClick={onAddSortingItem}>
            <Button.Icon icon={AddIcon} />
            {t('actions.sort')}
          </Button>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="secondary">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button intent="primary" type="submit">
            {t('actions.apply')}
          </Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function SortingDialog() {
  const [t] = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary" className="gap-1" size="sm" data-testid="sorting-dialog-trigger">
          <Button.Icon icon={EditIcon} />
          {t('keywords.sorting')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <SortingDialogContent setIsDialogOpen={setIsDialogOpen} />
      </Dialog.Content>
    </Dialog>
  );
}
