import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { Button, DropdownMenu, Input, Tooltip } from '@knack/asterisk-react';

import { isMacOS } from '@/utils/user-agent';
import { useDataModelZoom } from './helpers/useDataModelZoom';

export function DataModelZoomDropdown() {
  const [t] = useTranslation();
  const { zoomIn, zoomOut, zoomTo, zoomValue, fitView } = useDataModelZoom();

  const [customZoom, setCustomZoom] = useState(`${zoomValue}%`);

  const metaShortcut = isMacOS() ? '⌘' : 'ctrl';

  function handleSubmitZoom(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // remove the % sign and convert to number
    const customZoomNumber = Number(customZoom.replace('%', ''));

    // if the input is not a number, set the zoom to the current value
    if (Number.isNaN(customZoomNumber)) {
      setCustomZoom(`${zoomValue}%`);
      return;
    }

    zoomTo(customZoomNumber);
  }

  useEffect(() => {
    setCustomZoom(`${zoomValue}%`);
  }, [zoomValue]);

  return (
    <DropdownMenu>
      <Tooltip>
        <Tooltip.Trigger asChild>
          <DropdownMenu.Trigger
            data-testid="zoom-menu-trigger"
            className="border-none bg-transparent"
            asChild
          >
            <Button intent="minimal" className="text-base text-default aria-expanded:bg-subtle">
              <span className="mr-2 min-w-11">{zoomValue}%</span>
              <ChevronDownIcon size={14} />
            </Button>
          </DropdownMenu.Trigger>
        </Tooltip.Trigger>
        <Tooltip.Content side="bottom" className="z-20">
          {t('components.data_model.zoom_options')}
        </Tooltip.Content>
      </Tooltip>
      <DropdownMenu.Content
        className="min-w-[260px]"
        align="end"
        data-testid="zoom-menu-content"
        onFocusOutside={(e) => e.preventDefault()}
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <div className="relative mb-2 p-2">
          <form onSubmit={(e) => handleSubmitZoom(e)}>
            <Input
              data-testid="zoom-menu-input"
              value={customZoom}
              onChange={(e) => setCustomZoom(e.target.value)}
            />
          </form>
        </div>
        <DropdownMenu.Separator />
        <DropdownMenu.Item data-testid="zoom-menu-zoom-in" onSelect={() => zoomIn()}>
          {t('components.data_model.zoom_in')}
          <DropdownMenu.Shortcut>{metaShortcut}+</DropdownMenu.Shortcut>
        </DropdownMenu.Item>
        <DropdownMenu.Item data-testid="zoom-menu-zoom-out" onSelect={() => zoomOut()}>
          {t('components.data_model.zoom_out')}
          <DropdownMenu.Shortcut>{metaShortcut}-</DropdownMenu.Shortcut>
        </DropdownMenu.Item>
        <DropdownMenu.Item data-testid="zoom-menu-zoom-fit" onSelect={() => fitView()}>
          {t('components.data_model.zoom_fit')}
          <DropdownMenu.Shortcut>⇧1</DropdownMenu.Shortcut>
        </DropdownMenu.Item>
        <DropdownMenu.Item data-testid="zoom-menu-zoom-to" onSelect={() => zoomTo(50)}>
          {t('components.data_model.zoom_to')} 50%
        </DropdownMenu.Item>
        <DropdownMenu.Item onSelect={() => zoomTo(100)}>
          {t('components.data_model.zoom_to')} 100%
          <DropdownMenu.Shortcut>{metaShortcut}0</DropdownMenu.Shortcut>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
