import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineUpload as UploadIcon } from 'react-icons/hi';
import { Button, Tooltip } from '@knack/asterisk-react';

import { formatNumberForStorage } from '@/utils/formatters';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { CellLoading } from '@/components/data-table/display/fields/CellLoading';
import {
  type ImageField,
  type ImageFieldRawValueUploadFormat
} from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { ImageUploadEdit } from '@/components/data-table/display/fields/image/upload/ImageUploadEdit';
import { ImageUploadRenderEmpty } from '@/components/data-table/display/fields/image/upload/ImageUploadRenderEmpty';
import { useImageUpload } from '@/components/data-table/display/fields/image/upload/useImageUpload';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function ImageUploadRender(props: FieldRenderProps<ImageField>) {
  const { rawValue, rowId, fieldId, isFloating } = props;

  const { url, filename, thumb_url: thumbUrl, size } = rawValue as ImageFieldRawValueUploadFormat;

  const [t] = useTranslation();
  const selectedCell = useDataTableStore().use.selectedCell();
  const isCellSelected = useDataTableStore().use.isCellSelected(rowId, fieldId);
  const { setIsEditing } = useDataTableStore().use.actions();

  const { getInputProps, getRootProps, isDragActive } = useImageUpload(props);

  useEffect(
    () => () => {
      if (isFloating) {
        setIsEditing(false);
      }
    },
    [isFloating, setIsEditing]
  );

  if (!url) {
    return <ImageUploadRenderEmpty {...props} />;
  }

  if (isCellSelected && selectedCell?.isEditing && isFloating) {
    return <ImageUploadEdit {...props} />;
  }

  return (
    <div className="flex size-full cursor-pointer items-center p-1 text-sm" {...getRootProps()}>
      <button
        type="button"
        className="size-full"
        data-testid={`image-field-render-edit-button-${
          isFloating ? 'floating-' : ''
        }${rowId}-${fieldId}`}
        onClick={() => setIsEditing(true)}
      >
        <CellLoading rowId={rowId} fieldId={fieldId} />

        <Tooltip>
          <Tooltip.Trigger asChild>
            <img
              src={thumbUrl}
              alt={filename}
              className="size-full rounded-lg object-contain"
              data-testid={`image-field-render-${isFloating ? 'floating-' : ''}${rowId}-${fieldId}`}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content className="z-50">{`${filename} (${formatNumberForStorage(size)})`}</Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip>
      </button>

      {isDragActive && (
        <div className="absolute flex size-full items-center bg-base/80">
          <Button intent="secondary" className="m-0 mr-2 h-fit rounded-md p-2">
            <UploadIcon size={16} />
          </Button>

          <input {...getInputProps()} />
          <p className="grow-2 basis-5">{t('components.data_table.image_field.change_file')}</p>
        </div>
      )}
      <CellErrors rowId={rowId} fieldId={fieldId} />
    </div>
  );
}
