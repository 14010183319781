import { useTranslation } from 'react-i18next';

import { BuilderAccordion } from '@/components/BuilderAccordion';
import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';

export function LoginSettings() {
  const [t] = useTranslation();

  return (
    <CollapsiblePanel.Content title={t('pages.element_settings.login.heading')}>
      <BuilderAccordion>
        <ViewTitleDescriptionSettings />
      </BuilderAccordion>
    </CollapsiblePanel.Content>
  );
}
