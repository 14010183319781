import { type AxiosInstance } from 'axios';
import FormData from 'form-data';

import { type KnackRecord } from '@/types/schema/KnackRecord';

type UploadAssetResponse = {
  id: string;
  public_url: string;
  filename: string;
  thumb_url: string;
}[];

export const apiHelper = (axiosInstance: AxiosInstance) => ({
  newRow: async ({
    objectKey,
    queryString,
    rowValues
  }: {
    objectKey: string;
    queryString: string;
    rowValues: Record<string, unknown>;
  }) =>
    axiosInstance.post<KnackRecord>(
      `v1/objects/${objectKey}/records${queryString}`,
      {
        ...rowValues
      },
      {
        withCredentials: true
      }
    ),
  saveRow: async ({
    objectKey,
    rowId,
    queryString,
    rowValues
  }: {
    objectKey: string;
    rowId: string;
    queryString: string;
    rowValues: Record<string, unknown>;
  }) =>
    axiosInstance.put<KnackRecord>(
      `v1/objects/${objectKey}/records/${rowId}${queryString}`,
      {
        id: rowId,
        ...rowValues
      },
      {
        withCredentials: true
      }
    ),
  uploadImageAsset: async ({
    applicationId,
    fieldId,
    file
  }: {
    applicationId: string;
    fieldId: string;
    file: File;
  }) => {
    const formData = new FormData();
    formData.append('files', file);

    return axiosInstance.post<UploadAssetResponse>(
      `v1/applications/${applicationId}/assets/image/upload/stream?fieldKey=${fieldId}&filename=${file.name}&size=${file.size}&type=${file.type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      }
    );
  },
  uploadFileAsset: async ({
    applicationId,
    fieldId,
    file
  }: {
    applicationId: string;
    fieldId: string;
    file: File;
  }) => {
    const formData = new FormData();
    formData.append('files', file);

    return axiosInstance.post<UploadAssetResponse>(
      `v1/applications/${applicationId}/assets/file/upload/stream?fieldKey=${fieldId}&filename=${file.name}&size=${file.size}&type=${file.type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      }
    );
  }
});
