import {
  type BaseKnackField,
  type ValidationRule,
  type ValidationRuleCriteriaForAsset
} from '@/types/schema/KnackField';

export const IMAGE_SOURCE_OPTIONS = ['upload', 'url'] as const;
export const IMAGE_RESIZE_TYPE_OPTIONS = ['web-friendly', 'custom'] as const;
export const IMAGE_THUMB_TYPE_OPTIONS = ['pixel', 'square', 'percentage'] as const;

export type ImageThumbnail = {
  key?: string;
  thumb_type: (typeof IMAGE_THUMB_TYPE_OPTIONS)[number];
  width: number | string;
  height: number | string;
  length: number | string;
  percentage: number | string;
};

export interface ImageField extends BaseKnackField {
  type: 'image';
  format: {
    source: (typeof IMAGE_SOURCE_OPTIONS)[number];
    thumbs: ImageThumbnail[];
    resize?: {
      type: (typeof IMAGE_RESIZE_TYPE_OPTIONS)[number];
      maxHeight?: number;
      maxWidth?: number;
    };
  };
  validation: ImageFieldValidationRule[];
}

interface ImageFieldValidationRule extends ValidationRule {
  criteria: ValidationRuleCriteriaForAsset[];
}
