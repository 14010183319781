import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { type z } from 'zod';

import { cn } from '@/utils/tailwind';
import { CellErrors } from '@/components/data-table/display/fields/CellErrors';
import { getCurrencySchema } from '@/components/data-table/display/fields/currency/CurrencySchema';
import { type CurrencyField as CurrencyFieldDisplay } from '@/components/data-table/display/fields/Field';
import { type FieldRenderProps } from '@/components/data-table/display/fields/FieldRender';
import { TextareaCell } from '@/components/data-table/display/TextareaCell';
import { useSelectionStrategy } from '@/components/data-table/display/useSelectionStrategy';
import { setCursorPositionAtTheEnd } from '@/components/data-table/helpers/setCursorPositionAtTheEnd';
import { useDataTableStore } from '@/components/data-table/useDataTableStore';

export function CurrencyEdit(props: FieldRenderProps<CurrencyFieldDisplay>) {
  const { value, rawValue, fieldId, rowId, type } = props;

  const { moveSelectionVertical, moveSelectionHorizontal } = useSelectionStrategy();

  const selectedCell = useDataTableStore().use.selectedCell();
  const cellErrors = useDataTableStore().use.cellErrors(rowId, fieldId);

  const { saveCell, setIsEditing, clearCellErrors } = useDataTableStore().use.actions();

  if (!selectedCell) throw new Error('No selected cell');

  const currentField = useDataTableStore().use.getField<typeof type>(fieldId);

  const currencyFormSchema = getCurrencySchema(currentField);

  type CurrencyFormSchema = z.infer<typeof currencyFormSchema>;

  const {
    register,
    handleSubmit,
    setFocus,
    getValues,
    getFieldState,
    setValue,
    formState: { errors, isValid }
  } = useForm<CurrencyFormSchema>({
    resolver: zodResolver(currencyFormSchema),
    defaultValues: {
      currency: rawValue
    }
  });

  const handleForm = useCallback(async () => {
    const formState = getFieldState('currency');
    if (!formState.invalid) {
      await saveCell(rowId, fieldId, getValues('currency'), {
        value: getValues('currency'),
        rawValue: getValues('currency')
      });
    }
  }, [fieldId, getFieldState, getValues, rowId, saveCell]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Clean server error on change
    clearCellErrors(rowId, fieldId);
    if (e.key === 'Escape') {
      setIsEditing(false);
      const formState = getFieldState('currency');
      if (formState.invalid) {
        setValue('currency', rawValue);
      }

      e.preventDefault();
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      if (e.key === 'Tab' && isValid) {
        moveSelectionHorizontal('right');
      }
      if (e.key === 'Enter' && isValid) {
        if (!selectedCell.isEditing) {
          e.preventDefault();
          setIsEditing(true);
        } else {
          e.preventDefault();
          moveSelectionVertical('down');
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    // Hack needed to focus the textarea when the component is created
    setTimeout(() => {
      setFocus('currency');
    }, 0);
  }, [setFocus]);

  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing]
  );

  const currencyRegister = register('currency', {
    onChange: async () => {
      if (!selectedCell.isEditing) {
        setIsEditing(true);
      }

      await handleSubmit(handleForm)();
    }
  });

  return (
    <div className="flex size-full">
      <TextareaCell
        defaultValue={value}
        intent={cellErrors || errors.currency ? 'destructive' : 'default'}
        className={cn({
          'opacity-0': !selectedCell.isEditing
        })}
        onKeyDown={handleKeyDown}
        onClick={(e) => {
          if (!selectedCell.isEditing) {
            setIsEditing(true);
            setCursorPositionAtTheEnd(e);
          }
        }}
        data-testid={`edit-currency-input-${rowId}-${fieldId}`}
        onFocus={setCursorPositionAtTheEnd}
        {...currencyRegister}
      />

      <CellErrors
        rowId={rowId}
        fieldId={fieldId}
        testIdPrefix="currency-edit-error"
        additionalErrors={selectedCell.isEditing && errors?.currency ? [errors.currency] : []}
      />
    </div>
  );
}
