import { type SVGAttributes } from 'react';
import {
  HiCreditCard as CardIcon,
  HiTableCells as StandardTableIcon,
  HiUsers as UserRolesIcon
} from 'react-icons/hi2';

import { type KnackObject } from '@/types/schema/KnackObject';

interface KnackTableIconProps extends SVGAttributes<SVGElement> {
  tableType: KnackObject['type'];
  size?: number;
}

export function KnackTableIcon({ tableType, size = 16, ...svgProps }: KnackTableIconProps) {
  let TableIcon = StandardTableIcon;

  if (tableType === 'UserObject') {
    TableIcon = UserRolesIcon;
  } else if (
    tableType === 'EcommercePaymentMethodObject' ||
    tableType === 'EcommercePaymentObject'
  ) {
    TableIcon = CardIcon;
  }

  return <TableIcon size={size} {...svgProps} />;
}
