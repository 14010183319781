import { type IconType } from 'react-icons';
import { HiChevronRight as RightArrowIcon } from 'react-icons/hi2';
import { Badge, Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export type ViewSettingsCategoryItem<T = string> = {
  id: T;
  name: string;
  description: string;
  icon: IconType;
  badge?: string;
  isComingSoon?: boolean;
};

interface ViewSettingsCategoryListProps {
  categories: ViewSettingsCategoryItem[];
  onCategorySelect: (categoryId: string) => void;
}

export function ViewSettingsCategoryList({
  categories,
  onCategorySelect
}: ViewSettingsCategoryListProps) {
  return (
    <div className="space-y-2">
      {categories.map((category) => (
        <Button
          key={category.id}
          intent="secondary"
          onClick={() => onCategorySelect(category.id)}
          disabled={category.isComingSoon}
          className={cn(
            'group h-24 w-full flex-col justify-start py-4 text-left font-normal hover:border-brand-default hover:bg-brand-muted disabled:opacity-100',
            {
              'border-subtle bg-muted hover:border-subtle hover:bg-muted': category.isComingSoon
            }
          )}
        >
          <div className="flex size-full items-center justify-between gap-2">
            <div className="self-start">
              <div className="mb-3 flex items-center gap-1">
                <category.icon
                  size="18"
                  className={cn({
                    'text-subtle group-hover:fill-[url(#svg-gradient-1)]': !category.isComingSoon,
                    'text-muted group-hover:text-muted': category.isComingSoon
                  })}
                />
                <span
                  className={cn('font-medium text-default group-hover:text-emphasis', {
                    'text-muted group-hover:text-muted': category.isComingSoon
                  })}
                >
                  {category.name}
                </span>
                {category.badge && !category.isComingSoon && (
                  <span className="rounded-sm bg-subtle px-1 text-xs text-default">
                    {category.badge}
                  </span>
                )}
                {category.isComingSoon && (
                  <Badge className="px-2 py-0.5 text-xs text-default">Coming Soon</Badge>
                )}
              </div>
              <p
                className={cn('text-xs text-subtle group-hover:text-default', {
                  'text-muted group-hover:text-muted': category.isComingSoon
                })}
              >
                {category.description}
              </p>
            </div>
            <RightArrowIcon className="shrink-0 text-default group-hover:text-emphasis" size={20} />
          </div>
        </Button>
      ))}
    </div>
  );
}
