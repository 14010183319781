import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { FilterCard } from '@/components/filters/FilterCard';
import { FilterForm } from '@/components/filters/FilterForm';

function useDefaultFilterValue(connectionFieldObject: KnackObject | undefined) {
  const { getBaseFieldOperators } = useFieldHelpers();
  const { getDefaultCriteriaValue } = useCriteriaHelpers();

  if (!connectionFieldObject) {
    return undefined;
  }

  if (connectionFieldObject.fields.length === 0) {
    return undefined;
  }

  const firstFieldInObject = connectionFieldObject.fields[0];

  const defaultFilter: KnackFilter = {
    field: firstFieldInObject.key,
    operator: getBaseFieldOperators(firstFieldInObject)[0],
    value: getDefaultCriteriaValue(firstFieldInObject)
  };

  return defaultFilter;
}

interface ConnectionFiltersProps {
  connectionInputToUpdate: FormViewConnectionInput;
  setConnectionInputToUpdate: (input: FormViewConnectionInput) => void;
  connectionField: ConnectionField;
}

export function ConnectionFilters({
  connectionInputToUpdate,
  setConnectionInputToUpdate,
  connectionField
}: ConnectionFiltersProps) {
  if (!connectionField.relationship.object) {
    throw new Error('Connection field is missing a relationship object');
  }

  const [t] = useTranslation();
  const { getObjectByKey } = useObjectHelpers();
  const [isAddingFilter, setIsAddingFilter] = useState(false);

  const connectionFieldObject = getObjectByKey(connectionField.relationship.object ?? '');
  const defaultFilter = useDefaultFilterValue(connectionFieldObject);

  if (!connectionFieldObject) {
    return null;
  }

  const onFilterSave = (updatedFilter: KnackFilter, updatedFilterIndex?: number) => {
    // If the connection input doesn't have a source object, create one with an empty filters array
    if (!connectionInputToUpdate.source) {
      connectionInputToUpdate.source = {
        filters: []
      };
    }

    // If there is no updated filter index, it means we are adding a new filter
    if (updatedFilterIndex === undefined) {
      setConnectionInputToUpdate({
        ...connectionInputToUpdate,
        source: {
          ...connectionInputToUpdate.source,
          filters: [...connectionInputToUpdate.source.filters, updatedFilter]
        }
      });

      setIsAddingFilter(false);

      return;
    }

    // Otherwise, update the filter at the specified index
    setConnectionInputToUpdate({
      ...connectionInputToUpdate,
      source: {
        ...connectionInputToUpdate.source,
        filters: connectionInputToUpdate.source.filters.map((filter, index) => {
          if (index === updatedFilterIndex) {
            return updatedFilter;
          }

          return filter;
        })
      }
    });
  };

  const onFilterDelete = (filterIndex: number) => {
    setConnectionInputToUpdate({
      ...connectionInputToUpdate,
      source: {
        ...connectionInputToUpdate.source,
        filters:
          connectionInputToUpdate.source?.filters.filter((_, index) => index !== filterIndex) || []
      }
    });
  };

  return (
    <div className="mb-4">
      <span className="mb-2 block text-sm">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_filters'
        )}
      </span>
      <p className="mb-2 text-xs text-subtle">
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_filters_description'
        )}
      </p>

      {connectionInputToUpdate.source && connectionInputToUpdate.source.filters.length > 0 && (
        <div className="mb-2 space-y-2">
          {connectionInputToUpdate.source.filters.map((filter, index) => (
            <FilterCard
              // eslint-disable-next-line react/no-array-index-key
              key={`${filter.field}-${index}`}
              originObject={connectionFieldObject}
              filter={filter}
              filterIndex={index}
              onFilterSave={onFilterSave}
              onFilterDelete={onFilterDelete}
            />
          ))}
        </div>
      )}

      {isAddingFilter && defaultFilter && (
        <div className="mb-2">
          <FilterForm
            originObject={connectionFieldObject}
            filter={defaultFilter}
            onSave={onFilterSave}
            onCancel={() => setIsAddingFilter(false)}
          />
        </div>
      )}

      <Button
        intent="secondary"
        onClick={() => setIsAddingFilter(true)}
        disabled={isAddingFilter || !defaultFilter}
      >
        <PlusIcon size={16} className="mr-2" />
        {t(
          'pages.element_settings.form.categories.form_fields.field_management.edit_field_modal.field_add_filter'
        )}
      </Button>
    </div>
  );
}
