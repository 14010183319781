import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, Dialog, Label, useToast } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { getKnackFieldTypeGroupItem, type KnackFieldTypeGroupItem } from '@/utils/fields';
import { AllowedCompatiblityCard } from '@/pages/tables/fields/change-field-type/AllowedCompatibilityCard';
import { CompatibilityWarningCard } from '@/pages/tables/fields/change-field-type/CompatibilityWarningCard';
import { ConnectionRelationships } from '@/pages/tables/fields/change-field-type/ConnectionRelationships';
import { FieldTypeGroupsSelector } from '@/pages/tables/fields/change-field-type/FieldTypeGroupsSelector';
import { useFieldsStore } from '@/pages/tables/fields/useFieldsStore';

type ChangeFieldTypeDialogProps = {
  objectKey: KnackObject['key'];
  field: KnackField;
  onClose: () => void;
};

export function ChangeFieldTypeDialog({ objectKey, field, onClose }: ChangeFieldTypeDialogProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const [newFieldTypeGroupItem, setNewFieldTypeGroupItem] =
    useState<KnackFieldTypeGroupItem | null>(null);
  const [hasAcknowledgedCompatibilityWarning, setHasAcknowledgedCompatibilityWarning] =
    useState(false);

  const newConnectionFieldRelationship = useFieldsStore().use.newConnectionFieldRelationship();

  const { mutate: updateField, isPending: isChangeFieldTypeLoading } = useFieldMutation();

  const currentFieldTypeItem = getKnackFieldTypeGroupItem(field.type);

  const shouldShowCompatibilityWarning =
    (newFieldTypeGroupItem &&
      !currentFieldTypeItem?.allowedTypeConversions.includes(newFieldTypeGroupItem.type)) ||
    false;

  const handleChangeFieldType = () => {
    if (!newFieldTypeGroupItem) return;

    updateField(
      {
        tableKey: objectKey,
        updatedField: {
          ...field,
          unique: shouldShowCompatibilityWarning ? false : field.unique,
          required: shouldShowCompatibilityWarning ? false : field.required,
          type: newFieldTypeGroupItem.type,
          relationship:
            newFieldTypeGroupItem.type === 'connection' ? newConnectionFieldRelationship : undefined
        } as KnackField
      },
      {
        onSuccess: () => {
          presentToast({
            title: t(
              'pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.field_type_updated'
            )
          });
          onClose();
        },
        onError: () => {
          presentToast({
            title: t(
              'pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.update_error'
            )
          });
        }
      }
    );
  };

  if (!currentFieldTypeItem) {
    return null;
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content data-testid="change-field-type-dialog" width="lg">
        <Dialog.MainContent>
          <Dialog.Header className="mb-2">
            <Dialog.Title>
              {t('pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.title')}
            </Dialog.Title>
          </Dialog.Header>
          <div className="flex max-h-[620px]">
            <FieldTypeGroupsSelector
              field={field}
              objectKey={objectKey}
              onSelectNewFieldTypeItem={setNewFieldTypeGroupItem}
            />
            <div className="flex-1 px-4">
              <h3 className="py-2.5 font-semibold">
                <Trans
                  i18nKey={`pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.${newFieldTypeGroupItem ? 'subtitle_with_new_field_selected' : 'subtitle_without_field_selected'}`}
                  values={{
                    oldFieldName: currentFieldTypeItem.name,
                    newFieldName: newFieldTypeGroupItem && newFieldTypeGroupItem.name
                  }}
                />
              </h3>

              <div className="flex flex-col gap-2">
                {newFieldTypeGroupItem ? (
                  <>
                    {newFieldTypeGroupItem.description}
                    {!shouldShowCompatibilityWarning ? (
                      <AllowedCompatiblityCard
                        oldFieldName={currentFieldTypeItem.name}
                        newFieldName={newFieldTypeGroupItem.name}
                      />
                    ) : (
                      <>
                        <CompatibilityWarningCard
                          oldFieldName={currentFieldTypeItem.name}
                          newFieldName={newFieldTypeGroupItem.name}
                        />
                        <div className="flex items-center justify-end gap-2">
                          <Checkbox
                            checked={hasAcknowledgedCompatibilityWarning}
                            onClick={() =>
                              setHasAcknowledgedCompatibilityWarning((prevState) => !prevState)
                            }
                            id="understand-consequences-checkbox"
                          />
                          <Label htmlFor="understand-consequences-checkbox">
                            {t(
                              'pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.understand_consequences'
                            )}
                          </Label>
                        </div>
                        {newFieldTypeGroupItem.type === 'connection' && (
                          <ConnectionRelationships objectKey={objectKey} />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <span>
                    <Trans
                      i18nKey="pages.tables_page.fields_list.field_row.contextual_menu.change_field_type.select_new_type"
                      values={{
                        oldFieldType: currentFieldTypeItem.name
                      }}
                      components={[<span className="font-semibold" key="0" />]}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal" data-testid="change-field-type-cancel-button">
              {t('actions.cancel')}
            </Button>
          </Dialog.Close>
          <Button
            isLoading={isChangeFieldTypeLoading}
            onClick={handleChangeFieldType}
            data-testid="change-field-type-confirm-button"
            disabled={
              !newFieldTypeGroupItem ||
              (shouldShowCompatibilityWarning && !hasAcknowledgedCompatibilityWarning)
            }
          >
            {t('actions.save')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
