import { Outlet, useParams } from 'react-router-dom';

import { MainLayout } from '@/components/layout/MainLayout';
import { ThemesMessagingContextProvider } from '@/pages/themes/theme-editor/MessagingContext';
import { ThemesPageContextProvider } from '@/pages/themes/ThemesPageContext';
import { type PageUrlParams } from '@/Router';

export function ThemesPage() {
  const { id: themeId } = useParams<PageUrlParams>();

  return (
    <MainLayout>
      <ThemesMessagingContextProvider key={themeId}>
        <ThemesPageContextProvider key={themeId}>
          <Outlet />
        </ThemesPageContextProvider>
      </ThemesMessagingContextProvider>
    </MainLayout>
  );
}
