import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type BuilderView, type KnackViewType } from '@/types/schema/BuilderView';
import { type ReportViewChart } from '@/types/schema/views/ReportView';
import { type PageDataSource } from '@/pages/pages/page-editor/add-view/helpers/usePageDataSources';
import {
  useViewSources,
  type ViewSource,
  type ViewSourcePath
} from '@/pages/pages/page-editor/add-view/helpers/useViewSources';
import { getNewViewSchema } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/viewSchemas';

interface AddRecordViewFlowContextProviderProps {
  page: BuilderPage;
  pageDataSources: PageDataSource[];
  selectedViewType: KnackViewType;
  // We can add more subtypes in the future
  selectedViewSubType?: ReportViewChart['type'];
  handleAddView: (viewSchema: Partial<BuilderView>) => void;
  onBack: () => void;
  children: React.ReactNode;
}

type AddRecordViewFlowContextState = {
  currentStep: AddRecordViewFlowSteps;
  viewSources: ViewSource[];
  viewSourcesForFormView?: {
    adding: ViewSource[];
    updating: ViewSource[];
  };
  selectedViewType: KnackViewType;
  selectedViewSubType?: ReportViewChart['type'];
  selectedViewSource: ViewSource | undefined;
  setSelectedViewSource: (viewSource: ViewSource | undefined) => void;
  selectedViewSourcePath: ViewSourcePath | undefined;
  setSelectedViewSourcePath: (viewSourcePath: ViewSourcePath | undefined) => void;
  selectedViewSourcePathDirectConnectionFieldKey: string | undefined;
  setSelectedViewSourcePathDirectConnectionFieldKey: (fieldKey: string | undefined) => void;
  selectedViewSourcePathParentConnectionFieldKey: string | undefined;
  setSelectedViewSourcePathParentConnectionFieldKey: (fieldKey: string | undefined) => void;
  selectedFormRecordAction: 'add' | 'update';
  setSelectedFormRecordAction: (formRecordAction: 'add' | 'update') => void;
  handleAddView: (viewSchema: Partial<BuilderView>) => void;
  onBack: () => void;
  onContinue: () => void;
} | null;

const AddRecordViewFlowContext = createContext<AddRecordViewFlowContextState>(null);

export enum AddRecordViewFlowSteps {
  'selectViewSource',
  'selectViewSourcePath',
  'confirm'
}

export function AddRecordViewFlowContextProvider({
  page,
  pageDataSources,
  selectedViewType,
  selectedViewSubType,
  handleAddView,
  onBack,
  children
}: AddRecordViewFlowContextProviderProps) {
  const { getViewSources } = useViewSources();

  const [currentStep, setCurrentStep] = useState<AddRecordViewFlowSteps>(
    AddRecordViewFlowSteps.selectViewSource
  );

  const [selectedViewSource, setSelectedViewSource] = useState<ViewSource>();
  const [selectedViewSourcePath, setSelectedViewSourcePath] = useState<ViewSourcePath>();
  const [
    selectedViewSourcePathDirectConnectionFieldKey,
    setSelectedViewSourcePathDirectConnectionFieldKey
  ] = useState<string>();
  const [
    selectedViewSourcePathParentConnectionFieldKey,
    setSelectedViewSourcePathParentConnectionFieldKey
  ] = useState<string>();

  // If the selected view type is a 'form', we need to keep track of the form record action ('add' or 'update') selected by the user in the flow, so the selection is retained when the user navigates back and forth between steps
  const [selectedFormRecordAction, setSelectedFormRecordAction] = useState<'add' | 'update'>('add');

  const viewSources = useMemo(
    () => getViewSources({ pageDataSources, viewType: selectedViewType }),
    [getViewSources, selectedViewType, pageDataSources]
  );

  // If the selected view type is a 'form', we need to get the view sources for both record actions (add or update)
  const viewSourcesForFormView = useMemo(() => {
    if (selectedViewType !== 'form') return undefined;

    // By default, the view sources for 'Form' views only include sources that can display 'many' records (for adding records), so we need to retrieve the sources for 'one' (for updating records)
    return {
      adding: viewSources,
      updating: getViewSources({ pageDataSources, viewType: 'form', formRecordAction: 'update' })
    };
  }, [selectedViewType, getViewSources, pageDataSources, viewSources]);

  const onBackClick = useCallback(() => {
    if (currentStep === AddRecordViewFlowSteps.selectViewSource) {
      onBack();
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  }, [currentStep, onBack]);

  const onContinueClick = useCallback(() => {
    if (currentStep !== AddRecordViewFlowSteps.confirm) {
      setCurrentStep((prevStep) => prevStep + 1);
      return;
    }

    if (!selectedViewSource || !selectedViewSourcePath) return;

    const viewSchema = getNewViewSchema({
      page,
      viewType: selectedViewType,
      viewSubtype: selectedViewSubType,
      viewSource: selectedViewSource,
      viewSourcePath: selectedViewSourcePath,
      viewSourcePathDirectConnectionFieldKey: selectedViewSourcePathDirectConnectionFieldKey,
      viewSourcePathParentConnectionFieldKey: selectedViewSourcePathParentConnectionFieldKey
    });

    handleAddView(viewSchema);
  }, [
    page,
    currentStep,
    handleAddView,
    selectedViewSource,
    selectedViewSourcePath,
    selectedViewType,
    selectedViewSubType,
    selectedViewSourcePathDirectConnectionFieldKey,
    selectedViewSourcePathParentConnectionFieldKey
  ]);

  const contextValue = useMemo(
    () => ({
      currentStep,
      viewSources,
      viewSourcesForFormView,
      selectedViewType,
      selectedViewSubType,
      selectedViewSource,
      setSelectedViewSource,
      selectedViewSourcePath,
      setSelectedViewSourcePath,
      selectedViewSourcePathDirectConnectionFieldKey,
      setSelectedViewSourcePathDirectConnectionFieldKey,
      selectedViewSourcePathParentConnectionFieldKey,
      setSelectedViewSourcePathParentConnectionFieldKey,
      selectedFormRecordAction,
      setSelectedFormRecordAction,
      onBack: onBackClick,
      onContinue: onContinueClick,
      handleAddView
    }),
    [
      currentStep,
      viewSources,
      viewSourcesForFormView,
      selectedViewType,
      selectedViewSubType,
      selectedViewSource,
      selectedViewSourcePath,
      selectedViewSourcePathDirectConnectionFieldKey,
      selectedViewSourcePathParentConnectionFieldKey,
      selectedFormRecordAction,
      onBackClick,
      onContinueClick,
      handleAddView
    ]
  );

  return (
    <AddRecordViewFlowContext.Provider value={contextValue}>
      {children}
    </AddRecordViewFlowContext.Provider>
  );
}

export const useAddRecordViewFlowContext = () => {
  const context = useContext(AddRecordViewFlowContext);
  if (!context) {
    throw new Error(
      'useAddRecordViewFlowContext must be used within a AddRecordViewFlowContextProvider'
    );
  }
  return context;
};
