import { useToast } from '@knack/asterisk-react';

import { getErrorMessages, type ErrorResponseProps } from '@/utils/errors';

export function useErrorToast() {
  const { presentToast } = useToast();

  return ({ error, fallbackKey, translationPrefix, i18nOptions }: ErrorResponseProps) => {
    const errorMessages = getErrorMessages({
      error,
      fallbackKey,
      translationPrefix,
      i18nOptions
    });

    if (errorMessages) {
      errorMessages.forEach((message) => {
        presentToast({
          title: message,
          intent: 'destructive'
        });
      });
    }
  };
}
