import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Spinner } from '@knack/asterisk-react';

import { useResetPasswordMutation } from '@/hooks/api/mutations/useResetPasswordMutation';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { ApiErrorBanner } from '@/components/errors/ApiErrorBanner';

type ResetPasswordSubPageParams = {
  id: string;
  rowId: string;
};

export function ResetPassword({ tableKey, rowId }: { tableKey: string; rowId: string }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { data: allPages, isLoading } = usePagesQuery();
  const { mutate: resetPassword, isSuccess, isPending, error } = useResetPasswordMutation();

  if (isLoading) {
    return null;
  }

  const shouldShowWarning = !allPages?.some((page) => page.type === 'authentication');

  const handleResetPassword = () => {
    resetPassword({ tableKey, ids: [rowId] });
  };

  if (isSuccess) {
    return (
      <>
        <Dialog.MainContent>
          <Dialog.Header className="mb-4">
            <Dialog.Title>
              {t(`components.data_table.row_actions.reset_password_modal.title`)}
            </Dialog.Title>
            <p>{t(`components.data_table.row_actions.reset_password_modal.success`)}</p>
          </Dialog.Header>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('actions.continue')}
          </Button>
        </Dialog.Footer>
      </>
    );
  }

  if (isPending) {
    return (
      <div className="flex items-center justify-center p-8">
        <Spinner />
      </div>
    );
  }

  if (shouldShowWarning) {
    return (
      <>
        {error && (
          <ApiErrorBanner className="mb-4" error={error} fallbackKey="errors.generic_error" />
        )}
        <Dialog.MainContent>
          <Dialog.Header className="mb-4">
            <Dialog.Title>
              {t(`components.data_table.row_actions.reset_password_modal.title`)}
            </Dialog.Title>
          </Dialog.Header>
          <Trans i18nKey="warning">
            In order for recipients to receive reset password emails, a page with a login view needs
            to be implemented. Learn more about logins and registrations
            <a
              className="ml-1 underline"
              href="https://learn.knack.com/article/iqyr40dmbz-logins-and-registrations"
            >
              here
            </a>
          </Trans>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('actions.continue')}
          </Button>
        </Dialog.Footer>
      </>
    );
  }

  return (
    <>
      <Dialog.MainContent>
        <Dialog.Header className="mb-4">
          <Dialog.Title>
            {t(`components.data_table.row_actions.reset_password_modal.title`)}
          </Dialog.Title>
          <p>{t(`components.data_table.row_actions.reset_password_modal.confirm`)}</p>
          <p>
            <Trans i18nKey="components.data_table.row_actions.reset_password_modal.confirm_2">
              Their current password will be <strong>deleted</strong> and an email will be sent with
              a link for the user to reset the password.
            </Trans>
          </p>
        </Dialog.Header>
      </Dialog.MainContent>
      <Dialog.Footer>
        <Button
          intent="minimal"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('actions.cancel')}
        </Button>
        <Button onClick={() => handleResetPassword()}>
          {t('components.data_table.row_actions.reset_password_modal.send_emails')}
        </Button>
      </Dialog.Footer>
    </>
  );
}

export function ResetPasswordSubPage() {
  const { id, rowId } = useParams<ResetPasswordSubPageParams>();
  const navigate = useNavigate();

  if (!id || !rowId) {
    throw new Error('ResetPassword parameters are wrong');
  }

  return (
    <Dialog
      open
      onOpenChange={() => {
        navigate(-1);
      }}
    >
      <Dialog.Content>
        <ResetPassword tableKey={id} rowId={rowId} />
      </Dialog.Content>
    </Dialog>
  );
}
