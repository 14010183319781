import { useTranslation } from 'react-i18next';
import { Dialog } from '@knack/asterisk-react';

import { FieldSettings, type NewFieldState } from '@/components/field-settings/FieldSettings';

type AddFieldDialogProps = {
  objectKey: string;
  newFieldState: NewFieldState;
  onClose: () => void;
};

const DEFAULT_FIELD_TYPE = 'short_text';

export function AddFieldDialog({ objectKey, newFieldState, onClose }: AddFieldDialogProps) {
  const [t] = useTranslation();

  return (
    <Dialog open onOpenChange={onClose}>
      <Dialog.Content data-testid="add-field-dialog">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>{t('components.data_table.contextual_menu.add_field')}</Dialog.Title>
          </Dialog.Header>
          <FieldSettings
            objectKey={objectKey}
            initialType={DEFAULT_FIELD_TYPE}
            onCancel={onClose}
            onSave={onClose}
            newFieldState={newFieldState}
          />
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}
