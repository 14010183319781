import { t } from 'i18next';
import { z } from 'zod';

import { type RichTextField } from '@/types/schema/fields';

export function getRichTextSchema(field: RichTextField) {
  const { required } = field;
  return z.object({
    richText: z
      .string()
      .optional()
      .or(z.literal(''))
      .refine((text) => !required || text, t('components.data_table.errors.required_field'))
  });
}
