import { DateTime } from 'luxon';

import { type DateFormatValue, type DateTimeFieldFormat } from '@/types/schema/fields';
import {
  formatTime,
  getTimeFormat
} from '@/components/data-table/display/fields/date-time/formatters/timeFormatter';
import {
  type DateTimeAttributes,
  type DateTimeField
} from '@/components/data-table/display/fields/Field';

export function mapDateFormatFromKnackSchema(dateFormat?: DateFormatValue) {
  switch (dateFormat) {
    case 'Ignore Date':
      return undefined;

    case 'dd/mm/yyyy':
      return 'dd/MM/yyyy';

    case 'M D, yyyy':
      return 'MMMM dd, yyyy';

    case 'mm/dd/yyyy':
      return 'MM/dd/yyyy';

    default:
      return 'MM/dd/yyyy';
  }
}

export function formatDate(date: string, format?: DateFormatValue) {
  if (!date || format === 'Ignore Date') return null;

  return DateTime.fromISO(date, {
    // IMPORTANT: We don't take into consideration the user Timezone and force it to UTC.
    // The timezones were not handled by the server/frontend, now the frontend use the iso_timestamp and send it back to the server.
    // This way if in the future the server could save the iso_timestamp we send and remove any forced timezone we do in the code.
    zone: 'utc'
  }).toFormat(mapDateFormatFromKnackSchema(format) || 'MM/dd/yyyy');
}

// This function was picked up from the server, typed, removed moment and simplified
// https://github.com/knackhq/Server/blob/2b83776119bcf01f20a6a3651ce046be9128a4f5/app/lib/formatter.js#L505-L506
export function formatDateTimeRawValue(
  val: DateTimeField['rawValue'],
  format: DateTimeFieldFormat
) {
  if (!val || !val.iso_timestamp || (!val.date && !val.time)) {
    return '';
  }

  let result = '';

  const date = formatDate(val.iso_timestamp, format.date_format);
  if (format.date_format !== 'Ignore Date') {
    result += date;
  }

  if (!val.all_day && format.time_format && format.time_format !== 'Ignore Time') {
    const time = formatTime(val.iso_timestamp, format.time_format);
    result += ` ${time}`;
  }

  if (val.to && val.to.iso_timestamp) {
    const dateTo = formatDate(val.to.iso_timestamp, format.date_format);

    if (date !== dateTo) {
      result += ` to ${dateTo}`;
    }

    if (!val.to.all_day && val.to.hours && format.time_format) {
      const time = formatTime(val.to.iso_timestamp, format.time_format);
      if (date === dateTo) {
        result += ` to`;
      }
      result += ` ${time}`;
    }
  }

  return result;
}

export const getDateFormat = (format?: DateFormatValue) =>
  format === 'dd/mm/yyyy' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

export const getFormattedDateForInput = (dateTimeUTC: string, format?: DateFormatValue) =>
  DateTime.fromISO(dateTimeUTC, { zone: 'utc' }).toFormat(getDateFormat(format) || 'MM/dd/yyyy');

export const getUTCFromRawValue = (rawValue: DateTimeAttributes, field: DateTimeFieldFormat) => {
  const dateUnion: string[] = [];
  const dateFormatUnion: string[] = [];

  if (field.date_format !== 'Ignore Date' && rawValue.date) {
    dateUnion.push(rawValue.date);
    dateFormatUnion.push(getDateFormat(field.date_format));
  }

  if (field.time_format !== 'Ignore Time') {
    dateUnion.push(`${rawValue.hours || '12'}:${rawValue.minutes || '00'}`);
    dateFormatUnion.push(getTimeFormat(field.time_format));

    if (field.time_format === 'HH:MM am') {
      dateUnion.push(rawValue.am_pm || 'am');
      dateFormatUnion.push('a');
    }
  }

  // We add the timezone to +0. This way the parser doesn't convert the date to the client timezone.
  dateUnion.push('+00:00');
  dateFormatUnion.push('ZZ');

  return DateTime.fromFormat(dateUnion.join(' '), dateFormatUnion.join(' '), {
    zone: 'utc'
  });
};
