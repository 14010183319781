import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type ImageThumbnail } from '@/types/schema/fields';

type ThumbnailsImageProps = {
  thumb: ImageThumbnail;
  index: number;
};

export function ThumbnailsInput({ thumb, index }: ThumbnailsImageProps) {
  const [t] = useTranslation();

  const { setValue, getValues } = useFormContext();

  return (
    <>
      {thumb.thumb_type === 'pixel' && (
        <div className="flex-col">
          <Label htmlFor="width-input">
            {t('components.data_table.attributes.field_settings.image.thumbnail_width_label')}
          </Label>
          <Input
            type="number"
            className="mb-2 mt-1 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            data-testid="thumbnail-width-input"
            id="width-input"
            value={thumb.width}
            onChange={(e) => {
              const updatedThumbs = getValues('format.thumbs');
              updatedThumbs[index].width = e.target.value;
              setValue('format.thumbs', updatedThumbs);
            }}
          />

          <Label htmlFor="height-input">
            {t('components.data_table.attributes.field_settings.image.thumbnail_height_label')}
          </Label>
          <Input
            type="number"
            className="mt-1 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            data-testid="thumbnail-height-input"
            id="height-input"
            value={thumb.height}
            onChange={(e) => {
              const updatedThumbs = getValues('format.thumbs');
              updatedThumbs[index].height = e.target.value;
              setValue('format.thumbs', updatedThumbs);
            }}
          />
        </div>
      )}

      {thumb.thumb_type === 'percentage' && (
        <div className="flex-col">
          <Label htmlFor="percentage-input">
            {t('components.data_table.attributes.field_settings.image.thumbnail_percentage_label')}
          </Label>
          <Input
            type="number"
            className="mt-1 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            id="percentage-input"
            value={thumb.percentage}
            data-testid="thumbnail-percentage-input"
            onChange={(e) => {
              const updatedThumbs = getValues('format.thumbs');
              updatedThumbs[index].percentage = e.target.value;
              setValue('format.thumbs', updatedThumbs);
            }}
          />
        </div>
      )}

      {thumb.thumb_type === 'square' && (
        <div className="flex-col">
          <Label htmlFor="side-length-input">
            {t('components.data_table.attributes.field_settings.image.thumbnail_side_length_label')}
          </Label>
          <Input
            type="number"
            className="mt-1 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            data-testid="thumbnail-side-length-input"
            id="side-length-input"
            value={thumb.length}
            onChange={(e) => {
              const updatedThumbs = getValues('format.thumbs');
              updatedThumbs[index].length = e.target.value;
              setValue('format.thumbs', updatedThumbs);
            }}
          />
        </div>
      )}
    </>
  );
}
