import { Controller, FormProvider, useForm, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, Form, Input, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';
import { z } from 'zod';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useFieldHelpers } from '@/hooks/helpers/useFieldHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { FieldIcon } from '@/components/FieldIcon';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { DateTimeCriteriaInput } from '@/components/inputs/date-time/DateTimeCriteriaInput';
import { DateTimeRangeInput } from '@/components/inputs/date-time/DateTimeRangeInput';
import { ConnectionFieldInput } from './ConnectionFieldInput';
import { MultipleChoiceFieldInput } from './MultipleChoiceFieldInput';

interface FilterFormProps {
  originObject: KnackObject;
  filter: KnackFilter;
  onCancel: () => void;
  onSave: (filter: KnackFilter) => void;
}

export function FilterForm({ originObject, filter, onCancel, onSave }: FilterFormProps) {
  const [t] = useTranslation();

  const { getBaseFieldOperators } = useFieldHelpers();
  const {
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator,
    isCriteriaValueEmpty
  } = useCriteriaHelpers();

  const filterFormSchema = z.custom<KnackFilter>().superRefine((data, ctx) => {
    const isValueRequired =
      !shouldHideValueBasedOnOperator(data.operator) && !isDateTimeRangeOperator(data.operator);

    if (!isValueRequired) {
      return;
    }

    const selectedField = originObject.fields.find((field) => field.key === data.field);

    if (!selectedField) {
      return;
    }

    const isValueEmpty = isCriteriaValueEmpty(data.value, selectedField);

    // Only show a validation error on the value if the operator requires a value, and the value is missing
    if (isValueEmpty) {
      ctx.addIssue({
        path: ['value'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }
  });

  const form = useForm<KnackFilter>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: filter
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = form;

  const [selectedFieldKey, selectedOperator] = watch(['field', 'operator']);
  const selectedField = originObject.fields.find((field) => field.key === selectedFieldKey);
  const fieldOperators = selectedField ? getBaseFieldOperators(selectedField) : [];

  const onSubmit = (updatedFilter: KnackFilter) => {
    onSave(updatedFilter);
  };

  const onFieldChange = (
    newFieldKey: KnackFieldKey,
    formOnChange: ControllerRenderProps['onChange']
  ) => {
    const newSelectedField = originObject.fields.find((field) => field.key === newFieldKey);

    if (!newSelectedField || !selectedField) {
      return;
    }

    if (shouldResetCriteriaValue(newSelectedField, selectedField)) {
      setValue('value', getDefaultCriteriaValue(newSelectedField));
    }

    if (shouldResetCriteriaOperator(newSelectedField, selectedOperator)) {
      setValue('operator', getDefaultCriteriaOperator(newSelectedField));
    }

    clearFormErrors();
    formOnChange(newFieldKey);
  };

  const renderFieldInput = () => {
    if (!selectedField || shouldHideValueBasedOnOperator(selectedOperator)) {
      return null;
    }

    if (selectedField.type === 'connection') {
      return <ConnectionFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    if (selectedField.type === 'date_time') {
      if (isDateTimeRangeOperator(selectedOperator)) {
        return (
          <DateTimeRangeInput<KnackFilter>
            rangeTypeFormFieldName="type"
            rangeValueFormFieldName="range"
            operator={selectedOperator}
          />
        );
      }
      return <DateTimeCriteriaInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    if (selectedField.type === 'multiple_choice') {
      return <MultipleChoiceFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    if (selectedField.type === 'boolean') {
      return <BooleanFieldInput<KnackFilter> field={selectedField} formFieldName="value" />;
    }

    return (
      <Input
        className="text-sm"
        intent={errors.value ? 'destructive' : undefined}
        placeholder={t('actions.enter_value')}
        {...register('value')}
      />
    );
  };

  return (
    <Card className="bg-muted p-2 text-sm shadow-none sm:p-2">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Section className="mb-2 flex gap-2">
            <div className="flex-1">
              <Controller
                control={control}
                name="field"
                render={({ field: { value: fieldKey, onChange } }) => (
                  <Select
                    value={fieldKey}
                    onValueChange={(newFieldKey: KnackFieldKey) =>
                      onFieldChange(newFieldKey, onChange)
                    }
                  >
                    <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                    <Select.Content>
                      {originObject.fields.map((field) => (
                        <Select.Item key={field.key} value={field.key}>
                          <span className="flex items-center">
                            <FieldIcon
                              className="mr-2 shrink-0 text-subtle"
                              size={16}
                              name={field.type}
                            />
                            {field.name}
                          </span>
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name="operator"
                render={({ field: { value: operator, onChange } }) => (
                  <Select
                    value={operator}
                    onValueChange={(newOperator) => {
                      clearFormErrors();
                      onChange(newOperator);
                    }}
                  >
                    <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                    <Select.Content>
                      {fieldOperators.map((fieldOperator: string) => (
                        <Select.Item key={fieldOperator} value={fieldOperator}>
                          {t(`operators.${snakeCase(fieldOperator)}`)}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </div>
          </Form.Section>

          <Form.Section className="mb-2">
            {renderFieldInput()}
            {errors.value && (
              <p className="mt-1 text-sm text-destructive">{errors.value.message}</p>
            )}
          </Form.Section>

          <Form.Section className="flex justify-end gap-2">
            <Button intent="secondary" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
            <Button type="submit">{t('actions.save')}</Button>
          </Form.Section>
        </form>
      </FormProvider>
    </Card>
  );
}
