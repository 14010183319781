import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/utils/axiosConfig';

type DeleteRecordsResponse = {
  success: boolean;
};

type DeleteRecordsParams = {
  objectKey: string;
  deleteIds: string[];
};

async function deleteRecords({ objectKey, deleteIds }: DeleteRecordsParams) {
  const { data } = await axiosInstance.post<DeleteRecordsResponse>(
    `/v1/objects/${objectKey}/records/delete`,
    {
      ids: deleteIds
    },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useDeleteRecordsMutation() {
  return useMutation({
    mutationFn: (params: DeleteRecordsParams) => deleteRecords(params)
  });
}
