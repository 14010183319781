import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { useLogoutUserMutation } from '@/hooks/api/mutations/useLogoutUserMutation';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { safeLogRocketTrack } from '@/utils/logrocket';
import { PageError } from '@/components/errors/PageError';

export function AppNotFound() {
  const [t, { language }] = useTranslation();

  const { data: session } = useSessionQuery();
  const logout = useLogoutUserMutation();

  const handleSignInClick = () => logout.mutate();

  const handleDashboardLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    safeLogRocketTrack('NavigateToDashboard', session?.account?.settings?.hipaa?.enabled);

    // Redirect to the dashboard
    window.location.href = import.meta.env.PUBLIC_DASHBOARD_URL;
  };

  return (
    <>
      <PageError title={t('components.app_not_found.title')} message={t('errors.verify_url')}>
        <div className="flex gap-4">
          <Button
            intent="secondary"
            onClick={handleSignInClick}
            data-testid="app-not-found-sign-in-button"
          >
            {t('components.app_not_found.sign_in')}
          </Button>
          <Button asChild data-testid="app-not-found-go-to-dashboard-button">
            <a href={import.meta.env.PUBLIC_DASHBOARD_URL} onClick={handleDashboardLinkClick}>
              {t('navigation.go_to_dashboard')}
            </a>
          </Button>
        </div>
      </PageError>
      <Helmet>
        <title lang={language}>{t('components.app_not_found.title')}</title>
      </Helmet>
    </>
  );
}
