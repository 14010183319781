enum NameTitles {
  None = 'None',
  Ms = 'Ms.',
  Miss = 'Miss',
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Dr = 'Dr.',
  Prof = 'Prof.'
}

export const TITLES = (Object.keys(NameTitles) as Array<keyof typeof NameTitles>).map(
  (title) => NameTitles[title]
);

export const DEFAULT_FORMAT_VALUE = 'First Last';
