import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  MiniMap,
  SelectionMode,
  useNodesInitialized,
  useReactFlow,
  type EdgeTypes,
  type NodeTypes
} from 'reactflow';

import 'reactflow/dist/base.css';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useDataModelDiagramQuery } from '@/hooks/api/queries/useDataModelDiagramQuery';
import { calculateInitialEdges } from '@/pages/data-model/helpers/calculateInitialEdges';
import { calculateInitialNodes } from '@/pages/data-model/helpers/calculateInitialNodes';
import { useDataModelStore } from '@/pages/data-model/helpers/useDataModelStore';
import { useDataModelViewOptionsStore } from '@/pages/data-model/helpers/useDataModelViewOptionsStore';
import { useElkAutoLayout } from '@/pages/data-model/helpers/useElkAutoLayout';
import { DataModelEmptyState } from './DataModelEmptyState';
import { DataModelSidePanel } from './DataModelSidePanel';
import { EdgeMarkers } from './EdgeMarkers';
import { LoadingSkeleton } from './LoadingSkeleton';
import { TableConnectionLine } from './TableConnectionLine';
import { defaultEdgeOptions, TableEdgeComponent, tableEdgeTypeName } from './TableEdgeComponent';
import { TableNodeComponent, tableNodeTypeName } from './TableNodeComponent';

export function DataModelDiagram() {
  const { data: application } = useApplicationQuery();
  const { data: savedDiagram, isLoading: isLoadingSavedDiagram } = useDataModelDiagramQuery();
  const knackObjects = application?.objects;
  const objectRecordCounts = application?.counts.objects;
  const isEmptyApp = knackObjects?.length === 0;

  const dataModelContainerRef = useRef<HTMLDivElement>(null);

  const [isLoadingDiagram, setIsLoadingDiagram] = useState(true);
  const nodesInitialized = useNodesInitialized();
  const { fitView } = useReactFlow();
  const { isLoading: isElkLoading, getAutoLayoutNodes } = useElkAutoLayout();

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onSelectionChange,
    resetLayout,
    recalculateEdges,
    setInitialData
  } = useDataModelStore((state) => ({
    nodes: state.nodes,
    edges: state.edges,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    onSelectionChange: state.onSelectionChange,
    resetLayout: state.resetLayout,
    recalculateEdges: state.recalculateEdges,
    setInitialData: state.setInitialData
  }));
  const { isMultiSelect, showGrid } = useDataModelViewOptionsStore((state) => ({
    isMultiSelect: state.isMultiSelect,
    showGrid: state.showGrid
  }));

  const nodeTypes: NodeTypes = useMemo(() => ({ [tableNodeTypeName]: TableNodeComponent }), []);
  const edgeTypes: EdgeTypes = useMemo(() => ({ [tableEdgeTypeName]: TableEdgeComponent }), []);

  const setAutoLayout = useCallback(async () => {
    if (!getAutoLayoutNodes) return;

    const newNodes = await getAutoLayoutNodes(nodes, edges);
    resetLayout(newNodes);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges, fitView, getAutoLayoutNodes, resetLayout]);

  // Set the initial nodes and edges
  useEffect(() => {
    if (isLoadingSavedDiagram) return;

    if (savedDiagram) {
      setInitialData(
        calculateInitialNodes(knackObjects, objectRecordCounts, savedDiagram),
        calculateInitialEdges(knackObjects)
      );
    } else {
      setInitialData(
        calculateInitialNodes(knackObjects, objectRecordCounts),
        calculateInitialEdges(knackObjects)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSavedDiagram]);

  // Perform necessary layout logic if the nodes are initialized
  useEffect(() => {
    if (!nodesInitialized) {
      if (isEmptyApp) {
        setIsLoadingDiagram(false);
      }
      return;
    }

    if (isElkLoading || isLoadingSavedDiagram) {
      return;
    }

    if (savedDiagram) {
      recalculateEdges();
      setIsLoadingDiagram(false);
      return;
    }

    void (async () => {
      await setAutoLayout();
      setIsLoadingDiagram(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesInitialized, isElkLoading, isLoadingSavedDiagram]);

  return (
    <div className="relative h-full w-full overflow-hidden" ref={dataModelContainerRef}>
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onSelectionChange={onSelectionChange}
        connectionLineComponent={TableConnectionLine}
        panOnDrag={!isMultiSelect}
        selectionOnDrag={isMultiSelect}
        selectionMode={SelectionMode.Partial}
        defaultEdgeOptions={defaultEdgeOptions}
        elevateEdgesOnSelect
        fitViewOptions={{ padding: 0.2 }}
        fitView
        minZoom={0.1}
        zoomOnDoubleClick={false}
        proOptions={{
          hideAttribution: true
        }}
      >
        <Background
          variant={BackgroundVariant.Dots}
          gap={25}
          size={3}
          color="#E0DCDF"
          className={!showGrid ? 'hidden' : undefined}
        />
        {!isLoadingDiagram && !isEmptyApp && <MiniMap ariaLabel="Data Model Minimap" />}
        {!isLoadingDiagram && isEmptyApp && <DataModelEmptyState />}
        {isLoadingDiagram && <LoadingSkeleton />}
      </ReactFlow>
      <DataModelSidePanel containerRef={dataModelContainerRef} />
      <EdgeMarkers />
    </div>
  );
}
