import * as React from "react";
const SvgViewList = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 60, height: 60, viewBox: "0 0 60 60", ...props }, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", transform: "translate(0 6)" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", style: {
  fill: "var(--background-color)"
}, d: "M3,47 L57,47 C58.1,47 59,46.1 59,45 L59,3 C59,1.9 58.1,1 57,1 L3,1 C1.9,1 1,1.9 1,3 L1,45 C1,46.1 1.9,47 3,47 Z" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M57,48 L3,48 C1.3,48 0,46.7 0,45 L0,3 C0,1.3 1.3,0 3,0 L57,0 C58.7,0 60,1.3 60,3 L60,45 C60,46.7 58.7,48 57,48 Z M3,2 C2.4,2 2,2.4 2,3 L2,45 C2,45.6 2.4,46 3,46 L57,46 C57.6,46 58,45.6 58,45 L58,3 C58,2.4 57.6,2 57,2 L3,2 Z" }), /* @__PURE__ */ React.createElement("rect", { fill: "#d9efed", style: {
  fill: "var(--secondary-color)"
}, width: 20, height: 10, x: 7, y: 7 }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M27,18 L7,18 C6.4,18 6,17.6 6,17 L6,7 C6,6.4 6.4,6 7,6 L27,6 C27.6,6 28,6.4 28,7 L28,17 C28,17.6 27.5,18 27,18 Z M8,16 L26,16 L26,8 L8,8 L8,16 Z" }), /* @__PURE__ */ React.createElement("rect", { fill: "#d9efed", style: {
  fill: "var(--secondary-color)"
}, width: 20, height: 10, x: 33, y: 7 }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M53,18 L33,18 C32.4,18 32,17.6 32,17 L32,7 C32,6.4 32.4,6 33,6 L53,6 C53.6,6 54,6.4 54,7 L54,17 C54,17.6 53.5,18 53,18 Z M34,16 L52,16 L52,8 L34,8 L34,16 Z" }), /* @__PURE__ */ React.createElement("rect", { fill: "#d9efed", style: {
  fill: "var(--secondary-color)"
}, width: 20, height: 10, x: 7, y: 27 }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M27,38 L7,38 C6.4,38 6,37.6 6,37 L6,27 C6,26.4 6.4,26 7,26 L27,26 C27.6,26 28,26.4 28,27 L28,37 C28,37.6 27.5,38 27,38 Z M8,36 L26,36 L26,28 L8,28 L8,36 Z" }), /* @__PURE__ */ React.createElement("rect", { fill: "#d9efed", style: {
  fill: "var(--secondary-color)"
}, width: 20, height: 10, x: 33, y: 27 }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M53,38 L33,38 C32.4,38 32,37.6 32,37 L32,27 C32,26.4 32.4,26 33,26 L53,26 C53.6,26 54,26.4 54,27 L54,37 C54,37.6 53.5,38 53,38 Z M34,36 L52,36 L52,28 L34,28 L34,36 Z" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M21 22L7 22C6.4 22 6 21.6 6 21 6 20.4 6.4 20 7 20L21 20C21.6 20 22 20.4 22 21 22 21.6 21.6 22 21 22zM47 22L33 22C32.4 22 32 21.6 32 21 32 20.4 32.4 20 33 20L47 20C47.6 20 48 20.4 48 21 48 21.6 47.6 22 47 22zM21 42L7 42C6.4 42 6 41.6 6 41 6 40.4 6.4 40 7 40L21 40C21.6 40 22 40.4 22 41 22 41.6 21.6 42 21 42zM47 42L33 42C32.4 42 32 41.6 32 41 32 40.4 32.4 40 33 40L47 40C47.6 40 48 40.4 48 41 48 41.6 47.6 42 47 42z" })));
export default SvgViewList;
