import { type KnackViewType } from '@/types/schema/BuilderView';
import BarChartIcon from '@/assets/svg/views/view-bar-chart.svg?react';
import CalendarViewIcon from '@/assets/svg/views/view-calendar.svg?react';
import CheckoutViewIcon from '@/assets/svg/views/view-checkout.svg?react';
import CustomerViewIcon from '@/assets/svg/views/view-customer.svg?react';
import DetailsViewIcon from '@/assets/svg/views/view-details.svg?react';
import FormViewIcon from '@/assets/svg/views/view-form.svg?react';
import ListViewIcon from '@/assets/svg/views/view-list.svg?react';
import MapViewIcon from '@/assets/svg/views/view-map.svg?react';
import MenuViewIcon from '@/assets/svg/views/view-menu.svg?react';
import RichTextViewIcon from '@/assets/svg/views/view-rich-text.svg?react';
import SearchViewIcon from '@/assets/svg/views/view-search.svg?react';
import TableViewIcon from '@/assets/svg/views/view-table.svg?react';

export function ViewIcon({ type, className }: { type: KnackViewType; className?: string }) {
  switch (type) {
    case 'form':
      return <FormViewIcon className={className} />;
    case 'calendar':
      return <CalendarViewIcon className={className} />;
    case 'table':
      return <TableViewIcon className={className} />;
    case 'search':
      return <SearchViewIcon className={className} />;
    case 'details':
      return <DetailsViewIcon className={className} />;
    case 'list':
      return <ListViewIcon className={className} />;
    case 'map':
      return <MapViewIcon className={className} />;
    case 'report':
      return <BarChartIcon className={className} />;
    case 'rich_text':
      return <RichTextViewIcon className={className} />;
    case 'menu':
      return <MenuViewIcon className={className} />;
    case 'checkout':
      return <CheckoutViewIcon className={className} />;
    case 'customer':
      return <CustomerViewIcon className={className} />;
    default:
      return null;
  }
}
