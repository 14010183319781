import { type SampleExcel } from '@/pages/add-table/CreateTableFromExcelSampleList';

export const sampleExcels: SampleExcel[] = [
  {
    name: 'Bakery Manager',
    key: 'bakery-manager',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_Bakery-ManagerKNACK.xlsx',
    pages: ['Recipes', 'Ingredients', 'Orders', 'Order Items']
  },
  {
    name: 'Business Directory',
    key: 'business-directory',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_BusinessDirectory-KNACK.xlsx',
    pages: ['Businesses']
  },
  {
    name: 'Camp Management',
    key: 'camp-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CampManagement-KNACK.xlsx',
    pages: [
      'Participants',
      'Sessions',
      'Session Registrations',
      'Staff Trainings',
      'Staff Training Registrations',
      // 'Medical Histories',
      'Immunizations',
      'Emergency Contacts'
    ]
  },
  {
    name: 'Case Management',
    key: 'case-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CaseManagement-KNACK.xlsx',
    pages: ['Cases', 'Assets', 'Scheduled Events', 'Calls', 'Notes']
  },
  {
    name: 'Class Catalog & Enrollments',
    key: 'class-catalog',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_ClassCatalog+Enrollments-KNACK.xlsx',
    pages: ['Enrollments', 'Classes']
  },
  {
    name: 'Contact Directory',
    key: 'contact-directory',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_ContactDirectory-KNACK.xlsx',
    pages: ['Companies', 'Contacts', 'Notes']
  },
  {
    name: 'Coworking Member Portal',
    key: 'coworking-member-portal',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CoworkingMemberPortal-KNACK.xlsx',
    pages: ['Events', 'Event registrations', 'Rooms', 'Room registrations']
  },
  {
    name: 'Custom CRM',
    key: 'custom-crm',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CustomCRM-KNACK.xlsx',
    pages: ['Contacts', 'Tasks & Meetings']
  },
  /*   {
    name: 'Customer Payment Portal',
    key: 'customer-payment-portal',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CustomerPaymentPortal.xlsx',
    pages: ['Services', 'Invoices', 'Customers', 'Payments']
  }, */
  {
    name: 'Customer Portal',
    key: 'customer-portal',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_CustomerPortal-KNACK.xlsx',
    pages: ['Services', 'Invoices']
  },
  {
    name: 'Donations Manager',
    key: 'donations-manager',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_DonationsManager-KNACK.xlsx',
    pages: ['Campaigns', 'Donors', 'Donations', 'Non-profits']
  },
  {
    name: 'Employee Reviews',
    key: 'employee-reviews',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_EmployeeReviews.xlsx',
    pages: ['Reviews', 'Employees']
  },
  {
    name: 'Equipment Tracker',
    key: 'equipment-tracker',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_EquipmentTracker-KNACK.xlsx',
    pages: ['Items', 'Logs']
  },
  {
    name: 'Event Calendar',
    key: 'event-calendar',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_EventCalendar-KNACK.xlsx',
    pages: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  },
  {
    name: 'Event Registration',
    key: 'event-registration',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_EventRegistration-KNACK.xlsx',
    pages: ['Events', 'Registrations']
  },
  {
    name: 'Grants Management',
    key: 'grants-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_GrantsManagement-KNACK.xlsx',
    pages: ['Applications', 'Projects', 'Transactions', 'Grants']
  },
  {
    name: 'HOA Management',
    key: 'hoa-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_HOAManagement-KNACK.xlsx',
    pages: [
      'Communities',
      'Properties',
      'Projects',
      'Community Events',
      'Community Announcements',
      'Dues'
    ]
  },
  {
    name: 'Image Gallery',
    key: 'image-gallery',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_ImageGallery-KNACK.xlsx',
    pages: ['Images']
  },
  {
    name: 'Inventory Management',
    key: 'inventory-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_InventoryManagement-KNACK.xlsx',
    pages: ['Current Inventory', 'Purchases', 'Orders']
  },
  /*   {
    name: 'Job Listings',
    key: 'job-listings',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_JobListings-KNACK.xlsx',
    pages: ['Jobs', 'Applications', 'Evaluations']
  }, */
  {
    name: 'Medical Inventory Manager',
    key: 'medical-inventory-manager',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_MedicalInventory-ManagerKNACK.xlsx',
    pages: [
      'Customers',
      'Clinics',
      'Vendors',
      'Products',
      'Inventory',
      'Inventory Lots',
      'Sales Orders',
      'Order Items',
      'Order Picks',
      'PO Items'
    ]
  },
  {
    name: 'Member Directory',
    key: 'member-directory',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_MemberDirectory-KNACK.xlsx',
    pages: ['Members']
  },
  /*   {
    name: 'Mobile Workforce',
    key: 'mobile-workforce',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_MobileWorkforce-KNACK.xlsx',
    pages: ['Jobs', 'Tasks', 'Customers']
  }, */
  {
    name: 'Multistep Application',
    key: 'multistep-application',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_MultistepApplication.xlsx',
    pages: ['Applicants', 'Education', 'Employment History', 'Licenses']
  },
  /*   {
    name: 'Municipal Payment Portal',
    key: 'municipal-payment-portal',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_MunicipalPaymentPortal-KNACK.xlsx',
    pages: ['Services', 'Residents', 'Invoices']
  }, */
  {
    name: 'Online Donation Portal',
    key: 'online-donation-portal',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_OnlineDonationPortal-KNACK.xlsx',
    pages: ['Campaigns', 'Donations', 'Donors', 'Nonprofits']
  },
  {
    name: 'Payroll',
    key: 'payroll',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_Payroll-KNACK.xlsx',
    pages: ['Time Cards', 'Timesheet', 'Pay Periods']
  },
  {
    name: 'Product Catalog',
    key: 'product-catalog',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_ProductCatalog-KNACK.xlsx',
    pages: ['Products']
  },
  {
    name: 'Purchase Orders',
    key: 'purchase-orders',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_PurchaseOrders-KNACK.xlsx',
    pages: ['Products', 'Vendors', 'Orders', 'Order Items']
  },
  {
    name: 'Quotes And Invoices',
    key: 'quotes-and-invoices',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_QuotesAndInvoices-KNACK.xlsx',
    pages: ['Quotes', 'Products', 'Customers', 'Line Items']
  },
  {
    name: 'Real Estate',
    key: 'real-estate',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_RealEstate.xlsx',
    pages: ['Listings', 'Inquiries']
  },
  {
    name: 'Small Business Budget',
    key: 'small-business-budget',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_SmallBusinessBudget-KNACK.xlsx',
    pages: ['Monthly Budget Overview', 'Income', 'Expenses']
  },
  {
    name: 'Sports League',
    key: 'sports-league',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_SportsLeague-KNACK.xlsx',
    pages: ['Games', 'Teams']
  },
  {
    name: 'Store Locator',
    key: 'store-locator',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_StoreLocator-KNACK.xlsx',
    pages: ['Locations']
  },
  /*   {
    name: 'Timesheets',
    key: 'timesheets',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_Timesheets-KNACK-1.xlsx',
    pages: ['Weekly Timesheet']
  }, */
  {
    name: 'Tutoring Company',
    key: 'tutoring-company',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_TutoringCompany-KNACK.xlsx',
    pages: ['Sessions', 'Primary Subjects', 'Secondary Subjects', 'Credits']
  },
  {
    name: 'Volunteer Management',
    key: 'volunteer-management',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_VolunteerManagement-KNACK.xlsx',
    pages: ['Events', 'Event Jobs', 'Event Job Signups', 'Volunteers']
  },
  {
    name: 'Warehouse Manager',
    key: 'warehouse-manager',
    url: 'https://23287346.fs1.hubspotusercontent-na1.net/hubfs/23287346/Spreadsheet Templates_External Website/Free_ExcelTemplate_WarehouseManager.xlsx',
    pages: [
      'Products',
      'Suppliers',
      'Customer Orders',
      'Order Items',
      'Warehouses',
      'Warehouse Inventory',
      'Inventory Purchases',
      'Stock Transfers',
      'Warehouse Staff'
    ]
  }
];
