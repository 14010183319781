import { useTranslation } from 'react-i18next';
import { Tabs, type TabsListItem } from '@knack/asterisk-react';

import type { LegendPosition, ReportView } from '@/types/schema/views/ReportView';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { getBaseReportViewObject } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export function LegendPlacement() {
  const [t] = useTranslation();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const legendItems: TabsListItem[] = [
    {
      children: <span>{t('pages.element_settings.report.categories.general.left')}</span>,
      value: 'left'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.right')}</span>,
      value: 'right'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.below')}</span>,
      value: 'below'
    },
    {
      children: <span>{t('pages.element_settings.report.categories.general.none')}</span>,
      value: 'none'
    }
  ];

  return (
    <div className="my-4">
      <div className="mb-2 text-sm">
        {t('pages.element_settings.report.categories.general.legend_placement')}
      </div>
      <Tabs
        defaultValue={view.rows[0].reports[0].layout.legend}
        onValueChange={(value) => {
          const newSchema = getBaseReportViewObject(view);
          newSchema.rows[0].reports[0].layout.legend = value as LegendPosition;
          updateViewSchema(newSchema);
        }}
      >
        <Tabs.List items={legendItems} shouldDisableResponsive />
      </Tabs>
    </div>
  );
}
